import classes from './ThankYouPage.module.scss';
import QuizViewMain from 'pages/QuizViewPage/Components/Containers/MainContainer/MainContainer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Loader from 'components/UI/Loader';
const HeaderTitle = ({ title = '', type, isEditing }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  let titleText = '';
  if (title?.length > 0) {
    if (mobileView) {
      if (title?.length > 30) {
        titleText = title.substring(0, 25) + '...';
      } else {
        titleText = title;
      }
    } else {
      if (title?.length > 50) {
        titleText = title.substring(0, 40) + '...';
      } else {
        titleText = title;
      }
    }
  } else {
    if (type === 'Form' && isEditing) {
      titleText = 'Edit a Form';
    } else if (type === 'Form' && !isEditing) {
      titleText = 'Create a Form';
    } else if (type != 'Form' && isEditing) {
      titleText = 'Edit a Quiz';
    } else {
      titleText = 'Create a Quiz';
    }
  }
  return (
    <Tooltip title={title} arrow>
      <div style={{ fontSize: mobileView ? '20px' : '' }}>{titleText}</div>
    </Tooltip>
  );
};
export default function ThankYouPage() {
  const { activeQuiz } = useSelector(state => state?.quizes);
  const [loading, setLoading] = useState(false);
  const { title, type } = activeQuiz;
  if (loading) {
    return <Loader />;
  }

  return (
    <QuizViewMain
      headerProps={{
        headerTitle: <HeaderTitle {...{ title, type }} />,
      }}
    >
      <div className={classes.container}>
        <div className={classes.noImagestatsText}>Thank you for filling out the form</div>
        <div className={classes.statsText}>You've successfully submitted your information.</div>
      </div>
    </QuizViewMain>
  );
}
