import React, { useState } from 'react';
import classes from './Question.module.scss';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ActionsIcon from '../../Assests/Icons/ActionIcon';
import Multiple from '../QuestionTypes/Multiple/Multiple';
import { questionTypes } from 'pages/CreateQuizes/Constants/constants';
import Dropdown from '../QuestionTypes/Dropdown/Dropdown';
import ActionsPanel from '../ActionPanel/ActionsPanel';
import { ClickAwayListener, Portal, Tooltip } from '@material-ui/core';
import { convertToPlainText } from 'utils/utils';
import Modal from 'components/UI/Modal';
import Ranking from '../QuestionTypes/Ranking/Ranking';
import Rating from '../QuestionTypes/Rating/Rating';
import ShortText from '../QuestionTypes/ShortText/ShortText';
import classNames from 'classnames';
import styled from 'styled-components';

const TYPE_MAPPER = {
  [questionTypes.multiple_choice]: <Multiple />,
  [questionTypes.dropdown]: <Dropdown />,
  [questionTypes.ranking]: <Ranking />,
  [questionTypes.rating]: <Rating />,
  [questionTypes.short_text]: <ShortText />,
};

// const ActionPanelDropDown = styled.div`
//   position: absolute;
//   top: ${({ top }) => (top ? top : '0px')};
//   left: ${({ left }) => (left ? left : '0px')};
//   z-index: 100;
//   ${({ mobileView }) => mobileView && 'left: 250px'}
//   ${({ index }) =>
//     index === 4 &&
//     `
//     top: 500px;
//     left: 280px;
//     z-index:100;
//     position: absolute;
//   `}
// `;

const ActionPanelDropDown = styled.div`
  position: absolute;
  right: 5px;
  top: 16px;
  z-index: 100;
`;

const Question = ({
  question,
  index,
  parentProvided,
  push,
  remove,
  isFocused,
  onFocus,
  questionsLength,
  mobileView,
  setFieldValue,
  questions,
  setFocusedQuestion,
  displayIndex,
}) => {
  const [isActionPanelOpen, setIsActionPanelOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [actionPanelPosition, setActionPanelPosition] = useState({ top: 0, left: 0 });
  const {
    type,
    description,
    options,
    isOptional,
    name,
    questionType,
    userResponse,
    isDraft,
    isRequired,
    id,
    placeholder,
  } = question;
  const nonDeletedQuestions = questions.filter(question => question.isDeleted === false);

  const actionsList =
    nonDeletedQuestions.length > 1
      ? [
          {
            name: 'Duplicate',
            onClick: e => {
              e.stopPropagation();
              push(question);
              setIsActionPanelOpen(false);
              setFieldValue('isSaved', false);
            },
          },
          {
            name: 'Delete',
            onClick: e => {
              e.stopPropagation();
              setIsConfirmationModalOpen(true);
              setIsActionPanelOpen(false);
              setFieldValue('isSaved', false);
            },
          },
        ]
      : [
          {
            name: 'Duplicate',
            onClick: e => {
              e.stopPropagation();
              push(question);
              setIsActionPanelOpen(false);
              setFieldValue('isSaved', false);
            },
          },
        ];
  const getFirstSentence = content => {
    const str = convertToPlainText(content);
    const indexOfDot = str.indexOf('.');
    return indexOfDot !== -1 ? str.substring(0, indexOfDot) : str;
  };
  function setDeletedAtIndex(questions, index) {
    return questions.map((question, i) => (i === index ? { ...question, isDeleted: true } : question));
  }
  const handleDelete = () => {
    // remove(index);

    if (index === 0) {
      const firstIndex = setDeletedAtIndex(questions, index).findIndex(question => question.isDeleted === false);
      setFocusedQuestion(firstIndex);
    } else {
      const lastIndex =
        setDeletedAtIndex(questions, index).length -
        1 -
        setDeletedAtIndex(questions, index)
          .slice()
          .reverse()
          .findIndex(question => question.isDeleted === false);
      setFocusedQuestion(lastIndex);
    }

    setFieldValue(
      `questions`,
      questions.map((q, i) => (i === index ? { ...q, isDeleted: true } : q)),
    );
    setIsConfirmationModalOpen(false);
  };
  console.log('name----->', convertToPlainText(name).length);
  return (
    <>
      <Droppable droppableId={`question-${index}`}>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} innerRef={provided.innerRef}>
            <div
              className={classNames(classes.question_container, {
                [classes.question_container_responsive]: mobileView,
                [classes.focused_question]: isFocused,
              })}
              onClick={onFocus}
            >
              <div className={classes.dragicon_container} {...parentProvided.dragHandleProps}>
                <DragIndicatorIcon className={classes.drag_icon} />
              </div>
              <div className={classes.question}>
                <div className={classes.question_type}>{TYPE_MAPPER[questionType]}</div>
                <Tooltip title={name != undefined && name?.length === 0 ? placeholder : convertToPlainText(name)}>
                  <div
                    className={
                      name != undefined || name?.length === 0 ? classes.question_desc_place : classes.question_desc
                    }
                  >
                    {name != undefined && convertToPlainText(name)?.length === 0
                      ? `${displayIndex}. ${placeholder}`
                      : convertToPlainText(name)?.length === 0
                      ? `${displayIndex}. ${placeholder}`
                      : `${displayIndex}. ${getFirstSentence(name)}`}
                  </div>
                </Tooltip>
              </div>
              <div
                className={classes.actionicon_container}
                onClick={e => {
                  e.stopPropagation();
                  setIsActionPanelOpen(!isActionPanelOpen);
                  e.stopPropagation();
                  const rect = e.currentTarget.getBoundingClientRect();
                  const scrollTop = window.scrollY || document.documentElement.scrollTop;
                  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
                  setActionPanelPosition({ top: rect.bottom + scrollTop, left: rect.left + scrollLeft - 10 });
                }}
              >
                <ActionsIcon />
                {isActionPanelOpen && (
                  /*  <Portal>
                    <ActionPanelDropDown
                      mobileView={mobileView}
                      top={`${actionPanelPosition.top}px`}
                      left={`${actionPanelPosition.left}px`}
                      index={index}
                    >
                      <ActionsPanel
                        actions={actionsList}
                        onClose={() => {
                          setIsActionPanelOpen(false);
                        }}
                      />
                    </ActionPanelDropDown>
                  </Portal> */
                  <ActionPanelDropDown mobileView={mobileView}>
                    <ActionsPanel
                      actions={actionsList}
                      onClose={() => {
                        setIsActionPanelOpen(false);
                      }}
                    />
                  </ActionPanelDropDown>
                )}
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Modal
        isOpen={isConfirmationModalOpen}
        title="Confirmation"
        submitTitle="Delete"
        onSubmit={handleDelete}
        onCancel={() => {
          setIsConfirmationModalOpen(false);
        }}
        reduceCancelButton
      >
        <div> Are you sure you want to delete this question?</div>
      </Modal>
    </>
  );
};

export default Question;
