import React, { useState, useEffect, useMemo } from 'react';
import classes from './ContentBody.module.scss';
import { CircularProgress, OutlinedInput, TextField, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { CKEditor } from 'ckeditor4-react';
import { FieldArray } from 'formik';
import { SlimStyledSlider, SlimSwitch, StyledInput } from 'components/switch/style';
import { questionTypes } from 'pages/CreateQuizes/Constants/constants';
import Modal from 'components/UI/Modal';
import Button from 'components/FormUI/Button';
import CheckIcon from '../QuizContent/Assests/Icons/CheckIcon';
import DropdownIcon from '../QuizContent/Assests/Icons/DropdownIcon';
import AddChoicesModal from '../QuizContent/Modals/AddChoices/AddChoices';
import QuizOptions from '../QuizContent/Components/Options/Options';
import QuestionTypesPanel from '../QuizContent/Components/QuestionTypesPanel/QuestionTypesPanel';
import QuestionTypeSelect from '../QuizContent/Components/QuestionTypeSelect/QuestionTypeSelect';
import { convertToPlainText } from 'utils/utils';
import { createUpdateQuiz, createUpdateQuestionInQuiz } from '../../Services/CreateQuizes.service';
import { useDispatch } from 'react-redux';
import { setActiveQuiz } from 'actions/quizes';
import RankingIcon from '../QuizContent/Assests/Icons/RankingIcon';
import RatingIcon from '../QuizContent/Assests/Icons/RatingIcon';
import ShortTextIcon from '../QuizContent/Assests/Icons/ShortTextIcon';
import useScreenView from 'hooks/useScreenView';
import styled from 'styled-components';
import { css } from 'styled-components';
import classNames from 'classnames';
import { TOOLTIP } from '../../../../constants';
import { DOCUMENT_TYPES } from '../QuizContent/constants';
import { colors } from 'utils/styles';
import EditableTextField, {
  EditableTextFieldContent,
} from '../QuizContent/Components/EditableTextField/EditableTextField';
import FormOptions from '../QuizContent/Components/FormOptions/FormOptions';
import Select from 'components/UI/Select';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { useHeader } from 'hooks/usePageActions';
import useRouter from 'hooks/useRouter';

const StyledContainer = styled.div`
  ${({ mobileView }) =>
    mobileView &&
    css`
      padding: 24px 16px;
    `}
`;

const StyledQuestionTypeContainer = styled.div`
  ${({ mobileView }) =>
    mobileView &&
    css`
      flex-direction: column;
      gap: 16px;
    `}
`;

const OPTIONS_MAPPER = {
  [DOCUMENT_TYPES.Quiz]: QuizOptions,
  [DOCUMENT_TYPES.Form]: FormOptions,
};

const RATING_ITEMS = [
  {
    title: '3 Stars',
    value: '3',
  },
  {
    title: '4 Stars',
    value: '4',
  },
  {
    title: '5 Stars',
    value: '5',
  },
  {
    title: '6 Stars',
    value: '6',
  },
  {
    title: '7 Stars',
    value: '7',
  },
  {
    title: '8 Stars',
    value: '8',
  },
  {
    title: '9 Stars',
    value: '9',
  },
  {
    title: '10 Stars',
    value: '10',
  },
];

const ContentBody = ({
  focusedQuestion,
  focusedQuestionData,
  setFieldValue,
  questions,
  formProps,
  mobileView,
  setFocusedQuestion,
}) => {
  const { history, location, query } = useRouter();
  const isEditing = query?.id;
  useHeader(
    formProps?.values?.type === 'Form' && isEditing
      ? 'Edit a Form'
      : formProps?.values?.type === 'Form' && !isEditing
      ? 'Create a Form'
      : formProps?.values?.type != 'Form' && isEditing
      ? 'Edit a Quiz'
      : 'Create a Quiz',
  );

  const [isAddChoicesModalOpen, setIsAddChoicesModalOpen] = useState(false);
  const [switchingQuestionModal, setSwitchingQuestionModal] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isEditorLoaded, setIsEditorLoaded] = useState(false);
  const [text, setText] = useState(focusedQuestionData?.name || '');
  const [showCkEditor, setShowCkEditor] = useState(true);
  const [showGradeModal, setShowGradeModal] = useState(false);
  const [isQuestionTypesPanelOpen, setIsQuestionTypesPanelOpen] = useState(false);
  const [errormessage, seterrormessage] = useState(null);
  const [showErrorModal, setshowErrorModal] = useState(false);
  const isForm = formProps?.values?.type === DOCUMENT_TYPES.Form;
  const isQuiz = formProps?.values?.type === DOCUMENT_TYPES.Quiz;
  const btnTitle =
    focusedQuestionData?.questionType === questionTypes.multiple_choice
      ? '+ Add Choice'
      : focusedQuestionData?.questionType === questionTypes.dropdown
      ? '+ Add an option'
      : '+ Add Choices';
  const dispatch = useDispatch();
  const toolbarConfig = useMemo(
    () => [
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
      { name: 'links', items: ['Link', 'Unlink'] },
      { name: 'insert', items: ['Image'] },
      { name: 'undo', items: ['Undo', 'Redo'] },
      { name: 'styles', items: ['Font', 'FontSize', '-'] },
      { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'indent', items: ['Indent', 'Outdent'] },
      { name: 'lists', items: ['NumberedList', 'BulletedList'] },
      {
        name: 'paragraph',
        items: [
          'Blockquote',
          'CreateDiv',
          /* 'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock', */
          '-',
          'BidiLtr',
          'BidiRtl',
          'Language',
        ],
      },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
    ],
    [],
  );
  const actionsList = isForm
    ? [
        {
          name: 'Dropdown',
          icon: <DropdownIcon />,
          onClick: e => {
            e.stopPropagation();
            const question = {
              type: questionTypes.dropdown,
              description: '',
              options: [{ value: '', isCorrect: false }],
              isOptional: false,
              canSelectMultipleCorrectOptions: false,
              name: '',
              questionType: questionTypes.dropdown,
              userResponse: [],
              isDraft: true,
              isRequired: true,
              isDeleted: false,
              placeholder: 'Please write your question here…',
            };
            setFieldValue('questions', [...questions, question]);
            setIsQuestionTypesPanelOpen(false);
          },
        },
        {
          name: 'Multiple Choice',
          icon: <CheckIcon />,
          onClick: e => {
            e.stopPropagation();
            const question = {
              type: questionTypes.multiple_choice,
              description: '',
              options: [{ value: '', isCorrect: false }],
              isOptional: false,
              canSelectMultipleCorrectOptions: false,
              name: '',
              questionType: questionTypes.multiple_choice,
              userResponse: [],
              isDraft: true,
              isRequired: true,
              isDeleted: false,
              placeholder: 'Please write your question here…',
            };
            setFieldValue('questions', [...questions, question]);
            setIsQuestionTypesPanelOpen(false);
          },
        },
        {
          name: 'Ranking',
          icon: <RankingIcon />,
          onClick: e => {
            e.stopPropagation();
            const question = {
              type: questionTypes.ranking,
              description: '',
              options: [],
              isOptional: false,
              hasMultipleAnswers: false,
              name: '',
              questionType: questionTypes.ranking,
              userResponse: [],
              isDraft: true,
              isDeleted: false,
              isRequired: true,
              placeholder: 'Please write your question here…',
            };
            setFieldValue('questions', [...questions, question]);
            setIsQuestionTypesPanelOpen(false);
          },
        },
        {
          name: 'Rating',
          icon: <RatingIcon />,
          onClick: e => {
            e.stopPropagation();
            const question = {
              type: questionTypes.rating,
              description: '',
              options: [{ value: '5' }],
              isOptional: false,
              hasMultipleAnswers: false,
              name: '',
              questionType: questionTypes.rating,
              userResponse: [],
              isDraft: true,
              isDeleted: false,
              isRequired: true,
              placeholder: 'Please write your question here…',
            };
            setFieldValue('questions', [...questions, question]);
            setIsQuestionTypesPanelOpen(false);
          },
        },
        {
          name: 'Short Text',
          icon: <ShortTextIcon />,
          onClick: e => {
            e.stopPropagation();
            const question = {
              type: questionTypes.short_text,
              description: '',
              options: [{ value: '0', isCorrect: false }],
              isOptional: false,
              hasMultipleAnswers: false,
              name: '',
              questionType: questionTypes.short_text,
              userResponse: [],
              isDraft: true,
              isDeleted: false,
              isRequired: true,
              placeholder: 'Please write your question here…',
              canSelectMultipleCorrectOptions: false,
            };
            setFieldValue('questions', [...questions, question]);
            setIsQuestionTypesPanelOpen(false);
            setFieldValue('isSaved', false);
          },
        },
      ]
    : [
        {
          name: 'Dropdown',
          icon: <DropdownIcon />,
          onClick: e => {
            e.stopPropagation();
            const question = {
              type: questionTypes.dropdown,
              description: '',
              options: [{ value: '', isCorrect: false }],
              isOptional: false,
              canSelectMultipleCorrectOptions: false,
              name: '',
              questionType: questionTypes.dropdown,
              userResponse: [],
              isDraft: true,
              isRequired: true,
              isDeleted: false,
              placeholder: 'Please write your question here…',
            };
            setFieldValue('questions', [...questions, question]);
            setIsQuestionTypesPanelOpen(false);
          },
        },
        {
          name: 'Multiple Choice',
          icon: <CheckIcon />,
          onClick: e => {
            e.stopPropagation();
            const question = {
              type: questionTypes.multiple_choice,
              description: '',
              options: [{ value: '', isCorrect: false }],
              isOptional: false,
              canSelectMultipleCorrectOptions: false,
              name: '',
              questionType: questionTypes.multiple_choice,
              userResponse: [],
              isDraft: true,
              isRequired: true,
              isDeleted: false,
              placeholder: 'Please write your question here…',
            };
            setFieldValue('questions', [...questions, question]);
            setIsQuestionTypesPanelOpen(false);
          },
        },
      ];

  const handleTypeSelect = type => {
    if (
      (convertToPlainText(focusedQuestionData?.name).length === 0 && focusedQuestionData?.options?.length === 0) ||
      focusedQuestionData?.options[0]?.value?.length === 0
    ) {
      setShowCkEditor(false);
      setTimeout(() => {
        setShowCkEditor(true);
      }, 500);
      setFieldValue(`questions[${focusedQuestion}].questionType`, type);
      setFieldValue(`questions[${focusedQuestion}].type`, type);
      if (type === questionTypes.multiple_choice) {
        setFieldValue(`questions[${focusedQuestion}].options`, [{ value: '', isCorrect: false }]);
      }
      if (type === questionTypes.dropdown) {
        setFieldValue(`questions[${focusedQuestion}].options`, [{ value: '', isCorrect: false }]);
      }
      if (type === questionTypes.ranking) {
        setFieldValue(`questions[${focusedQuestion}].options`, [{ value: '', isCorrect: false }]);
      }
      if (type === questionTypes.rating) {
        setFieldValue(`questions[${focusedQuestion}].options`, [{ value: '5', isCorrect: false }]);
      }
      if (type === questionTypes.short_text) {
        setFieldValue(`questions[${focusedQuestion}].options`, [{ value: '0', isCorrect: false }]);
      } else {
        setFieldValue(`questions[${focusedQuestion}].options`, []);
      }

      // setFieldValue(`questions[${focusedQuestion}].name`, '');
    } else {
      setSelectedType(type);
      setSwitchingQuestionModal(true);
    }
  };

  const getFirstSentence = content => {
    const str = convertToPlainText(content);
    const indexOfDot = str.indexOf('.');
    return indexOfDot !== -1 ? str.substring(0, indexOfDot) : str;
  };

  useEffect(() => {
    if (showCkEditor === true) {
      setShowCkEditor(false);
    }
    setTimeout(() => {
      setShowCkEditor(true);
    }, 500);
  }, [focusedQuestion]);

  const saveQuiz = () => {
    if (
      formProps?.values?.passingPercentage <= 0 &&
      formProps?.values?.isPassingGradeRequired === true &&
      formProps?.values.type === 'Quiz'
    ) {
      setLoader(false);
      setShowGradeModal(true);
    } else {
      let updatedQuestions = formProps?.values?.questions
        .filter(question => question?.name?.length > 0 || question?.options?.length > 0)
        .map(question => ({
          ...question,
          id: question.id ?? null,
          isDraft: question.isDraft,
        }));
      let Form = {
        ...formProps.values,
        questions: updatedQuestions,
      };
      createUpdateQuiz(Form)
        .then(res => {
          setLoader(false);
          setFieldValue('isSaved', true);
          setFieldValue('questions', res.questions);
          dispatch(
            setActiveQuiz({
              ...res,
              isSaved: true,
            }),
          );
          if (isQuiz) {
            const question = {
              type: questionTypes.dropdown,
              description: '',
              options: [{ value: '', isCorrect: false }],
              isOptional: false,
              canSelectMultipleCorrectOptions: false,
              name: '',
              questionType: questionTypes.dropdown,
              userResponse: [],
              isDraft: true,
              isRequired: true,
              isDeleted: false,
              placeholder: 'Please write your question here…',
            };
            setFieldValue('questions', [...questions, question]);
          } else {
            const question = {
              type: questionTypes.short_text,
              description: '',
              options: [{ value: '0', isCorrect: false }],
              isOptional: false,
              hasMultipleAnswers: false,
              name: '',
              questionType: questionTypes.short_text,
              userResponse: [],
              isDraft: true,
              isDeleted: false,
              isRequired: true,
              placeholder: 'Please write your question here…',
              canSelectMultipleCorrectOptions: false,
            };
            setFieldValue('questions', [...questions, question]);
            setIsQuestionTypesPanelOpen(false);
            setFieldValue('isSaved', false);
          }
        })
        .catch(err => {
          console.log('error---->', err);
          seterrormessage(err.response.data.invalidQuestion);
          setLoader(false);
          setshowErrorModal(true);
        });
    }
  };
  const checkDisabled = () => {
    if (isForm) {
      const hasCorrectAnswer =
        focusedQuestionData?.options?.every(item => item.value.length > 0) &&
        focusedQuestionData?.options?.every(item => item.value.length < 21);
      if (focusedQuestionData?.questionType === 'ShortText') {
        return focusedQuestionData?.name?.length === 0 || formProps?.values?.title?.length === 0;
      } else {
        return (
          focusedQuestionData?.name?.length === 0 ||
          focusedQuestionData?.name?.length > 200 ||
          focusedQuestionData?.options?.length === 0 ||
          !hasCorrectAnswer ||
          formProps.values.title.length === 0
        );
      }
    } else {
      const hasCorrectAnswer = focusedQuestionData?.options?.some(item => item.isCorrect === true);
      if (formProps.values.isPassingGradeRequired === true) {
        return (
          convertToPlainText(focusedQuestionData?.name).length === 0 ||
          focusedQuestionData?.options?.length === 0 ||
          !hasCorrectAnswer ||
          formProps.values.title.length === 0
          // ||
          // (formProps.values.passingPercentage > 0 && formProps.values.isPassingGradeRequired === true) === false
        );
      } else {
        return (
          convertToPlainText(focusedQuestionData?.name).length === 0 ||
          focusedQuestionData?.options?.length === 0 ||
          !hasCorrectAnswer ||
          formProps.values.title.length === 0
        );
      }
    }
  };

  const handleQuestionChange = e => {
    console.log('e------>', e.target.value);
    e.stopPropagation();
    setFieldValue(`questions[${focusedQuestion}].name`, e?.target?.value);
    setFieldValue('isSaved', false);
  };

  // useEffect(() => {
  //   setFieldValue(`questions[${focusedQuestion}].name`, text);
  //   setFieldValue('isSaved', false);
  // }, [text]);

  const OptionsComponent = OPTIONS_MAPPER[formProps?.values?.type];

  return (
    <StyledContainer className={classes.body} mobileView={mobileView}>
      {isForm && (
        <div className={classes.question_heading}>
          {/* <span>{`${focusedQuestion + 1}. Question`}</span> */}
          <span>{`Question`}</span>
        </div>
      )}
      <StyledQuestionTypeContainer className={classes.question_type_container} mobileView={mobileView}>
        <QuestionTypeSelect
          docType={formProps?.values?.type}
          type={focusedQuestionData?.questionType}
          onSelect={handleTypeSelect}
        />
        {!mobileView && (
          <div className={classes.toggle_container}>
            <div className={classes.heading}>Make Required</div>
            <SlimSwitch className={classes.toggle_button}>
              <StyledInput
                type="checkbox"
                onClick={() => {
                  setFieldValue(`questions[${focusedQuestion}].isRequired`, !focusedQuestionData?.isRequired);
                  setFieldValue(`isSaved`, false);
                }}
                checked={focusedQuestionData?.isRequired}
              />
              <SlimStyledSlider className="slider round" />
            </SlimSwitch>
          </div>
        )}
      </StyledQuestionTypeContainer>
      {isQuiz && (
        <div className={classes.question_heading}>
          <span>{`Question`}</span>
          <Tooltip title="The question header will populate based on the first sentence in the content below." arrow>
            <InfoIcon style={{ marginLeft: '8px', marginBottom: '3px' }} htmlColor="#D1B989" />
          </Tooltip>
        </div>
      )}

      {isForm && (
        <EditableTextFieldContent
          className={classes.question}
          containerClassName={classes.question_container}
          placeholder={focusedQuestionData?.placeholder}
          value={focusedQuestionData?.name}
          onChange={handleQuestionChange}
          edit={true}
          inputClasses={{
            root: classes.editable_input_root,
          }}
          helperText={`Question should not be more than 200 characters.`}
          maxLength={200}
        />
      )}
      {isQuiz && (
        <div className={classes.ckeditor_container}>
          {showCkEditor && (
            <CKEditor
              config={{
                editorplaceholder: 'Write your question here.',
                allowedContent: true,
                height: '150px',
                width: '100%',
                dialog_noConfirmCancel: true,
                toolbar: toolbarConfig,
                extraPlugins: [
                  'font',
                  'colorbutton',
                  'justify',
                  'colordialog',
                  'uploadimage',
                  'filebrowser',
                  'image2',
                  'selectall',
                  'editorplaceholder',
                ],
                removeButtons: [],
              }}
              initData={questions[focusedQuestion]?.name}
              onChange={event => {
                setFieldValue(`questions[${focusedQuestion}].name`, event?.editor?.getData()?.trim());
                setFieldValue(`questions[${focusedQuestion}].description`, event?.editor?.getData()?.trim());
                setFieldValue(`isSaved`, false);
              }}
              onLoaded={() => {
                setIsEditorLoaded(true);
              }}
            />
          )}
        </div>
      )}

      <FieldArray name={`questions[${focusedQuestion}].options`}>
        {({ push, remove, form }) => {
          const { setFieldValue } = form;

          const addChoice = () => {
            if (focusedQuestionData?.questionType === questionTypes.multiple_choice) {
              setFieldValue(`isSaved`, false);
              const multiple_choice = { value: '', isCorrect: false };
              setFieldValue(`questions[${focusedQuestion}].options`, [
                ...focusedQuestionData?.options,
                multiple_choice,
              ]);
              // push(multiple_choice);
            } else if (focusedQuestionData?.questionType === questionTypes.dropdown) {
              setFieldValue(`isSaved`, false);
              const multiple_choice = { value: '', isCorrect: false };
              setFieldValue(`questions[${focusedQuestion}].options`, [
                ...focusedQuestionData?.options,
                multiple_choice,
              ]);
            } else if (focusedQuestionData?.questionType === questionTypes.ranking) {
              setFieldValue(`isSaved`, false);
              const ranking = { value: '' };
              push(ranking);
            }
          };

          const removeChoice = idx => {
            setFieldValue(`isSaved`, false);
            remove(idx);
          };

          return (
            <>
              <div className={classes.options_container}>
                <OptionsComponent
                  index={focusedQuestion}
                  question={focusedQuestionData}
                  setFieldValue={setFieldValue}
                  onRemoveChoice={removeChoice}
                  questionType={focusedQuestionData?.questionType}
                  formProps={formProps}
                />
              </div>
              <div
                className={classNames(classes.actions, {
                  [classes.actions_responsive]:
                    mobileView &&
                    focusedQuestionData?.questionType === questionTypes.dropdown &&
                    focusedQuestionData?.options.length > 0,
                })}
              >
                <div
                  className={classNames(classes.add_choice_btn_container, {
                    [classes.add_choice_btn_container_responsive]:
                      mobileView &&
                      focusedQuestionData?.options?.length > 0 &&
                      focusedQuestionData?.questionType === questionTypes.dropdown,
                  })}
                >
                  {(isQuiz || isForm) &&
                    focusedQuestionData?.questionType !== questionTypes.short_text &&
                    focusedQuestionData?.questionType !== questionTypes.rating && (
                      <Button
                        className={btnTitle === '+ Add an option' ? classes.add_option_btn : classes.add_choice_btn}
                        onClick={addChoice}
                      >
                        {btnTitle}
                      </Button>
                    )}

                  {isForm && focusedQuestionData?.questionType === questionTypes.short_text && (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                      <div className={classes.toggle_container}>
                        <div className={classes.heading}>Max characters</div>
                        <SlimSwitch className={classes.toggle_button}>
                          <StyledInput
                            type="checkbox"
                            onClick={() => {
                              setFieldValue(
                                `questions[${focusedQuestion}].options[0].isCorrect`,
                                !focusedQuestionData?.options[0]?.isCorrect,
                              );

                              if (focusedQuestionData?.options[0]?.isCorrect === true) {
                                setFieldValue(`questions[${focusedQuestion}].options[0].value`, '0');
                              }
                              setFieldValue(`isSaved`, false);
                            }}
                            checked={focusedQuestionData?.options[0]?.isCorrect}
                          />
                          <SlimStyledSlider className="slider round" />
                        </SlimSwitch>
                      </div>
                      {focusedQuestionData?.options[0]?.isCorrect && (
                        <>
                          <TextField
                            onChange={e => {
                              const regex = /^\d+$/;
                              if (regex.test(e.target.value) === false) {
                                setFieldValue(`questions[${focusedQuestion}].options[0].value`, '');
                              } else {
                                if (regex.test(e.target.value)) {
                                  setFieldValue(`questions[${focusedQuestion}].options[0].value`, e.target.value);
                                  setFieldValue(`isSaved`, false);
                                }
                              }
                            }}
                            value={focusedQuestionData?.options[0]?.value}
                            variant="outlined"
                            InputProps={{
                              classes: {
                                root: classes.max_character_input_root,
                                notchedOutline: classes.max_character_input_notchedOutline,
                              },
                            }}
                          />
                          {focusedQuestionData?.options[0]?.value === 0 ||
                            focusedQuestionData?.options[0]?.value === '0' ||
                            (focusedQuestionData?.options[0]?.value.length === 0 && (
                              <CommonErrorMessage message="Max character should be greater than 0" />
                            ))}
                        </>
                      )}
                    </div>
                  )}

                  {isForm && focusedQuestionData?.questionType === questionTypes.rating && (
                    <div className={classes.rating_dropdown_container}>
                      <Select
                        onChange={e => {
                          setFieldValue(`questions[${focusedQuestion}].options[${0}].value`, e.target.value);
                        }}
                        className={classes.select}
                        input={
                          <OutlinedInput
                            margin="dense"
                            classes={{
                              root: classes.input_root,
                              notchedOutline: classes.input_notchedOutline,
                            }}
                          />
                        }
                        value={focusedQuestionData?.options[0]?.value}
                        items={RATING_ITEMS}
                        classes={{
                          icon: classes.select_icon,
                        }}
                      />
                    </div>
                  )}

                  {/* {focusedQuestionData?.options?.length > 0 &&
                    focusedQuestionData?.questionType === questionTypes.dropdown && (
                      <div className={classes.options_count} onClick={addChoice}>
                        {focusedQuestionData?.options?.length} options in list
                      </div>
                    )} */}
                </div>

                {!mobileView ? (
                  <div className={classes.toggle_container}>
                    {focusedQuestionData?.questionType === questionTypes.multiple_choice && (
                      <>
                        <Tooltip
                          title={
                            isForm
                              ? 'Choose whether the question allows only one answer or multiple answers.'
                              : 'Choose whether the question allows only one correct answer or multiple correct answers.'
                          }
                          arrow
                        >
                          <InfoIcon style={{ marginLeft: '10px' }} htmlColor={colors.lightBrown} />
                        </Tooltip>
                        <div className={classes.heading}>
                          {isForm ? `Multiple Answers` : `Multiple correct answers`}
                        </div>
                        <SlimSwitch className={classes.toggle_button}>
                          <StyledInput
                            type="checkbox"
                            onClick={() => {
                              setFieldValue(
                                `questions[${focusedQuestion}].canSelectMultipleCorrectOptions`,
                                !focusedQuestionData?.canSelectMultipleCorrectOptions,
                              );

                              let updatedArray = focusedQuestionData.options.map(item => ({
                                ...item,
                                isCorrect: false,
                              }));
                              setFieldValue(`questions[${focusedQuestion}].options`, updatedArray);
                              setFieldValue(`isSaved`, false);
                            }}
                            checked={
                              focusedQuestionData?.canSelectMultipleCorrectOptions === undefined
                                ? false
                                : focusedQuestionData?.canSelectMultipleCorrectOptions
                            }
                          />
                          <SlimStyledSlider className="slider round" />
                        </SlimSwitch>
                      </>
                    )}
                  </div>
                ) : (
                  <div className={classes.toggle_container}>
                    <div className={classes.heading}>Make Required</div>
                    <SlimSwitch className={classes.toggle_button}>
                      <StyledInput
                        type="checkbox"
                        onClick={() => {
                          setFieldValue(`questions[${focusedQuestion}].isRequired`, !focusedQuestionData?.isRequired);
                          setFieldValue(`isSaved`, false);
                        }}
                        checked={focusedQuestionData?.isRequired}
                      />
                      <SlimStyledSlider className="slider round" />
                    </SlimSwitch>
                  </div>
                )}
              </div>
              {mobileView && focusedQuestionData?.questionType === questionTypes.multiple_choice && (
                <div className={classes.toggle_container}>
                  <div className={classes.heading}>{isForm ? `Multiple Answers` : `Multiple correct answers`}</div>
                  <SlimSwitch className={classes.toggle_button}>
                    <StyledInput
                      type="checkbox"
                      onClick={() => {
                        setFieldValue(
                          `questions[${focusedQuestion}].canSelectMultipleCorrectOptions`,
                          !focusedQuestionData?.canSelectMultipleCorrectOptions,
                        );

                        let updatedArray = focusedQuestionData.options.map(item => ({
                          ...item,
                          isCorrect: false,
                        }));
                        setFieldValue(`questions[${focusedQuestion}].options`, updatedArray);
                        setFieldValue(`isSaved`, false);
                      }}
                      checked={
                        focusedQuestionData?.canSelectMultipleCorrectOptions === undefined
                          ? false
                          : focusedQuestionData?.canSelectMultipleCorrectOptions
                      }
                    />
                    <SlimStyledSlider className="slider round" />
                  </SlimSwitch>
                </div>
              )}
              <div className={classes.actions}>
                <div></div>
                <div
                  className={`${classes.save_and_add_btn_container} ${
                    mobileView ? classes.save_and_add_btn_container_responsive : ''
                  }`}
                >
                  {checkDisabled() ? (
                    <Tooltip
                      title={
                        isForm
                          ? 'Please ensure form has multiple options before saving the form.'
                          : 'Please ensure question has multiple options and one marked as the correct answer before saving the quiz.'
                      }
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                    >
                      <div>
                        <Button
                          className={`${classes.save_and_next} ${mobileView ? classes.save_and_next_responsive : ''}`}
                          disabled={checkDisabled()}
                          onClick={() => {
                            setLoader(true);
                            saveQuiz();
                          }}
                        >
                          {loader ? <CircularProgress size={20} /> : `Save & Add Next Question`}
                        </Button>
                      </div>
                    </Tooltip>
                  ) : (
                    <Button
                      className={`${classes.save_and_next} ${mobileView ? classes.save_and_next_responsive : ''}`}
                      disabled={
                        checkDisabled() ||
                        (focusedQuestionData?.options[0]?.isCorrect === true &&
                          (focusedQuestionData?.options[0]?.value === 0 ||
                            focusedQuestionData?.options[0]?.value === '0' ||
                            focusedQuestionData?.options[0]?.value.length === 0))
                      }
                      onClick={() => {
                        setLoader(true);
                        saveQuiz();
                      }}
                    >
                      <div style={{ width: '220px' }}>
                        {loader ? <CircularProgress size={15} /> : `Save & Add Next Question`}
                      </div>
                    </Button>
                  )}
                  {isQuestionTypesPanelOpen && (
                    <QuestionTypesPanel
                      actions={actionsList}
                      onClose={() => {
                        setIsQuestionTypesPanelOpen(false);
                      }}
                      containerClassName={classNames(classes.question_type_panel, {
                        [classes.question_type_panel_responsive]: mobileView,
                      })}
                      contentBody={true}
                    />
                  )}
                </div>
              </div>
              {/* {isAddChoicesModalOpen && (
                <AddChoicesModal
                  isOpen={isAddChoicesModalOpen}
                  onClose={() => {
                    setIsAddChoicesModalOpen(false);
                    setFieldValue(`isSaved`, false);
                  }}
                  choices={focusedQuestionData?.options}
                  onSubmit={choices => {
                    setFieldValue(`questions[${focusedQuestion}].options`, choices);
                    setFieldValue(`isSaved`, false);
                  }}
                  mobileView={mobileView}
                  docType={formProps?.values?.type}
                />
              )} */}
              <Modal
                isCreatingContribution={true}
                isOpen={showErrorModal}
                headerBorder={true}
                onCancel={() => {
                  setshowErrorModal(false);
                }}
                title="Question Data Missing"
                disableCancel
                disableFooter={true}
                type={'Quizzes'}
              >
                {errormessage?.name === null ? (
                  <div style={{ marginTop: '30px' }}>One or more required fields are missing in questions.</div>
                ) : (
                  <div style={{ marginTop: '30px' }}>
                    One or more required fields are missing in question
                    <span style={{ fontWeight: '700' }}>{`${convertToPlainText(errormessage?.name).trim()}.`}</span>
                  </div>
                )}
              </Modal>
              <Modal
                isCreatingContribution={true}
                isOpen={switchingQuestionModal}
                onCancel={() => {
                  setSwitchingQuestionModal(false);
                  setSelectedType(null);
                }}
                title="Switching Question Type"
                type={'Quizzes'}
                submitTitle={'Ok'}
                cancelTitle={'Cancel'}
                onSubmit={() => {
                  setSwitchingQuestionModal(false);
                  setFieldValue(`questions[${focusedQuestion}].questionType`, selectedType);
                  setFieldValue(`questions[${focusedQuestion}].type`, selectedType);
                  // setFieldValue(`questions[${focusedQuestion}].name`, focusedQuestionData?.name || '');
                  if (selectedType === questionTypes.multiple_choice) {
                    setFieldValue(`questions[${focusedQuestion}].options`, [{ value: '', isCorrect: false }]);
                  }
                  if (selectedType === questionTypes.dropdown) {
                    setFieldValue(`questions[${focusedQuestion}].options`, [{ value: '', isCorrect: false }]);
                  }
                  if (selectedType === questionTypes.ranking) {
                    setFieldValue(`questions[${focusedQuestion}].options`, [{ value: '', isCorrect: false }]);
                  }
                  if (selectedType === questionTypes.rating) {
                    setFieldValue(`questions[${focusedQuestion}].options`, [{ value: '5', isCorrect: false }]);
                  }
                  if (selectedType === questionTypes.short_text) {
                    setFieldValue(`questions[${focusedQuestion}].options`, [{ value: '', isCorrect: false }]);
                  }

                  setShowCkEditor(false);
                  setTimeout(() => {
                    setShowCkEditor(true);
                  }, 500);
                }}
              >
                Switching the question type will erase the options and their content. Are you sure you want to proceed?
              </Modal>
            </>
          );
        }}
      </FieldArray>
      <Modal
        isOpen={showGradeModal}
        onSubmit={() => {
          setShowGradeModal(false);
          setLoader(false);
        }}
        title={`Passing Grade Required`}
        disableConfirm={true}
        cancelTitle={'Close'}
        onCancel={() => {
          setShowGradeModal(false);
        }}
        mobileView={mobileView}
        modalType={'Quizes'}
        maxWidth={mobileView ? '350px' : null}
        disableFooter={true}
      >
        <div style={{ marginTop: '20px' }}>
          You have activated passing grade required and your passing grade should be greater than 0.
        </div>
      </Modal>
    </StyledContainer>
  );
};

export default ContentBody;
