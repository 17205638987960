import React, { useEffect, useRef, useState } from 'react';
import { Grid, Radio, TextField, FormControlLabel, Typography, makeStyles } from '@material-ui/core';
import Select from 'components/UI/Select';
import * as S from '../ShareModal/Content/styled';
import InputEmailsComponent from 'components/UI/InputEmailsComponent';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { Autocomplete } from '@mui/material';
import { determineDarkThemedColorToUse } from 'services/contributions.service';
import { ArrowDropDown } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  input_root: {
    padding: '4px !important',
  },
  inputRoot: {
    paddingRight: '0px !important',
  },
  endAdornment: {
    color: 'black !important',

    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  endAdornmentDark: {
    color: 'white !important',

    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}));
const UserSelectionInputFields = ({
  isExistingClient,
  setIsExistingClient,
  themedColor,
  selectOptions,
  formik,
  emailsList,
  setEmailsList,
  selectedClient,
  onChangeClient,
  filterClient,
  paymentInfo,
  onOptionSelect,
  closeDropDown,
  meetingForm,
  setMeetingForm,
  isDarkThemeEnabled,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const classes = useStyles();
  const dropdownRef = useRef(null);
  useEffect(() => {
    if (isDropdownVisible) {
      onOptionSelect(false);
    }
  }, [closeDropDown, isDropdownVisible]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const handleOutsideClick = e => {
    if (!isDropdownVisible) {
      return;
    }
    if (
      dropdownRef &&
      dropdownRef?.current &&
      !e?.target?.className?.includes('dropdownList') &&
      !dropdownRef?.current?.contains(e.target)
    ) {
      setIsDropdownVisible(false);
    }
  };

  return (
    <>
      <Grid container>
        <div className="radio-icon-style">
          <FormControlLabel
            value="client"
            className="modal-radio-styling"
            control={
              <Radio
                color="primary"
                checked={isExistingClient}
                onChange={() => {
                  formik.setErrors({});
                  formik.setFieldValue('email', '');
                  formik.setFieldValue('name', '');
                  formik.setFieldValue('price', '');
                  setEmailsList('');
                  setMeetingForm({
                    ...meetingForm,
                    name: '',
                    email: '',
                  });
                  setIsExistingClient(!isExistingClient);
                  formik.setErrors({});
                  formik.setTouched({ price: false });
                }}
              />
            }
            label={<span style={!isExistingClient ? { color: 'gray' } : {}}> Existing Client</span>}
          />
        </div>
        <Grid container className="AutoSelectField" style={{ marginLeft: 20 }}>
          {selectOptions?.length === 0 ? (
            <div style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>No Available Clients</div>
          ) : (
            <>
              <Grid
                item
                xs={12}
                style={{ cursor: 'pointer' }}
                onClick={e => {
                  e.stopPropagation();
                  setIsDropdownVisible(!isDropdownVisible);
                  formik.setErrors({});
                  formik.setTouched({ price: false });
                }}
              >
                <Autocomplete
                  name="client"
                  fullWidth
                  classes={{
                    popper: isDarkThemeEnabled ? 'dark-theme-enabled' : '',
                    popupIndicator: isDarkThemeEnabled ? classes.endAdornmentDark : classes.endAdornment,
                    input: classes.input_root,
                    inputRoot: isDarkThemeEnabled && classes.inputRoot,
                  }}
                  className={'AutocompleteField'}
                  clearIcon={false}
                  error={false}
                  ref={dropdownRef}
                  open={isDropdownVisible}
                  value={formik?.values?.client}
                  noOptionsText={
                    <p
                      className="dropdownList"
                      style={{
                        margin: '5px',
                        color: isDarkThemeEnabled ? 'white' : 'black',
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontSize: '0.875rem',
                        fontFamily: 'Avenir',
                        letterSpacing: '0.4px',
                      }}
                    >
                      No Client Found
                    </p>
                  }
                  style={{ whiteSpace: 'break-spaces' }}
                  getOptionLabel={option => {
                    if (option) {
                      return `(${option?.clientName}) - ${option?.clientEmail}`;
                    } else return '';
                  }}
                  renderOption={(props, option) =>
                    option?.clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/) ? (
                      <div
                        className="dropdownList"
                        style={{
                          borderTop: '1px solid #f2f2f2',
                          cursor: 'pointer',
                          color: isDarkThemeEnabled ? 'white' : 'black',
                        }}
                        ref={dropdownRef}
                        onClick={() => {
                          setEmailsList('');
                          setIsExistingClient(true);
                          setIsDropdownVisible(!isDropdownVisible);
                          onChangeClient(option);
                          formik.setErrors({});
                          formik.setTouched({ price: false });
                          formik.setFieldValue('email', '');
                          formik.setFieldValue('name', '');
                          formik.setFieldValue('price', '');
                          setMeetingForm({
                            ...meetingForm,
                            name: '',
                            email: '',
                            clientId: option.value,
                            clientName: option.clientName.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)
                              ? option.clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)[1]
                              : option.clientName,
                          });
                        }}
                      >
                        <p
                          className="dropdownList"
                          style={{
                            margin: '10px',
                            color: 'rgba(0, 0, 0, 0.87)',
                            color: isDarkThemeEnabled ? 'white' : 'black',
                            fontSize: '0.875rem',
                            fontFamily: 'Avenir',
                            letterSpacing: '0.4px',
                          }}
                        >
                          ({option?.clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)[1]}{' '}
                          <span className="dropdownList" style={{ color: isDarkThemeEnabled ? 'white' : 'gray' }}>
                            ({option?.clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)[2]})
                          </span>
                          ) - {option?.clientEmail}
                        </p>
                      </div>
                    ) : (
                      <div
                        className="dropdownList"
                        style={{ borderTop: '1px solid #f2f2f2', cursor: 'pointer' }}
                        onClick={() => {
                          setEmailsList('');
                          formik.setFieldValue('email', '');
                          formik.setFieldValue('name', '');
                          formik.setFieldValue('price', '');
                          setMeetingForm({
                            ...meetingForm,
                            name: '',
                            email: '',
                            clientId: option.value,
                            clientName: option.clientName.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)
                              ? option.clientName?.match(/^(.*?)\s*\((.*?)\)\s*(.*)$/)[1]
                              : option.clientName,
                          });
                          setIsDropdownVisible(!isDropdownVisible);
                          setIsExistingClient(true);
                          onChangeClient(option);
                        }}
                      >
                        <p
                          className="dropdownList"
                          style={{
                            margin: '10px',
                            color: 'rgba(0, 0, 0, 0.87)',
                            color: isDarkThemeEnabled ? 'white' : 'black',
                            fontSize: '0.875rem',
                            fontFamily: 'Avenir',
                            letterSpacing: '0.4px',
                          }}
                        >
                          ({option?.clientName}) - {option?.clientEmail}
                        </p>
                      </div>
                    )
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Select Client"
                      onClick={() => {
                        if (!isDropdownVisible) {
                          setIsDropdownVisible(true);
                          formik.setErrors({});
                        }
                      }}
                      classes={{
                        root: classes.input_root,
                      }}
                    />
                  )}
                  options={selectOptions}
                  disabled={!isExistingClient}
                />
                {isExistingClient && formik?.touched?.client && formik?.errors?.client && (
                  <div>
                    <CommonErrorMessage message={'Client is required'} align="left" color="red" />
                  </div>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: '16px' }}>
        <div className="radio-icon-style">
          <FormControlLabel
            value="Email"
            className="modal-radio-styling"
            control={
              <Radio
                disabled={
                  paymentInfo?.paymentOptions?.length === 1 &&
                  paymentInfo?.paymentOptions?.includes('FreeSessionsPackage')
                }
                color="primary"
                checked={!isExistingClient}
                onChange={() => {
                  formik.setFieldValue('client', '');
                  formik.setFieldValue('price', '');
                  if (
                    paymentInfo?.paymentOptions?.length === 1 &&
                    paymentInfo?.paymentOptions?.includes('FreeSessionsPackage')
                  ) {
                  } else {
                    formik.setErrors({});
                    setIsExistingClient(!isExistingClient);
                  }
                  setMeetingForm({
                    ...meetingForm,
                    client: '',
                  });
                }}
              />
            }
            label={<span style={isExistingClient ? { color: 'gray' } : {}}>New Client Email</span>}
          />
        </div>
        <>
          <Grid container space={4} style={{ marginBottom: '15px', marginLeft: '5px' }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              {/* <S.SendEmailBlock style={{ color: themedColor }}> */}
              <TextField
                className={
                  isDarkThemeEnabled ? 'darkModeEnabled coach-client-name-margins' : 'coach-client-name-margins'
                }
                id="standard-full-width"
                margin="normal"
                fullWidth
                disabled={isExistingClient}
                type="email"
                placeholder="example@cohere.com"
                onChange={e => {
                  formik.setFieldValue('email', e?.target?.value);
                  formik.setFieldValue('price', '');
                  setEmailsList(e?.target?.value);
                }}
                value={formik.values.email}
                InputLabelProps={{
                  shrink: true,
                }}
                label=" "
              />
              {/* </S.SendEmailBlock> */}
              {!isExistingClient && formik?.touched?.email && formik?.errors?.email && (
                <div style={{ marginLeft: '10px' }}>
                  <CommonErrorMessage message={formik?.errors?.email} align="left" color="red" />
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                value={formik?.values?.name}
                onChange={e => {
                  formik.setFieldValue('name', e?.target?.value);
                  formik.setFieldValue('price', '');
                  setMeetingForm({
                    ...meetingForm,
                    name: e?.target?.value,
                  });
                }}
                id="standard-full-width"
                placeholder="Example: John Doe"
                fullWidth
                className="coach-client-name-margins"
                label={<span style={isExistingClient ? { color: 'gray' } : {}}>New Client Name</span>}
                disabled={isExistingClient}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
              />
              {!isExistingClient && formik?.touched?.name && formik?.errors?.name && (
                <div style={{ marginLeft: '10px' }}>
                  <CommonErrorMessage message={'Name is required'} align="left" color="red" />
                </div>
              )}
            </Grid>
          </Grid>
        </>
      </Grid>
    </>
  );
};

export default UserSelectionInputFields;
