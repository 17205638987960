import * as yup from 'yup';

import { PAYMENT_OPTIONS } from 'constants.js';
import moment from 'moment';
import * as Yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { otherwise } from 'ramda';
import { isValidLink } from 'utils/utils';

const MAX_SPLIT_NUMBERS = 12;
const livePaymentValidationSchema = yup.object().shape({
  cost: yup.number().when('paymentOptions', {
    is: values => values.includes('EntireCourse') || values.includes('SplitPayments'),
    then: yup
      .number()
      .min(1, 'Value should be more than 1')
      .integer('Please only enter numbers')
      .required('This is a required field'),
    otherwise: yup.number().nullable(),
  }),

  splitNumbers: yup
    .number()
    .min(2)
    .max(MAX_SPLIT_NUMBERS)
    .integer('please only enter numbers')
    .required('This is a required field'),
  paymentOptions: yup
    .array()
    .of(yup.mixed().oneOf(['Free', 'EntireCourse', 'SplitPayments']))
    .min(1),
  coachPaysStripeFee: Yup.bool(),
});
const liveSessionsValidationSchema = yup.object().shape({
  enrollment: yup.object().shape({
    fromDate: yup.date().typeError('Field must not be empty').required(),
    toDate: yup
      .date()
      .typeError('Field must not be empty')
      .min(yup.ref('fromDate'), 'This time must be later than from date')
      .required(),
  }),
  sessions: yup.array().of(
    yup.object().shape({
      minParticipantsNumber: yup
        .number()
        .min(1, ({ min }) => `Value should be at least ${min}`)
        .max(yup.ref('maxParticipantsNumber'), 'Value should be less than max')
        .required('This is a required field'),
      maxParticipantsNumber: yup
        .number()
        // .max(100, 'Value should not exceed ${max}')
        .min(yup.ref('minParticipantsNumber'), 'Value should be more than min')
        .required('This is a required field'),
      title: yup
        .string()
        .strict(true)
        .min(2, ({ min }) => `Value should be longer than ${min} characters`)
        .required('This is a required field'),
      isPrerecorded: yup.boolean(),
      sessionTimes: yup
        .array()
        .when('isPrerecorded', {
          is: isPrerecorded => !isPrerecorded,
          then: yup.array().of(
            yup.object().shape({
              startTime: yup.date().required(),
              endTime: yup
                .date()
                .when('prerecordedSession', {
                  is: false,
                  then: yup.date().min(yup.ref('startTime'), 'This time must be later than start time'),
                })
                .required(),
            }),
          ),
          otherwise: yup.array().of(
            yup.object().shape({
              selfPacedContentType: yup.string().nullable(),
              subCategoryName: yup.string().max(50, 'Cannot Exceed 50 Characters').nullable(),
              embeddedVideoUrl: yup
                .string()
                .test('isValidURL', 'Invalid Link', value => {
                  if (value) {
                    return isValidLink(value);
                  }
                  return true;
                })
                .nullable(),

              startTime: yup.date().required(),
              prerecordedSession: yup.object().when(['embeddedVideoUrl', 'selfPacedContentType'], {
                is: (embeddedVideoUrl, selfPacedContentType) =>
                  selfPacedContentType?.includes('Video') && !embeddedVideoUrl,
                then: yup
                  .object()
                  .required('Please upload self-paced content or embed a video link, or delete this module to proceed.')
                  .nullable(),
                otherwise: yup.object().nullable(),
              }),
              selfPacedContentAsHtml: yup.string().when('selfPacedContentType', {
                is: selfPacedContentType => selfPacedContentType && selfPacedContentType.includes('Text'),
                then: yup.string().min(1, 'Please add text content, or delete this module to proceed.').required(),
                otherwise: yup.string().nullable(),
              }),
              endTime: yup
                .date()
                .when('prerecordedSession', {
                  is: false,
                  then: yup.date().min(yup.ref('startTime'), 'This time must be later than start time'),
                })
                .required(),
              ignoreDateAvailable: yup.boolean(),
              isExpireDateOptionSelected: yup.boolean(),
              isExpireDateEnabled: yup.boolean(),
              isSpecificDateEnabled: yup.boolean(),
              selfPacedContentAvailableDate: yup.date().nullable(),
              noOfDaysForSpecificDate: yup.number().nullable(),
              noOfDaysForExpirationDateEnabled: yup.boolean(),
              noOfDaysForSpecificDateEnabled: yup.boolean(),
              selfPacedContentExpirationDate: yup
                .date()
                .nullable()
                .when(
                  ['ignoreDateAvailable', 'isExpireDateOptionSelected', 'isExpireDateEnabled', 'isSpecificDateEnabled'],
                  {
                    is: (
                      ignoreDateAvailable,
                      isExpireDateOptionSelected,
                      isExpireDateEnabled,
                      isSpecificDateEnabled,
                    ) => {
                      return (
                        ignoreDateAvailable &&
                        isExpireDateOptionSelected &&
                        isExpireDateEnabled &&
                        isSpecificDateEnabled
                      );
                    },
                    then: yup.date().test('is-greater', 'Must be after Date Available.', function (value) {
                      const { selfPacedContentAvailableDate } = this.parent;
                      const expirationDate = moment(value).startOf('day');
                      const availableDate = moment(selfPacedContentAvailableDate).startOf('day');

                      return expirationDate && availableDate && moment(expirationDate).isAfter(availableDate);
                    }),
                  },
                ),
              noOfDaysForExpirationDate: yup
                .number()
                .when(['noOfDaysForExpirationDateEnabled', 'noOfDaysForSpecificDateEnabled'], {
                  is: (noOfDaysForExpirationDateEnabled, noOfDaysForSpecificDateEnabled) => {
                    return noOfDaysForExpirationDateEnabled && noOfDaysForSpecificDateEnabled;
                  },
                  then: yup
                    .number()
                    .test('is-greater-number', 'Expiration Date must be after Date Available.', function (val) {
                      const { noOfDaysForSpecificDate } = this.parent;
                      return val > noOfDaysForSpecificDate;
                    }),
                }),
            }),
          ),
        })
        .min(1, 'Please add a session time')
        .required(),
    }),
  ),
  // .min(1, 'Please add a session time')
  // .required(),
});

const liveSessionsValidationSchemaEditing = yup.object().shape({
  sessions: yup.array().of(
    yup.object().shape({
      minParticipantsNumber: yup
        .number()
        .min(1, ({ min }) => `Value should be at least ${min}`)
        .max(yup.ref('maxParticipantsNumber'), 'Value should be less than max')
        .required('This is a required field'),
      maxParticipantsNumber: yup
        .number()
        // .max(100, 'Value should not exceed ${max}')
        .min(yup.ref('minParticipantsNumber'), 'Value should be more than min')
        .required('This is a required field'),
      title: yup
        .string()
        .strict(true)
        .min(2, ({ min }) => `Value should be longer than ${min} characters`)
        .required('This is a required field'),
      isPrerecorded: yup.boolean(),
      sessionTimes: yup
        .array()
        .when('isPrerecorded', {
          is: isPrerecorded => !isPrerecorded,
          then: yup.array().of(
            yup.object().shape({
              startTime: yup.date().required(),
              endTime: yup
                .date()
                .when('prerecordedSession', {
                  is: false,
                  then: yup.date().min(yup.ref('startTime'), 'This time must be later than start time'),
                })
                .required(),
            }),
          ),
          otherwise: yup.array().of(
            yup.object().shape({
              selfPacedContentType: yup.string().nullable(),
              subCategoryName: yup.string().max(50, 'Cannot Exceed 50 Characters').nullable(),
              embeddedVideoUrl: yup
                .string()
                .test('isValidURL', 'Invalid Link', value => {
                  if (value) {
                    return isValidLink(value);
                  }
                  return true;
                })
                .nullable(),

              startTime: yup.date().required(),
              prerecordedSession: yup.object().when(['embeddedVideoUrl', 'selfPacedContentType'], {
                is: (embeddedVideoUrl, selfPacedContentType) =>
                  selfPacedContentType?.includes('Video') && !embeddedVideoUrl,
                then: yup
                  .object()
                  .required('Please upload self-paced content or embed a video link, or delete this module to proceed.')
                  .nullable(),
                otherwise: yup.object().nullable(),
              }),
              selfPacedContentAsHtml: yup.string().when('selfPacedContentType', {
                is: selfPacedContentType => selfPacedContentType && selfPacedContentType.includes('Text'),
                then: yup.string().min(1, 'Please add text content, or delete this module to proceed.').required(),
                otherwise: yup.string().nullable(),
              }),
              endTime: yup
                .date()
                .when('prerecordedSession', {
                  is: false,
                  then: yup.date().min(yup.ref('startTime'), 'This time must be later than start time'),
                })
                .required(),
              ignoreDateAvailable: yup.boolean(),
              isExpireDateOptionSelected: yup.boolean(),
              isExpireDateEnabled: yup.boolean(),
              isSpecificDateEnabled: yup.boolean(),
              selfPacedContentAvailableDate: yup.date().nullable(),
              noOfDaysForSpecificDate: yup.number().nullable(),
              noOfDaysForExpirationDateEnabled: yup.boolean(),
              noOfDaysForSpecificDateEnabled: yup.boolean(),
              selfPacedContentExpirationDate: yup
                .date()
                .nullable()
                .when(
                  ['ignoreDateAvailable', 'isExpireDateOptionSelected', 'isExpireDateEnabled', 'isSpecificDateEnabled'],
                  {
                    is: (
                      ignoreDateAvailable,
                      isExpireDateOptionSelected,
                      isExpireDateEnabled,
                      isSpecificDateEnabled,
                    ) => {
                      return (
                        ignoreDateAvailable &&
                        isExpireDateOptionSelected &&
                        isExpireDateEnabled &&
                        isSpecificDateEnabled
                      );
                    },
                    then: yup.date().test('is-greater', 'Must be after Date Available.', function (value) {
                      const { selfPacedContentAvailableDate } = this.parent;
                      const expirationDate = moment(value).startOf('day');
                      const availableDate = moment(selfPacedContentAvailableDate).startOf('day');

                      return expirationDate && availableDate && moment(expirationDate).isAfter(availableDate);
                    }),
                  },
                ),
              noOfDaysForExpirationDate: yup
                .number()
                .when(['noOfDaysForExpirationDateEnabled', 'noOfDaysForSpecificDateEnabled'], {
                  is: (noOfDaysForExpirationDateEnabled, noOfDaysForSpecificDateEnabled) => {
                    return noOfDaysForExpirationDateEnabled && noOfDaysForSpecificDateEnabled;
                  },
                  then: yup
                    .number()
                    .test('is-greater-number', 'Expiration Date must be after Date Available.', function (val) {
                      const { noOfDaysForSpecificDate } = this.parent;
                      return val > noOfDaysForSpecificDate;
                    }),
                }),
            }),
          ),
        })
        .min(1, 'Please add a session time')
        .required(),
    }),
  ),
  // .min(1, 'Please add a session time')
  // .required(),
  displaySessionTimeDates: yup.bool(),
});

Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
  return this.test('unique', message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

const phoneUtil = PhoneNumberUtil.getInstance();
const validatePhoneNumber = phone => {
  if (phone === '') {
    return false;
  }
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const oneToOneSessionsValidationSchema = yup.object().shape({
  sessions: yup
    .array()
    .of(
      yup.object().shape({
        durations: yup.number().required('This is a required field'),
        isPrerecorded: yup.boolean(),
        sessionTimes: yup.array().when('isPrerecorded', {
          is: isPrerecorded => !isPrerecorded,
          then: yup.array().of(
            yup.object().shape({
              startDate: yup.date().required(),
              endDate: yup
                .date()
                .when('startDate', st => {
                  return yup.date().min(moment(st).subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss'));
                })
                .required(),
              startTime: yup.date().required(),

              endTime: yup.date().min(yup.ref('startTime'), 'This time must be later than start time').required(),
            }),
          ),
          otherwise: yup.array().of(
            yup.object().shape({
              // startTime: yup.date().required(),
              prerecordedSession: yup.object().when(['embeddedVideoUrl', 'selfPacedContentType'], {
                is: (embeddedVideoUrl, selfPacedContentType) =>
                  selfPacedContentType?.includes('Video') && !embeddedVideoUrl,
                then: yup
                  .object()
                  .required('Please upload self-paced content or embed a video link, or delete this module to proceed.')
                  .nullable(),
                otherwise: yup.object().nullable(),
              }),
              startDate: yup.date().required(),
              endDate: yup
                .date()
                .when('startDate', st => {
                  return yup.date().min(moment(st).subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss'));
                })
                .required(),
              startTime: yup.date().required(),

              endTime: yup.date().min(yup.ref('startTime'), 'This time must be later than start time').required(),
            }),
          ),
        }),
      }),
    )
    .min(1)
    .required(),
  isPhoneNumberAdded: Yup.bool(),
  isCoachNumberAdded: Yup.bool(),
  coachPhoneNumber: Yup.string().when(['isPhoneNumberAdded', 'isCoachNumberAdded'], {
    is: (isPhoneNumberAdded, isCoachNumberAdded) => isPhoneNumberAdded && isCoachNumberAdded,
    then: Yup.string()
      .required('Phone number is required')
      .test('valid-phone', 'Invalid phone number', value => {
        return validatePhoneNumber(value);
      }),
  }),
  enrollmentForm: Yup.object().shape({
    isCustomEnrollmentFormAdded: yup.bool(),
  }),
  isAccountNeeded: Yup.boolean().default(true),
});

const enrollmentFormSchema = Yup.object().shape({
  enrollmentForm: Yup.object().shape({
    isCustomEnrollmentFormAdded: yup.bool(),
    isEditMode: yup.boolean(),
    enrollmentFormViewModel: yup
      .object()
      .shape({
        isCustomEnrollmentFormAdded: Yup.bool(),
        isEditMode: yup.boolean(),
        questions: Yup.array().when(['isCustomEnrollmentFormAdded', 'isEditMode'], {
          is: (isCustomEnrollmentFormAdded, isEditMode) => isCustomEnrollmentFormAdded && !isEditMode,
          then: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string().required('Question is a required field'),
                isOptional: Yup.bool().required('Is optional is a required field'),
                questionType: Yup.string().required('Question type is a required field'),
                options: Yup.array().when('questionType', {
                  is: questionType => ['CheckBox', 'Radio'].includes(questionType),
                  then: Yup.array()
                    .of(Yup.string().required('Option is a required field'))
                    .min(1, 'Add atleast 1 option')
                    .unique('Options must be unique')
                    .required('Valid Options is required'),
                }),
              }),
            )
            .min(1, 'At least add 1 question for the enrollment form')
            .max(10, 'Enrollment form can`t have more than 10 questions')
            .required('Please add atleast 1 question to the form.')
            .test('unique-email-type', 'Only one question of type Email is allowed', function (questions) {
              const typeCounts = {};

              questions.forEach(question => {
                const { questionType } = question;
                if (questionType === 'Email') {
                  typeCounts[questionType] = (typeCounts[questionType] || 0) + 1;
                }
              });

              // Check if any of the allowed types has more than one question
              return Object.values(typeCounts).every(count => count <= 1);
            })
            .test('unique-phone-no-type', 'Only one question of type Phone number is allowed', function (questions) {
              const typeCounts = {};

              questions.forEach(question => {
                const { questionType } = question;
                if (questionType === 'PhoneNo') {
                  typeCounts[questionType] = (typeCounts[questionType] || 0) + 1;
                }
              });

              // Check if any of the allowed types has more than one question
              return Object.values(typeCounts).every(count => count <= 1);
            }),
          otherwise: Yup.array().of(
            Yup.object().shape({
              name: Yup.string(),
              isOptional: Yup.bool(),
              questionType: Yup.string(),
              options: Yup.array().when('questionType', {
                is: questionType => ['CheckBox', 'Radio'].includes(questionType),
                then: Yup.array().of(Yup.string()).min(1, 'Add atleast 1 option').unique('options must be unique'),
              }),
            }),
          ),
        }),
        userId: Yup.string().required(),
        contributionId: Yup.string().required(),
        contributionName: Yup.string().required(),
      })
      .required(),
  }),
});

const membershipSessionsValidationSchema = yup.object().shape({
  enrollment: yup.object().shape({
    anyTime: yup.bool(),
    fromDate: yup.date().typeError('Field must not be empty').required(),
    toDate: yup
      .date()
      .typeError('Field must not be empty')
      .min(yup.ref('fromDate'), 'This time must be later than from date')
      .required(),
  }),
  sessions: yup.array().of(
    yup.object().shape({
      minParticipantsNumber: yup
        .number()
        .min(1, ({ min }) => `Value should be at least ${min}`)
        .max(yup.ref('maxParticipantsNumber'), 'Value should be less than max')
        .required('This is a required field'),
      maxParticipantsNumber: yup
        .number()
        // .max(100, 'Value should not exceed ${max}')
        .min(yup.ref('minParticipantsNumber'), 'Value should be more than min')
        .required('This is a required field'),
      title: yup
        .string()
        .strict(true)
        .min(2, ({ min }) => `Value should be longer than ${min} characters`)
        .required('This is a required field'),
      isPrerecorded: yup.boolean(),
      sessionTimes: yup
        .array()
        .when('isPrerecorded', {
          is: isPrerecorded => !isPrerecorded,
          then: yup.array().of(
            yup.object().shape({
              startTime: yup.date().required(),
              endTime: yup.date().min(yup.ref('startTime'), 'This time must be later than start time').required(),
            }),
          ),
          otherwise: yup.array().of(
            yup.object().shape({
              subCategoryName: yup.string().max(50, 'Cannot Exceed 50 Characters').nullable(),
              embeddedVideoUrl: yup
                .string()
                .test('isValidURL', 'Invalid Link', value => {
                  if (value) {
                    return isValidLink(value);
                  }
                  return true;
                })
                .nullable(),
              prerecordedSession: yup.object().when(['embeddedVideoUrl', 'selfPacedContentType'], {
                is: (embeddedVideoUrl, selfPacedContentType) =>
                  selfPacedContentType?.includes('Video') && !embeddedVideoUrl,
                then: yup
                  .object()
                  .required('Please upload self-paced content or embed a video link, or delete this module to proceed.')
                  .nullable(),
                otherwise: yup.object().nullable(),
              }),
              // startDate: yup.date().required(),
              startTime: yup.date().required(),
              endTime: yup.date().min(yup.ref('startTime'), 'This time must be later than start time').required(),
              ignoreDateAvailable: yup.boolean(),
              isExpireDateOptionSelected: yup.boolean(),
              isExpireDateEnabled: yup.boolean(),
              isSpecificDateEnabled: yup.boolean(),
              selfPacedContentAvailableDate: yup.date().nullable(),
              noOfDaysForSpecificDate: yup.number().nullable(),
              noOfDaysForExpirationDateEnabled: yup.boolean(),
              noOfDaysForSpecificDateEnabled: yup.boolean(),
              selfPacedContentExpirationDate: yup
                .date()
                .nullable()
                .when(
                  ['ignoreDateAvailable', 'isExpireDateOptionSelected', 'isExpireDateEnabled', 'isSpecificDateEnabled'],
                  {
                    is: (
                      ignoreDateAvailable,
                      isExpireDateOptionSelected,
                      isExpireDateEnabled,
                      isSpecificDateEnabled,
                    ) => {
                      return (
                        ignoreDateAvailable &&
                        isExpireDateOptionSelected &&
                        isExpireDateEnabled &&
                        isSpecificDateEnabled
                      );
                    },
                    then: yup.date().test('is-greater', 'Must be after Date Available.', function (value) {
                      const { selfPacedContentAvailableDate } = this.parent;
                      const expirationDate = moment(value).startOf('day');
                      const availableDate = moment(selfPacedContentAvailableDate).startOf('day');

                      return expirationDate && availableDate && moment(expirationDate).isAfter(availableDate);
                    }),
                  },
                ),
              noOfDaysForExpirationDate: yup
                .number()
                .when(['noOfDaysForExpirationDateEnabled', 'noOfDaysForSpecificDateEnabled'], {
                  is: (noOfDaysForExpirationDateEnabled, noOfDaysForSpecificDateEnabled) => {
                    return noOfDaysForExpirationDateEnabled && noOfDaysForSpecificDateEnabled;
                  },
                  then: yup
                    .number()
                    .test('is-greater-number', 'Expiration Date must be after Date Available.', function (val) {
                      const { noOfDaysForSpecificDate } = this.parent;
                      return val > noOfDaysForSpecificDate;
                    }),
                }),
            }),
          ),
        })
        .min(1, 'Please add a session time')
        .required(),
    }),
  ),
  // .min(1, 'Please add a session time')
  // .required(),
});
const membershipSessionsValidationSchemaEditing = yup.object().shape({
  sessions: yup.array().of(
    yup.object().shape({
      minParticipantsNumber: yup
        .number()
        .min(1, ({ min }) => `Value should be at least ${min}`)
        .max(yup.ref('maxParticipantsNumber'), 'Value should be less than max')
        .required('This is a required field'),
      maxParticipantsNumber: yup
        .number()
        // .max(100, 'Value should not exceed ${max}')
        .min(yup.ref('minParticipantsNumber'), 'Value should be more than min')
        .required('This is a required field'),
      title: yup
        .string()
        .strict(true)
        .min(2, ({ min }) => `Value should be longer than ${min} characters`)
        .required('This is a required field'),
      isPrerecorded: yup.boolean(),
      sessionTimes: yup
        .array()
        .when('isPrerecorded', {
          is: isPrerecorded => !isPrerecorded,
          then: yup.array().of(
            yup.object().shape({
              startTime: yup.date().required(),
              endTime: yup.date().min(yup.ref('startTime'), 'This time must be later than start time').required(),
            }),
          ),
          otherwise: yup.array().of(
            yup.object().shape({
              subCategoryName: yup.string().max(50, 'Cannot Exceed 50 Characters').nullable(),
              embeddedVideoUrl: yup
                .string()
                .test('isValidURL', 'Invalid Link', value => {
                  if (value) {
                    return isValidLink(value);
                  }
                  return true;
                })
                .nullable(),
              prerecordedSession: yup.object().when(['embeddedVideoUrl', 'selfPacedContentType'], {
                is: (embeddedVideoUrl, selfPacedContentType) =>
                  selfPacedContentType?.includes('Video') && !embeddedVideoUrl,
                then: yup
                  .object()
                  .required('Please upload self-paced content or embed a video link, or delete this module to proceed.')
                  .nullable(),
                otherwise: yup.object().nullable(),
              }),
              // startDate: yup.date().required(),
              startTime: yup.date().required(),
              endTime: yup.date().min(yup.ref('startTime'), 'This time must be later than start time').required(),
            }),
          ),
          ignoreDateAvailable: yup.boolean(),
          isExpireDateOptionSelected: yup.boolean(),
          isExpireDateEnabled: yup.boolean(),
          isSpecificDateEnabled: yup.boolean(),
          selfPacedContentAvailableDate: yup.date().nullable(),
          noOfDaysForSpecificDate: yup.number().nullable(),
          noOfDaysForExpirationDateEnabled: yup.boolean(),
          noOfDaysForSpecificDateEnabled: yup.boolean(),
          selfPacedContentExpirationDate: yup
            .date()
            .nullable()
            .when(
              ['ignoreDateAvailable', 'isExpireDateOptionSelected', 'isExpireDateEnabled', 'isSpecificDateEnabled'],
              {
                is: (ignoreDateAvailable, isExpireDateOptionSelected, isExpireDateEnabled, isSpecificDateEnabled) => {
                  return (
                    ignoreDateAvailable && isExpireDateOptionSelected && isExpireDateEnabled && isSpecificDateEnabled
                  );
                },
                then: yup.date().test('is-greater', 'Must be after Date Available.', function (value) {
                  const { selfPacedContentAvailableDate } = this.parent;
                  const expirationDate = moment(value).startOf('day');
                  const availableDate = moment(selfPacedContentAvailableDate).startOf('day');

                  return expirationDate && availableDate && moment(expirationDate).isAfter(availableDate);
                }),
              },
            ),
          noOfDaysForExpirationDate: yup
            .number()
            .when(['noOfDaysForExpirationDateEnabled', 'noOfDaysForSpecificDateEnabled'], {
              is: (noOfDaysForExpirationDateEnabled, noOfDaysForSpecificDateEnabled) => {
                return noOfDaysForExpirationDateEnabled && noOfDaysForSpecificDateEnabled;
              },
              then: yup
                .number()
                .test('is-greater-number', 'Expiration Date must be after Date Available.', function (val) {
                  const { noOfDaysForSpecificDate } = this.parent;
                  return val > noOfDaysForSpecificDate;
                }),
            }),
        })
        .min(1, 'Please add a session time')
        .required(),
    }),
  ),
  // .min(1, 'Please add a session time')
  // .required(),
});
const membershipPaymentValidationSchema = yup.object().shape({
  paymentOptions: yup
    .array()
    .of(
      yup
        .mixed()
        .oneOf([
          PAYMENT_OPTIONS.PER_DAY,
          PAYMENT_OPTIONS.PER_WEEK,
          PAYMENT_OPTIONS.PER_MONTH,
          PAYMENT_OPTIONS.PER_YEAR,
          PAYMENT_OPTIONS.PACKAGE,
          PAYMENT_OPTIONS.FREE,
        ]),
    )
    .min(1),
  perMonthCost: yup.number().when('paymentOptions', {
    is: values => values.includes(PAYMENT_OPTIONS.PER_MONTH),
    then: yup
      .number()
      .min(1, 'Value should be more than 1')
      .integer('Please only enter numbers')
      .required('This is a required field'),
    otherwise: yup.number().nullable(),
  }),
  perYearCost: yup.number().when('paymentOptions', {
    is: values => values.includes(PAYMENT_OPTIONS.PER_YEAR),
    then: yup
      .number()
      .min(1, 'Value should be more than 1')
      .integer('Please only enter numbers')
      .required('This is a required field'),
    otherwise: yup.number().nullable(),
  }),
  membershipPackage: yup.object().when('paymentOptions', {
    is: values => values.includes(PAYMENT_OPTIONS.PACKAGE),
    then: yup
      .object()
      .required()
      .shape({
        duration: yup.number().required(),
        period: yup.string().required(),
        cost: yup
          .number()
          .min(1, 'Value should be more than 1')
          .integer('Please only enter numbers')
          .required('This is a required field'),
      }),
    otherwise: yup.object().nullable(),
  }),
});

const communitySessionsValidationSchema = yup.object({
  sessions: yup.array().of(
    yup.object({
      minParticipantsNumber: yup
        .number()
        .min(1, ({ min }) => `Value should be at least ${min}`)
        .max(yup.ref('maxParticipantsNumber'), 'Value should be less than max')
        .required('This is a required field'),
      maxParticipantsNumber: yup
        .number()
        .min(yup.ref('minParticipantsNumber'), 'Value should be more than min')
        .required('This is a required field'),
      title: yup
        .string()
        .strict(true)
        .min(2, ({ min }) => `Value should be longer than ${min} characters`)
        .required('This is a required field'),
      isPrerecorded: yup.boolean(),
      sessionTimes: yup
        .array()
        .when('isPrerecorded', {
          is: isPrerecorded => !isPrerecorded,
          then: yup.array().of(
            yup.object().shape({
              startTime: yup.date().required(),
              endTime: yup
                .date()
                .when('prerecordedSession', {
                  is: false,
                  then: yup.date().min(yup.ref('startTime'), 'This time must be later than start time'),
                })
                .required(),
            }),
          ),
          otherwise: yup.array().of(
            yup.object().shape({
              subCategoryName: yup.string().max(50, 'Cannot Exceed 50 Characters').nullable(),
              // startTime: yup.date().required(),
              embeddedVideoUrl: yup
                .string()
                .test('isValidURL', 'Invalid Link', value => {
                  if (value) {
                    return isValidLink(value);
                  }
                  return true;
                })
                .nullable(),
              prerecordedSession: yup.object().when(['embeddedVideoUrl', 'selfPacedContentType'], {
                is: (embeddedVideoUrl, selfPacedContentType) =>
                  selfPacedContentType?.includes('Video') && !embeddedVideoUrl,
                then: yup
                  .object()
                  .required('Please upload self-paced content or embed a video link, or delete this module to proceed.')
                  .nullable(),
                otherwise: yup.object().nullable(),
              }),
              startTime: yup.date().required(),
              endTime: yup
                .date()
                .when('prerecordedSession', {
                  is: false,
                  then: yup.date().min(yup.ref('startTime'), 'This time must be later than start time'),
                })
                .required(),
              ignoreDateAvailable: yup.boolean(),
              isExpireDateOptionSelected: yup.boolean(),
              isExpireDateEnabled: yup.boolean(),
              isSpecificDateEnabled: yup.boolean(),
              selfPacedContentAvailableDate: yup.date().nullable(),
              noOfDaysForSpecificDate: yup.number().nullable(),
              noOfDaysForExpirationDateEnabled: yup.boolean(),
              noOfDaysForSpecificDateEnabled: yup.boolean(),
              selfPacedContentExpirationDate: yup
                .date()
                .nullable()
                .when(
                  ['ignoreDateAvailable', 'isExpireDateOptionSelected', 'isExpireDateEnabled', 'isSpecificDateEnabled'],
                  {
                    is: (
                      ignoreDateAvailable,
                      isExpireDateOptionSelected,
                      isExpireDateEnabled,
                      isSpecificDateEnabled,
                    ) => {
                      return (
                        ignoreDateAvailable &&
                        isExpireDateOptionSelected &&
                        isExpireDateEnabled &&
                        isSpecificDateEnabled
                      );
                    },
                    then: yup.date().test('is-greater', 'Must be after Date Available.', function (value) {
                      const { selfPacedContentAvailableDate } = this.parent;
                      const expirationDate = moment(value).startOf('day');
                      const availableDate = moment(selfPacedContentAvailableDate).startOf('day');

                      return expirationDate && availableDate && moment(expirationDate).isAfter(availableDate);
                    }),
                  },
                ),
              noOfDaysForExpirationDate: yup
                .number()
                .when(['noOfDaysForExpirationDateEnabled', 'noOfDaysForSpecificDateEnabled'], {
                  is: (noOfDaysForExpirationDateEnabled, noOfDaysForSpecificDateEnabled) => {
                    return noOfDaysForExpirationDateEnabled && noOfDaysForSpecificDateEnabled;
                  },
                  then: yup
                    .number()
                    .test('is-greater-number', 'Expiration Date must be after Date Available.', function (val) {
                      const { noOfDaysForSpecificDate } = this.parent;
                      return val > noOfDaysForSpecificDate;
                    }),
                }),
            }),
          ),
        })
        .min(1, 'Please add a session time')
        .required(),
    }),
  ),
});
const communityPaymentValidationSchema = yup.object().shape({
  paymentOptions: yup
    .array()
    .of(
      yup
        .mixed()
        .oneOf([
          PAYMENT_OPTIONS.FREE,
          PAYMENT_OPTIONS.PER_DAY,
          PAYMENT_OPTIONS.PER_WEEK,
          PAYMENT_OPTIONS.PER_MONTH,
          PAYMENT_OPTIONS.PER_YEAR,
          PAYMENT_OPTIONS.PACKAGE,
        ]),
    )
    .min(1),
  perMonthCost: yup.number().when('paymentOptions', {
    is: values => values.includes(PAYMENT_OPTIONS.PER_MONTH),
    then: yup
      .number()
      .min(1, 'Value should be more than 1')
      .integer('Please only enter numbers')
      .required('This is a required field'),
    otherwise: yup.number().nullable(),
  }),
  perYearCost: yup.number().when('paymentOptions', {
    is: values => values.includes(PAYMENT_OPTIONS.PER_YEAR),
    then: yup
      .number()
      .min(1, 'Value should be more than 1')
      .integer('Please only enter numbers')
      .required('This is a required field'),
    otherwise: yup.number().nullable(),
  }),
  membershipPackage: yup.object().when('paymentOptions', {
    is: values => values.includes(PAYMENT_OPTIONS.PACKAGE),
    then: yup
      .object()
      .required()
      .shape({
        duration: yup.number().required(),
        period: yup.string().required(),
        cost: yup
          .number()
          .min(1, 'Value should be more than 1')
          .integer('Please only enter numbers')
          .required('This is a required field'),
      }),
    otherwise: yup.object().nullable(),
  }),
});

export {
  liveSessionsValidationSchema,
  oneToOneSessionsValidationSchema,
  membershipSessionsValidationSchema,
  membershipPaymentValidationSchema,
  communitySessionsValidationSchema,
  communityPaymentValidationSchema,
  livePaymentValidationSchema,
  enrollmentFormSchema,
  liveSessionsValidationSchemaEditing,
  membershipSessionsValidationSchemaEditing,
};
