import React, { useCallback, useEffect, useState } from 'react';
import useRouter from 'hooks/useRouter';
import useHttp from 'hooks/http.hook';
import useVideoChatActions from 'hooks/useVideoChatActions';
import { connect, useDispatch } from 'react-redux';
import { LOG_IN_FAILURE, logOut, login } from 'actions/user';
import PropTypes from 'prop-types';
import { setCookie } from 'services/cookies.service';
import styled from 'styled-components';
import Loader from 'components/UI/Loader';
import { getIpGlobal } from 'utils/utils';
import { fetchClientContributionAfterInterval } from 'actions/contributions';
import { find } from 'lodash';
import { Avatar, useMediaQuery, useTheme } from '@material-ui/core';
import { getInitialSymbol } from 'components/UI/Header';
import * as R from 'ramda';

const headerHeight = '90px';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${({ mobileView }) => (mobileView ? '45% 20px' : '150px 48px')};
`;
const StyledLoadingContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyledHeader = styled.div`
  background-color: white;
  box-shadow: 0 0px 4px 0 rgba(157, 157, 157, 0.3);
  display: flex;
  justify-content: center;
  min-width: 320px;
  padding: 20px 40px 20px 20px;
  max-height: ${headerHeight};
`;

const AvatarComponent = styled(Avatar)`
  &.MuiAvatar-root {
    width: 48px !important;
    height: 48px !important;
  }
`;

const StyledP = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: ${({ mobileView }) => (mobileView ? '14px' : '22px')};
  font-family: 'Avenir';
  font-weight: 800;
  word-wrap: break-word;
  margin: 0;
`;

const StyledContainerBox = styled.div`
  display: flex;
  min-width: ${({ mobileView }) => (mobileView ? '100%' : '49.3125rem')};
  max-width: 54.8%;
  max-height: 54.4%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--Cohere-Greys-Background-Grey, #f5f5f5);
  background: #fff;

  /* Cohere/Shadow B */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  padding: ${({ mobileView }) => (mobileView ? '20px' : '60px')};
`;

export const getInitials = R.compose(R.join(''), R.map(getInitialSymbol), R.take(2), R.split(' '));
let sessionsRefresher = null;
function VideoCall({ videoChat, contribution }) {
  const { request } = useHttp();
  const { query, history, parentDomain } = useRouter();
  const { startVideoChat } = useVideoChatActions();
  const [launched, setLaunched] = useState(false);
  const dispatch = useDispatch();
  const pDomain = parentDomain.split(':3000')[0];
  const [loading, setLoading] = useState(true);
  const [currentSession, setCurrentSession] = useState(null);
  const { email, contributionId, sessionId } = query;
  const [callEnded, setCallEnded] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const launchGuestSession = useCallback(
    ({ clientId, client }) => {
      request('/Video/GetGuestClientToken', 'POST', {
        contributionId,
        classId: sessionId,
        userId: clientId,
        identityName: client,
      })
        .then(({ token }) =>
          startVideoChat({
            contributionId,
            sessionId,
            chatId: clientId,
            token,
            deleteRoomOnVideoEnd: true,
          }),
        )
        .catch(error => {
          history.replace('/dashboard');
        })
        .finally(() => {
          setLaunched(true);
        });
    },
    [query],
  );

  useEffect(() => {
    if (launched && !videoChat.contributionId) {
      setCookie('guestVideoCall', false, pDomain, 30);
      dispatch(logOut);
      setCallEnded(true);
    }
  }, [launched, videoChat?.contributionId]);

  const initLoginUser = async () => {
    const returnedLoginAction = await login(email, null, null, null, null, true, null, null, null, true)(dispatch);
    if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
      if (['contributionId', 'sessionId', 'clientId', 'client', 'email'].every(key => key in query) && !launched) {
        setCookie('guestVideoCall', true, pDomain, 30);
      }
    } else {
      history.replace('/dashboard');
    }
  };

  const getCurrentSession = () => {
    let sessionTime;
    find(contribution.availabilityTimes, obj => {
      sessionTime = find(obj.bookedTimes, bookedTime => bookedTime.id === sessionId);
      return sessionTime;
    });

    return sessionTime;
  };

  useEffect(() => {
    if (contribution?.id) {
      setLoading(false);
      setCurrentSession(getCurrentSession());
    }
  }, [contribution]);

  useEffect(() => {
    if (currentSession?.videoRoomInfo?.isRunning && !launched) {
      launchGuestSession(query);
    }
  }, [currentSession]);

  useEffect(() => {
    if (callEnded || launched) {
      if (sessionsRefresher) {
        clearInterval(sessionsRefresher);
        sessionsRefresher = null;
      }
    }
  }, [callEnded, launched]);

  useEffect(() => {
    if (email) {
      initLoginUser();
    }
    sessionsRefresher = setInterval(async () => {
      const userIP = await getIpGlobal();
      dispatch(fetchClientContributionAfterInterval(contributionId, userIP, null));
    }, 10000);
    return () => {
      if (sessionsRefresher) {
        clearInterval(sessionsRefresher);
      }
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <StyledHeader>
        {contribution && (
          <AvatarComponent className="mx-2" alt={contribution?.serviceProviderName} src={contribution?.coachAvatarUrl}>
            {getInitials(contribution?.serviceProviderName)}
          </AvatarComponent>
        )}
      </StyledHeader>
      <StyledContainer mobileView={mobileView}>
        <StyledContainerBox mobileView={mobileView}>
          {contribution?.id && (
            <>
              {!callEnded ? (
                <>
                  <StyledLoadingContainer>
                    <Loader relative />
                  </StyledLoadingContainer>
                  <StyledP mobileView={mobileView}>
                    Please wait for {contribution.serviceProviderName} to begin the session.
                  </StyledP>
                </>
              ) : (
                <>
                  <StyledP mobileView={mobileView}>This session has ended.</StyledP>
                </>
              )}
            </>
          )}
        </StyledContainerBox>
      </StyledContainer>
    </>
  );
}

VideoCall.propTypes = {
  videoChat: PropTypes.shape({
    contributionId: PropTypes.string,
  }),
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  contribution: PropTypes.shape({
    availabilityTimes: PropTypes.arrayOf(PropTypes.shape({})),
    serviceProviderName: PropTypes.string,
    id: PropTypes.string,
    coachAvatarUrl: PropTypes.string,
  }),
};

VideoCall.defaultProps = {
  videoChat: null,
  contribution: null,
  user: null,
};

const mapStateToProps = ({ videoChat, account: { user }, contributions }) => {
  return {
    videoChat,
    user,
    contribution: contributions.activeContribution,
  };
};

export default connect(mapStateToProps, null)(VideoCall);
