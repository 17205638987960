import React, { useState } from 'react';
import classes from './DropDown.module.scss';
import { MenuItem, OutlinedInput, Select, styled } from '@material-ui/core';
import Modal from 'components/UI/Modal';
import { TextField } from '@material-ui/core';
import { Icon } from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { convertToPlainText } from 'utils/utils';
import { questionTypes } from 'pages/CreateQuizes/Constants/constants';
import useQuiz from 'hooks/useQuiz';

const ArrowIcon = props => <Icon color="#213649" path={mdiChevronDown} size={1} {...props} />;

export default function DropDownWithTitle({
  title = '',
  data,
  val,
  setVal,
  textField = false,
  setFieldValue,
  name,
  questions,
  isPublished,
}) {
  const { activeQuiz } = useQuiz();
  const [showModal, setShowModal] = useState(false);
  const [showModalQuizType, setShowModalQuizType] = useState(false);
  return (
    <>
      <div className={classes.container}>
        {title?.length > 0 && <div className={classes.dd_title}>{title}</div>}
        {textField ? (
          <>
            <TextField
              fullWidth
              variant="outlined"
              name={'formprops.name'}
              value={val}
              placeholder={activeQuiz.type === 'Form' ? 'Form Title' : 'Quiz Title'}
              onChange={e => {
                setVal(e.target.value);
              }}
              InputProps={{
                inputProps: {
                  style: {
                    fontSize: '1rem',
                    fontFamily: 'Avenir',
                    padding: '11.5px',
                    fontWeight: '400',
                  },
                },
                classes: {
                  root: classes.textfield_input_root,
                  notchedOutline: classes.textfield_input_notchedOutline,
                },
              }}
            />

            {val?.length === 0 && <CommonErrorMessage align="left" message="Title should not be empty" color={'red'} />}
          </>
        ) : (
          <Select
            IconComponent={ArrowIcon}
            className={classes.select_root}
            variant="outlined"
            fullWidth
            defaultValue={0}
            classes={{ select: classes.select_select }}
            input={
              <OutlinedInput
                margin="dense"
                classes={{ root: classes.select_input_root, notchedOutline: classes.select_input_notchedOutline }}
              />
            }
            onChange={e => {
              if (e.target.value === 'Quiz' || e.target.value === 'Form') {
                setShowModal(true);
              } else {
                setVal(e.target.value);
              }
              if (e.target.value === 'Personality Quiz' || e.target.value === 'Scored Quiz') {
                setShowModalQuizType(true);
              } else {
                setVal(e.target.value);
              }
            }}
            value={val}
          >
            {data?.map(data => {
              return (
                <MenuItem
                  disabled={
                    data?.value === 'integrate_into_service'
                      ? true
                      : data?.value === 'Personality Quiz'
                      ? true
                      : activeQuiz.isPublished && (data?.value === 'Form' || data?.value === 'Quiz')
                      ? true
                      : false
                  }
                  style={{
                    fontFamily: 'Avenir',
                    fontWeight: val === data.value ? '500' : '300',
                    fontSize: '16px',
                    color: '#213649',
                  }}
                  value={data?.value}
                >
                  {data?.title}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </div>
      <Modal
        title={`Switching Type ${name}`}
        isOpen={showModal}
        subbmitTitle="OK"
        cancelTitle="Cancel"
        onSubmit={() => {
          setShowModal(false);

          if (val === 'Form') {
            setFieldValue('type', 'Quiz');
            setFieldValue('quizType', 'Scored');
            if (questions.length > 0) {
              let filtered = questions
                .filter(
                  question =>
                    question.questionType != questionTypes.ranking &&
                    question.questionType != questionTypes.rating &&
                    question.questionType != questionTypes.short_text,
                )
                .map(question => ({
                  ...question,
                  name: convertToPlainText(question.name),
                  description: convertToPlainText(question.description),
                  options: [{ value: '', isCorrect: false }],
                }));
              if (filtered.length === 0) {
                setFieldValue('questions', [
                  {
                    type: questionTypes.multiple_choice,
                    questionType: questionTypes.multiple_choice,
                    name: '',
                    description: '',
                    options: [{ value: '', isCorrect: false }],
                    placeholder: 'Please write your question here…',
                    isOptional: true,
                    canSelectMultipleCorrectOptions: false,
                    name: '',
                    userResponse: [],
                    isDraft: true,
                    isRequired: true,
                    isDeleted: false,
                  },
                ]);
              } else {
                setFieldValue('questions', filtered);
              }
            } else {
              setFieldValue('questions', [
                {
                  type: questionTypes.multiple_choice,
                  questionType: questionTypes.multiple_choice,
                  name: '',
                  description: '',
                  options: [{ value: '', isCorrect: false }],
                  placeholder: 'Please write your question here…',
                  isOptional: true,
                  canSelectMultipleCorrectOptions: false,
                  name: '',
                  userResponse: [],
                  isDraft: true,
                  isRequired: true,
                  isDeleted: false,
                },
              ]);
            }
          } else {
            setFieldValue('type', 'Form');
            if (questions.length > 0) {
              let filtered = questions
                .filter(
                  question =>
                    question.questionType != questionTypes.ranking &&
                    question.questionType != questionTypes.rating &&
                    question.questionType != questionTypes.short_text,
                )
                .map(question => ({
                  ...question,
                  name: convertToPlainText(question.name),
                  description: convertToPlainText(question.description),
                  options: [{ value: '', isCorrect: false }],
                }));
              console.log('Filtered', filtered);
              if (filtered.length === 0) {
                setFieldValue('questions', [
                  {
                    type: questionTypes.multiple_choice,
                    questionType: questionTypes.multiple_choice,
                    name: '',
                    description: '',
                    options: [{ value: '', isCorrect: false }],
                    placeholder: 'Please write your question here…',
                    isOptional: true,
                    canSelectMultipleCorrectOptions: false,
                    name: '',
                    userResponse: [],
                    isDraft: true,
                    isRequired: true,
                    isDeleted: false,
                  },
                ]);
              } else {
                setFieldValue('questions', filtered);
              }
            } else {
              setFieldValue('questions', [
                {
                  type: questionTypes.multiple_choice,
                  questionType: questionTypes.multiple_choice,
                  name: '',
                  description: '',
                  options: [{ value: '', isCorrect: false }],
                  placeholder: 'Please write your question here…',
                  isOptional: true,
                  canSelectMultipleCorrectOptions: false,
                  name: '',
                  userResponse: [],
                  isDraft: true,
                  isRequired: true,
                  isDeleted: false,
                },
              ]);
            }
          }
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        modalType={'Quizes'}
      >
        {` By switching the type from${
          val === 'Quiz' ? ' Quiz to Form' : ' Form to Quiz'
        }, you will lose information input in the multiple-choice questions and options. Please review your content and settings accordingly after the switch.`}
      </Modal>
      <Modal
        title={`Switching Quiz Type ${val}`}
        isOpen={showModalQuizType}
        subbmitTitle="OK"
        cancelTitle="Cancel"
        onSubmit={() => {
          setShowModalQuizType(false);
        }}
        onCancel={() => {
          setShowModalQuizType(false);
        }}
        modalType={'Quizes'}
      >
        {`By switching the quiz type from scored to personality, logic will be enabled by default for the quiz. Please review your quiz content and settings accordingly after the switch.`}
      </Modal>
    </>
  );
}
