import React, { useState } from 'react';
import classes from './Question.module.scss';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ActionsIcon from '../../Assests/Icons/ActionIcon';
import Multiple from '../QuestionTypes/Multiple/Multiple';
import { questionTypes } from 'pages/CreateQuizes/Constants/constants';
import Dropdown from '../QuestionTypes/Dropdown/Dropdown';
import ActionsPanel from '../ActionPanel/ActionsPanel';
import { ClickAwayListener } from '@material-ui/core';
import Response from '../QuestionTypes/Response/Response';
import { convertToPlainText } from 'utils/utils';
import Ranking from '../QuestionTypes/Ranking/Ranking';
import Rating from '../QuestionTypes/Rating/Rating';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ShortText from '../QuestionTypes/ShortText/ShortText';
import { Tooltip } from '@material-ui/core';

const TYPE_MAPPER = {
  [questionTypes.multiple_choice]: <Multiple />,
  [questionTypes.dropdown]: <Dropdown />,
  [questionTypes.ranking]: <Ranking />,
  [questionTypes.rating]: <Rating />,
  [questionTypes.short_text]: <ShortText />,
  [questionTypes.response]: <Response />,
};

const QuestionPreview = ({ question, index, response }) => {
  const [isActionPanelOpen, setIsActionPanelOpen] = useState(false);
  const {
    type,
    description,
    options,
    isOptional,
    name,
    questionType,
    userResponse,
    isDraft,
    isRequired,
    id,
    placeholder,
  } = question;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(993));
  const actionsList = [
    {
      name: 'Duplicate',
      onClick: e => {
        e.stopPropagation();
        setIsActionPanelOpen(false);
      },
    },
    {
      name: 'Delete',
      onClick: e => {
        e.stopPropagation();

        setIsActionPanelOpen(false);
      },
    },
  ];
  const getFirstSentence = content => {
    const str = convertToPlainText(content);
    // const indexOfDot = str.indexOf('.');
    // return indexOfDot !== -1 ? str.substring(0, indexOfDot) : str;
    return str;
  };
  return (
    <div className={`${classes.question_preview_container}`}>
      <div className={classes.question}>
        <div className={classes.question_type}>{response ? TYPE_MAPPER['Response'] : TYPE_MAPPER[questionType]}</div>
        <Tooltip title={getFirstSentence(name)}>
          {isMobile ? (
            <div className={classes.question_desc} title={getFirstSentence(name)}>
              {`${index + 1}. ${
                getFirstSentence(name)?.length > 30
                  ? getFirstSentence(name)?.slice(0, 30) + '...'
                  : getFirstSentence(name)
              }`}
            </div>
          ) : (
            <div className={classes.question_desc} title={getFirstSentence(name)}>
              {`${index + 1}. ${getFirstSentence(name)}`}
            </div>
          )}
        </Tooltip>
      </div>
    </div>
  );
};

export default QuestionPreview;
