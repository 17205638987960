import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { colors } from 'utils/styles';
import Fab from '@mui/material/Fab';
import GetStartedCard from './GetStartedCard';
import ChatCard from './ChatCard';
import { CaptureConsole } from '@sentry/integrations';
import MinimizeIcon from '@mui/icons-material/Minimize';
import { useTheme, useMediaQuery, ClickAwayListener } from '@material-ui/core';
import { ContributionType, SIGNUP_TYPES, UserRoles, ContributionThemedColors } from 'helpers/constants';
import { useAccount, useRouter, useShallowEqualSelector } from 'hooks';
import usePaidTier from 'hooks/usePaidTier';
import * as paidTier from 'selectors/paidTier';
import { PAID_TIER_TITLES } from '../../../../../constants';
import { setCohereAcademyStatus } from 'actions/update-user';
import { useDispatch } from 'react-redux';
import CoachImage from 'assets/chatlogobutton.jpg';
import classes from './LiveChatContainer.module.scss';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: end;
  // gap: 10px;
  // justify-content: space-between;
  // width: 180px;
  position: fixed;
  bottom: 75px;
  right: 0px;
`;
const StyledChatIconContainer = styled.div`
  background-color: ${({ color }) => (color ? color : 'red')};
  border-radius: 25px;
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
  width: 50px;
  height: 50px;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const StyledChatToggle = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const StyledIconWrapper = styled.div`
  position: absolute;
  top: 14px;
  left: 14px;
`;

const StyledGetStarted = styled.div`
  width: ${({ hovered }) => (hovered ? '140px' : '60px')};
  height: 50px;
  background-color: ${({ color }) => (color ? color : 'red')};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px 0px 0px 25px;
  font-size: 14px;
  font-weight: bold;
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
`;

const ChatAndGetStartedButtons = ({ page }) => {
  const [showGetStartedCard, setShowGetStartedCard] = useState(false);
  const [showChatCard, setShowChatCard] = useState(false);

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [getStartedBtnHovered, setGetStartedBtnHovered] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAccount();
  const { currentRole, user: signupType, loggedInUser } = useAccount();
  const dispatch = useDispatch();
  // const { isLaunchPlan } = usePaidTier();

  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);
  const shouldBeDisabled =
    signupType === SIGNUP_TYPES.TYPE_A ||
    signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser?.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser?.signupType === SIGNUP_TYPES.TYPE_A;

  const disable =
    (shouldBeDisabled && currentRole === UserRoles.cohealer) ||
    (isLaunchPlan && currentRole === UserRoles.cohealer && user.isPartnerCoach === false);

  const loadScript = () => {
    const script = document.createElement('script');
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=5ae9b8f5-8796-4ad3-b5f2-840c37eae0d7';
    script.id = 'ze-snippet';
    script.async = true;
    script.onload = () => {
      setScriptLoaded(true);
      window.zE('webWidget', 'show');
      window.zE('webWidget', 'open');
      window.zE('webWidget:on', 'close', () => {
        window.zE('webWidget', 'hide');
      });
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    // Add the Zendesk Chat widget customization code here
    window.zESettings = {
      webWidget: {
        color: { theme: colors.newGold },
        offset: { horizontal: '100px', vertical: '100px' },
      },
    };
  }, []);

  useEffect(() => {
    if (page != 'ProfilePage' && disable === false && user?.progressBarPercentage == 0) {
      setShowGetStartedCard(true);
    }
  }, []);

  const hideGetStartedCard = () => {
    setShowGetStartedCard(false);
  };

  const hideChatCard = () => {
    setShowChatCard(false);
  };

  const showGetStartedCardOnClick = () => {
    setShowChatCard(false);
    setShowGetStartedCard(prevState => !prevState);
  };

  const showChatCardOnClick = () => {
    setShowGetStartedCard(false);
    setShowChatCard(prevState => !prevState);
  };

  const onMouseOver = () => {
    setGetStartedBtnHovered(true);
  };

  const onMouseOut = () => {
    setTimeout(() => {
      setGetStartedBtnHovered(false);
    }, 3000); // 3000 milliseconds = 3 seconds
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setGetStartedBtnHovered(false);
      }}
    >
      <StyledContainer className={classes.container}>
        <div style={{ position: 'relative' }}>
          {showGetStartedCard && <GetStartedCard hideCardFunc={hideGetStartedCard} />}
        </div>

        <div style={{ position: 'relative' }}>
          {showChatCard && <ChatCard hideCardFunc={hideChatCard} loadScript={loadScript} />}
        </div>
        <StyledGetStarted
          hovered={getStartedBtnHovered || showChatCard || showGetStartedCard}
          className={classes.getstarted_btn}
          color={disable ? colors?.backgroundGrey : colors?.newGold}
          onClick={() => {
            disable ? console.log('first') : showGetStartedCardOnClick();
          }}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          disable={disable}
        >
          <img className={classes.image} src={CoachImage} />
          {(getStartedBtnHovered || showChatCard || showGetStartedCard) && 'Get Started'}
        </StyledGetStarted>
        <StyledChatIconContainer
          color={disable ? colors?.backgroundGrey : colors?.newGold}
          onClick={() => {
            disable ? console.log('first') : showChatCardOnClick();
          }}
          className={`${classes.chat_btn} ${
            getStartedBtnHovered || showChatCard || showGetStartedCard ? classes.show : ''
          }`}
          disable={disable}
        >
          <StyledChatToggle>
            <StyledIconWrapper>
              <ChatBubbleIcon htmlColor="white" />
            </StyledIconWrapper>
          </StyledChatToggle>
        </StyledChatIconContainer>
      </StyledContainer>
    </ClickAwayListener>
  );
};

export default ChatAndGetStartedButtons;
