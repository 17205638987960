import React from 'react';

const BackLeftIcon = ({ onClick }) => {
  return (
    <div
      style={{
        cursor: 'pointer',
        height: '26px',
        width: '26px',
        borderRadius: '13px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
      }}
      onClick={onClick}
    >
      <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.66675 12.3335L2.33341 7.00016L7.66675 1.66683"
          stroke="#213649"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
};

export default BackLeftIcon;
