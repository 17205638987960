import React, { useState } from 'react';
import { SlimSwitch, StyledInput, SlimStyledSlider } from 'components/switch/style';
import classes from './SwitchTextField.module.scss';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme, useMediaQuery, Tooltip, TextField } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from 'utils/styles';

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

const DivWrapper = ({ children }) => {
  return <>{children}</>;
};

export default function SwitchTextField({
  text,
  val,
  setVal,
  setPassingGrade,
  passingGrade,
  textFieldIncluded = false,
  disabled = false,
  disabledTooltipText = '',
}) {
  const [showTextField, setShowTextField] = useState(val);
  const className = useStyles();
  const Wrapper = disabled ? Tooltip : DivWrapper;
  const tooltipProps = {
    title: disabledTooltipText,
    arrow: true,
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Wrapper {...(disabled && tooltipProps)}>
          {text === 'Define a passing grade' ? (
            <span>
              <Tooltip title="Set the minimum percentage score required for participant to pass the quiz." arrow>
                <div
                  style={{
                    fontFamily: 'Avenir',
                    fontWeight: '500',
                    fontSize: '15px',
                    lineHeight: '16px',
                    color: disabled ? ' #9b9b9b' : '#213649',
                  }}
                >
                  {text}

                  {text === 'Define a passing grade' && (
                    <InfoIcon style={{ marginLeft: '10px', marginBottom: '3px' }} htmlColor={colors.lightBrown} />
                  )}
                </div>
              </Tooltip>
            </span>
          ) : (
            <div
              style={{
                fontFamily: 'Avenir',
                fontWeight: '500',
                fontSize: '15px',
                lineHeight: '16px',
                color: disabled ? ' #9b9b9b' : '#213649',
              }}
            >
              {text}
            </div>
          )}
        </Wrapper>
        <span style={{ display: 'flex', marginTop: '10px' }}>
          <SlimSwitch className="switch" style={{ marginLeft: 8 }}>
            <StyledInput
              disabled={disabled}
              type="checkbox"
              onClick={() => {
                setVal(!val);
                if (val === false) {
                  setShowTextField(true);
                } else {
                  setShowTextField(false);
                }
              }}
              checked={val}
            />
            <SlimStyledSlider className="slider round" />
          </SlimSwitch>
        </span>
      </div>
      {showTextField && textFieldIncluded === true && (
        <>
          <TextField
            className={className.input}
            fullWidth
            variant="outlined"
            name={'formprops.name'}
            value={passingGrade}
            placeholder={'Passing Grade'}
            onChange={e => {
              const regex = /^\d+$/;
              if (regex.test(e.target.value) === false) {
                setPassingGrade('');
              }
              if (regex.test(e.target.value)) {
                if (e.target.value < 0) {
                } else if (e.target.value > 100) {
                } else {
                  setPassingGrade(e.target.value);
                }
              }
            }}
            InputProps={{
              endAdornment: <div>%</div>,
              inputProps: {
                style: {
                  fontSize: '1rem',
                  fontFamily: 'Avenir',
                  padding: '11.5px',
                  fontWeight: '400',
                },
              },
              classes: {
                root: classes.textfield_input_root,
                notchedOutline: classes.textfield_input_notchedOutline,
              },
            }}
            min={0}
            max={100}
            type="tel"
          />
          {(passingGrade?.length === 0 || passingGrade === 0) && (
            <CommonErrorMessage align="left" color={'red'} message="Passing grade should not be 0" />
          )}
        </>
      )}
    </div>
  );
}
