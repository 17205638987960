import Button from 'components/FormUI/Button';
import Modal from 'components/UI/Modal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { redirectTo } from 'services/links';
import { DatePicker } from '@material-ui/pickers';
import { getCoachContributionTimes, getContribution } from 'services/contributions.service';
import { getTimePeriodsForAvailability } from 'utils/datesAndMoney';
import { uniqBy } from 'lodash';
import useAccount from 'hooks/useAccount';
import { CONTRIBUTION_COLORS, TIMEZONES } from 'constants.js';
import Loader from 'components/UI/Loader';
import { isEmpty } from 'ramda';
import { colors } from 'utils/styles';
import { date } from 'yup';
import { startIncrementDurationsOneToOne } from 'pages/CreateContribution/components/OneToOneForm';
import { useRouter } from 'hooks';
import { useContribution } from 'pages/ContributionView/hooks';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  label_root: {
    color: 'white',
  },
}));

const ReschduleSessionPopup = ({ session, isModalOpen, onCloseModal, onConfirm, contribution }) => {
  const { durations } = contribution;
  const { isDarkModeEnabled } = useContribution();
  const { sessionTitle: title = '', start = '', end = '', contributionId = '', sessionIncrements } = session;
  const [selectedDate, setSelectedDate] = useState(moment(start));
  const [selectedSlot, setSelectedSlot] = useState();
  const [slots, setSlots] = useState([]);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [isUpdateSessionButtonDisable, setIsUpdateSessionButtonDisable] = useState(loadingSlots);
  const currentDateTime = new Date();
  const classNames = useStyles();
  const {
    user: { timeZoneId = '' },
  } = useAccount();
  const { protocol, domain } = useRouter();
  useEffect(() => {
    setLoadingSlots(true);
    // const increments = sessionIncrements || [];
    //const sessionDuration = durations?.[0];
    const startTimeIncrementDuration = sessionIncrements?.[0];
    const increments = [startTimeIncrementDuration];

    // if (startTimeIncrementDuration > 0) {
    //   for (let i = 0; i < Math.ceil(sessionDuration / startTimeIncrementDuration); i++) {
    //     increments.push(i * startTimeIncrementDuration);
    //   }
    // }
    // if (increments.every(k => k !== 0)) {
    //   increments.push(0);
    // }
    Promise.allSettled(
      increments.map(k => getCoachContributionTimes(contributionId, k, selectedDate.format('YYYY-MM-DD'))),
    ).then(_slots => {
      const zeroSlots = _slots.reduce(
        (acc, curr) => (acc.some(k => k.startTime === curr.value?.startTime) ? acc : [...acc, ...curr.value]),
        [],
      );
      const formattedSlots = zeroSlots
        .filter(slot => moment(slot.startTime).isSame(selectedDate._d, 'day'))
        .sort((a, b) => moment(a.startTime).diff(moment(b.startTime), 'minutes'));

      const events = getTimePeriodsForAvailability({
        availabilityPeriods: uniqBy(formattedSlots, 'id'),
        duration: '60',
        serviceProviderName: 'test',
      });
      setSlots(events);
      setLoadingSlots(false);
    });
  }, [selectedDate]);

  useEffect(() => {
    setSelectedSlot(
      slots?.find(
        slot => slot.start?.getTime() !== start?.getTime() && slot.start?.getTime() > currentDateTime.getTime(),
      ),
    );
    setIsUpdateSessionButtonDisable(isEmpty(slots));
  }, [slots]);

  return (
    <>
      {loadingSlots && <Loader />}
      <Modal
        isOpen={isModalOpen}
        title={`Reschedule ${title}`}
        onSubmit={() => onConfirm(selectedSlot)}
        submitTitle="Update"
        disableSubmitClick={loadingSlots || isUpdateSessionButtonDisable || !selectedSlot}
        onCancel={() => onCloseModal()}
        className="master-calendar-reschedule-modal"
        hiddenCancel
      >
        <p style={{ display: 'flex', gap: '11px', color: isDarkModeEnabled ? 'white' : '' }}>
          {title}
          <span style={{ color: '#d1b989' }}>
            {moment(start).format('hh:mm A')} - {moment(end).format('hh:mm A')}
          </span>
        </p>
        <Button
          className="availibility-button"
          onClick={() => {
            const contributionUrl = new URL(
              `/edit-contribution/${contributionId}/sessions`,
              `${protocol}//${domain}`,
            ).toString();
            window.location.href = contributionUrl;
          }}
        >
          See my Availability
        </Button>

        <DatePicker
          label="Date"
          value={selectedDate}
          className="master-date-picker"
          style={{ display: 'block', width: '100%', color: isDarkModeEnabled ? 'white' : '' }}
          onChange={value => {
            setSelectedDate(value);
          }}
          InputLabelProps={{
            classes: {
              root: isDarkModeEnabled ? classNames.label_root : '',
            },
          }}
          disablePast
        />
        <div className="reschedule-slot-container">
          {isEmpty(slots) ? (
            <p>No session against this date</p>
          ) : (
            slots?.map(slot => {
              const isPastOrBookedSlot =
                start?.getTime() === slot.start?.getTime() || slot.start?.getTime() < currentDateTime.getTime();
              return (
                <div
                  className={`${selectedSlot?.id === slot?.id ? 'selected-slot' : ''} ${
                    isPastOrBookedSlot ? ' past-slot' : ''
                  }`}
                  onClick={() => {
                    if (!isPastOrBookedSlot) {
                      setSelectedSlot(slot);
                    }
                  }}
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => {}}
                  style={{ color: isPastOrBookedSlot ? colors.stroke : isDarkModeEnabled ? 'white' : '' }}
                >
                  <p>{moment(slot?.start).format('hh:mm A')}</p>
                </div>
              );
            })
          )}
        </div>
        <div className="update-timezone" style={{ color: isDarkModeEnabled ? 'white' : '' }}>
          {TIMEZONES[timeZoneId]}{' '}
          <span
            style={{ color: CONTRIBUTION_COLORS.PrimaryColorCode, cursor: 'pointer' }}
            onClick={() => {
              redirectTo('/account/profile');
            }}
          >
            Update My Timezone
          </span>
        </div>
      </Modal>
    </>
  );
};

export default ReschduleSessionPopup;
