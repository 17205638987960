import React from 'react';
import Grid from '@material-ui/core/Grid';
import SubcscribeCard from './components/SubcscribeCard/SubcscribeCard';
import styled from 'styled-components';

import './Notifications.scss';
import ChatAndGetStartedButtons from 'pages/Dashboard/components/CohealerDashboard/LiveChat/LiveChatContainer';

function Notifications() {
  return (
    <Grid container direction="column" alignItems="center" spacing={3}>
      <SubcscribeCard />
      <ChatAndGetStartedButtons page={'ProfilePage'} />
    </Grid>
  );
}

export default Notifications;
