import React, { useState } from 'react';
import classes from './MobileHeader.module.scss';
import Button from 'components/FormUI/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP } from '../../../../../../constants';
import { CircularProgress } from '@material-ui/core';
import useQuiz from 'hooks/useQuiz';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { openInNewWindow } from 'services/links';
import { convertToPlainText } from 'utils/utils';
import { createUpdateQuiz, updateRemindAhain } from 'pages/CreateQuizes/Services/CreateQuizes.service';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setActiveQuiz } from 'actions/quizes';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const StyledHostName = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  line-height: 16px;
  color: #4a4a4a;
  width: 600px;
  overflow: hidden;
  border: 1px solid #dfe3e4;
  display: flex;
  align-items: center;
  // margin-right: 20px;
  border-radius: 4px;
  padding: 0px 10px;
`;

const StyledHostNameMobile = styled.div`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 350;
  color: #4a4a4a;
  overflow: hidden;
  border: 1px solid #dfe3e4;
  display: flex;
  align-items: center;
  margin-right: 20px;
  border-radius: 4px;
  padding: 0px 10px;
`;

const MobileHeader = ({ isEditing, values, setFieldValue }) => {
  const { activeQuiz } = useQuiz();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showUpdateShareModal, setShowUpdateShareModal] = useState(false);
  const [dontRemindAgain, setDontRemindAgain] = useState(activeQuiz.dontRemindAgain);
  const [showGradeModal, setShowGradeModal] = useState(false);
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const originText = window.location.origin;

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const checkIfQuizQuestionsSave = () => {
    return (
      values?.questions.filter(question => question?.isDeleted === false)?.length > 0 &&
      values?.isSaved &&
      (values.isPassingGradeRequired === true ||
        ((!values.noResultData?.isCutomButtonEnabled ||
          (values.noResultData?.buttonLink?.length > 0 &&
            values.noResultData?.buttonLink != null &&
            values.noResultData?.buttonText?.length > 0)) &&
          (!values.noResultData?.isImageEnabled || values.noResultData?.image?.length > 0) &&
          values.noResultData?.percentageText?.length > 0 &&
          values.noResultData?.headerText?.length > 0)) &&
      (values.isPassingGradeRequired === false ||
        ((!values.passResultData?.isCutomButtonEnabled ||
          (values.passResultData?.buttonLink?.length > 0 &&
            values.passResultData?.buttonLink != null &&
            values.passResultData?.buttonText?.length > 0)) &&
          (!values.passResultData?.isImageEnabled || values.passResultData?.image?.length > 0) &&
          values.passResultData?.percentageText?.length > 0 &&
          values.passResultData?.headerText?.length > 0)) &&
      (values.isPassingGradeRequired === false ||
        ((!values.failResultData?.isCutomButtonEnabled ||
          (values.failResultData?.buttonLink?.length > 0 &&
            values.failResultData?.buttonLink != null &&
            values.failResultData?.buttonText?.length > 0)) &&
          (!values.failResultData?.isImageEnabled || values.failResultData?.image?.length > 0) &&
          values.failResultData?.percentageText?.length > 0 &&
          values.failResultData?.headerText?.length > 0)) &&
      values?.questions
        .filter(question => question?.isDeleted === false)
        ?.every(
          item =>
            item.id != undefined &&
            convertToPlainText(item?.name)?.length > 0 &&
            item?.options?.length > 0 &&
            item.options.some(option => option.isCorrect),
        )
    );
  };

  const checkIfQuizQuestionsSaveForm = () => {
    console.log(
      'validation---->22',
      values?.questions?.length > 0,
      values?.isSaved,
      values?.questions
        .filter(question => question?.isDeleted === false)
        ?.every(
          item => item.id != undefined && convertToPlainText(item?.name)?.length > 0 && item?.options?.length > 0,
        ),
    );
    return (
      values?.questions.filter(question => question?.isDeleted === false)?.length > 0 &&
      values?.isSaved &&
      values?.questions
        .filter(question => question?.isDeleted === false)
        ?.every(item => item.id != undefined && convertToPlainText(item?.name)?.length > 0 && item?.options?.length > 0)
    );
  };
  const copyToClipboard = async text => {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };
  const validateArray = () => {
    return (
      values?.questions
        .filter(question => question?.isDeleted === false)
        ?.every(
          item =>
            convertToPlainText(item?.name)?.length > 0 &&
            item?.options?.length > 0 &&
            item.options.some(option => option.isCorrect),
        ) &&
      values?.title?.length > 0 &&
      values?.isSaved === false
    );
  };
  // const validateArrayForm = () => {
  //   return (
  //     values?.questions
  //       .filter(question => question?.isDeleted === false)
  //       ?.every(
  //         question =>
  //           question?.name?.length > 0 &&
  //           question?.name?.length < 200 &&
  //           question?.options?.length > 0 &&
  //           question.options.every(option => option.value.length > 0 && option.value.length < 21),
  //       ) &&
  //     values?.title?.length > 0 &&
  //     values?.isSaved === false
  //   );
  // };

  const validateArrayForm = () => {
    return (
      values?.questions
        .filter(question => question?.isDeleted === false)
        ?.every(question => {
          const isNameValid = question?.name?.length > 0 && question?.name?.length < 200;

          const hasValidOptions =
            question?.options?.length > 0 &&
            question.options.every(option => option.value.length > 0 && option.value.length < 21);

          const isShortQuestionValid =
            question?.questionType !== 'ShortText' ||
            question.options[0]?.isCorrect === false ||
            (question.options[0]?.isCorrect === true && parseInt(question.options[0]?.value) > 0);
          console.log('isShortQuestionValid', isShortQuestionValid);
          return isNameValid && hasValidOptions && isShortQuestionValid;
        }) &&
      values?.title?.length > 0 &&
      values?.isSaved === false
    );
  };

  const saveQuiz = () => {
    if (values?.passingPercentage <= 0 && values?.isPassingGradeRequired === true && values.type === 'Quiz') {
      setShowGradeModal(true);
    } else {
      if (activeQuiz.isPublished === true) {
        if (activeQuiz.dontRemindAgain === false) {
          setShowUpdateShareModal(true);
        } else {
          let updatedQuestions = values?.questions?.map(question => ({
            ...question,
            isDraft: question?.isDraft,
          }));
          let Form = {
            ...values,
            questions: updatedQuestions,
          };
          createUpdateQuiz(Form).then(res => {
            setFieldValue('isSaved', true);
            setFieldValue('questions', res.questions);
            dispatch(
              setActiveQuiz({
                ...res,
                isSaved: true,
              }),
            );
          });
        }
      } else {
        let updatedQuestions = values?.questions?.map(question => ({
          ...question,
          isDraft: question?.isDraft,
        }));
        let Form = {
          ...values,
          questions: updatedQuestions,
        };
        createUpdateQuiz(Form).then(res => {
          setFieldValue('isSaved', true);
          setFieldValue('questions', res.questions);
          dispatch(
            setActiveQuiz({
              ...res,
              isSaved: true,
            }),
          );
        });
      }
    }
  };

  const publishQuiz = () => {
    setLoader(true);
    let updatedQuestions = values?.questions
      .filter(question => question?.name?.length > 0 && question?.options?.length > 0 && question.isDeleted === false)
      .map(question => ({
        ...question,
        isDraft: false,
      }));
    let Form = {
      ...values,
      questions: updatedQuestions,
      isPublished: true,
    };
    createUpdateQuiz(Form)
      .then(res => {
        setLoader(false);
        dispatch(
          setActiveQuiz({
            ...res,
            isSaved: true,
          }),
        );
        setShowShareModal(true);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.buttons_container}>
          <Button
            className={classes.preview_btn}
            variant="secondary"
            autoWidth
            invert
            borderColor={
              values.type === 'Quiz'
                ? !checkIfQuizQuestionsSave()
                  ? ''
                  : '#D1B989'
                : !checkIfQuizQuestionsSaveForm()
                ? ''
                : '#D1B989'
            }
            textColor={'#D1B989'}
            disabled={values.type === 'Quiz' ? !checkIfQuizQuestionsSave() : !checkIfQuizQuestionsSaveForm()}
            onClick={() => {
              if (values.type === 'Quiz') {
                if (isEditing) {
                  openInNewWindow(`/edit-quiz/${activeQuiz?.id}/preview`);
                } else {
                  openInNewWindow(`/create-quiz/preview/${activeQuiz?.id}`);
                }
              } else {
                if (isEditing) {
                  openInNewWindow(`/edit-form/${activeQuiz?.id}/preview`);
                } else {
                  openInNewWindow(`/create-form/preview/${activeQuiz?.id}`);
                }
              }
            }}
          >
            Preview
          </Button>
          {values.type === 'Quiz' ? (
            validateArray() === false ? (
              <Tooltip
                title="Please ensure each question has multiple options and one marked as the correct answer before saving the quiz."
                arrow
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              >
                <div>
                  <Button
                    className={classes.save_quiz_btn}
                    variant="secondary"
                    disabled={validateArray() === false ? true : false}
                    autoWidth
                    onClick={saveQuiz}
                  >
                    {isEditing
                      ? 'Saved'
                      : validateArray() === false && activeQuiz?.questions?.length > 0
                      ? `Saved`
                      : `Save Quiz`}
                  </Button>
                </div>
              </Tooltip>
            ) : (
              <Button
                className={classes.save_quiz_btn}
                variant="secondary"
                disabled={false}
                autoWidth
                onClick={saveQuiz}
              >
                {`Save Quiz`}
              </Button>
            )
          ) : validateArrayForm() === false ? (
            <Tooltip
              title="Please ensure each question has multiple options before saving the form."
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <div>
                <Button
                  className={classes.save_quiz_btn}
                  variant="secondary"
                  disabled={validateArrayForm() === false ? true : false}
                  autoWidth
                  onClick={saveQuiz}
                >
                  {isEditing
                    ? 'Saved'
                    : validateArrayForm() === false && activeQuiz?.questions?.length > 0
                    ? `Saved`
                    : `Save Form`}
                </Button>
              </div>
            </Tooltip>
          ) : (
            <Button className={classes.save_quiz_btn} variant="secondary" disabled={false} autoWidth onClick={saveQuiz}>
              {`Save Form`}
            </Button>
          )}
          <Button
            className={classes.publish_btn}
            variant="secondary"
            autoWidth
            disabled={values.type === 'Quiz' ? !checkIfQuizQuestionsSave() : !checkIfQuizQuestionsSaveForm()}
            onClick={publishQuiz}
          >
            {loader ? <CircularProgress size={20} /> : `Publish`}
          </Button>
        </div>
      </div>
      <Modal
        isOpen={showShareModal}
        onSubmit={() => {
          setShowShareModal(false);
        }}
        title={`Your ${activeQuiz?.type === 'Quiz' ? 'Quiz' : 'Form'} has Been Published!`}
        disableConfirm={true}
        cancelTitle={'Close'}
        onCancel={() => {
          setShowShareModal(false);
        }}
        mobileView={mobileView}
        modalType={'Quizes'}
        maxWidth={mobileView ? '350px' : null}
        disableFooter={true}
      >
        {/* <div
          style={{ fontWeight: '800', fontSize: '18px', fontFamily: 'Avenir', color: '#000000', marginBottom: '10px' }}
        >
          Here’s what you can do next:
        </div> */}
        <div
          style={{ fontWeight: '800', fontSize: '16px', fontFamily: 'Avenir', color: '#1E1E1E', marginBottom: '10px' }}
        >
          Copy Shareable Link
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '12px' }}>
          {mobileView ? (
            <StyledHostNameMobile>
              {values.type === 'Quiz'
                ? `${originText}/quiz-view/${activeQuiz?.id}`
                : `${originText}/form-view/${activeQuiz?.id}`}
            </StyledHostNameMobile>
          ) : (
            <StyledHostName>
              {values.type === 'Quiz'
                ? `${originText}/quiz-view/${activeQuiz?.id}`
                : `${originText}/form-view/${activeQuiz?.id}`}
            </StyledHostName>
          )}
          {!mobileView && (
            <Tooltip
              // className="cursor-pointer"
              title="Copied To Clipboard"
              open={isToolTipOpen}
              onClose={() => {
                setIsToolTipOpen(false);
              }}
              arrow
            >
              <Button
                style={{ height: '40px', width: '100px', padding: '11px 30.23px 11px 30.59px', fontSize: '14px' }}
                onClick={() => {
                  setIsToolTipOpen(true);
                  setTimeout(() => {
                    copyToClipboard(
                      values.type === 'Quiz'
                        ? `${originText}/quiz-view/${activeQuiz?.id}`
                        : `${originText}/form-view/${activeQuiz?.id}`,
                    );
                  }, 200);
                }}
                textColor="#fff"
                autoWidth
              >
                Copy
              </Button>
            </Tooltip>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {mobileView && (
            <Tooltip
              // className="cursor-pointer"
              title="Copied To Clipboard"
              open={isToolTipOpen}
              onClose={() => {
                setIsToolTipOpen(false);
              }}
              arrow
            >
              <Button
                style={{
                  padding: '11px 20.23px 11px 20.59px',
                  fontSize: '10px',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
                onClick={() => {
                  setIsToolTipOpen(true);
                  setTimeout(() => {
                    copyToClipboard(
                      values.type === 'Quiz'
                        ? `${originText}/quiz-view/${activeQuiz?.id}`
                        : `${originText}/form-view/${activeQuiz?.id}`,
                    );
                  }, 200);
                }}
                textColor="#fff"
                autoWidth
              >
                Copy
              </Button>
            </Tooltip>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={showUpdateShareModal}
        mobileView={mobileView}
        onSubmit={() => {
          let updatedQuestions = values?.questions?.map(question => ({
            ...question,
            isDraft: question?.isDraft,
          }));
          let Form = {
            ...values,
            questions: updatedQuestions,
            dontRemindAgain: dontRemindAgain,
          };
          createUpdateQuiz(Form).then(res => {
            setFieldValue('isSaved', true);
            setFieldValue('questions', res.questions);
            dispatch(
              setActiveQuiz({
                ...res,
                isSaved: true,
              }),
            );
          });
          setShowUpdateShareModal(false);
        }}
        title={`Save ${activeQuiz.type}`}
        cancelTitle={'Cancel'}
        onCancel={() => {
          setShowUpdateShareModal(false);
        }}
        modalType={'Quizes'}
        maxWidth={'900px'}
        submitTitle={'Save'}
      >
        <div className={classes.remind_again}>
          Any further changes made to the {activeQuiz.type === 'Quiz' ? 'quiz' : 'form'} will only affect future users
          taking the {activeQuiz.type === 'Quiz' ? 'quiz' : 'form'}, not those who have already completed the previous
          version.
          <FormControlLabel
            className={classes.MuiFormControlLabel_root}
            checked={dontRemindAgain}
            onChange={() => {
              setDontRemindAgain(!dontRemindAgain);
              updateRemindAhain(activeQuiz.id, !dontRemindAgain).then(res => {
                // setFieldValue('questions', res.questions);
                setFieldValue('dontRemindAgain', res.dontRemindAgain);
                dispatch(
                  setActiveQuiz({
                    ...values,
                    dontRemindAgain: res.dontRemindAgain,
                  }),
                );
              });
            }}
            control={
              <Checkbox
                classes={{
                  root: classes.checkbox_root,
                }}
                color="primary"
              />
            }
            label={'Don’t remind me again'}
            name="default"
          />
        </div>
      </Modal>
      <Modal
        isOpen={showGradeModal}
        onSubmit={() => {
          setShowGradeModal(false);
        }}
        title={`Passing Grade Required`}
        disableConfirm={true}
        cancelTitle={'Close'}
        onCancel={() => {
          setShowGradeModal(false);
        }}
        mobileView={mobileView}
        modalType={'Quizes'}
        maxWidth={mobileView ? '350px' : null}
        disableFooter={true}
      >
        <div style={{ marginTop: '20px' }}>
          You have activated passing grade required and your passing grade should be greater than 0.
        </div>
      </Modal>
    </>
  );
};

export default MobileHeader;
