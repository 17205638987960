import { useMediaQuery } from '@material-ui/core';
import { setActiveQuiz, setShowCreateModal } from 'actions/quizes';
import { useHeader } from 'hooks/usePageActions';
import CreateQuizesModal from 'pages/CreateQuizes/Components/Modals/CreateModal/CreateModal';
import PreviewPage from 'pages/CreateQuizes/Pages/PreviewPage/PreviewPage';
import ContentPage from 'pages/CreateQuizes/Pages/QuizContent/ContentPage';
import { getClientQuizFormById } from 'pages/CreateQuizes/Services/CreateQuizes.service';
import ClientQuizPage from 'pages/QuizViewPage/Pages/QuizPage/QuizPage';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
function CreateQuizContainer({ match: { path } }) {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { showModal } = useSelector(state => state.quizes);
  const mobileView = useMediaQuery('(max-width: 1200px)');
  return (
    <>
      <Switch>
        <Route path={`${path}/content`} exact component={ContentPage} />
        <Route path={`${path}/preview/:id?`} exact component={ClientQuizPage} />
        <Route path={`${path}/result`} exact component={ContentPage} />
        <Redirect to={`${path}/content`} />
      </Switch>
    </>
  );
}
export default CreateQuizContainer;
