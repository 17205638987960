import React from 'react';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';
import { downloadFile } from 'utils/utils';
import { getNPSFeedbackExportFile } from 'services/user.service';
import useNotifications from 'hooks/useNotifications';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  margin-top: 1.5rem;
`;

const StyledCardsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 2rem;
  flex-wrap: wrap;
  row-gap: 1rem;
`;

const StyledCard = styled.div`
  border-radius: 0.25rem;
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  background: #fff;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.15rem;
  flex: 1 0 0;
  overflow: hidden;

  min-width: 20rem;
  min-height: 8rem;
  max-width: 13.3258875rem;

  .stats-row-names {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .stats-type {
    color: black;
    font-family: Avenir;
    font-size: 1.05rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
  }

  .stats-row-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

const typeToFunctionAndFileName = {
  'NPS Score': {
    func: getNPSFeedbackExportFile,
    fileName: 'NPS_Score.csv',
    type: 'Platform',
  },
  'Create a Contribution Feedback': {
    func: getNPSFeedbackExportFile,
    fileName: 'ContributionFeedback.csv',
    type: 'Contribution',
  },
  'Create a Lead Magnet Feedback': {
    func: getNPSFeedbackExportFile,
    fileName: 'LeadMagnetFeedback.csv',
    type: 'LeadMagnet',
  },
  'Create an Email Campaign Feedback': {
    func: getNPSFeedbackExportFile,
    fileName: 'EmailCampaignFeedback.csv',
    type: 'EmailCampaign',
  },
  'Sessions Feedback': {
    func: getNPSFeedbackExportFile,
    fileName: 'SessionsFeedback.csv',
    type: 'Sessions',
  },
};

export const EngagementData = ({}) => {
  const cards = Object.keys(typeToFunctionAndFileName).map(type => ({
    type,
  }));
  const { successMessage, infoMessage, errorMessage } = useNotifications();

  return (
    <StyledContainer>
      <StyledCardsContainer>
        {cards.map((k, inx) => (
          <StyledCard key={inx}>
            <div className="stats-row-names">
              <div className="stats-type">{k.type}</div>
            </div>
            <div className="stats-row-buttons">
              <Button
                autoWidth
                onClick={async () => {
                  const { func, fileName, type } = typeToFunctionAndFileName[k.type];
                  if (func) {
                    try {
                      const res = await func(type);
                      if (res) {
                        downloadFile(res, fileName);
                        return successMessage('Success', 'Feedback file downloaded successfully');
                      }
                    } catch (error) {
                      return infoMessage('Info', 'No feedback is available to export.');
                    }
                  }
                }}
              >
                Download
              </Button>
            </div>
          </StyledCard>
        ))}
      </StyledCardsContainer>
    </StyledContainer>
  );
};
