import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/FormUI/Button';
import styled, { css } from 'styled-components';
import { Typography, MenuItem, FormControl, Select, InputLabel, Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from 'utils/styles';
import { SmsBillingServices } from './Services/SmsBillingServices';
import Loader from 'components/UI/Loader';
import { redirectTo } from 'services/links';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import BasicSmsCreditsInfo from './BasicSmsCreditsInfo';
import { Switch, StyledInput, StyledSlider } from '../../../../components/switch/style';
import useNotifications from 'hooks/useNotifications';
import { useLocation, useHistory } from 'react-router-dom';

const commonStyles = {
  fontFamily: 'Avenir',
  fontSize: '16px',
};

const commonBorderLineStyles = {
  borderLeft: '1px solid #E7E7E7',
  borderRight: '1px solid #E7E7E7',
  borderBottom: '1px solid #E7E7E7',
};

const useStyles = makeStyles(theme => ({
  smsCreditsTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '10px',
    margin: '15px 0px',
  },
  backButton: {
    fontSize: 'medium',
  },
  common: {
    fontFamily: 'Avenir',
    fontSize: '16px',
  },
  addsOnTitle: {
    ...commonStyles,
    fontWeight: '400',
  },
  addsOnSlash: {
    ...commonStyles,
    fontWeight: '500',
  },
  creditsTitle: {
    ...commonStyles,
    fontWeight: '800',
    color: '#215C73',
  },
  creditsMainContainer: {
    display: 'flex',
    padding: '10px 20px 10px 20px',
    borderTop: '1px solid #E7E7E7',
    borderLeft: '1px solid #E7E7E7',
    borderRight: '1px solid #E7E7E7',
    borderBottom: '1px solid #E7E7E7',
    gap: '10px',
    flexDirection: 'column',
  },
  creditsSubContainer: {
    display: 'flex',
    gap: '10px',
  },
  purchasedCreditsInfo: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textAlign: 'left',
  },
  purchasedCreditsInfoSpan: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: '300',
    marginLeft: '4px',
  },
  addCreditsHeaderSection: {
    border: '1px solid #E7E7E7',
    marginTop: '15px',
    padding: '10px 20px 10px 20px',
  },
  addCreditsDropdown: {
    padding: '10px 20px 10px 20px',
    ...commonBorderLineStyles,
  },
  credits: {
    color: '#215C73',
    fontWeight: '900',
    fontSize: '18px',
  },
  button: {
    backgroundColor: '#D6C49A',
    color: '#000',
    '&:hover': {
      backgroundColor: '#C5B08A',
    },
  },
  formControl: {
    width: '100%',
    marginBottom: '10px',
  },
  switchControl: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  processPaymentButtonWrapper: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
  },
  creditsDropdown: {
    fontFamily: 'Avenir',
    border: '1px solid #DFE3E4',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: '350',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    width: '35%',
    height: '43px',
    marginBottom: '10px',
  },
  autoRechargeWrapper: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    padding: '10px 20px 10px 20px',
    border: '1px solid #E7E7E7',
    marginTop: '18px',
  },
  autoRechargeOptionsMainWrapper: {
    padding: '10px 20px 10px 20px',
    ...commonBorderLineStyles,
  },
  autoRechargeOptionsWrapper: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  autoRechargeCreditsInfo: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textAlign: 'left',
    marginBottom: '10px',
  },
  autoRechargeCreditsDropdown: {
    fontFamily: 'Avenir',
    border: '1px solid #DFE3E4',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: '350',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    width: '80%',
    height: '43px',
    marginBottom: '6px',
  },
  autoRechargeRadioButton: {
    marginBottom: '0px',
  },
}));

const SelectStyled = styled(Select)`
  .MuiSelect-select {
    padding: 3px 10px;
  }
  .MuiSelect-select:focus {
    border-radius: 0;
    background-color: transparent;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  &:last-child {
    border-bottom: none;
  }
`;

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;
export const TOOLTIP = {
  ENTER_DELAY: 0,
  LEAVE_DELAY: 10000,
};
export const StyleddButtonProceedPayment = styled(Button)`
  min-width: 175px;
  min-height: 48px;
  font-size: 15px;
  font-weight: 800;
  padding: 12px 15px 12px 15px;
`;
export const StyleddButtonSaveChanges = styled(Button)`
  min-width: 129px;
  min-height: 48px;
  font-size: 15px;
  font-weight: 800;
  padding: 12px 15px 12px 15px;
`;
const ValidationMessage = styled.div`
  color: red;
  margin: 10px 0px 15px 0px;
`;

const AutoRechargeValidationMessage = styled.div`
  color: red;
  font-size: 14px;
`;
const customIcon = (
  <svg
    viewBox="0 0 24 24"
    role="presentation"
    className="MuiSelect-icon"
    style={{ width: '1.5rem', height: '1.5rem', marginRight: '9px', opacity: '40%' }}
  >
    <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" style={{ fill: 'black' }} />
  </svg>
);

const DetailedSmsCreditsInfo = ({ smsCredits }) => {
  const classes = useStyles();
  const [autoRecharge, setAutoRecharge] = useState(false);
  const [creditPlanId, setCreditPlanId] = useState(0);
  const [smsPlans, setSmsPlans] = useState([]);
  const [smsAutoRechargeSettings, setSmsAutoRechargeSettings] = useState({
    threshHoldPlanId: 0,
    rechargerPlanId: 0,
    IsAutoChargeEnabled: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [onBack, setOnBack] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [showThreshHoldValidation, setShowThreshHoldValidation] = useState(false);
  const [showRechargerValidation, setShowRechargerValidation] = useState(false);
  const validationMessage = 'Please select a valid plan';
  const validateSelection = value => {
    return value !== 0 && value !== null && value.trim() !== '';
  };
  const { successMessage, errorMessage } = useNotifications();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const resultType = queryParams.get('success');

  const fetchSmsPlans = async () => {
    try {
      const response = await SmsBillingServices.GetSmsPlans();
      setSmsPlans(response);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching SMS plans:', error);
      setIsLoading(false);
    }
  };
  const fetchAutoRechargeSettings = async () => {
    try {
      const response = await SmsBillingServices.GetAutoRechargeSettings();
      setIsLoading(false);
      setAutoRecharge(response.isAutoChargeEnabled);
      setSmsAutoRechargeSettings(response);
    } catch (error) {
      console.error('Error fetching SMS plans:', error);
      setIsLoading(false);
    }
  };
  const EnableDisableAutoChargeOption = async autoCharge => {
    try {
      setAutoRecharge(autoCharge);
      const autoRechargeSettings = await SmsBillingServices.GetAutoRechargeSettings();
      setSmsAutoRechargeSettings(prevSettings => ({
        ...prevSettings,
        isAutoChargeEnabled: autoCharge,
      }));
      let threshHoldPlanValidator =
        smsAutoRechargeSettings.threshHoldPlanId == '' ||
        smsAutoRechargeSettings.threshHoldPlanId == null ||
        smsAutoRechargeSettings.threshHoldPlanId == 0;

      let rechargerPlanIdValidator =
        smsAutoRechargeSettings.rechargerPlanId == '' ||
        smsAutoRechargeSettings.rechargerPlanId == null ||
        smsAutoRechargeSettings.rechargerPlanId == 0;

      if (!autoRechargeSettings.isAutoChargeEnabled && threshHoldPlanValidator && rechargerPlanIdValidator) {
        setIsLoading(false);
        setAutoRecharge(true);
      } else {
        const response = await SmsBillingServices.SetAutoRechargeOption(autoCharge);
        setIsLoading(false);
        setAutoRecharge(response.isAutoChargeEnabled);
        setSmsAutoRechargeSettings(prevSettings => ({
          ...prevSettings,
          isAutoChargeEnabled: autoCharge,
        }));
      }
    } catch (error) {
      console.error('Error fetching SMS plans:', error);
      setIsLoading(false);
    }
  };
  const fetchSmsPlanPurchaseUrl = async () => {
    try {
      if (!validateSelection(creditPlanId)) {
        setShowValidation(true);
        return;
      }
      if (creditPlanId != '' && creditPlanId != null && creditPlanId != 0) {
        var requestBody = {
          smsPlanId: creditPlanId,
        };
        const response = await SmsBillingServices.CreateCheckoutSession(requestBody);
        return redirectTo(response);
      }
    } catch (error) {
      console.error('Error fetching SMS plans:', error);
    }
  };
  const SaveAutoRechargeSettings = async () => {
    try {
      const { threshHoldPlanId, rechargerPlanId } = smsAutoRechargeSettings;
      // Validate threshold plan selection
      if (!validateSelection(threshHoldPlanId)) {
        setShowThreshHoldValidation(true);
      }

      // Validate recharge plan selection
      if (!validateSelection(rechargerPlanId)) {
        setShowRechargerValidation(true);
        return;
      }

      const threshHoldPlanValidator = validateSelection(threshHoldPlanId);
      const rechargerPlanIdValidator = validateSelection(rechargerPlanId);

      if (threshHoldPlanValidator && rechargerPlanIdValidator) {
        const smsPurchase = await SmsBillingServices.GetSmsPurchase();

        // Function to handle auto-recharge settings
        const saveAutoRechargeSettings = async () => {
          const response = await SmsBillingServices.SetSmsCreditsAutoRechargeSettings(
            threshHoldPlanId,
            rechargerPlanId,
          );
          setSmsAutoRechargeSettings(response);
          successMessage('Success', 'Settings saved successfully');
        };

        if (!smsPurchase.isPurchaseFound) {
          const requestBody = { smsPlanId: rechargerPlanId };
          const response = await SmsBillingServices.CreateCheckoutSession(requestBody);
          await saveAutoRechargeSettings();
          redirectTo(response);
        } else {
          await saveAutoRechargeSettings();
        }
      }
    } catch (error) {
      console.error('Error fetching SMS plans:', error);
      errorMessage();
    }
  };

  useEffect(() => {
    fetchSmsPlans();
    fetchAutoRechargeSettings();
  }, []);
  const handleCreditPlanChange = event => {
    setCreditPlanId(event.target.value);
  };
  const handlePlanPayment = event => {
    fetchSmsPlanPurchaseUrl();
  };
  const handleThreshHoldPlanChange = event => {
    const { value } = event.target;
    setSmsAutoRechargeSettings(prevState => ({
      ...prevState,
      threshHoldPlanId: value,
    }));
  };
  const handleRechargerPlanChange = event => {
    const { value } = event.target;
    setSmsAutoRechargeSettings(prevState => ({
      ...prevState,
      rechargerPlanId: value,
    }));
  };
  const handleSaveAutoRechargeSettings = event => {
    SaveAutoRechargeSettings();
  };
  const handleAutoRechargeOption = event => {
    EnableDisableAutoChargeOption(event.target.checked);
  };
  const handleOnBack = event => {
    setOnBack(true);
  };
  if (isLoading) {
    return <Loader />;
  }
  if (onBack) {
    return <BasicSmsCreditsInfo />;
  }
  return (
    <div>
      <div className={classes.smsCreditsTitleContainer} onClick={handleOnBack}>
        <ArrowBackIosIcon className={classes.backButton} />
        <Typography className={classes.addsOnTitle}>Credit & Add-ons</Typography>
        <span className={classes.addsOnSlash}>/</span>
        <Typography className={classes.creditsTitle}>SMS Credits</Typography>
      </div>
      <div className={classes.creditsMainContainer}>
        {smsCredits.isAcceleratePlanUser && (
          <div className={classes.creditsSubContainer}>
            <Typography className={classes.purchasedCreditsInfo}>
              Monthly Complementary Credits<span className={classes.purchasedCreditsInfoSpan}>(expire each month)</span>
              :
            </Typography>
            <Typography className={classes.credits}>
              {smsCredits.totalRemainingComplementaryCredits > 0
                ? smsCredits.totalRemainingComplementaryCredits.toLocaleString()
                : 'No credits remaining'}
            </Typography>
          </div>
        )}
        <div className={classes.creditsSubContainer}>
          <Typography className={classes.purchasedCreditsInfo}>Remaining Purchased Credits:</Typography>
          <Typography className={classes.credits}>
            {smsCredits.totalRemainingPurchasedCredits > 0
              ? `${smsCredits.totalRemainingPurchasedCredits.toLocaleString()} ($${smsCredits.totalRemainingPurchasedCreditsAmount.toLocaleString()})`
              : 'No credits purchased'}
          </Typography>
          <StyledTooltip
            title="Looking for a receipt? Please check your email at the moment of your purchase."
            arrow
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          >
            <InfoIcon htmlColor={colors.lightBrown} />
          </StyledTooltip>
        </div>
        <div className={classes.creditsSubContainer}>
          <Typography className={classes.purchasedCreditsInfo}>Total Credits:</Typography>
          <Typography className={classes.credits}>
            {smsCredits.totalRemainingCredits > 0
              ? smsCredits.totalRemainingCredits.toLocaleString()
              : 'No credits purchased'}
          </Typography>
        </div>
      </div>

      <div className={classes.addCreditsHeaderSection}>
        <Typography className={classes.purchasedCreditsInfo}>Add Credits</Typography>
      </div>
      <div className={classes.addCreditsDropdown}>
        <SelectStyled
          className={classes.creditsDropdown}
          onChange={handleCreditPlanChange}
          fullWidth
          value={creditPlanId}
          disableUnderline
          IconComponent={() => customIcon}
        >
          <StyledMenuItem value={0}> -- Select --</StyledMenuItem>
          {smsPlans.map(({ id, productName }) => (
            <StyledMenuItem key={id} value={id}>
              {productName}
            </StyledMenuItem>
          ))}
        </SelectStyled>
        {showValidation && !validateSelection(creditPlanId) && (
          <ValidationMessage>{validationMessage}</ValidationMessage>
        )}
        <div className={classes.processPaymentButtonWrapper}>
          <StyleddButtonProceedPayment onClick={handlePlanPayment}>Proceed To Payment</StyleddButtonProceedPayment>
        </div>
      </div>
      <div className={classes.autoRechargeWrapper}>
        <Typography className={classes.purchasedCreditsInfo}>Set up auto-recharge?</Typography>
        <Switch className={classes.autoRechargeRadioButton}>
          <StyledInput
            value={autoRecharge}
            type="checkbox"
            checked={smsAutoRechargeSettings.isAutoChargeEnabled}
            onChange={handleAutoRechargeOption}
          />
          <StyledSlider className="slider round" />
        </Switch>
        <StyledTooltip
          title="Get a credit refill whenever your existing SMS credits run out. Your credit card will automatically get charged to refill your credits on Cohere."
          arrow
          enterTouchDelay={TOOLTIP.ENTER_DELAY}
          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
        >
          <InfoIcon htmlColor={colors.lightBrown} />
        </StyledTooltip>
      </div>
      {autoRecharge && smsAutoRechargeSettings.isAutoChargeEnabled && (
        <div className={classes.autoRechargeOptionsMainWrapper}>
          <div className={classes.autoRechargeOptionsWrapper}>
            <FormControl className={classes.formControl} style={{ height: 'auto' }}>
              <Typography className={classes.autoRechargeCreditsInfo}>
                Automatically recharge my account when balance is below
              </Typography>
              <SelectStyled
                className={classes.autoRechargeCreditsDropdown}
                onChange={handleThreshHoldPlanChange}
                value={
                  smsAutoRechargeSettings.threshHoldPlanId == '' || smsAutoRechargeSettings.threshHoldPlanId == null
                    ? 0
                    : smsAutoRechargeSettings.threshHoldPlanId
                }
                fullWidth
                disableUnderline
                IconComponent={() => customIcon}
              >
                <StyledMenuItem value={0}> -- Select --</StyledMenuItem>
                {smsPlans.map(({ id, productName }) => {
                  const credits = productName.match(/\(([^)]+)\)/)[1];
                  return (
                    <StyledMenuItem key={id} value={id}>
                      {credits}
                    </StyledMenuItem>
                  );
                })}
              </SelectStyled>
              <div style={{ height: '1.5em' }}>
                {showThreshHoldValidation && !validateSelection(smsAutoRechargeSettings.threshHoldPlanId) && (
                  <AutoRechargeValidationMessage>{validationMessage}</AutoRechargeValidationMessage>
                )}
              </div>
            </FormControl>
            <FormControl className={classes.formControl} style={{ height: 'auto' }}>
              <Typography className={classes.autoRechargeCreditsInfo}>Recharge with amount</Typography>
              <SelectStyled
                className={classes.autoRechargeCreditsDropdown}
                onChange={handleRechargerPlanChange}
                value={
                  smsAutoRechargeSettings.rechargerPlanId == '' || smsAutoRechargeSettings.rechargerPlanId == null
                    ? 0
                    : smsAutoRechargeSettings.rechargerPlanId
                }
                fullWidth
                disableUnderline
                IconComponent={() => customIcon}
              >
                <StyledMenuItem value={0}> -- Select --</StyledMenuItem>
                {smsPlans.map(({ id, productName }) => (
                  <StyledMenuItem key={id} value={id}>
                    {productName}
                  </StyledMenuItem>
                ))}
              </SelectStyled>
              <div style={{ height: '1.5em' }}>
                {showRechargerValidation && !validateSelection(smsAutoRechargeSettings.rechargerPlanId) && (
                  <AutoRechargeValidationMessage>{validationMessage}</AutoRechargeValidationMessage>
                )}
              </div>
            </FormControl>
          </div>
          <div className={classes.processPaymentButtonWrapper}>
            <StyleddButtonSaveChanges onClick={handleSaveAutoRechargeSettings}>Save Changes</StyleddButtonSaveChanges>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailedSmsCreditsInfo;
