import React, { useEffect, useState } from 'react';
import classes from './TemplatePage.module.scss';
import { Formik, Form } from 'formik';
import CreateCampaignMain from 'pages/CreateCampaign/Components/Containers/MainContainer/MainContainer';
import Stepper from 'pages/CreateCampaign/Components/Stepper/Stepper';
import useRouter from 'hooks/useRouter';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { useTheme, useMediaQuery, Box } from '@material-ui/core';
import Buttons from 'pages/CreateContribution/components/Buttons';
import mailIcon from 'assets/mailIcon.png';
import refreshMailIcon from 'assets/refreshMailIcon.png';
import Modal from 'components/UI/Modal';
import PreDesignModal from './PreDesignModal/PreDesignModal';
import useCampaign from 'pages/CreateCampaign/Hooks/useCampaign';
import Loader from 'components/UI/Loader';
import { createCampaignServices } from 'pages/CreateCampaign/Services/CreateCampaign.service';
import templateImage from 'assets/campaignTemplate.png';
import { saveCampaignToLS, setSendEmailTo } from 'actions/campaign';
import { CampaignStatus } from 'pages/CreateCampaign/Constants/constants';
import { useDispatch } from 'react-redux';
import { PhoneDesktop, PhonePreviewVector } from '../DesignPage/assests/SvgImages';
import { setUserTemplates } from 'actions/contacts';
import { useSelector } from 'react-redux';

const TemplateOptions = {
  Scratch: 'Scratch',
  Past: 'Past',
};
const TemplatePage = () => {
  const { history, location } = useRouter();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { query } = useRouter();
  const isEditing = !!query?.id;
  const { campaign, isCreateCampagnPage } = useCampaign();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openTemplatePreview, setOpenTemplatePreview] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [openPhonePreview, setPhonePreview] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(campaign?.emailTemplateId ?? null);
  const [prevSelectedTemplateId, setPrevSelectedTemplateId] = useState(null);
  const [userEmailtemplates, setUserEmailtemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTempOpt, setSelectedTempOpt] = useState(
    campaign?.emailTemplateId ? TemplateOptions.Past : TemplateOptions.Scratch,
  );
  const { userTemplates } = useSelector(state => state.contacts);
  console.log('selectedTempOpt->', selectedTempOpt);
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isScratchSelected = selectedTempOpt === TemplateOptions.Scratch;
  const isApprovedCampaign = campaign?.status === CampaignStatus.Approved;

  useEffect(() => {
    if (selectedTempOpt === TemplateOptions.Scratch) {
      setSelectedTemplateId(null);
    }
  }, [selectedTempOpt]);
  useEffect(() => {
    setIsLoading(true);
    createCampaignServices
      .getUserEmailTemplate()
      .then(res => {
        const newArray = [];
        for (let i = 0; i < res.length; i++) {
          newArray.push({
            id: res[i].id,
            emailContent: res[i].emailContent,
            emailSubject: res[i].emailSubject,
            title: res[i].title,
            src: templateImage,
          });
        }
        dispatch(setUserTemplates(newArray));
        setUserEmailtemplates(newArray);
      })
      .catch(error => {
        console.log('err', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isCreateCampagnPage && campaign === null) {
      history.replace(`/create-campaign/title`);
    }
  }, [campaign]);

  const handleShow = () => {
    setSelectedTempOpt(TemplateOptions.Past);
    setShow(!show);
  };

  const handleShowScratch = () => {
    setSelectedTempOpt(TemplateOptions.Scratch);
    setShow(!show);
  };

  const handleFromScratchClick = () => {
    setSelectedTempOpt(TemplateOptions.Scratch);
  };

  const handleNextClick = to => {
    setIsLoading(true);
    let data = {
      ...campaign,
      emailTemplateId: selectedTemplateId ? selectedTemplateId : null,
      emailContent: selectedTemplateId
        ? userEmailtemplates?.find(t => t.id === selectedTemplateId)?.emailContent
        : null,
    };

    if (isEditing && isApprovedCampaign) {
      createCampaignServices
        .updateApprovedCampaign(data)
        .then(res => {
          dispatch(saveCampaignToLS(res));
          history.replace(to || redirectTo);
        })
        .catch(err => {
          console.log('err', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      createCampaignServices
        .updateDraftCampaign(data)
        .then(res => {
          dispatch(saveCampaignToLS(res));
          history.replace(to || redirectTo);
        })
        .catch(err => {
          console.log('err', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (to === undefined) {
      setShow(false);
    }
    history.replace(redirectTo || to);
  };

  const handleBackClick = () => {
    dispatch(setSendEmailTo(null));
    history.goBack();
    if (history.length === 1) {
      history.push('/dashboard');
    }
  };

  const handleSubmit = () => {
    if (selectedTemplateId === null) {
      setShow(false);
      handleFromScratchClick();
    } else {
      const to = isEditing ? `/edit-campaign/${query?.id}/contacts` : '/create-campaign/contacts';
      // setRedirectTo(to);

      handleNextClick();
    }
  };
  useEffect(() => {
    if (campaign?.emailTemplateId != null) {
      setSelectedTemplate(userTemplates.find(t => t.id === campaign.emailTemplateId));
    }
  }, [campaign, userTemplates]);

  console.log(
    'camapign----->',
    selectedTemplateId,
    userEmailtemplates?.find(t => t.id === selectedTemplateId)?.emailContent,
  );
  return (
    <>
      {isLoading && <Loader />}
      <CreateCampaignMain
        headerProps={{
          handleBackClick,
        }}
      >
        <Card id={'createCampaign'} mobileView={mobileView}>
          <CardHeader mobileView={mobileView}>
            <div className={classes.stepper_container}>
              <Stepper
                activeStep={1}
                handleSubmit={({ to }) => {
                  setRedirectTo(to);
                  handleNextClick(to);
                }}
              />
            </div>
          </CardHeader>
          <CardBody className={classes.form_body} mobileView={mobileView}>
            <div className={classes.form_container}>
              <div className={classes.title_class}>Choose Template</div>
              <div className={classes.cards_container}>
                <div
                  className={`${classes.scratch_card} ${isScratchSelected ? classes.selected : ''}`}
                  onClick={handleFromScratchClick}
                >
                  <img src={mailIcon} />
                  <p className={classes.heading}>Create From Scratch</p>
                  <p className={classes.text}>Start from scratch and design your custom email</p>
                </div>
                <div
                  className={`${classes.pre_design_card} ${!isScratchSelected ? classes.selected : ''}`}
                  onClick={handleShow}
                >
                  <img src={refreshMailIcon} />
                  <p className={classes.heading}>Choose a Past Email</p>
                  {campaign?.emailTemplateId != null ? (
                    <p className={classes.text}>{selectedTemplate?.title} Selected</p>
                  ) : (
                    <p className={classes.text}>Select one of your previously designed campaigns</p>
                  )}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Buttons
          backButtonTitle="Back"
          nextButtonTitle="Save and Next"
          formId="create-campaign-form"
          onNextClickHandler={() => {
            const to = isEditing ? `/edit-campaign/${query?.id}/contacts` : '/create-campaign/contacts';
            setRedirectTo(to);
            handleNextClick(to);
          }}
          backClickHandler={() => {
            history.replace(isEditing ? `/edit-campaign/${query?.id}/title` : '/create-campaign/title');
          }}
          disabled={isLoading}
        />
      </CreateCampaignMain>
      <Modal
        title="Past Campaigns"
        widthRequired
        onCancel={() => {
          handleShow();
          if (campaign.emailTemplateId === null || selectedTemplateId === null) {
            handleFromScratchClick();
          }
        }}
        submitTitle="Select"
        onSubmit={handleSubmit}
        isOpen={show}
        disableSubmitClick={selectedTemplateId === null ? true : false}
        reduceCancelButton
      >
        <PreDesignModal
          items={userEmailtemplates}
          selectedItem={selectedTemplateId}
          setSelectedItem={setSelectedTemplateId}
          handleShowScratch={handleShowScratch}
          onPreview={id => {
            setPrevSelectedTemplateId(selectedTemplateId);
            setSelectedTemplateId(id);
            setOpenTemplatePreview(true);
          }}
        />
      </Modal>
      <Modal
        disableConfirm={true}
        title="Campaign Preview "
        isOpen={openTemplatePreview}
        hiddenCancel
        onCancel={() => {
          setSelectedTemplateId(prevSelectedTemplateId);
          setPrevSelectedTemplateId(null);
          setOpenTemplatePreview(false);
        }}
        widthRequired
      >
        <div className={classes.preview_conatiner}>
          <div className={classes.preview_btns}>
            <div className={classes.image} onClick={() => setPhonePreview(true)}>
              <PhonePreviewVector
                fillBackgroundColor={openPhonePreview ? '#F1F8FF' : ''}
                fillColor={openPhonePreview ? '#215C73' : ''}
              />
            </div>
            <div className={classes.image} onClick={() => setPhonePreview(false)}>
              <PhoneDesktop
                fillBackgroundColor={!openPhonePreview ? '#F1F8FF' : ''}
                fillColor={!openPhonePreview ? '#215C73' : ''}
              />
            </div>
          </div>
          <div className={classes.preview_body}>
            <div
              className={openPhonePreview ? classes.responsive_preview : classes.responsive_desktop_preview}
              dangerouslySetInnerHTML={{
                __html: selectedTemplateId ? (
                  userEmailtemplates?.find(t => t.id === selectedTemplateId)?.emailContent
                ) : (
                  <></>
                ),
              }}
            ></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TemplatePage;
