import { useEffect, useCallback, useState } from 'react';

import { MEMBER_PODS_ACTIONS } from 'actions/MemberPods';
import { memberPodsSelector } from 'selectors/MemberPods';
import { memberPodsService } from 'services/memberPods/memberPods.service';
import useShallowEqualSelector from '../../../hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from '../../../constants';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import { getBoughtByType } from 'services/contributions.service';
import * as paidTier from 'selectors/paidTier';
import { ContributionType } from 'helpers/constants';
import isEqual from 'react-fast-compare';
import { cloneDeep } from 'lodash';

export const useLoadMemberPodsByContribution = ({ contributionId, userId }) => {
  const dispatch = useDispatch();

  // useEffect(() => console.info('vvv:', contributionId, userId), []);

  const [isLoader, setIsLoader] = useState({
    paidTierPlans: false,
    activePaidTierPlan: false,
    academyMemberships: false,
    activeAcademyMemberships: false,
  });

  const activePaidTierCurrentPlan = useSelector(paidTier.activePaidTierCurrentPlanSelector);
  const academyContributions = useSelector(paidTier.cohereAcademyContributionsSelector);
  const isScalePlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.scale;

  const getAllPaidTiers = useCallback(() => paidTierService.getPaidTierOptions(), []);
  const getActivePaidTier = useCallback(() => paidTierService.getCurrentPlan(), []);

  // Correct behavior - 100%
  const filterMembership = useCallback(
    res => {
      const filteredMemberships = [];

      academyContributions.forEach(item => {
        const matchedMembership = res.find(el => el.id === item.id);

        if (matchedMembership && matchedMembership.isPurchased) {
          filteredMemberships.push(item);
        }
        return true;
      });

      return filteredMemberships;
    },
    [academyContributions],
  );

  const updateActiveContributionsState = useCallback(() => {
    return getBoughtByType(ContributionType.contributionMembership)
      .then(filterMembership)
      .then(matchedMemberships => {
        dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
      });
  }, [filterMembership]);

  useEffect(() => {
    // this is a temp solution, we need to find a way to not repopulate it again if not needed
    // TODO: don't get paid tiers plans if the right version for existing user is already loaded
    // if (paidTierPlans.length) {
    //   return;
    // }
    setIsLoader(s => ({ ...s, paidTierPlans: true }));

    getAllPaidTiers().then(data => {
      dispatch(paidTierActions.setPaidTierPlans(data));
      setIsLoader(s => ({ ...s, paidTierPlans: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activePaidTierPlan: true }));

    getActivePaidTier().then(data => {
      dispatch(paidTierActions.setActivePaidTierPlan(data));
      setIsLoader(s => ({ ...s, activePaidTierPlan: false }));
    });
  }, []);
  // useEffect(() => {
  //   console.info('plan1:', cloneDeep(activePaidTierCurrentPlan));
  // }, [activePaidTierCurrentPlan, dispatch]);
  // console.info('plan:', cloneDeep(activePaidTierCurrentPlan));

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  useEffect(() => {
    setIsLoader(s => ({ ...s, academyMemberships: true }));

    paidTierService.getAcademy().then(data => {
      dispatch(paidTierActions.setPaidTierContribution(data));
      setIsLoader(s => ({ ...s, academyMemberships: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activeAcademyMemberships: true }));

    updateActiveContributionsState().then(() => {
      setIsLoader(s => ({ ...s, activeAcademyMemberships: false }));
    });
  }, []);
  const state = useSelector(memberPodsSelector);

  useEffect(() => {
    if (!contributionId || state.contributionMemberPods || state.error || state.isFetching) {
      return;
    }

    dispatch(MEMBER_PODS_ACTIONS.setIsFetching(true));

    memberPodsService
      .getContributionPods(contributionId)
      .then(data => {
        if (!data?.length && isScalePlan) {
          const defaultMemberPods = ['Group One', 'Group Two', 'Group Three', 'Group Four'];
          const promises = defaultMemberPods.map(name =>
            memberPodsService.addMemberPod({ coachId: userId, name, contributionId }),
          );

          return Promise.all(promises);
        }

        return data;
      })
      .then(data => {
        console.info('pods old:new:', cloneDeep(state.contributionMemberPods), cloneDeep(data));
        if (!isEqual(state.contributionMemberPods, data)) {
          console.info('setting:');
          dispatch(MEMBER_PODS_ACTIONS.setContributionMemberPods({ contributionId, data }));
        }
        console.info('setting-not');
      })
      .catch(error => dispatch(MEMBER_PODS_ACTIONS.setError(error)))
      .finally(() => dispatch(MEMBER_PODS_ACTIONS.setIsFetching(false)));
  }, [contributionId, dispatch, state.contributionMemberPods, state.error, state.isFetching, userId]);
  useEffect(() => {
    return () => {
      dispatch(MEMBER_PODS_ACTIONS.resetState());
    };
  }, [dispatch]);
};
