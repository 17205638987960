import React, { useState, useCallback, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import useNotifications from 'hooks/useNotifications';
import styled from 'styled-components';
import { useTheme, useMediaQuery, Grid } from '@material-ui/core';
import { Formik, Field, Form, yupToFormErrors } from 'formik';
import * as R from 'ramda';
import { EN_MESSAGES, VALIDATION_REGEX } from 'constants.js';
import * as Yup from 'yup';
import Checkbox from '@material-ui/core/Checkbox';
import * as bankAccountsService from 'services/bankAccounts.service';
import { redirectTo } from 'services/links';
import { addPublicFile } from 'services/content.service';
import * as contributionActions from 'actions/contributions';
import { IconAttach } from 'components/Icons/IconAttach';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import MainContainer from 'components/Containers/MainContainer';
import Button from 'components/FormUI/Button';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { PageTitleSecond, BodyOrLeftText } from 'components/UI/Text/TextStyles';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import Avatar from '@material-ui/core/Avatar';
import { useRouter, useHttp, useAccount } from 'hooks';
import { DialogActions, Box, TextField } from '@material-ui/core';
import * as userActions from 'actions/user';
import { useSaveContribution } from 'hooks/useSaveContribution';
import { useHeader } from 'hooks';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { display } from '@mui/system';
import Video from '../../pages/ViewAsCoach/components/sections/Video';
import {
  determineColorToUse,
  getThankYouPage,
  markThankYouPageViewed,
  getThemedColors,
  determineDarkThemedColorToUse,
} from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import Modal from 'components/UI/Modal';
import ThankYouPageMetaPixelContrib from 'components/AdsComponents/ThankYouMetaPixelContrin';
import GoogleAnalytics from 'components/AdsComponents/GoogleTag';
const AvatarComponent = styled(Avatar)`
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 24px;
        height: 24px;
        font-size: 0.7rem;
      `
    );
  }}
`;
const ACCEPTABLE_FILE_TYPES =
  'application/pdf,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation';

function ThankyouPreviewPage({ thankYouPage, preview = true, handleCloseThankYouModal }) {
  const { user } = useSelector(state => state.account);
  const [previewState, setPreviewState] = useState(true);
  const [previewData, setPreviewData] = useState(null);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const { history, query } = useRouter();
  const { pathname } = useRouter();
  const { themedBackgroundColor, newThemedTextColor } = getThemedColors(activeContribution);
  const [isOpenPasswordPopUp, setIsOpenPasswordPopUp] = useState(user?.isPasswordNotCreated);
  const [noPasswordModalVisible, setNoPasswordModalVisible] = useState(false);
  let colorToUse = determineColorToUse(activeContribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(activeContribution);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { request } = useHttp();
  const dispatch = useDispatch();
  const initialValues = {
    Password: '',
  };
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  useEffect(() => {
    if (query?.id) {
      getThankYouPage(query?.id).then(res => {
        setPreviewData(res);
      });
    }
    if (activeContribution?.isThankyouPageAdded) {
      setPreviewState(false);
    } else if (pathname.includes('edit-contribution') && pathname.includes('thankyoupreviewpage')) {
      setPreviewState(false);
    } else {
      setPreviewState(true);
    }
  }, []);
  const editHandler = useCallback(
    values => {
      const { Password } = values;
      request(`/Account/UpdatePasswordandTimezone`, 'POST', {
        password: Password,
        // id: user.accountId,
        email: user.email,
      })
        .then(() => {
          request(`/Account/${user.accountId}`).then(R.compose(dispatch, userActions.getAccount));
          setIsOpenPasswordPopUp(false);
        })
        .catch(() => {});
    },
    [user],
  );
  const thankYouPageViewed = () => {
    markThankYouPageViewed(query?.id).then(res => {
      if (activeContribution?.thankyouPage?.isSessionTab === true) {
        redirectTo(`/contribution-view/${activeContribution?.id}/community/social`);
      } else {
        if (activeContribution?.thankyouPage?.thankyouPageRedirectTo === 'About') {
          redirectTo(`/contribution-view/${activeContribution?.id}/about`);
        } else if (activeContribution?.thankyouPage?.thankyouPageRedirectTo === 'Sessions') {
          redirectTo(`/contribution-view/${activeContribution?.id}/sessions`);
        } else if (activeContribution?.thankyouPage?.thankyouPageRedirectTo === 'Community') {
          redirectTo(`/contribution-view/${activeContribution?.id}/community/social`);
        }
      }
    });
  };

  const thankyouDescrition = previewData ? previewData?.description : activeContribution?.thankyouPage?.description;

  return (
    <MainContainer previewPage PrimaryColorCode={colorToUse?.PrimaryColorCode}>
      {activeContribution?.id === '66466c79ee9e6c514da9d7f0' && <GoogleAnalytics />}
      {activeContribution?.id === '66466c79ee9e6c514da9d7f0' && <ThankYouPageMetaPixelContrib />}
      <Grid
        style={{
          width: '80%',
          background: themedBackgroundColor,
          boxShadow: '0px 2px 32px rgba(0, 0, 0, 0.1)',
          borderRadius: '20px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          alignSelf: 'center',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
            textAlign: 'center',
          }}
        >
          {/* {activeContribution?.thankyouPage?.profileImage ? (
            <AvatarComponent className="mx-2" src={activeContribution?.thankyouPage?.profileImage} />
          ) : (
            <AvatarComponent className="mx-2" src={previewData?.avatarUrl} />
          )} */}

          <div
            style={{
              // height: ' 80px',
              // lineHeight: '80px',
              color: colorToUse?.AccentColorCode || '#215C73',
              textAlign: 'center',
            }}
            className={'thankYouPageHeadingStyling'}
          >
            {previewData ? previewData?.tagline : activeContribution?.thankyouPage?.tagline}
          </div>
        </div>
        <div
          style={{
            width: '60%',
            fontFamily: 'Avenir',
            fontSize: '16px',
            lineHeight: '30px',
            textAlign: 'center',
            color: newThemedTextColor,
            alignSelf: 'center',
          }}
        >
          {/* {previewData ? previewData?.description : activeContribution?.thankyouPage?.description} */}
          {!isEmpty(thankyouDescrition) && thankyouDescrition.split('\n').map(str => <div>{str}</div>)}
        </div>
        {mobileView ? (
          <Button
            mobileView
            backgroundColor={colorToUse?.PrimaryColorCode}
            textColor={textColor}
            onClick={
              previewState
                ? handleCloseThankYouModal
                : () => {
                    thankYouPageViewed();
                  }
            }
            style={{ margin: '30px 5px 5px', width: mobileView ? '90%' : '' }}
            variant="secondary"
            className="banner-container__button"
            autoWidth
          >
            {previewData
              ? previewData?.contributionRedirectText
              : activeContribution?.thankyouPage?.contributionRedirectText}
          </Button>
        ) : (
          <Button
            mobileView
            backgroundColor={colorToUse?.PrimaryColorCode}
            textColor={textColor}
            onClick={
              previewState
                ? handleCloseThankYouModal
                : () => {
                    thankYouPageViewed();
                  }
            }
            style={{ margin: '30px 5px 5px' }}
            variant="secondary"
            className="banner-container__button"
            autoWidth
          >
            {previewData
              ? previewData?.contributionRedirectText
              : activeContribution?.thankyouPage?.contributionRedirectText}
          </Button>
        )}

        <Video
          data={
            previewData
              ? { primaryBannerUrl: previewData?.bannerImage, isPrimaryBannerVideo: previewData?.isVideoBanner }
              : {
                  primaryBannerUrl: activeContribution?.thankyouPage?.bannerImage,
                  isPrimaryBannerVideo: activeContribution?.thankyouPage?.isVideoBanner,
                }
          }
          colorToUse={colorToUse}
          videoComponent={true}
        />
      </Grid>
      {user?.isPasswordNotCreated === true && (
        <Modal
          title="Create a Cohere Login Password"
          isOpen={isOpenPasswordPopUp}
          submitTitle="Close"
          hiddenCancel
          disableFooter
          onCancel={() => {
            setIsOpenPasswordPopUp(false);
            setNoPasswordModalVisible(true);
          }}
          dontCancelOnSideClick
          onSubmit={() => {
            setIsOpenPasswordPopUp(false);
          }}
          style={{ zIndex: '2000' }}
        >
          <p style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>
            Finish creating your login account to get access to all the content shared by{' '}
            {activeContribution?.serviceProviderName} inside {activeContribution?.title}!
          </p>
          <p style={{ color: isDarkThemeEnabled ? 'white' : 'black' }}>
            If you don’t create a password now, we’ll automatically send you a temporary password via email in 10
            minutes!
          </p>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              Password: Yup.string()
                .strict(true)
                .trim('Please remove spaces after your password')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g, EN_MESSAGES.validation.password)
                // .required(<Translate id="validation.required" />),
                .required(EN_MESSAGES.validation.required),
            })}
            onSubmit={editHandler}
          >
            {({ values, errors, handleBlur, touched, handleChange }) => (
              <Form className="passowrd-popup-form">
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ width: '50%' }}>
                    <TextField
                      className="input-field"
                      id="outlined-basic"
                      margin="normal"
                      variant="outlined"
                      name="Password"
                      type="password"
                      fullWidth
                      placeholder="Create a password"
                      PostalCode={values.Password}
                      onChange={e => {
                        handleChange(e);
                      }}
                      onBlur={e => {
                        handleBlur(e);
                      }}
                      error={touched.Password && Boolean(errors.Password)}
                      helperText={errors.Password}
                    />
                  </span>
                  {/* {errors?.message && <CommonErrorMessage message={error.message} />} */}
                </Box>
                <Grid container item xs justifyContent="center" direction="column" alignItems="center">
                  <DialogActions>
                    <Button
                      style={{ height: '50px', width: '130px' }}
                      type="submit"
                      //   disabled={!isStripeSupportedCuntrySelected(values.CountryId) || loading}
                    >
                      Save
                      {/* {loading ? <Icon path={mdiLoading} size={1} spin={1} /> : 'Save'} */}
                    </Button>
                  </DialogActions>
                </Grid>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </MainContainer>
  );
}

ThankyouPreviewPage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  saveContributionToLS: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

ThankyouPreviewPage.defaultProps = {
  error: null,
};

const mapStateToProps = ({ account, contributions }) => ({
  user: account?.user,
  error: contributions?.error,
  loading: contributions?.loading,
  contribution: contributions.activeContribution,
});

const actions = {
  saveContributionToLS: contributionActions.saveDraftContribution,
};

export default connect(mapStateToProps, actions)(ThankyouPreviewPage);
