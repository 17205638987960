import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import useScreenView from 'hooks/useScreenView';

import { getContributionConversionMetrics } from 'services/contributions.service';
import { getLeadMagnetConversionMetrics } from 'services/leadMagnets.service';
import Loader from 'components/UI/Loader';

const AnalyticsTile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  gap: 10px;
  padding: ${({ mobileView }) => (mobileView ? '8px' : '1px 8px')};
  background: ${({ isDarkModeEnabled }) =>
    isDarkModeEnabled ? '#252728' : 'var(--Cohere-Greys-Background-Grey, #F5F5F5)'};
`;

const AnalyticsTileContent = styled.p`
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? 'white' : '#000')};
  text-align: center;
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: fit-content;
`;

const AnalyticsTileMetrics = styled.div`
  color: var(--Cohere-Primary-Blue, #215c73);
  text-align: center;
  font-family: Avenir;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
`;

const AnalyticsTileContainer = styled.div`
  display: flex;
  padding: 1px 8px;
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  height: ${({ mobileView }) => (mobileView ? 'fit-content' : '143px')};
  margin-bottom: 20px;
`;

export const ConversionMetricsModal = ({
  isOpen,
  handleClose,
  contributionId = null,
  leadMagnetId = null,
  isDarkModeEnabled = false,
  colorToUse,
}) => {
  const { mobileView } = useScreenView();
  const [conversionMetrics, setConversionMetrics] = useState({
    clientsCount: 0,
    visitsCount: 0,
    conversionPercentage: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (contributionId) {
      getContributionConversionMetrics(contributionId)
        .then(res => {
          setConversionMetrics(res);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    } else if (leadMagnetId) {
      getLeadMagnetConversionMetrics(leadMagnetId)
        .then(res => {
          setConversionMetrics(res);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <Modal
      title={
        <div style={{ ...(isDarkModeEnabled ? { color: 'white' } : {}) }}>
          <div>Analytics</div>
        </div>
      }
      isOpen={isOpen}
      onCancel={handleClose}
      onSubmit={handleClose}
      disableFooter
      className={`lead-magnet-form-modal ${isDarkModeEnabled ? 'dark-mode' : ''}`}
      style={isDarkModeEnabled ? { backgroundColor: 'rgb(45, 47, 49)' } : {}}
    >
      {loading && <Loader />}
      <div style={{ width: '100%' }}>
        <AnalyticsTileContent
          mobileView={mobileView}
          isDarkModeEnabled={isDarkModeEnabled}
          style={{ marginBottom: '20px', textAlign: 'start' }}
        >
          Track these key metrics to measure the conversion effectiveness of your landing pages.
        </AnalyticsTileContent>
        <AnalyticsTileContainer mobileView={mobileView}>
          <AnalyticsTile mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
            <AnalyticsTileContent isDarkModeEnabled={isDarkModeEnabled}>Number of Visitors</AnalyticsTileContent>
            <AnalyticsTileMetrics
              isDarkModeEnabled={isDarkModeEnabled}
              style={{ color: isDarkModeEnabled && colorToUse?.PrimaryColorCode }}
            >
              {conversionMetrics?.visitsCount || 0}
            </AnalyticsTileMetrics>
          </AnalyticsTile>
          <AnalyticsTile mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
            <AnalyticsTileContent isDarkModeEnabled={isDarkModeEnabled}>Conversion Rate</AnalyticsTileContent>
            <AnalyticsTileMetrics
              isDarkModeEnabled={isDarkModeEnabled}
              style={{ color: isDarkModeEnabled && colorToUse?.PrimaryColorCode }}
            >
              {conversionMetrics?.conversionPercentage || 0}%
            </AnalyticsTileMetrics>
          </AnalyticsTile>
          <AnalyticsTile mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
            <AnalyticsTileContent isDarkModeEnabled={isDarkModeEnabled}>
              {contributionId ? 'Clients Served' : 'Leads Generated'}
            </AnalyticsTileContent>
            <AnalyticsTileMetrics
              isDarkModeEnabled={isDarkModeEnabled}
              style={{ color: isDarkModeEnabled && colorToUse?.PrimaryColorCode }}
            >
              {contributionId ? conversionMetrics?.clientsCount || 0 : conversionMetrics?.leadsCount || 0}
            </AnalyticsTileMetrics>
          </AnalyticsTile>
        </AnalyticsTileContainer>
      </div>
    </Modal>
  );
};
