import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import noContributionImage from 'assets/no-contribution-image.png';
import { MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Switch, StyledInput, StyledSlider } from 'components/switch/style';
import TextArea from 'components/FormUI/TextArea';
import { openInNewWindow } from 'services/links';
import { DraggableIcon } from '../icons/Draggable';
import { LinkIcon } from '../icons/Link';
import { WorkTogetherIcon } from '../icons/WorkTogetherIcon';
import { DeleteIcon } from '../icons/Delete';
import { AddIcon } from '../icons/Add';
import { createAccessCode } from 'services/contributions.service';
import { cloneDeep } from 'lodash';
import { getLeadMagnetsForProfilePage } from 'services/leadMagnets.service';
import { fetchLeadMagnets, fetchLeadMagnetsList } from 'actions/leadMagnets';
import { formatMoney } from 'utils/datesAndMoney';

const selector = state => ({
  authorAvatarUrl: state?.upcomingCreated?.upcomingCreated?.authorAvatarUrl || '',
  contributionsForTable: state?.upcomingCreated?.upcomingCreated?.contributionsForTable || [],
  loading: state?.upcomingCreated?.upcomingCreated?.loading,
});

const ItemTypes = {
  Contribution: {
    value: 'Contribution',
    title: 'Contribution',
  },
  LeadMagnet: {
    value: 'LeadMagnet',
    title: 'Lead Magnet',
  },
};
const ItemTypesMap = new Map(Object.values(ItemTypes).map(item => [item.value, item.value]));

const Contributions = ({ values, setFieldValues }) => {
  const [contributionsForProfilePage, setContributionForProfilePage] = useState({});
  const { contributionsForTable, loading } = useSelector(selector);
  const { leadMagnetsCollection, loading: lmLoading } = useSelector(s => s.leadMagnets);
  const profilePageViewModel = (values && values.profilePageViewModel) || {};
  const contributions = profilePageViewModel.contributions || [];
  let fileteredContributions = contributionsForTable?.filter(item => item.status === 'Approved');
  let filteredLeadMagnets = useMemo(() => leadMagnetsCollection?.filter(item => item.status === 'Approved') || [], [
    leadMagnetsCollection,
  ]);
  const [leadMagnetsList, setLeadMagnetsList] = useState([]);
  const dispatch = useDispatch();

  const isLeadMagnetList = useRef([]);
  let fileteredContributionsForTable = contributionsForTable?.filter(item => item.status === 'Approved');

  useEffect(() => {
    mapContibutionsWithPricesForTable();
  }, [contributionsForTable, leadMagnetsCollection]);

  useEffect(() => {
    // getLeadMagnetsForProfilePage();
    // dispatch(fetchLeadMagnetsList());
    dispatch(fetchLeadMagnets());
  }, []);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const newList = Array.from(contributions);
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    setFieldValues('profilePageViewModel', { ...profilePageViewModel, contributions: newList });
  };

  const onAddPress = async () => {
    if ((fileteredContributionsForTable?.length < 1 && filteredLeadMagnets?.length <= 0) || contributions?.length > 9) {
      return;
    }
    let indexofArray = contributions.findIndex(obj => obj?.id === fileteredContributionsForTable[0]?.id);
    if (indexofArray !== -1) {
      // The ID is present in the array.
      if (indexofArray < contributions.length - 1) {
        indexofArray = indexofArray + 1;
      } else {
        indexofArray = indexofArray + 1;
      }
    } else {
      indexofArray = 0;
    }
    if (fileteredContributionsForTable?.length > 0) {
      const newContribution = fileteredContributionsForTable[indexofArray];
      let accessCode = null;
      let paidOrFree = fileteredContributionsForTable[indexofArray].paymentInfo.paymentOptions.find(
        value => !value.includes('Free'),
      );
      if (paidOrFree) {
        paidOrFree = 'Paid';
      } else {
        paidOrFree = 'Free';
        await createAccessCode(fileteredContributionsForTable[indexofArray].id).then(data => {
          accessCode = data.code;
        });
      }
      let priceId = null;
      if (fileteredContributionsForTable[indexofArray].paymentInfo.multiplePriceList.length > 1) {
        const objectWithIsDefaultTrue = fileteredContributionsForTable[
          indexofArray
        ].paymentInfo.multiplePriceList?.find(obj => obj.isDefault === true);
        priceId = objectWithIsDefaultTrue.id;
      } else {
        priceId = null;
      }
      setFieldValues('profilePageViewModel', {
        ...profilePageViewModel,
        contributions: [
          {
            id: newContribution.id,
            title: newContribution.title,
            isEnabled: true,
            imageUrl: newContribution.contributionImage,
            description: newContribution.purpose,
            type: newContribution.isWorkshop ? 'Workshops' : newContribution.type,
            contributionPaymentType: paidOrFree,
            multiplePriceList: newContribution?.paymentInfo?.multiplePriceList,
            paymentOption: newContribution?.paymentInfo?.paymentOptions,
            accessCode,
            symbol: newContribution?.symbol,
            priceId: priceId,
            isLeadMagnet: false,
          },
          ...(contributions || []),
        ],
      });
    } else if (filteredLeadMagnets?.length > 0) {
      const listOfAll = filteredLeadMagnets;
      const filteredListOfAll = (listOfAll || []).filter(
        k => k.status === 'Approved' && contributions.every(obj => obj.id !== k.id),
      );
      const firstSelectable = filteredListOfAll?.[0];
      const firstOne = {
        id: firstSelectable?.id,
        title: firstSelectable?.title,
        isEnabled: true,
        imageUrl:
          (firstSelectable.previewContentUrls?.length && firstSelectable?.previewContentUrls?.[0]) ||
          firstSelectable?.contributionImage ||
          firstSelectable?.file,
        description: firstSelectable?.purpose,
        type: firstSelectable?.type,
        contributionPaymentType: 'Free',
      };
      const newContribution = {
        // ...toBeModified,
        ...(firstOne && firstOne),
        paymentInfo: { paymentOptions: ['Free'] },
        applicationFormViewModel: null,
        isLeadMagnet: true,
        isEnabled: true,
      };
      setFieldValues('profilePageViewModel', {
        ...profilePageViewModel,
        contributions: [newContribution, ...(contributions || [])],
      });
    }
  };

  const mapContibutionsWithPricesForTable = () => {
    let tempContributionWithPrices = {},
      profileView = values.profilePageViewModel;
    fileteredContributions.map(contribution => {
      if (contribution.status === 'Approved') {
        const priceCalculated = calculatePricings(
          contribution?.paymentInfo,
          contribution?.id,
          contribution?.applicationFormViewModel,
        );
        let type = 'Free';
        for (let price of priceCalculated) {
          if (price.value === 'Paid') {
            type = 'Paid';
          }
        }
        let multiplePriceList = [];
        let priceId = null;
        if (contribution.paymentInfo.multiplePriceList.length > 1) {
          const objectWithIsDefaultTrue = contribution.paymentInfo.multiplePriceList?.find(
            obj => obj.isDefault === true,
          );
          priceId = objectWithIsDefaultTrue.id;
        } else {
          priceId = null;
        }
        if (contribution.paymentInfo.multiplePriceList.length > 1) {
          multiplePriceList = contribution.paymentInfo.multiplePriceList;
        }
        tempContributionWithPrices = {
          ...tempContributionWithPrices,
          [contribution.id]: {
            priceOptions: priceCalculated,
            multiplePriceList: multiplePriceList,
            paymentOptions: contribution.paymentInfo.paymentOptions,
            symbol: contribution.symbol,
            priceId: priceId,
            type: contribution?.type,
            isLeadMagnet: false,
          },
        };
      }
    });
    filteredLeadMagnets.map(magnet => {
      if (magnet.status === 'Approved') {
        let type = 'Free';
        tempContributionWithPrices = {
          ...tempContributionWithPrices,
          [magnet.id]: {
            isLeadMagnet: true,
            priceOptions: [{ value: type }],
            // paymentOptions: magnet.paymentInfo.paymentOptions,
            paymentOptions: magnet?.paymentInfo?.paymentOptions || [type],
            type: magnet?.type,
          },
        };
      }
    });
    setContributionForProfilePage(tempContributionWithPrices);
    setFieldValues('profilePageViewModel', { ...profilePageViewModel, contributions: profileView?.contributions });
  };

  const getItemType = item => {
    return item.isLeadMagnet ? ItemTypes.LeadMagnet.value : ItemTypes.Contribution.value;
  };
  const getSelectedOption = item => {
    return item.contributionPaymentType || 'Paid' || 'Free';
  };
  const getSelectedPricingOption = (item, price) => {
    const objectWithIsDefaultTrue = price?.find(obj => obj.isDefault === true);
    return item.priceId || objectWithIsDefaultTrue?.id;
  };

  const calculatePricings = (paymentInfo, contributionId, applicationForm) => {
    const { paymentOptions } = paymentInfo;
    let priceSelectOptions = [];
    const paymentTitle = {
      Free: 'Free',
      Paid: 'Paid',
      FreeApplication: 'Free Application',
      PaidApplication: 'Paid Application',
    };
    paymentOptions.map(paymentType => {
      if (paymentType.includes('Free') && applicationForm != null) {
        if (paymentType != 'FreeSessionsPackage') {
          priceSelectOptions.push({
            value: 'Free Application',
            isSelected: false,
            title: 'Free Application',
          });
        }
      }
      if (paymentType.includes('Free')) {
        if (paymentType != 'FreeSessionsPackage') {
          priceSelectOptions.push({
            value: paymentType,
            isSelected: false,
            title: `${paymentTitle[paymentType]}`,
          });
        }
      }
      const isInList = priceSelectOptions?.find(option => option?.value === paymentTitle?.Paid);
      if (!isInList) {
        if (!paymentType.includes('Free')) {
          if (paymentType != 'FreeSessionsPackage') {
            priceSelectOptions.push({
              value: 'Paid',
              isSelected: false,
              title: 'Paid',
            });
          }
        }
        if (!paymentType.includes('Free') && applicationForm != null) {
          if (paymentType != 'FreeSessionsPackage') {
            priceSelectOptions.push({
              value: 'Paid Application',
              isSelected: false,
              title: 'Paid Application',
            });
          }
        }
      }
    });
    const pricingSorted = priceSelectOptions.sort(
      (a, b) => a.title.length - b.title.length || a.title.localeCompare(b.title),
    );
    return pricingSorted;
  };

  const onDelete = index => {
    setFieldValues('profilePageViewModel', {
      ...profilePageViewModel,
      contributions: [
        ...contributions.filter((contribution, index2) => {
          return index !== index2;
        }),
      ],
    });
  };

  const onChange = async (index, newValue) => {
    if (!newValue.isLeadMagnet && newValue.contributionPaymentType === 'Free') {
      newValue = {
        ...newValue,
        accessCode: await createAccessCode(newValue?.id).then(data => {
          return data.code;
        }),
      };
    } else {
      newValue = {
        ...newValue,
        accessCode: null,
      };
    }
    setFieldValues('profilePageViewModel', {
      ...profilePageViewModel,
      contributions: [
        ...contributions.map((contribution, index2) => {
          if (index === index2) {
            return newValue;
          } else {
            return contribution;
          }
        }),
      ],
    });
  };

  const onChangePricingOption = async (paymentValue, contributionId) => {
    if (!paymentValue || !contributionId) {
      return;
    }
    const isHaveAccessCode = contributions.find(contribution => contribution.id === contributionId);
    const newValue = {
      accessCode:
        (paymentValue?.includes('Free Application') || paymentValue?.includes('Free')) && isHaveAccessCode?.accessCode
          ? isHaveAccessCode.accessCode
          : null,
      contributionPaymentType: paymentValue,
    };
    if (paymentValue.includes('Free') && isHaveAccessCode && isHaveAccessCode.accessCode === null) {
      await createAccessCode(contributionId).then(data => {
        newValue.accessCode = data.code;
        setAccessCode(newValue, contributionId);
      });
    } else {
      setAccessCode(newValue, contributionId);
    }
  };

  const onChangeMultiplePricingOption = async (paymentValue, contributionId) => {
    if (!paymentValue || !contributionId) {
      return;
    }
    setFieldValues('profilePageViewModel', {
      ...profilePageViewModel,
      contributions: [
        ...contributions.map(contribution => {
          if (contributionId === contribution.id) {
            const newContribution = { ...contribution, priceId: paymentValue };
            return newContribution;
          } else {
            return contribution;
          }
        }),
      ],
    });
  };

  const setAccessCode = (newValue, contributionId) => {
    setFieldValues('profilePageViewModel', {
      ...profilePageViewModel,
      contributions: [
        ...contributions.map(contribution => {
          if (contributionId === contribution.id) {
            const newContribution = { ...contribution, ...newValue };
            return newContribution;
          } else {
            return contribution;
          }
        }),
      ],
    });
  };
  return (
    <>
      {!loading && !lmLoading && (
        <Grid container className="contribution-section profile-section">
          <Grid container justifyContent="space-between" alignItems="center" className="section-title">
            <Grid item alignItems="center" style={{ display: 'flex' }}>
              <WorkTogetherIcon />
              <span className="heading">
                <TextArea
                  fprops={values}
                  image={values?.contributionLabel}
                  label={values?.contributionLabel}
                  isLabelEditable
                  isTextAreaModeEditable
                  initialMode={''}
                  changeMode={e => console.log(e)}
                  labelName="contributionLabel"
                  placeholder=""
                  name="contributionLabel"
                  rows="1"
                  fullWidth
                  counter={1000}
                  helperTextPosition="right"
                  isTextAreaVisible={false}
                  onClickResize={() => {}}
                  handleIconClick={() => {}}
                  isCollapsed={'purposeCollapsed'}
                  id="contribution-details-purpose-textarea"
                />{' '}
                {/* <p style={{ marginTop: '0px' }}>Contributions Order on Profile Page</p> */}
              </span>
            </Grid>
            <Grid item>
              <span className="add-action" role="button" tabIndex="0" onClick={onAddPress} onKeyUp={onAddPress}>
                <span className="add-icon">
                  <AddIcon />
                </span>
                <p>Add New Contributions or Lead Magnets</p>
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {droppableProvided => (
                  <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                    {contributions.map((item, index) => (
                      <Draggable key={item?.id?.concat(index)} draggableId={item?.id + index} index={index}>
                        {provided => (
                          <div className="draggable-item" ref={provided.innerRef} {...provided.draggableProps}>
                            <Grid container className="draggable-item-grid" spacing={12}>
                              <Grid className="d-flex" item sm={12} md={12} xs={12}>
                                <span className="draggable-icon" {...provided.dragHandleProps}>
                                  <DraggableIcon />
                                </span>
                                {fileteredContributionsForTable.length > 0 && filteredLeadMagnets?.length > 0 && (
                                  <Select
                                    style={{ width: '55%', marginRight: 8 }}
                                    className="select-field"
                                    label="itemType"
                                    name="itemType"
                                    variant="outlined"
                                    fullWidth
                                    defaultValue={getItemType(item) || []}
                                    input={<OutlinedInput margin="dense" />}
                                    onChange={async e => {
                                      const itemType = e.target.value;
                                      const isLeadMagnet = itemType === ItemTypes.LeadMagnet.value;
                                      if (!isLeadMagnet) {
                                        let fileteredContributionsForTable = contributionsForTable?.filter(
                                          item => item.status === 'Approved',
                                        );
                                        if (
                                          fileteredContributionsForTable.length < 1 &&
                                          filteredLeadMagnets?.length <= 0
                                        ) {
                                          return;
                                        }
                                        let indexofArray = contributions.findIndex(
                                          obj => obj?.id === fileteredContributionsForTable[0]?.id,
                                        );
                                        if (indexofArray !== -1) {
                                          // The ID is present in the array.
                                          if (indexofArray < contributions.length - 1) {
                                            indexofArray = indexofArray + 1;
                                          } else {
                                            indexofArray = indexofArray + 1;
                                          }
                                        } else {
                                          indexofArray = 0;
                                        }
                                        const newContribution = fileteredContributionsForTable[indexofArray];
                                        let accessCode = null;
                                        let paidOrFree = fileteredContributionsForTable[
                                          indexofArray
                                        ].paymentInfo.paymentOptions.find(value => !value.includes('Free'));
                                        if (paidOrFree) {
                                          paidOrFree = 'Paid';
                                        } else {
                                          paidOrFree = 'Free';
                                          await createAccessCode(fileteredContributionsForTable[indexofArray].id).then(
                                            data => {
                                              accessCode = data.code;
                                            },
                                          );
                                        }
                                        let priceId = null;
                                        if (
                                          fileteredContributionsForTable[indexofArray].paymentInfo.multiplePriceList
                                            .length > 1
                                        ) {
                                          const objectWithIsDefaultTrue = fileteredContributionsForTable[
                                            indexofArray
                                          ].paymentInfo.multiplePriceList?.find(obj => obj.isDefault === true);
                                          priceId = objectWithIsDefaultTrue.id;
                                        } else {
                                          priceId = null;
                                        }
                                        const contr = {
                                          id: newContribution.id,
                                          title: newContribution.title,
                                          isEnabled: true,
                                          imageUrl: newContribution.contributionImage,
                                          description: newContribution.purpose,
                                          type: newContribution.isWorkshop ? 'Workshops' : newContribution.type,
                                          contributionPaymentType: paidOrFree,
                                          multiplePriceList: newContribution?.paymentInfo?.multiplePriceList,
                                          paymentOption: newContribution?.paymentInfo?.paymentOptions,
                                          accessCode,
                                          symbol: newContribution?.symbol,
                                          priceId: priceId,
                                          isLeadMagnet,
                                        };
                                        onChange(index, contr);
                                        return;
                                      }
                                      const toBeModified = contributions[index];
                                      const listOfAll = isLeadMagnet ? filteredLeadMagnets : fileteredContributions;
                                      const filteredListOfAll = (listOfAll || []).filter(
                                        k => k.status === 'Approved' && contributions.every(obj => obj.id !== k.id),
                                      );
                                      const firstSelectable = filteredListOfAll?.[0];
                                      const firstOne = {
                                        id: firstSelectable?.id,
                                        title: firstSelectable?.title,
                                        isEnabled: true,
                                        imageUrl:
                                          (firstSelectable.previewContentUrls?.length &&
                                            firstSelectable?.previewContentUrls?.[0]) ||
                                          firstSelectable?.contributionImage ||
                                          firstSelectable?.file,
                                        description: firstSelectable?.purpose,
                                        type:
                                          !isLeadMagnet && firstSelectable?.isWorkshop
                                            ? 'Workshops'
                                            : firstSelectable?.type,
                                        contributionPaymentType: 'Free',
                                      };
                                      const newContribution = {
                                        // ...toBeModified,
                                        ...(firstOne && firstOne),
                                        paymentInfo: { paymentOptions: ['Free'] },
                                        applicationFormViewModel: null,
                                        isLeadMagnet,
                                        isEnabled: true,
                                      };
                                      // isLeadMagnetList.current[index] = isLeadMagnet;
                                      onChange(index, newContribution);
                                    }}
                                    item
                                    value={
                                      item?.isLeadMagnet ? ItemTypes.LeadMagnet.value : ItemTypes.Contribution.value
                                    }
                                  >
                                    {Object.values(ItemTypes).map(k => (
                                      <MenuItem value={k.value}>{k.title}</MenuItem>
                                    ))}
                                  </Select>
                                )}
                                <img src={item.imageUrl || noContributionImage} width={100} />
                                <Select
                                  className="select-field"
                                  label="Timezone"
                                  name="TimeZoneId"
                                  variant="outlined"
                                  fullWidth
                                  style={{
                                    minWidth:
                                      contributionsForProfilePage[item?.id]?.multiplePriceList?.length > 1 ? 135 : 160,
                                    marginRight: 8,
                                  }}
                                  value={item.id}
                                  input={<OutlinedInput margin="dense" />}
                                  onChange={e => {
                                    const toBeModified = contributions[index];
                                    let newContribution = (item?.isLeadMagnet
                                      ? filteredLeadMagnets
                                      : fileteredContributions
                                    )?.find(contribution => {
                                      return contribution.id === e.target.value;
                                    });
                                    if (item?.isLeadMagnet) {
                                      newContribution = {
                                        ...newContribution,
                                        isLeadMagnet: item?.isLeadMagnet,
                                        paymentInfo: { paymentOptions: ['Free'] },
                                        applicationFormViewModel: null,
                                      };
                                    }

                                    let check = newContribution?.paymentInfo?.paymentOptions.find(
                                      option =>
                                        !option.includes('Free') && newContribution.applicationFormViewModel === null,
                                    );

                                    let check2 = newContribution?.paymentInfo?.paymentOptions.find(
                                      option =>
                                        option.includes('Free') && newContribution.applicationFormViewModel === null,
                                    );
                                    let check3 = newContribution?.paymentInfo?.paymentOptions.find(
                                      option =>
                                        !option.includes('Free') && newContribution.applicationFormViewModel != null,
                                    );
                                    if (check) {
                                      check = 'Paid';
                                    } else if (check2) {
                                      check = 'Free';
                                    } else if (check3) {
                                      check = 'Paid Application';
                                    } else {
                                      check = 'Free Application';
                                    }
                                    newContribution = {
                                      id: newContribution.id,
                                      title: newContribution.title,
                                      imageUrl: item?.isLeadMagnet
                                        ? (newContribution.previewContentUrls?.length &&
                                            newContribution?.previewContentUrls?.[0]) ||
                                          newContribution?.contributionImage ||
                                          newContribution?.file
                                        : newContribution.contributionImage,
                                      description: newContribution.purpose,
                                      type: newContribution.isWorkshop ? 'Workshops' : newContribution.type,
                                      isEnabled: toBeModified.isEnabled || true,
                                      contributionPaymentType: check,
                                      isLeadMagnet: item?.isLeadMagnet,
                                    };
                                    onChange(index, newContribution);
                                  }}
                                >
                                  {item?.isLeadMagnet
                                    ? filteredLeadMagnets?.length > 0 &&
                                      filteredLeadMagnets.map(magnet => {
                                        if (magnet.status === 'Approved') {
                                          return (
                                            <MenuItem
                                              disabled={contributions.find(obj => obj.id === magnet.id)}
                                              value={magnet.id}
                                            >
                                              {magnet.title}
                                            </MenuItem>
                                          );
                                        }
                                        return null;
                                      })
                                    : fileteredContributions?.length > 0 &&
                                      fileteredContributions.map(contribution => {
                                        if (contribution.status === 'Approved') {
                                          return (
                                            <MenuItem
                                              disabled={contributions.find(obj => obj.id === contribution.id)}
                                              value={contribution.id}
                                            >
                                              {contribution.title}
                                            </MenuItem>
                                          );
                                        }
                                        return null;
                                      })}
                                </Select>
                                {contributionsForProfilePage[item?.id] ? (
                                  <Select
                                    style={{ width: '55%', marginRight: 8 }}
                                    className="select-field"
                                    label="contributionPricing"
                                    name="contributionPricing"
                                    variant="outlined"
                                    fullWidth
                                    defaultValue={getSelectedOption(item) || []}
                                    input={<OutlinedInput margin="dense" />}
                                    onChange={
                                      // item?.isLeadMagnet ? null : e => onChangePricingOption(e.target.value, item?.id)
                                      item?.isLeadMagnet
                                        ? e => {
                                            const toBeModified = contributions[index];
                                            const paymentType = e.target.value;
                                            const newContribution = {
                                              ...toBeModified,
                                              contributionPaymentType: paymentType,
                                            };
                                            onChange(index, newContribution);
                                          }
                                        : e => onChangePricingOption(e.target.value, item?.id)
                                    }
                                    // disabled={item?.isLeadMagnet || false}
                                    // value={item?.isLeadMagnet ? 'Free' : item?.contributionPricing}
                                  >
                                    {false && item?.isLeadMagnet ? (
                                      <MenuItem value="Free">Free</MenuItem>
                                    ) : (
                                      contributionsForProfilePage[item?.id] &&
                                      contributionsForProfilePage[item?.id]?.priceOptions?.map(value => {
                                        return <MenuItem value={value.value}>{value.value}</MenuItem>;
                                      })
                                    )}
                                  </Select>
                                ) : null}
                                {contributionsForProfilePage[item?.id] &&
                                contributionsForProfilePage[item?.id]?.multiplePriceList?.length > 0 &&
                                item.contributionPaymentType != 'Free' ? (
                                  <Select
                                    style={{ minWidth: 120, marginRight: 8 }}
                                    className="select-field"
                                    label="contributionPricing"
                                    name="contributionPricing"
                                    variant="outlined"
                                    fullWidth
                                    defaultValue={
                                      getSelectedPricingOption(
                                        item,
                                        contributionsForProfilePage[item?.id]?.multiplePriceList,
                                      ) || []
                                    }
                                    input={<OutlinedInput margin="dense" />}
                                    onChange={e => onChangeMultiplePricingOption(e.target.value, item?.id)}
                                  >
                                    {contributionsForProfilePage[item?.id]?.multiplePriceList?.map(data => {
                                      const contribution = contributionsForProfilePage[item?.id];
                                      const { symbol, paymentOptions, type } = contribution;
                                      const costText = `${symbol}${formatMoney(data?.cost)}`;
                                      const packageCostText = `${symbol}${formatMoney(data?.packageCost)}`;
                                      const splittedCostText = `${symbol}${formatMoney(data?.splittedCost)}`;

                                      let priceText = '';

                                      if (type === 'ContributionOneToOne') {
                                        if (
                                          paymentOptions.includes('PerSession') &&
                                          paymentOptions.includes('SessionsPackage')
                                        ) {
                                          priceText = `${costText} session price, ${packageCostText} package price`;
                                        } else if (!paymentOptions.includes('PerSession')) {
                                          priceText = `${packageCostText} package price`;
                                        } else {
                                          priceText = `${costText} session price`;
                                        }
                                      } else if (
                                        type === 'ContributionMembership' ||
                                        type === 'ContributionCommunity'
                                      ) {
                                        priceText = `${costText} ${
                                          data.paymentOption === 'MonthlyMembership'
                                            ? 'monthly membership price'
                                            : 'yearly membership price'
                                        }`;
                                      } else {
                                        if (
                                          paymentOptions.includes('EntireCourse') &&
                                          paymentOptions.includes('SplitPayments')
                                        ) {
                                          priceText = `${costText} full price, ${splittedCostText} split price`;
                                        } else if (!paymentOptions.includes('EntireCourse')) {
                                          priceText = `${splittedCostText} split price`;
                                        } else {
                                          priceText = `${costText} full price`;
                                        }
                                      }

                                      return <MenuItem value={data?.id}>{priceText}</MenuItem>;
                                    })}
                                  </Select>
                                ) : null}
                                <span
                                  style={{ display: 'flex' }}
                                  role="button"
                                  tabIndex="0"
                                  onClick={() => {
                                    if (contributions[index].contributionPaymentType === 'Free') {
                                      item?.isLeadMagnet
                                        ? openInNewWindow(`/lead-magnet-view/${contributions[index]?.id}/landingpage`)
                                        : openInNewWindow(
                                            `/contribution-view/${contributions[index]?.id}/${contributions[index]?.accessCode}/about`,
                                          );
                                    } else if (contributions[index].contributionPaymentType === 'Paid') {
                                      if (contributions[index].priceId) {
                                        openInNewWindow(
                                          `/contribution-view/${contributions[index].id}/${contributions[index].priceId}/about`,
                                        );
                                      } else {
                                        openInNewWindow(`/contribution-view/${contributions[index].id}/about`);
                                      }
                                    } else if (contributions[index].contributionPaymentType === 'Free Application') {
                                      item?.isLeadMagnet
                                        ? openInNewWindow(`/lead-magnet-view/${contributions[index]?.id}/landingpage`)
                                        : openInNewWindow(
                                            `/apply-contribution-view/${contributions[index]?.id}/${contributions[index]?.accessCode}/about`,
                                          );
                                    } else if (contributions[index].contributionPaymentType === 'Paid Application') {
                                      if (contributions[index].priceId) {
                                        openInNewWindow(
                                          `/apply-contribution-view/${contributions[index].id}/${contributions[index].priceId}/about`,
                                        );
                                      } else {
                                        openInNewWindow(`/apply-contribution-view/${contributions[index].id}/about`);
                                      }
                                    }
                                  }}
                                  onKeyUp={() => {
                                    if (contributions[index].contributionPaymentType === 'Free') {
                                      item?.isLeadMagnet
                                        ? openInNewWindow(`/lead-magnet-view/${contributions[index]?.id}/landingpage`)
                                        : openInNewWindow(
                                            `/contribution-view/${contributions[index]?.id}/${contributions[index]?.accessCode}/about`,
                                          );
                                    } else if (contributions[index].contributionPaymentType === 'Paid') {
                                      if (contributions[index].priceId) {
                                        openInNewWindow(
                                          `/contribution-view/${contributions[index].id}/${contributions[index].priceId}/about`,
                                        );
                                      } else {
                                        openInNewWindow(`/contribution-view/${contributions[index].id}/about`);
                                      }
                                    } else if (contributions[index].contributionPaymentType === 'Free Application') {
                                      openInNewWindow(
                                        `/apply-contribution-view/${contributions[index]?.id}/${contributions[index]?.accessCode}/about`,
                                      );
                                    } else if (contributions[index].contributionPaymentType === 'Paid Application') {
                                      if (contributions[index].priceId) {
                                        openInNewWindow(
                                          `/apply-contribution-view/${contributions[index].id}/${contributions[index].priceId}/about`,
                                        );
                                      } else {
                                        openInNewWindow(`/apply-contribution-view/${contributions[index].id}/about`);
                                      }
                                    }
                                  }}
                                >
                                  <LinkIcon />
                                </span>
                                <Switch className="switch" style={{ marginLeft: 8, marginRight: 8 }}>
                                  <StyledInput
                                    type="checkbox"
                                    onClick={e => {
                                      // const { checked } = e.target;
                                      const toBeModified = contributions[index];
                                      const newContribution = {
                                        ...toBeModified,
                                        isEnabled: !toBeModified.isEnabled,
                                        // isEnabled: checked,
                                      };
                                      onChange(index, newContribution);
                                    }}
                                    checked={item.isEnabled}
                                  />
                                  <StyledSlider className="slider round" />
                                </Switch>
                                <span
                                  role="button"
                                  tabIndex="0"
                                  className="delete-container"
                                  onClick={() => onDelete(index)}
                                  onKeyUp={() => onDelete(index)}
                                >
                                  <DeleteIcon className="other-link-delete" />
                                </span>{' '}
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      )}
    </>
  );
};

Contributions.propTypes = {
  setFieldValues: PropTypes.func.isRequired,
  values: PropTypes.shape({
    profilePageViewModel: {
      contributions: PropTypes.string,
    },
  }).isRequired,
};

export default Contributions;
