import React from 'react';
import classes from './Rating.module.scss';
import CheckIcon from '../../../Assests/Icons/CheckIcon';
import RatingIcon from '../../../Assests/Icons/RatingIcon';

const Rating = () => {
  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <RatingIcon />
      </div>
      <div className={classes.type_name}>Rating</div>
    </div>
  );
};

export default Rating;
