import React from 'react';

const CameraIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.29284 2.16667H3.56225L3.71042 1.94167L4.65986 0.5H7.4139L8.36334 1.94167L8.51151 2.16667H8.78092H10.9761C11.3001 2.16667 11.5738 2.43481 11.5738 2.77778V8.88889C11.5738 9.23186 11.3001 9.5 10.9761 9.5H1.09761C0.773617 9.5 0.5 9.23186 0.5 8.88889V2.77778C0.5 2.43481 0.773617 2.16667 1.09761 2.16667H3.29284Z"
        stroke="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.03664 4.01172C7.00901 4.01172 7.79739 4.74977 7.79739 5.66007C7.79739 6.57037 7.00901 7.30842 6.03664 7.30842C5.06426 7.30842 4.27588 6.57037 4.27588 5.66007C4.27588 4.74977 5.06426 4.01172 6.03664 4.01172Z"
        stroke="white"
      />
    </svg>
  );
};

export default CameraIcon;
