import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Loader from 'components/UI/Loader';
import {
  BasicInformationLeadMagnetPage as BasicInformationPage,
  ContributionDetailsLeadMagnetPage as ContributionDetailsPage,
  ReviewAndSubmitLeadMagnetPage as ReviewAndSubmitPage,
  SessionsLeadMagnetPage as ContentPage,
  PaymentLeadMagnetPage as PaymentPage,
} from 'pages/CreateLeadMagnet';
import { useHeader, useRouter } from 'hooks';
import * as leadMagnetActions from 'actions/leadMagnets';
import ThankYouPreviewPage from './ThankYouPreviewPage';
import CreateQuizesModal from 'pages/CreateQuizes/Components/Modals/CreateModal/CreateModal';
import { setShowCreateModal } from 'actions/quizes';
import { useSelector } from 'react-redux';

export const StyledMainSection = styled.div`
  padding: ${({ mobileView }) => (mobileView ? '30px 16px' : '30px 35px')};
`;

function CreateLeadMagnetContainer(props) {
  const {
    match: { path },
    resetLeadMagnet,
    updating,
  } = props;
  const { location } = useRouter();
  useHeader(location.pathname.includes('/thankyoupreviewpage') ? '' : 'Create a Lead Magnet');
  const dispatch = useDispatch();
  const { showModal } = useSelector(state => state.quizes);
  useEffect(() => {
    if (resetLeadMagnet) {
      resetLeadMagnet();
    }
  }, [resetLeadMagnet]);

  return (
    <>
      {updating && <Loader />}
      <Switch>
        <Route path={`${path}/basic`} exact component={BasicInformationPage} />
        {/* <Route path={`${path}/basic/:id`} exact component={BasicInformationPage} /> */}
        <Route path={`${path}/details`} exact component={ContributionDetailsPage} />
        <Route path={`${path}/content`} exact component={ContentPage} />
        {/* <Route path={`${path}/payment`} exact component={PaymentPage} /> */}
        <Route path={`${path}/review`} exact component={ReviewAndSubmitPage} />
        {/* <Route path={`${path}/:id?/thankyoupreviewpage`} exact component={ThankYouPreviewPage} /> */}
        <Redirect to={`${path}/basic`} />
      </Switch>
    </>
  );
}

CreateLeadMagnetContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.object,
  }).isRequired,
  // getContributionFromLS: PropTypes.func.isRequired,
  resetLeadMagnet: PropTypes.func.isRequired,
  updating: PropTypes.bool,
};

CreateLeadMagnetContainer.defaultProps = {
  updating: false,
};

const actions = {
  // getContributionFromLS: contributionActions.getLastDraftContribution,
  resetLeadMagnet: leadMagnetActions.resetLeadMagnet,
};

const mapStateToProps = ({ contributions }) => ({
  updating: contributions.updating,
});

export default connect(mapStateToProps, actions)(CreateLeadMagnetContainer);
