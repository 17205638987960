import React, { useState } from 'react';
import { styled as MuiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Typography, Tooltip, useTheme, useMediaQuery, Badge } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import { colors } from 'utils/styles';
import { useSessionAttachments } from 'hooks/useSessionAttachments';
import useAccount from 'hooks/useAccount';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import * as R from 'ramda';
import CloseIcon from '@material-ui/icons/Close';
import { isEmptyOrNil, lightOrDark } from 'utils/utils';
import { useSelector } from 'react-redux';
import {
  useActiveContributionSessionById,
  useActiveContributionSessionTimeOrContentById,
  useContribution,
} from 'pages/ContributionView/hooks';
import PropTypes from 'prop-types';
import { TOOLTIP } from '../../../../constants';
import SideBarNotePanel from './NotesPanel/SideBarNotePanel';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { AttachmentFileIcon, AttachmentIcon, NoteIcon } from './icons';
import CircularPlusIcon from '@mui/icons-material/ControlPoint';
import styled, { css } from 'styled-components';
import { orderBy } from 'lodash';
import { activeContribSessTabViewSettingsSelector } from 'selectors/contribution';

const maxDrawerWidth = 295;

const openedMixin = theme => ({
  width: '100%',
  // flexDirection: 'row-reverse',
  // maxWidth: maxDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  overflowY: 'auto',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  minWidth: '64px',
  width: '64px',
});

const DrawerHeader = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 5.12rem;
  padding-top: 1.25rem;
`;

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    ${({ textColor = 'white' }) =>
      css`
        color: ${textColor};
      `}
    ${({ backgroundColor }) =>
      backgroundColor &&
      css`
        background-color: ${backgroundColor};
      `}
  }
`;

const Drawer = MuiStyled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open, backgroundColor }) => ({
    width: '100%',
    // padding: '0px 20px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    zIndex: 1,
    position: 'relative !important',
    '.MuiDrawer-paper': {
      position: 'relative !important',
      border: '0px',
      ...(backgroundColor && { backgroundColor }),
    },

    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerIcon = styled.div`
  display: flex;
  padding: 5px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 27px;
  background: ${({ backgroundColor }) => backgroundColor || '#f5f5f5'};
`;

const StyledSubSectionTitle = MuiStyled(ListItemText)`
  .MuiTypography-root {
    color: ${({ textColor }) => textColor || 'var(--cohere-greys-000000, #000)'};
    font-family: Avenir;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  display: ${({ IsOpen }) => (IsOpen ? 'block' : 'none')};
`;

const SubSectionContainer = MuiStyled(ListItem)`
  display: flex !important;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start !important;
  padding: 0px !important;
`;

const SubSectionHeader = styled.div`
  display: flex;
  gap: 7px;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`;

const SubSectionContentContainer = styled.div`
  display: ${({ IsOpen }) => (IsOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const SubSectionButton = MuiStyled(Button)`
  &.MuiButton-root {
    display: ${({ IsOpen }) => (IsOpen ? 'flex' : 'none')};
    height: 36px;
    padding: 16px 10px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid ${({ borderColor }) => borderColor || '#000'};
    background: ${({ backgroundColor }) => backgroundColor || '#fff'};
    text-align: center;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    text-transform: none;
    color: ${({ textColor }) => textColor || '#000'};
  }
  &.Mui-disabled {
    color: ${({ textColor }) => textColor || '#000'} !important;
    border: ${({ isAttachmentDisabled, borderColor }) =>
      isAttachmentDisabled && `1px solid ${borderColor || '#00000042'}`};
  }
`;

const StyledAttachButton = styled(Button)`
  &.MuiButton-text {
    padding-left: 2px;
  }
`;

const AttachmentFile = MuiStyled(Button)`
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid ${({ borderColor }) => borderColor || '#dfe3e4'} !important;
  background: ${({ backgroundColor }) => backgroundColor || '#fafafa'} !important;
  width: 100%;

  span:first-child {
    gap: 16px;
    justify-content: flex-start;
  }

  svg {
    color: ${({ iconColor }) => iconColor || '#116582'};
    path {
      fill: ${({ iconColor }) => iconColor || '#116582'};
    }
  }
`;

const AttachmentFileTitle = MuiStyled(Typography)`
  color: ${({ textColor }) => textColor || '#5f5f5f'} !important;
  font-family: Avenir;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: 0;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledProgressWrap = MuiStyled(Box)`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
`;
const StyledList = MuiStyled(List)`
  display: flex;
  flex-direction: column;
  gap: 1.88rem;
  padding: 0;
  margin: 0;
`;
const StyledAttachText = styled.span`
  color: ${({ textColor }) => textColor || 'var(--Cohere-Primary-Blue, #215C73)'};
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: initial;
`;
const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root:hover {
    background-color: unset;
  }
`;
const sharedChevronIconStyles = css`
  /* path {
    fill: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#FFFFFF' : '#213649')};
  } */
`;
const StyledChevronRightIcon = styled(ChevronRightIcon)`
  ${sharedChevronIconStyles}
`;
const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  ${sharedChevronIconStyles}
`;
const StyledNoneText = styled.span`
  color: ${({ textColor }) => textColor || 'var(--cohere-greys-000000, #000)'};
`;

export default function MiniDrawer({ open, setOpen }) {
  const { selectedSessionTimeOrContentId: idForAttachment, selectedSessionId: sessionId } = useSelector(
    activeContribSessTabViewSettingsSelector,
  );
  const [openNotes, setOpenNotes] = useState(false);
  const { selectedSession } = useActiveContributionSessionById();
  const { selectedSessionTimeOrContent } = useActiveContributionSessionTimeOrContentById();
  const contribution = useContribution();
  const { id: contributionId, isDarkModeEnabled } = contribution;
  const { isPrerecorded } = selectedSession ?? {};
  const attachments = isPrerecorded ? selectedSessionTimeOrContent?.attachments : selectedSession?.attachments;
  const {
    progress,
    isFileUpload,
    // isCoach,
    handleUploadFile,
    handleDownloadFile,
    handleRemoveFile,
  } = useSessionAttachments(
    isPrerecorded ? idForAttachment : sessionId,
    contributionId,
    isPrerecorded,
    isPrerecorded ? sessionId : idForAttachment,
  );
  const { currentRole, isClient } = useAccount();
  const colorToUse = determineColorToUse(contribution);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isCoach = currentRole === 'Cohealer';
  const isAttachmentDisabled = isFileUpload || (isPrerecorded && attachments?.length >= 5);
  const {
    newThemedCardColor,
    newThemedBackgroundColor,
    themedCardOutlineColor,
    newThemedTextColor,
    themedColor,
    themedColorBtn,
    themedCardBackgroundColor,
    themedBackgroundColor,
  } = getThemedColors(contribution);
  // const textColor =
  //   colorToUse?.TextColorCode === 'Auto'
  //     ? lightOrDark(colorToUse?.PrimaryColorCode)
  //     : colorToUse?.TextColorCode === '#000000'
  //     ? '#000000'
  //     : '#FFFFFF';
  const textColor = isCoach ? themedColor : newThemedTextColor;
  const ExpansionCollapseIcon = open ? StyledChevronRightIcon : StyledChevronLeftIcon;
  return (
    <Box className="box" sx={{ display: 'flex', width: open ? '100%' : 'auto', flexDirection: 'row-reverse' }}>
      <CssBaseline />
      <Drawer
        className="main"
        variant="permanent"
        open={open}
        {...(isDarkModeEnabled
          ? {
              backgroundColor: themedCardBackgroundColor,
            }
          : {})}
      >
        {!mobileView && (
          <DrawerHeader>
            <DrawerIcon
              {...(isDarkModeEnabled
                ? {
                    backgroundColor: themedBackgroundColor,
                  }
                : {})}
            >
              <StyledIconButton
                disableRipple
                style={{
                  padding: '0px',
                  height: '1.625rem',
                  width: '1.625rem',
                  ...(isDarkModeEnabled
                    ? {
                        color: newThemedTextColor,
                        backgroundColor: newThemedBackgroundColor,
                      }
                    : {}),
                }}
                className="icon"
                onClick={open ? () => setOpen(false) : () => setOpen(true)}
              >
                <ExpansionCollapseIcon {...(isDarkModeEnabled && { fillColor: themedColorBtn })} />
              </StyledIconButton>
            </DrawerIcon>
          </DrawerHeader>
        )}
        {openNotes ? (
          <div style={{ display: open ? 'block' : 'none' }}>
            <SideBarNotePanel
              noteProps={{
                title: selectedSession.title,
                classId: selectedSession.id,
                contributionId,
                isPrerecorded: selectedSession.isPrerecorded,
                subClassId: idForAttachment,
              }}
              setOpenNotes={setOpenNotes}
            />
          </div>
        ) : (
          // list of attachment
          <StyledList>
            <SubSectionContainer key="attachment" disablePadding>
              <SubSectionHeader>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: 'center',
                  }}
                >
                  {open || !(attachments || [])?.length ? (
                    <div
                      style={{ cursor: 'pointer', marginLeft: !open ? '7px' : '' }}
                      onClick={() => {
                        if (open) {
                          setOpen(false);
                        } else {
                          setOpen(true);
                        }
                      }}
                    >
                      <AttachmentIcon {...(isDarkModeEnabled && { fillColor: newThemedTextColor })} />
                    </div>
                  ) : (
                    <StyledBadge
                      overlap="circular"
                      badgeContent={attachments?.length || 0}
                      backgroundColor={colorToUse?.AccentColorCode}
                      style={{ cursor: 'pointer', marginLeft: !open ? '8px' : '' }}
                      onClick={() => {
                        if (open) {
                          setOpen(false);
                        } else {
                          setOpen(true);
                        }
                      }}
                    >
                      <AttachmentIcon {...(isDarkModeEnabled && { fillColor: newThemedTextColor })} />
                    </StyledBadge>
                  )}
                </ListItemIcon>
                <StyledSubSectionTitle IsOpen={open} primary="Attachments" {...{ textColor }} />
              </SubSectionHeader>
              <SubSectionContentContainer IsOpen={open}>
                {isEmptyOrNil(attachments) && isClient ? (
                  <StyledNoneText {...{ textColor }}>None</StyledNoneText>
                ) : (
                  orderBy(attachments, [k => k.uploadDateTime], ['desc']).map(
                    (
                      {
                        id: documentId,
                        contentType,
                        attachementUrl,
                        documentKeyWithExtension,
                        documentOriginalNameWithExtension,
                      },
                      idx,
                    ) => (
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <AttachmentFile
                          onClick={handleDownloadFile}
                          data-content-type={contentType}
                          disableRipple
                          data-document-id={documentId}
                          data-document-name={documentOriginalNameWithExtension}
                          iconColor={colorToUse?.AccentColorCode}
                          backgroundColor={isDarkModeEnabled ? newThemedBackgroundColor : null}
                          borderColor={isDarkModeEnabled && themedCardOutlineColor}
                        >
                          <AttachmentFileIcon />
                          <AttachmentFileTitle {...{ textColor }}>
                            {/* <span>{`${R.inc(idx)}.`}</span> */}
                            <span>{documentOriginalNameWithExtension}</span>
                          </AttachmentFileTitle>
                        </AttachmentFile>
                        {isCoach && (
                          <CloseIcon
                            style={{
                              marginLeft: '5px',
                              ...(isDarkModeEnabled ? { color: newThemedTextColor } : {}),
                            }}
                            fontSize="small"
                            className="cursor-pointer"
                            onClick={handleRemoveFile}
                            data-document-id={documentId}
                            data-document-extension={documentKeyWithExtension}
                          />
                        )}
                      </div>
                    ),
                  )
                )}
              </SubSectionContentContainer>

              {isCoach && (
                <div style={{ display: open ? 'flex' : 'none', alignItems: 'center', gap: '8px' }}>
                  <>
                    <input
                      accept="*"
                      className="d-none"
                      id={`attach-file-${sessionId}`}
                      type="file"
                      onChange={handleUploadFile}
                      multiple
                      disabled={isAttachmentDisabled}
                    />
                    <label
                      style={{ marginBottom: '0px', display: 'flex', gap: '5px', alignItems: 'center' }}
                      htmlFor={`attach-file-${sessionId}`}
                    >
                      {mobileView ? (
                        <StyledAttachButton
                          disabled={isFileUpload}
                          disableRipple
                          component="span"
                          startIcon={
                            // <span
                            //   style={{
                            //     height: '1.5rem',
                            //     width: '1.5rem',
                            //     borderRadius: 100,
                            //     backgroundColor: colorToUse?.PrimaryColorCode,
                            //     justifyContent: 'center',
                            //     alignItems: 'center',
                            //     display: 'flex',
                            //   }}
                            // >
                            <CircularPlusIcon
                              // fontSize="small"
                              style={{ width: '1.5rem', height: '1.5rem', color: colorToUse?.AccentColorCode }}
                            />
                            // {/* </span> */}
                          }
                        >
                          <StyledAttachText textColor={colorToUse?.AccentColorCode}>Attach a File</StyledAttachText>
                        </StyledAttachButton>
                      ) : (
                        <SubSectionButton
                          disabled={isAttachmentDisabled}
                          isAttachmentDisabled={isAttachmentDisabled}
                          IsOpen={open}
                          disableRipple
                          component="span"
                          {...(isDarkModeEnabled
                            ? {
                                borderColor: themedCardOutlineColor,
                                backgroundColor: newThemedCardColor,
                                textColor: themedColor,
                              }
                            : {})}
                        >
                          Attach a File
                        </SubSectionButton>
                      )}
                      {isFileUpload && (
                        <StyledProgressWrap position="relative">
                          <CircularProgressWithLabel
                            value={progress}
                            {...{ textColor, progressColor: colorToUse?.AccentColorCode }}
                          />
                        </StyledProgressWrap>
                      )}
                    </label>
                  </>
                  {isPrerecorded && (
                    <Tooltip
                      title="You can attach up to 5 files per content per module"
                      arrow
                      enterTouchDelay={TOOLTIP.ENTER_DELAY}
                      leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      style={{ marginLeft: '5px' }}
                    >
                      <InfoIcon
                        style={{ margin: '0px' }}
                        htmlColor={colorToUse?.PrimaryColorCode || colors.lightBrown}
                      />
                    </Tooltip>
                  )}
                </div>
              )}
              {/* <SubSectionButton IsOpen={open}>Attach a File</SubSectionButton>
                <Tooltip
                  title="content is missing"
                  arrow
                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                >
                  <InfoIcon htmlColor={colors.lightBrown} />
                </Tooltip> */}
            </SubSectionContainer>

            <SubSectionContainer key="personalNotes" disablePadding>
              <SubSectionHeader>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{ cursor: 'pointer', marginLeft: !open ? '7px' : '' }}
                    onClick={() => {
                      if (open) {
                        setOpen(false);
                      } else {
                        setOpen(true);
                      }
                    }}
                  >
                    <NoteIcon {...(isDarkModeEnabled && { fillColor: newThemedTextColor })} />
                  </div>
                </ListItemIcon>
                <StyledSubSectionTitle IsOpen={open} primary="Personal Notes" {...{ textColor }} />
              </SubSectionHeader>
              <SubSectionButton
                IsOpen={open}
                onClick={() => setOpenNotes(true)}
                {...(isDarkModeEnabled
                  ? { borderColor: themedCardOutlineColor, backgroundColor: newThemedCardColor, textColor }
                  : {})}
              >
                Add a Note
              </SubSectionButton>
            </SubSectionContainer>
          </StyledList>
        )}
      </Drawer>
    </Box>
  );
}

MiniDrawer.propTypes = {
  sessionId: PropTypes.string.isRequired,
  idForAttachment: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
