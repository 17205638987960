import { createAction } from 'redux-actions';
export const SHOW_MODAL_CREATE = 'SHOW_MODAL_CREATE';
export const SET_ACTIVE_QUIZ = 'SET_ACTIVE_QUIZ';
export const SET_SHOW_CUSTOMIZE_RESULTS = 'SET_SHOW_CUSTOMIZE_RESULTS';
export const SET_LEAD_USER = 'SET_LEAD_USER';
export const SET_RESPONSE_USER = 'SET_RESPONSE_USER';
export const SHOW_MODAL_CREATE_FORM = 'SHOW_MODAL_CREATE_FORM';
export const SHOW_MODAL_CREATE_QUIZ = 'SHOW_MODAL_CREATE_QUIZ';
export const SET_DEFAULT_PAGE = 'SET_DEFAULT_PAGE';
export const SET_TABS_COUNT = 'SET_TABS_COUNT';

export const setShowCreateModal = createAction(SHOW_MODAL_CREATE);
export const setShowCreateModalQuiz = createAction(SHOW_MODAL_CREATE_QUIZ);
export const setShowCreateModalForm = createAction(SHOW_MODAL_CREATE_FORM);
export const setActiveQuiz = createAction(SET_ACTIVE_QUIZ);

export const setShowCustomizeResults = createAction(SET_SHOW_CUSTOMIZE_RESULTS);

export const setLeadUser = createAction(SET_LEAD_USER);

export const setUserResponse = createAction(SET_RESPONSE_USER);

export const setDefaultPage = createAction(SET_DEFAULT_PAGE);

export const setAllFormsCountDetails = createAction(SET_TABS_COUNT);
