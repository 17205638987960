import { useDeepEqualSelector, useShallowEqualSelector } from 'hooks';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const selector = state => state?.contributions?.activeContribution || {};

const getActiveContributionSelector = createSelector(selector, contribution => contribution);

function useContribution() {
  // return useDeepEqualSelector(selector);
  return useShallowEqualSelector(selector);
  // return useSelector(getActiveContributionSelector);
}

export default useContribution;
