import React, { useState, useEffect } from 'react';
import classes from '../../QuizPage.module.scss';
import Button from 'components/FormUI/Button';
import { MenuItem, OutlinedInput, Select, Paper, Tooltip } from '@material-ui/core';
import useRouter from 'hooks/useRouter';
import { convertToPlainText, lightOrDark } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import MultipleChoice from '../MultipleChoice/MultipleChoice';
import { submitQuestion } from 'pages/CreateQuizes/Services/CreateQuizes.service';
import useAccount from 'hooks/useAccount';
import { isEmpty } from 'lodash';
import { CONTRIBUTION_COLORS } from '../../../../../../constants';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import RankingTypeOptions from '../../../../../../pages/CreateQuizes/Pages/QuizContent/Components/FormOptions/FormOptionsType/RankingType/RankingType';
import RatingTypeOptions from '../../../../../../pages/CreateQuizes/Pages/QuizContent/Components/FormOptions/FormOptionsType/RatingType/RatingType';
import ShortTextOptions from '../../../../../../pages/CreateQuizes/Pages/QuizContent/Components/FormOptions/FormOptionsType/ShortText/ShortText';
import { setUserResponse } from 'actions/quizes';
import { useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';

// const CustomPaper = styled(Paper)(({ mobileView }) => ({
//   top: mobileView ? '267px !important' : '280px !important',
//   height: mobileView ? 'min-content' : 'min-content',
// }));

export default function QuestionView({ question, setActiveIndex, activeIndex, isPreview }) {
  const { activeQuiz, userDetails, userResponse } = useSelector(state => state?.quizes);
  const { name, questionType, options, isRequired } = question;
  const [checked, setChecked] = useState([]);
  const [showRanking, setShowRanking] = useState(false);
  const [responseSubmitted, setResponseSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isCorrectSelected, setIsCorrectSelected] = useState(false);
  const [fetchedRespose, setfetchedRespose] = useState({});
  const { history } = useRouter();
  const { user } = useAccount();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { questions, isBrandingColorEnabled, brandingColors } = activeQuiz;
  const { PrimaryColorCode, AccentColorCode, TextColorCode } = brandingColors ?? CONTRIBUTION_COLORS;
  const textColor =
    TextColorCode === 'Auto' ? lightOrDark(PrimaryColorCode) : TextColorCode === '#000000' ? '#000000' : '#FFFFFF';
  const isLastQuestion = activeIndex === questions.length - 1;
  const getFirstSentence = str => {
    let text = convertToPlainText(str);
    const indexOfDot = text.indexOf('.');
    return indexOfDot !== -1 ? text.substring(0, indexOfDot) : text;
  };
  const correctAnswer = options.filter(item => item.isCorrect === true);
  useEffect(() => {
    setResponseSubmitted(false);
    setChecked([]);
    if (activeQuiz.type === 'Quiz') {
      setChecked([]);
    } else {
      if (userResponse && !isEmpty(userResponse)) {
        let selectedQuestionResponse = userResponse?.filter(item => item.questionId === question.id);
        if (selectedQuestionResponse.length > 0) {
          if (question.questionType === 'Ranking') {
            let updatedRespomse = selectedQuestionResponse[0]?.userResponses?.map(item => ({
              value: item,
              isCorrect: false,
            }));
            if (selectedQuestionResponse[0]?.userResponses === undefined) {
              setShowRanking(false);
              setChecked(options);
              setTimeout(() => {
                setShowRanking(true);
              }, 1000);
            } else {
              setShowRanking(false);
              setChecked(updatedRespomse);
              setTimeout(() => {
                setShowRanking(true);
              }, 1000);
            }
          } else {
            if (selectedQuestionResponse[0]?.userResponses === undefined) {
              setChecked([]);
            } else {
              setChecked(selectedQuestionResponse[0]?.userResponses);
            }
          }
        } else {
          if (question.questionType === 'Ranking') {
            setShowRanking(true);
            setChecked(options);
          } else {
            setChecked([]);
          }
        }
      } else {
        if (question.questionType === 'Ranking') {
          setShowRanking(true);
          setChecked(options);
        } else {
          setChecked([]);
        }
      }
    }
  }, [activeIndex]);

  const submitAnswer = () => {
    const isNestedArray = arr => Array.isArray(arr) && arr.some(Array.isArray);
    let userResponses = question?.canSelectMultipleCorrectOptions
      ? checked
      : questionType === 'Ranking'
      ? checked.map(item => item.value)
      : checked.length === 0
      ? []
      : [checked];
    let params = {
      quizId: activeQuiz.id,
      userId: !isEmpty(user) ? user.id : userDetails.id,
      questionId: question.id,
      quizLeadUserEmail: isEmpty(user) ? userDetails.email : user.email,
      userResponses: isNestedArray(userResponses) ? userResponses.flat() : userResponses,
      isLastQuestion: isLastQuestion,
    };
    submitQuestion(params)
      .then(res => {
        let selectedQuestionResponse = res.responses.filter(item => item.questionId === question.id);
        setIsCorrectSelected(selectedQuestionResponse[0].isCorrect);
        setfetchedRespose(res);
        setResponseSubmitted(true);
        if (activeQuiz.type === 'Form') {
          setChecked([]);
          setActiveIndex(activeIndex + 1);
          setResponseSubmitted(true);
          dispatch(setUserResponse(res.responses));
        }
      })
      .catch(() => {
        if (activeQuiz.type === 'Form') {
          setChecked([]);
          setActiveIndex(activeIndex + 1);
        }
      });
  };

  const getDateTitle = dateString => {
    console.log('dateString => ', dateString);
    if (!mobileView) {
      if (dateString?.length <= 80) {
        return dateString;
      } else {
        return dateString.substring(0, 80) + '...';
      }
    } else {
      if (dateString?.length <= 28) {
        return dateString;
      } else {
        return dateString.substring(0, 28) + '...';
      }
    }
  };

  return (
    <div className={classes.preview_container}>
      {activeQuiz.type === 'Form' ? (
        <div className={activeQuiz.type === 'Form' ? classes.header_container_form : classes.header_container}>
          <div className={classes.header}>{activeIndex + 1}.</div>
          <div className={classes.question}>
            <div
              style={{ maxWidth: '100%', overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}
              className={classes.question_content}
              dangerouslySetInnerHTML={{ __html: `${name.trim()}*` }}
            ></div>
          </div>
        </div>
      ) : (
        <div className={activeQuiz.type === 'Form' ? classes.header_container_form : classes.header_container}>
          <div className={classes.header}>*{activeIndex + 1}.</div>
          <div className={classes.question}>
            <div
              style={{ maxWidth: '100%', overflowWrap: 'break-word', wordWrap: 'break-word', wordBreak: 'break-word' }}
              className={classes.question_content}
              dangerouslySetInnerHTML={{ __html: `${name}` }}
            ></div>
          </div>
        </div>
      )}
      <div style={activeQuiz.type === 'Quiz' ? {} : { marginTop: '10px' }} className={classes.options_container}>
        {activeQuiz.type === 'Quiz' && question.canSelectMultipleCorrectOptions === true ? (
          <div className={classes.sub_header}>Choose only best answers</div>
        ) : (
          activeQuiz.type === 'Quiz' && <div className={classes.sub_header}>Choose only one best answer</div>
        )}
        {questionType === 'MultipleChoice' &&
          options.map(data => {
            return (
              <MultipleChoice
                checked={checked}
                setChecked={setChecked}
                data={data}
                responseSubmitted={responseSubmitted}
                fetchedRespose={fetchedRespose}
                isMultipleAllowed={question.canSelectMultipleCorrectOptions}
                setErrorMessage={setErrorMessage}
                isForm={activeQuiz.type === 'Form'}
              />
            );
          })}
        {questionType == 'Dropdown' && (
          <Select
            style={{ overflow: 'hidden' }}
            className="select-field"
            variant="outlined"
            fullWidth
            disabled={activeQuiz.type === 'Quiz' && responseSubmitted}
            input={<OutlinedInput margin="dense" />}
            onChange={e => {
              setErrorMessage(false);
              setChecked(e.target.value);
            }}
            value={checked}
            PopperProps={{
              disableInteractive: true,
            }}
            MenuProps={{
              disablePortal: true,
              PaperProps: {
                style: {
                  minHeight: mobileView ? 'min-content' : 'min-content',
                },
              },
            }}
            /*    MenuProps={{
          PaperProps: {
            component: CustomPaper,
            mobileView,
          },
        }} */
          >
            {options.map(data => {
              return (
                <MenuItem
                  disabled={false}
                  style={{
                    fontFamily: 'Avenir',
                    fontWeight: checked === data.value && checked.length === data.value.length ? '500' : '300',
                    fontSize: '16px',
                    color: '#213649',
                  }}
                  value={data.value}
                >
                  <Tooltip title={data.value}>
                    <div> {getDateTitle(data.value)} </div>
                  </Tooltip>
                </MenuItem>
              );
            })}
          </Select>
        )}
        {questionType == 'Ranking' && showRanking && (
          <RankingTypeOptions question={question} setChecked={setChecked} clientView={true} checked={checked} />
        )}
        {questionType == 'Rating' && (
          <RatingTypeOptions question={question} setChecked={setChecked} clientView={true} checked={checked} />
        )}
        {questionType == 'ShortText' && (
          <ShortTextOptions question={question} setChecked={setChecked} clientView={true} checked={checked} />
        )}
        {errorMessage && (
          <div style={{ marginTop: '10px' }}>
            <CommonErrorMessage align="center" message="Please select at least one option." />
          </div>
        )}
        <div className={classes.footer}>
          {activeQuiz.type === 'Quiz' &&
            responseSubmitted &&
            !isCorrectSelected &&
            (question.canSelectMultipleCorrectOptions === true ? (
              <div className={classes.banner}>
                The selected option(s) is/are incorrect. The correct answers are highlighted above.
                {/* {correctAnswer.map((answer, index) => (
                  <span key={index}>
                    {convertToPlainText(answer.value)}
                    {index < correctAnswer.length - 1 ? ', ' : ''}
                  </span>
                ))} */}
              </div>
            ) : (
              <div className={classes.banner}>
                This answer is incorrect. The correct answer is{' '}
                {convertToPlainText(correctAnswer[0].value).length > 50
                  ? `${convertToPlainText(correctAnswer[0].value).substring(0, 20)}...`
                  : convertToPlainText(correctAnswer[0].value)}
                .
              </div>
            ))}
          {activeQuiz.type === 'Quiz' && responseSubmitted && isCorrectSelected && (
            <div className={classes.bannerCorrect}>This answer is correct.</div>
          )}

          {activeQuiz.type === 'Quiz' ? (
            <Button
              className={classes.submit_btn}
              variant="secondary"
              autoWidth
              disabled={isPreview && isLastQuestion}
              backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
              textColor={isBrandingColorEnabled ? textColor : null}
              onClick={() => {
                if (isPreview) {
                  setActiveIndex(activeIndex + 1);
                } else {
                  if (!responseSubmitted) {
                    setErrorMessage(false);
                    if (checked.length === 0) {
                      setErrorMessage(true);
                    } else {
                      submitAnswer();
                    }
                  } else if (responseSubmitted && isLastQuestion) {
                    console.log('first', !isEmpty(user) ? user.id : userDetails.id, user.id, userDetails.id);
                    history.replace(
                      `/quiz-view/${activeQuiz.id}/result/${fetchedRespose.id}/${
                        !isEmpty(user) ? user.id : userDetails.id
                      }`,
                    );
                  } else if (responseSubmitted && isLastQuestion === false) {
                    setActiveIndex(activeIndex + 1);
                  }
                }
              }}
            >
              {isPreview && !isLastQuestion
                ? 'Next'
                : isLastQuestion && isPreview
                ? 'Submit'
                : isLastQuestion && responseSubmitted
                ? 'Submit'
                : !isLastQuestion && responseSubmitted
                ? 'Next'
                : 'Confirm Answer'}
            </Button>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
              {activeIndex > 0 && (
                <Button
                  className={classes.backbtn}
                  variant="secondary"
                  invert
                  autoWidth
                  disabled={isPreview && isLastQuestion}
                  backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
                  textColor={isBrandingColorEnabled ? PrimaryColorCode : null}
                  onClick={() => {
                    setActiveIndex(activeIndex - 1);
                    setChecked([]);
                  }}
                  style={
                    isBrandingColorEnabled
                      ? {
                          color: isBrandingColorEnabled ? PrimaryColorCode : null,
                          borderColor: isBrandingColorEnabled ? PrimaryColorCode : null,
                        }
                      : {}
                  }
                >
                  {'Back'}
                </Button>
              )}
              <Button
                className={classes.submit_btn}
                variant="secondary"
                autoWidth
                disabled={(isPreview && isLastQuestion) || (isRequired && checked.length === 0 && !isPreview)}
                backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
                textColor={isBrandingColorEnabled ? textColor : null}
                onClick={() => {
                  if (isPreview) {
                    setActiveIndex(activeIndex + 1);
                  } else {
                    if (!isLastQuestion) {
                      setErrorMessage(false);
                      if (checked.length === 0 && isRequired) {
                        setErrorMessage(true);
                      } else {
                        submitAnswer();
                      }
                    } else if (isLastQuestion) {
                      if (checked.length === 0 && isRequired) {
                        setErrorMessage(true);
                      } else {
                        submitAnswer();
                        history.replace(`/form-view/${activeQuiz.id}/thankyou`);
                      }
                    }
                  }
                }}
              >
                {isPreview && !isLastQuestion
                  ? 'Next'
                  : isLastQuestion && isPreview
                  ? 'Submit'
                  : isLastQuestion
                  ? 'Submit'
                  : 'Next'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
