import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AffiliateCoachBanner from './BannerTypes/AffiliateCoachBanner';
import Email from './BannerTypes/Email';
import BankInfo from './BannerTypes/BankInfo';
import ClosestSession from './BannerTypes/ClosestSession';
import TestVideo from './BannerTypes/TestVideo';
import SocialSecurityInfo from './BannerTypes/SocialSecurityInfo';
import ClientDeclinedSubscriptions from './BannerTypes/ClientDeclinedSubscriptions';
import CoachDeclinedSubscriptions from './BannerTypes/CoachDeclinedSubscriptions';
import './Banner.scss';
import StandardStripeBanner from './BannerTypes/StandardStripeBanner';
import StripeRequestBanner from './BannerTypes/StripeRequestBanner';
import PendingApplications from './BannerTypes/PendingApplications';
import AddressBanner from './BannerTypes/AddressBanner';
import PaymentFailedBanner from './BannerTypes/PaymentFailedBanner';

const Banner = ({ type, closestClassForBanner, clientDeclinedSubscriptions, coachDeclinedSubscriptions }) => {
  const getBanner = useCallback(() => {
    switch (type) {
      case 'email':
        return <Email />;
      case 'address':
        return <AddressBanner />;
      case 'bankInfo':
        return <BankInfo />;
      case 'closestSession':
        return <ClosestSession closestClassForBanner={closestClassForBanner || {}} />;
      case 'testVideo':
        return <TestVideo />;
      case 'socialSecurityInfo':
        return <SocialSecurityInfo />;
      // case 'moreInfoRequired':
      //   return <StripeRequestBanner />;
      case 'coachDeclinedSubscriptions':
        return <CoachDeclinedSubscriptions coachDeclinedSubscriptions={coachDeclinedSubscriptions} />;
      case 'standardAccountTransfersEnabled':
        return <StandardStripeBanner />;
      case 'affiliateCoach':
        return <AffiliateCoachBanner />;
      case 'clientDeclinedSubscriptions':
        return <ClientDeclinedSubscriptions clientDeclinedSubscriptions={clientDeclinedSubscriptions} />;
      case 'pendingApplicationsCount':
        return <PendingApplications />;
      case 'affiliateCoach':
        return <AffiliateCoachBanner />;
      case 'paymentFailed':
        return <PaymentFailedBanner />;
      default:
        return null;
    }
  }, [type, closestClassForBanner]);

  return getBanner(type);
};

Banner.defaultProps = {
  className: null,
  closestClassForBanner: {},
};

Banner.propTypes = {
  type: PropTypes.string.isRequired,
  closestClassForBanner: PropTypes.shape({
    contributionId: PropTypes.string,
    classId: PropTypes.string,
    contributionTitle: PropTypes.string,
    minutesLeft: PropTypes.number,
  }),
};

export default Banner;
