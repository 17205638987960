import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTheme, useMediaQuery } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { EnrollmentFormRow } from './EnrollmentFormRow';
import { Pagination } from '@mui/material';
import { GetAllEnrollmentFormsByCoach } from 'services/user.service';
import Loader from 'components/UI/Loader';

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);
const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);

const EnrollmenFormTable = ({ rows }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [openedIndex, setOpenedIndex] = useState(null);
  const [pageNumber, setpageNumber] = useState(1);
  const [totalRecords, settotalrecords] = useState(0);
  const [contributionEnrollmentsData, setcontributionEnrollmentsData] = useState(null);
  const round = Math.ceil(contributionEnrollmentsData?.totalRecords / 20);
  const [loading, setLoading] = useState(false);
  const handleChange = useCallback((event, value) => {
    if (value === 1) {
      setpageNumber(1);
      GetAllEnrollmentFormsByCoachFunction(1);
    } else if (value > 1) {
      setpageNumber(value);
      GetAllEnrollmentFormsByCoachFunction(value);
    } else {
      setpageNumber(value);
      GetAllEnrollmentFormsByCoachFunction(value);
    }
  }, []);

  const GetAllEnrollmentFormsByCoachFunction = number => {
    GetAllEnrollmentFormsByCoach(number).then(res => {
      setcontributionEnrollmentsData(res);
      setpageNumber(res?.pageNumber);
      settotalrecords(res?.totalRecords);
      setLoading(false);
    });
  };
  useEffect(() => {
    setLoading(true);
    GetAllEnrollmentFormsByCoachFunction(pageNumber);
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <>
      <StyledTableContainer component={Paper} mobileView={mobileView}>
        <Table className="applications-table" aria-label="applications-table">
          <TableHead>
            <TableRow>
              <BoldTableCell>Service Name</BoldTableCell>
              <BoldTableCell>Type</BoldTableCell>
              <BoldTableCell>No. of Questions</BoldTableCell>
              <BoldTableCell>No. of Responses</BoldTableCell>
              <BoldTableCell>Date</BoldTableCell>
              <BoldTableCell align="center">Export Responses</BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contributionEnrollmentsData?.items?.map((row, index) => (
              <EnrollmentFormRow
                row={row}
                index={index}
                key={row.id}
                totalRows={rows.length}
                openedIndex={openedIndex}
                setOpenedIndex={setOpenedIndex}
              />
            ))}
          </TableBody>
        </Table>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <div style={{ fontSize: '13px', fontWeight: '400', position: 'relative', bottom: '-12px' }}>
            {pageNumber === 0 ? 1 : pageNumber * 10 - 10 + 1} - {round != pageNumber ? pageNumber * 10 : totalRecords}{' '}
            of {totalRecords}
          </div>
          <Pagination
            count={round}
            page={pageNumber}
            onChange={handleChange}
            className={`client-info-table-pagination`}
            size="small"
            shape="rounded"
          />
        </div>
      </StyledTableContainer>
    </>
  );
};
EnrollmenFormTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
};
EnrollmenFormTable.defaultProps = {
  rows: [],
};
export default EnrollmenFormTable;
