import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BiSolidTimeFive } from 'react-icons/bi';
import { BsFillCameraVideoFill, BsChatLeftDotsFill, BsFillPersonFill } from 'react-icons/bs';
import { memberPodsService } from 'services/memberPods/memberPods.service';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';
import styled from 'styled-components';
import { mdiApps, mdiMessageProcessing, mdiTimerOutline, mdiAccountOutline, mdiGenderMale } from '@mdi/js';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { ContributionType, ContributionThemedColors } from 'helpers/constants';
import { getLanguages } from 'utils/transformerLanguages';
import { Card, CardBody } from 'components/UI/Card';
import { LabelText } from 'components/UI/Text/TextStyles';
import Icon from 'components/UI/Icon';
import BreathWork from '../../../assets/1.Bs.png';
import Sessions from '../../../assets/2.Bs.png';
import AgeIcon from '../../../assets/4.Bs.png';
import LanguageIcon from '../../../assets/3.Bs.png';
import RequirementsIcon from '../../../assets/5.Bs.png';
import { getThemedColors } from 'services/contributions.service';
import useAccount from 'hooks/useAccount';

const useStyles = makeStyles(theme => ({
  gridItem: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const genderDisplay = {
  NoRequirement: 'No Requirement',
  Male: 'For Men',
  Female: 'For Women',
  NonBinary: 'Everyone Welcome',
};

const getSessionsCount = ({ availabilityTimes = [], clientPackages }) => {
  let newArray = [];

  availabilityTimes.forEach(time => {
    time.bookedTimes.forEach(booked => {
      if (booked.participantId && booked.isPurchaseConfirmed) {
        newArray = [...newArray, booked];
      }
    });
  });

  return newArray.length + (clientPackages && clientPackages.length ? sumBy(clientPackages, 'freeSessionNumbers') : 0);
};

const AboutGeneralInfoBlock = ({
  contribution = {},
  categories = [],
  gender,
  minAge,
  languageCodes = [],
  brandingColors,
}) => {
  const classes = useStyles();
  const [podsArray, setPodsArray] = useState([]);
  const [totalSessions, setTotalSessions] = useState({
    liveSession: null,
    selfPaced: null,
  });
  const theme = useTheme();
  const { user, currentRole } = useAccount();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const mobileViewTem2 = useMediaQuery(theme.breakpoints.down('sm'));
  const oneToOneType = contribution?.type === ContributionType.contributionOneToOne;
  const { themedColor } = getThemedColors(contribution);

  const sessionsCount = !isEmpty(contribution?.sessions)
    ? contribution.sessions.length
    : getSessionsCount(contribution);
  useEffect(() => {
    memberPodsService.getContributionPods(contribution.id).then(data => {
      setPodsArray(data);
    });
  }, []);
  const SideIconMain = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    // padding: 1rem 0px 1px 0px;
    align-items: center;
    margin-bottom: 50px;
    flex-wrap: wrap;

    ${({ mobileViewTem2 }) => (mobileViewTem2 ? 'width: 100%' : '80%')};
    ${({ mobileViewTem2 }) => (mobileViewTem2 ? 'margin: 0px' : '')};
  `;
  const SideImg1 = styled.div`
    display: flex;
    align-items: center;
    margin-right: ${({ mobileViewTem2 }) => (mobileViewTem2 ? '' : '20px')};
    ${({ mobileViewTem2 }) => (mobileViewTem2 ? 'padding: 6px 0px' : '')};
  `;
  const SideIcon = styled.img``;

  const BreathTxt = styled.p`
    font-family: 'Poppins';
    color: ${props => props.themedColor};
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 0.5rem;
    margin: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  `;

  const StyledBiSolidTimeFive = styled(BiSolidTimeFive)`
    color: ${({ color }) => color};
    height: 25px;
    width: 25px;
    margin: 0px 5px;

    ${({ mobileView }) =>
      mobileView &&
      `
  height: 15px;
  width: 15px;
  `}
  `;

  const StyledBsFillCameraVideoFill = styled(BsFillCameraVideoFill)`
    color: ${({ color }) => color};
    height: 25px;
    width: 25px;
    margin: 0px 5px;

    ${({ mobileView }) =>
      mobileView &&
      `
height: 15px;
width: 15px;
`}
  `;

  const StyledBsChatLeftDotsFill = styled(BsChatLeftDotsFill)`
    color: ${({ color }) => color};
    height: 25px;
    width: 25px;
    margin: 0px 5px;

    ${({ mobileView }) =>
      mobileView &&
      `
height: 15px;
width: 15px;
`}
  `;

  const StyledBsFillPersonFill = styled(BsFillPersonFill)`
    color: ${({ color }) => color};
    height: 25px;
    width: 25px;
    margin: 0px 5px;

    ${({ mobileView }) =>
      mobileView &&
      `
height: 15px;
width: 15px;
`}
  `;

  useEffect(() => {
    if (contribution?.sessions) {
      let selfPaced = totalSessions.selfPaced;
      let liveSession = totalSessions.liveSession;
      const mappedSessions = contribution?.sessions.map(session => {
        if (session.isPrerecorded) {
          selfPaced = selfPaced + 1;
        } else {
          liveSession = liveSession + 1;
        }
        return session;
      });
      setTotalSessions({
        liveSession,
        selfPaced,
      });
    }
  }, []);
  const filterSessions = () => {
    // const podIds = sessions.flatMap(session => session.sessionTimes.filter(sessionTime => sessionTime.podId !== "").map(sessionTime => sessionTime.podId));
    let count = 0;
    if (contribution?.sessions) {
      for (const session of contribution?.sessions) {
        let shouldCountSession = false;
        for (const sessionTime of session.sessionTimes) {
          // Check if SessionTime does not have a PodId
          if (sessionTime.podId === '' || sessionTime.podId === null) {
            shouldCountSession = true;
            break; // One SessionTime without a PodId is enough to count the session
          } else {
            // If SessionTime has a PodId, check if the user is not in the Pod's ClientIds
            const pod = podsArray.find(p => p.id === sessionTime.podId);
            if (isEmpty(pod) || pod.clientIds.includes(user.id)) {
              shouldCountSession = true;
              break; // User not being in a Pod's ClientIds is enough to count the session
            }
          }
        }

        // If the criteria are met, count the session
        if (shouldCountSession) {
          count = count + 1;
        }
      }
    }
    return count;
  };
  useEffect(() => {
    if (!isEmpty(user)) filterSessions();
  }, [podsArray]);
  return (
    contribution?.id != '668302b62459e1456f1b814d' &&
    contribution?.id != '6682fb852459e1456f1b7f48' && (
      <SideIconMain mobileViewTem2={mobileViewTem2}>
        {(oneToOneType || totalSessions.liveSession !== null) && (
          <SideImg1 mobileViewTem2={mobileViewTem2}>
            {/* <Icon path={mdiTimerOutline} right="4" /> */}
            <StyledBiSolidTimeFive color={brandingColors?.PrimaryColorCode} />
            {isEmpty(user) ? (
              <BreathTxt themedColor={themedColor}>{`${oneToOneType ? '1:1' : totalSessions.liveSession}  ${
                totalSessions.liveSession == 1 ? `Session` : `Sessions`
              }`}</BreathTxt>
            ) : (
              <BreathTxt themedColor={themedColor}>{`${
                oneToOneType ? '1:1' : filterSessions() - totalSessions.selfPaced
              }  ${filterSessions() - totalSessions.selfPaced == 1 ? `Session` : `Sessions`}`}</BreathTxt>
            )}
          </SideImg1>
        )}
        {totalSessions.selfPaced && (
          <SideImg1 mobileViewTem2={mobileViewTem2}>
            {/* <Icon path={mdiMessageProcessing} right="4" /> */}
            <StyledBsFillCameraVideoFill color={brandingColors?.PrimaryColorCode} />
            <BreathTxt themedColor={themedColor}>{`${oneToOneType ? '1:1' : totalSessions.selfPaced} Self-Paced Module${
              totalSessions.selfPaced > 1 ? 's' : ''
            }`}</BreathTxt>
          </SideImg1>
        )}
        <SideImg1 mobileViewTem2={mobileViewTem2}>
          <StyledBsChatLeftDotsFill color={brandingColors?.PrimaryColorCode} />
          <BreathTxt themedColor={themedColor}>{getLanguages(languageCodes).join(', ')}</BreathTxt>
        </SideImg1>
        <SideImg1 mobileViewTem2={mobileViewTem2}>
          {/* <Icon path={mdiAccountOutline} right="4" /> */}
          <StyledBsFillPersonFill color={brandingColors?.PrimaryColorCode} />
          <BreathTxt themedColor={themedColor}>{minAge}</BreathTxt>
        </SideImg1>
      </SideIconMain>
    )
  );
};

AboutGeneralInfoBlock.propTypes = {
  contribution: PropTypes.shape({}),
  categories: PropTypes.arrayOf(PropTypes.string),
  gender: PropTypes.string.isRequired,
  minAge: PropTypes.number.isRequired,
  languageCodes: PropTypes.arrayOf(PropTypes.string),
};

AboutGeneralInfoBlock.defaultProps = {
  contribution: {},
  categories: [],
  languageCodes: [],
};

export default AboutGeneralInfoBlock;
