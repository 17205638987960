import React from 'react';

const ShortTextIcon = () => {
  return (
    <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.00006H9" stroke="#213649" stroke-width="1.5" stroke-linecap="round" />
      <path d="M1.5 4.00006H5" stroke="#213649" stroke-width="1.5" stroke-linecap="round" />
      <path d="M7.5 4.00006H8.5" stroke="#213649" stroke-width="1.5" stroke-linecap="round" />
    </svg>
  );
};

export default ShortTextIcon;
