import React from 'react';
import classes from '../ResultPage.module.scss';
import Button from 'components/FormUI/Button';
import { useSelector } from 'react-redux';
import useAccount from 'hooks/useAccount';
import useRouter from 'hooks/useRouter';
import { redirectTo } from 'services/links';
import { isEmpty } from 'lodash';
import { lightOrDark } from 'utils/utils';
import { CONTRIBUTION_COLORS } from '../../../../../constants';

export default function PassedResult({ resultObtained, isNoGradingResult }) {
  const {
    activeQuiz: { passResultData, noResultData, isBrandingColorEnabled, brandingColors },
    userDetails,
  } = useSelector(state => state?.quizes);
  const { PrimaryColorCode, AccentColorCode, TextColorCode } = brandingColors ?? CONTRIBUTION_COLORS;
  const textColor =
    TextColorCode === 'Auto' ? lightOrDark(PrimaryColorCode) : TextColorCode === '#000000' ? '#000000' : '#FFFFFF';
  const { user } = useAccount();
  const { history, query } = useRouter();

  function ensureHttps(url) {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }
    return url;
  }

  // Utility to replace placeholders with actual values in UI
  const renderTextWithPlaceholders = (text, placeholders) => {
    return text.split(placeholders[0]).reduce((acc, part, index) => {
      if (index === 0) return [part];
      return acc.concat(
        <span key={index} className={placeholders[2]}>
          {placeholders[1]}
        </span>,
        part,
      );
    }, []);
  };

  const headerText = passResultData?.percentageText || '';
  const headerText1 = noResultData?.percentageText || '';

  return isNoGradingResult ? (
    <div className={classes.container}>
      {noResultData.isImageEnabled && (
        <picture>
          <img className={classes.image_container} width="100%" src={noResultData.image} alt="" />
        </picture>
      )}
      <div className={noResultData.isImageEnabled ? classes.completing_text : classes.noimagecompleting_text}>
        {noResultData.headerText}
      </div>
      <div className={classes.passedText}>
        {renderTextWithPlaceholders(headerText1, [
          '{ScoredPercentage}',
          `${resultObtained.percentage}%`,
          classes.passedAge,
        ])}
      </div>
      <div className={classes.statsText}>
        You answered {resultObtained.correctAnswers} out of {resultObtained.totalQuestions} questions correctly
      </div>

      <div className={classes.buttons_container}>
        {noResultData.isCutomButtonEnabled ? (
          <Button
            backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
            textColor={isBrandingColorEnabled ? textColor : null}
            style={{ marginLeft: '10px' }}
            variant="secondary"
            autoWidth
            onClick={() => {
              redirectTo(ensureHttps(noResultData.buttonLink));
            }}
          >
            {noResultData.buttonText}
          </Button>
        ) : (
          <Button
            backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
            textColor={isBrandingColorEnabled ? textColor : null}
            style={{ marginLeft: '10px' }}
            variant="secondary"
            autoWidth
            onClick={() => {
              history.replace('/dashboard');
            }}
          >
            Complete
          </Button>
        )}
      </div>
    </div>
  ) : (
    <>
      <div className={classes.container}>
        {passResultData.isImageEnabled && (
          <picture>
            <img className={classes.image_container} width="100%" src={passResultData.image} alt="" />
          </picture>
        )}
        <div className={passResultData.isImageEnabled ? classes.completing_text : classes.noimagecompleting_text}>
          {passResultData.headerText}
        </div>
        <div className={classes.passedText}>
          {renderTextWithPlaceholders(headerText, [
            '{ScoredPercentage}',
            `${resultObtained.percentage}%`,
            classes.passedAge,
          ])}
        </div>
        <div className={classes.statsText}>
          You answered {resultObtained.correctAnswers} out of {resultObtained.totalQuestions} questions correctly
        </div>

        <div className={classes.buttons_container}>
          {passResultData.isCutomButtonEnabled ? (
            <Button
              backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
              textColor={isBrandingColorEnabled ? textColor : null}
              style={{ marginLeft: '10px' }}
              variant="secondary"
              autoWidth
              onClick={() => {
                redirectTo(ensureHttps(passResultData.buttonLink));
              }}
            >
              {passResultData.buttonText}
            </Button>
          ) : (
            !isEmpty(user) && (
              <Button
                backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
                textColor={isBrandingColorEnabled ? textColor : null}
                style={{ marginLeft: '10px' }}
                variant="secondary"
                autoWidth
                onClick={() => {
                  history.replace('/dashboard');
                }}
              >
                Complete
              </Button>
            )
          )}
        </div>
      </div>
    </>
  );
}
