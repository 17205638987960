import React, { useEffect } from 'react';

import { createCustomerPortalLink } from 'services/paidTierOptions.service';
import { redirectInNewTab } from 'services/links';
import { useStyles, PrimaryP, SecondaryP, UpdatePaymentMethodButton } from '../Billing.styles';
import { useDispatch } from 'react-redux';
import Dictionary from '../dictionary.json';
import usePaidTier from 'hooks/usePaidTier';
import Button from 'components/FormUI/Button';
import { useLocation } from 'react-router-dom';
import { setCohereAcademyStatus } from 'actions/update-user';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from '../../../../constants';
import * as paidTier from 'selectors/paidTier';
const BillingDescription = () => {
  const location = useLocation();
  const { membershipOfferscoach, paymentButton, membershipOffers, about } = Dictionary.description;
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  // const { isLaunchPlan } = usePaidTier();
  const dispatch = useDispatch();
  // const { isLaunchPlan, activePaidTierPlan } = usePaidTier();
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const classes = useStyles();

  const redirectToCustomerPortalLink = () => {
    createCustomerPortalLink().then(res => {
      redirectInNewTab(res);
    });
  };

  return (
    <div className={classes.descriptionContainer} style={{ paddingBottom: '10px', paddingTop: '10px' }}>
      <div className={classes.descriptionBlock}>
        {location.pathname == '/account/billing/coach' ? (
          <PrimaryP fontWeight={500} textfont="18px" className={classes.descriptionTitleMedia}>
            {membershipOfferscoach}
          </PrimaryP>
        ) : (
          <>
            <PrimaryP className={classes.descriptionTitleMedia}>{membershipOffers}</PrimaryP>
            <SecondaryP className={classes.descriptionAboutMedia}>{about}</SecondaryP>
          </>
        )}
      </div>

      {!isLaunchPlan && (
        <div className={classes.updatePaymentMethod}>
          <div>
            {/* <UpdatePaymentMethodButton className={classes.buttonMedia} onClick={redirectToCustomerPortalLink}>
              {paymentButton}
            </UpdatePaymentMethodButton> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingDescription;
