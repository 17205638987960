import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import VideoCall from 'pages/VideoCall/VideoCall';

function VideoCallContainer({ match: { path } }) {
  return (
    <Switch>
      <Route path={`${path}/`} exact component={VideoCall} />
    </Switch>
  );
}

VideoCallContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.shape({}),
  }).isRequired,
};

export default VideoCallContainer;
