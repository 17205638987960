import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Formik, Form } from 'formik';
import CoachImage from 'assets/chatlogo.png';
import { useSelector, useDispatch } from 'react-redux';
import { isNil, sortBy } from 'lodash';
import BrowseIconImage from 'assets/browse.png';
import { useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useTheme, useMediaQuery, Box, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import TemplateOneCongrats from 'assets/congratulations.png';
import { isEmpty } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import ImageCropper from './components/Cropper/ImageCropper';
import EasyCropper from './components/Cropper/EasyCropper';
import CohereIcon from 'assets/icon.png';
import TemplateOneIcon from 'assets/templateone1.png';
import TemplateTwoIcon from 'assets/templatetwo2.png';
import TemplateThreeIcon from 'assets/templatethree3.png';
import TemplateOne from 'assets/templateone11.png';
import TemplateTwo from 'assets/templatetwo22.png';
import TemplateThree from 'assets/templatethree33.png';
import { TemplateType } from 'helpers/constants/templateType';
import { ContributionStatus } from 'helpers/constants';
import CrossIcon from '@material-ui/icons/Clear';
import { getLatestApproved, getUpcomingCreated } from 'services/contributions.service';
import { DialogActions, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { ImageCompressor } from 'image-compressor';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import MainContainer from 'components/Containers/MainContainer';
import * as contributionActions from 'actions/contributions';
import * as pageActions from 'actions/page';
import Input from 'components/FormUI/Input';
import Select from 'components/FormUI/Select';
import Button from 'components/FormUI/Button';
import MUIButton from '@mui/material/Button';
import Loader from 'components/UI/Loader';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { useSaveContribution } from 'hooks/useSaveContribution';
import { PageTitleSecond, BodyText } from 'components/UI/Text/TextStyles';
import { useHttp, usePreferences, useRouter, useAccount } from 'hooks';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { toRem, colors } from 'utils/styles';
import { dataURLtoFile } from 'utils/utils';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { ContributionType } from 'helpers/constants';
import { Switch } from '../../components/switch/style';
import Buttons from './components/Buttons';
import { CONTRIBUTION_COLORS, EN_MESSAGES, TOOLTIP } from '../../constants';
import ImageDropzone from '../../components/FormUI/ImageDropone';
import Modal from 'components/UI/Modal';
import ImageViewer from 'react-simple-image-viewer';
import './style.css';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { Diversity1 } from '@mui/icons-material';
const StyledTitle = styled.div`
  font-family: Avenir;
  font-size: 18px;
  // font-size: 1.2rem;
  font-weight: 500;
  line-height: 21.41px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  ${({ mobileView }) =>
    mobileView &&
    css`
      font-size: 16px;
      margin-top: 20px;
    `}
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.51rem;
`;

const StyledExplainText = styled.div`
  font-size: ${toRem(16)};
  line-height: 1.25;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`;

const StyledImgForTour = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const StyledImg = styled.div`
  // width: 100%;
  // height: 100%;
  // border: 1px dotted ${colors.darkOceanBlue};
  // object-fit: contain;
  // object-position: left;

  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  background-color: #f8f8f8;
  width: 100%;
  height: 100%;
  border: 1px solid #00000021;
`;
const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledButtonClicked = styled.button`
  background-color: white;
  padding: 16px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  border: solid 1px #dadada;
  border-radius: 4px;
  line-height: 23.94px;
  letter-spacing: 0.12px;
  width: 100%;
  color: black;
  cursor: pointer;
  outline: none;

  &:disabled {
    background-color: #ededed;
    color: #9b9b9b;
    border: none;
    cursor: initial;
  }

  ${({ active }) =>
    active &&
    css`
      border: solid 1px ${colors.darkOceanBlue};
      background: ${colors.darkOceanBlue};
      color: white;
      outline: none !important;
      font-weight: 900;
    `}
`;

const Image = styled.img`
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  /* max-height: 500px;
  object-fit: contain;
  border: solid 1px #dadada; */
`;

const ImagePlaceholder = styled.div`
  position: relative;
  background-color: #f5f5f5;
  width: 100%;
  min-height: 200px;

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${colors.fadedPurple};
    `}
`;

const FlexImagePlaceholder = styled(ImagePlaceholder)`
  display: flex;
`;

const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 0px 24px 10px 24px;
  background-color: ${({ color }) => color};
`;

const AccountRequiredContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  width: 100%;
  gap: 10px;
`;

const AccountNeededParentContainer = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  width: 100%;

  &.expanded {
    max-height: 200px;
  }
`;

const AccountRequiredLabel = styled.div`
  font-family: Avenir;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.41px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AccountRequiredRadio = styled.div`
  display: flex;
  width: 100%;
  gap: 50px;
`;

const StyledLabel = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  text-align: left;
`;

const useStyles = makeStyles({
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  subTitle: {
    fontFamily: 'Avenir',
    fontSize: '0.875rem',
    fontWeight: '350',
    lineHeight: '22px',
    letterSpacing: '0.25px',
    textAlign: 'left',
  },
  row: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  categoriesRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  radioGroupRoot: {
    flexDirection: 'row',
  },
  // detailsHeader: {
  //   marginRight: '20px',
  //   whiteSpace: 'nowrap',
  // },
  header: ({ mobileView }) =>
    mobileView
      ? {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingBottom: '10px',
        }
      : {},
});

const StyledSelect = styled(Select)`
  width: calc(100% - 33px);
`;
const StyledSwitchWrap = styled(Box)`
  display: flex;
  align-items: center;
`;
const StyledSwitch = styled(Switch)`
  margin: 0 10px 0 0;
`;

const StyledIcons = styled.div`
  display: flex;
  flex-direction: 'row';
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  flex-wrap: wrap;
`;

const StyledCloseIcon = styled(CrossIcon)`
  margin: 5px;
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
`;

const TemplateIcon = styled.img`
  margin: 5px;
  vertical-align: middle;
  width: 150px;
  height: 130px;
  border-radius: 5px;
  object-fit: fill;
  object-position: center;
  border: ${({ active }) => (active ? `1px solid ${colors.darkOceanBlue}` : '1px solid #dadada')};
  cursor: pointer;
`;
const BrowseIcon = styled.img`
  margin: 5px;
  vertical-align: middle;
  width: 10px;
  height: 15px;
  object-fit: fill;
  object-position: center;
`;

const initialValues = {
  category: [],
};
const BasicInformation = ({ onDuplicate }) => {
  const dispatch = useDispatch();
  const { user } = useAccount();
  const { state } = useLocation();
  const showCompletedGetStart = useSelector(state => state.contributions.showCompletedGetStart);
  const refreshStep = showCompletedGetStart === 'refreshContribution';
  const [isStyleModalOpen, setIsStyleModalOpen] = useState(false);
  const imgRef = useRef();
  const serviceAgreementType = user?.serviceAgreementType;
  const isBetaUser = user?.isBetaUser;
  const {
    user: { transfersEnabled, isStandardAccount, defaultPaymentMethod, standardAccountTransfersEnabled, avatarUrl },
  } = useAccount();
  const showOnlyFreeOption = serviceAgreementType && isBetaUser ? true : false;
  const showFreeOption =
    (user?.defaultPaymentMethod === 'Simple' && transfersEnabled === false) ||
    (user?.defaultPaymentMethod === 'Advance' && standardAccountTransfersEnabled === false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('0');
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(3);
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [categoryArray, setcategoryArray] = useState([]);
  const [customCategory, setCustomCategory] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const errorOccured = useSelector(state => state?.contributions.showError);
  useEffect(() => {
    if (
      mobileView &&
      (!user?.userProgressbarData?.FirstContributionDone ||
        (user?.userProgressbarData?.FirstContributionDone === true && refreshStep))
    ) {
      setStepIndex(4);
      setRun(true);
    } else if (
      mobileView === false &&
      (!user?.userProgressbarData?.FirstContributionDone ||
        (user?.userProgressbarData?.FirstContributionDone === true && refreshStep))
    ) {
      setStepIndex(3);
      setRun(true);
    }
  }, [mobileView]);
  const [errorState, setErrorState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCategoriesModal, setShowCategoriesModal] = useState(false);
  const togglePopup = () => {
    setShowModal(!showModal);
  };
  const toggleCategoriesPopup = () => {
    setErrorState(false);
    setShowCategoriesModal(!showCategoriesModal);
    setCategoriesOpen(false);
    setcategoryArray([]);
    setCustomCategory('');
    refetch();
  };
  let joyRideHelpers;
  useEffect(() => {
    if (isEditing === true) {
      setRun(false);
    }
  }, []);
  const [contibId, setContibId] = useState(null);
  useEffect(() => {
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, []);

  const updateUserF = async (goToNext = false) => {
    let obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        FirstContributionDone: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      dispatch(updateUser(u));
      if (goToNext) {
        if (res.userProgressbarData?.IsPlanPurchased === false) {
          history.push('/account/payment', { from: 'verify' });
        } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
          history.push('/account/payment', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IntegrationsDone === false) {
          history.push('/account/integrations', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
          history.push('/account/profile');
        } else if (res?.userProgressbarData?.FirstContributionDone === false) {
          dispatch(contributionActions.setPopUp(true));
          dispatch(contributionActions.setShowCompletedGetStart('create'));
          history.push('/dashboard');
        } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
          history.push('/account/profile', { from: 'Manage Your Website Information' });
        } else if (res?.userProgressbarData?.InviteLink === false) {
          contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
        }
      }
    });
    joyRideHelpers.skip();
  };
  const [steps, setSteps] = useState([
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: CONTRIBUTION_COLORS.PrimaryColorCode,
              borderWidth: '5px',
            }}
          >
            <StyledImg style={{ borderRadius: '100px' }} previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Setup Your Landing Page & Opt-In
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            In the following 5 simple steps, you are creating a landing page with built in paid or free opt-in that will
            bring your customers into your services on Cohere!
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {user?.userProgressbarData?.FirstContributionDon === false && (
              <Button
                className="btn-left"
                autoWidth
                backgroundColor={'white'}
                invert
                style={{
                  marginTop: '40px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  width: '100%',
                  borderColor: '#A58B62',
                  borderWidth: '1px',
                  borderRadius: '5px',
                  color: '#A58B62',
                }}
                onClick={() => {
                  updateUserF();
                  // setRun(false);
                }}
              >
                Mark as Complete
              </Button>
            )}
            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.next();
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'right',
      target: '#create-contribution-basic-info-link-test',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImgForTour previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Choose a Service Type
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Each contribution has unique payment and scheduling features on Steps 3 & 4! You can edit this any time
            before submitting your contribution on Step 5.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            {refreshStep === false && (
              <div>
                <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '100%',
                    borderColor: '#A58B62',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#A58B62',
                  }}
                  onClick={() => {
                    updateUserF();
                    // setRun(false);
                  }}
                >
                  Mark as Complete
                </Button>
                {/* <Button
                  className="btn-left"
                  autoWidth
                  backgroundColor={'white'}
                  invert
                  style={{
                    marginTop: '10px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '100%',
                    borderColor: '#215C73',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    color: '#215C73',
                  }}
                  onClick={() => {
                    updateUserF(true);
                    // setRun(false);
                  }}
                >
                  Complete & Next
                </Button> */}
              </div>
            )}

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#createContribution',
    },
  ]);
  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.log(data);
    console.groupEnd();
  };

  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const saveContribution = useCallback((...args) => dispatch(contributionActions.saveContribution(...args)), [
    dispatch,
  ]);
  const saveContributionToLS = useCallback((...args) => dispatch(contributionActions.saveDraftContribution(...args)), [
    dispatch,
  ]);
  const changeHeader = useCallback((...args) => dispatch(pageActions.changeHeader(...args)), [dispatch]);
  const contributionFormRef = useRef();
  const [redirectTo, setRedirectTo] = useState(undefined);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageToCrop, setImageToCrop] = useState('');
  const [errorsState, setErrors] = useState([]);

  const { contribution, loading } = useSelector(({ contributions }) => ({
    contribution: contributions.activeContribution,
    loading: contributions.loading,
  }));

  const { request, loading: hookLoading } = useHttp();
  const { preferences, refetch } = usePreferences();
  const { history, query } = useRouter();
  const classes = useStyles();
  const { type: prevContributionType } = useContribution();
  const fileInput = React.createRef();
  // Image size in bytes
  const imagelimitinbytes = 10485760;
  //
  const isEditing = !!query.id;
  const saveHandler = isEditing ? saveContribution : saveContributionToLS;
  const { onSave: onSaveContribution } = useSaveContribution(isEditing);
  const defaultTemplate = isEditing ? TemplateType.TemplateThree : TemplateType.TemplateOne;

  useEffect(() => {
    setIsExpanded(false);
    if (contribution) {
      setIsExpanded(contribution.type === ContributionType.contributionOneToOne);
    }
  }, [contribution?.isAccountNeeded]);

  const contributionClickHandler = useCallback(
    (type, setFieldValue) => () => {
      setFieldValue('type', type);
      setFieldValue('activeTemplate', TemplateType.TemplateOne);
      setIsExpanded(type === ContributionType.contributionOneToOne);
    },
    [],
  );

  const uploadFileHandler = (setFieldValue, setImageLoading, droppedFiles = []) => e => {
    // setImageLoading(true);
    const imageCompressor = new ImageCompressor();
    const compressorSettings = {
      toWidth: 640,
      toHeight: 350,
      mimeType: 'image/png',
      mode: 'strict',
      quality: 0.6,
    };
    const formData = new FormData();

    let files;
    if (droppedFiles.length > 0) {
      files = droppedFiles;
    } else {
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
    }
    if (!files || !files[0]) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageToCrop(reader.result?.toString() || '');
      setIsOpen(true);
      // const imageSrc = reader.result;
      // if (files[0].size <= imagelimitinbytes) {
      //   formData.append('file', dataURLtoFile(imageSrc));
      //   request('/content/addpublicimage', 'POST', formData, {
      //     'Content-Type': 'multipart/form-data',
      //   })
      //     .then(image => {
      //       setFieldValue('file', image);
      //     })
      //     .catch(console.dir)
      //     .finally(() => setImageLoading(false));
      // } else {
      //   imageCompressor.run(imageSrc, compressorSettings, compressedSrc => {
      //     formData.append('file', dataURLtoFile(compressedSrc));
      //     request('/content/addpublicimage', 'POST', formData, {
      //       'Content-Type': 'multipart/form-data',
      //     })
      //       .then(image => {
      //         setFieldValue('file', image);
      //       })
      //       .catch(console.dir)
      //       .finally(() => setImageLoading(false));
      //   });
      // }
    };
    reader.readAsDataURL(files[0]);
  };

  const uploadFileHandlerwithoutevent = (setFieldValue, setImageLoading, droppedFiles = []) => {
    // setImageLoading(true);
    const imageCompressor = new ImageCompressor();
    const compressorSettings = {
      toWidth: 640,
      toHeight: 350,
      mimeType: 'image/png',
      mode: 'strict',
      quality: 0.6,
    };
    const formData = new FormData();

    let files;
    if (droppedFiles.length > 0) {
      files = droppedFiles;
    }

    if (!files || !files[0]) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageToCrop(reader.result?.toString() || '');
      setIsOpen(true);
      const imageSrc = reader.result;
      // imageCompressor.run(imageSrc, compressorSettings, compressedSrc => {
      //   formData.append('file', dataURLtoFile(compressedSrc));
      //   request('/content/addpublicimage', 'POST', formData, {
      //     'Content-Type': 'multipart/form-data',
      //   })
      //     .then(image => {
      //       setFieldValue('file', image);
      //     })
      //     .catch(console.dir)
      //     .finally(() => setImageLoading(false));
      // });
    };
    reader.readAsDataURL(files[0]);
  };

  const submitHandler = useCallback(
    values => {
      setErrors([]);
      // contributionFormRef.current.handleSubmit();
      const data = {
        ...values,
        coachSelectedBrandingColors: {
          PrimaryColorCode: user.brandingColors?.PrimaryColorCode || '#CDBA8F',
          AccentColorCode: user.brandingColors?.AccentColorCode || '#116582',
          TertiaryColorCode: user.brandingColors?.TertiaryColorCode || '#F6E8BO',
          TextColorCode: user.brandingColors?.TextColorCode || '#FFFFFF',
        },
        brandingColors: {
          PrimaryColorCode: contribution?.brandingColors?.PrimaryColorCode || '#CDBA8F',
          AccentColorCode: contribution?.brandingColors?.AccentColorCode || '#116582',
          TertiaryColorCode: contribution?.brandingColors?.TertiaryColorCode || '#F6E8BO',
          TextColorCode: contribution?.brandingColors?.TextColorCode || '#FFFFFF',
        },
        isThankyouPageAdded: values?.isThankyouPageAdded,
        thankyouPage: values?.thankyouPage,
        isLive: values.isLive === 'live' && values.type === ContributionType.contributionCourse,
        categories: values.categories,
        previewContentUrls: [values.file],
        userId: user.id,
      };

      if ([ContributionType.contributionOneToOne, ContributionType.contributionLocalEvent].includes(values.type)) {
        data.isLive = true;
      }

      delete data.file;

      changeHeader(data.title);

      const newContribution = {
        ...contribution,
        ...data,
        isThankyouPageAdded: contribution?.isThankyouPageAdded || data?.isThankyouPageAdded,
        thankyouPage: contribution?.thankyouPage || data?.thankyouPage,
      };

      if (!isEditing && newContribution.sessions && prevContributionType !== newContribution.type) {
        delete newContribution.sessions;
      }
      onSaveContribution(newContribution);
      if (isEditing) {
        return history.push(redirectTo);
      }

      saveHandler({
        ...newContribution,
        showOnlyFreeOption,
        showFreeOption,
        isThankyouPageAdded: contribution?.isThankyouPageAdded || data?.isThankyouPageAdded,
        thankyouPage: contribution?.thankyouPage || data?.thankyouPage,
      })
        .then(res => {
          history.push(redirectTo);
        })
        .catch(err => {
          setErrorPopUp(true);
          setErrors(err?.response?.data?.errors);
        });
    },
    [
      contribution,
      changeHeader,
      saveHandler,
      onSaveContribution,
      redirectTo,
      history,
      isEditing,
      prevContributionType,
      setErrors,
    ],
  );

  const giveImg = template => {
    if (template === TemplateType.TemplateOne) {
      return TemplateOne;
    } else if (template === TemplateType.TemplateTwo) {
      return TemplateTwo;
    } else {
      return TemplateThree;
    }
  };

  const columnView = useMediaQuery(theme.breakpoints.down(1373));
  const [previewTemplate, setPreviewTemplate] = useState(contribution?.activeTemplate || defaultTemplate);
  useEffect(() => {
    if (!!contribution?.activeTemplate) {
      setPreviewTemplate(contribution?.activeTemplate);
    }
  }, [contribution?.activeTemplate]);
  let array = [];
  const handleCategoryChange = newSelected => {
    if (contributionFormRef.current.values.categories.length > 0) {
      array = contributionFormRef.current.values.categories;
    }
    if (newSelected.includes('Create Custom Category')) {
      toggleCategoriesPopup();
    } else {
      if (array.includes(newSelected)) {
        // Remove the selected value if it's already present
        const index = array.indexOf(newSelected);
        if (index !== -1) {
          array.splice(index, 1);
        }
      } else {
        // Add the selected value if it's not present
        array.push(newSelected);
      }
      setTimeout(() => {
        contributionFormRef.current.setFieldValue('categories', array);
      }, 300);
    }
  };
  let arraytoDeleteFrom = [];
  const deleteCategory = itemValue => {
    arraytoDeleteFrom = contributionFormRef.current.values.categories;

    if (arraytoDeleteFrom.includes(itemValue[0])) {
      const index = arraytoDeleteFrom.indexOf(itemValue[0]);
      if (index !== -1) {
        arraytoDeleteFrom.splice(index, 1);
      }
    }
    request('/ReferenceData/RemoveCustomCategories', 'PUT', itemValue).then(res => {
      contributionFormRef.current.setFieldValue('categories', arraytoDeleteFrom);
      refetch();
    });
  };
  const handleKeyPress = event => {
    if (event.key === 'Escape') {
      // Handle the "Esc" key press event here
      setCategoriesOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  const browseBtnStyle = {
    backgroundColor: '#ffffff',
    padding: '8px 20px',
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'none',
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: '900',
    lineHeight: '26.25px',
    letterSpacing: '0.43px',
    color: '#215C73',
    minWidth: 'auto',
    borderRadius: '4px',
    border: '1px solid #215C73',
  };
  const imagBtnStyle = {
    backgroundColor: '#ffffff',
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'none',
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: '900',
    lineHeight: '16px',
    letterSpacing: '1.25px',
    color: '#215C73',
    minWidth: 'auto',
    borderRadius: '4px',
    border: '1px solid #215C73',
  };
  return (
    <Formik
      innerRef={contributionFormRef}
      enableReinitialize
      initialValues={{
        title: contribution?.title || '',
        contributionTagline: contribution?.contributionTagline || '',
        categories: contribution?.categories || [],
        isAccountNeeded: contribution?.isAccountNeeded ?? true,
        isLive: isNil(contribution?.isLive) ? 'live' : contribution.isLive === false ? 'self-paced' : 'live',
        type: contribution?.type || ContributionType.contributionCourse,
        file: (contribution?.previewContentUrls && contribution?.previewContentUrls[0]) || null,
        activeTemplate: contribution?.activeTemplate || defaultTemplate,
        isWorkshop: contribution?.isWorkshop || false,
        isThankyouPageAdded: contribution?.isThankyouPageAdded || false,
        thankyouPage: contribution?.thankyouPage || null,
        whoIAmLabel:
          contribution?.whoIAmLabel == 'Bio' ? 'Who this is for' : contribution?.whoIAmLabel || 'Who this is for',
        whatWeDoLabel: contribution?.whatWeDoLabel || 'What to expect',
        purposeLabel:
          contribution?.purposeLabel == 'The Purpose'
            ? 'Why this was created'
            : contribution?.purposeLabel || 'Why this was created',
        preparationLabel:
          contribution?.preparationLabel == 'How to Prepare'
            ? 'Additional details'
            : contribution?.preparationLabel || 'Additional details',
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(50, 'Title can be a max of 50 characters').required('This is a required field'),
        contributionTagline: Yup.string().max(200, 'Tagline can be a max of 200 characters').optional(),
        categories: Yup.array().of(Yup.string()).min(1, 'Please add a category').required(),
        type: Yup.mixed()
          .oneOf([
            ContributionType.contributionCourse,
            ContributionType.contributionWebinar,
            ContributionType.contributionOneToOne,
            ContributionType.contributionLocalEvent,
            ContributionType.contributionMembership,
            ContributionType.contributionCommunity,
            ContributionType.contributionWorkshop,
          ])
          .required('This is a required field'),
        isLive: Yup.mixed().oneOf(['live', 'self-paced']),
      })}
      onSubmit={submitHandler}
    >
      {({ values, setFieldValue, handleSubmit, errors }) => {
        return (
          <MainContainer
            sidebarProps={{
              saveHandler: (event, { to }) => {
                setRedirectTo(to);
                if (isEditing) {
                  if (values.isThankyouPageAdded === false) {
                    submitHandler(values);
                  }
                } else {
                  submitHandler(values);
                }
              },
              errors,
            }}
          >
            <Joyride
              // tooltipComponent={Tooltip}
              callback={handleJoyrideCallback}
              continuous={true}
              getHelpers={getHelpers}
              run={run}
              stepIndex={stepIndex}
              showProgress={false}
              showSkipButton={false}
              steps={steps}
              styles={{
                options: {
                  primaryColor: '#CDBA8F',
                  zIndex: 10000000,
                },
              }}
            />
            <StyledMainSection mobileView={mobileView}>
              {errorOccured && (
                <div
                  style={{
                    width: '45%',
                    backgroundColor: 'white',
                    position: 'absolute',
                    marginInline: '9%',
                    border: '1px solid #DBE7F2',
                    borderRadius: '4px',
                    zIndex: 1,
                  }}
                >
                  <div style={{ padding: '15px 10px 10px 15px' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '700',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#1E1E1E',
                            marginInline: '10px',
                          }}
                        >
                          Empty Fields
                        </div>
                      </div>
                      <StyledCloseIcon
                        onClick={() => {
                          dispatch(contributionActions.setErrorPopUp(false));
                        }}
                        fontSize="10px"
                        style={{ color: 'black' }}
                      />
                    </div>
                    <div
                      style={{
                        fontFamily: 'Avenir',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#1E1E1E',
                        marginInline: '35px',
                      }}
                    >
                      Please fill out all fields in order to continue
                    </div>

                    {/* <div style={{
                    fontFamily: 'Avenir',
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#1E1E1E',
                    marginTop: '10px',
                    marginInline: "35px"
                  }}>Please fill out missing field in order to continue</div> */}
                  </div>
                </div>
              )}

              <div>
                <Card id={'createContribution'} mobileView={mobileView}>
                  <CardHeader mobileView={mobileView}>
                    <PageTitleSecond mobileView={mobileView}>1. Basic Information</PageTitleSecond>
                  </CardHeader>
                  <CardBody mobileView={mobileView}>
                    <Form id="create-contribution-form">
                      <Grid container alignItems="flex-start" spacing={6}>
                        <Grid item container md={12} xs={12} style={{ paddingBottom: '0px' }}>
                          <Grid item container md={6} xs={12} style={{ paddingRight: mobileView ? '' : '24px' }}>
                            <Grid item xs={12} className={classes.row} style={{ marginBottom: '0px' }}>
                              <StyledTitle mobileView={mobileView}>Title</StyledTitle>
                            </Grid>
                            <Grid style={{ marginTop: '15px' }} item xs={12}>
                              <Input
                                id="contribution-title-input"
                                label="Contribution Title"
                                name="title"
                                counter={50}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Input
                                id="contribution-tagline-input"
                                label="Tagline (Optional)"
                                name="contributionTagline"
                                counter={200}
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes.categoriesRow}
                              onClick={() => {
                                if (categoriesOpen) {
                                  setCategoriesOpen(!categoriesOpen);
                                }
                              }}
                              container
                              wrap="nowrap"
                            >
                              {categoriesOpen ? (
                                <StyledSelect
                                  label="Category (Multiple Choice)"
                                  name="categories"
                                  multiple
                                  disabled={loading || hookLoading}
                                  openState={categoriesOpen}
                                  removeItem
                                  items={preferences.map(({ name, isCustom, deletionNotAllowed }) => ({
                                    title: name,
                                    value: name,
                                    isCustom: isCustom,
                                    deletionNotAllowed: deletionNotAllowed,
                                  }))}
                                  onChange={handleCategoryChange} // Pass the onChange handl
                                  onDelete={deleteCategory}
                                />
                              ) : (
                                <Grid
                                  onClick={() => {
                                    setCategoriesOpen(true);
                                  }}
                                  item
                                  xs={11}
                                  className={classes.categoriesRow}
                                  container
                                  wrap="nowrap"
                                >
                                  <StyledSelect
                                    label="Category (Multiple Choice)"
                                    name="categories"
                                    multiple
                                    disabled={loading || hookLoading}
                                    openState={categoriesOpen}
                                    onChange={handleCategoryChange}
                                    onDelete={deleteCategory}
                                    items={preferences.map(({ name, isCustom, deletionNotAllowed }) => ({
                                      title: name,
                                      value: name,
                                      isCustom: isCustom,
                                      deletionNotAllowed: deletionNotAllowed,
                                    }))}
                                  />
                                </Grid>
                              )}
                              <Tooltip
                                title="We recommend up to 3 for a more cohesive look."
                                arrow
                                className="mt-4 ml-2"
                                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                              >
                                <InfoIcon htmlColor={colors.lightBrown} />
                              </Tooltip>
                            </Grid>
                          </Grid>
                          <Grid item container md={6} xs={12}>
                            <Grid item xs={12} className={classes.categoriesRow}>
                              <StyledTitle mobileView={mobileView}>
                                <span>Contribution Type </span>
                                <Tooltip
                                  title="Cohere makes it easy to launch live online services called Contributions. These include live 1:1 services, live group courses, and memberships."
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  style={{ marginLeft: '8px' }}
                                >
                                  <InfoIcon htmlColor={colors.lightBrown} />
                                </Tooltip>
                              </StyledTitle>
                            </Grid>

                            <Grid item container spacing={2} style={{ marginBottom: '15px' }}>
                              <Grid style={{ marginTop: '30px' }} item sm={6} xs={12}>
                                <StyledButtonClicked
                                  type="button"
                                  disabled={isEditing}
                                  active={
                                    values.type === ContributionType.contributionCourse && values.isWorkshop === false
                                  }
                                  onClick={() => {
                                    setFieldValue('type', ContributionType.contributionCourse);
                                    setFieldValue('isWorkshop', false);
                                    setFieldValue('activeTemplate', TemplateType.TemplateOne);
                                    setIsExpanded(false);
                                  }}
                                  id="live-course-type-radio"
                                >
                                  Group Course
                                </StyledButtonClicked>
                              </Grid>
                              <Grid style={{ marginTop: '30px' }} item sm={6} xs={12}>
                                <StyledButtonClicked
                                  type="button"
                                  disabled={isEditing}
                                  active={values.type === ContributionType.contributionMembership}
                                  onClick={contributionClickHandler(
                                    ContributionType.contributionMembership,
                                    setFieldValue,
                                  )}
                                >
                                  Membership
                                </StyledButtonClicked>
                              </Grid>

                              <Grid style={{ marginTop: '20px' }} item sm={6} xs={12}>
                                <StyledButtonClicked
                                  type="button"
                                  disabled={isEditing}
                                  active={values.type === ContributionType.contributionOneToOne}
                                  onClick={contributionClickHandler(
                                    ContributionType.contributionOneToOne,
                                    setFieldValue,
                                  )}
                                  id="one-to-one-type-radio"
                                >
                                  1:1 Sessions
                                </StyledButtonClicked>
                              </Grid>
                              <Grid style={{ marginTop: '20px' }} item sm={6} xs={12}>
                                <StyledButtonClicked
                                  type="button"
                                  disabled={isEditing}
                                  active={values.type === ContributionType.contributionCommunity}
                                  onClick={contributionClickHandler(
                                    ContributionType.contributionCommunity,
                                    setFieldValue,
                                  )}
                                >
                                  Community
                                </StyledButtonClicked>
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                <StyledButtonClicked
                                  style={{ marginTop: '20px' }}
                                  type="button"
                                  disabled={isEditing}
                                  active={
                                    values.type === ContributionType.contributionCourse && values.isWorkshop === true
                                  }
                                  onClick={() => {
                                    setFieldValue('type', ContributionType.contributionCourse);
                                    setFieldValue('isWorkshop', true);
                                    setFieldValue('activeTemplate', TemplateType.TemplateTwo);
                                    setIsExpanded(false);
                                  }}
                                  id="live-course-type-radio"
                                >
                                  Workshop
                                </StyledButtonClicked>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <StyledLine color="#e7e7e7" />
                        <AccountNeededParentContainer
                          className={isExpanded ? 'expanded' : 'account-required-container'}
                        >
                          <AccountRequiredContainer>
                            <AccountRequiredLabel>What Type of 1:1 Client Experience Do You Want?</AccountRequiredLabel>
                            <RadioGroup
                              name="isAccountNeeded"
                              value={String(values.isAccountNeeded)}
                              onChange={e => {
                                setFieldValue('isAccountNeeded', e.target.value === 'true');
                              }}
                            >
                              <AccountRequiredRadio>
                                <RadioContainer>
                                  <FormControlLabel
                                    label={
                                      <StyledLabel style={{ color: contribution?.status === ContributionStatus.approved ? 'rgba(0, 0, 0, 0.38)' : '' }}>
                                        Complete Client Experience
                                      </StyledLabel>
                                    }
                                    control={
                                      <Radio
                                        style={{ opacity: contribution?.status === ContributionStatus.approved ? '50%' : '100%' }}
                                        disabled={contribution?.status === ContributionStatus.approved}
                                        color="primary"
                                        value="true"
                                        name="isAccountNeeded"
                                      />
                                    }
                                  />
                                  <Tooltip
                                    title="This option provides your clients with full access to their own client dashboard to work with you, where they’ll be able to access recordings, the Cohere mobile app, chat, reminders, and notifications."
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                    style={{ margin: 0 }}
                                  >
                                    <InfoIcon htmlColor={colors.lightBrown} />
                                  </Tooltip>
                                </RadioContainer>
                                <RadioContainer>
                                  <FormControlLabel
                                    label={
                                      <StyledLabel style={{ color: contribution?.status === ContributionStatus.approved ? 'rgba(0, 0, 0, 0.38)' : '' }}>
                                        Simple Experience Without a Client Account
                                      </StyledLabel>
                                    }
                                    control={
                                      <Radio
                                        style={{ opacity: contribution?.status === ContributionStatus.approved ? '50%' : '100%' }}
                                        disabled={contribution?.status === ContributionStatus.approved}
                                        color="primary"
                                        value="false"
                                        name="isAccountNeeded"
                                      />
                                    }
                                  />
                                  <Tooltip
                                    title="With this option, your clients will not have access to their own client dashboard and login to access your content, recordings and more. After completing their booking, they can add the session to their calendar and will receive a confirmation email with a calendar invite. Session packages and subscriptions are not available with this option."
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                    style={{ margin: 0 }}
                                  >
                                    <InfoIcon htmlColor={colors.lightBrown} />
                                  </Tooltip>
                                </RadioContainer>
                              </AccountRequiredRadio>
                            </RadioGroup>
                          </AccountRequiredContainer>
                          <StyledLine style={{ margin: '0px 24px 10px 24px', width: 'auto' }} color="#e7e7e7" />
                        </AccountNeededParentContainer>
                        <Grid item container md={12} xs={12}>
                          <Grid item container md={6} xs={12} style={{ paddingRight: mobileView ? '' : '24px' }}>
                            <Grid container item xs={12} className={classes.row} style={{ marginBottom: '0px' }}>
                              <Grid item xs={12} className={classes.row}>
                                <StyledTitle mobileView={mobileView} style={mobileView ? { fontWeight: 800 } : {}}>
                                  <div>Landing Page Design</div>
                                  <Tooltip
                                    title="You can select a template for this Contribution."
                                    arrow
                                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                    style={{ margin: 0 }}
                                  >
                                    <InfoIcon htmlColor={colors.lightBrown} />
                                  </Tooltip>
                                </StyledTitle>
                              </Grid>
                              <Grid item xs={12} className={classes.row}>
                                <div className={classes.subTitle}>
                                  Choose a landing page template for your Contribution.
                                </div>
                              </Grid>
                              <Grid item xs={12} className={classes.row}>
                                <div className={classes.btnContainer}>
                                  <MUIButton
                                    variant="contained"
                                    disableElevation="true"
                                    style={browseBtnStyle}
                                    onClick={() => {
                                      setIsStyleModalOpen(true);
                                    }}
                                  >
                                    <BrowseIcon
                                      mobileView={mobileView}
                                      active={previewTemplate == TemplateType.TemplateOne}
                                      src={BrowseIconImage}
                                    />
                                    Browse
                                  </MUIButton>
                                </div>
                              </Grid>
                            </Grid>

                            <Grid container item xs={12} className={classes.row} style={{ marginBottom: 0 }}>
                              <Grid item md={12} sm={12} xs={12}>
                                <div style={{ width: '100%', height: '289px' }}>
                                  <StyledImg previewImg={giveImg(values.activeTemplate)}></StyledImg>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item container md={6} xs={12}>
                            <Grid item xs={12} className={classes.row}>
                              <StyledTitle mobileView={mobileView} style={mobileView ? { fontWeight: 800 } : {}}>
                                <div>Landing Page Image</div>
                                <Tooltip
                                  title="We recommend adding either a picture of yourself, creating an image with the title of your Contribution using a free platform such as canva.com or simply adding your logo! We recommend Contribution image dimensions of 820 x 312px. When exporting from websites like Canva, export at least 1.5X resolution."
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                  style={{ margin: 0 }}
                                >
                                  <InfoIcon htmlColor={colors.lightBrown} />
                                </Tooltip>
                              </StyledTitle>
                            </Grid>
                            <Grid item xs={12} className={classes.row}>
                              <div className={classes.subTitle}>
                                Your clients see this when they purchase and/or view your Contribution.
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes.row}
                              style={{
                                justifyContent: mobileView ? 'flex-start' : 'flex-end',
                                ...(mobileView ? {} : { marginBottom: '27px' }),
                              }}
                            >
                              <MUIButton
                                variant="contained"
                                disableElevation="true"
                                style={imagBtnStyle}
                                onClick={() => {
                                  if (fileInput) {
                                    fileInput.current.click();
                                  }
                                }}
                              >
                                Attach an Image
                              </MUIButton>
                              {/* <Button
                                invert
                                attachImage={true}
                                mobileView={mobileView}
                                backgroundColor="white"
                                style={{
                                  border: '1px solid #215C73',
                                  color: '#215C73',
                                }}
                                type="button"
                                onClick={() => {
                                  if (fileInput) {
                                    fileInput.current.click();
                                  }
                                }}
                              >
                                Attach an Image
                              </Button> */}
                              <HiddenFileInput
                                onChange={uploadFileHandler(setFieldValue, setImageLoading)}
                                ref={fileInput}
                                accept="image/png,image/gif,image/jpeg"
                              />
                            </Grid>
                            {!imageLoading && (
                              <Grid
                                item
                                xs={12}
                                style={{
                                  dispaly: 'flex',
                                  // alignItems: 'flex-end',
                                  height: '289px',
                                  border: '1px solid rgb(218, 218, 218)',
                                  borderRadius: '10px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  // marginTop: '1rem',
                                  overflow: 'hidden',
                                }}
                              >
                                {values.file ? (
                                  <Grid container item xs={12} className={classes.row} style={{ marginBottom: 0 }}>
                                    <Grid item md={12} sm={12} xs={12}>
                                      <div
                                        style={{
                                          width: '100%',
                                          // height: 'auto', maxHeight: '289px'
                                          height: '100%',
                                        }}
                                      >
                                        <Image crossorigin="anonymous" ref={imgRef} src={values.file} />
                                      </div>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <ImageDropzone
                                    action={droppedFiles => {
                                      uploadFileHandlerwithoutevent(setFieldValue, setImageLoading, droppedFiles);
                                    }}
                                    isDisabled={imageLoading}
                                    style={{ borderRadius: '10px', height: '289px', margin: 0 }}
                                    placeholder="Drag and Drop Your Image Here"
                                    type="image/jpeg, image/png"
                                    stopPropagation
                                  />
                                )}
                              </Grid>
                            )}
                            {imageLoading && (
                              <Grid
                                item
                                xs={12}
                                style={{
                                  border: '1px solid rgb(218, 218, 218)',
                                  borderRadius: '10px',
                                }}
                              >
                                <FlexImagePlaceholder>
                                  <Loader relative withoutTopOffset flex={1} />
                                </FlexImagePlaceholder>
                              </Grid>
                            )}
                            <EasyCropper
                              title="Crop Your Contribution Image"
                              submitTitle="Set Image"
                              imageToCrop={imageToCrop}
                              isOpen={isOpen}
                              onClose={() => {
                                setIsOpen(false);
                                setImageToCrop('');
                              }}
                              setFieldValue={setFieldValue}
                              field="file"
                              shape="rectangle"
                            />
                            <Modal
                              title="Select a Theme"
                              isOpen={isStyleModalOpen}
                              submitTitle="Select"
                              brandingColor={false}
                              hiddenCancel
                              isCreatingContribution
                              onSubmit={() => {
                                setFieldValue('activeTemplate', previewTemplate);
                                setIsStyleModalOpen(false);
                              }}
                              onCancel={() => {
                                setIsStyleModalOpen(false);
                              }}
                              widthRequired
                            >
                              <div
                                // onClick={() => openImageViewer(giveImg(previewTemplate))}
                                className="container"
                              >
                                <StyledImg previewImg={giveImg(previewTemplate)} className="image"></StyledImg>
                                <div className="overlay">
                                  <button onClick={() => openImageViewer(giveImg(previewTemplate))} className="button">
                                    Click to view
                                  </button>
                                </div>

                                {isViewerOpen && (
                                  <ImageViewer
                                    src={[currentImage]}
                                    disableScroll={false}
                                    closeOnClickOutside={true}
                                    onClose={() => {
                                      setIsViewerOpen(false);
                                    }}
                                    closeComponent={() => {
                                      return <></>;
                                    }}
                                  />
                                )}
                              </div>
                              <StyledIcons>
                                <TemplateIcon
                                  mobileView={mobileView}
                                  active={previewTemplate == TemplateType.TemplateOne}
                                  src={TemplateOneIcon}
                                  onClick={() => {
                                    setPreviewTemplate(TemplateType.TemplateOne);
                                  }}
                                />
                                <TemplateIcon
                                  mobileView={mobileView}
                                  active={previewTemplate == TemplateType.TemplateTwo}
                                  src={TemplateTwoIcon}
                                  onClick={() => {
                                    setPreviewTemplate(TemplateType.TemplateTwo);
                                  }}
                                />
                                <TemplateIcon
                                  mobileView={mobileView}
                                  active={previewTemplate == TemplateType.TemplateThree}
                                  src={TemplateThreeIcon}
                                  onClick={() => {
                                    setPreviewTemplate(TemplateType.TemplateThree);
                                  }}
                                />
                              </StyledIcons>
                            </Modal>
                          </Grid>

                          {/* <ImageCropper
                          title="Crop Your Contribution Image"
                          submitTitle="Set Image"
                          imageToCrop={imageToCrop}
                          isOpen={isOpen}
                          onClose={() => {
                            setIsOpen(false);
                            setImageToCrop('');
                          }}
                          setFieldValue={setFieldValue}
                          field="file"
                          shape="rectangle"
                        /> */}
                        </Grid>
                      </Grid>
                    </Form>

                    {errorsState?.map(({ errorMessage }) => (
                      <CommonErrorMessage key={errorMessage} message={errorMessage} />
                    ))}
                  </CardBody>
                </Card>
              </div>
              <Buttons
                nextButtonTitle="Save and Next"
                formId="create-contribution-form"
                onNextClickHandler={event => {
                  setRedirectTo(query.id ? `/edit-contribution/${query.id}/details` : '/create-contribution/details');
                  if (isEmpty(errors) === false) {
                    dispatch(contributionActions.setErrorPopUp(true));
                  } else {
                    handleSubmit(event);
                  }
                }}
              />
            </StyledMainSection>
            <Modal
              title=""
              isOpen={showModal}
              onCloseText="OK"
              hiddenCancel
              onSubmit={togglePopup}
              onCancel={togglePopup}
              disableFooter
            >
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <StyledImgCongrats previewImg={TemplateOneCongrats}></StyledImgCongrats>
                <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
                <div
                  style={{
                    color: '#A58B62',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 450,
                    fontFamily: 'Avenir',
                  }}
                >
                  You are fully set up & ready to transform lives!
                </div>
                <Button
                  autoWidth
                  backgroundColor={'#C9B382'}
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    marginBottom: '10px',
                  }}
                  onClick={togglePopup}
                >
                  Let’s do this!
                </Button>
              </div>
            </Modal>
            <Modal
              title="Create Custom Category"
              isOpen={showCategoriesModal}
              onCloseText="OK"
              hiddenCancel
              onSubmit={toggleCategoriesPopup}
              onCancel={toggleCategoriesPopup}
              disableFooter
            >
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={Yup.object().shape({
                    category: Yup.string().max(10, 'Cannot exceed 100 characters'),
                  })}
                  onSubmit={() => {}}
                >
                  {({ values, errors, handleBlur, touched, handleChange, handleSubmit }) => (
                    <Form>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          {categoryArray.map(
                            (e, i) =>
                              e.length != 0 && (
                                <div
                                  style={{
                                    backgroundColor: '#116582',
                                    borderRadius: '5px',
                                    display: 'inline-block',
                                    marginLeft: '5px',
                                    paddingTop: '2px',
                                  }}
                                >
                                  <div
                                    style={{
                                      height: '15%',
                                      backgroundColor: '#116582',
                                      color: 'white',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      display: 'inline-block',
                                      marginLeft: '5px',
                                      paddingLeft: '5px',
                                      paddingRight: '10px',
                                      width: e.length > 40 && '60ch',
                                      wordWrap: 'break-word',
                                      borderRadius: '5px',
                                    }}
                                  >
                                    {e}
                                  </div>
                                  <StyledCloseIcon
                                    style={{ color: 'white' }}
                                    onClick={() => {
                                      let localArray = [...categoryArray];
                                      if (i >= 0 && i < localArray.length) {
                                        // Use the splice() method to remove the element at index i from localArray
                                        localArray.splice(i, 1);

                                        // Set the modified localArray back to categoryArray
                                        setcategoryArray(localArray);
                                      } else if (localArray.length === 1) {
                                        setcategoryArray([]);
                                      }
                                    }}
                                  />
                                </div>
                              ),
                          )}
                        </div>
                        <span style={{ width: '100%' }}>
                          <TextField
                            className="input-field"
                            id="outlined-basic"
                            margin="normal"
                            variant="outlined"
                            name="category"
                            fullWidth
                            placeholder="Add Category"
                            value={customCategory}
                            onChange={e => {
                              setCustomCategory(e.target.value);
                              if (e.target.value.length < 25) {
                                setCustomCategory(e.target.value);
                                setErrorState(false);
                              } else {
                                setErrorState(true);
                                setCustomCategory(e.target.value);
                              }
                            }}
                            error={errorState}
                            helperText={
                              errorState && customCategory.length === 0
                                ? 'Please Add Category'
                                : errorState && 'Category Length should be less than 25'
                            }
                          />
                        </span>
                      </Box>
                      <Grid container item xs justifyContent="center" direction="column" alignItems="center">
                        <DialogActions className={classes.actions}>
                          <Button
                            onClick={() => {
                              if (errorState === false) {
                                if (customCategory != '') {
                                  let localcategoryArray = categoryArray;
                                  localcategoryArray.push(customCategory);
                                  setcategoryArray(localcategoryArray);
                                  setCustomCategory('');
                                }
                              }
                            }}
                            style={{ width: '135px', fontSize: '13px' }}
                          >
                            Add Another
                          </Button>
                          <Button
                            backgroundColor={'#116582'}
                            style={{ width: '135px', fontSize: '13px' }}
                            onClick={() => {
                              if (errorState === false) {
                                if (categoryArray.length === 0 && customCategory.length === 0) {
                                  toggleCategoriesPopup();
                                } else {
                                  setcategoryArray([customCategory]);
                                  if (categoryArray.length > 0 && customCategory.length > 0) {
                                    let customArray = categoryArray;
                                    let customState = customArray.concat([customCategory]);
                                    request('/ReferenceData/AddCustomCategories', 'POST', customState).then(res => {
                                      toggleCategoriesPopup();
                                    });
                                    if (contributionFormRef?.current) {
                                      const selectedCategories = contributionFormRef.current.values.categories;
                                      const newCategories = selectedCategories.concat(customState);
                                      contributionFormRef.current.setFieldValue('categories', newCategories);
                                    }
                                  } else if (categoryArray.length === 0 && customCategory.length > 0) {
                                    request('/ReferenceData/AddCustomCategories', 'POST', [customCategory]).then(
                                      res => {
                                        toggleCategoriesPopup();
                                      },
                                    );
                                    if (contributionFormRef?.current) {
                                      const selectedCategories = contributionFormRef.current.values.categories;
                                      const newCategories = selectedCategories.concat([customCategory]);
                                      contributionFormRef.current.setFieldValue('categories', newCategories);
                                    }
                                  } else {
                                    request('/ReferenceData/AddCustomCategories', 'POST', categoryArray).then(res => {
                                      toggleCategoriesPopup();
                                    });
                                    if (contributionFormRef?.current) {
                                      const selectedCategories = contributionFormRef.current.values.categories;
                                      const newCategories = selectedCategories.concat(categoryArray);
                                      contributionFormRef.current.setFieldValue('categories', newCategories);
                                    }
                                  }
                                }
                              }
                            }}
                          >
                            Done
                          </Button>
                        </DialogActions>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </div>
            </Modal>
          </MainContainer>
        );
      }}
    </Formik>
  );
};

BasicInformation.propTypes = {};

BasicInformation.defaultProps = {};

export default BasicInformation;
