import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Video } from 'components/Video/Video';
import VideoButton from 'components/FormUI/Button';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import useVideoPlayer from 'hooks/useVideoPlayer';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { handleSelfpacedMediaDownload } from '../../helpers';
import ButtonWraper from 'components/FormUI/Button';
import { getThemedColors } from 'services/contributions.service';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useAccount from 'hooks/useAccount';
import axiosInstance from 'utils/axiosInstance';
import { UserRoles } from 'helpers/constants';
import * as actions from 'actions/contributions';
import { useDispatch } from 'react-redux';
const StyledPdfContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 0.25rem;
  /* border: 1px solid ${({ borderColor }) => borderColor || 'var(--Cohere-Greys-Grey-Tint, #DFE3E4)'};
  padding: 0.5rem 1.25rem; */
  gap: 1rem;
  min-height: 90px;
  border: none;
  padding: 0;

  .MuiGrid-container {
    width: auto;
  }
`;

const StyledPdfContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledPdfButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

const StyledBody = styled.div`
  padding: 20px 0px;
`;
const StyledPdfViewerWrapper = styled.div`
  padding-bottom: 1.125rem;
  ${({ mobileView }) =>
    mobileView &&
    css`
      /* Note: To remove padding from main parent div of column body */
      // margin-right: -1.5rem;
      // margin-left: -1.5rem;
    `}
`;

const SelfPacedText = ({ session, sessionTime, sessionTimeIndex, colorToUse, textColor }) => {
  const contribution = useContribution();
  const { user } = useAccount();
  const dispatch = useDispatch();
  const { isDarkModeEnabled } = contribution;
  const { themedCardOutlineColor, themedColor, newThemedTextColor, newThemedBackgroundColor } = getThemedColors(
    contribution,
  );
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { isCoach } = useAccount();
  useEffect(() => {
    if (!isCoach) {
      setVideoAsWatched();
    }
  }, []);

  const setVideoAsWatched = () => {
    return axiosInstance
      .post(`/Contribution/UserViewedRecording`, {
        contributionId: contribution.id,
        sessionTimeId: sessionTime.id,
        userId: user.id,
      })
      .then(() => {
        if (!isCoach) {
          dispatch(actions.fetchClientContribution(contribution.id));
        }
      });
  };
  return (
    <>
      <StyledPdfViewerWrapper mobileView={mobileView}>
        <div dangerouslySetInnerHTML={{ __html: sessionTime?.selfPacedContentAsHtml }}></div>
      </StyledPdfViewerWrapper>
    </>
  );
};

export default SelfPacedText;
