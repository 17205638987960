import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { Card as CardUI, CardHeader as CardHeaderUI } from 'components/UI/Card';
// import * as purchaseService from 'services/purchase.service';
import { SuccessfulPurchaseModal } from 'components/Modals/SuccessfulPurchaseModal';
import * as contributionActions from 'actions/contributions';
import Button from 'components/FormUI/Button';
import { fetchClientContribution } from 'actions/contributions';
import { useAccount, useHttp, useRouter, useClientPreview } from 'hooks';
import { UserRoles, PaymentStatus } from 'helpers/constants';
import PurchaseModal from '../PurchaseModal';
import ProceedModal from '../ProceedModal';
import RefuseModal from '../RefuseModal';
import useContribution from '../../hooks/useContribution';
import CustomPurchaseModal from '../PurchaseModal/CustomPurchaseModal';
import { determineColorToUse, getOneToOneSelfBookClient } from 'services/contributions.service';
import { PAYMENT_OPTIONS } from '../../../../constants';
import { TemplateType } from 'helpers/constants/templateType';
import Modal from 'components/UI/Modal';
import { LabelText } from 'components/UI/Text/TextStyles';
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useStyles } from './hooks/useStyles';
import { lighten } from 'polished';
import { getIpGlobal, lightOrDark } from 'utils/utils';
import { PURCHASE_MODAL_STEPS } from '../PurchaseModal/PurchaseModal.constants';
import isNil from 'lodash/isNil';
import { getThemedColors } from 'services/contributions.service';
import { redirectTo } from 'services/links';
import { If } from 'utils/fp';

const CardHeaderTab = styled.div`
  color: ${({ color }) => color};
  background-color: ${({ clicked, colorToUse }) => (clicked ? colorToUse?.PrimaryColorCode : '')};
  border-radius: 10px 10px 0px 0px;
  font-family: 'Brandon Text';
  font-style: normal;
  font-size: 17px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  font-weight: 600;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const HorizontalLine = styled.hr`
  // margin-top: 1rem;
  // border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const CustomPurchaseBlockOneToOne = ({ easyBooking }) => {
  const contribution = useSelector(s => s.contributions.activeContribution);
  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const {
    clientPackages,
    customToS,
    isPurchased,
    paymentInfo: { paymentOptions },
    enrollment,
    serviceProviderName,
    type,
    defaultCurrency,
    defaultSymbol,
    participantsIds,
    availabilityTimes,
    title,
    id,
    customTitleForMeetYourCoach,
    isCouponAvailable,
  } = contribution;

  const { history, query } = useRouter();
  const queryCode = query?.code === undefined ? null : query?.code?.includes('p_') ? null : query?.code;
  const { clientPreviewMode } = useClientPreview();
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const { user, currentRole } = useAccount();
  const [isOpenPasswordPopUp, setIsOpenPasswordPopUp] = useState(user?.isPasswordNotCreated);
  const { purchaseStatus } = useContribution();
  const isParticipant = participantsIds?.some(id => id === user.id);
  const { request } = useHttp();
  const dispatch = useDispatch();
  const onlyFreeSingleSession = paymentOptions?.length === 1 && paymentOptions?.includes(PAYMENT_OPTIONS.FREE);
  const onlyFreeSessionPackage =
    paymentOptions?.length === 1 && paymentOptions?.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE);
  const onlyFreeSingleAndFreeSessionPackage =
    paymentOptions?.length === 2 &&
    paymentOptions?.includes(PAYMENT_OPTIONS.FREE) &&
    paymentOptions?.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE);
  const onlyFreeOptionsAllowed = onlyFreeSingleSession || onlyFreeSessionPackage || onlyFreeSingleAndFreeSessionPackage;
  const [isPurchaseModalShowed, setPurchaseModalShowed] = useState(false);
  const [isProceedModalShowed, setProceedModalShowed] = useState(false);
  const [isPackage, setIsPackage] = useState(false);
  const [isMonthlySessionSubscription, setIsMonthlySessionSubscription] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRefuseModalShowed, setRefuseModalShowed] = useState(false);
  const [isOpenSuccessPurchaseModal, setSuccessPurchaseModal] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const downSmTab = useMediaQuery(theme.breakpoints.down(404));
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [isNotLoggedUser, setIsNotLoggedUser] = useState(false);
  const [isSubmitPurchaseModalNow, setIsSubmitPurchaseModalNow] = useState(false);
  const isCoach = currentRole === UserRoles.cohealer;
  const isProceeded = [PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(purchaseStatus);
  const { newThemedCardColor } = getThemedColors(contribution);
  const styledProps = {
    cardBackgroundColor: newThemedCardColor,
  };
  const classNames = useStyles(styledProps);
  const [isFreePackageFailed, setIsFreePackagePuchased] = useState(false);
  const [perSessionStyled, setperSessionStyled] = useState(
    isInviteToJoin && paymentOptions.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE) ? false : true,
  );

  const [sessionPackageStyled, setsessionPackageStyled] = useState(
    isInviteToJoin && paymentOptions.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE) ? true : false,
  );

  const [monthlySessionSubscription, setMonthlySessionSubscription] = useState(false);
  const isCodeToJoin = useSelector(state => state?.contributions?.singleCode);
  const couponCode = useSelector(state => state?.contributions?.couponCode);
  const [showClientInvitedView, setShowClientInvitedView] = useState(false);
  const [invitedClientData, setinvitedClientData] = useState(null);
  useEffect(() => {
    if (paymentOptions.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE)) handlePurchasePackage();
    if (isCodeToJoin) {
      getSelfBookData();
    }
  }, []);

  const getSelfBookData = () => {
    getOneToOneSelfBookClient(isCodeToJoin.slice(2)).then(response => {
      setinvitedClientData(response);
    });
  };
  const initializeStep = () => {
    if (clientPreviewMode) {
      return PURCHASE_MODAL_STEPS.init;
    }
    if (!isNil(isInviteToJoin || queryCode)) {
      return PURCHASE_MODAL_STEPS.join;
    }
    if (!isEmpty(user)) {
      return PURCHASE_MODAL_STEPS.loggedIn;
    }
    return PURCHASE_MODAL_STEPS.init;
  };
  const handleClosePackagePurchased = () => {
    setIsFreePackagePuchased(false);
  };
  const handleOpenPackagePurchased = () => {
    setIsFreePackagePuchased(true);
  };

  const [step, setStep] = useState(initializeStep());

  // const [typeOfPayment, setTypeOfPayment] = useState(
  //   paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION)
  //     ? PAYMENT_OPTIONS.PER_SESSION
  //     : paymentOptions.filter(p => isInviteToJoin?.length > 0 || p !== PAYMENT_OPTIONS.FREE).sort()[0],
  // );
  const shouldHidePriceSection = isInviteToJoin || queryCode;
  const [typeOfPayment, setTypeOfPayment] = useState(
    paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION) ? PAYMENT_OPTIONS.PER_SESSION : paymentOptions.sort()[0],
  );

  const handlePayment = async ({ status }) => {
    if ([PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased].includes(status)) {
      if (isNotLoggedUser) {
        setIsNotLoggedUser(false);
        return setIsSubmitPurchaseModalNow(true);
      }
      setPurchaseModalShowed(true);
    }
    if ([PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(status)) {
      setPurchaseModalShowed(false);
      setProceedModalShowed(true);
    }
    if (!status) {
      const userIP = await getIpGlobal();
      dispatch(fetchClientContribution(id, userIP));
      setPurchaseModalShowed(false);
    }
  };

  const handleSingleSessionPurchase = () => {
    setIsMonthlySessionSubscription(false);
    setIsPackage(false);
  };

  const handlePurchasePackage = async couponid => {
    // setIsMonthlySessionSubscription(false);
    if (isEmpty(user)) {
      setIsNotLoggedUser(true);
      setPurchaseModalShowed(true);
      setIsPackage(true);
      return;
    }

    if (isPackage) {
      setPurchaseModalShowed(true);
    } else {
      if (isProceeded) {
        setProceedModalShowed(true);
        return;
      }
      if (typeof couponid === 'string' || couponid === 'number') {
        couponid = couponid;
      } else {
        couponid = null;
      }
      setIsPackage(true);
      try {
        // const paymentDataResponse = await request('/api/purchase/one-to-one/package', 'POST', {
        //   contributionId: id,
        //   couponId: couponid,
        // });
        const paymentDataResponse = await createPackageIntentOnStripe({
          contributionId: id,
          couponId: couponid,
        });
        // setPaymentData(paymentDataResponse);
        handlePayment(paymentDataResponse);
      } catch (e) {
        setErrorMessage(e.response.data.message);
        setRefuseModalShowed(true);
      }
    }
  };

  const createPackageIntentOnStripe = params => {
    if (typeOfPayment === PAYMENT_OPTIONS.FREESESSIONPACKAGE) {
      return new Promise(async resolve => {
        const paymentDataResponse = await request('/api/purchase/one-to-one/pkg', 'POST', params);
        setPaymentData(paymentDataResponse);
        resolve(paymentDataResponse);
      });
    } else {
      return new Promise(async resolve => {
        const paymentDataResponse = await request('/api/purchase/one-to-one/package', 'POST', params);
        setPaymentData(paymentDataResponse);
        resolve(paymentDataResponse);
      });
    }
  };

  const proseedHandlePurchase = useCallback(
    id => {
      handlePurchasePackage(id);
    },
    [handlePurchasePackage],
  );

  const handlePurchaseProceedModalCancel = useCallback(
    (releasePaymentIntent = true) => {
      // setPurchaseModalShowed(false);
      setProceedModalShowed(false);
      if (isPackage) {
        setIsPackage(false);
        if (releasePaymentIntent) {
          request('/api/purchase/cancleOneToOnePackagePayment', 'POST', {
            contributionId: id,
            created: paymentData?.created,
          }).catch(console.dir);
        }
      }
    },
    [id, paymentData, setPurchaseModalShowed, setProceedModalShowed, isPackage, setIsPackage],
  );

  const toggleSuccessPurchaseModal = () => setSuccessPurchaseModal(prevState => !prevState);

  const closeSuccessPurchaseModal = () => {
    setSuccessPurchaseModal(false);
    redirectTo(`/contribution-view/${id}/sessions`);
  };

  const handlePurchaseProceedModalSubmit = async () => {
    const userIP = await getIpGlobal();
    setPurchaseModalShowed(false);
    setProceedModalShowed(false);
    toggleSuccessPurchaseModal();
    if (isPackage) {
      setIsPackage(false);
      redirectTo(`/contribution-view/${id}/sessions`);
    }
    setTimeout(() => {
      dispatch(fetchClientContribution(id, userIP));
    }, 2000);

    setTimeout(() => {
      dispatch(fetchClientContribution(id, userIP));
    }, 4000);
  };

  const handleRefuseModal = useCallback(() => {
    setRefuseModalShowed(false);
  }, [setRefuseModalShowed]);

  const handleMonthlySessionSubscription = useCallback(async () => {
    setIsPackage(false);
    if (isEmpty(user)) {
      setIsNotLoggedUser(true);
      setPurchaseModalShowed(true);
      setIsMonthlySessionSubscription(true);
      return;
    }

    if (isProceeded) {
      setProceedModalShowed(true);
      return;
    }

    setIsMonthlySessionSubscription(true);
    setPurchaseModalShowed(true);
  }, [isProceeded, id, handlePayment, user]);

  const allPurchasedPackagesAreCompleted =
    isEmpty(clientPackages) ||
    clientPackages.filter(item => !item.isMonthlySessionSubscription).every(item => item.isCompleted);

  const allPurchasedMonthlySessionSubscriptionAreCompleted =
    isEmpty(clientPackages) ||
    clientPackages
      .filter(item => item.isMonthlySessionSubscription)
      .every(item => item.ssMonthlySessionSubscriptionCompleted);

  const isPackagePurchaseEnabled = paymentOptions.includes('SessionsPackage');
  const isFreePackagePurchaseEnabled = paymentOptions.includes('FreeSessionsPackage');

  const isMonthlySessionSubscriptionEnabled = paymentOptions.includes('MonthlySessionSubscription');

  const isPackagePurchaseAllowed = isPackagePurchaseEnabled && allPurchasedPackagesAreCompleted;
  const isFreePackagePurchaseAllowed = isFreePackagePurchaseEnabled && allPurchasedPackagesAreCompleted;

  const isMonthlySessionSubscriptionPurchaseAllowed =
    isMonthlySessionSubscriptionEnabled && allPurchasedMonthlySessionSubscriptionAreCompleted;

  const showPackagePurchaseButton = isPackagePurchaseAllowed && allPurchasedMonthlySessionSubscriptionAreCompleted;
  const showFreePackagePurchaseButton =
    isFreePackagePurchaseAllowed && allPurchasedMonthlySessionSubscriptionAreCompleted;

  const showMontlySessionSubscriptionPurchaseButton = !isPurchased && isMonthlySessionSubscriptionEnabled;

  const showPerSessionButton = paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION);
  // && [PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased].includes(purchaseStatus);

  const anyPaymentOptionEnabled =
    showPackagePurchaseButton ||
    showMontlySessionSubscriptionPurchaseButton ||
    (paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION) &&
      [PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased].includes(purchaseStatus));

  useEffect(() => {
    const visibleOptions = [];

    if (showPerSessionButton) {
      visibleOptions.push('showPerSessionButton');
    }

    if (showPackagePurchaseButton) {
      visibleOptions.push('showPackagePurchaseButton');
    }

    if (showMontlySessionSubscriptionPurchaseButton) {
      visibleOptions.push('showMontlySessionSubscriptionPurchaseButton');
    }

    if (visibleOptions[0] === 'showMontlySessionSubscriptionPurchaseButton') {
      setMonthlySessionSubscription(true);
      setIsMonthlySessionSubscription(true);
      setperSessionStyled(false);
      setsessionPackageStyled(false);
      setTypeOfPayment(PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION);
      handleMonthlySessionSubscription();
    }

    if (visibleOptions[0] === 'showPerSessionButton') {
      setMonthlySessionSubscription(false);
      setIsMonthlySessionSubscription(false);
      setperSessionStyled(true);
      setsessionPackageStyled(false);
      setTypeOfPayment(PAYMENT_OPTIONS.PER_SESSION);
      handleSingleSessionPurchase();
    }

    // if (visibleOptions[0] === 'showPackagePurchaseButton') {
    //   setMonthlySessionSubscription(false);
    //   setIsMonthlySessionSubscription(false);
    //   setperSessionStyled(false);
    //   setsessionPackageStyled(true);
    //   setTypeOfPayment(PAYMENT_OPTIONS.SESSIONS_PACKAGE);
    //   handlePurchasePackage();
    // }
  }, [showPackagePurchaseButton, showMontlySessionSubscriptionPurchaseButton, showPerSessionButton]);

  useEffect(() => {
    if (
      paymentOptions.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE) &&
      showFreePackagePurchaseButton &&
      isInviteToJoin
    ) {
      setStep(initializeStep());
      setsessionPackageStyled(true);
      setMonthlySessionSubscription(false);
      setIsMonthlySessionSubscription(false);
      setperSessionStyled(false);
      setTypeOfPayment(PAYMENT_OPTIONS.FREESESSIONPACKAGE);
      handlePurchasePackage();
    }

    if (
      paymentOptions.includes(PAYMENT_OPTIONS.SESSIONS_PACKAGE) &&
      showPackagePurchaseButton &&
      isInviteToJoin === null &&
      !isCodeToJoin
    ) {
      setMonthlySessionSubscription(false);
      setIsMonthlySessionSubscription(false);
      setperSessionStyled(false);
      setsessionPackageStyled(true);
      setTypeOfPayment(PAYMENT_OPTIONS.SESSIONS_PACKAGE);
      handlePurchasePackage();
    }

    if (isInviteToJoin != null && paymentOptions.includes(PAYMENT_OPTIONS.FREESESSIONPACKAGE) === false) {
      setStep(initializeStep());
      setperSessionStyled(true);
      setMonthlySessionSubscription(false);
      setIsMonthlySessionSubscription(false);
      setsessionPackageStyled(false);
      setTypeOfPayment(PAYMENT_OPTIONS.FREE);
      handleSingleSessionPurchase();
      handlePurchaseProceedModalCancel();
    }
  }, [showFreePackagePurchaseButton, showPackagePurchaseButton, isInviteToJoin, isCodeToJoin]);

  useEffect(() => {
    if (paymentOptions.includes(PAYMENT_OPTIONS.SESSIONS_PACKAGE) && sessionPackageStyled) {
      handlePurchasePackage();
      setMonthlySessionSubscription(false);
      setIsMonthlySessionSubscription(false);
      setperSessionStyled(false);
      setsessionPackageStyled(true);
      setTypeOfPayment(PAYMENT_OPTIONS.SESSIONS_PACKAGE);
    }
  }, [typeOfPayment, showFreePackagePurchaseButton, showPackagePurchaseButton, isPackage, step]);

  const isFreeContribution =
    (contribution.paymentInfo.paymentOptions.length === 1 &&
      contribution.paymentInfo.paymentOptions.includes('Free')) ||
    (contribution.paymentInfo.paymentOptions.length === 1 &&
      contribution.paymentInfo.paymentOptions.includes('FreeSessionsPackage')) ||
    (contribution.paymentInfo.paymentOptions.length === 2 &&
      contribution.paymentInfo.paymentOptions.includes('FreeSessionsPackage') &&
      contribution.paymentInfo.paymentOptions.includes('Free'));

  return (
    <Grid className={classNames.wrapper} container>
      <Grid item md={12} xs={12}>
        {((isFreeContribution === true && isInviteToJoin != null) ||
          (isFreeContribution === true && isCodeToJoin != null) ||
          isFreeContribution === false) && (
          <CardUI className={`${classNames.customSectionWrapper} one-one-session-card`}>
            {!(shouldHidePriceSection && shouldHidePriceSection != 'clientpreview') &&
              anyPaymentOptionEnabled &&
              isCodeToJoin === null && (
                <CardHeaderUI
                  className="one-one-session-header"
                  CardBgCol="#e9f0f7"
                  style={{
                    backgroundColor: lighten(0.18, colorToUse?.PrimaryColorCode),
                    borderRadius: '12px 12px 0px 0px',
                    padding: '0px',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {showPerSessionButton && (
                    <CardHeaderTab
                      colorToUse={colorToUse}
                      color={textColor}
                      clicked={perSessionStyled}
                      className={
                        perSessionStyled && activeTemplate == TemplateType.TemplateOne
                          ? 'tabsStyle'
                          : perSessionStyled && activeTemplate == TemplateType.TemplateTwo
                          ? 'tabsStyleTem2'
                          : ''
                      }
                      onClick={() => {
                        setStep(initializeStep());
                        setperSessionStyled(true);
                        setMonthlySessionSubscription(false);
                        setIsMonthlySessionSubscription(false);
                        setsessionPackageStyled(false);
                        setTypeOfPayment(PAYMENT_OPTIONS.PER_SESSION);
                        handleSingleSessionPurchase();
                        handlePurchaseProceedModalCancel();
                      }}
                      size={downSm ? 'medium' : 'large'}
                      variant="text"
                    >
                      {availabilityTimes.length > 0 && !isEmpty(user) && isParticipant
                        ? 'Book another session'
                        : 'Single Session'}
                    </CardHeaderTab>
                  )}

                  {showPackagePurchaseButton && (
                    <CardHeaderTab
                      colorToUse={colorToUse}
                      color={textColor}
                      clicked={sessionPackageStyled}
                      className={
                        sessionPackageStyled && activeTemplate == TemplateType.TemplateOne
                          ? 'tabsStyle'
                          : sessionPackageStyled && activeTemplate == TemplateType.TemplateTwo
                          ? 'tabsStyleTem2'
                          : ''
                      }
                      onClick={() => {
                        setStep(initializeStep());
                        setsessionPackageStyled(true);
                        setMonthlySessionSubscription(false);
                        setIsMonthlySessionSubscription(false);
                        setperSessionStyled(false);
                        setTypeOfPayment(PAYMENT_OPTIONS.SESSIONS_PACKAGE);
                        handlePurchasePackage();
                      }}
                      size={downSm ? 'medium' : 'large'}
                      variant="text"
                    >
                      Package
                    </CardHeaderTab>
                  )}
                  {showMontlySessionSubscriptionPurchaseButton && (
                    <CardHeaderTab
                      colorToUse={colorToUse}
                      color={textColor}
                      clicked={monthlySessionSubscription}
                      className={
                        monthlySessionSubscription && activeTemplate == TemplateType.TemplateOne
                          ? 'tabsStyle'
                          : monthlySessionSubscription && activeTemplate == TemplateType.TemplateTwo
                          ? 'tabsStyleTem2'
                          : ''
                      }
                      onClick={() => {
                        setStep(initializeStep());
                        setMonthlySessionSubscription(true);
                        setIsMonthlySessionSubscription(true);
                        setsessionPackageStyled(false);
                        setperSessionStyled(false);
                        setTypeOfPayment(PAYMENT_OPTIONS.MONTHLY_SESSION_SUBSCRIPTION);
                        handleMonthlySessionSubscription();
                        handlePurchaseProceedModalCancel();
                      }}
                      size={downSm ? 'medium' : 'large'}
                      variant="text"
                    >
                      Subscription
                    </CardHeaderTab>
                  )}
                </CardHeaderUI>
              )}
            {!(shouldHidePriceSection && shouldHidePriceSection != 'clientpreview') &&
              anyPaymentOptionEnabled &&
              isCodeToJoin != null && (
                <CardHeaderUI
                  CardBgCol="#e9f0f7"
                  className="one-one-session-header"
                  style={{
                    backgroundColor: lighten(0.18, colorToUse?.PrimaryColorCode),
                    borderRadius: '12px 12px 0px 0px',
                    padding: '0px',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <CardHeaderTab
                    colorToUse={colorToUse}
                    color={textColor}
                    clicked={perSessionStyled}
                    className={
                      perSessionStyled && activeTemplate == TemplateType.TemplateOne
                        ? 'tabsStyle'
                        : perSessionStyled && activeTemplate == TemplateType.TemplateTwo
                        ? 'tabsStyleTem2'
                        : ''
                    }
                    onClick={() => {}}
                    size={downSm ? 'medium' : 'large'}
                    variant="text"
                  >
                    Single Session
                  </CardHeaderTab>
                </CardHeaderUI>
              )}
            {isCodeToJoin != null && (
              <CardHeaderUI
                CardBgCol="#e9f0f7"
                className="one-one-session-header"
                style={{
                  backgroundColor: lighten(0.18, colorToUse?.PrimaryColorCode),
                  borderRadius: '12px 12px 0px 0px',
                  padding: '0px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <CardHeaderTab
                  colorToUse={colorToUse}
                  color={textColor}
                  clicked={perSessionStyled}
                  className={
                    perSessionStyled && activeTemplate == TemplateType.TemplateOne
                      ? 'tabsStyle'
                      : perSessionStyled && activeTemplate == TemplateType.TemplateTwo
                      ? 'tabsStyleTem2'
                      : ''
                  }
                  onClick={() => {}}
                  size={downSm ? 'medium' : 'large'}
                  variant="text"
                >
                  Single Session
                </CardHeaderTab>
              </CardHeaderUI>
            )}
            {((shouldHidePriceSection && shouldHidePriceSection != 'clientpreview') ||
              (clientPreviewMode && onlyFreeOptionsAllowed)) &&
              isCodeToJoin === null && (
                <>
                  <CardHeaderUI
                    CardBgCol="#e9f0f7"
                    className="one-one-session-header"
                    style={{
                      backgroundColor: lighten(0.18, colorToUse?.PrimaryColorCode),
                      borderRadius: '10px 10px 0px 0px',
                      padding: '0px',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    {(paymentOptions.includes(PAYMENT_OPTIONS.FREE) || isInviteToJoin != null) && (
                      <CardHeaderTab
                        colorToUse={colorToUse}
                        color={textColor}
                        clicked={perSessionStyled}
                        className={
                          perSessionStyled && activeTemplate == TemplateType.TemplateOne
                            ? 'tabsStyle'
                            : perSessionStyled && activeTemplate == TemplateType.TemplateTwo
                            ? 'tabsStyleTem2'
                            : ''
                        }
                        onClick={() => {
                          setStep(initializeStep());
                          setperSessionStyled(true);
                          setMonthlySessionSubscription(false);
                          setIsMonthlySessionSubscription(false);
                          setsessionPackageStyled(false);
                          setTypeOfPayment(PAYMENT_OPTIONS.FREE);
                          handleSingleSessionPurchase();
                          handlePurchaseProceedModalCancel();
                        }}
                        size={downSm ? 'medium' : 'large'}
                        variant="text"
                      >
                        Free Session
                      </CardHeaderTab>
                    )}
                    {paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION) && isInviteToJoin === null && (
                      <CardHeaderTab
                        colorToUse={colorToUse}
                        color={textColor}
                        clicked={perSessionStyled}
                        className={
                          perSessionStyled && activeTemplate == TemplateType.TemplateOne
                            ? 'tabsStyle'
                            : perSessionStyled && activeTemplate == TemplateType.TemplateTwo
                            ? 'tabsStyleTem2'
                            : ''
                        }
                        onClick={() => {
                          setStep(initializeStep());
                          setperSessionStyled(true);
                          setMonthlySessionSubscription(false);
                          setIsMonthlySessionSubscription(false);
                          setsessionPackageStyled(false);
                          setTypeOfPayment(PAYMENT_OPTIONS.PER_SESSION);
                          handleSingleSessionPurchase();
                          handlePurchaseProceedModalCancel();
                        }}
                        size={downSm ? 'medium' : 'large'}
                        variant="text"
                      >
                        Single Session
                      </CardHeaderTab>
                    )}

                    {showFreePackagePurchaseButton && (
                      <CardHeaderTab
                        colorToUse={colorToUse}
                        color={textColor}
                        clicked={sessionPackageStyled}
                        className={
                          sessionPackageStyled && activeTemplate == TemplateType.TemplateOne
                            ? 'tabsStyle'
                            : sessionPackageStyled && activeTemplate == TemplateType.TemplateTwo
                            ? 'tabsStyleTem2'
                            : ''
                        }
                        onClick={() => {
                          setStep(initializeStep());
                          setsessionPackageStyled(true);
                          setMonthlySessionSubscription(false);
                          setIsMonthlySessionSubscription(false);
                          setperSessionStyled(false);
                          setTypeOfPayment(PAYMENT_OPTIONS.FREESESSIONPACKAGE);
                          handlePurchasePackage();
                        }}
                        size={downSm ? 'medium' : 'large'}
                        variant="text"
                      >
                        Free Package
                      </CardHeaderTab>
                    )}
                  </CardHeaderUI>
                  {/* <Typography
                  variant="h5"
                  style={{ fontWeight: 'bold', textAlign: 'center', padding: '1.8rem 0px 1rem 0px' }}
                >
                  Free Access
                </Typography>
                <hr /> */}
                </>
              )}
            <CustomPurchaseModal
              isCodeToJoin={isCodeToJoin}
              isInviteToJoin={shouldHidePriceSection && shouldHidePriceSection != 'clientpreview'}
              onClose={handlePurchaseProceedModalCancel}
              onSubmit={handlePurchaseProceedModalSubmit}
              typeOfPayment={typeOfPayment}
              isPackage={isPackage}
              isMonthlySessionSubscription={isMonthlySessionSubscription}
              perSessionStyled={perSessionStyled}
              sessionPackageStyled={sessionPackageStyled}
              monthlySessionSubscription={monthlySessionSubscription}
              oneToOne
              paymentDataOneToOne={paymentData}
              updatePackageIntent={createPackageIntentOnStripe}
              proseedHandlePurchase={isNotLoggedUser && proseedHandlePurchase}
              submitNow={isSubmitPurchaseModalNow}
              anyPaymentOptionEnabled={anyPaymentOptionEnabled}
              isCouponAvailable={isCouponAvailable}
              step={step}
              invitedClientData={invitedClientData}
              handleOpenPackagePurchased={handleOpenPackagePurchased}
              setStep={val => {
                setStep(val);
              }}
              easyBookingState={easyBooking}
            />
          </CardUI>
        )}
        {isProceedModalShowed && (
          <ProceedModal
            isOpen={isProceedModalShowed}
            onClose={handlePurchaseProceedModalCancel}
            onSubmit={handlePurchaseProceedModalSubmit}
          />
        )}
        {isRefuseModalShowed && (
          <RefuseModal
            isOpen={isRefuseModalShowed}
            onClose={handleRefuseModal}
            onSubmit={handleRefuseModal}
            message={errorMessage}
          />
        )}
        {isOpenSuccessPurchaseModal && (
          <SuccessfulPurchaseModal
            isOpen={isOpenSuccessPurchaseModal}
            handleOpen={toggleSuccessPurchaseModal}
            handleClose={closeSuccessPurchaseModal}
          />
        )}
        <Modal
          isOpen={isFreePackageFailed}
          onCancel={handleClosePackagePurchased}
          onSubmit={handleClosePackagePurchased}
          title="Free Package Purchase Failed"
          hiddenCancel
        >
          <LabelText>Unable to purchase sessions package until you have incomplete package</LabelText>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default CustomPurchaseBlockOneToOne;
