import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CoachImage from 'assets/chatlogo.png';
import Button from 'components/FormUI/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import ReactCrop from 'react-image-crop';
import CrossIcon from '@material-ui/icons/Clear';
import { isEmpty, isNil } from 'lodash';
import 'react-image-crop/dist/ReactCrop.css';
import ImageCropper from './components/Cropper/ImageCropper';
import styled from 'styled-components';
import { mdiAccountOutline, mdiHeart, mdiRun, mdiTabletIpad, mdiLightbulb } from '@mdi/js';
import * as paidTierService from 'services/paidTierOptions.service';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@material-ui/core/Tooltip';
import ExamplePDF from 'assets/cohere_template_example.pdf';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import TextField from 'components/UI/TextField';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import MainContainer from 'components/Containers/MainContainer';
import * as contributionActions from 'actions/contributions';
import { ContributionType, ContributionStatus } from 'helpers/constants';
import Select from 'components/FormUI/Select';
import Input from 'components/FormUI/Input';
import Modal from 'components/UI/Modal';
import TextArea from 'components/FormUI/TextArea';
import { useSaveContribution } from 'hooks/useSaveContribution';
import { useRouter, useHttp } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { PageTitleSecond, BodyText } from 'components/UI/Text/TextStyles';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import Buttons from './components/Buttons';
import SamplePfd from './sample.pdf';
import { colors } from '../../utils/styles/styles';
import { CONTRIBUTION_COLORS, TOOLTIP } from '../../constants';
import { Construction } from '@mui/icons-material';
import ButtonExample from 'pages/Profile/ButtonExample';
import * as userActions from 'actions/user';
import { addCalendarAccount } from 'services/calendars.service';
import { convertToPlainText, dataURLtoFile } from 'utils/utils';
import moment from 'moment';
import TemplateOne from 'assets/congratulations.png';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { MenuItem, OutlinedInput } from '@material-ui/core';
import { editUserColors } from 'services/user.service';
import { getLatestApproved, getUpcomingCreated } from 'services/contributions.service';
import { BsFillHeartFill, BsFillPersonFill, BsFillQuestionCircleFill, BsFillLightbulbFill } from 'react-icons/bs';

const ExampleLink = styled.a`
  margin-left: auto;
  padding-left: 10px;
  white-space: nowrap;

  ${({ mobileView }) => mobileView && `margin-top: 10px; white-space: normal;`}
`;

const Image = styled.img`
  background-color: #f5f5f5;
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  border: solid 1px #dadada;
`;

const StyledColorCodeWrapper = styled.div`
  display: flex;
  width: 70%;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
`;
const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const StyledCloseIcon = styled(CrossIcon)`
  margin: 5px;
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const StyledTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.115;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  ${({ mobileView }) => mobileView && `font-size: 16px`}
`;

const InputHidden = styled.input.attrs({ type: 'file' })`
  display: none;
`;

const StyledColorSection = styled.div`
  margin-top: 15px;
`;

const Container = styled.div`
  cursor: pointer;
`;

const StyledColorPicker = styled.div`
  border: 1px solid rgb(178, 202, 224);
  border-radius: 5px;
  display: flex;
  padding: 3px;
  width: 145px;
  height: 32px;
`;

const StyledColorSelector = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const StyledIconSection = styled.div`
  margin-top: 15px;
`;

const EmptyIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  color: #bdbdbd;
  height: 75px;
  width: 75px;
  border-radius: 10px;
`;

const StyledHeading = styled(Typography)`
  color: '#213649';
`;

const IconContainer = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles({
  checkboxLabel: {
    fontSize: 13.7,
    fontFamily: 'Avenir',
  },

  detailsHeader: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
  },

  colorSelect: {
    // borderRadius: '5px',
    // display: 'flex',
    // padding: '3px',
    width: '145px',
    // height: '32px',
    backgroundColor: 'transparent',
    // marginTop: '-30px',
  },

  customBranding: {
    marginRight: '0px',
  },
  hidechat: {
    marginRight: '20px',
    marginTop: '10px',
  },
  hideMemberCommunity: {
    alignSelf: 'end',
    margin: '8px 0px ',
    width: '160px',
  },

  hideMemberOnGroupChatLiveSession: {
    alignSelf: 'end',
    margin: '8px 0px ',
    width: '200px',
  },

  header: ({ mobileView }) =>
    mobileView
      ? {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingBottom: '10px',
        }
      : {},
});

const SocialGrid = styled(Grid)`
  display: flex;
  justify-content: flex-start;
`;

const DetailsGrid = styled(Grid)`
  position: relative;
`;

const TextAreaWrapper = styled(Grid)`
  ${({ isCollapsed }) =>
    !isCollapsed &&
    `
      position: absolute;
      z-index: 2;
      background: white;
      height: -webkit-fill-available;
      margin-bottom: 15px;
      box-shadow: 0 2px 8px 1px ${colors.shadow};
    `}
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: -8px;
  top: calc(50% - 0px);
  transform: translateY(-50%);
`;

const StyledTooltipModal = styled(Tooltip)`
  position: absolute;
  right: -23px;
  top: calc(50% - 0px);
  transform: translateY(-50%);
`;

const StyledTooltipWrap = styled.span`
  position: relative;
  padding-right: 7px;
  display: inline-block;
`;
const StyledCheckbox = styled(Checkbox)`
  padding-left: 0 !important;
`;

const StyledTooltipWrapModal = styled.span`
  position: relative;
  padding-right: 7px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function ContributionDetails({ user, contribution, saving, saveContribution, saveContributionToLS }) {
  const TEXT_AREA_SIZE = {
    SMALL: 6,
    HUGE: 12,
  };
  const { pathname } = useRouter();

  const theme = useTheme();
  const IconUpload = useRef();
  const [openBrandingModal, setOpenBrandingModal] = useState(false);
  const [openBrandingModalPopUp, setOpenBrandingModalPopUp] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ mobileView });
  const { query, history } = useRouter();
  const isCACStep =
    history.location.pathname.includes('edit-contribution') || history.location.pathname.includes('create-contribution')
      ? false
      : true;
  const contributionFormRef = useRef();
  const iconRef = useRef(null);
  const dispatch = useDispatch();
  const isEditing = Boolean(query.id);
  const { onSave: onSaveContribution } = useSaveContribution(isEditing);
  const [redirectTo, setRedirectTo] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [whoIAmCollapsed, setWhoIAmCollapsed] = useState(true);
  const handleResizeWhoIAm = useCallback(() => setWhoIAmCollapsed(prevState => !prevState), []);
  const [purposeCollapsed, setPurposeCollapsed] = useState(true);
  const handleResizePurpose = useCallback(() => setPurposeCollapsed(prevState => !prevState), []);
  const [whatWeDoCollapsed, setWhatWeDoCollapsed] = useState(true);
  const handleResizeWhatWeDo = useCallback(() => setWhatWeDoCollapsed(prevState => !prevState), []);
  const [preparationCollapsed, setPreparationCollapsed] = useState(true);
  const handlePreparationResize = useCallback(() => setPreparationCollapsed(prevState => !prevState), []);
  const [imageForCrop, setImageForCrop] = useState(null);
  const saveHandler = isEditing ? saveContribution : saveContributionToLS;
  const [clickedIconeType, setClickedIconeType] = useState(null);
  const [imageToCrop, setImageToCrop] = useState('');
  const [run, setRun] = useState(!user?.userProgressbarData?.FirstContributionDone);
  const [isCollectiveEnabled, setIsCollectiveEnabled] = useState(false);
  const [stepIndex, setStepIndex] = useState(4);
  const [showModal, setShowModal] = useState(false);
  const [contibId, setContibId] = useState(null);
  const errorOccured = useSelector(state => state?.contributions.showError);
  const getActivePaidTier = useCallback(() => {
    paidTierService.getCurrentPlan().then(res => {
      setIsCollectiveEnabled(res.isCollectiveEnabled);
    });
  }, []);
  useEffect(() => {
    getLatestApproved().then(res => {
      setContibId(res);
    });
    if (!isEditing) {
      if (contributionFormRef.current) {
        if (
          contribution.brandingColors.PrimaryColorCode != contribution.coachSelectedBrandingColors.PrimaryColorCode ||
          contribution.brandingColors.AccentColorCode != contribution.coachSelectedBrandingColors.AccentColorCode ||
          contribution.brandingColors.TertiaryColorCode != contribution.coachSelectedBrandingColors.TertiaryColorCode ||
          contribution.brandingColors.TextColorCode != contribution.coachSelectedBrandingColors.TextColorCode
        ) {
          contributionFormRef.current.setFieldValue('isCustomBrandingColorsActive', true);
          contributionFormRef.current.setFieldValue(
            'PrimaryColorCode',
            contribution.coachSelectedBrandingColors.PrimaryColorCode,
          );
          contributionFormRef.current.setFieldValue(
            'AccentColorCode',
            contribution.coachSelectedBrandingColors.AccentColorCode,
          );
          contributionFormRef.current.setFieldValue(
            'TertiaryColorCode',
            contribution.coachSelectedBrandingColors.TertiaryColorCode,
          );
          contributionFormRef.current.setFieldValue(
            'TextColorCode',
            contribution.coachSelectedBrandingColors.TextColorCode,
          );
          contributionFormRef.current.setFieldValue('isDarkModeEnabled', user?.profilePageViewModel?.isDarkModeEnabled);
          contributionFormRef.current.setFieldValue('customLogo', user.customLogo);
        }
        if (contribution.type === ContributionType.contributionOneToOne) {
          contributionFormRef.current.setFieldValue('isCommunityFeatureHiddden', true);
        }
      }
    }
  }, []);
  useEffect(() => {
    if (contributionFormRef?.current) {
      if (!isEditing) {
        if (contributionFormRef.current.values.isCustomBrandingColorsActive === true) {
          contributionFormRef.current.setFieldValue('isCustomBrandingColorsActive', true);
          contributionFormRef.current.setFieldValue(
            'PrimaryColorCode',
            contribution.coachSelectedBrandingColors.PrimaryColorCode,
          );
          contributionFormRef.current.setFieldValue(
            'AccentColorCode',
            contribution.coachSelectedBrandingColors.AccentColorCode,
          );
          contributionFormRef.current.setFieldValue(
            'TertiaryColorCode',
            contribution.coachSelectedBrandingColors.TertiaryColorCode,
          );
          contributionFormRef.current.setFieldValue(
            'TextColorCode',
            contribution.coachSelectedBrandingColors.TextColorCode,
          );
          // contributionFormRef.current.setFieldValue('isDarkModeEnabled', user?.profilePageViewModel?.isDarkModeEnabled);
          contributionFormRef.current.setFieldValue('isDarkModeEnabled', contribution?.isDarkModeEnabled);
          contributionFormRef.current.setFieldValue('customLogo', user.customLogo);
        } else {
          contributionFormRef.current.setFieldValue('PrimaryColorCode', '#CDBA8F');
          contributionFormRef.current.setFieldValue('AccentColorCode', '#116582');
          contributionFormRef.current.setFieldValue('TertiaryColorCode', '#F6E8BO');
          contributionFormRef.current.setFieldValue('TextColorCode', '#FFFFFF');
          contributionFormRef.current.setFieldValue('isDarkModeEnabled', contribution?.isDarkModeEnabled);
          contributionFormRef.current.setFieldValue('customLogo', contribution.customLogo);
        }
      } else {
        if (contributionFormRef.current.values.isCustomBrandingColorsActive === true) {
          if (contributionFormRef.current.values.isLegacyColorsActive === false) {
            contributionFormRef.current.setFieldValue('isCustomBrandingColorsActive', true);
            contributionFormRef.current.setFieldValue('PrimaryColorCode', user.brandingColors.PrimaryColorCode);
            contributionFormRef.current.setFieldValue('AccentColorCode', user.brandingColors.AccentColorCode);
            contributionFormRef.current.setFieldValue('TertiaryColorCode', user.brandingColors.TertiaryColorCode);
            contributionFormRef.current.setFieldValue('TextColorCode', user.brandingColors.TextColorCode);
            contributionFormRef.current.setFieldValue('isDarkModeEnabled', contribution?.isDarkModeEnabled);
            contributionFormRef.current.setFieldValue('customLogo', user.customLogo);
          } else {
            contributionFormRef.current.setFieldValue('isCustomBrandingColorsActive', true);
            contributionFormRef.current.setFieldValue('PrimaryColorCode', contribution.brandingColors.PrimaryColorCode);
            contributionFormRef.current.setFieldValue('AccentColorCode', contribution.brandingColors.AccentColorCode);
            contributionFormRef.current.setFieldValue(
              'TertiaryColorCode',
              contribution.brandingColors.TertiaryColorCode,
            );
            contributionFormRef.current.setFieldValue('TextColorCode', contribution.brandingColors.TextColorCode);
            contributionFormRef.current.setFieldValue('isDarkModeEnabled', contribution?.isDarkModeEnabled);
            contributionFormRef.current.setFieldValue('customLogo', user.customLogo);
          }
        } else {
          contributionFormRef.current.setFieldValue('PrimaryColorCode', '#CDBA8F');
          contributionFormRef.current.setFieldValue('AccentColorCode', '#116582');
          contributionFormRef.current.setFieldValue('TertiaryColorCode', '#F6E8BO');
          contributionFormRef.current.setFieldValue('TextColorCode', '#FFFFFF');
          contributionFormRef.current.setFieldValue('isDarkModeEnabled', false);
          contributionFormRef.current.setFieldValue('customLogo', null);
        }
      }
    }
  }, [contributionFormRef?.current?.values?.isCustomBrandingColorsActive]);
  const togglePopup = () => {
    setShowModal(!showModal);
  };
  let joyRideHelpers;
  useEffect(() => {
    if (isEditing === true) {
      setRun(false);
    }
  }, []);

  const getOptions = () => {
    const { paymentInfo: { paymentOptions = [] } = {} } = contribution;
    let options = [
      { title: 'Free', value: 'Free' },
      { title: 'Paid', value: 'Paid' },
      { title: 'Both', value: 'Both' },
    ];
    const isPaid =
      paymentOptions?.includes('EntireCourse') ||
      paymentOptions?.includes('PerSession') ||
      paymentOptions?.includes('SessionsPackage') ||
      paymentOptions?.includes('SplitPayments') ||
      paymentOptions?.includes('MonthlySessionSubscription');
    const isFree = paymentOptions.includes('Free') || paymentOptions.includes('FreeSessionsPackage');
    if (!(isPaid && isFree) && !pathname.includes('/create-contribution')) {
      if (isPaid) options = [{ title: 'Paid', value: 'Paid' }];
      if (isFree) options = [{ title: 'Free', value: 'Free' }];
    }
    return options;
  };

  const [steps, setSteps] = useState([
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Landing Page Details
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            These sections will be displayed on your landing page to give your clients details about your service! Feel
            free to customize the titles and details and/or hide sections. You can edit these sections at any time.
          </div>
          <div
            className="buttons"
            style={{ width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}
          >
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                // marginLeft: '10px',
              }}
              onClick={() => {
                updateUserF(true);
                // joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Complete & Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#contributionDetails',
    },
    // {
    //   hideFooter: true,
    //   disableBeacon: true,
    //   content: (
    //     <div>
    //       <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
    //       <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
    //       <div style={{ color: '#A58B62', fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
    //         You are fully set up & ready to transform lives!
    //       </div>
    //       <Button
    //         autoWidth
    //         backgroundColor={'#C9B382'}
    //         style={{
    //           marginTop: '40px',
    //           paddingTop: '10px',
    //           paddingBottom: '10px',
    //         }}
    //         onClick={() => {
    //           joyRideHelpers.skip();
    //         }}
    //       >
    //         Let’s do this!
    //       </Button>
    //     </div>
    //   ),
    //   placement: 'center',
    //   target: 'body',
    // },
  ]);

  const updateUserF = async (goToNextStep = false) => {
    let obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        FirstContributionDone: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      if (goToNextStep) {
        if (res.userProgressbarData?.IsPlanPurchased === false) {
          history.push('/account/payment', { from: 'verify' });
        } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
          history.push('/account/payment', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IntegrationsDone === false) {
          history.push('/account/integrations', { from: 'bankAccount' });
        } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
          history.push('/account/profile');
        } else if (res?.userProgressbarData?.FirstContributionDone === false) {
          dispatch(contributionActions.setPopUp(true));
          dispatch(contributionActions.setShowCompletedGetStart('create'));
          history.push('/dashboard');
        } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
          history.push('/account/profile', { from: 'Manage Your Website Information' });
        } else if (res?.userProgressbarData?.InviteLink === false) {
          contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
        }
      }
    });
    joyRideHelpers.skip();
  };

  useEffect(() => {
    if (!!user?.userProgressbarData?.FirstContributionDone) {
      setSteps([]);
    }
  }, []);

  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }

    console.groupCollapsed(type);
    console.log(data);
    console.groupEnd();
  };
  const colorOptions = [
    { value: '#000000', title: 'Black' },
    { value: '#FFFFFF', title: 'White' },
    { value: 'Auto', title: 'Auto' },
  ];

  const onUploadFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageToCrop(reader.result?.toString() || ''));
      reader.readAsDataURL(e.target.files[0]);
      setIsOpen(true);
    }
  };

  const saveToStorageHandler = useCallback(
    (values, formikHelpers, to = '') => {
      let data;
      const {
        PrimaryColorCode,
        AccentColorCode,
        TertiaryColorCode,
        TextColorCode,
        customLogo,
        isDarkModeEnabled,
        ...rest
      } = values;
      let coachSelectedBrandingColors = {
        PrimaryColorCode: user.brandingColors.PrimaryColorCode,
        AccentColorCode: user.brandingColors.AccentColorCode,
        TertiaryColorCode: user.brandingColors.TertiaryColorCode,
        TextColorCode: user.brandingColors.TextColorCode,
      };
      let brandingColors = {
        PrimaryColorCode,
        AccentColorCode,
        TertiaryColorCode,
        TextColorCode,
      };
      data = {
        ...rest,
        customLogo: values?.customLogo?.length > 0 ? values?.customLogo : null,
        isDarkModeEnabled: isDarkModeEnabled,
        coachSelectedBrandingColors: coachSelectedBrandingColors,
        brandingColors: brandingColors,
        isLegacyColorsActive: values.isLegacyColorsActive,
        isCustomBrandingColorsActive: values.isCustomBrandingColorsActive,
        languageCodes: values.languageCodes,
        minAge: String(values.minAge),
        allowClientsToInvite: values.allowClientsToInvite,
        inviteClientType: values.inviteClientType,
      };

      const newContribution = {
        customLogo: values?.customLogo?.length > 0 ? values?.customLogo : null,
        ...contribution,
        ...data,
      };
      saveHandler(newContribution);
      onSaveContribution(newContribution);
      history.push(to || redirectTo);
    },
    [contribution, history, saveHandler, onSaveContribution, redirectTo],
  );
  const isInvitationDisabled = !!query.id && contribution?.status !== ContributionStatus.unfinished;
  const { request, loading } = useHttp();

  const saveColor = obj => {
    const { colorName, colorCode, formValues } = obj;
    const { PrimaryColorCode, AccentColorCode, TertiaryColorCode, TextColorCode, ...rest } = formValues || {};
    const brandColors = {
      PrimaryColorCode,
      AccentColorCode,
      TertiaryColorCode,
      TextColorCode,
    };
    const userData = {
      ...rest,
      brandingColors: {
        ...brandColors,
        [colorName]: colorCode,
      },
      id: user.id,
      accountId: user.accountId,
      avatarUrl: user.avatarUrl,
      BirthDate: moment.utc(formValues?.BirthDate).format(),
      serviceAgreementType: user.serviceAgreementType,
      isBetaUser: user.isBetaUser,
    };

    // editProfile(user.id, userData);
  };

  const handleFormKeyDown = e => {
    if (e.target.tagName !== 'TEXTAREA' && (e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  const onClickHandler = () => {
    if (IconUpload) {
      IconUpload.current.click();
    }
  };

  const onModalClose = () => {
    setIsOpen(false);
  };

  const handleIconChange = useCallback((e, setFieldValue) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        const formData = new FormData();
        // setFieldValue('customLogo', reader.result);
        formData.append('file', dataURLtoFile(reader.result));
        request('/content/addpublicimage', 'POST', formData, {
          'Content-Type': 'multipart/form-data',
        })
          .then(image => {
            setFieldValue('customLogo', image);
          })
          .catch(console.dir)
          .finally(() => {
            // onClose();
            // setUploading(false);
          });
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const handleSubmitCropper = () => {
    setIsOpen(false);
  };
  const iconType = {
    whoIAm: 'whoIAm',
    whatWeDo: 'whatWeDo',
    purpose: 'purpose',
    preparation: 'preparation',
  };

  function handleIconClick(type) {
    setClickedIconeType(type);

    if (iconRef) {
      iconRef.current.click();
    }
  }

  useEffect(() => {
    if (!saving) {
      setOpenBrandingModal(false);
    }
  }, [saving]);

  if (!contribution) {
    return null;
  }

  const customMax = (value, limit = 1000) => {
    return (convertToPlainText(value)?.length || 0) <= limit;
  };

  return (
    <Formik
      innerRef={contributionFormRef}
      enableReinitialize
      initialValues={{
        customTitleForMeetYourCoach: contribution?.customTitleForMeetYourCoach || 'Coach',
        // contributionTagline: contribution?.contributionTagline || '',
        whoIAmLabel: contribution?.whoIAmLabel || 'Who this is for',
        whoIAmIcon: contribution?.whoIAmIcon || mdiAccountOutline,
        viewWhoIAm: contribution?.viewWhoIAm,
        whoIAm: contribution?.whoIAm || '',
        whatWeDoLabel: contribution?.whatWeDoLabel || 'What to expect',
        whatWeDoIcon: contribution?.whatWeDoIcon || mdiLightbulb,
        viewWhatWeDo: contribution?.viewWhatWeDo,
        whatWeDo: contribution?.whatWeDo || '',
        purposeLabel: contribution?.purposeLabel || 'Why this was created',
        purposeIcon: contribution?.purposeIcon || mdiHeart,
        viewPurpose: contribution?.viewPurpose,
        purpose: contribution?.purpose || '',
        preparationLabel: contribution?.preparationLabel || 'Additional details',
        preparationIcon: contribution?.preparationIcon || mdiTabletIpad,
        viewPreparation: contribution?.viewPreparation,
        preparation: contribution?.preparation || '',
        languageCodes: contribution?.languageCodes || ['En'],
        minAge: contribution?.minAge?.toString() || '18+',
        gender: contribution?.gender || 'NoRequirement',
        inviteClientType: contribution?.inviteClientType || 'Both',
        allowClientsToInvite: isNil(contribution?.allowClientsToInvite) ? false : contribution?.allowClientsToInvite,
        shareInCollective: isNil(contribution?.shareInCollective) ? false : contribution?.shareInCollective,
        isCalendarHidden: isNil(contribution?.isCalendarHidden) ? true : contribution?.isCalendarHidden,
        isSpeakingLanguageRequired: isNil(contribution?.isSpeakingLanguageRequired)
          ? false
          : contribution?.isSpeakingLanguageRequired,
        invitationOnly: isNil(contribution?.invitationOnly) ? true : contribution?.invitationOnly,
        arePublicPostsAllowed: isNil(contribution?.arePublicPostsAllowed) ? true : contribution?.arePublicPostsAllowed,

        areClientPublicPostsAllowed: isNil(contribution?.areClientPublicPostsAllowed)
          ? true
          : contribution?.areClientPublicPostsAllowed,
        instagramUrl: contribution?.instagramUrl || '',
        linkedInUrl: contribution?.linkedInUrl || '',
        youtubeUrl: contribution?.youtubeUrl || '',
        facebookUrl: contribution?.facebookUrl || '',
        websiteUrl: contribution?.websiteUrl || '',
        tiktokUrl: contribution?.tiktokUrl || '',
        isCustomBrandingColorsActive: contribution.isCustomBrandingColorsActive || false,
        isLegacyColorsActive: contribution.isLegacyColorsActive || false,
        isCommunityFeatureHiddden: contribution.isCommunityFeatureHiddden,
        PrimaryColorCode:
          // contribution?.isLegacyColorsActive === false && contribution?.isCustomBrandingColorsActive === true
          //   ? contribution?.coachSelectedBrandingColors?.PrimaryColorCode
          //   : contribution?.isLegacyColorsActive === true && contribution?.isCustomBrandingColorsActive === false
          //   ?
          contribution.brandingColors?.PrimaryColorCode,
        // : contribution?.isLegacyColorsActive === true && contribution?.isCustomBrandingColorsActive === true
        // ? contribution.coachSelectedBrandingColors?.PrimaryColorCode
        // : CONTRIBUTION_COLORS.PrimaryColorCode,
        AccentColorCode:
          // contribution?.isLegacyColorsActive === false && contribution?.isCustomBrandingColorsActive == true
          //   ? contribution?.coachSelectedBrandingColors?.AccentColorCode
          //   : contribution?.isLegacyColorsActive === true && contribution?.isCustomBrandingColorsActive == false
          //   ?
          contribution.brandingColors?.AccentColorCode,
        // : contribution?.isLegacyColorsActive === true && contribution?.isCustomBrandingColorsActive === true
        // ? contribution.coachSelectedBrandingColors?.AccentColorCode
        // : CONTRIBUTION_COLORS.AccentColorCode,

        TextColorCode: contribution.brandingColors?.TextColorCode || 'Auto',
        TertiaryColorCode:
          // contribution?.isLegacyColorsActive === false && contribution?.isCustomBrandingColorsActive == true
          //   ? contribution?.coachSelectedBrandingColors?.TertiaryColorCode
          //   : contribution?.isLegacyColorsActive === true && contribution?.isCustomBrandingColorsActive === false
          //   ?
          contribution.brandingColors?.TertiaryColorCode,
        // : contribution?.isLegacyColorsActive === true && contribution?.isCustomBrandingColorsActive === true
        // ? contribution.coachSelectedBrandingColors?.TertiaryColorCode
        // : CONTRIBUTION_COLORS.TertiaryColorCode,
        customLogo: contribution?.customLogo || null,
        isDarkModeEnabled: contribution?.isDarkModeEnabled || false,
        isMembersHiddenInCommunity: contribution.isMembersHiddenInCommunity || false,
        isMembersHiddenInGroupChat: contribution.isMembersHiddenInCommunity || false,
        isGroupChatHidden: contribution.isGroupChatHidden || false,
        isLiveSessionMembersHidden: contribution.isLiveSessionMembersHidden || false,
      }}
      validationSchema={Yup.object().shape({
        customTitleForMeetYourCoach: Yup.string()
          .max(20, 'Title can be a max of 20 characters')
          .required('This is a required field'),
        // contributionTagline: Yup.string().max(200, 'Title can be a max of 200 characters').optional(),
        viewWhoIAm: Yup.boolean().optional(),
        whoIAm: Yup.string().when('viewWhoIAm', {
          is: false,
          then: Yup.string()
            .strict(true)
            .min(0)
            .test('CustomMax', 'Cannot exceed 1000 characters', customMax)
            .required('This is a required field'),
          otherwise: Yup.string().strict(true).min(0).test('CustomMax', 'Cannot exceed 1000 characters', customMax),
        }),

        whoIAmLabel: Yup.string()
          .strict(true)
          .min(2, 'Must be at least 2 characters')
          .max(50, 'Cannot exceed 50 characters')
          .required('This is a required field'),

        viewWhatWeDo: Yup.boolean().optional(),
        whatWeDo: Yup.string().when('viewWhatWeDo', {
          is: false,
          then: Yup.string()
            .strict(true)
            .min(0)
            .test('CustomMax', 'Cannot exceed 1000 characters', customMax)
            .required('This is a required field'),
          otherwise: Yup.string().strict(true).min(0).test('CustomMax', 'Cannot exceed 1000 characters', customMax),
        }),

        whatWeDoLabel: Yup.string()
          .strict(true)
          .min(2, 'Must be at least 2 characters')
          .max(50, 'Cannot exceed 50 characters')
          .required('This is a required field'),

        viewPurpose: Yup.boolean().optional(),
        purpose: Yup.string().when('viewPurpose', {
          is: false,
          then: Yup.string()
            .strict(true)
            .min(0)
            .test('CustomMax', 'Cannot exceed 1000 characters', customMax)
            .required('This is a required field'),
          otherwise: Yup.string().strict(true).min(0).test('CustomMax', 'Cannot exceed 1000 characters', customMax),
        }),
        purposeLabel: Yup.string()
          .strict(true)
          .min(2, 'Must be at least 2 characters')
          .max(50, 'Cannot exceed 50 characters')
          .required('This is a required field'),

        viewPreparation: Yup.boolean().optional(),
        preparation: Yup.string().when('viewPreparation', {
          is: false,
          then: Yup.string()
            .strict(true)
            .min(0)
            .test('CustomMax', 'Cannot exceed 1000 characters', customMax)
            .required('This is a required field'),
          otherwise: Yup.string()
            .strict(true)
            .min(0)
            .test('CustomMax', 'Cannot exceed 1000 characters', customMax)
            .nullable(),
        }),
        preparationLabel: Yup.string()
          .strict(true)
          .min(2, 'Must be at least 2 characters')
          .max(50, 'Cannot exceed 500 characters')
          .required('This is a required field'),
        languageCodes: Yup.string().required('This is a required field'),
        minAge: Yup.string().required('This is a required field'),
        gender: Yup.mixed().oneOf(['NoRequirement', 'Male', 'Female', 'NonBinary']),
        inviteClientType: Yup.mixed().required().oneOf(['Free', 'Paid', 'Both']),
        instagramUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('instagram.com', 'This link is not related to Instagram'),
        linkedInUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('linkedin.com', 'This link is not related to LinkedIn'),
        youtubeUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('youtube.com|youtu.be', 'This link is not related to Youtube'),
        facebookUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('facebook.com', 'This link is not related to Facebook'),
        tiktokUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('tiktok.com', 'This link is not related to TikTok'),
        websiteUrl: Yup.string().max(100, 'Cannot exceed 100 characters'),
        PrimaryColorCode: Yup.string().min(7).max(7).required(),
        AccentColorCode: Yup.string().min(7).max(7).required(),
        TertiaryColorCode: Yup.string().min(7).max(7).required(),
        // TextColorCode: Yup.string().min(7).max(7).required(),
      })}
      onSubmit={saveToStorageHandler}
    >
      {formProps => {
        console.log(
          'formProps.values.isDarkModeEnabled--->contribution?.isDarkModeEnabled',
          formProps?.values?.isDarkModeEnabled,
          contribution?.isDarkModeEnabled,
        );
        const saveData = (event, to, withValidation = false) => {
          if (isEditing || withValidation) {
            formProps.handleSubmit(event);
          } else {
            saveToStorageHandler(formProps.values, {}, to);
          }
        };
        if (isEmpty(formProps.errors)) {
          dispatch(contributionActions.setErrorPopUp(false));
        }
        const setToInitial = () => {
          if (formProps.errors?.PrimaryColorCode) {
            formProps.setFieldValue('PrimaryColorCode', '#000');
          }
          if (formProps.errors?.AccentColorCode) {
            formProps.setFieldValue('AccentColorCode', '#000');
          }
          if (formProps.errors?.TertiaryColorCode) {
            formProps.setFieldValue('TertiaryColorCode', '#000');
          }
          if (formProps.errors?.TextColorCode) {
            formProps.setFieldValue('TextColorCode', '#000');
          }
        };

        return (
          <>
            <MainContainer
              sidebarProps={{
                saveHandler: (event, { to }) => {
                  setRedirectTo(to);
                  saveData(event, to);
                },
                errors: formProps?.errors,
              }}
            >
              <Joyride
                // tooltipComponent={Tooltip}
                callback={handleJoyrideCallback}
                continuous={true}
                getHelpers={getHelpers}
                run={run}
                stepIndex={stepIndex}
                showProgress={false}
                showSkipButton={false}
                steps={steps}
                styles={{
                  options: {
                    primaryColor: '#CDBA8F',
                    zIndex: 10000000,
                  },
                }}
              />
              <StyledMainSection mobileView={mobileView}>
                {errorOccured && (
                  <div
                    style={{
                      width: '45%',
                      backgroundColor: 'white',
                      position: 'absolute',
                      marginInline: '9%',
                      border: '1px solid #DBE7F2',
                      borderRadius: '4px',
                      zIndex: 1,
                    }}
                  >
                    <div style={{ padding: '15px 10px 10px 15px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontWeight: '700',
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#1E1E1E',
                              marginInline: '10px',
                            }}
                          >
                            {formProps.errors.whoIAm === 'This is a required field' ||
                            formProps.errors.preparation === 'This is a required field' ||
                            formProps.errors.purpose === 'This is a required field' ||
                            formProps.errors.whatWeDo === 'This is a required field'
                              ? 'Empty Fields'
                              : 'Error'}
                          </div>
                        </div>
                        <StyledCloseIcon
                          onClick={() => {
                            dispatch(contributionActions.setErrorPopUp(false));
                          }}
                          fontSize="10px"
                          style={{ color: 'black' }}
                        />
                      </div>
                      <div
                        style={{
                          fontFamily: 'Avenir',
                          fontWeight: '400',
                          fontSize: '16px',
                          lineHeight: '22px',
                          color: '#1E1E1E',
                          marginInline: '35px',
                        }}
                      >
                        {formProps.errors.whoIAm === 'This is a required field' ||
                        formProps.errors.preparation === 'This is a required field' ||
                        formProps.errors.purpose === 'This is a required field' ||
                        formProps.errors.whatWeDo === 'This is a required field'
                          ? 'Please fill out all fields in order to continue'
                          : 'Limit Exceeded'}
                      </div>
                      {/* <div style={{
                    fontFamily: 'Avenir',
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#1E1E1E',
                    marginTop: '10px',
                    marginInline: "35px"
                  }}>Please fill out missing field in order to continue</div> */}
                    </div>
                  </div>
                )}
                <Card id={'contributionDetails'} mobileView={mobileView}>
                  <CardHeader className={classes.header} mobileView={mobileView}>
                    <PageTitleSecond className={classes.detailsHeader} mobileView={mobileView}>
                      2. Details
                    </PageTitleSecond>
                    <BodyText mobileView={mobileView}>
                      Use this section to inform your client on the high-level purpose and details of your Contribution.
                      {contribution.type === ContributionType.contributionOneToOne &&
                        ' If you plan to offer this as a package of sessions, feel free to include relevant information.'}
                    </BodyText>
                    {/* {!isEditing && (
                      <ExampleLink href={ExamplePDF} mobileView={mobileView} target="_blank">
                        PDF Template
                      </ExampleLink>
                    )} */}
                  </CardHeader>
                  <CardBody mobileView={mobileView}>
                    <Form id="create-contribution-form" onKeyDown={handleFormKeyDown}>
                      <Grid container spacing={4}>
                        <Grid
                          style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline' }}
                          item
                          md={6}
                          xs={12}
                        >
                          <p style={{ marginRight: '10px', minWidth: '80px' }}>Meet Your</p>
                          <Input
                            type="text"
                            name="customTitleForMeetYourCoach"
                            value={formProps.values.customTitleForMeetYourCoach}
                          />
                          <StyledTooltipWrap>
                            <StyledTooltip
                              title="Your clients will see a section called “Meet Your Coach” on the landing page associated with this service. You can change this title to be anything you’d like! Examples; healer, teacher, mentor, consultant etc."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon htmlColor={colors.lightBrown} />
                            </StyledTooltip>
                          </StyledTooltipWrap>
                        </Grid>
                        {/* {contribution?.type != ContributionType.contributionOneToOne && ( */}
                        {/* <Grid
                          style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline' }}
                          item
                          md={6}
                          xs={12}
                        >
                          <p style={{ marginRight: '10px', width: '200px' }}>Tagline (optional)</p>
                          <Input
                            id="Tagline-input"
                            // label={`Taglin(optional)`}
                            name="contributionTagline"
                            value={formProps.values.contributionTagline}
                            counter={200}
                            fullWidth
                          />
                        </Grid> */}
                        {/* )} */}
                      </Grid>

                      <DetailsGrid container justify="center" spacing={4}>
                        <TextAreaWrapper
                          container
                          item
                          isCollapsed={purposeCollapsed}
                          md={purposeCollapsed ? TEXT_AREA_SIZE.SMALL : TEXT_AREA_SIZE.HUGE}
                          xs={12}
                        >
                          <TextArea
                            fprops={formProps}
                            image={formProps.values.purposeIcon}
                            label={formProps.values.purposeLabel}
                            isLabelEditable
                            isTextAreaModeEditable
                            initialMode={formProps.values.viewPurpose ? false : true}
                            changeMode={() => formProps.setFieldValue('viewPurpose', !formProps.values.viewPurpose)}
                            labelName="purposeLabel"
                            placeholder="This section is used to describe your client's ideal outcome and the goal for working together. Feel free to describe the mission of this specific program."
                            name="purpose"
                            rows="5"
                            fullWidth
                            counter={1000}
                            helperTextPosition="right"
                            onClickResize={handleResizePurpose}
                            // handleUploadIconFile={e => {
                            //   handleUploadIconFile(e, iconType.purpose, formProps.setFieldValue);
                            // }}
                            handleIconClick={() => {
                              handleIconClick(iconType.purpose);
                            }}
                            isCollapsed={purposeCollapsed}
                            isckEditorForEditContribution
                            id="contribution-details-purpose-textarea"
                          />
                        </TextAreaWrapper>
                        <TextAreaWrapper
                          container
                          item
                          isCollapsed={whoIAmCollapsed}
                          md={whoIAmCollapsed ? TEXT_AREA_SIZE.SMALL : TEXT_AREA_SIZE.HUGE}
                          xs={12}
                        >
                          <TextArea
                            fprops={formProps}
                            image={formProps.values.whoIAmIcon}
                            label={formProps.values.whoIAmLabel}
                            isLabelEditable
                            isTextAreaModeEditable
                            initialMode={formProps.values.viewWhoIAm ? false : true}
                            changeMode={() => formProps.setFieldValue('viewWhoIAm', !formProps.values.viewWhoIAm)}
                            labelName="whoIAmLabel"
                            placeholder="This section is used to describe who this offer is best for. Feel free to write a short description or bullet points. You can also change the title and intention of this section."
                            name="whoIAm"
                            rows="5"
                            fullWidth
                            counter={1000}
                            helperTextPosition="right"
                            onClickResize={handleResizeWhoIAm}
                            handleIconClick={() => {
                              handleIconClick(iconType.whoIAm);
                            }}
                            isCollapsed={whoIAmCollapsed}
                            isckEditorForEditContribution
                            id="contribution-details-bio-textarea"
                          />
                        </TextAreaWrapper>
                        <TextAreaWrapper
                          container
                          item
                          isCollapsed={whatWeDoCollapsed}
                          md={whatWeDoCollapsed ? TEXT_AREA_SIZE.SMALL : TEXT_AREA_SIZE.HUGE}
                          xs={12}
                        >
                          <TextArea
                            fprops={formProps}
                            image={formProps.values.whatWeDoIcon}
                            label={formProps.values.whatWeDoLabel}
                            isLabelEditable
                            isTextAreaModeEditable
                            initialMode={formProps.values.viewWhatWeDo ? false : true}
                            changeMode={() => formProps.setFieldValue('viewWhatWeDo', !formProps.values.viewWhatWeDo)}
                            labelName="whatWeDoLabel"
                            placeholder="This section is used to summerize the duration(s) of your sessions, subjects covered as well as any relevant information that will help clients understand what to expect and how they will reach their ideal outcome by working with you."
                            name="whatWeDo"
                            rows="5"
                            fullWidth
                            counter={1000}
                            helperTextPosition="right"
                            onClickResize={handleResizeWhatWeDo}
                            handleIconClick={() => {
                              handleIconClick(iconType.whatWeDo);
                            }}
                            isCollapsed={whatWeDoCollapsed}
                            isckEditorForEditContribution
                            id="contribution-details-expect-textarea"
                          />
                        </TextAreaWrapper>
                        <TextAreaWrapper
                          container
                          item
                          isCollapsed={preparationCollapsed}
                          md={preparationCollapsed ? TEXT_AREA_SIZE.SMALL : TEXT_AREA_SIZE.HUGE}
                          xs={12}
                        >
                          <TextArea
                            fprops={formProps}
                            image={formProps.values.preparationIcon}
                            label={formProps.values.preparationLabel}
                            isLabelEditable
                            isTextAreaModeEditable
                            initialMode={formProps.values.viewPreparation ? false : true}
                            changeMode={() =>
                              formProps.setFieldValue('viewPreparation', !formProps.values.viewPreparation)
                            }
                            labelName="preparationLabel"
                            placeholder="This section is used to share with your client how they can show up prepared, present and ready for sessions. Consider mentioning whether you will be providing worksheets or materials for them to review or complete prior to each session. "
                            name="preparation"
                            rows="5"
                            fullWidth
                            counter={1000}
                            helperTextPosition="right"
                            onClickResize={handlePreparationResize}
                            handleIconClick={() => {
                              handleIconClick(iconType.preparation);
                            }}
                            isCollapsed={preparationCollapsed}
                            isckEditorForEditContribution
                            id="contribution-details-prepare-textarea"
                          />
                        </TextAreaWrapper>
                      </DetailsGrid>

                      <Grid container spacing={4}>
                        <Grid item md={3} sm={6} xs={12}>
                          <Select
                            label="Language(s)"
                            name="languageCodes"
                            fullWidth
                            multiple
                            items={[
                              { title: 'English', value: 'En' },
                              { title: 'Arabic', value: 'Ar' },
                              { title: 'Chinese', value: 'Zh' },
                              { title: 'Czech', value: 'Cs' },
                              { title: 'Danish', value: 'Da' },
                              { title: 'Finnish', value: 'Fi' },
                              { title: 'French', value: 'Fr' },
                              { title: 'German', value: 'De' },
                              { title: 'Hindi', value: 'Hi' },
                              { title: 'Indonesian', value: 'Id' },
                              { title: 'Italian', value: 'It' },
                              { title: 'Japanese', value: 'Ja' },
                              { title: 'Korean', value: 'Ko' },
                              { title: 'Polish', value: 'Pl' },
                              { title: 'Portuguese', value: 'Pt' },
                              { title: 'Russian', value: 'Ru' },
                              { title: 'Spanish', value: 'Es' },
                              { title: 'Swedish', value: 'Sv' },
                              { title: 'Vietnamese', value: 'Vi' },
                            ]}
                          />
                          <FormControlLabel
                            classes={{
                              label: classes.checkboxLabel,
                            }}
                            checked={formProps.values.isSpeakingLanguageRequired}
                            onChange={formProps.handleChange('isSpeakingLanguageRequired')}
                            control={<Checkbox color="primary" />}
                            label="Speaking this language is required"
                            name="isSpeakingLanguageRequired"
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Input label="Age Requirement" type="text" name="minAge" fullWidth />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Select
                            label="Gender Requirement"
                            name="gender"
                            fullWidth
                            items={[
                              { title: 'No requirement', value: 'NoRequirement' },
                              { title: 'Male', value: 'Male' },
                              { title: 'Female', value: 'Female' },
                              { title: 'Non-binary', value: 'NonBinary' },
                            ]}
                          />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Input label="Website (optional)" type="text" name="websiteUrl" fullWidth />
                        </Grid>
                      </Grid>

                      <SocialGrid container spacing={4}>
                        <Grid item md={3} sm={6} xs={12}>
                          <Input label="Instagram (optional)" type="text" name="instagramUrl" fullWidth />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Input label="LinkedIn (optional)" type="text" name="linkedInUrl" fullWidth />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Input label="Youtube (optional)" type="text" name="youtubeUrl" fullWidth />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Input label="Facebook (optional)" type="text" name="facebookUrl" fullWidth />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Input label="TikTok (optional)" type="text" name="tiktokUrl" fullWidth />
                        </Grid>
                      </SocialGrid>
                      {/* <Grid container>
                      <Grid item md={3} sm={6} xs={12} style={{ marginTop: '22px', marginBottom: '22px' }}>
                        <Input label="TikTok (optional)" type="text" name="tiktokUrl" fullWidth />
                      </Grid>
                    </Grid> */}
                      <Grid container spacing={4}>
                        {/* <Grid container item md={3} sm={6} xs={12}>
                        <StyledTooltipWrap>
                          <FormControlLabel
                            checked={formProps.values.invitationOnly}
                            onChange={formProps.handleChange('invitationOnly')}
                            control={<Checkbox color="primary" />}
                            label="Invitation Only"
                            name="invitationOnly"
                            disabled={true}
                          />
                          <StyledTooltip
                            title="Currently Cohere allows coaches and service providers to invite clients to the platform and their services. In the future, we will have an option to open up your services to the public so that Cohere can connect you with more clients."
                            arrow
                            enterTouchDelay={TOOLTIP.ENTER_DELAY}
                            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                          >
                            <InfoIcon htmlColor={colors.lightBrown} />
                          </StyledTooltip>
                        </StyledTooltipWrap>
                      </Grid> */}
                        <Grid container item md={4} sm={6} xs={12}>
                          <StyledTooltipWrap>
                            <FormControlLabel
                              checked={formProps.values.arePublicPostsAllowed}
                              onChange={formProps.handleChange('arePublicPostsAllowed')}
                              control={<Checkbox color="primary" />}
                              label="Enable Client Posts"
                              name="arePublicPostsAllowed"
                            />
                            <StyledTooltip
                              title="By checking this box, you agree to enable clients posts including images, videos and/or thoughts on the 'community' tab of this contribution, which are viewable by other clients who also purchase this service."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon htmlColor={colors.lightBrown} />
                            </StyledTooltip>
                          </StyledTooltipWrap>
                        </Grid>

                        <Grid container item md={4} sm={6} xs={12}>
                          <StyledTooltipWrap>
                            <FormControlLabel
                              checked={formProps.values.isCommunityFeatureHiddden}
                              onChange={formProps.handleChange('isCommunityFeatureHiddden')}
                              control={<Checkbox color="primary" />}
                              label="Hide Community Feature"
                              name="hideCommunity"
                            />
                            <StyledTooltip
                              title="By checking this box, you agree to hide community feature."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon htmlColor={colors.lightBrown} />
                            </StyledTooltip>
                          </StyledTooltipWrap>
                        </Grid>

                        <Grid container item md={4} sm={6} xs={12}>
                          <StyledTooltipWrap>
                            <FormControlLabel
                              checked={formProps.values.isCustomBrandingColorsActive}
                              onChange={formProps.handleChange('isCustomBrandingColorsActive')}
                              control={<Checkbox color="primary" />}
                              label={
                                <Link
                                  style={{ textDecoration: 'underline' }}
                                  onClick={() => {
                                    setOpenBrandingModal(true);
                                  }}
                                >
                                  Use Custom Branding Colors
                                </Link>
                              }
                              name="isCustomBrandingColorsActive"
                            />

                            <StyledTooltip
                              title="By default, your contribution will use the custom branding colors that are set in your profile. You can always uncheck the setting to use Cohere branding colors."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon htmlColor={colors.lightBrown} />
                            </StyledTooltip>
                          </StyledTooltipWrap>
                        </Grid>
                        {contribution?.type != ContributionType.contributionOneToOne && (
                          <>
                            <Grid container item md={4} sm={6} xs={12}>
                              <StyledTooltipWrap>
                                <FormControlLabel
                                  checked={formProps.values.isMembersHiddenInCommunity}
                                  onChange={formProps.handleChange('isMembersHiddenInCommunity')}
                                  control={<Checkbox color="primary" />}
                                  // disabled={query?.id ? true : false}
                                  label="Hide Members Information"
                                  name="isMembersHiddenInCommunity"
                                />
                                <StyledTooltip
                                  title="If checked your clients will not be able to see a list of members enrolled."
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <InfoIcon htmlColor={colors.lightBrown} />
                                </StyledTooltip>
                              </StyledTooltipWrap>
                            </Grid>
                            <Grid container item md={4} sm={6} xs={12}>
                              <StyledTooltipWrap>
                                <FormControlLabel
                                  checked={formProps.values.isGroupChatHidden}
                                  onChange={formProps.handleChange('isGroupChatHidden')}
                                  control={<Checkbox color="primary" />}
                                  disabled={query?.id ? true : false}
                                  label="Disable Group Chat"
                                  name="isGroupChatHidden"
                                />
                                <StyledTooltip
                                  title="If checked group chat functionality will be disabled. Community features will still be enabled."
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <InfoIcon htmlColor={colors.lightBrown} />
                                </StyledTooltip>
                              </StyledTooltipWrap>
                            </Grid>
                            <Grid container item md={4} sm={6} xs={12}>
                              <StyledTooltipWrap>
                                <FormControlLabel
                                  checked={formProps.values.isLiveSessionMembersHidden}
                                  onChange={formProps.handleChange('isLiveSessionMembersHidden')}
                                  control={<Checkbox color="primary" />}
                                  // disabled={query?.id ? true : false}
                                  label="Hide Session Participants Count"
                                  name="isLiveSessionMembersHidden"
                                />
                                <StyledTooltip
                                  title="On the session tab for your service, members will not able to see who has registered for sessions."
                                  arrow
                                  enterTouchDelay={TOOLTIP.ENTER_DELAY}
                                  leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                                >
                                  <InfoIcon htmlColor={colors.lightBrown} />
                                </StyledTooltip>
                              </StyledTooltipWrap>
                            </Grid>
                          </>
                        )}
                        <Grid container item md={4} sm={6} xs={12}>
                          <StyledTooltipWrap>
                            <FormControlLabel
                              checked={formProps.values.allowClientsToInvite}
                              onChange={formProps.handleChange('allowClientsToInvite')}
                              control={<Checkbox color="primary" />}
                              label="Allow Clients to Share Contribution"
                              name="allowClientsToInvite"
                            />
                            <StyledTooltip
                              title="If unchecked, only you can invite clients to your service. If checked, your clients can invite friends. You control sharing options for paid and/or free links."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon htmlColor={colors.lightBrown} />
                            </StyledTooltip>
                          </StyledTooltipWrap>
                          {formProps.values.allowClientsToInvite && (
                            <div className="ClientShareContributionList" style={{ width: '70%' }}>
                              <Select
                                name="inviteClientType"
                                fullWidth
                                value={formProps.values.inviteClientType}
                                items={getOptions(formProps)}
                              />
                            </div>
                          )}
                        </Grid>
                        <Grid container item md={4} sm={6} xs={12}>
                          <StyledTooltipWrap>
                            <FormControlLabel
                              checked={formProps.values.shareInCollective}
                              onChange={formProps.handleChange('shareInCollective')}
                              control={<Checkbox color="primary" />}
                              label="Share in the Cohere Collective"
                              name="shareInCollective"
                            />
                            <StyledTooltip
                              title="This service will not be shared within the collective. Please note you must enable a free link in step 4 to go live, Otherwise members will not be able to access it."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon htmlColor={colors.lightBrown} />
                            </StyledTooltip>
                          </StyledTooltipWrap>
                        </Grid>
                      </Grid>
                      {formProps.errors && formProps.errors.PrimaryColorCode && (
                        <p style={{ color: 'red' }}>{formProps.errors.PrimaryColorCode}</p>
                      )}
                      {formProps.errors && formProps.errors.AccentColorCode && (
                        <p style={{ color: 'red' }}>{formProps.errors.AccentColorCode}</p>
                      )}
                      {formProps.errors && formProps.errors.TertiaryColorCode && (
                        <p style={{ color: 'red' }}>{formProps.errors.TertiaryColorCode}</p>
                      )}
                      {formProps.errors && formProps.errors.TextColorCode && (
                        <p style={{ color: 'red' }}>{formProps.errors.TextColorCode}</p>
                      )}
                    </Form>
                  </CardBody>
                </Card>

                <Buttons
                  backButtonTitle="Back"
                  backClickHandler={event => {
                    const to = query.id ? `/edit-contribution/${query.id}/basic` : '/create-contribution/basic';
                    setRedirectTo(to);
                    saveData(event, to);
                  }}
                  onNextClickHandler={event => {
                    if (isEditing) {
                      if (isEmpty(formProps.errors) === false) {
                        dispatch(contributionActions.setErrorPopUp(true));
                      } else {
                        const to = query.id
                          ? `/edit-contribution/${query.id}/sessions`
                          : '/create-contribution/sessions';
                        setRedirectTo(to);
                        saveData(event, to, true);
                      }
                    } else {
                      const to = query.id ? `/edit-contribution/${query.id}/sessions` : '/create-contribution/sessions';
                      setRedirectTo(to);
                      saveData(event, to, true);
                    }
                  }}
                  nextButtonTitle="Save and Next"
                  formId="create-contribution-form"
                />
              </StyledMainSection>
            </MainContainer>
            <input type="file" accept="image/*" onChange={onUploadFile} hidden="hidden" ref={iconRef} />
            <ImageCropper
              title={`Crop Your Icon`}
              submitTitle="Set Icon"
              imageToCrop={imageToCrop}
              isOpen={isOpen}
              onClose={() => {
                setIsOpen(false);
                setClickedIconeType(null);
              }}
              setFieldValue={formProps.setFieldValue}
              field={`${clickedIconeType}Icon`}
              shape="square"
              brandingColor={false}
            />
            <Modal
              title="Custom Branding Colors"
              isOpen={openBrandingModal}
              hiddenCancel
              loading={saving}
              brandingColor={false}
              isCreatingContribution={true}
              onCancel={() => {
                setOpenBrandingModal(false);
              }}
              onSubmit={() => {
                const to = query.id ? `/edit-contribution/${query.id}/details` : '/create-contribution/details';
                if (
                  !formProps.errors?.PrimaryColorCode &&
                  !formProps.errors?.AccentColorCode &&
                  !formProps.errors?.TertiaryColorCode &&
                  !formProps.errors?.TextColorCode
                ) {
                  const userData = {
                    ...user,

                    coachSelectedBrandingColors: {
                      PrimaryColorCode: formProps.values.PrimaryColorCode,
                      AccentColorCode: formProps.values.AccentColorCode,
                      TertiaryColorCode: formProps.values.TertiaryColorCode,
                      TextColorCode: formProps.values.TextColorCode,
                    },
                    customLogo: formProps.values.customLogo,
                    isDarkModeEnabled: formProps.values.isDarkModeEnabled,
                  };
                  // saveToStorageHandler(formProps.values, {}, to);
                  // saveData(formProps, to, true);
                  setOpenBrandingModalPopUp(true);
                  // editProfile(user?.id, userData);
                  // setOpenBrandingModal(false);
                }
              }}
              submitTitle="Save"
            >
              <Grid container item style={{ marginBottom: '50px', width: '500px' }}>
                <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7', height: '90px' }}>
                  <div className="heading">
                    <p>Brand Elements</p>
                  </div>
                  <div>
                    <p>Cohere allows you to adjust your custom branding colors</p>
                  </div>
                </Grid>
                <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7' }}>
                  <StyledIconSection>
                    <StyledRow>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Logo</StyledHeading>
                        <StyledTooltipModal
                          title="This is the logo that will appear on your branded landing pages on Cohere."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>
                      <Container onClick={onClickHandler}>
                        {formProps.values.customLogo ? (
                          <IconContainer>
                            <Image src={formProps.values.customLogo} />
                          </IconContainer>
                        ) : (
                          <EmptyIconContainer>+</EmptyIconContainer>
                        )}
                      </Container>
                    </StyledRow>
                  </StyledIconSection>
                  <InputHidden
                    ref={IconUpload}
                    onChange={e => {
                      handleIconChange(e, formProps.setFieldValue);
                      formProps.setFieldValue('isCustomBrandingColorsActive', true);
                    }}
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </Grid>
                <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7' }}>
                  <StyledIconSection>
                    <StyledRow>
                      <p style={{ margin: 'auto 0' }}>Choose Style Mode</p>
                      <ToggleButtonGroup
                        className="toggle-button"
                        color="primary"
                        value={formProps?.values?.isDarkModeEnabled ? 'dark' : 'light'}
                        exclusive
                        onChange={() => {
                          formProps.setFieldValue('isDarkModeEnabled', !formProps.values.isDarkModeEnabled);
                          formProps.setFieldValue('isCustomBrandingColorsActive', true);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="light">Light</ToggleButton>
                        <ToggleButton value="dark">Dark</ToggleButton>
                      </ToggleButtonGroup>
                    </StyledRow>
                  </StyledIconSection>
                </Grid>
                <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7', marginTop: '20px' }}>
                  <StyledColorSection>
                    <StyledColorSelector>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Primary color</StyledHeading>
                        <StyledTooltipModal
                          title="This color will be associated with call-to-action (CTA) buttons on your branded pages"
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>

                      <StyledColorPicker>
                        <ButtonExample
                          colorCode={formProps.values.PrimaryColorCode}
                          onchangevalue={code => {
                            formProps.setFieldValue('PrimaryColorCode', code);
                            // formProps.setFieldValue('isLegacyColorsActive', true);
                            formProps.setFieldValue('isCustomBrandingColorsActive', true);
                            saveColor({
                              colorName: 'PrimaryColorCode',
                              colorCode: code,
                              // formValues: values,
                            });
                          }}
                        />
                        {/* <StyledColor colorCode={values.PrimaryColorCode} onClick={funTest} /> */}
                        <StyledColorCodeWrapper>
                          <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                          <div style={{ width: '85%' }}>
                            <TextField
                              name="PrimaryColorCode"
                              value={formProps?.values?.PrimaryColorCode?.substring(1)}
                              type="text"
                              onChange={e => {
                                const colorCode = '#' + e.target.value;
                                formProps.setFieldValue('PrimaryColorCode', colorCode);
                                // formProps.setFieldValue('isLegacyColorsActive', true);
                                formProps.setFieldValue('isCustomBrandingColorsActive', true);
                                if (colorCode.length == 7) {
                                  saveColor({
                                    colorName: 'PrimaryColorCode',
                                    colorCode: colorCode,
                                    // formValues: values,
                                  });
                                }
                              }}
                              onBlur={formProps.handleBlur}
                              fullWidth
                            />
                          </div>
                        </StyledColorCodeWrapper>
                      </StyledColorPicker>
                    </StyledColorSelector>
                    <StyledColorSelector>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Accent color</StyledHeading>
                        <StyledTooltipModal
                          title="The color will be associated with various tabs, labels, and misc. components on your branded pages."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>
                      <StyledColorPicker>
                        <ButtonExample
                          colorCode={formProps.values.AccentColorCode}
                          onchangevalue={code => {
                            formProps.setFieldValue('AccentColorCode', code);
                            // formProps.setFieldValue('isLegacyColorsActive', true);
                            formProps.setFieldValue('isCustomBrandingColorsActive', true);
                            saveColor({
                              colorName: 'AccentColorCode',
                              colorCode: code,
                              // formValues: values,
                            });
                          }}
                        />
                        <StyledColorCodeWrapper>
                          <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                          <div style={{ width: '85%' }}>
                            <TextField
                              name="AccentColorCode"
                              value={formProps?.values?.AccentColorCode?.substring(1)}
                              type="text"
                              onChange={e => {
                                // formProps.setFieldValue('isLegacyColorsActive', true);
                                formProps.setFieldValue('isCustomBrandingColorsActive', true);
                                const colorCode = '#' + e.target.value;
                                formProps.setFieldValue('AccentColorCode', colorCode);

                                if (colorCode.length == 7) {
                                  saveColor({
                                    colorName: 'AccentColorCode',
                                    colorCode: colorCode,
                                    // formValues: values,
                                  });
                                }
                              }}
                              onBlur={formProps.handleBlur}
                              fullWidth
                            />
                          </div>
                        </StyledColorCodeWrapper>
                      </StyledColorPicker>
                    </StyledColorSelector>
                    <StyledColorSelector>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Tertiary color</StyledHeading>
                        <StyledTooltipModal
                          title="This color is only used if you have more than two categories on your Contribution."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>
                      <StyledColorPicker>
                        <ButtonExample
                          colorCode={formProps.values.TertiaryColorCode}
                          onchangevalue={code => {
                            // formProps.setFieldValue('isLegacyColorsActive', true);
                            formProps.setFieldValue('TertiaryColorCode', code);
                            formProps.setFieldValue('isCustomBrandingColorsActive', true);
                            saveColor({
                              colorName: 'TertiaryColorCode',
                              colorCode: code,
                              // formValues: values,
                            });
                          }}
                        />
                        {/* <StyledColor colorCode={values.TertiaryColorCode} /> */}
                        <StyledColorCodeWrapper>
                          <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                          <div style={{ width: '85%' }}>
                            <TextField
                              value={formProps?.values?.TertiaryColorCode?.substring(1)}
                              name="TertiaryColorCode"
                              type="text"
                              onChange={e => {
                                // formProps.setFieldValue('isLegacyColorsActive', true);
                                const colorCode = '#' + e.target.value;
                                formProps.setFieldValue('TertiaryColorCode', colorCode);
                                formProps.setFieldValue('isCustomBrandingColorsActive', true);

                                if (colorCode.length == 7) {
                                  saveColor({
                                    colorName: 'TertiaryColorCode',
                                    colorCode: colorCode,
                                    // formValues: values,
                                  });
                                }
                              }}
                              onBlur={formProps.handleBlur}
                              fullWidth
                            />
                          </div>
                        </StyledColorCodeWrapper>
                      </StyledColorPicker>
                    </StyledColorSelector>
                    <StyledColorSelector>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Text color</StyledHeading>
                        <StyledTooltipModal
                          title="This color is used show text color on your Contribution."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>
                      <Select
                        className={classes.colorSelect}
                        label=""
                        name="TextColorCode"
                        fullWidth
                        items={[
                          { title: 'Black', value: '#000000' },
                          { title: 'White', value: '#FFFFFF' },
                          { title: 'Auto', value: 'Auto' },
                        ]}
                        value={formProps?.values?.TextColorCode}
                        onChange={e => {
                          formProps.setFieldValue('TextColorCode', e.target.value);
                          // formProps.setFieldValue('isLegacyColorsActive', true);
                          formProps.setFieldValue('isCustomBrandingColorsActive', true);
                        }}
                      />
                    </StyledColorSelector>
                  </StyledColorSection>
                </Grid>
              </Grid>
              {formProps.errors && formProps.errors.PrimaryColorCode && (
                <p style={{ color: 'red' }}>{formProps.errors.PrimaryColorCode}</p>
              )}
              {formProps.errors && formProps.errors.AccentColorCode && (
                <p style={{ color: 'red' }}>{formProps.errors.AccentColorCode}</p>
              )}
              {formProps.errors && formProps.errors.TertiaryColorCode && (
                <p style={{ color: 'red' }}>{formProps.errors.TertiaryColorCode}</p>
              )}
              {formProps.errors && formProps.errors.TextColorCode && (
                <p style={{ color: 'red' }}>{formProps.errors.TextColorCode}</p>
              )}
            </Modal>
            <Modal
              title="Custom Branding Colors Set As Default"
              isOpen={openBrandingModalPopUp}
              brandingColor={false}
              isCreatingContribution={true}
              // hiddenCancel
              loading={saving}
              onCancel={() => {
                setOpenBrandingModalPopUp(false);
                setOpenBrandingModal(false);
                formProps.setFieldValue('isLegacyColorsActive', true);
              }}
              onSubmit={() => {
                if (
                  !formProps.errors?.PrimaryColorCode &&
                  !formProps.errors?.AccentColorCode &&
                  !formProps.errors?.TertiaryColorCode &&
                  !formProps.errors?.TextColorCode
                ) {
                  const userData = {
                    id: user.id,
                    brandingColors: {
                      PrimaryColorCode: formProps.values.PrimaryColorCode,
                      AccentColorCode: formProps.values.AccentColorCode,
                      TertiaryColorCode: formProps.values.TertiaryColorCode,
                      TextColorCode: formProps.values.TextColorCode,
                    },
                    customLogo: formProps?.values?.customLogo?.length > 0 ? formProps.values.customLogo : null,
                    isDarkModeEnabled: formProps?.values?.isDarkModeEnabled,
                  };
                  // saveData(formProps, to, true);
                  editUserColors(userData).then(res => {
                    dispatch(userActions.getUserSuccess(res));
                  });

                  // editProfile(user?.id, userData);
                  setOpenBrandingModal(false);
                  setOpenBrandingModalPopUp(false);
                  formProps.setFieldValue('isLegacyColorsActive', true);
                }
              }}
              cancelTitle="Just For This Contribution"
              submitTitle="Save To Profile And For This Contribution"
            >
              <Grid container item style={{ marginBottom: '50px', width: '500px' }}>
                Do you also want to make these colors your default profile colors?
              </Grid>
            </Modal>
            <Modal
              title=""
              isOpen={showModal}
              onCloseText="OK"
              hiddenCancel
              onSubmit={togglePopup}
              onCancel={togglePopup}
              disableFooter
            >
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
                <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
                <div
                  style={{
                    color: '#A58B62',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 450,
                    fontFamily: 'Avenir',
                  }}
                >
                  You are fully set up & ready to transform lives!
                </div>
                <Button
                  autoWidth
                  backgroundColor={'#C9B382'}
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    marginBottom: '10px',
                  }}
                  onClick={togglePopup}
                >
                  Let’s do this!
                </Button>
              </div>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
}

ContributionDetails.propTypes = {
  contribution: PropTypes.shape(),
  user: PropTypes.shape({
    bio: PropTypes.string,
  }).isRequired,
  saveContribution: PropTypes.func.isRequired,
  saveContributionToLS: PropTypes.func.isRequired,
};

ContributionDetails.defaultProps = {
  contribution: null,
};

const mapStateToProps = ({ account, contributions }) => ({
  contribution: contributions.activeContribution,
  user: account?.user,
  saving: account?.isLoading,
});

const actions = {
  saveContribution: contributionActions.saveContribution,
  saveContributionToLS: contributionActions.saveDraftContribution,
};

export default connect(mapStateToProps, actions)(ContributionDetails);
