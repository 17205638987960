import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Form } from 'formik';

import EnrollmentDates from './components/EnrollmentDates';
import Sessions from './components/Sessions';
import LiveVideo from './components/LiveVideo';
import { ContributionType } from 'helpers/constants';
import LiveSessionReminders from './components/LiveSessionReminders';

const MembershipForm = ({ mysession, values, errors, touched, setFieldValue, smsReminderSettings }) => {
  const Itemcallback = useCallback(data => {
    mysession(data);
  }, []);
  return (
    <Form>
      <Grid container spacing={4}>
        <EnrollmentDates setFieldValue={setFieldValue} values={values} enrollment={values.enrollment} />

        <Sessions
          values={values}
          enrollment={values.enrollment}
          providerName={values.liveVideoServiceProvider.providerName}
          sessions={values.sessions}
          mysession={Itemcallback}
          errors={errors}
          touched={touched}
          type={ContributionType.contributionMembership}
        />
        <LiveSessionReminders
          values={smsReminderSettings}
          setFieldValue={setFieldValue}
          contributionType={ContributionType.contributionMembership}
        />
        <LiveVideo
          values={values}
          liveVideoServiceProvider={values.liveVideoServiceProvider}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </Form>
  );
};

MembershipForm.propTypes = {
  values: PropTypes.shape({
    sessions: PropTypes.arrayOf(),
    enrollment: PropTypes.shape({
      toDate: PropTypes.string,
      fromDate: PropTypes.string,
      anyTime: PropTypes.bool,
    }).isRequired,
    liveVideoServiceProvider: PropTypes.shape(),
  }).isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.shape().isRequired,
};

export default MembershipForm;
