import React from 'react';
import classes from './ConfirmationEmailEditorModal.module.scss';
import Modal from 'components/UI/Modal';
import ConfirmationEmailModalContent from './ConfirmationEmailModalContent/ConfirmationEmailModalContent';

const ConfirmationEmailEditorModal = ({ isOpen, onSubmit, onCancel, ...restProps }) => {
  return (
    <Modal isOpen={isOpen} onCancel={onCancel} bodyStyles={{ maxHeight: '85vh' }} {...restProps}>
      <ConfirmationEmailModalContent onCancel={onCancel} />
    </Modal>
  );
};

export default ConfirmationEmailEditorModal;
