import React, { useRef, useState } from 'react';
import { ClickAwayListener, Grid, TextField, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { mdiDotsVertical, mdiFileDocumentOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { useRouteMatch } from 'react-router-dom';
import textFilePlaceholder from 'assets/textFile.png';
import excelFilePlaceholder from 'assets/excelFile.png';
import zipFilePlaceholder from 'assets/zipFile.png';
import pptFilePlaceholder from 'assets/pptFile.png';
import pdfFilePlaceholder from 'assets/pdfFile.png';
import Loader from 'components/UI/Loader';
import Modal from 'components/UI/Modal';
import PropTypes from 'prop-types';
import {
  deleteSpecificResources,
  downloadSpecificResources,
  getThemedColors,
  renameSpecificResources,
  shareSpecificResource,
} from 'services/contributions.service';
import { useSelector } from 'react-redux';
import {
  useStylesResource,
  StyledResourceOuterContainer,
  PreviewResourceImage,
  StyledResourceDateTime,
  StyledAlignmentContainer,
  StyledFileOutline,
  StyledResourceVideo,
  StyledFileIconOutline,
} from './style';
import DeleteFileModal from './components/DeleteFileModal';
import ClickDropDown, { StyledDropDownItem } from '../UI/ClickDropDown';
import './ResourceCard.scss';
import ResourceDateTime from './components/ResourceDateTime';
import useAccount from 'hooks/useAccount';
import { UserRoles } from 'helpers/constants';

const knownImageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'svg'];
const knownVideoExtensions = ['mpeg', 'mp4', 'webm', 'mkv'];
const unplayableVideoExtensions = ['mov', 'avi', 'quicktime'];
const knownOtherExtensions = ['pdf', 'rar', 'xlsx', 'pptx', 'txt'];

const ResourcesCard = ({
  resourceImg,
  resourceTitle,
  resourceDateTime,
  resourceContentType,
  resourceId,
  resourceSessionId,
  resourceKeyExntension,
}) => {
  const { path } = useRouteMatch();
  const startTimeMoment = moment(resourceDateTime);
  const lastDotIndex = resourceTitle.lastIndexOf('.');
  const fileExtension = resourceTitle.slice(lastDotIndex + 1)?.toLowerCase();
  const fileName = resourceTitle.substring(0, lastDotIndex);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const classes = useStylesResource();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newTitle, setNewTitle] = useState(fileName);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);

  const { currentRole } = useAccount();
  const isCohealer = currentRole === UserRoles.cohealer;
  const videoRef = useRef(null);
  const activeContributionID = activeContribution?.id;
  const isCoach = currentRole === UserRoles.cohealer;

  const {
    themedColor: themedTextColor,
    themedBackgroundColor: oldThemedBackgroundColor,
    newThemedBackgroundColor,
    newThemedTextColor,
  } = getThemedColors(activeContribution);

  const getRequestData = (isViewAble = true) => {
    return {
      contributionId: activeContributionID,
      sessionId: resourceSessionId,
      fileName: newTitle.concat('.', fileExtension),
      isViewAble,
      isResourceOnly: !resourceSessionId,
      documentId: resourceId,
      documentKeyWithExtension: resourceKeyExntension,
      documentOriginalNameWithExtension: newTitle.concat('.', fileExtension),
    };
  };
  const onDownload = () => {
    const downloadData = getRequestData(false);
    downloadSpecificResources(downloadData);
  };
  const onRename = () => {
    const renameData = getRequestData();
    setIsRenaming(false);
    setIsLoading(true);
    renameSpecificResources(renameData).then(() => {
      setIsLoading(false);
    });
  };
  const onSubmitDeleteHandler = () => {
    const deleteData = getRequestData();
    setIsDeleteModalOpen(false);
    setIsLoading(true);
    deleteSpecificResources(deleteData)
      .then(res => {
        setIsLoading(false);
        setIsDeleted(true);
      })
      .catch(error => {
        setIsLoading(false);
      });
  };
  const copyToClipboard = async text => {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const onSubmitShare = () => {
    const shareData = getRequestData();
    shareSpecificResource(shareData)
      .then(res => {
        copyToClipboard(res);
        setIsLoading(false);
        setIsDropDownOpen(true);
        setTooltipOpen(true);
      })
      .catch(error => {
        setIsLoading(false);
      });
    setTimeout(() => {
      setTooltipOpen(false);
      // setIsDropDownOpen(false);
    }, 2000);
    setTimeout(() => {
      setIsDropDownOpen(false);
    }, 2000);
  };

  const getResourceThumbnail = () => {
    const isImage = knownImageExtensions.includes(fileExtension);
    const isVideo = knownVideoExtensions.includes(fileExtension);
    const isUnplayableVideo = unplayableVideoExtensions.includes(fileExtension);
    const otherKnownType = knownOtherExtensions.includes(fileExtension);
    const isUnknownExtension = !isImage && !isVideo && !isUnplayableVideo && !otherKnownType;
    return (
      <PreviewResourceImage>
        {isImage && (
          <PreviewResourceImage
            style={{
              backgroundImage: `url(${resourceImg || ''})`,
              backgroundSize: 'cover',
            }}
          />
        )}
        {isUnplayableVideo && (
          <p style={{ padding: '10px', textAlign: 'center' }}>
            Video file format (.{fileExtension}) is not supported. Please download to watch.
          </p>
        )}
        {isVideo && (
          <StyledResourceVideo controls="controls" preload="metadata" ref={videoRef} controlsList="nodownload">
            <source src={`${resourceImg || ''}#t=0.2`} type="video/mp4" />
          </StyledResourceVideo>
        )}
        {fileExtension === 'pdf' && <StyledFileIconOutline imageUrl={pdfFilePlaceholder} />}
        {fileExtension === 'rar' && <StyledFileIconOutline imageUrl={zipFilePlaceholder} />}
        {fileExtension === 'xlsx' && <StyledFileIconOutline imageUrl={excelFilePlaceholder} />}
        {fileExtension === 'pptx' && <StyledFileIconOutline imageUrl={pptFilePlaceholder} />}
        {fileExtension === 'txt' && <StyledFileIconOutline imageUrl={textFilePlaceholder} />}
        {isUnknownExtension && (
          <StyledFileOutline>
            <Icon size={3} path={mdiFileDocumentOutline} />
          </StyledFileOutline>
        )}
      </PreviewResourceImage>
    );
  };

  const getActionDropdown = () => {
    const isImage = knownImageExtensions.includes(fileExtension);
    const isVideo = knownVideoExtensions.includes(fileExtension);

    return (
      <ClickDropDown
        className="resource-card-dropdown-icon"
        icon={mdiDotsVertical}
        isPathForContribution={path?.includes('contribution-view')}
        isOpenNow={isDropDownOpen}
      >
        {(isImage || isVideo) && (
          <div>
            <StyledDropDownItem
              style={{ background: newThemedBackgroundColor, color: themedTextColor, textDecoration: 'none' }}
              onClick={() => {
                if (isImage) {
                  setShowImageModal(true);
                } else if (isVideo) {
                  videoRef.current.requestFullscreen();
                }
              }}
            >
              Open
            </StyledDropDownItem>
          </div>
        )}
        <div>
          <StyledDropDownItem
            style={{ background: newThemedBackgroundColor, color: themedTextColor, textDecoration: 'none' }}
            onClick={onDownload}
          >
            Download
          </StyledDropDownItem>
        </div>
        {isCohealer && (
          <div>
            <StyledDropDownItem
              style={{ background: newThemedBackgroundColor, color: themedTextColor, textDecoration: 'none' }}
              onClick={() => {
                setIsRenaming(true);
              }}
            >
              Rename
            </StyledDropDownItem>
          </div>
        )}
        {isCohealer && (
          <ClickAwayListener
            onClickAway={() => {
              setIsDropDownOpen(false);
            }}
          >
            <div
              onClick={() => {
                setIsDropDownOpen(true);
                onSubmitShare();
              }}
              onKeyUp={() => {
                setIsDropDownOpen(true);
              }}
              role="button"
              tabIndex="0"
            >
              <Tooltip className="cursor-pointer" title="Link Copied" open={tooltipOpen} onClose={() => {}} arrow>
                <StyledDropDownItem
                  style={{ background: newThemedBackgroundColor, color: themedTextColor, textDecoration: 'none' }}
                >
                  Share
                </StyledDropDownItem>
              </Tooltip>
            </div>
          </ClickAwayListener>
        )}
        {isCohealer && (
          <div
            onClick={() => setIsDeleteModalOpen(true)}
            onKeyUp={() => setIsDeleteModalOpen(true)}
            role="button"
            tabIndex="0"
          >
            <StyledDropDownItem
              style={{ background: newThemedBackgroundColor, color: themedTextColor, textDecoration: 'none' }}
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            >
              Delete
            </StyledDropDownItem>
          </div>
        )}
      </ClickDropDown>
    );
  };

  return (
    <>
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
      {!isDeleted && (
        <StyledResourceOuterContainer
          style={{
            background: newThemedBackgroundColor,
            border: activeContribution?.isDarkModeEnabled ? '1px solid darkgray' : 'none',
          }}
          className={classes.basicResourceCard}
        >
          <Grid container>
            <Grid item xs={12} className={classes.basicResourceImage}>
              {getResourceThumbnail()}
            </Grid>
            <Grid item xs={12} className={classes.basicResourceTextInfo}>
              <StyledAlignmentContainer
                className={activeContribution?.isDarkModeEnabled ? 'rename-rescource-field' : ''}
                style={{ color: newThemedTextColor }}
              >
                {isRenaming ? (
                  <TextField
                    style={{ margin: '0', color: newThemedTextColor }}
                    id="outlined-basic"
                    margin="normal"
                    fullWidth
                    value={newTitle}
                    onChange={ev => setNewTitle(ev.target.value)}
                    InputProps={{ endAdornment: '.'.concat(fileExtension) }}
                    onBlur={onRename}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        onRename();
                      }
                    }}
                  />
                ) : (
                  <Tooltip title={newTitle.concat('.', fileExtension)}>
                    <StyledResourceDateTime style={{ color: newThemedTextColor }}>
                      {newTitle.concat('.', fileExtension)}
                    </StyledResourceDateTime>
                  </Tooltip>
                )}
                {getActionDropdown()}
              </StyledAlignmentContainer>
              {resourceDateTime && <ResourceDateTime startTimeMoment={startTimeMoment} />}
            </Grid>
          </Grid>
        </StyledResourceOuterContainer>
      )}

      <Modal
        className="resources-image-modal"
        isOpen={showImageModal}
        onCancel={() => setShowImageModal(false)}
        disableFooter
        mobileView
      >
        <img alt="image" src={resourceImg} style={{ maxWidth: '100%', maxHeight: '80%' }} />
      </Modal>

      <DeleteFileModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        onSubmit={onSubmitDeleteHandler}
        isDarkThemeEnabled={activeContribution?.isDarkModeEnabled}
      />
    </>
  );
};

ResourcesCard.propTypes = {
  resourceImg: PropTypes.string.isRequired,
  resourceTitle: PropTypes.string.isRequired,
  resourceDateTime: PropTypes.string.isRequired,
  resourceContentType: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  resourceSessionId: PropTypes.string.isRequired,
  resourceKeyExntension: PropTypes.string.isRequired,
};

export default ResourcesCard;
