export const LANGUAGE_CODES = {
  en: 'English',
  ar: 'Arabic',
  zh: 'Chinese',
  da: 'danish',
  fr: 'French',
  fi: 'Finnish',
  de: 'German',
  hi: 'Hindi',
  id: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  pl: 'Polish',
  pt: 'Portuguese',
  ru: 'Russian',
  es: 'Spanish',
  sv: 'Swedish',
  vi: 'Vietnamese',
};

export const PROFILE_CATEGORY_DESCRIPTIONS = {
  Interests:
    "These selections will help us tailor your experience. Tap once for things you're sort of interested in, twice for things you're really interested in, and a third time to remove it.",
  Curiosities:
    'These selections will help us tailor your experience. Tap once for things you might want to learn more about, twice for things you  really want to learn more about, and a third time to remove it.',
  Experiences:
    "These selections will help us tailor your experience. Tap once for things you're beginner or intermediate at, twice for things you're a pro at, and a third time to remove it.",
};

export const TIMEZONES = {
  'America/Argentina/Buenos_Aires': 'Argentina Standard Time',
  'America/Los_Angeles': 'Pacific Standard Time',
  'America/Denver': 'Mountain Standard Time',
  'America/Chicago': 'Central Standard Time',
  'America/New_York': 'Eastern Standard Time',
  'America/Anchorage': 'Alaskan Standard Time',
  'America/St_Johns': 'Newfoundland Standard Time',
  'Pacific/Honolulu': 'Hawaiian Standard Time',
  'America/Phoenix': 'Arizona Standard Time',
  'Australia/ACT': 'Australia/ACT',
  'Australia/Adelaide': 'Australia/Adelaide',
  'Australia/Brisbane': 'Australia/Brisbane',
  'Australia/Broken_Hill': 'Australia/Broken_Hill',
  'Australia/Canberra': 'Australia/Canberra',
  'Australia/Currie': 'Australia/Currie',
  'Australia/Darwin': 'Australia/Darwin',
  'Australia/Eucla': 'Australia/Eucla',
  'Australia/Hobart': 'Australia/Hobart',
  'Australia/LHI': 'Australia/LHI',
  'Australia/Lindeman': 'Australia/Lindeman',
  'Australia/Lord_Howe': 'Australia/Lord_Howe',
  'Australia/Melbourne': 'Australia/Melbourne',
  'Australia/North': 'Australia/North',
  'Australia/NSW': 'Australia/NSW',
  'Australia/Perth': 'Australia/Perth',
  'Australia/Queensland': 'Australia/Queensland',
  'Australia/South': 'Australia/South',
  'Australia/Sydney': 'Australia/Sydney',
  'Australia/Tasmania': 'Australia/Tasmania',
  'Australia/Victoria': 'Australia/Victoria',
  'Australia/West': 'Australia/West',
  'Australia/Yancowinna': 'Australia/Yancowinna',
  'Canada/Atlantic': 'Canada/Atlantic',
  'Canada/Central': 'Canada/Central',
  'Canada/Eastern': 'Canada/Eastern',
  'Canada/Mountain': 'Canada/Mountain',
  'Canada/Newfoundland': 'Canada/Newfoundland',
  'Canada/Pacific': 'Canada/Pacific',
  'Canada/Saskatchewan': 'Canada/Saskatchewan',
  'Canada/Yukon': 'Canada/Yukon',
  'Europe/Amsterdam': 'Europe/Amsterdam',
  'Europe/Andorra': 'Europe/Andorra',
  'Europe/Athens': 'Europe/Athens',
  'Europe/Belfast': 'Europe/Belfast',
  'Europe/Belgrade': 'Europe/Belgrade',
  'Europe/Berlin': 'Europe/Berlin',
  'Europe/Bratislava': 'Europe/Bratislava',
  'Europe/Brussels': 'Europe/Brussels',
  'Europe/Bucharest': 'Europe/Bucharest',
  'Europe/Budapest': 'Europe/Budapest',
  'Europe/Busingen': 'Europe/Busingen',
  'Europe/Chisinau': 'Europe/Chisinau',
  'Europe/Copenhagen': 'Europe/Copenhagen',
  'Europe/Dublin': 'Europe/Dublin',
  'Europe/Gibraltar': 'Europe/Gibraltar',
  'Europe/Guernsey': 'Europe/Guernsey',
  'Europe/Helsinki': 'Europe/Helsinki',
  'Europe/Isle_of_Man': 'Europe/Isle of Man',
  'Europe/Istanbul': 'Europe/Istanbul',
  'Europe/Jersey': 'Europe/Jersey',
  'Europe/Kaliningrad': 'Europe/Kiev',
  'Europe/Lisbon': 'Europe/Lisbon',
  'Europe/Ljubljana': 'Europe/Ljubljana',
  'Europe/London': 'Europe/London',
  'Europe/Luxembourg': 'Europe/Luxembourg',
  'Europe/Madrid': 'Europe/Madrid',
  'Europe/Malta': 'Europe/Malta',
  'Europe/Mariehamn': 'Europe/Mariehamn',
  'Europe/Minsk': 'Europe/Minsk',
  'Europe/Monaco': 'Europe/Monaco',
  'Europe/Moscow': 'Europe/Moscow',
  'Europe/Nicosia': 'Europe/Nicosia',
  'Europe/Oslo': 'Europe/Oslo',
  'Europe/Paris': 'Europe/Paris',
  'Europe/Podgorica': 'Europe/Podgorica',
  'Europe/Prague': 'Europe/Prague',
  'Europe/Riga': 'Europe/Riga',
  'Europe/Rome': 'Europe/Rome',
  'Europe/Samara': 'Europe/Samara',
  'Europe/San_Marino': 'Europe/San_Marino',
  'Europe/Sarajevo': 'Europe/Sarajevo',
  'Europe/Simferopol': 'Europe/Simferopol',
  'Europe/Skopje': 'Europe/Skopje',
  'Europe/Sofia': 'Europe/Sofia',
  'Europe/Stockholm': 'Europe/Stockholm',
  'Europe/Tallinn': 'Europe/Tallinn',
  'Europe/Tirane': 'Europe/Tirane',
  'Europe/Tiraspol': 'Europe/Tiraspol',
  'Europe/Uzhgorod': 'Europe/Uzhgorod',
  'Europe/Vaduz': 'Europe/Vaduz',
  'Europe/Vatican': 'Europe/Vatican',
  'Europe/Vienna': 'Europe/Vienna',
  'Europe/Vilnius': 'Europe/Vilnius',
  'Europe/Volgograd': 'Europe/Volgograd',
  'Europe/Warsaw': 'Europe/Warsaw',
  'Europe/Zagreb': 'Europe/Zagreb',
  'Europe/Zaporozhye': 'Europe/Zaporozhye',
  'Europe/Zurich': 'Europe/Zurich',
  Hongkong: 'Hongkong',
  Iceland: 'Iceland',
  Israel: 'Israel',
  Japan: 'Japan',
  'Mexico/BajaNorte': 'Mexico/Pacific',
  'Mexico/BajaSur': 'Mexico/Mountain',
  'Mexico/General': 'Mexico/Central',
  Navajo: 'Navajo',
  NZ: 'NZ',
  'NZ-CHAT': 'NZ-CHAT',
  Singapore: 'Singapore',
  'America/Argentina/Buenos_Aires': 'America/Argentina/Buenos_Aires',
  'America/Argentina/Catamarca': 'America/Argentina/Catamarca',
  'America/Argentina/ComodRivadavia': 'America/Argentina/ComodRivadavia',
  'America/Argentina/Cordoba': 'America/Argentina/Cordoba',
  'America/Argentina/Jujuy': 'America/Argentina/Jujuy',
  'America/Argentina/La_Rioja': 'America/Argentina/La_Rioja',
  'America/Argentina/Mendoza': 'America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos': 'America/Argentina/Rio_Gallegos',
  'America/Argentina/Salta': 'America/Argentina/Salta',
  'America/Argentina/San_Juan': 'America/Argentina/San_Juan',
  'America/Argentina/San_Luis': 'America/Argentina/San_Luis',
  'America/Argentina/Tucuman': 'America/Argentina/Tucuman',
  'America/Argentina/Ushuaia': 'America/Argentina/Ushuaia',
  'America/Costa_Rica': 'America/Costa_Rica',
  'America/Santo_Domingo': 'America/Santo_Domingo',
  Egypt: 'Egypt',
  'Asia/Calcutta': 'Asia/Calcutta',
  'Asia/Kolkata': 'Asia/Kolkata',
  'Asia/Karachi': 'Asia/Karachi',
  'Asia/Jakarta': 'Asia/Jakarta',
  'Asia/Jayapura': 'Asia/Jayapura',
  'America/Lima': 'America/Lima',
  'Asia/Bangkok': 'Asia/Bangkok',
  'America/Port_of_Spain': 'America/Port_of_Spain',
  'America/Montevideo': 'America/Montevideo',
  'Asia/Dubai': 'Gulf Standard Time',
  'America/Bogota': 'Colombia Standard Time',
};

export const EN_MESSAGES = {
  validation: {
    required: 'Required',
    email: {
      invalid: 'Invalid email',
      exists: 'User with this email already exists',
    },
    password:
      'Password must be at least 8 characters and contain following: upper case (A-Z), lower case (a-z) and number (0-9)',
  },
  signuppassword:
    'Password must be at least 8 characters and contain following: upper case (A-Z), lower case (a-z) and number (0-9).',
  auth: {
    login: 'Login',
  },
};

export const LOCAL_TRACK_PRIORITIES = {
  high: 'high',
  standard: 'standard',
  low: 'low',
};

export const RECORDING_VIDEO_STATUS = {
  roomInProgress: 0,
  inProgress: 1,
  available: 2,
};

export const LiveVideoProvider = {
  Cohere: { title: 'Cohere Video', value: 'Cohere' },
  Custom: { title: 'Other Video Platform Link', value: 'Custom' },
  InPersonSession: { title: 'In Person Session', value: 'custom' },
  Zoom: { title: 'Zoom', value: 'Zoom' },
};

export const PhoneCallOptions = {
  clientsPhone: { title: `Use Client's Phone Number`, value: 'client-phone' },
  coachPhone: { title: 'Use My Phone Number', value: 'coach-phone' },
};

export const ROUTES = {
  CREATE_CONTRIBUTION_BASIC: '/create-contribution/basic',
  CREATE_CONTRIBUTION_DETAILS: '/create-contribution/details',
  CREATE_CONTRIBUTION_SESSIONS: '/create-contribution/sessions',
  CREATE_CONTRIBUTION_PAYMENT: '/create-contribution/payment',
  CREATE_CONTRIBUTION_REVIEW: '/create-contribution/review',
  CREATE_CONTRIBUTION_SAVE: '/create-contribution/saveHandler',

  CREATE_LEAD_MAGNET_BASIC: '/create-lead-magnet/basic',
  CREATE_LEAD_MAGNET_DETAILS: '/create-lead-magnet/details',
  CREATE_LEAD_MAGNET_CONTENT: '/create-lead-magnet/content',
  // CREATE_LEAD_MAGNET_PAYMENT: '/create-lead-magnet/payment',
  CREATE_LEAD_MAGNET_REVIEW: '/create-lead-magnet/review',
  CREATE_CAMPAIGN_TITLE: '/create-campaign/title',

  ROLE_SWITCH: '/role/switch',
  ADD_ROLE: '/role/add',
  ADD_ROLE_CLIENT: '/role/add/client',
  SUBMIT_ADD_ROLE: '/role/submit',
  UNSUBSCRIBE: '/unsubscribe/:clientEmail/:coachId',
  RESUBSCRIBE: '/resubscribe/:clientEmail/:coachId',

  DASHBOARD: '/dashboard',

  SIGNIN: '/auth/signin',
  UNSUBSCRIBE: '/unsubscribe/:clientEmail/:coachId',
  RESUBSCRIBE: '/resubscribe/:clientEmail/:coachId',

  CONTRIBUTIONS: '/contributions/',
  CONTRIBUTIONS_1_TO_1: '/contributions/1-to-1-sessions',
  CONTRIBUTIONS_GROUP_COURSES: '/contributions/group-courses',
  CONTRIBUTIONS_MEMBERSHIPS: '/contributions/memberships',
  CONTRIBUTIONS_WORKSHOPS: '/contributions/workshop',

  LEAD_MAGNETS: '/lead-magnets',

  ACCOUNT: '/account',
  ACCOUNT_VIDEO: '/account/video',
  ACCOUNT_AFFILIATE: '/account/affiliate',
  ACCOUNT_BILLING: '/account/billing',
  ACCOUNT_COACH_BILLING: '/account/billing/coach',
  ACCOUNT_BILING_SIX_MONTH: '/account/billing/six-month',
  ACCOUNT_BILLING_EXTENDED: '/account/billing/coach/extended',
  ACCOUNT_MEMBERSHIPS: '/account/memberships',
  ACCOUNT_COUPON_CREATE: '/account/payment/createCoupon',
};

export const RESPONSE_CODES = {
  CLIENT: {
    NOT_ALLOWED: 403,
  },
  TWILIO: {
    IDENTITY_DUPLICATION: 53205,
  },
  BACKEND: {
    SUCCESS: 200,
  },
};

export const INPUT_NUMBERS_VALIDATION_REGEX = /\D/;

export const TOOLTIP = {
  ENTER_DELAY: 0,
  LEAVE_DELAY: 10000,
};

export const TOOLTIPDELAY = {
  ENTER_DELAY: 0,
  LEAVE_DELAY: 10000,
};

export const CAMPAIGN_TEMPLATE_TYPES = {
  TextOnly: 'TextOnly',
  Graphical: 'Graphical',
};

export const PAYMENT_OPTIONS = {
  PER_DAY: 'DailyMembership',
  TRIAL: 'Trial',
  PER_WEEK: 'WeeklyMembership',
  PER_MONTH: 'MonthlyMembership',
  PER_YEAR: 'YearlyMembership',
  PACKAGE: 'MembershipPackage',
  FREE: 'Free',
  FREESESSIONPACKAGE: 'FreeSessionsPackage',
  ENTIRE_COURSE: 'EntireCourse',
  SPLIT_PAYMENTS: 'SplitPayments',
  PER_SESSION: 'PerSession',
  SESSIONS_PACKAGE: 'SessionsPackage',
  MONTHLY_SESSION_SUBSCRIPTION: 'MonthlySessionSubscription',
};

export const MEMBERSHIP_STATUSES = {
  ACTIVE: 'active',
  CANCEL: 'cancel',
  UPGRADE: 'update',
};

export const PAIDTIER_STATUSES = {
  ACTIVE: 'Active',
  CANCEL: 'Cancel',
  UPGRADE: 'Upgraded',
};

export const VALIDATION_REGEX = {
  DATE: /\d{2}\/\d{2}\/\d{4}/,
};

export const LOCAL_STORAGE_TOKENS = {
  modalWasShown: 'MODAL_PLANS_WAS_SHOWN',
};

export const PAID_TIER_BUTTON_LABEL = {
  downgrade: 'Downgrade',
  cancel: 'Cancel',
  upgrade: 'Upgrade',
};

export const PAID_TIER_TITLES = {
  launch: 'Launch',
  impact: 'Impact',
  scale: 'Scale',
};

export const ENV = {
  DEV: 'development',
  PROD: 'production',
};
export const COUNT_POSTS_BY_PAGE = 5;
export const DAILY_BY_SEND_DATA = 1500;

export const CONTRIBUTION_COLORS = {
  PrimaryColorCode: '#C9B382',
  AccentColorCode: '#116582',
  TertiaryColorCode: '#F6E8BO',
  TextColorCode: '#FFFFF',
};

export const LEAD_MAGNET_COLORS = CONTRIBUTION_COLORS;

export const JOIN_CONTRIBUTION_STATUS = {
  apply: 'Apply',
  join: 'Join',
  pending: 'Pending',
};

export const PLATFORMS_ALLOWED = [
  'YouTube',
  'SoundCloud',
  'Facebook',
  'Vimeo',
  'Twitch',
  'Streamable',
  'Wistia',
  'DailyMotion',
  'Mixcloud',
  'Vidyard',
  'Kaltura',
];
export const EMBEDDED_LINK_ALLOWED_AUDIO_PLATFORMS = ['SoundCloud', 'Mixcloud'].map(k => k?.toLowerCase());
export const EMBEDDED_LINK_ALLOWED_VIDEO_PLATFORMS = PLATFORMS_ALLOWED.filter(
  k => !EMBEDDED_LINK_ALLOWED_AUDIO_PLATFORMS.includes(k),
).map(k => k?.toLowerCase());
export const SECTION_MENU_OPTIONS = {
  createGroup: 'Create Group',
  editSection: 'Edit Section',
  deleteSection: 'Delete Section',
};

export const GROUP_MENU_OPTIONS = {
  editGroup: 'Edit Group',
  archiveGroup: 'Archive Group',
  moveTosection: 'Move',
  muteNotifications: 'Mute Notifications',
  unmuteNotifications: 'Unmute Notifications',
  unArchive: 'Unarchive',
};
