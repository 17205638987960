import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const getCommunicationTabData = contributionId =>
  axiosInstance.get(`/Contribution/GetCustomTemplateByContributionId/${contributionId}`).then(get('data'));
const updateEmailTemplate = data => axiosInstance.put('/Contribution/UpdateEmailTemplate', data).then(get('data'));
const disableAllEmails = contributionId =>
  axiosInstance
    .put(`/Contribution/DisableEmailTemplatesByContributionId?ContributionId=${contributionId}`)
    .then(get('data'));
const disableEmailDoNotRemindMe = contributionId =>
  axiosInstance
    .put(`/Contribution/CustomEmailFeedbackPopDontRemindMe?ContributionId=${contributionId}`)
    .then(get('data'));
const updateIsNewleyCreated = data =>
  axiosInstance.put('/Contribution/UpdateIsNewlyCreatedByIdAndType', data).then(get('data'));

const updateHideCustomizeEmailBanner = data =>
  axiosInstance
    .put(
      `/Contribution/HideCustomizeEmailBanner?ContributionId=${data?.contributionId}&HideCustomizeEmailBanner=${data?.value}`,
      data,
    )
    .then(get('data'));

const updateEmailPermission = (emailType, isEmailEnabled) =>
  axiosInstance
    .put(`/User/UpdateEmailPermission?emailType=${emailType}&isEmailEnabled=${isEmailEnabled}`)
    .then(get('data'));
const updateEmailPermissionContribution = (contributionId, isEmailEnabled) =>
  axiosInstance
    .put(
      `/User/UpdateEmailNotificationByContribution?contributionId=${contributionId}&isEmailEnabled=${isEmailEnabled}`,
    )
    .then(get('data'));
const resetEmailTemplate = data => axiosInstance.put('/Contribution/ResetEmailTemplate', data).then(get('data'));
const CopyContributionEmailSettings = (data, contributionId) =>
  axiosInstance
    .put(
      `/Contribution/CopyContributionEmailSettings?FromContributionId=${data?.contributionId}&ToContributionId=${contributionId}`,
    )
    .then(get('data'));
const changeCalendarView = contributionId =>
  axiosInstance.put(`/Contribution/EnableDisableCalendarPreview?contributionId=${contributionId}`).then(get('data'));
const updateSelfpacedContent = (contributionId, sessionTimeId, SelfPacedContentAsHtml, moreInfo) =>
  axiosInstance
    .put(`/Contribution/UpdateSelfPacedContentAsHtml`, {
      contributionId,
      sessionTimeId,
      SelfPacedContentAsHtml,
      moreInfo,
    })
    .then(get('data'));
const updateSelfpacedContentMoreInfo = (contributionId, sessionTimeId, moreInfo, SelfPacedContentAsHtml) =>
  axiosInstance
    .put(`/Contribution/UpdateSelfPacedContentAsHtml`, {
      contributionId,
      sessionTimeId,
      moreInfo,
      SelfPacedContentAsHtml,
    })
    .then(get('data'));
const postApprovalRequired = (contributionId, IsPostApprovalRequired, ArePublicPostsAllowed) =>
  axiosInstance
    .put(
      `/Contribution/SetIfPostApprovalRequired?ContributionId=${contributionId}&IsPostApprovalRequired=${IsPostApprovalRequired}&ArePublicPostsAllowed=${ArePublicPostsAllowed}`,
    )
    .then(get('data'));
const enableBrandingColorsOnEmailTemplates = (data, contributionId) =>
  axiosInstance
    .put(`/Contribution/EnableBrandingColorsOnEmailTemplates?contributionId=${contributionId}&isEnabled=${data}`)
    .then(get('data'));
const GetCustomizedContributions = contributionId =>
  axiosInstance.get(`/Contribution/GetCustomizedContributions?ContributionId=${contributionId}`).then(get('data'));
export {
  getCommunicationTabData,
  updateEmailTemplate,
  updateEmailPermission,
  resetEmailTemplate,
  GetCustomizedContributions,
  CopyContributionEmailSettings,
  enableBrandingColorsOnEmailTemplates,
  postApprovalRequired,
  updateEmailPermissionContribution,
  changeCalendarView,
  updateSelfpacedContent,
  updateSelfpacedContentMoreInfo,
  disableAllEmails,
  disableEmailDoNotRemindMe,
  updateIsNewleyCreated,
  updateHideCustomizeEmailBanner,
};
