import React, { useCallback, useState, useRef, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { mdiHelpCircleOutline } from '@mdi/js';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import { useField } from 'formik';
// import Switch from '@mui/material/Switch';
import { Switch, StyledInput, StyledSlider } from '../switch/style';

import Icon from 'components/UI/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { TOOLTIP } from '../../constants';
import EditButton from 'components/UI/ListButtons/EditButton';
import Input from 'components/FormUI/Input';
import CollapseIcon from 'assets/ic-collapse-arrows.svg';
import ExpandIcon from 'assets/ic-expand-arrows.svg';

import { colors } from 'utils/styles';
import { style } from '@mui/system';
import { filledInputClasses } from '@mui/material';
import { CKEditor } from 'ckeditor4-react';
import './TextArea.scss';
import { convertToPlainText } from 'utils/utils';
import RichTextPlaceholder from './RichTextPlaceholder';

const useStyles = makeStyles(() => ({
  labelRoot: {
    fontFamily: 'Avenir',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    fontSize: `${14 / 16}rem`,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300,
  },
}));

const StyledSwitch = withStyles({
  switchBase: {
    color: colors.darkOceanBlue,
    '&$checked': {
      color: colors.darkOceanBlue,
    },
    '&$checked + $track': {
      backgroundColor: colors.darkOceanBlue,
    },
  },
  checked: {},
  track: {},
})(Switch);

const StyledInputLabel = styled(InputLabel)`
  margin-top: 10px;
  ${({ profile }) =>
    !profile &&
    css`
      margin-left: 10px;
    `}
`;

const StyledTextArea = styled.textarea`
  ${({ affiliateRow }) =>
    affiliateRow === true
      ? css`
          border: solid 1px #dbe7f2;
          border-radius: 4px;
        `
      : css`
          border: solid 1px #e4e4e4;
          border-radius: 3px;
        `}
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.27;
  letter-spacing: 0.25px;
  resize: none;
  padding: 0.85rem;
  font-family: 'Avenir';
  font-weight: normal;
  outline: none;

  ::-webkit-input-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  }
  ::-moz-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  } /* Firefox 19+ */
  :-moz-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  } /* Firefox 18- */
  :-ms-input-placeholder {
    font-size: 0.875rem;
    color: #2b2b2b;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${colors.fadedPurple};
    `}

  ${({ isCollapsed }) =>
    !isCollapsed &&
    `
    height: 360px;
  `}
`;

const HelperText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  font-weight: normal;
  line-height: 2rem;
  letter-spacing: 0.08px;
  font-family: 'Avenir';
  text-align: ${({ align }) => align || 'right'};
`;

const StyledErrorHelper = styled(HelperText)`
  text-align: left;
  color: ${colors.fadedPurple};
  font-size: 1rem;
  text-align: left;
  letter-spacing: 0.1px;
`;

const TextFieldContainer = styled.div`
  display: inline-flex;
  border: 0;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const StyledLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledIcon = styled.img`
  position: absolute;
  right: 7px;
  top: 55px;
  width: 12px;
  height: 12px;
  transition: margin ease-in-out 50ms;

  ${({ initialMode }) =>
    !initialMode &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  ${({ styleOverrides }) => styleOverrides && styleOverrides}

  &:hover {
    cursor: pointer;
  }
`;

function TextArea({
  image,
  fullWidth,
  helperText,
  optional,
  placeholder,
  helperTextPosition,
  counter,
  label,
  isLabelEditable,
  isTextAreaModeEditable,
  initialMode,
  changeMode,
  setMode,
  labelName,
  onClickResize,
  isCollapsed,
  isTextAreaVisible,
  textAreaClassNames,
  onChange,
  onClick,
  value,
  disabled,
  profile,
  handleIconClick,
  fprops,
  isckEditorForEditContribution,
  noLabel,
  affiliateRow = false,
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);
  const classes = useStyles();
  let formHook = useField;
  const iconRef = useRef(null);
  const mainContainerRef = useRef(null);
  const [applyExpansionIconStyles, setApplyExpansionIconStyles] = useState(false);
  if (disabled) {
    formHook = () => [{}, {}];
  }
  const [field, meta] = formHook(props);

  let privateHelperText = helperText || (optional ? 'optional' : '');
  const privateHelperTextPosition = optional ? 'right' : helperTextPosition || 'left';
  // here check if container-ref has scroll before rendering to screen

  if (counter) {
    privateHelperText = `${convertToPlainText(field?.value || '')?.length}/${counter}`;
  }
  const [isEditLabelMode, setIsEditLabelMode] = useState(false);
  const [ckEditorLoaded, setCkEditorLoaded] = useState(false);
  const editLabelToggle = useCallback(() => setIsEditLabelMode(labelMode => !labelMode), [setIsEditLabelMode]);

  const onChangeHandler = onChange ?? field.onChange;
  const onClickHandler = onClick ?? field.onClick;
  const changeModeHandler = changeMode ?? field.changeMode;

  const defaultValue = value ?? field.value;
  const testStr = 'coherepublic.s3.amazonaws.com';
  // const regExForIcon = /^data:?[a-z]{5}\/[a-z]*;.*/;
  // const shouldShowImg = regExForIcon.test(image);
  const isImage = image?.includes('coherepublic') && image?.includes('s3.amazonaws.com');

  useEffect(() => {
    const updateEditorHeight = () => {
      if (mainContainerRef.current) {
        setApplyExpansionIconStyles(
          mainContainerRef.current.scrollHeight > mainContainerRef.current.clientHeight && !isFocused,
        );
        const iframe = mainContainerRef.current.querySelector('iframe');
        if (iframe) {
          const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
          const iframeScrollHeight = iframeDocument.documentElement.scrollHeight;
          const iframeClientHeight = iframeDocument.documentElement.clientHeight;

          setApplyExpansionIconStyles(iframeScrollHeight > iframeClientHeight && !isFocused);
        }
      }
    };
    setTimeout(updateEditorHeight, 0);

    return clearTimeout(updateEditorHeight);
  }, [isFocused, isCollapsed, mainContainerRef.current]);

  const ckEditorVisible = () => {
    if (
      props?.name === 'purpose' ||
      props?.name === 'whoIAm' ||
      props?.name === 'whatWeDo' ||
      props?.name === 'preparation'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCKEditor = event => {
    fprops.setFieldValue(field?.name, event.editor.getData().trim());
  };

  useEffect(() => {
    const handlePaste = event => {
      // Get pasted content
      const clipboardData = event?.clipboardData || window?.clipboardData;
      const pastedText = clipboardData.getData('text/html');

      // Modify the pasted content to set default color
      const modifiedPastedText = pastedText.replace(/<span style="color:([^;"]+);?">/g, '<span>');

      // Set the modified content to clipboard
      event.clipboardData.setData('text/html', modifiedPastedText);
    };

    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []);
  return (
    <TextFieldContainer
      ref={mainContainerRef}
      fullWidth={fullWidth}
      className={`custom-text-area-container ${isFocused || !isCollapsed ? 'custom-text-area-focused' : ''} ${
        !isCollapsed ? 'custom-text-area-expanded' : ''
      }`}
    >
      {!noLabel && (
        <StyledLabel style={isTextAreaModeEditable && { justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            {(label === 'Bio' || label === 'What to expect') && profile === true ? (
              <></>
            ) : Boolean(image) && isImage ? (
              <img
                src={image}
                style={{ marginRight: '5px', cursor: 'pointer', height: '35px', width: '35px', borderRadius: '20px' }}
              />
            ) : (
              affiliateRow === false && <Icon path={image} right="5" valign="top" />
            )}
            {labelName === 'preparationLabel' && (
              <CloudUploadIcon
                onClick={handleIconClick}
                htmlColor={colors.darkOceanBlue}
                style={{ cursor: 'pointer' }}
              />
            )}
            {labelName === 'whatWeDoLabel' && (
              <CloudUploadIcon
                onClick={handleIconClick}
                htmlColor={colors.darkOceanBlue}
                style={{ cursor: 'pointer' }}
              />
            )}

            {labelName === 'whoIAmLabel' && (
              <CloudUploadIcon
                onClick={handleIconClick}
                htmlColor={colors.darkOceanBlue}
                style={{ cursor: 'pointer' }}
              />
            )}

            {labelName === 'purposeLabel' && (
              <CloudUploadIcon
                onClick={handleIconClick}
                htmlColor={colors.darkOceanBlue}
                style={{ cursor: 'pointer' }}
              />
            )}

            {Boolean(label) &&
              !isEditLabelMode &&
              (isTextAreaVisible ? (
                <StyledInputLabel
                  profile={profile}
                  classes={{
                    root: classes.labelRoot,
                  }}
                >
                  {label}
                </StyledInputLabel>
              ) : affiliateRow ? (
                <p style={{ margin: '0px' }}>{label}</p>
              ) : (
                <span className="heading">
                  <p style={{ marginTop: '0px' }}>{label}</p>
                </span>
              ))}
            {isEditLabelMode && (
              <div style={{ marginLeft: '10px' }}>
                {' '}
                <Input type="text" name={labelName} value={label} />
              </div>
            )}
            {isLabelEditable && (
              <div onClick={editLabelToggle}>
                <EditButton />
              </div>
            )}
            {/* <Tooltip
            title="it is tooltip"
            arrow
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          > */}

            {/* </Tooltip> */}
          </div>
          {isTextAreaVisible && isTextAreaModeEditable && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {!initialMode ? (
                <div>
                  <p style={{ marginTop: '0px', marginBottom: '5px', marginRight: '5px', fontSize: '1rem' }}>Hidden</p>
                </div>
              ) : (
                <div>
                  <p style={{ marginTop: '0px', marginBottom: '5px', marginRight: '5px', fontSize: '1rem' }}>Visible</p>
                </div>
              )}
              <div>
                {/* <StyledSwitch color="default" checked={initialMode} onChange={changeModeHandler} /> */}
                <Switch className="switch">
                  <StyledInput type="checkbox" onClick={changeMode} checked={initialMode} />
                  <StyledSlider className="slider round" />
                </Switch>
              </div>
            </div>
          )}
        </StyledLabel>
      )}

      {isTextAreaVisible && !isckEditorForEditContribution && (
        <StyledTextArea
          affiliateRow={affiliateRow}
          disabled={
            props.name === 'purpose' ||
            props.name === 'whoIAm' ||
            props.name === 'whatWeDo' ||
            props.name === 'preparation'
              ? !initialMode
              : initialMode
          }
          placeholder={placeholder}
          error={meta.touched && meta.error && affiliateRow === false}
          isCollapsed={isCollapsed}
          className={textAreaClassNames}
          {...field}
          {...props}
          onChange={onChangeHandler}
          value={typeof field.value === 'string' ? field.value : defaultValue}
        />
      )}

      {isTextAreaModeEditable && isckEditorForEditContribution && (
        <>
          {!ckEditorLoaded && <RichTextPlaceholder content={defaultValue} width="100%" height="162px" />}

          <span className={initialMode ? 'ckeditor-custom-textarea' : 'disabled-ck-editor'}>
            <CKEditor
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              initData={defaultValue || ' '}
              config={{
                editorplaceholder: placeholder,
                allowedContent: true,
                height: '150px',
                dialog_noConfirmCancel: true,
                toolbar: [
                  {
                    name: 'basicstyles',
                    items: ['Bold', 'Italic', 'Underline'],
                  },
                  { name: 'colors', items: ['TextColor'] },
                  { name: 'link', items: ['Link', 'Unlink'] },
                ],
                removeButtons: [],
                extraPlugins: ['font', 'editorplaceholder'],
              }}
              onChange={handleCKEditor}
              onLoaded={({ editor }) => {
                const customCss =
                  ".cke_editable { font-size: 16px; font-family: 'Avenir', sans-serif; } .cke_editable::before { font-size: 13px; font-family: 'Avenir', sans-serif; }";
                editor.addContentsCss(customCss);
                setCkEditorLoaded(true);
              }}
            />
          </span>
        </>
      )}
      {isTextAreaVisible && onClickResize && (
        <StyledIcon
          initialMode={initialMode}
          src={isCollapsed ? ExpandIcon : CollapseIcon}
          onClick={onClickResize}
          styleOverrides={applyExpansionIconStyles ? { marginRight: '1rem' } : { marginRight: 'initial' }}
        />
      )}
      {isTextAreaVisible && meta.touched && meta.error && affiliateRow === false ? (
        <StyledErrorHelper>{meta.error}</StyledErrorHelper>
      ) : (
        isTextAreaVisible &&
        privateHelperText && <HelperText align={privateHelperTextPosition}>{privateHelperText}</HelperText>
      )}
    </TextFieldContainer>
  );
}

TextArea.propTypes = {
  image: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  noLabel: PropTypes.bool,
  helperText: PropTypes.string,
  optional: PropTypes.bool,
  helperTextPosition: PropTypes.oneOf(['left', 'right']),
  counter: PropTypes.number,
  label: PropTypes.string,
  isLabelEditable: PropTypes.bool,
  labelName: PropTypes.string,
  onClickResize: PropTypes.func,
  handleIconClick: PropTypes.func,
  isCollapsed: PropTypes.bool,
  isTextAreaVisible: PropTypes.bool,
  textAreaClassNames: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  isckEditorForEditContribution: PropTypes.bool,
};

TextArea.defaultProps = {
  image: null,
  placeholder: null,
  fullWidth: false,
  helperText: null,
  optional: false,
  helperTextPosition: 'left',
  counter: null,
  label: '',
  isLabelEditable: false,
  noLabel: false,
  labelName: '',
  isCollapsed: true,
  isTextAreaVisible: true,
  textAreaClassNames: null,
  handleIconClick: () => {},
  onChange: null,
  value: null,
  isckEditorForEditContribution: false,
};

export default TextArea;
