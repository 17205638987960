import React from 'react';
import classes from './SegmentGroup.module.scss';
import { FieldArray } from 'formik';
import SegmentGroupCondition from '../Condition/Condition';

const draftCondition = {
  tagName: 'Tag',
  tagExists: 'Exists',
  tagId: '',
};

const SegmentGroup = props => {
  const { segmentGroupIndex, segmentGroup, tags, formProps, setSegmentError } = props;
  return (
    <div className={classes.segmentGroupContainer}>
      <div className={classes.segmentConditionsContainer}>
        <SegmentGroupCondition
          key={segmentGroupIndex}
          conditionIndex={segmentGroupIndex}
          segmentGroupIndex={segmentGroupIndex}
          condition={segmentGroup}
          tags={tags}
          formProps={formProps}
          setSegmentError={setSegmentError}
        />
      </div>
      {formProps.values.segmentConditions.length - 1 === segmentGroupIndex && (
        <div
          className={classes.addAnotherConditionBtn}
          onClick={() => {
            const newArray = [...formProps.values.segmentConditions, { ...draftCondition }];
            formProps.setFieldValue('segmentConditions', newArray);
          }}
        >
          Add Another Condition
        </div>
      )}
    </div>
  );
};

export default SegmentGroup;
