import React, { useEffect, useRef, useState } from 'react';
import classes from './AddTagModalContent.module.scss';
import TextField from 'components/UI/TextField';
import Select from 'components/UI/Select';
import styled from 'styled-components';
import { Formik } from 'formik';
import Modal from 'components/UI/Modal';
import { CrossOutlineIcon } from 'pages/CreateLeadMagnet/components/Icon';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getAllApprovedContributionsForCoach } from 'services/contributions.service';
import Loader from 'components/UI/Loader';
import { ContactsServices } from 'pages/Contacts/Services/Contacts.service';
import useAccount from 'hooks/useAccount';
import { fetchAllContactsAsync, fetchAllTagsAsync } from 'actions/contacts';
import { isEmpty } from 'lodash';
import Button from 'components/FormUI/Button';

const StyledError = styled.div`
  color: red;
  margin-top: 5px;
`;

const AddTagModalContent = ({ isOpen, onClose, tag = {}, onAddContactsClick, setNewTag, isEditing }) => {
  console.log('taglovelytag>>', tag);
  const { user } = useAccount();
  const dispatch = useDispatch();
  const { contacts } = useSelector(state => state.contacts);
  const [contactsList, setContactsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contributionsList, setContributionsList] = useState([]);
  const [modalError, setModalError] = useState([]);
  const formikRef = useRef();

  useEffect(() => {
    if (contacts) {
      setContactsList(contacts?.map(c => ({ title: `${c.firstName} ${c.lastName}`, value: c.id })));
    }
  }, [contacts]);

  const getContributionsList = async () => {
    setIsLoading(true);
    const res = await getAllApprovedContributionsForCoach();
    setContributionsList(res?.map(c => ({ title: c.title, value: c.id })));
    setIsLoading(false);
  };

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue('newTag', tag?.name);
      formikRef.current.setFieldValue('contacts', tag?.contactIds);
    }
  }, [tag]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        tagId: tag?.id ?? null,
        coachId: tag?.coachId ?? null,
        emailSent: tag?.emailSent ?? 0,
        newTag: tag?.name ?? '',
        contacts: tag?.contactIds ?? [],
      }}
      validationSchema={Yup.object().shape({
        newTag: Yup.string().required('This is required'),
        contacts: Yup.array(),
      })}
      onSubmit={values => {
        setIsSubmitting(true);
        if (isEditing) {
          const data = {
            id: values?.tagId,
            coachId: values?.coachId,
            emailSent: values?.emailSent,
            name: values?.newTag,
            contactsCount: values?.contacts.length,
            contactIds: values?.contacts,
            contacts: values?.contacts?.map(contactId => {
              const contactDetails = contacts?.find(c => c.id === contactId);
              return {
                id: contactId,
                notes: contactDetails?.notes,
                chatSid: contactDetails?.chatSid,
                tagIds: contactDetails?.tags?.map(t => t.id),
                firstName: contactDetails?.firstName,
                lastName: contactDetails?.lastName,
              };
            }),
          };

          ContactsServices.editTag(data)
            .then(res => {
              console.log('res', res);
              dispatch(fetchAllTagsAsync());
              onClose();
            })
            .catch(err => {
              console.log('err', err);
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        } else {
          const data = {
            name: values?.newTag,
            coachId: user?.id,
            emailSent: 0,
            contactsCount: values?.contacts.length,
            contactIds: values?.contacts,
            contacts: values?.contacts?.map(contactId => {
              const contactDetails = contacts?.find(c => c.id === contactId);
              return {
                id: contactId,
                notes: contactDetails?.notes,
                chatSid: contactDetails?.chatSid,
                tagIds: contactDetails?.tags?.map(t => t.id),
                firstName: contactDetails?.firstName,
                lastName: contactDetails?.lastName,
              };
            }),
          };

          ContactsServices.createTag(data)
            .then(res => {
              console.log('res', res);
              dispatch(fetchAllTagsAsync());
              onClose();
            })
            .catch(err => {
              console.log('err', err);
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        }
        dispatch(fetchAllContactsAsync({}));
      }}
    >
      {({ values, handleChange, setFieldValue, errors, touched, handleSubmit, handleBlur }) => {
        const selectedContacts = contacts?.filter(c => values?.contacts.includes(c.id));
        const contactsValue =
          selectedContacts?.length > 3
            ? `${selectedContacts
                ?.map(c => `${c.firstName} ${c.lastName}`)
                .slice(0, 3)
                .join(', ')} ${selectedContacts?.length > 3 ? `and ${selectedContacts?.length - 3} more` : ''}`
            : selectedContacts?.map(c => `${c.firstName} ${c.lastName}`).join(', ');
        console.log('selectedContacts-->>>contactsValue', selectedContacts, contactsValue);
        return (
          <Modal
            title={isEditing ? 'Edit Tag' : 'Add a Tag'}
            isOpen={isOpen}
            onCancel={() => {
              onClose();
            }}
            onSubmit={() => {
              handleSubmit();
              // onClose();
            }}
            submitTitle={isEditing ? 'Save' : 'Add'}
            reduceCancelButton
            widthRequired
            disableSubmitClick={isSubmitting}
          >
            <div className={classes.main}>
              {isLoading && <Loader />}
              <div className={classes.field_container}>
                <div className={classes.field_label}>Tag Name</div>
                <div className={classes.field}>
                  <TextField
                    name="newTag"
                    className={classes.textfield}
                    value={values?.newTag}
                    onChange={e => {
                      setFieldValue('newTag', e.target.value);
                      setNewTag(e.target.value);
                    }}
                    onBlur={handleBlur}
                    inputClasses={{
                      underline: classes.textfield_underline,
                    }}
                  />
                  {touched?.newTag && errors?.newTag && <StyledError>{errors.newTag}</StyledError>}
                </div>
              </div>
              <div className={classes.field_container}>
                <div className={classes.field_label}> Select Contacts</div>
                <div className={classes.field}>
                  <TextField
                    name="newTag"
                    className={classes.textfield}
                    value={contactsValue}
                    onClick={() => {
                      onAddContactsClick();
                    }}
                    onChange={e => {}}
                    inputClasses={{
                      underline: classes.textfield_underline,
                    }}
                  />
                </div>
              </div>
              <div className={classes.field_container}>
                <Button className={classes.addcontacts_btn} onClick={onAddContactsClick} type="button">
                  {selectedContacts?.length > 0 ? 'Edit & View Contacts' : 'Add & View Contacts'}
                </Button>
              </div>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AddTagModalContent;
