import React from 'react';

const ScoredQuizIcon = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.39 0.693237C33.6063 0.736497 33.837 0.794176 34.0533 0.837435C37.8457 1.61611 40.4124 4.75963 40.4413 8.76833C40.4701 12.6905 40.4557 16.6127 40.4413 20.5349C40.4413 21.6164 39.9654 22.3374 39.1147 22.6258C38.3216 22.8998 37.4708 22.6402 36.9373 21.9913C36.5623 21.5299 36.4902 20.9819 36.4902 20.4051C36.4902 16.5839 36.4758 12.7626 36.4902 8.94137C36.4902 7.54265 36.0577 6.36022 34.9329 5.48062C34.2119 4.90382 33.3611 4.64427 32.4527 4.64427C24.392 4.64427 16.3313 4.62985 8.27067 4.64427C6.05002 4.64427 4.435 6.21603 4.27638 8.40784C4.26196 8.56646 4.26196 8.73949 4.26196 8.89811C4.26196 20.2898 4.26196 31.667 4.26196 43.0587C4.26196 44.4574 4.69455 45.6398 5.83372 46.5194C6.59797 47.1106 7.46316 47.3413 8.41487 47.3413C10.5346 47.3413 12.6687 47.3413 14.7884 47.3413C15.7257 47.3413 16.4179 47.8749 16.7063 48.7112C16.9658 49.4755 16.7495 50.3839 16.0718 50.8309C15.6969 51.0761 15.2066 51.2635 14.7596 51.2779C12.5822 51.3212 10.4048 51.3068 8.22741 51.2924C3.88705 51.3212 0.267671 47.7739 0.310931 43.1452C0.36861 36.6418 0.325351 30.1241 0.325351 23.6207C0.325351 18.7036 0.325351 13.8008 0.325351 8.88369C0.325351 5.76901 1.60871 3.3609 4.2908 1.78914C5.11273 1.31329 6.09328 1.12583 7.00173 0.794176C7.11708 0.750917 7.24686 0.722077 7.37664 0.693237C16.0574 0.693237 24.7237 0.693237 33.39 0.693237Z"
        fill="#215C73"
      />
      <path
        d="M45.6897 33.367C45.6753 35.1839 45.0841 36.597 43.9305 37.7506C40.3832 41.3123 36.8215 44.8596 33.2454 48.3924C32.9138 48.7241 32.4379 48.9692 31.9765 49.1134C29.4963 49.8344 27.0016 50.5121 24.5214 51.1899C23.2669 51.5359 22.2143 51.0745 21.897 49.9497C21.7817 49.546 21.7961 49.0557 21.9114 48.652C22.6469 46.1429 23.3967 43.6339 24.1898 41.1392C24.3196 40.7355 24.5647 40.3317 24.8675 40.0289C28.4004 36.4816 31.9332 32.9344 35.4949 29.4015C37.1965 27.7144 39.2441 27.1664 41.5368 27.8874C43.7863 28.594 45.1274 30.1946 45.5888 32.5018C45.6609 32.8334 45.6753 33.1795 45.6897 33.367ZM26.6844 46.4746C27.9534 46.1285 29.1214 45.8113 30.2749 45.4796C30.5345 45.4075 30.7941 45.2489 30.9815 45.0758C33.2022 42.8696 35.4228 40.6634 37.629 38.4427C37.73 38.3418 37.8309 38.2264 37.8886 38.1688C36.9657 37.2315 36.0429 36.323 35.0335 35.3136C34.9614 35.429 34.8749 35.602 34.7451 35.7318C32.9426 37.5343 31.169 39.38 29.3088 41.1248C28.2562 42.1198 27.4919 43.2013 27.2179 44.6433C27.1314 45.22 26.9007 45.8113 26.6844 46.4746ZM40.8014 35.256C41.5224 34.708 41.8973 33.9726 41.6666 33.0497C41.4791 32.2422 40.9023 31.7519 40.0948 31.5933C39.3162 31.4347 38.5375 31.8096 38.0616 32.5306C38.9701 33.4246 39.8785 34.3331 40.8014 35.256Z"
        fill="#215C73"
      />
      <path
        d="M20.3839 12.5591C23.7292 12.5591 27.0746 12.5591 30.42 12.5591C31.7467 12.5591 32.6407 13.4964 32.5253 14.7509C32.4388 15.6738 31.689 16.4092 30.7373 16.5101C30.6075 16.5245 30.4777 16.5245 30.3479 16.5245C23.7148 16.5245 17.0673 16.5245 10.4342 16.5245C9.17965 16.5245 8.34331 15.818 8.25679 14.7076C8.15585 13.5108 9.04988 12.5879 10.3188 12.5735C12.8134 12.5591 15.2937 12.5735 17.7883 12.5735C18.6391 12.5591 19.5187 12.5591 20.3839 12.5591Z"
        fill="#215C73"
      />
      <path
        d="M20.3549 24.4276C17.0096 24.4276 13.6642 24.4276 10.3188 24.4276C8.97771 24.4276 8.0981 23.4326 8.25672 22.1637C8.37208 21.2841 9.09307 20.5919 10.0159 20.491C10.1457 20.4766 10.2755 20.4766 10.4053 20.4766C17.0672 20.4766 23.7148 20.4766 30.3767 20.4766C31.6457 20.4766 32.5108 21.2697 32.5253 22.4232C32.5397 23.5624 31.6745 24.4132 30.4777 24.4276C28.4012 24.442 26.3248 24.4276 24.2483 24.4276C22.9505 24.4276 21.6527 24.4276 20.3549 24.4276Z"
        fill="#215C73"
      />
      <path
        d="M16.3884 32.3459C14.3408 32.3459 12.3076 32.3603 10.26 32.3459C8.94777 32.3314 8.06816 31.2932 8.25562 30.0243C8.39981 29.0581 9.22174 28.3948 10.3032 28.3948C12.322 28.3804 14.3552 28.3948 16.374 28.3948C18.4216 28.3948 20.4548 28.3804 22.5024 28.3948C23.8146 28.4093 24.6942 29.4475 24.5067 30.7164C24.3626 31.6826 23.5406 32.3459 22.4591 32.3459C20.4404 32.3459 18.4072 32.3459 16.3884 32.3459Z"
        fill="#215C73"
      />
    </svg>
  );
};

export default ScoredQuizIcon;
