import React, { useState, useEffect } from 'react';
import classes from './QuizPage.module.scss';
import QuizViewMain from 'pages/QuizViewPage/Components/Containers/MainContainer/MainContainer';
import { useDispatch, useSelector } from 'react-redux';
import QuestionView from './Components/QuestionView/QuestionView';
import { getClientQuizFormById, getQuizFormById } from 'pages/CreateQuizes/Services/CreateQuizes.service';
import { setActiveQuiz } from 'actions/quizes';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Loader from 'components/UI/Loader';
import ProgressBar from './Components/ProgressBar/ProgressBar';
import useAccount from 'hooks/useAccount';
import { UserRoles } from 'helpers/constants';
import useRouter from 'hooks/useRouter';
import useScreenView from 'hooks/useScreenView';
import { CONTRIBUTION_COLORS } from '../../../../constants';
import { Tooltip } from '@material-ui/core';
import { useTheme, useMediaQuery } from '@material-ui/core';

const HeaderTitle = ({ title = '', type, isEditing }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  let titleText = '';
  if (title?.length > 0) {
    if (mobileView) {
      if (title?.length > 30) {
        titleText = title.substring(0, 25) + '...';
      } else {
        titleText = title;
      }
    } else {
      if (title?.length > 50) {
        titleText = title.substring(0, 40) + '...';
      } else {
        titleText = title;
      }
    }
  } else {
    if (type === 'Form' && isEditing) {
      titleText = 'Edit a Form';
    } else if (type === 'Form' && !isEditing) {
      titleText = 'Create a Form';
    } else if (type != 'Form' && isEditing) {
      titleText = 'Edit a Quiz';
    } else {
      titleText = 'Create a Quiz';
    }
  }
  return (
    <Tooltip title={title} arrow>
      <div style={{ fontSize: mobileView ? '20px' : '' }}>{titleText}</div>
    </Tooltip>
  );
};
export default function ClientQuizPage() {
  const { activeQuiz, userDetails } = useSelector(state => state?.quizes);
  const [activeIndex, setActiveIndex] = useState(0);
  const { mobileView } = useScreenView();
  const dispatch = useDispatch();
  const { currentRole, user } = useAccount();
  const { id } = useParams();
  const { history, pathname } = useRouter();
  const isPreview = pathname.includes('preview');
  const { questions, isBrandingColorEnabled, brandingColors, type, title } = activeQuiz;
  const { PrimaryColorCode, AccentColorCode, TextColorCode } = brandingColors ?? CONTRIBUTION_COLORS;

  useEffect(() => {
    if (id && currentRole === UserRoles.cohealer && isPreview) {
      getQuizFormById(id, true)
        .then(res => {
          dispatch(setActiveQuiz(res));
        })
        .catch(error => {
          console.log('error', error);
        });
    }
    if (id && (isEmpty(user) || currentRole === UserRoles.client)) {
      getClientQuizFormById(id)
        .then(res => {
          dispatch(setActiveQuiz(res));
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  }, [id]);

  if (isEmpty(activeQuiz)) {
    return <Loader />;
  }

  return (
    <QuizViewMain
      headerProps={{
        headerTitle: <HeaderTitle {...{ title, type }} />,
      }}
    >
      <ProgressBar
        isBrandingColorEnabled={isBrandingColorEnabled}
        AccentColorCode={AccentColorCode}
        array={questions}
        activeIndex={activeIndex}
      />
      <div className={classes.container}>
        <QuestionView
          isPreview={isPreview}
          question={questions[activeIndex]}
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
        />
      </div>
    </QuizViewMain>
  );
}
