import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, Switch, Route, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import * as paidTier from 'selectors/paidTier';
import { colors, devices } from 'utils/styles';
import * as contributionActions from 'actions/contributions';
import * as pageActions from 'actions/page';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DropDownList from './DropDownList/DropDownList';
import Joyride, {
  CallBackProps,
  STATUS,
  EVENTS,
  Step,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import Can from 'components/Auth/Can';
import Logo from 'components/FormUI/Logo';
import Button from 'components/FormUI/Button';
import usePaidTier from 'hooks/usePaidTier';
import { SIGNUP_TYPES, UserRoles } from 'helpers/constants';
import * as pageAction from '../../actions/page';

import SidebarDropDown from './SidebarDropDown';
import CreateContributionMenu from './CreateContributionMenu';
import CreateLeadMagnetMenu from './CreateLeadMagnetMenu';
import { CONTRIBUTION_COLORS, PAID_TIER_TITLES, ROUTES } from '../../constants';
import { useDispatch } from 'react-redux';
import { getUserProfile } from 'services/user.service';
import CoachImage from 'assets/chatlogo.png';
import { useSelector } from 'react-redux';
import { useAccount, useShallowEqualSelector } from 'hooks/index';
import { setCohereAcademyStatus } from 'actions/update-user';
import { ClickAwayListener, makeStyles } from '@material-ui/core';
import { fetchDomains } from 'actions/emailMarketing.actions';
import CreateQuizesModal from 'pages/CreateQuizes/Components/Modals/CreateModal/CreateModal';
import { setShowCreateModal } from 'actions/quizes';
const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 270px;
  min-height: 100vh;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0px 4px 0 rgba(157, 157, 157, 0.3);
  z-index: 200;

  /* @media screen and (${devices.laptop}) {
    display: block;
  } */
`;

const SidebarHeader = styled.div`
  margin: 45px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SidebarTerms = styled.div`
  margin: 60px 0 10px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 13px;
  align-items: center;
  cursor: pointer;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const StyledHeaderTitle = styled.div`
  margin: 0 auto;
  font-family: Avenir;
  font-size: 22.3px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
`;
const StyledAffiliateLink = styled(NavLink)`
  color: ${colors.gray};
  font-weight: initial;

  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const StyledLink = styled(NavLink)`
  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledTermsLink = styled.a`
  color: ${colors.gray};
  font-weight: initial;

  &.disableMe {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const useStyles = makeStyles(theme => ({
  createButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  createButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 20px',
    minWidth: '130px !important',
    maxWidth: '130px !important',
    fontSize: '15px',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  dropDownContainer: {},
}));

const DropDownSvg = fill => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill={fill}>
      <path
        d="M11.7472 0.937637L11.5707 0.761053L11.3939 0.937445L6.98047 5.34125L2.56705 0.937444L2.39028 0.761053L2.21369 0.937636L0.803692 2.34764L0.626916 2.52441L0.803692 2.70119L6.80369 8.70119L6.98047 8.87797L7.15725 8.70119L13.1572 2.70119L13.334 2.52441L13.1572 2.34764L11.7472 0.937637Z"
        fill={fill}
        stroke="white"
        stroke-width="0.5"
      />
    </svg>
  );
};

function Sidebar(props) {
  // const [termsModal, setTermsModal] = useState(false);
  const { signupType } = useSelector(state => state.account);
  const { currentRole, user: loggedInUser, roles } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const isCoachAndClientRolesAvailable = roles.includes(UserRoles.client) && roles.includes(UserRoles.cohealer);
  const isAdmin = roles.includes(UserRoles.admin);
  const user = useAccount();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const showCompletedGetStart = useSelector(state => state.contributions.showCompletedGetStart);
  const refreshStep = showCompletedGetStart === 'createRefresh';
  const { showModal } = useSelector(state => state.quizes);
  const showStep = showCompletedGetStart === 'create';
  const { path } = useRouteMatch();
  const isCohealer = currentRole === UserRoles.cohealer;
  const isClient = currentRole === UserRoles.client;
  const shouldShowViewSwitchLink = isCohealer || isClient;

  const DropDownItems = [
    {
      title: 'Contribution',
      to: ROUTES.CREATE_CONTRIBUTION_BASIC,
    },
    {
      title: 'Lead Magnet',
      to: ROUTES.CREATE_LEAD_MAGNET_BASIC,
    },
    {
      title: 'Email Campaign',
      to: ROUTES.CREATE_CAMPAIGN_TITLE,
    },
    {
      title: 'Form/Quiz',
      to: '',
    },
  ];
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  // const { isLaunchPlan } = usePaidTier();
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%
  useEffect(() => {
    dispatch(fetchDomains());
  }, []);

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const [stepIndex, setStepIndex] = useState(0);
  const shouldBeDisabled =
    currentRole === UserRoles.cohealer &&
    (signupType === SIGNUP_TYPES.TYPE_A ||
      signupType === SIGNUP_TYPES.TYPE_B ||
      loggedInUser.signupType === SIGNUP_TYPES.TYPE_B ||
      loggedInUser.signupType === SIGNUP_TYPES.TYPE_A ||
      isLaunchPlan);
  const [run, setRun] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const PopUp = useSelector(state => state.contributions.showPopUp);
  useEffect(() => {
    dispatch(contributionActions.setPopUp(false));
    if ((refreshStep || PopUp === true) && path.includes('dashboard')) {
      setRun(true);
    } else if ((showStep || PopUp === true) && path.includes('dashboard')) {
      setRun(true);
    } else {
      dispatch(contributionActions.setPopUp(false));
    }
  }, [PopUp, showCompletedGetStart]);

  let joyRideHelpers;

  const [steps, setSteps] = useState([
    {
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              borderColor: CONTRIBUTION_COLORS.PrimaryColorCode,
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Create a Contribution
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Click this button to create your first service. Services are called “Contributions” on Cohere, because we
            know you’re making huge impact on people’s lives!
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '20px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => {
                dispatch(pageActions.toggleMobileSidebar());
                dispatch(contributionActions.setPopUp(false));
                dispatch(contributionActions.setShowCompletedGetStart(null));
                setRun(false);
                if (user.user.userProgressbarData.FirstContributionDone === false) {
                  history.push('/create-contribution/basic');
                } else if (user.user.userProgressbarData.FirstContributionDone === true) {
                  history.push('/create-contribution/basic', { from: 'refreshContribution' });
                }
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'bottom',
      target: '#createService',
    },
  ]);

  const handleJoyrideCallback = data => {
    const { status, type, index, action } = data;
    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if (finishedStatuses.includes(status) || action == 'close') {
      setRun(false);
      setStepIndex(null);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);

    console.groupEnd();
  };
  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const handleClickAway = () => {
    setDropDownOpen(false);
  };
  return (
    <>
      <Joyride
        // tooltipComponent={Tooltip}
        callback={handleJoyrideCallback}
        continuous={true}
        getHelpers={getHelpers}
        run={run}
        stepIndex={stepIndex}
        showProgress={false}
        showSkipButton={false}
        steps={steps}
        styles={{
          options: {
            primaryColor: CONTRIBUTION_COLORS.AccentColorCode,
            zIndex: 10000000,
          },
        }}
      />

      <SidebarContainer>
        <div style={isCohealer ? {} : { position: 'fixed', minWidth: '270px' }}>
          <div>
            <SidebarHeader>
              <StyledLink
                className={
                  user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                    ? null
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                    ? 'disableMe'
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                    ? null
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                    ? 'disableMe'
                    : null
                }
                to="/dashboard"
              >
                <Logo />
              </StyledLink>

              {/* <Can
                roleName={UserRoles.cohealer}
                yes={() => (
                  <StyledLink
                    id={'createService'}
                    className={
                      user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                        ? null
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                        ? 'disableMe'
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                        ? null
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                        ? 'disableMe'
                        : null
                    }
                    
                    to="/create-contribution/basic"
                  >
                    <Button
                      onClick={() => {
                        dispatch(contributionActions.setErrorPopUp(false));
                      }}
                      autoWidth
                    >
                      Create a Contribution
                    </Button>
                  </StyledLink>
                )}
              /> */}

              {isCoach && !isAdmin && (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div id={'createService'} className={classes.createButtonWrapper}>
                    <Button
                      disabled={shouldBeDisabled}
                      onClick={() => {
                        dispatch(contributionActions.setErrorPopUp(false));
                        setDropDownOpen(!dropDownOpen);
                      }}
                      className={classes.createButton}
                    >
                      Create <DropDownSvg fill={shouldBeDisabled ? '#9b9b9b' : 'white'} />
                    </Button>
                    {dropDownOpen && (
                      <DropDownList containerClassName={classes.dropDownContainer} list={DropDownItems} />
                    )}
                  </div>
                </ClickAwayListener>
              )}

              <Can roleName={UserRoles.admin} yes={() => <StyledHeaderTitle>Admin</StyledHeaderTitle>} />
            </SidebarHeader>
          </div>
          <Switch>
            <Route path="/create-contribution">
              <CreateContributionMenu {...props} />
            </Route>
            <Route path="/edit-contribution/:id">
              <CreateContributionMenu {...props} />
            </Route>
            <Route path="/create-lead-magnet">
              <CreateLeadMagnetMenu {...props} />
            </Route>
            <Route path="/edit-lead-magnet/:id">
              <CreateLeadMagnetMenu {...props} />
            </Route>
            <Route component={SidebarDropDown}>
              <SidebarDropDown {...props} />
            </Route>
          </Switch>
          <SidebarTerms
          // onClick={() => {
          //   setTermsModal(true);
          // }}
          >
            {shouldShowViewSwitchLink &&
            isCoachAndClientRolesAvailable === true &&
            (isLaunchPlan === false ||
              (isLaunchPlan === true && user?.user?.isPartnerCoach) ||
              (isLaunchPlan === true && user?.user?.havePaidTierPurchase)) &&
            currentRole === UserRoles.client ? (
              <></>
            ) : /* <StyledAffiliateLink
              className={
                user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                  ? null
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                    ? 'disableMe'
                    : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                      ? null
                      : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                        ? 'disableMe'
                        : null
              }
              onClick={() => dispatch(pageAction.changeDropdown(true))}
              to={ROUTES.ROLE_SWITCH}
            >
              {`Change to ${isClient ? 'Coach' : 'Client'} View?`}
            </StyledAffiliateLink> */
            currentRole === UserRoles.cohealer ? (
              <></>
            ) : (
              /* <StyledAffiliateLink onClick={() => dispatch(pageAction.changeDropdown(true))} to={ROUTES.ROLE_SWITCH}>
                          {`Change to ${isClient ? 'Coach' : 'Client'} View?`}
                        </StyledAffiliateLink> */
              <></>
            )}

            <StyledTermsLink
              className={
                user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                  ? null
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                  ? 'disableMe'
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                  ? null
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                  ? 'disableMe'
                  : null
              }
              rel="noopener noreferer"
              href="https://www.cohere.live/privacy-policy"
              target="_blank"
            >
              {currentRole === UserRoles.cohealer && (
                <StyledAffiliateLink className={shouldBeDisabled && 'disableMe'} to={ROUTES.ACCOUNT_AFFILIATE}>
                  Affiliate Program
                </StyledAffiliateLink>
              )}
            </StyledTermsLink>
            <StyledTermsLink rel="noopener noreferer" href="https://www.cohere.live/privacy-policy" target="_blank">
              Cohere Terms and Privacy
            </StyledTermsLink>
            {isCoach
              ? isCoachAndClientRolesAvailable && (
                  <StyledAffiliateLink
                    // onClick={() => dispatch(pageAction.changeDropdown(true))}
                    to={ROUTES.ROLE_SWITCH}
                  >
                    My Journey
                  </StyledAffiliateLink>
                )
              : shouldShowViewSwitchLink &&
                isCoachAndClientRolesAvailable === true &&
                (isLaunchPlan === false ||
                  (isLaunchPlan === true && user?.user?.isPartnerCoach) ||
                  (isLaunchPlan === true && user?.user?.havePaidTierPurchase)) &&
                currentRole === UserRoles.client && (
                  <StyledAffiliateLink
                    className={
                      user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                        ? null
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                        ? 'disableMe'
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                        ? null
                        : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                        ? 'disableMe'
                        : null
                    }
                    onClick={() => dispatch(pageAction.changeDropdown(true))}
                    to={ROUTES.ROLE_SWITCH}
                  >
                    Coach Dashboard
                  </StyledAffiliateLink>
                )}
          </SidebarTerms>
          {/* <Modal
        title="Terms and Privacy"
        isOpen={termsModal}
        hiddenCancel
        submitTitle="Exit"
        onSubmit={() => {
          setTermsModal(false);
        }}
        onCancel={() => {
          setTermsModal(false);
        }}
      >
        <FlexDiv>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/CoherePrivacyPolicy-1.pdf"
          >
            Cohere Privacy Policy
          </a>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/TermsofUse.pdf"
          >
            Terms of Use
          </a>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/UserAgreement.pdf"
          >
            User Agreement
          </a>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/CohereFeeAuthorizationAgreement.pdf"
          >
            Cohere Fee Authorization Agreement
          </a>
          <a
            target="_blank"
            rel="noopener noreferer"
            href="https://www.cohere.live/wp-content/uploads/2020/11/CohereOne-to-OneandGroupCoursePaymentAgreement.pdf"
          >
            Cohere One-to-One and Group Course Payment Agreement
          </a>
        </FlexDiv>
      </Modal> */}
        </div>
      </SidebarContainer>
    </>
  );
}

Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;
