import { handleActions } from 'redux-actions';
import * as actions from 'actions/easyBookingThankYou';
import moment from 'moment';
import { isUndefined } from 'lodash';

const initialState = {
  selectedDate: moment(),
  selectedSlot: null,
  timeZone: '',
  timeZoneThankYou: '',
  code: null,
  showModal: true,
  isSelfSchedule: false,
};

const easyBookingThankYou = handleActions(
  new Map([
    [
      actions.setEasyBookingThankYouData.setData,
      (state, { payload }) => {
        let newPayload = { ...payload };
        if (isUndefined(payload.showModal)) {
          newPayload = { ...payload, showModal: true };
        }
        return {
          ...state,
          ...newPayload,
        };
      },
    ],
  ]),
  initialState,
);

export default easyBookingThankYou;
