import React, { memo, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useHttp, useClientPreview, useRouter, useScreenView, useChangedProps } from 'hooks';
import styled, { css } from 'styled-components';
import { cloneDeep, debounce, isArray, isEqual } from 'lodash';
import { ContentRow } from 'pages/CreateQuizes/Components/styled';
import { MOBILE_TABS } from '../constants';
import useQuiz from 'hooks/useQuiz';

const QuizContentLayout = props => {
  const {
    children,
    leftCol = <></>,
    centerCol = <></>,
    rightCol = <></>,
    header = <></>,
    subHeader = <></>,
    selectedTab,
    focusedQuestion,
  } = props;
  const { mobileView } = useScreenView();
  const { showCustomizeResults } = useQuiz();
  const isSettingsActive = selectedTab === MOBILE_TABS.Settings;

  const [inDetailsMode, setDetailsMode] = useState(false);

  if (mobileView) {
    if (isSettingsActive) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {header}
          {subHeader}
          {rightCol}
        </div>
      );
    } else {
      if (focusedQuestion === null && !showCustomizeResults) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {header}
            {subHeader}
            {leftCol}
          </div>
        );
      } else {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {header}
            {!showCustomizeResults && subHeader}
            {centerCol}
          </div>
        );
      }
    }
  }
  return (
    <ContentRow
      className="row--body"
      {...{
        mobileView,
      }}
      // ref={columnsContainerRef}
    >
      {leftCol}
      {centerCol}
      {rightCol}
    </ContentRow>
  );
};

export default memo(QuizContentLayout, isEqual);
