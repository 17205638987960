import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextField, Grid } from '@material-ui/core';
import { useStyles } from 'pages/ContributionView/components/PurchaseBlock/hooks/useStyles';
import { TemplateType } from 'helpers/constants/templateType';
import { LOG_IN_FAILURE, login } from 'actions/user';
import useScreenView from 'hooks/useScreenView';
import { addContributiontoMyJourney } from 'services/purchase.service';
import { useContribution } from 'pages/ContributionView/hooks';
import { setCookie } from 'services/cookies.service';
import useRouter from 'hooks/useRouter';
import { setItem } from 'services/localStorage.service';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import { StyledLink } from 'pages/Auth/SignInPage';
import { getOTP } from 'actions/user';
import { Link } from 'react-router-dom';
import SignInWithOtpModal from 'components/Modals/SignInWithOtpModal';
import { determineColorToUse, determineDarkThemedColorToUse } from 'services/contributions.service';
import { setEasyBookingThankYouData } from 'actions/easyBookingThankYou';

const StyledModal = styled(Modal)`
  .cohere-modal {
    max-width: 720px !important;
    width: ${props => (props.ispassword || props.mobileView ? '100%' : '524px')};
  }
`;

const StyledEmail = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SendOtpLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const SendOtpLink = styled(Link)`
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#2b2b2b')};
  font-family: 'Avenir';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 1.17;
  text-decoration: none;
  margin-left: 5px;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 360px) {
    font-size: 12px;
  }
  @media (max-width: 390px) {
    font-size: 14px;
  }
  @media (max-width: 375px) {
    font-size: 12px;
  }
`;
const StyledError = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 2px;
  padding-left: 10px;
`;

const StyledFormContainer = styled.div`
  padding-top: 10px;
`;

const modeModel = {
  confirmation: 'CONFIRMATION',
  login: 'LOGIN',
};

const validationSchema = isOtpModal =>
  Yup.object().shape({
    email: Yup.string().isRequired,
    password: Yup.string().when('otp', {
      is: () => !isOtpModal,
      then: Yup.string()
        .strict(true)
        .trim('Please remove spaces after your password')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g,
          'Password must be at least 8 characters and contain following: upper case (A-Z), lower case (a-z) and number (0-9)',
        )
        .required(
          'Password must be at least 8 characters and contain following: upper case (A-Z), lower case (a-z) and number (0-9)',
        ),
    }),
  });

function ExistingUserGuestContributionAddModal({
  isOpen,
  setIsOpen,
  user,
  PrimaryColorCode,
  newThemedTextColor,
  newThemedBackgroundColor,
}) {
  const [title, setTitle] = useState('Add session to existing account?');
  const [submitTitle, setSubmitTitle] = useState('Add to my account');
  const [cancelTitle, setcancelTitle] = useState('No thanks');
  const [mode, setMode] = useState(modeModel.confirmation);
  const { parentDomain } = useRouter();
  const classNames = useStyles();
  const dispatch = useDispatch();
  const { mobileView } = useScreenView();
  const contribution = useContribution();
  const easyBookingThankYou = useSelector(state => state.easyBookingThankYou);

  const formRef = useRef();

  const addToMyJourney = async (BookingDoneWithSecondaryAccount = true) => {
    await addContributiontoMyJourney({
      contributionId: contribution.id,
      helpingMaterial: easyBookingThankYou?.helpingMaterial,
      bookTimeId: easyBookingThankYou?.selectedSlot?.id,
      BookingDoneWithSecondaryAccount,
    })
      .then(response => {})
      .catch(error => {
        console.error('Error during adding session to my journey:', error);
      });
  };

  const handleLoginUser = async values => {
    const { email, password, otp } = values;
    const returnedLoginAction = await login(email, password, null, null, null, null, null, null, otp)(dispatch);
    if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
      const pDomain = parentDomain.split(':3000')[0];
      setItem('guestContributionId', null);
      setCookie('guestContributionId', null, pDomain, 30);
      addToMyJourney(false);
    }
  };

  const handleSubmit = () => {
    if (mode === modeModel.confirmation) {
      setTitle('Login to your account');
      setSubmitTitle('Confirm');
      setcancelTitle('Cancel');
      setMode(modeModel.login);
    }
    if (mode === modeModel.login) {
      formRef.current.handleSubmit();
    }
  };
  const handleCancel = () => {
    if (mode === modeModel.confirmation) {
      addToMyJourney(true);
      dispatch(setEasyBookingThankYouData.setData({ ...easyBookingThankYou, showModal: false }));
      setIsOpen(false);
    }
    if (mode === modeModel.login) {
      setTitle('Add session to existing account?');
      setSubmitTitle('Add to my account');
      setcancelTitle('No thanks');
      setMode(modeModel.confirmation);
    }
  };
  const handleShowOtpPopup = () => {
    setShowOtpModal(true);
    const email = user?.email;
    getOTP(email)(dispatch);
  };

  const { domain } = useRouter();
  let colorToUse = determineColorToUse(contribution);
  const PrimaryColor = colorToUse?.PrimaryColorCode;
  const [showOtpModal, setShowOtpModal] = useState(false);
  const closeModal = () => setShowOtpModal(false);
  const { error } = useSelector(state => state.account);

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        title={title}
        applyTheming
        ispassword={mode === modeModel.confirmation}
        mobileView={mobileView}
        submitTitle={submitTitle}
        cancelTitle={cancelTitle}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        shrinkCancelButton
        disableOverlayClick
      >
        {mode === modeModel.confirmation ? (
          <div>
            Would you like to add this session to your existing Cohere account {user?.email}? This allows you to access
            and launch it later inside Cohere. If you decline, you'll still receive a calendar invite and email
            confirmation.
          </div>
        ) : (
          <Formik
            innerRef={formRef}
            initialValues={{ email: user?.email, password: '' }}
            enableReinitialize
            onSubmit={handleLoginUser}
            validationSchema={validationSchema(showOtpModal)}
          >
            {({ errors, touched, handleChange, handleBlur }) => (
              <>
                <StyledFormContainer>
                  <div>
                    <StyledEmail>
                      <span>
                        Please enter your password to add this session to your Cohere account{' '}
                        <span style={{ color: PrimaryColorCode }}>{user?.email}.</span>
                      </span>
                    </StyledEmail>
                  </div>
                  <TextField
                    fullWidth
                    className={classNames.inputField}
                    name="password"
                    placeholder="Enter your password"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                    InputProps={{
                      style: {
                        color: newThemedTextColor,
                        backgroundColor: newThemedBackgroundColor,
                        borderRadius: '10px',
                      },
                      classes: { notchedOutline: classNames.noBorder },
                    }}
                  />
                  {errors && errors.password && touched.password && <StyledError>Incorrect Password</StyledError>}
                </StyledFormContainer>

                <Grid item xs={12} style={{ paddingTop: '8px', marginBottom: '16px' }}>
                  <SendOtpLinkContainer>
                    <SendOtpLink isDarkModeEnabled={contribution.isDarkModeEnabled} onClick={handleShowOtpPopup}>
                      Sign in using email OTP
                    </SendOtpLink>
                    <StyledLink
                      style={{
                        color: newThemedTextColor,
                        fontSize: '14px',
                        fontWeight: 400,
                        fontFamily: 'Avenir',
                      }}
                      onClick={() => redirectToAppIfIsCoachDomain(domain, '/auth/restore-pass')}
                    >
                      I forgot my password
                    </StyledLink>
                  </SendOtpLinkContainer>
                </Grid>
              </>
            )}
          </Formik>
        )}
        {showOtpModal && (
          <SignInWithOtpModal
            isOpen={showOtpModal}
            email={user?.email}
            isDarkModeEnabled={contribution.isDarkModeEnabled}
            formRef={formRef}
            onClose={closeModal}
            accentColorCode={colorToUse?.AccentColorCode}
            primaryColor={PrimaryColor}
            textColor={newThemedTextColor}
            errorMsg={error?.message}
            activeTemplate="TemplateOne"
          />
        )}
      </StyledModal>
    </>
  );
}

ExistingUserGuestContributionAddModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  PrimaryColorCode: PropTypes.string.isRequired,
  newThemedTextColor: PropTypes.string.isRequired,
  newThemedBackgroundColor: PropTypes.string.isRequired,
};

export default ExistingUserGuestContributionAddModal;
