import React, { useState, useEffect, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { cohereAcademyIsEnableSelector } from 'selectors/user';
import { cancelMembership } from 'services/purchase.service';
import * as paidTier from 'selectors/paidTier';
import usePaidTier from 'hooks/usePaidTier';

import { PlanColumnGrid, useStyles } from '../Billing.styles';
import * as paidTierActions from 'actions/paidTierOptions';
import * as paidTierService from 'services/paidTierOptions.service';
import AdvertismentCard from 'components/AdvertismentCard';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useShallowEqualSelector } from 'hooks/index';
import { ContributionType, SIGNUP_TYPES } from 'helpers/constants';
import { PAID_TIER_TITLES } from '../../../../constants';
import { createAccessCode, getBoughtByType, joinContribution } from 'services/contributions.service';
import axiosInstance from 'utils/axiosInstance';

const CohereAcademy = ({ setIsShowAcademyWarning }) => {
  const { academyContributions, activeAcademyContributions } = usePaidTier();
  const classes = useStyles();
  const { signupType } = useSelector(state => state.account);
  const isEnable = useSelector(cohereAcademyIsEnableSelector);
  const { user } = useAccount();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const dispatch = useDispatch();
  const updateActiveContributionsState = useCallback(() => {
    return (
      getBoughtByType(ContributionType.contributionMembership)
        // .then(filterMembership)
        .then(matchedMemberships => {
          dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
        })
    );
  }, []);
  const shouldBeDisabled =
    signupType === SIGNUP_TYPES.TYPE_A ||
    signupType === SIGNUP_TYPES.TYPE_B ||
    user.signupType === SIGNUP_TYPES.TYPE_B ||
    user.signupType === SIGNUP_TYPES.TYPE_A;

  const [isShowLoader, setIsShowLoader] = useState({
    id: null,
    status: false,
  });

  useEffect(() => {
    setIsShowLoader({
      id: null,
      status: false,
    });
  }, [activeAcademyContributions]);

  const enrollMembershipOnclick = async (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    if (isShowLoader.id) {
      return;
    }

    setIsShowLoader({
      id,
      status: true,
    });

    const code = await createAccessCode(id);
    joinContribution({ id, accessCode: code.code })
      .then(() => {
        updateActiveContributionsState();

        setTimeout(() => {
          updateActiveContributionsState().then(() => {
            setIsShowLoader({
              id: null,
              status: false,
            });
          });
        }, 2000);
      })
      .catch(e => {
        setIsShowAcademyWarning(true);
        setIsShowLoader({
          id: null,
          status: false,
        });
      });
  };

  const leaveMembershipOnclick = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    if (isShowLoader.id) {
      return;
    }

    setIsShowLoader({
      id,
      status: true,
    });

    axiosInstance
      .post(`/Contribution/RevokeContributionAccess?contributionId=${id}&myJourneyAccessRevokeOnly=false`)
      .then(() => {
        updateActiveContributionsState().then(() => {
          setIsShowLoader({
            id: null,
            status: false,
          });
        });
      });
  };

  return (
    <PlanColumnGrid>
      {academyContributions.map(item => {
        return (
          <Link
            to={`/contribution-view/${item.id}/about`}
            className={shouldBeDisabled || isEnable ? classes.disableMe : classes.cohereAcademyLink}
            key={item.id}
          >
            <AdvertismentCard
              key={item.id}
              userId={item.userId}
              img={item.image}
              title={item.title}
              author={item.serviceProviderName}
              theme={item?.categories[0]}
              handleEnrollOnclick={event => enrollMembershipOnclick(event, item.id)}
              isActive={activeAcademyContributions.some(el => el.id === item.id)}
              handleLeaveOnclick={event => leaveMembershipOnclick(event, item.id)}
              isShowLoader={item.id === isShowLoader.id ? isShowLoader : null}
              AvatarUrl={item.avatarUrl}
              verticalView
            />
          </Link>
        );
      })}
    </PlanColumnGrid>
  );
};

export default CohereAcademy;
