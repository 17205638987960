import React from 'react';

const ApplicationsIcon = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.9267 19.9055C32.1461 20.6889 30.8778 20.6901 30.0958 19.908L26.1969 16.0092C25.4668 15.2791 25.4668 14.0955 26.1969 13.3654C26.9269 12.6354 28.1106 12.6354 28.8406 13.3654L31.4937 16.0185L38.1207 9.39158C38.8514 8.66089 40.0361 8.66089 40.7668 9.39159C41.4966 10.1214 41.4976 11.3043 40.7691 12.0353L32.9267 19.9055ZM21.125 15.6998C21.125 14.6643 20.2855 13.8248 19.25 13.8248H6.125C5.08947 13.8248 4.25 14.6643 4.25 15.6998C4.25 16.7353 5.08947 17.5748 6.125 17.5748H19.25C20.2855 17.5748 21.125 16.7353 21.125 15.6998ZM38.5531 27.1654C39.2832 26.4353 39.2832 25.2517 38.5531 24.5216C37.8231 23.7916 36.6394 23.7916 35.9094 24.5216L32.375 28.056L28.8406 24.5216C28.1106 23.7916 26.9269 23.7916 26.1969 24.5216C25.4668 25.2517 25.4668 26.4353 26.1969 27.1654L29.7312 30.6997L26.1969 34.2341C25.4668 34.9642 25.4668 36.1478 26.1969 36.8779C26.9269 37.6079 28.1106 37.6079 28.8406 36.8779L32.375 33.3435L35.9094 36.8779C36.6394 37.6079 37.8231 37.6079 38.5531 36.8779C39.2832 36.1478 39.2832 34.9642 38.5531 34.2341L35.0187 30.6997L38.5531 27.1654ZM21.125 30.6997C21.125 29.6642 20.2855 28.8247 19.25 28.8247H6.125C5.08947 28.8247 4.25 29.6642 4.25 30.6997C4.25 31.7353 5.08947 32.5747 6.125 32.5747H19.25C20.2855 32.5747 21.125 31.7353 21.125 30.6997Z"
        fill="#215C73"
      />
    </svg>
  );
};

export default ApplicationsIcon;
