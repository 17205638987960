import React, { useState, useEffect, useMemo } from 'react';
import classes from './Option.module.scss';
import { Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CrossIcon from '../../Assests/Icons/CrossIcon';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import { CKEditor } from 'ckeditor4-react';
import Loader from 'components/UI/Loader';
import { convertToPlainText } from 'utils/utils';

const StyledLabel = styled.div`
  font-family: 'Avenir';
  font-weight: 350;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
`;

const Option = ({ option, parentProvided, questionsIndex, index, setFieldValue, onRemoveChoice, question }) => {
  const [isEditorLoaded, setIsEditorLoaded] = useState(false);
  const [loadingCkEditor, setLoadingCkEditor] = useState(false);
  const hasCorrectAnswer = question?.options?.some(item => item.isCorrect === true);

  useEffect(() => {
    setLoadingCkEditor(false);
    setTimeout(() => {
      setLoadingCkEditor(true);
    }, 500);
  }, [questionsIndex, index]);

  useEffect(() => {
    if (convertToPlainText(option.value).length === 0) {
      setFieldValue(`questions[${questionsIndex}].options[${index}].isCorrect`, false);
    }
  }, [option.value]);

  const toolbarConfig = useMemo(
    () => [
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
      { name: 'links', items: ['Link', 'Unlink'] },
      { name: 'insert', items: ['Image'] },
      { name: 'undo', items: ['Undo', 'Redo'] },
      { name: 'styles', items: ['Font', 'FontSize', '-'] },
      { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'indent', items: ['Indent', 'Outdent'] },
      { name: 'lists', items: ['NumberedList', 'BulletedList'] },
      {
        name: 'paragraph',
        items: [
          'Blockquote',
          'CreateDiv',
          /* 'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock', */
          '-',
          'BidiLtr',
          'BidiRtl',
          'Language',
        ],
      },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
    ],
    [],
  );
  return (
    <Droppable droppableId={`option-${index}`}>
      {(provided, snapshot) => (
        <div {...provided.droppableProps} innerRef={provided.innerRef}>
          {/* {!isEditorLoaded && <Loader />} */}
          <div className={classes.container}>
            <div className={classes.header}>
              <div className={classes.main}>
                <div className={classes.dragicon_container} {...parentProvided.dragHandleProps}>
                  <DragIndicatorIcon className={classes.drag_icon} />
                </div>
                <div className={classes.option_number}>{`Option ${index + 1}`}</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  className={classes.MuiFormControlLabel_root}
                  checked={option?.isCorrect}
                  disabled={convertToPlainText(option.value).length === 0}
                  onChange={() => {
                    setFieldValue(`isSaved`, false);
                    let contentLength = convertToPlainText(option.value);

                    if (contentLength.length > 0) {
                      if (question.canSelectMultipleCorrectOptions) {
                        setFieldValue(`questions[${questionsIndex}].options[${index}].isCorrect`, !option?.isCorrect);
                      } else {
                        if (!hasCorrectAnswer) {
                          let updatedArray = question.options.map(item => ({
                            ...item,
                            isCorrect: false,
                          }));
                          setFieldValue(`questions[${questionsIndex}].options`, updatedArray);
                          setFieldValue(`questions[${questionsIndex}].options[${index}].isCorrect`, !option?.isCorrect);
                        } else if (option?.isCorrect) {
                          setFieldValue(`questions[${questionsIndex}].options[${index}].isCorrect`, !option?.isCorrect);
                        } else {
                          let updatedArray = question.options.map(item => ({
                            ...item,
                            isCorrect: false,
                          }));
                          setFieldValue(`questions[${questionsIndex}].options`, updatedArray);
                          setFieldValue(`questions[${questionsIndex}].options[${index}].isCorrect`, !option?.isCorrect);
                        }
                      }
                    }
                  }}
                  control={
                    <Checkbox
                      classes={{
                        root: classes.checkbox_root,
                      }}
                      color="primary"
                    />
                  }
                  label={<StyledLabel>Correct Answer</StyledLabel>}
                  name="default"
                  // disabled={sender?.isDefault}
                />
                <div
                  className={classes.cross_icon_container}
                  onClick={() => {
                    onRemoveChoice(index);
                  }}
                >
                  <CrossIcon />
                </div>
              </div>
            </div>
            <div className={classes.body}>
              {loadingCkEditor && (
                <CKEditor
                  config={{
                    editorplaceholder: 'Write your quiz option here',
                    allowedContent: true,
                    height: '150px',
                    width: '100%',
                    dialog_noConfirmCancel: true,
                    toolbar: toolbarConfig,
                    extraPlugins: [
                      'font',
                      'colorbutton',
                      'justify',
                      'colordialog',
                      'uploadimage',
                      'filebrowser',
                      'image2',
                      'selectall',
                      'editorplaceholder',
                    ],
                    removeButtons: [],
                  }}
                  initData={option?.value}
                  onChange={event => {
                    setFieldValue(
                      `questions[${questionsIndex}].options[${index}].value`,
                      event?.editor?.getData()?.trim(),
                    );
                    setFieldValue(`isSaved`, false);
                  }}
                />
              )}
            </div>
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default Option;
