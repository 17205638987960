import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

const CreateCheckoutSession = data => axiosInstance.post(`/api/Sms/create-checkout-session`, data).then(get('data'));

const SetSmsCreditsAutoRechargeSettings = (threshHoldPlanId, rechargerPlanId) =>
  axiosInstance
    .post(
      `/api/Sms/SetSmsCreditsAutoRechargeSettings?threshHoldPlanId=${threshHoldPlanId}&rechargerPlanId=${rechargerPlanId}`,
    )
    .then(get('data'));

const SetAutoRechargeOption = autoRecharge =>
  axiosInstance.post(`/api/Sms/SetAutoRechargeOption?autoRecharge=${autoRecharge}`).then(get('data'));
const GetSmsPlans = () => axiosInstance.get(`/api/Sms/GetSmsPlans`).then(get('data'));
const GetSmsCredits = () => axiosInstance.get(`/api/Sms/GetSmsCredits`).then(get('data'));
const GetAutoRechargeSettings = () => axiosInstance.get(`/api/Sms/GetAutoRechargeSettings`).then(get('data'));
const GetSmsPurchase = () => axiosInstance.get(`/api/Sms/GetSmsPurchase`).then(get('data'));

export const SmsBillingServices = {
  CreateCheckoutSession,
  SetSmsCreditsAutoRechargeSettings,
  SetAutoRechargeOption,
  GetSmsPlans,
  GetSmsCredits,
  GetAutoRechargeSettings,
  GetSmsPurchase,
};
