import React from 'react';

export const CrossOutlineIcon = ({ fillColor = '#116582', disabled = false }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.639893" width="23" height="23" rx="11.5" stroke={disabled ? 'rgba(0, 0, 0, 0.38)' : 'black'} />
      <path
        d="M19 6.54989L17.59 5.13989L12 10.7299L6.41 5.13989L5 6.54989L10.59 12.1399L5 17.7299L6.41 19.1399L12 13.5499L17.59 19.1399L19 17.7299L13.41 12.1399L19 6.54989Z"
        fill={disabled ? 'rgba(0, 0, 0, 0.38)' : 'black'}
        fill-opacity="0.87"
      />
    </svg>
  );
};

export const CameraIcon = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16.1719" cy="16.8411" r="16" fill="#CDBA8F" />
      <path
        d="M23.8929 22.0382C23.8929 22.6326 23.4129 23.1121 22.8254 23.1121H9.51858C8.93111 23.1121 8.4511 22.6326 8.4511 22.0382V13.8712C8.4511 13.2769 8.93111 12.7974 9.51858 12.7974H11.1389C12.1115 12.7974 13.0195 12.3103 13.5574 11.5C13.9429 10.9191 14.5938 10.57 15.2909 10.57H17.0531C17.7502 10.57 18.401 10.9191 18.7866 11.5C19.3245 12.3103 20.2325 12.7974 21.2051 12.7974H22.8254C23.4129 12.7974 23.8929 13.2769 23.8929 13.8712V22.0382Z"
        stroke="white"
        stroke-width="0.822118"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1721 15.5205C17.482 15.5205 18.5439 16.5069 18.5439 17.7234C18.5439 18.94 17.482 19.9263 16.1721 19.9263C14.8623 19.9263 13.8003 18.94 13.8003 17.7234C13.8003 16.5069 14.8623 15.5205 16.1721 15.5205Z"
        stroke="white"
        stroke-width="0.822118"
      />
    </svg>
  );
};
