import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info.js';
import styled, { css } from 'styled-components';

import { TOOLTIP } from 'pages/ContributionView/ContributionSessions/constants';
import { colors } from 'utils/styles';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import {
  StyledFlexContainer,
  StyledTooltip,
  StyledInfoIcon,
  PageTitleSecond,
  PageTitleFirst,
} from './SharedStyledComponents';
import useScreenView from 'hooks/useScreenView';

const StyledDivOptional = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* padding-left: 0.5rem; */
`;

const DetailsContainerWithHeader = props => {
  const {
    firstHeading = false,
    secondHeading = false,
    toolTip = false,
    children = null,
    isOptional = false,
    headingStyles = {},
    extraUI = null,
  } = props;
  const { mobileView } = useScreenView();
  return (
    <Grid item xs={12} spacing={4} style={{ width: '100%' }}>
      <Card leadCard>
        <CardHeader {...{ mobileView }}>
          <StyledFlexContainer>
            {firstHeading && (
              <>
                <PageTitleFirst {...{ mobileView, styleOverrides: headingStyles }}>{firstHeading}</PageTitleFirst>
                {isOptional ? <StyledDivOptional>(Optional)</StyledDivOptional> : <></>}
                {extraUI && extraUI}
              </>
            )}
            {secondHeading && (
              <>
                <PageTitleSecond {...{ mobileView, styleOverrides: headingStyles }}>{secondHeading}</PageTitleSecond>
                {isOptional ? <StyledDivOptional>(Optional)</StyledDivOptional> : <></>}
                {extraUI && extraUI}
              </>
            )}
            {toolTip && (
              <StyledTooltip
                title={toolTip}
                arrow
                enterTouchDelay={TOOLTIP.ENTER_DELAY}
                leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
              >
                <StyledInfoIcon htmlColor={colors.lightBrown} />
              </StyledTooltip>
            )}
          </StyledFlexContainer>
        </CardHeader>
        <CardBody {...{ mobileView }}>{children}</CardBody>
      </Card>
    </Grid>
  );
};

export default DetailsContainerWithHeader;
