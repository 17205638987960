import React from 'react';

const PriorTemplateIcon = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 42 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.3706 0.939941C30.5629 0.978403 30.768 1.02968 30.9603 1.06815C34.3321 1.76045 36.6141 4.55533 36.6398 8.11943C36.6654 11.6066 36.6526 15.0938 36.6398 18.581C36.6398 19.5425 36.2167 20.1835 35.4603 20.4399C34.7552 20.6835 33.9988 20.4528 33.5244 19.8758C33.1911 19.4656 33.127 18.9784 33.127 18.4656C33.127 15.0681 33.1141 11.6707 33.127 8.27328C33.127 7.02969 32.7424 5.9784 31.7424 5.19635C31.1013 4.68353 30.3449 4.45276 29.5372 4.45276C22.3706 4.45276 15.2039 4.43994 8.03723 4.45276C6.06287 4.45276 4.62697 5.8502 4.48594 7.79892C4.47312 7.93994 4.47312 8.09379 4.47312 8.23481C4.47312 18.363 4.47312 28.4784 4.47312 38.6066C4.47312 39.8502 4.85774 40.9015 5.87056 41.6835C6.55005 42.2092 7.31928 42.4143 8.16543 42.4143C10.05 42.4143 11.9475 42.4143 13.8321 42.4143C14.6654 42.4143 15.2808 42.8887 15.5372 43.6323C15.768 44.3117 15.5757 45.1194 14.9731 45.5169C14.6398 45.7348 14.2039 45.9015 13.8065 45.9143C11.8706 45.9528 9.93466 45.9399 7.99876 45.9271C4.13979 45.9528 0.921841 42.7989 0.960302 38.6835C1.01158 32.9015 0.973123 27.1066 0.973123 21.3246C0.973123 16.9528 0.973123 12.5938 0.973123 8.22199C0.973123 5.45276 2.11415 3.31174 4.49876 1.9143C5.22953 1.49122 6.10133 1.32456 6.90902 1.02968C7.01158 0.991223 7.12697 0.965582 7.24235 0.939941C14.9603 0.939941 22.6654 0.939941 30.3706 0.939941Z"
        fill="#215C73"
      />
      <path
        d="M18.8065 11.4912C21.7809 11.4912 24.7552 11.4912 27.7296 11.4912C28.9091 11.4912 29.704 12.3246 29.6014 13.44C29.5245 14.2605 28.8578 14.9143 28.0117 15.0041C27.8963 15.0169 27.7809 15.0169 27.6655 15.0169C21.7681 15.0169 15.8578 15.0169 9.96038 15.0169C8.84499 15.0169 8.1014 14.3887 8.02448 13.4015C7.93474 12.3374 8.72961 11.5169 9.85781 11.5041C12.0758 11.4912 14.2809 11.5041 16.4988 11.5041C17.2552 11.4912 18.0373 11.4912 18.8065 11.4912Z"
        fill="#215C73"
      />
      <path
        d="M18.7806 22.0425C15.8063 22.0425 12.8319 22.0425 9.85754 22.0425C8.66523 22.0425 7.88318 21.1579 8.02421 20.0297C8.12677 19.2476 8.7678 18.6322 9.58831 18.5425C9.7037 18.5297 9.81908 18.5297 9.93446 18.5297C15.8575 18.5297 21.7678 18.5297 27.6909 18.5297C28.8191 18.5297 29.5883 19.2348 29.6011 20.2604C29.614 21.2733 28.8447 22.0297 27.7806 22.0425C25.9345 22.0553 24.0883 22.0425 22.2422 22.0425C21.0883 22.0425 19.9345 22.0425 18.7806 22.0425Z"
        fill="#215C73"
      />
      <path
        d="M15.2551 29.081C13.4346 29.081 11.6269 29.0938 9.80636 29.081C8.6397 29.0682 7.85764 28.1451 8.02431 27.0169C8.15252 26.1579 8.88329 25.5682 9.84482 25.5682C11.6397 25.5553 13.4474 25.5682 15.2423 25.5682C17.0628 25.5682 18.8705 25.5553 20.691 25.5682C21.8576 25.581 22.6397 26.5041 22.473 27.6323C22.3448 28.4912 21.6141 29.081 20.6525 29.081C18.8576 29.081 17.05 29.081 15.2551 29.081Z"
        fill="#215C73"
      />
      <path
        d="M31.7675 27.9754C31.7675 27.0845 30.6903 26.6383 30.0603 27.2683L27.2589 30.0697C26.8683 30.4603 26.8683 31.0934 27.2589 31.484L30.0603 34.2854C30.6903 34.9154 31.7675 34.4692 31.7675 33.5783V32.5154C33.3044 32.5154 34.7785 33.126 35.8653 34.2128C36.9521 35.2996 37.5627 36.7736 37.5627 38.3106C37.5627 38.7743 37.5105 39.2265 37.409 39.6612C37.2289 40.433 37.338 41.2849 37.8984 41.8453C38.7201 42.667 40.102 42.5263 40.4958 41.4331C40.8466 40.4595 41.0398 39.4082 41.0398 38.3106C41.0398 33.1877 36.8904 29.0383 31.7675 29.0383V27.9754ZM25.6365 34.7759C24.8148 33.9542 23.433 34.095 23.0391 35.1882C22.6883 36.1618 22.4951 37.213 22.4951 38.3106C22.4951 43.4336 26.6445 47.583 31.7675 47.583V48.6459C31.7675 49.5368 32.8446 49.9829 33.4746 49.353L36.276 46.5515C36.6666 46.161 36.6666 45.5278 36.276 45.1373L33.4746 42.3358C32.8446 41.7059 31.7675 42.152 31.7675 43.0429V44.1058C30.2305 44.1058 28.7564 43.4953 27.6696 42.4085C26.5828 41.3216 25.9722 39.8476 25.9722 38.3106C25.9722 37.8469 26.0244 37.3948 26.1259 36.9601C26.306 36.1882 26.1969 35.3363 25.6365 34.7759Z"
        fill="#215C73"
      />
    </svg>
  );
};

export default PriorTemplateIcon;
