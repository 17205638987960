import {
  SET_ACTIVE_QUIZ,
  SHOW_MODAL_CREATE,
  SHOW_MODAL_CREATE_QUIZ,
  SHOW_MODAL_CREATE_FORM,
  SET_SHOW_CUSTOMIZE_RESULTS,
  SET_LEAD_USER,
  SET_RESPONSE_USER,
  SET_DEFAULT_PAGE,
  SET_TABS_COUNT,
} from 'actions/quizes';

const initialState = {
  isLoading: false,
  error: null,
  activeQuiz: {},
  quizesCollection: [],
  showModal: false,
  showCustomizeResults: false,
  userDetails: {},
  userResponse: {},
  showQuizModal: false,
  showFormModal: false,
  formPage: 'Forms',
  countData: {},
};

const quizesReducer = (state = initialState, action) => {
  switch (action?.type) {
    case SHOW_MODAL_CREATE:
      return { ...state, showModal: action.payload };
    case SET_ACTIVE_QUIZ:
      return { ...state, activeQuiz: action.payload };
    case SET_SHOW_CUSTOMIZE_RESULTS:
      return { ...state, showCustomizeResults: action.payload };
    case SET_LEAD_USER:
      return { ...state, userDetails: action.payload };
    case SET_RESPONSE_USER:
      return { ...state, userResponse: action.payload };
    case SHOW_MODAL_CREATE_QUIZ:
      return { ...state, showQuizModal: action.payload };
    case SHOW_MODAL_CREATE_FORM:
      return { ...state, showFormModal: action.payload };
    case SET_DEFAULT_PAGE:
      return { ...state, formPage: action.payload };
    case SET_TABS_COUNT:
      return { ...state, countData: action.payload };
    default:
      return state;
  }
};

export default quizesReducer;
