import React, { useEffect } from 'react';
import classes from './QuestionTypesPanel.module.scss';
import classNames from 'classnames';

const QuestionTypesPanel = ({ actions, onClose, containerClassName, contentBody, mobileView, type }) => {
  let containerClass = contentBody ? classes.content_container : classes.container;

  if (containerClassName) {
    containerClass = `${containerClass} ${containerClassName}`;
  }

  useEffect(() => {
    document.addEventListener('click', onClose);
    return () => {
      document.removeEventListener('click', onClose);
    };
  }, []);

  return (
    <div className={classNames(containerClass, { [classes.container_responsive]: mobileView && !contentBody })}>
      {actions.map((action, index) => {
        const { name, icon, onClick } = action;
        const isLast = index === actions.length - 1;
        return (
          <div className={`${classes.action} ${isLast ? classes.last_item : ''}`} onClick={onClick}>
            <div className={classes.icon}>{icon}</div>
            {name === 'Short Text' ? (
              <div className={type === 'ShortText' ? classes.action_name_selected : classes.action_name}>{name}</div>
            ) : name === 'Multiple Choice' ? (
              <div className={type === 'MultipleChoice' ? classes.action_name_selected : classes.action_name}>
                {name}
              </div>
            ) : (
              <div className={type === name ? classes.action_name_selected : classes.action_name}>{name}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default QuestionTypesPanel;
