import React from 'react';
import classes from '../ResultPage.module.scss';
import Button from 'components/FormUI/Button';
import { useSelector } from 'react-redux';
import { redirectTo } from 'services/links';
import useAccount from 'hooks/useAccount';
import { isEmpty } from 'lodash';
import useRouter from 'hooks/useRouter';
import { lightOrDark } from 'utils/utils';
import { CONTRIBUTION_COLORS } from '../../../../../constants';

export default function FailedResult({ resultObtained, isNoGradingResult }) {
  const {
    activeQuiz: { failResultData, passingPercentage, noResultData, id, isBrandingColorEnabled, brandingColors },
    userDetails,
  } = useSelector(state => state?.quizes);
  const { PrimaryColorCode, AccentColorCode, TextColorCode } = brandingColors ?? CONTRIBUTION_COLORS;
  const textColor =
    TextColorCode === 'Auto' ? lightOrDark(PrimaryColorCode) : TextColorCode === '#000000' ? '#000000' : '#FFFFFF';
  const { user } = useAccount();
  const { history, query } = useRouter();

  function ensureHttps(url) {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }
    return url;
  }

  // Utility to replace placeholders with actual values in UI
  const renderTextWithPlaceholders = (text, placeholders) => {
    return text.split(placeholders[0]).reduce((acc, part, index) => {
      if (index === 0) return [part];
      return acc.concat(<span key={index}>{placeholders[1]}</span>, part);
    }, []);
  };

  const headerText = failResultData?.headerText || '';
  const headerText1 = noResultData?.percentageText || '';
  const needText = failResultData?.percentageText || '';

  return isNoGradingResult ? (
    <div className={classes.container}>
      {noResultData.isImageEnabled && (
        <picture>
          <img className={classes.image_container} width="100%" src={noResultData.image} alt="" />
        </picture>
      )}

      <div className={noResultData.isImageEnabled ? classes.completing_text : classes.noimagecompleting_text}>
        {noResultData.headerText}
      </div>
      <div className={classes.passedText}>
        {renderTextWithPlaceholders(headerText1, ['{ScoredPercentage}', `${resultObtained.percentage}%`])}
      </div>
      <div className={classes.statsText}>
        You answered {resultObtained.correctAnswers} out of {resultObtained.totalQuestions} questions correctly
      </div>
      {noResultData.isCutomButtonEnabled ? (
        <div className={classes.buttons_container_failed}>
          <Button
            backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
            textColor={isBrandingColorEnabled ? textColor : null}
            style={{ marginLeft: '10px' }}
            variant="secondary"
            autoWidth
            onClick={() => {
              redirectTo(ensureHttps(noResultData.buttonLink));
            }}
          >
            {noResultData.buttonText}
          </Button>
        </div>
      ) : (
        <div className={classes.buttons_container_failed}>
          {failResultData.isCutomButtonEnabled ? (
            <Button
              backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
              textColor={isBrandingColorEnabled ? textColor : null}
              style={{ marginLeft: '10px' }}
              variant="secondary"
              autoWidth
              onClick={() => {
                redirectTo(ensureHttps(failResultData.buttonLink));
              }}
            >
              {failResultData.buttonText}
            </Button>
          ) : (
            !isEmpty(user) && (
              <Button
                backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
                textColor={isBrandingColorEnabled ? textColor : null}
                style={{ marginLeft: '10px' }}
                variant="secondary"
                autoWidth
                onClick={() => {
                  history.replace('/dashboard');
                }}
              >
                Complete
              </Button>
            )
          )}
        </div>
      )}
    </div>
  ) : (
    <>
      <div className={classes.container}>
        {failResultData.isImageEnabled && (
          <picture>
            <img className={classes.image_container} width="100%" src={failResultData.image} alt="" />
          </picture>
        )}
        <div className={failResultData.isImageEnabled ? classes.passedText : classes.noImagePassedText}>
          {renderTextWithPlaceholders(headerText, [
            '{ScoredPercentage}',
            <span className={classes.failedAge}>{resultObtained.percentage}%</span>,
          ])}
        </div>
        <div className={classes.needText}>
          {renderTextWithPlaceholders(needText, ['{PassingPercentage}', `${passingPercentage}%`])}
        </div>
        <div className={classes.statsText}>
          You answered {resultObtained.correctAnswers} out of {resultObtained.totalQuestions} questions correctly
        </div>

        <div className={classes.buttons_container_failed}>
          <Button
            variant="secondary"
            autoWidth
            backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
            textColor={isBrandingColorEnabled ? textColor : null}
            onClick={() => {
              if (isEmpty(userDetails) && isEmpty(user)) {
                history.replace(`/quiz-view/${id}`);
              } else {
                history.replace(`/quiz-view/${id}/quiz`);
              }
            }}
          >
            Retake
          </Button>
          {failResultData.isCutomButtonEnabled ? (
            <Button
              backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
              textColor={isBrandingColorEnabled ? textColor : null}
              style={{ marginLeft: '10px' }}
              variant="secondary"
              autoWidth
              onClick={() => {
                redirectTo(ensureHttps(failResultData.buttonLink));
              }}
            >
              {failResultData.buttonText}
            </Button>
          ) : (
            !isEmpty(user) && (
              <Button
                backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
                textColor={isBrandingColorEnabled ? textColor : null}
                style={{ marginLeft: '10px' }}
                variant="secondary"
                autoWidth
                onClick={() => {
                  history.replace('/dashboard');
                }}
              >
                Complete
              </Button>
            )
          )}
        </div>
      </div>
    </>
  );
}
