import React, { useState } from 'react';
import classes from './Condition.module.scss';
import { TextField, Select, OutlinedInput, MenuItem } from '@material-ui/core';
import { DeleteIcon } from 'pages/Profile/components/icons/Delete';
import Toggle from '../../../Components/Toggle/Toggle';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { useSelector } from 'react-redux';

const Contributions = ['Abc', 'Efg', 'Hij', 'Klm', 'Xyz'];

const Condition = props => {
  const { segmentGroupIndex, condition, tags, formProps, setSegmentError } = props;
  const { tagName, tagExists, tagId } = condition || {};
  const errorState = useSelector(state => state?.contributions?.errorState);
  const [deleteRow, setDelete] = useState(false);
  const onDeleteClick = segmentGroupIndex => {
    if (
      formProps.values.segmentConditions === 1 //if there is only one segment group and that has only one condition, we can't delete condition
    ) {
      return;
    } else if (formProps.values.segmentConditions.length > 1) {
      let newArray = [...formProps.values.segmentConditions]; // Create a copy of the array to avoid mutating the original
      let updatedArray = newArray.splice(segmentGroupIndex, 1);
      console.log('NewArray----->', newArray, updatedArray);
      setDelete(true);
      formProps.setFieldValue('segmentConditions', newArray);
      setTimeout(() => {
        setDelete(false);
      }, 500);
      return;
    }
  };

  const handleToggleCheck = val => {
    formProps.setFieldValue(`conditionCheck`, val);
  };

  const handleTagNameChange = e => {
    formProps.setFieldValue(`segmentConditions[${segmentGroupIndex}].tagName`, e.target.value);
  };

  const handleTagExistsChange = e => {
    formProps.setFieldValue(`segmentConditions[${segmentGroupIndex}].tagExists`, e.target.value);
    setSegmentError(null);
  };

  const handleContributionNameChange = e => {
    formProps.setFieldValue(`segmentConditions[${segmentGroupIndex}].tagId`, e.target.value);
    setSegmentError(null);
  };

  console.log('first', formProps.values.segmentConditions);
  return (
    <>
      {segmentGroupIndex > 0 && (
        <div className={classes.toggleContainer}>
          <Toggle value={formProps.values.conditionCheck} onChange={handleToggleCheck} />
        </div>
      )}
      <div className={classes.conditionContainer}>
        <div className={classes.fieldContainer}>
          <TextField
            className={classes.tagNameInput}
            id="outlined-basic"
            variant="outlined"
            disabled
            fullWidth
            // placeholder="Title"
            value={tagName}
            // onChange={handleTagNameChange}
            onChange={() => {}}
            error={
              (formProps.values.segmentConditions[segmentGroupIndex].tagName === '' && errorState) ||
              (formProps.values.segmentConditions[segmentGroupIndex].tagName.length === 0 &&
                'Please enter a title' &&
                errorState)
            }
            // helperText={
            //   formProps.values.segmentConditions[segmentGroupIndex].tagName === '' || formProps.values.segmentConditions[segmentGroupIndex].tagName.length === 0 &&
            //   "Please enter a title"
            // }
          />
          {formProps.values.segmentConditions[segmentGroupIndex].tagName === '' && errorState && (
            <CommonErrorMessage color={'red'} message={'Please enter a title'} align={'left'} />
          )}
        </div>
        <div className={classes.fieldContainer}>
          <Select
            className={classes.tagExistsSelect}
            style={{ backgroundColor: 'white' }}
            label=""
            name="tagExists"
            variant="outlined"
            fullWidth
            defaultValue={tagExists}
            input={<OutlinedInput margin="dense" />}
            onChange={handleTagExistsChange}
          >
            {['Exists', 'Does Not Exist']?.map(value => {
              return <MenuItem value={value}>{value}</MenuItem>;
            })}
          </Select>
        </div>
        {deleteRow === false ? (
          <div className={classes.fieldContainer}>
            <Select
              className={classes.contributionNameSelect}
              style={{ backgroundColor: 'white' }}
              label=""
              // name="tagId"
              variant="outlined"
              fullWidth
              defaultValue={formProps.values.segmentConditions[segmentGroupIndex].tagId}
              input={<OutlinedInput margin="dense" />}
              onChange={handleContributionNameChange}
            >
              {tags?.map(value => {
                return <MenuItem value={value.id}>{value.name}</MenuItem>;
              })}
            </Select>
            {formProps.values.segmentConditions[segmentGroupIndex].tagId === '' && errorState && (
              <CommonErrorMessage color={'red'} message={'Please select a tag'} align={'left'} />
            )}
          </div>
        ) : (
          <div className={classes.fieldContainer}></div>
        )}
        {formProps.values.segmentConditions.length > 1 && (
          <div
            className={classes.deleteBtnContainer}
            onClick={() => {
              onDeleteClick(segmentGroupIndex);
            }}
          >
            <DeleteIcon className={classes.deletBtn} />
          </div>
        )}
      </div>
    </>
  );
};

export default Condition;
