import React, { useCallback, useState, useEffect } from 'react';
import classes from './Resultwithoutgrade.module.scss';
import { SlimStyledSlider, StyledSlider, SlimSwitch, Switch, StyledInput } from 'components/switch/style';
import QuizImage from '../../QuizContent/Components/QuizImage/QuizImage';
import { useAccount, useHttp } from 'hooks/index';
import axiosInstance from 'utils/axiosInstance';
import { EditableTextField } from '../../QuizContent/Components/EditableTextField/EditableTextField';
import Button from 'components/FormUI/Button';
import TextField from 'components/UI/TextField';
import classNames from 'classnames';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';

const ResultWithOutGrade = ({ noResultData, setFieldValue, mobileView }) => {
  const [imgUrl, setImgUrl] = useState(null);
  const [thankyouMsg, setThankyouMsg] = useState('Thanks for completing this Quiz!');
  const [scorePercentage, setScorePercentage] = useState('You passed it with a score of');
  const [percentageValue, setPercentageValue] = useState('[%]');
  const { request, loading } = useHttp();
  const { user } = useAccount();

  const handleUploadFile = useCallback(({ target: { files } }) => {
    const formData = new FormData();

    formData.append('file', files[0]);

    request('/Content/AddAvatar', 'POST', formData, {
      'Content-Type': 'multipart/form-data',
    })
      .then(res => {
        setFieldValue('isSaved', false);
        setImgUrl(res);
        setFieldValue('noResultData', {
          ...noResultData,
          isImageEnabled: true,
          image: res,
        });
      })
      .catch(console.dir);
    // .finally(updateUserImg);
  }, []);

  const fieldAvatarUrl = 'AvatarUrl';
  const fieldValue = null;
  const fieldinUserCollection = true;

  const onDeleteClick = useCallback(async () => {
    try {
      const res = await axiosInstance.post(
        `Content/RemoveImagesFromS3BucketAndAccountPage?imageUrl=${imgUrl}&field=${fieldAvatarUrl}&fieldValue=${fieldValue} &userId=${user?.id}&inUserCollection=${fieldinUserCollection}`,
      );
      setFieldValue('isSaved', false);
      setFieldValue('noResultData', {
        ...noResultData,
        image: null,
      });
    } catch (error) {
      console.error(error);
    }
  });

  const handleThankNoteChange = e => {
    e.stopPropagation();
    setThankyouMsg(e.target.value);
    setFieldValue('isSaved', false);
    setFieldValue('noResultData', {
      ...noResultData,
      headerText: e.target.value,
    });
  };

  const handlePercentageChange = e => {
    setFieldValue('isSaved', false);
    e.stopPropagation();
    setScorePercentage(e.target.value);
    setFieldValue('noResultData', {
      ...noResultData,
      percentageText: e.target.value,
    });
  };

  useEffect(() => {
    if (
      noResultData?.isCutomButtonEnabled &&
      (noResultData?.buttonLink?.length === 0 || noResultData?.buttonLink === null)
    ) {
      setFieldValue('isSaved', true);
    }
    if (noResultData?.isCutomButtonEnabled && noResultData?.buttonText?.length === 0) {
      setFieldValue('isSaved', true);
    }
    if (noResultData?.isImageEnabled && (noResultData?.image === null || noResultData?.image?.length === 0)) {
      setFieldValue('isSaved', true);
    }
    if (noResultData?.percentageText?.length === 0) {
      setFieldValue('isSaved', true);
    }
    if (noResultData?.headerText?.length === 0) {
      setFieldValue('isSaved', true);
    }
  }, [noResultData]);

  return (
    <div className={classes.container}>
      <div className={classes.image_toggle_container}>
        <div className={classes.image_toggle}>
          <div className={classes.heading}>Add Custom Image</div>
          <Switch className={classes.toggle_button}>
            <StyledInput
              type="checkbox"
              onClick={() => {
                setFieldValue('isSaved', false);
                setFieldValue('noResultData', {
                  ...noResultData,
                  isImageEnabled: !noResultData.isImageEnabled,
                });
              }}
              checked={noResultData.isImageEnabled}
            />
            <StyledSlider className="slider round" />
          </Switch>
        </div>
      </div>
      {noResultData.isImageEnabled && (
        <div className={classes.image_container}>
          <QuizImage
            url={noResultData.image}
            onUpload={handleUploadFile}
            onDelete={onDeleteClick}
            isUploading={loading}
          />
        </div>
      )}

      {noResultData?.isImageEnabled && (
        <div className={classes.photo_size}>Photo should be atleast 500x500px and upto 2 mb.</div>
      )}
      <EditableTextField value={noResultData.headerText} onChange={handleThankNoteChange} mobileView={mobileView} />
      <EditableTextField
        className={classNames(classes.score_percentage, { [classes.score_percentage_responsive]: mobileView })}
        value={noResultData.percentageText}
        onChange={handlePercentageChange}
        mobileView={mobileView}
      />
      <div className={classNames(classes.correct_answers, { [classes.correct_answers_responsive]: mobileView })}>
        You answered [no. of correct answers] out of [total no. of questions] questions correctly
      </div>

      <div className={classNames(classes.action_btns, { [classes.action_btns_responsive]: mobileView })}>
        {noResultData.isCutomButtonEnabled ? (
          <Button className={classes.button_btn} autoWidth>
            {noResultData.buttonText}
          </Button>
        ) : (
          <Button className={classes.button_btn} autoWidth>
            Complete
          </Button>
        )}
      </div>
      <div className={classes.image_toggle_container}>
        <div className={classes.image_toggle}>
          <div className={classes.heading}>Customize Button</div>
          <Switch className={classes.toggle_button}>
            <StyledInput
              type="checkbox"
              onClick={() => {
                setFieldValue('isSaved', false);
                setFieldValue('noResultData', {
                  ...noResultData,
                  isCutomButtonEnabled: !noResultData?.isCutomButtonEnabled,
                });
              }}
              checked={noResultData.isCutomButtonEnabled}
            />
            <StyledSlider className="slider round" />
          </Switch>
        </div>
      </div>
      {noResultData?.isCutomButtonEnabled && (
        <div className={classes.btn_text_input}>
          <TextField
            fullWidth
            placeholder="Button Text"
            variant="outlined"
            inputClasses={{
              root: classes.input_root,
              notchedOutline: classes.input_notchedOutline,
              focused: classes.input_focused,
            }}
            value={noResultData?.buttonText}
            onChange={e => {
              setFieldValue('isSaved', false);
              setFieldValue('noResultData', {
                ...noResultData,
                buttonText: e.target.value,
              });
            }}
          />
          {noResultData.buttonText.length === 0 && (
            <CommonErrorMessage message="Customize button text should not be empty" />
          )}
        </div>
      )}
      {noResultData?.isCutomButtonEnabled && (
        <div className={classes.btn_link_input}>
          <TextField
            fullWidth
            placeholder="Enter Custom Redirect URL"
            variant="outlined"
            inputClasses={{
              root: classes.input_root,
              notchedOutline: classes.input_notchedOutline,
              focused: classes.input_focused,
            }}
            value={noResultData.buttonLink}
            onChange={e => {
              setFieldValue('isSaved', false);
              setFieldValue('noResultData', {
                ...noResultData,
                buttonLink: e.target.value,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ResultWithOutGrade;
