import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useHeader, useEndVideoChat } from 'hooks';
import { fetchUpcomingCreated } from 'actions/contributions';

import Loader from 'components/UI/Loader';
import Banner from 'components/Banner/Banner';
import ContributionsTable from 'components/Tables/Contributions';
import { useState } from 'react';

const AllCoursesPage = ({
  contributionscount,
  contributions,
  closestClassForBanner,
  getContributions,
  loading,
  userId,
}) => {
  const [prevContributions, setPrevContributions] = useState(contributions);
  useEffect(() => {
    if (!loading) {
      setPrevContributions(contributions);
    }
  }, [contributions, loading]);
  const [pagination, setpagination] = useState(0);
  useHeader('My Contributions');

  const handlePageNumber = useCallback(page => {
    setpagination(page);
  }, []);

  const renderList = useCallback(async () => {
    getContributions(userId);
  }, [getContributions, userId]);

  const { Popup } = useEndVideoChat(renderList);

  useEffect(() => {
    renderList();
  }, [renderList]);

  if ((!prevContributions || !prevContributions?.length) && !loading) {
    return <div>There are no contributions yet.</div>;
  }

  return (
    <>
      {loading && <Loader />}
      <Banner type="closestSession" closestClassForBanner={closestClassForBanner} />
      {prevContributions?.length > 0 && (
        <ContributionsTable
          totalCount={contributionscount}
          rows={loading ? prevContributions : contributions}
          childpageNumber={handlePageNumber}
          isAll
        />
      )}
      <Popup />
    </>
  );
};

const mapStateToProps = ({ upcomingCreated: { loading, error, upcomingCreated }, account }) => ({
  userId: account?.user?.id,
  loading,
  error,
  contributionscount: upcomingCreated?.totalCount || null,
  contributions: upcomingCreated?.contributionsForTable || [],
  closestClassForBanner: upcomingCreated?.closestClassForBanner || {},
});

const actions = {
  getContributions: fetchUpcomingCreated,
};

AllCoursesPage.propTypes = {
  userId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  contributions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getContributions: PropTypes.func.isRequired,
  closestClassForBanner: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, actions)(AllCoursesPage);
