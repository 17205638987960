import React from 'react';
import classes from './MainContainer.module.scss';
import Header from 'components/UI/Header';
import styled from 'styled-components';
import useRouter from 'hooks/useRouter';
import Notification from 'pages/CreateCampaign/Components/Notification/Notification';
import { isEmpty } from 'lodash';
import useCampaign from 'pages/CreateCampaign/Hooks/useCampaign';

const StyledMainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  height: 100vh;
  position: relative;
`;

const StyledMainSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${({ mobileView }) => (mobileView ? '30px 16px' : '1px 0px')};
`;
const StickyPortion = styled.div`
  ${({ isSticky }) =>
    isSticky &&
    `
  position: sticky;
  top: 0;
  z-index: 20;  
`}
`;
const CreateQuizMain = ({ headerProps, children }) => {
  const { pathname } = useRouter();
  const { campaignNotification } = useCampaign();
  const isQuizPreview = pathname?.includes('/create-quiz/preview');
  const isFormPreview = pathname?.includes('/create-form/preview');

  return (
    <StyledMainContainer>
      <StickyPortion isSticky={true}>
        <Header
          isQuizPreview={isQuizPreview}
          isFormPreview={isFormPreview}
          backButtonText="Home"
          isCustomPrevButton
          {...headerProps}
        />
      </StickyPortion>
      <StyledMainSection>
        {
          <div style={{ zIndex: 100 }}>
            {!isEmpty(campaignNotification) && <Notification notification={campaignNotification} />}
          </div>
        }
        {children}
      </StyledMainSection>
    </StyledMainContainer>
  );
};

export default CreateQuizMain;
