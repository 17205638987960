const {
  GET_CONTACTS_REQUEST,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_ERROR,
  SET_LEADS,
  SET_CLIENTS,
  GET_TAGS_REQUEST,
  GET_TAGS_ERROR,
  GET_TAGS_SUCCESS,
  GET_USER_TEMPLATES,
  SET_CONTACTS,
} = require('actions/contacts');

const initialState = {
  isLoading: false,
  error: '',
  leads: [],
  clients: [],
  contacts: [],
  tags: {
    isLoading: false,
    error: '',
    tags: [],
  },
  userTemplates: [],
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS_REQUEST:
      return { ...state, isLoading: true };
    case GET_TAGS_REQUEST:
      return { ...state, tags: { ...state.tags, isLoading: true } };
    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contacts: action.payload,
        leads: action.payload.filter(contact => contact?.isLead && !contact?.isDeleted),
        clients: action.payload.filter(contact => !contact?.isLead && !contact?.isDeleted),
      };
    case SET_CONTACTS:
      const updatedContact = action.payload;
      const updatedContacts = state.contacts.map(item => {
        return item.id === updatedContact.id ? updatedContact : item;
      });
      return { ...state, contacts: updatedContacts };
    case SET_LEADS:
      return { ...state, isLoading: false, leads: action.payload };
    case SET_CLIENTS:
      return { ...state, isLoading: false, clients: action.payload };
    case GET_TAGS_SUCCESS:
      return { ...state, tags: { ...state.tags, isLoading: false, tags: action.payload } };
    case GET_CONTACTS_ERROR:
      return { ...state, isLoading: false, error: action.payload, contacts: [], clients: [], leads: [] };
    case GET_TAGS_ERROR:
      return { ...state, tags: { ...state.tags, isLoading: false, error: action.payload, tags: [] } };
    case GET_USER_TEMPLATES:
      return { ...state, userTemplates: action.payload };
    default:
      return state;
  }
};

export default contactsReducer;
