import React from 'react';
import classes from './Response.module.scss';
import ResultIcon from '../../../Assests/Icons/ResultIcon';

const Response = () => {
  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <ResultIcon />
      </div>
      <div className={classes.type_name}>Result</div>
    </div>
  );
};

export default Response;
