import { useMediaQuery, useTheme } from '@material-ui/core';

const useScreenView = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return {
    mobileView,
  };
};

export default useScreenView;
