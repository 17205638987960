import React from 'react';
import classes from './TabsHeader.module.scss';
import Button from 'components/FormUI/Button';

const TabsHeader = ({ tabs, activeTab }) => {
  return (
    <div className={classes.container}>
      {tabs?.map(item => {
        const { name, onClick } = item;
        const isActive = activeTab === name;
        return (
          <div className={`${classes.tab_container} ${isActive ? classes.active_tab_container : ''}`}>
            <Button
              className={`${classes.tab_btn} ${isActive ? classes.active_tab_btn : ''}`}
              variant="contained"
              value={item}
              onClick={() => {
                onClick(name);
              }}
            >
              {name}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default TabsHeader;
