import Modal from 'components/UI/Modal';
import React, { useEffect, useState } from 'react';
import mailIcon from 'assets/form.png';
import refreshMailIcon from 'assets/quiz.png';
import formScratch from 'assets/formScratch.png';
import priorForm from 'assets/priorForm.png';
import scoreQuiz from 'assets/scoreQuiz.png';
import pQuiz from 'assets/pquiz.png';
import classes from './CreateQuizesModal.module.scss';
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import TextField from 'components/UI/TextField';
import { MenuItem, OutlinedInput, Select, Tooltip, Paper } from '@material-ui/core';
import { createUpdateQuiz, getQuizFormById, getQuizzesByType } from '../../../Services/CreateQuizes.service';
import useRouter from 'hooks/useRouter';
import useAccount from 'hooks/useAccount';
import { setActiveQuiz } from 'actions/quizes';
import FormIcon from './Assests/Icons/Form';
import ApplicationsIcon from './Assests/Icons/Applications';
import FromScratchIcon from './Assests/Icons/FromScratch';
import PriorTemplateIcon from './Assests/Icons/PriorTemplate';
import ScoredQuizIcon from './Assests/Icons/ScoredQuiz';
import PersonalityQuizIcon from './Assests/Icons/PersonalityQuiz';
import Loader from 'components/UI/SmallLoader';
import { formatTime } from 'utils/utils';

const LabelInput = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
`;
export const StyledInput = styled(TextField)`
  height: 48px;
  & * {
    font-size: 1rem;
    font-weight: 400;
  }
  .MuiInputBase-root,
  .MuiInputBase-input {
    font-size: 1rem;
  }
  .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
  }
  .MuiFormHelperText-root {
    margin-right: 0;
  }
  .MuiFormHelperText-root.Mui-error {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .MuiFormHelperText-contained {
    font-size: 0.75rem !important;
    font-weight: normal !important;
  }
`;
// const CustomPaper = styled(Paper)(({ mobileView }) => ({
//   top: 'unset !important',
//   bottom: mobileView ? '70px' : '10px',
//   height: mobileView ? '375px' : '265px',
// }));

const CreateQuizesModal = ({ isOpen, handleClose, handleSubmit, type = null, showCreateModal, setShowCreateModal }) => {
  const theme = useTheme();
  const { user, currentRole } = useAccount();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isQuizSelected, setIsQuizSelected] = useState(false);
  console.log('isQuizSelected ==> ', isQuizSelected);
  const [showQuizorFormModal, setShowQuizorFormModal] = useState(false);
  const [isScratchSelected, setIsScratchSelected] = useState(true);
  const [isScoreQuizSelected, setIsScoreQuizSelected] = useState(true);
  const [isCreateScoredQuiz, setIsCreateScoredQuiz] = useState(false);
  const [priorTemplateSelected, setPriorTemplateSelected] = useState(false);
  const [formPriorScratchSelected, setFormPriorScratchSelected] = useState(false);
  const [quizPriorScratchSelected, setQuizPriorScratchSelected] = useState(false);
  const [priorQuizesFormsList, setPriorQuizesFormsList] = useState(null);
  const [formQuizTitle, setFormQuizTitle] = useState('');
  const [priorformQuizSelected, setPriorformQuizSelected] = useState(0);
  const [showPriorTitleModal, setShowPriorTitleModal] = useState(false);
  const [priorTemplateData, setPriorTemplateData] = useState(null);
  const { showModal, showQuizModal, showFormModal } = useSelector(state => state.quizes);
  const { location, history } = useRouter();
  useEffect(() => {
    setIsQuizSelected(false);
    setShowQuizorFormModal(false);
    setIsScratchSelected(true);
    setIsScoreQuizSelected(true);
    setIsCreateScoredQuiz(false);
    setQuizPriorScratchSelected(false);
    setShowPriorTitleModal(false);
    setFormQuizTitle('');
    setFormPriorScratchSelected(false);
    setPriorformQuizSelected(0);
    if (type === 'Quiz') {
      setIsQuizSelected(true);
      setShowQuizorFormModal(false);
      setIsScratchSelected(true);
      setIsScoreQuizSelected(true);
      setIsCreateScoredQuiz(false);
      setQuizPriorScratchSelected(false);
      setShowPriorTitleModal(false);
      setFormQuizTitle('');
      setFormPriorScratchSelected(false);
      setPriorformQuizSelected(0);
    }
  }, [showModal, showQuizModal, showFormModal]);

  useEffect(() => {
    if (formPriorScratchSelected && isScratchSelected === false) {
      getPriorFormsQuizes('Form');
    } else if (quizPriorScratchSelected && isScratchSelected === false) {
      getPriorFormsQuizes('Quiz');
    }
  }, [formPriorScratchSelected, quizPriorScratchSelected, isScratchSelected]);

  const getPriorFormsQuizes = type => {
    getQuizzesByType(type).then(res => {
      setPriorQuizesFormsList(res);
    });
  };

  const getQuizForm = id => {
    getQuizFormById(id).then(res => {
      setPriorTemplateData(res);
      setFormQuizTitle(res.title);
    });
  };

  const createQuiz = () => {
    let formBody;
    if (isScratchSelected) {
      formBody = {
        title: formQuizTitle,
        userId: user.id,
        useCase: 'StandAlone',
        type: isQuizSelected ? 'Quiz' : 'Form',
        quizType: isQuizSelected ? 'Scored' : undefined,
        isBrandingColorEnabled: true,
      };
    } else {
      let updatedQuestions = priorTemplateData.questions.map(question => ({
        ...question,
        isDraft: question.isDraft,
      }));
      formBody = {
        ...priorTemplateData,
        id: null,
        questions: updatedQuestions,
        isPublished: false,
        title: formQuizTitle,
        userId: user.id,
        useCase: 'StandAlone',
        isBrandingColorEnabled: true,
        type: isQuizSelected ? 'Quiz' : 'Form',
        quizType: isQuizSelected ? 'Scored' : undefined,
      };
    }

    createUpdateQuiz(formBody).then(res => {
      dispatch(setActiveQuiz(res));
      if (res?.type === 'Quiz') {
        history.push('/create-quiz/content', { from: 'verify' });
      } else {
        history.push('/create-form/content', { from: 'verify' });
      }

      handleClose();
    });
  };

  const getDateTitle = dateString => {
    /* console.log('dateString => ', dateString); */
    if (!mobileView) {
      if (dateString?.length <= 60) {
        return dateString;
      } else {
        return dateString.substring(0, 60) + '...';
      }
    } else {
      return dateString?.length <= 30 ? dateString : dateString.substring(0, 30) + '...';
    }
  };
  return (
    <>
      {formPriorScratchSelected || quizPriorScratchSelected || showPriorTitleModal ? (
        <></>
      ) : !showQuizorFormModal && type === null ? (
        <Modal
          isCreatingContribution={true}
          brandingColor={false}
          submitTitle={'Next'}
          maxWidth={'900px'}
          title={'What Would You Like to Create?'}
          isOpen={isOpen}
          onCancel={handleClose}
          onSubmit={() => {
            setShowQuizorFormModal(true);
            setIsScratchSelected(true);
          }}
          hiddenCancel={true}
        >
          <div className={classes.form_body}>
            <div className={classes.form_container}>
              <div className={classes.cards_container}>
                <div
                  className={`${classes.pre_design_card} ${isQuizSelected === false ? classes.selected : ''}`}
                  onClick={() => {
                    setIsQuizSelected(false);
                  }}
                >
                  <FormIcon />
                  {/* <img src={mailIcon} /> */}
                  <p className={classes.heading}>Form</p>
                  <p className={classes.text}>You can use this to create a form or survey for your clients.</p>
                </div>
                <div
                  className={`${classes.pre_design_card} ${isQuizSelected === true ? classes.selected : ''}`}
                  onClick={() => {
                    setIsQuizSelected(true);
                  }}
                >
                  <ApplicationsIcon />
                  {/* <img src={refreshMailIcon} /> */}
                  <p className={classes.heading}>Quiz</p>
                  <p className={classes.text}>Test your client’s knowledge or create a personality quiz.</p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}
      {formPriorScratchSelected || quizPriorScratchSelected || showPriorTitleModal ? (
        <></>
      ) : (showQuizorFormModal || type === 'form') && !isQuizSelected ? (
        <Modal
          isCreatingContribution={true}
          brandingColor={false}
          submitTitle={'Next'}
          maxWidth={'900px'}
          title={'Create a Form'}
          isOpen={isOpen}
          ignoreDisable={true}
          onCancel={() => {
            setShowQuizorFormModal(false);
            handleClose();
          }}
          isBackButton={true}
          onBack={() => {
            if (type === 'form') {
              handleClose();
            }
            setShowQuizorFormModal(false);
          }}
          modalType={'Quizes'}
          onSubmit={() => {
            if (isScratchSelected === false) {
              setFormPriorScratchSelected(true);
              setPriorTemplateSelected(true);
            } else {
              setFormPriorScratchSelected(true);
              setPriorTemplateSelected(false);
            }
          }}
          cancelTitle={'Back'}
          hiddenCancel={false}
        >
          <div className={classes.form_body}>
            <div className={classes.form_container}>
              <div className={classes.cards_container}>
                <div
                  className={`${classes.scratch_card_quiz} ${isScratchSelected ? classes.selected : ''}`}
                  onClick={() => {
                    setIsScratchSelected(true);
                  }}
                >
                  <FromScratchIcon />
                  {/* <img src={formScratch} /> */}
                  <p className={classes.heading}>Create From Scratch</p>
                </div>
                <div
                  className={`${classes.pre_design_card_quiz} ${!isScratchSelected ? classes.selected : ''}`}
                  onClick={() => {
                    setIsScratchSelected(false);
                  }}
                >
                  <PriorTemplateIcon />
                  {/* <img src={priorForm} /> */}
                  <p className={classes.heading}>Choose a Prior Template</p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}
      {formPriorScratchSelected || quizPriorScratchSelected || showPriorTitleModal ? (
        <></>
      ) : (showQuizorFormModal || type === 'Quiz') && isQuizSelected ? (
        <Modal
          isCreatingContribution={true}
          brandingColor={false}
          submitTitle={'Next'}
          maxWidth={'900px'}
          title={'Create Quiz'}
          isOpen={isOpen}
          ignoreDisable={true}
          modalType={'Quizes'}
          onCancel={() => {
            setIsCreateScoredQuiz(false);
            handleClose();
          }}
          isBackButton={true}
          onBack={() => {
            if (type === 'Quiz') {
              setIsCreateScoredQuiz(false);
              handleClose();
            }
            setIsCreateScoredQuiz(false);
            setShowQuizorFormModal(false);
          }}
          onSubmit={() => {
            if (type === 'Quiz') {
              setIsCreateScoredQuiz(true);
            }
            setIsCreateScoredQuiz(true);
          }}
          cancelTitle={'Back'}
          hiddenCancel={false}
        >
          <div className={classes.form_body}>
            <div className={classes.form_container}>
              <div className={classes.cards_container}>
                <div
                  className={`${classes.scratch_card} ${isScoreQuizSelected ? classes.selected : ''}`}
                  onClick={() => {
                    setIsScoreQuizSelected(true);
                  }}
                >
                  {/* <img src={scoreQuiz} /> */}
                  <ScoredQuizIcon />
                  <p className={classes.heading}>Scored Quiz</p>
                  <p className={classes.text}>
                    This quiz can be used as a standalone quiz to evaluate client’s results or knowledge. Once you
                    create this quiz, you’ll be able to automatically share quiz results with your clients.
                  </p>
                </div>

                <div
                  className={`${classes.pre_design_card_disabled} ${!isScoreQuizSelected ? classes.selected : ''}`}
                  // onClick={() => { setIsScoreQuizSelected(false) }}
                >
                  <div className={classes.comingsoon_badge}>Coming Soon</div>
                  {/* <img src={pQuiz} /> */}
                  <PersonalityQuizIcon />
                  <p className={classes.heading}>Personality/Conditional Quiz</p>
                  <p className={classes.text}>
                    {/* Create a personality quiz or other types of quizzes that have conditional results based upon
                    responses. */}
                    A conditional quiz is a quiz where the answers to previous questions determine what the quiz taker
                    sees later. This can be a personality type quiz or other!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}
      {formPriorScratchSelected || quizPriorScratchSelected || showPriorTitleModal ? (
        <></>
      ) : ((showQuizorFormModal && isQuizSelected && isScoreQuizSelected) || type === 'Quiz') && isCreateScoredQuiz ? (
        <Modal
          isCreatingContribution={true}
          brandingColor={false}
          submitTitle={'Next'}
          maxWidth={'900px'}
          title={'Create Scored Quiz'}
          isOpen={isOpen}
          ignoreDisable={true}
          onCancel={() => {
            handleClose();
          }}
          isBackButton={true}
          onBack={() => {
            setIsQuizSelected(true);
            setIsCreateScoredQuiz(false);
          }}
          modalType={'Quizes'}
          onSubmit={() => {
            if (isScratchSelected === false) {
              setQuizPriorScratchSelected(true);
              setPriorTemplateSelected(true);
            } else {
              setQuizPriorScratchSelected(true);
              setPriorTemplateSelected(false);
            }
          }}
          cancelTitle={'Back'}
          hiddenCancel={false}
        >
          <div className={classes.form_body}>
            <div className={classes.form_container}>
              <div className={classes.cards_container}>
                <div
                  className={`${classes.scratch_card_quiz} ${isScratchSelected ? classes.selected : ''}`}
                  onClick={() => {
                    setIsScratchSelected(true);
                  }}
                >
                  {/* <img src={formScratch} /> */}
                  <FromScratchIcon />
                  <p className={classes.heading}>Create From Scratch</p>
                </div>
                <div
                  className={`${classes.pre_design_card_quiz} ${!isScratchSelected ? classes.selected : ''}`}
                  onClick={() => {
                    setIsScratchSelected(false);
                  }}
                >
                  <PriorTemplateIcon />
                  {/* <img src={priorForm} /> */}
                  <p className={classes.heading}>Choose a Prior Template</p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}

      {formPriorScratchSelected ? (
        <Modal
          isCreatingContribution={true}
          brandingColor={false}
          submitTitle={'Next'}
          maxWidth={'900px'}
          title={isScratchSelected ? 'Add a Title for Your Form' : 'Choose a Prior Template'}
          isOpen={isOpen}
          subText={isScratchSelected ? '(This can be changed later)' : ''}
          ignoreDisable={true}
          onCancel={() => {
            handleClose();
            setFormQuizTitle('');
            setPriorformQuizSelected(0);
          }}
          isBackButton={true}
          onBack={() => {
            setFormPriorScratchSelected(false);
            setFormQuizTitle('');
            setPriorformQuizSelected(0);
          }}
          disableSubmitClick={
            isScratchSelected
              ? formQuizTitle.length === 0
              : priorformQuizSelected === null || priorformQuizSelected === 0
          }
          modalType={'Quizes'}
          onSubmit={() => {
            console.log('Prior or Scratch Selection', formQuizTitle, priorformQuizSelected);
            if (isScratchSelected === false) {
              setShowPriorTitleModal(true);
            } else {
              createQuiz();
            }
          }}
          cancelTitle={'Back'}
          hiddenCancel={false}
          mobileView={mobileView}
        >
          <div className={classes.container}>
            <div className={classes.segment_name}>
              {priorQuizesFormsList != undefined && isScratchSelected === false && priorQuizesFormsList.length === 0 ? (
                <></>
              ) : (
                <LabelInput>{isScratchSelected ? 'Title' : 'Select Prior Template'}</LabelInput>
              )}
              <div className={classes.field}>
                {isScratchSelected ? (
                  <StyledInput
                    fullWidth
                    variant="outlined"
                    name={'formprops.name'}
                    value={formQuizTitle}
                    placeholder={formPriorScratchSelected ? 'Form Title' : 'Quiz Title'}
                    onChange={e => {
                      setFormQuizTitle(e.target.value);
                    }}
                  />
                ) : priorQuizesFormsList != undefined && priorQuizesFormsList.length > 0 ? (
                  <Select
                    style={{ overflow: 'hidden' }}
                    className="select-field"
                    variant="outlined"
                    fullWidth
                    value={priorformQuizSelected}
                    input={<OutlinedInput placeholder="Choose a Prior Form" margin="dense" />}
                    onChange={e => {
                      setPriorformQuizSelected(e.target.value);
                      getQuizForm(e.target.value);
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          marginTop: 0, // Adjusts the margin between the Select field and dropdown menu
                          maxHeight: '300px',
                        },
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null, // Ensures the dropdown aligns with the bottom of the Select field
                    }}
                  >
                    <MenuItem disabled={true} value={0}>
                      Choose a Prior Form
                    </MenuItem>
                    {priorQuizesFormsList != undefined &&
                      priorQuizesFormsList.length > 0 &&
                      priorQuizesFormsList?.map(data => {
                        return (
                          <MenuItem value={data?.id} key={data?.id} maxWidth={'400px'}>
                            <Tooltip title={`${data?.title} (${formatTime(data?.createTime)})`}>
                              <div>{getDateTitle(`${data?.title} (${formatTime(data?.createTime)})`)}</div>
                            </Tooltip>
                          </MenuItem>
                        );
                      })}
                  </Select>
                ) : priorQuizesFormsList != undefined &&
                  isScratchSelected === false &&
                  priorQuizesFormsList.length === 0 ? (
                  <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '400' }}>
                    No Prior Form added yet.
                  </div>
                ) : (
                  <CircularProgress />
                )}
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}

      {quizPriorScratchSelected ? (
        <Modal
          isCreatingContribution={true}
          brandingColor={false}
          submitTitle={'Next'}
          maxWidth={'900px'}
          title={isScratchSelected ? 'Add a Title for Your Quiz' : 'Choose a Prior Template'}
          isOpen={isOpen}
          subText={isScratchSelected ? '(This can be changed later)' : ''}
          ignoreDisable={true}
          disableSubmitClick={
            isScratchSelected
              ? formQuizTitle.length === 0
              : priorformQuizSelected === null || priorformQuizSelected === 0
          }
          onCancel={() => {
            handleClose();
          }}
          isBackButton={true}
          onBack={() => {
            setQuizPriorScratchSelected(false);
            setPriorformQuizSelected(null);
            setFormQuizTitle('');
          }}
          modalType={'Quizes'}
          onSubmit={() => {
            if (isScratchSelected === false) {
              setShowPriorTitleModal(true);
            } else {
              createQuiz();
            }
          }}
          cancelTitle={'Back'}
          hiddenCancel={false}
          mobileView={mobileView}
        >
          <div className={classes.container}>
            <div className={classes.segment_name}>
              {priorQuizesFormsList != undefined && isScratchSelected === false && priorQuizesFormsList.length === 0 ? (
                <></>
              ) : (
                <LabelInput>{isScratchSelected ? 'Title' : 'Select Prior Template'}</LabelInput>
              )}
              <div className={classes.field}>
                {isScratchSelected ? (
                  <StyledInput
                    fullWidth
                    variant="outlined"
                    name={'formprops.name'}
                    value={formQuizTitle}
                    placeholder={'Quiz Title'}
                    onChange={e => {
                      setFormQuizTitle(e.target.value);
                    }}
                  />
                ) : priorQuizesFormsList != undefined && priorQuizesFormsList.length > 0 ? (
                  <Select
                    style={{ overflow: 'hidden' }}
                    className="select-field"
                    variant="outlined"
                    fullWidth
                    value={priorformQuizSelected}
                    input={<OutlinedInput placeholder="Choose a Prior Quiz" margin="dense" />}
                    onChange={e => {
                      console.log(e.target.value, 'e.target.value');
                      setPriorformQuizSelected(e.target.value);
                      getQuizForm(e.target.value);
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          marginTop: 0, // Ensures dropdown aligns directly below the select field
                          maxHeight: '300px',
                        },
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null, // Aligns dropdown with the bottom of the select field
                    }}
                  >
                    <MenuItem disabled={true} value={0}>
                      Choose a Prior Quiz
                    </MenuItem>
                    {priorQuizesFormsList != undefined &&
                      priorQuizesFormsList.length > 0 &&
                      priorQuizesFormsList.map(data => (
                        <MenuItem key={data?.id} value={data?.id}>
                          <Tooltip title={`${data?.title} (${formatTime(data?.createTime)})`}>
                            <div>{getDateTitle(`${data?.title} (${formatTime(data?.createTime)})`)}</div>
                          </Tooltip>
                        </MenuItem>
                      ))}
                  </Select>
                ) : priorQuizesFormsList != undefined &&
                  isScratchSelected === false &&
                  priorQuizesFormsList.length === 0 ? (
                  <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '400' }}>
                    No Prior Template added yet.
                  </div>
                ) : (
                  <CircularProgress />
                )}
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}
      {showPriorTitleModal ? (
        <Modal
          isCreatingContribution={true}
          brandingColor={false}
          submitTitle={'Next'}
          maxWidth={'900px'}
          title={formPriorScratchSelected ? 'Add a Title for Your Form' : 'Add a title for your quiz'}
          isOpen={isOpen}
          disableSubmitClick={formQuizTitle.length === 0}
          subText={'(This can be changed later)'}
          ignoreDisable={true}
          onCancel={() => {
            handleClose();
          }}
          isBackButton={true}
          onBack={() => {
            setShowPriorTitleModal(false);
            setFormQuizTitle('');
          }}
          modalType={'Quizes'}
          onSubmit={() => {
            createQuiz();
          }}
          cancelTitle={'Back'}
          hiddenCancel={false}
          mobileView={mobileView}
        >
          <div className={classes.container}>
            <div className={classes.segment_name}>
              <LabelInput>{'Title'}</LabelInput>
              <div className={classes.field}>
                <StyledInput
                  fullWidth
                  variant="outlined"
                  name={'formprops.name'}
                  value={formQuizTitle}
                  placeholder={formPriorScratchSelected ? 'Form Title' : 'Quiz Title'}
                  onChange={e => {
                    setFormQuizTitle(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default CreateQuizesModal;
