const copyToClipboard = text => {
  const dummy = document.createElement('input');
  dummy.style.position = 'absolute';
  dummy.style.left = '-9999px';
  dummy.style.opacity = '0';
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  dummy.setSelectionRange(0, text.length);
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

export default copyToClipboard;
