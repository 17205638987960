import React from 'react';

const FromScratchIcon = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2283_41629)">
        <path
          d="M0.500012 22.9744C0.512796 10.6122 10.6506 0.487228 23.0256 0.500012C35.4006 0.512796 45.5 10.6122 45.5 23C45.5 35.3878 35.4006 45.5 23.0256 45.5C10.6378 45.5128 0.487228 35.3494 0.500012 22.9744ZM41.4219 23.0256C41.4602 12.9006 33.2656 4.65484 23.0895 4.59092C12.9389 4.527 4.65484 12.7216 4.59092 22.8977C4.527 33.0867 12.7727 41.3835 22.9872 41.4091C33.1378 41.4475 41.3708 33.2273 41.4219 23.0256Z"
          fill="#215C73"
        />
        <path
          d="M25.0587 20.9545C27.117 20.9545 29.1113 20.9545 31.1056 20.9545C31.9877 20.9545 32.6397 21.3381 33.0232 22.1307C33.6752 23.4986 32.6908 25.0327 31.1312 25.0455C29.303 25.071 27.4621 25.0455 25.634 25.0455C25.4678 25.0455 25.2888 25.0455 25.0587 25.0455C25.0587 25.2628 25.0587 25.429 25.0587 25.5952C25.0587 27.4233 25.0715 29.2642 25.0587 31.0923C25.0587 32.0384 24.5218 32.7926 23.6908 33.0866C22.8982 33.3679 22.0161 33.1378 21.4664 32.4986C21.1084 32.0895 20.955 31.5909 20.955 31.0412C20.9678 29.2258 20.955 27.4105 20.955 25.5824C20.955 25.4162 20.955 25.2372 20.955 25.0199C20.7377 25.0199 20.5587 25.0199 20.3925 25.0199C18.5644 25.0199 16.7235 25.0199 14.8954 25.0199C13.6042 25.0199 12.6709 24.1506 12.6837 22.9616C12.6965 21.7727 13.617 20.929 14.9209 20.929C16.9153 20.929 18.9096 20.929 20.9678 20.929C20.9678 20.7372 20.9678 20.571 20.9678 20.4048C20.9678 18.5767 20.9678 16.7358 20.9678 14.9077C20.9678 13.642 21.8371 12.7216 23.0133 12.7216C24.1766 12.7216 25.0587 13.642 25.0715 14.9077C25.0843 16.723 25.0715 18.5383 25.0715 20.3665C25.0587 20.5455 25.0587 20.7244 25.0587 20.9545Z"
          fill="#215C73"
        />
      </g>
      <defs>
        <clipPath id="clip0_2283_41629">
          <rect width="45" height="45" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FromScratchIcon;
