import React from 'react';

const IncorrectIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 15.0088L10 11.0088L14 15.0088L14.7867 14.2222L10.7867 10.2222L14.7867 6.22217L14 5.4355L10 9.4355L6 5.4355L5.21333 6.22217L9.21333 10.2222L5.21333 14.2222L6 15.0088ZM10.0033 20.2222C8.62111 20.2222 7.32111 19.9599 6.10333 19.4355C4.8863 18.9103 3.82741 18.1977 2.92667 17.2977C2.02593 16.3985 1.31296 15.3407 0.787778 14.1244C0.262593 12.9081 0 11.6085 0 10.2255C0 8.84328 0.262222 7.54328 0.786667 6.3255C1.31185 5.10846 2.02444 4.04958 2.92444 3.14883C3.8237 2.24809 4.88148 1.53513 6.09778 1.00995C7.31407 0.484761 8.6137 0.222168 9.99667 0.222168C11.3789 0.222168 12.6789 0.48439 13.8967 1.00883C15.1137 1.53402 16.1726 2.24661 17.0733 3.14661C17.9741 4.04587 18.687 5.10365 19.2122 6.31995C19.7374 7.53624 20 8.83587 20 10.2188C20 11.6011 19.7378 12.9011 19.2133 14.1188C18.6881 15.3359 17.9756 16.3948 17.0756 17.2955C16.1763 18.1962 15.1185 18.9092 13.9022 19.4344C12.6859 19.9596 11.3863 20.2222 10.0033 20.2222Z"
        fill="#C11111"
      />
    </svg>
  );
};

export default IncorrectIcon;
