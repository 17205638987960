import styled, { css } from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info.js';
import Input from 'components/FormUI/Input';

export const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;
export const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  margin: -5px 0 0 5px;
`;
export const PageTitleSecond = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-family: Avenir;
  font-size: 1.0625rem;
  font-style: normal;
  line-height: normal;
  font-size: ${({ mobileView }) => (mobileView ? '1.0625rem' : ' 1.125rem')};
  ${({ styleOverrides }) => styleOverrides || ''};
`;
export const PageTitleFirst = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-family: Avenir;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
  font-size: ${({ mobileView }) => (mobileView ? '1.125rem' : '1.5rem')};
  font-weight: ${({ mobileView }) => (mobileView ? '500' : '500')};
  ${({ mobileView }) =>
    mobileView &&
    css`
      margin: 0;
    `};
  ${({ styleOverrides }) => styleOverrides || ''};
`;
export const StyledPageText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Avenir;
  font-size: ${({ mobileView }) => (mobileView ? '0.875rem' : '1.rem')};
  font-style: normal;
  font-weight: ${({ mobileView }) => (mobileView ? '300' : '350')};
  line-height: ${({ mobileView }) => (mobileView ? '1.25rem' : '1.5rem')};
`;
export const StyledTitle = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-bottom: 0px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: rgba(0, 0, 0, 0.38);
    `}
`;
export const StyledLinkButton = styled.a`
  color: rgba(0, 0, 0, 0.87);
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-bottom: 0px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledBody = styled.div`
  display: flex;
  /* padding: 1.25rem 0.5rem; */
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
`;
export const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;

export const StyledInput = styled(Input)`
  height: 48px;
  & * {
    font-size: 1rem;
    font-weight: 400;
  }
  .MuiInputBase-root,
  .MuiInputBase-input {
    font-size: 1rem;
  }
  .MuiInputBase-input {
    ${({ isTextArea }) =>
      isTextArea &&
      `
    height:auto !important;
    overflow: hidden;
  `}
  }
  .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
  }
  .MuiFormHelperText-root {
    margin-right: 0;
  }
  .MuiFormHelperText-root.Mui-error {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .MuiFormHelperText-contained {
    font-size: 0.75rem !important;
    font-weight: normal !important;
  }
`;
export const StyledGridContianer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.75rem;
  ${({ mobileView }) => mobileView && 'padding-top:4.5rem;'};
`;
export const StyledGridContent = styled.div`
  padding: ${({ mobileView, isRight }) =>
    mobileView ? '1.5rem 1rem 0rem' : isRight ? '2.5rem 6rem 0rem 2.44rem' : '2.5rem 2.44rem 0rem 6rem'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ mobileView }) => (mobileView ? '1.5rem' : '1.875rem')};
`;
export const StyledHeadingOne = styled.p`
  color: ${({ textColor }) => textColor};
  font-family: Poppins;
  font-size: ${({ mobileView }) => (mobileView ? '1.75rem' : '2.25rem')};
  font-style: normal;
  font-weight: 700;
  line-height: ${({ mobileView }) => (mobileView ? '2.125rem' : '5rem')}; /* 222.222% */
  letter-spacing: 0.0125rem;
  margin-bottom: 0rem;
`;

export const StyledText = styled.p`
  color: ${({ textColor }) => textColor};
  font-family: Poppins;
  font-size: ${({ mobileView }) => (mobileView ? '0.875rem' : '1rem')};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0125rem;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: ${({ mobileView }) => (mobileView ? '13.22788rem' : '24.16038rem')};
`;

export const StyledDownLoadContianer = styled.div`
  display: flex;
  padding: 2.5rem 0rem;
  flex-direction: column;
  align-items: center;
  gap: 1.875rem;
  width: 100%;
  align-self: stretch;
  background-color: ${({ bgColor }) => bgColor};
`;
export const StyledDownLoadContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
  gap: 1.25rem;
`;

export const StyledPdfContainer = styled.div`
  display: flex;
  height: 13.125rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.375rem;
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  background: #fff;
`;

export const StyledPdfIcon = styled.div`
  display: flex;
  height: 9.6875rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  align-self: stretch;
  background-color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#2D2F31' : 'transparent')};
`;

export const StyledPdfTextContainer = styled.div`
  background: #252728;
  display: flex;
  width: 100%;
  height: 3.4375rem;
  padding: 0.625rem 0.875rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  flex-shrink: 0;
`;

export const HiddenFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;
