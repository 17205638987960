import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTheme, useMediaQuery } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { QuizzesRow } from './QuizesRow';

const BoldTableCell = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);
const BoldTableCellLowPadded = withStyles({
  root: {
    fontWeight: 800,
  },
})(TableCell);
const StyledTableContainer = withStyles({
  root: {
    overflowX: 'auto',
    maxWidth: props => (props.mobileView ? '96vw' : '100%'),
    margin: '0 auto',
  },
})(TableContainer);

const QuizzesTable = ({ rows, getQuizesFormsList }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [openedIndex, setOpenedIndex] = useState(null);
  const [contactWithOpenDropDown, setContactWithOpenDropDown] = useState(null);
  const setId = id => {
    setContactWithOpenDropDown(id);
  };

  return (
    <>
      <StyledTableContainer component={Paper} mobileView={mobileView}>
        <Table className="applications-table" aria-label="applications-table">
          <TableHead>
            <TableRow>
              <BoldTableCell>Title</BoldTableCell>
              <BoldTableCell>Type</BoldTableCell>
              <BoldTableCell>No. of Questions</BoldTableCell>
              <BoldTableCell>No. of Responses</BoldTableCell>
              <BoldTableCell>Date Created</BoldTableCell>
              <BoldTableCell align="center">
                <div>Preview</div>
              </BoldTableCell>
              <BoldTableCell>
                <div style={{ marginLeft: '8px' }}>Share</div>
              </BoldTableCell>
              <BoldTableCell align="center">Action</BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <QuizzesRow
                row={row}
                index={index}
                key={row.id}
                totalRows={rows.length}
                openedIndex={openedIndex}
                setOpenedIndex={setOpenedIndex}
                contactWithOpenDropDown={contactWithOpenDropDown}
                setId={setId}
                getQuizesFormsList={getQuizesFormsList}
              />
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
QuizzesTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
};
QuizzesTable.defaultProps = {
  rows: [],
};
export default QuizzesTable;
