import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { colors } from 'utils/styles';
import { TemplateType } from 'helpers/constants/templateType';
import { connect } from 'react-redux';
import useAccount from 'hooks/useAccount';
import { ContributionType } from 'helpers/constants';
import { lightOrDark } from 'utils/utils';
import { isEmpty } from 'lodash';
const StyledButton = styled.button`
  position: relative;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  border: none;
  color: #fff;
  fill: #fff; /* for svg */
  font-weight: 900;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: ${({ removeLetterSpaccing }) => (!removeLetterSpaccing ? '1.25px' : '')};
  min-width: 18rem;
  transition: background 0.2s;
  font-family: 'Avenir';
  ${({ activeTemplate, TCModal }) =>
    activeTemplate &&
    !TCModal &&
    css`
      filter: ${activeTemplate == TemplateType.TemplateOne ? ' drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' : ''};
    `};
  ${({ attachImage }) =>
    attachImage &&
    css`
      min-width: 10rem;
      padding: 0.65rem 0rem;
    `};
  ${({ activeTemplate }) =>
    activeTemplate
      ? css`
          border-radius: ${activeTemplate == TemplateType.TemplateOne
            ? '4px'
            : activeTemplate == TemplateType.TemplateTwo
            ? '4px'
            : ''};
        `
      : css`
          border-radius: 4px;
        `}
  ${({ autoWidth }) =>
    autoWidth &&
    css`
      min-width: auto;
    `}

    ${({ borderColor }) =>
    borderColor &&
    `border: 1px solid ${borderColor} !important;
  `}
   

  ${({ variant, backgroundColor, btnColor }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
      `;
    }
    return variant === 'primary'
      ? css`
          background-color: ${colors.darkOceanBlue};
        `
      : variant === 'grayedOut'
      ? css`
          background-color: ${colors.backgroundGrey};
        `
      : variant === 'outline'
      ? css`
          background-color: ${colors.white};
        `
      : css`
          background-color: ${colors.newGold};
        `;
  }}


    
  ${({ invert, borderColor }) =>
    invert && borderColor
      ? css`
          color: rgba(0, 0, 0, 0.87);
          background-color: white;
          border: 1px solid #d1b989;
        `
      : invert &&
        css`
          color: rgba(0, 0, 0, 0.87);
          background-color: white;
          border: 1px solid rgba(0, 0, 0, 0.87);
        `}

  &:hover {
    ${({ variant, backgroundColor, color, clientPreviewBtn }) => {
      if (backgroundColor) {
        return css`
          // background-color: ${darken(0.05, backgroundColor)};
          background-color: ${backgroundColor};
          color: ${color};
        `;
      }
      if (clientPreviewBtn) {
        return css`
          background-color: ${colors.white};
        `;
      }
      return variant === 'primary'
        ? css`
            // background-color: ${darken(0.05, colors.darkOceanBlue)};
            background-color: ${colors.darkOceanBlue};
          `
        : variant === 'grayedOut'
        ? css`
            background-color: ${colors.borderLightGray})};
          `
        : variant === 'outline'
        ? css`
            // background-color: ${darken(0.05, colors.lightBrown)};
            background-color: ${colors.white};
          `
        : css`
            // background-color: ${darken(0.05, colors.lightBrown)};
            background-color: ${colors.lightBrown};
          `;
    }}

    ${({ invert }) =>
      invert &&
      css`
        // background-color: ${darken(0.05, 'white')};
        background-color: ${'white'};
      `}
  }
  ${({ isDarkMode }) =>
    isDarkMode
      ? `
  :disabled {
    background-color: #616161;
    color: #9b9b9b;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }
  `
      : `
  :disabled {
    background-color: #ededed;
    color: #9b9b9b;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }`}

  ${({ mobileView, PaymentBlock }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: ${!PaymentBlock ? '8px 16px' : ''};
    min-width: 8rem;
  `}

  ${({ textColor }) =>
    textColor &&
    `color: ${textColor}
  `}

  ${({ mobileView, joinNow }) =>
    mobileView &&
    joinNow &&
    `
    font-size: 14px;
    padding: 20px 20px;
    min-width: 8rem;
  `}

  ${({ ClientJourneyBtn }) =>
    ClientJourneyBtn &&
    `
    min-width: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 66px;
    height: 36px;
    background: #C9B382;
    border-radius: 5px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
  
  `}

  ${({ ClientJourneyBtn, mobileView }) =>
    ClientJourneyBtn &&
    mobileView &&
    `
     width: 100%;
  `}

  ${({ clientPreviewBtn }) =>
    clientPreviewBtn &&
    `
    margin-right: 5px;
    background-color: ${colors.white};
    color: ${colors.darkOceanBlue};
    border: 1px solid ${colors.darkOceanBlue};
  `}

  ${({ clientPreviewBtn }) =>
    clientPreviewBtn &&
    `
    margin-right: 5px;
    background-color: ${colors.white};
    color: ${colors.darkOceanBlue};
    border: 1px solid ${colors.darkOceanBlue};
  `}

  ${({ styleOverrides }) => !isEmpty(styleOverrides) && styleOverrides}
`;

const Button = ({ isDarkMode = false, borderColor, children, ...attrs }) => {
  return (
    <StyledButton borderColor={borderColor} isDarkMode={isDarkMode} {...attrs}>
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  autoWidth: PropTypes.bool,
  invert: PropTypes.bool,
  mobileView: PropTypes.bool,
};

Button.defaultProps = {
  children: 'Default button',
  onClick: () => {},
  disabled: false,
  active: false,
  variant: 'secondary',
  backgroundColor: null,
  textColor: null,
  autoWidth: false,
  invert: false,
  mobileView: false,
};

const mapStateToProps = ({ contributions }) => ({
  brandingColors: contributions?.brandingColors,
  contributions: contributions,
});

export default connect(mapStateToProps)(Button);
