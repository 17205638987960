import React, { useEffect, useState } from 'react';
import classes from './QuestionTypeSelect.module.scss';
import { questionTypes } from 'pages/CreateQuizes/Constants/constants';
import CheckIcon from '../../Assests/Icons/CheckIcon';
import DropdownIcon from '../../Assests/Icons/DropdownIcon';
import QuestionTypesPanel from '../QuestionTypesPanel/QuestionTypesPanel';
import RankingIcon from '../../Assests/Icons/RankingIcon';
import RatingIcon from '../../Assests/Icons/RatingIcon';
import ShortTextIcon from '../../Assests/Icons/ShortTextIcon';
import { DOCUMENT_TYPES } from '../../constants';

const TYPE_MAPPER = {
  [questionTypes.multiple_choice]: 'Multiple Choice',
  [questionTypes.dropdown]: 'Dropdown',
  [questionTypes.ranking]: 'Ranking',
  [questionTypes.rating]: 'Rating',
  [questionTypes.short_text]: 'Short Text',
};

const QuestionTypeSelect = ({ docType, type, onSelect }) => {
  const [isQuestionTypesPanelOpen, setIsQuestionTypesPanelOpen] = useState(false);
  const isForm = docType === DOCUMENT_TYPES.Form;
  const formActionsList = [
    {
      name: 'Dropdown',
      icon: <DropdownIcon />,
      onClick: e => {
        e.stopPropagation();
        onSelect(questionTypes.dropdown);
        setIsQuestionTypesPanelOpen(false);
      },
    },
    {
      name: 'Multiple Choice',
      icon: <CheckIcon />,
      onClick: e => {
        e.stopPropagation();
        onSelect(questionTypes.multiple_choice);
        setIsQuestionTypesPanelOpen(false);
      },
    },
    {
      name: 'Ranking',
      icon: <RankingIcon />,
      onClick: e => {
        e.stopPropagation();
        onSelect(questionTypes.ranking);
        setIsQuestionTypesPanelOpen(false);
      },
    },
    {
      name: 'Rating',
      icon: <RatingIcon />,
      onClick: e => {
        e.stopPropagation();
        onSelect(questionTypes.rating);
        setIsQuestionTypesPanelOpen(false);
      },
    },
    {
      name: 'Short Text',
      icon: <ShortTextIcon />,
      onClick: e => {
        e.stopPropagation();
        onSelect(questionTypes.short_text);
        setIsQuestionTypesPanelOpen(false);
      },
    },
  ];
  const quizActionList = [
    {
      name: 'Dropdown',
      icon: <DropdownIcon />,
      onClick: e => {
        e.stopPropagation();
        onSelect(questionTypes.dropdown);
        setIsQuestionTypesPanelOpen(false);
      },
    },
    {
      name: 'Multiple Choice',
      icon: <CheckIcon />,
      onClick: e => {
        e.stopPropagation();
        onSelect(questionTypes.multiple_choice);
        setIsQuestionTypesPanelOpen(false);
      },
    },
  ];

  return (
    <div className={classes.container}>
      <div
        className={classes.question_type_select}
        onClick={() => {
          setIsQuestionTypesPanelOpen(true);
        }}
      >
        <div className={classes.icon_type_container}>
          <div className={classes.icon}>
            {type === questionTypes.multiple_choice ? (
              <CheckIcon />
            ) : type === questionTypes.dropdown ? (
              <DropdownIcon />
            ) : type === questionTypes.ranking ? (
              <RankingIcon />
            ) : type === questionTypes.rating ? (
              <RatingIcon />
            ) : (
              <ShortTextIcon />
            )}
          </div>
          <div className={classes.type_name}>{TYPE_MAPPER[type]}</div>
        </div>
        <div className={classes.dropdown_icon}>
          <DropdownIcon />
        </div>
      </div>
      {isQuestionTypesPanelOpen && (
        <QuestionTypesPanel
          actions={isForm ? formActionsList : quizActionList}
          type={type}
          onClose={() => {
            setIsQuestionTypesPanelOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default QuestionTypeSelect;
