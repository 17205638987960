import React, { useState } from 'react';
import classes from './IntroCard.module.scss';
import styled from 'styled-components';
import EmailIcon from 'assets/emailSvg.svg';
import PartyIcon from 'assets/partySvg.svg';
import Avatar from '@material-ui/core/Avatar';
import CoachImage from 'assets/chatlogo.png';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useRouter from 'hooks/useRouter';
import useAccount from 'hooks/useAccount';
import Modal from 'components/UI/Modal';
import Checkbox from '@material-ui/core/Checkbox';
import { colors } from 'utils/styles';
export const StyledGreeting = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 500;
  line-height: 31.5px;
  color: #c9b382;
  margin-top: 10px;
`;

export const WelcomMessage = styled.div`
  font-family: Avenir;
  font-size: 20px;
  font-weight: 800;
  line-height: 40.95px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const Description = styled.div`
  font-family: Avenir;
  font-size: 15px;
  font-weight: 400;
  line-height: 24.09px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 10px;
`;

export const FeatureHeading = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 24.09px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 10px;
`;

export const StyledFeatureList = styled.ul`
  padding-left: 15px;
`;

export const StyledFeature = styled.li`
  font-family: Avenir;
  font-size: 15px;
  font-weight: 400;
  line-height: 24.09px;
  color: rgba(0, 0, 0, 1);
  margin-top: 10px;

  &::marker {
    color: #dfe3e4;
  }
`;
export const StyledCheckList = styled.div`
  font-family: Avenir;
  font-size: 15px;
  font-weight: 400;
  line-height: 24.09px;
  color: rgba(0, 0, 0, 1);
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-left: -15px;
  display: flex;
`;
export const GetStarted = styled.div`
  font-family: Avenir;
  font-size: 15px;
  font-weight: 400;
  line-height: 24.09px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 15px;
  margin-bottom: 15px;
`;
export const StyledIcon = styled.img``;

export const ComingSoonTag = styled.span`
  padding: 2px 6px;
  background-color: #215c73;
  border-radius: 4px;
  color: white;
  font-family: Avenir;
  font-style: italic;
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  text-align: center;
  margin-left: 5px;
`;

export const NeedDomain = styled.div`
  font-family: Avenir;
  font-size: 15px;
  font-weight: 400;
  line-height: 24.09px;
  color: rgba(0, 0, 0, 1);
  margin-top: 20px;
  // text-decoration: underline;
  cursor: pointer;

  &:hover {
    // text-decoration: underline;
  }
`;
export const NeedHelp = styled.div`
  font-family: Avenir;
  font-size: 15px;
  font-weight: 400;
  line-height: 24.09px;
  color: rgba(0, 0, 0, 1);
  margin-top: 5px;
  // text-decoration: underline;
  cursor: pointer;

  &:hover {
    // text-decoration: underline;
  }
`;

export const StyledBetaTag = styled.span`
  font-family: Avenir;
  font-size: 13px;
  font-style: italic;
  font-weight: 800;
  line-height: 16px;
  border-radius: 4px;
  padding: 2px 6px;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#215c73')};
  color: white;
`;

export const AvatarComponent = styled(Avatar)`
  width: 50px;
  height: 50px;

  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 40px;
        height: 40px;
        font-size: 0.7rem;
      `
    );
  }}
`;

const IntroCard = ({}) => {
  const isAccountIntegrated = false;
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { history } = useRouter();
  const { user } = useAccount();
  const [needDomainModalOpen, setNeedDomainModalOpen] = useState(false);

  const handleConnectEmailAccount = () => {
    history.push('/account/integrations', { from: 'connectdomain' });
  };

  const handleNeedDomainClick = () => {
    setNeedDomainModalOpen(true);
  };

  return (
    <>
      <div className={classes.container}>
        <AvatarComponent alt="coach image" src={CoachImage} mobileView={mobileView} />

        <StyledGreeting>
          Hi {user?.firstName?.charAt(0).toUpperCase() + user?.firstName?.slice(1)} &nbsp;{' '}
          <StyledIcon src={PartyIcon} /> &nbsp; <StyledIcon src={EmailIcon} />
        </StyledGreeting>
        <WelcomMessage>
          Welcome to Cohere’s NEW Email Feature!
          {/* <StyledBetaTag>Beta</StyledBetaTag> */}
        </WelcomMessage>
        <Description>
          Inside, you’ll be able to create email marketing campaigns and sequences to your leads and/ or clients in one
          place.
        </Description>
        <FeatureHeading>Your Checklist to Go Live:</FeatureHeading>
        <StyledFeatureList>
          <StyledCheckList>
            <div style={{ marginRight: '10px' }}>
              <Checkbox
                disableRipple={true}
                classes={{
                  root: classes.checkbox_root,
                }}
                value={true}
                onChange={() => {}}
                checked={true}
                style={{ color: colors.darkOceanBlue }}
              />
            </div>
            Your account must be upgraded to an Accelerate Plan.
          </StyledCheckList>
          <StyledCheckList>
            <div style={{ marginRight: '10px' }}>
              <Checkbox
                disableRipple={true}
                classes={{
                  root: classes.checkbox_root,
                }}
                onChange={() => {}}
                checked={true}
                style={{ color: colors.darkOceanBlue }}
              />
            </div>
            You must have an email associated with your domain, such as example@yourname.com
          </StyledCheckList>
          <StyledCheckList>
            <div style={{ marginRight: '10px' }}>
              <Checkbox
                disableRipple={true}
                classes={{
                  root: classes.checkbox_root,
                }}
                onChange={() => {}}
                checked={true}
                style={{ color: colors.darkOceanBlue }}
              />
            </div>
            <div>
              You must own a domain, for example,{' '}
              <span style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1790FF' }}>yourname.com</span>.
              Need help connecting your domain to Cohere?{' '}
              <span
                onClick={() => {
                  window.open(
                    'https://coherepublic-prod.s3.amazonaws.com/marketing/how-to/Cohere+Connet+a+Domain.mp4',
                    '_blank',
                  );
                }}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                {' '}
                Watch this video.
              </span>
            </div>
          </StyledCheckList>
        </StyledFeatureList>
        <FeatureHeading>Additional Coming Soon Features:</FeatureHeading>
        <StyledFeatureList>
          <StyledFeature>Organize Your Email Lists</StyledFeature>
          <StyledFeature>Create Custom Email Marketing Sequences</StyledFeature>
          <StyledFeature>Manage Your Clients and Leads Using Tags</StyledFeature>
        </StyledFeatureList>
        <button
          className={classes.connectAccountBtn}
          disabled={isAccountIntegrated}
          onClick={handleConnectEmailAccount}
        >
          Connect Your Domain
        </button>
        <NeedDomain>
          {' '}
          <span onClick={handleNeedDomainClick} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            Need a domain?
          </span>
        </NeedDomain>
        <NeedHelp>
          <span>
            Need help connecting your domain?{' '}
            <span
              onClick={() => {
                window.open(
                  'https://coherepublic-prod.s3.amazonaws.com/marketing/how-to/Cohere+Connet+a+Domain.mp4',
                  '_blank',
                );
              }}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {' '}
              Watch this video.
            </span>
          </span>
        </NeedHelp>
      </div>
      <Modal
        isOpen={needDomainModalOpen}
        title="Need a domain?"
        onSubmit={() => {
          setNeedDomainModalOpen(false);
        }}
        onCancel={() => {
          setNeedDomainModalOpen(false);
        }}
        reduceCancelButton
      >
        <span>
          Domains can be acquired from a variety of vendors, including platforms like{' '}
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1790FF' }}
            onClick={() => {
              window.open('https://GoDaddy.com', '_blank');
            }}
          >
            {' '}
            GoDaddy.com
          </span>
          ,{' '}
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1790FF' }}
            onClick={() => {
              window.open('https://Bluehost.com', '_blank');
            }}
          >
            {' '}
            Bluehost.com
          </span>
          , and
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1790FF' }}
            onClick={() => {
              window.open('https://Namecheap.com', '_blank');
            }}
          >
            {' '}
            Namecheap.com
          </span>
          .
        </span>
      </Modal>
    </>
  );
};

export default IntroCard;
