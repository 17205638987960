import React from 'react';

const RankingIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 5H12.5" stroke="#213649" stroke-width="1.5" stroke-linecap="round" />
      <path d="M4 8H11" stroke="#213649" stroke-width="1.5" stroke-linecap="round" />
      <path d="M4 11H7.5" stroke="#213649" stroke-width="1.5" stroke-linecap="round" />
    </svg>
  );
};

export default RankingIcon;
