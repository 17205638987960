import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Video } from 'components/Video/Video';
import { Formik, Form, Field } from 'formik';
import UploadThumbnail from 'pages/CreateContribution/components/UploadThumbnail';
import { useContribution } from 'pages/ContributionView/hooks';
import { useRouter, useScreenView } from 'hooks';
import { useSaveContribution } from 'hooks/useSaveContribution';
import { updateContributionActions } from 'actions/contributions';
import { useDispatch, useSelector } from 'react-redux';
import { getThemedColors } from 'services/contributions.service';
const StyledBody = styled.div`
  padding: 20px 0px;
`;

const StyledVideoContainer = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.232px;
  border: 1.018px solid ${({ borderColor }) => borderColor || '#dadada'};
  height: ${({ heightRatio }) => `${heightRatio}px !important`};

  /* height: ${({ mobileView, heightRatio }) => (mobileView ? 'auto' : `${heightRatio}px !important`)}; */
  background-color: rgb(250, 250, 250);
`;

const SelfPacedVideo = ({ session, sessionIndex, sessionTime, sessionTimeIndex, refetch }) => {
  const [widthRatio, setWidthRatio] = useState(null);
  const [heightRatio, setHeightRatio] = useState(null);
  const { mobileView } = useScreenView();
  const videoContainerRef = useRef(null);
  const [isOpenUploadThumbnail, setIsOpenUploadThumbnail] = useState(false);
  const contribution = useContribution();
  const { query } = useRouter();
  const isEditing = Boolean(query.id);
  const { onSave: onSaveSelfPacedThumnail } = useSaveContribution(isEditing);
  const dispatch = useDispatch();
  const { themedCardOutlineColor } = getThemedColors(contribution);
  const { isDarkModeEnabled } = contribution;
  const { sessionsTabViewScreenUI } = useSelector(state => state.contributions);

  const updateSessionthumbnail = async thumbnail => {
    const sessionId = session.id;
    const sessionTimeId = sessionTime.id;
    const updateContribution = {
      ...contribution,
      sessions: contribution.sessions.map(session => {
        if (session.id === sessionId) {
          return {
            ...session,
            sessionTimes: session.sessionTimes.map(sessionTime => {
              if (sessionTime.id === sessionTimeId) {
                return {
                  ...sessionTime,
                  selfPacedThumbnailUrl: thumbnail,
                };
              }
              return sessionTime;
            }),
          };
        }
        return session;
      }),
    };
    dispatch(updateContributionActions.success(updateContribution));
    await onSaveSelfPacedThumnail(updateContribution);
  };
  useEffect(() => {
    if (videoContainerRef.current) {
      const containerWidth = videoContainerRef.current.getBoundingClientRect().width;
      const containerHeight = (containerWidth * 9) / 16;
      setWidthRatio(containerWidth);
      setHeightRatio(containerHeight);
    }
  });
  return (
    <StyledBody>
      <StyledVideoContainer
        borderColor={isDarkModeEnabled && themedCardOutlineColor}
        ref={videoContainerRef}
        {...{ widthRatio, heightRatio, mobileView }}
      >
        <Video
          style={{ width: '100%' }}
          isPreviewMode
          isSessionView
          session={session}
          sessionIndex={sessionIndex}
          sessionTime={sessionTime}
          sessionTimeIndex={sessionTimeIndex}
          uploadThumbnail={setIsOpenUploadThumbnail}
          refetch={refetch}
          isFullScreen={sessionsTabViewScreenUI.isCenterColumnFullScreenMode}
          {...{ widthRatio, heightRatio }}
        />
      </StyledVideoContainer>
      {isOpenUploadThumbnail && (
        <UploadThumbnail
          setIsOpen={setIsOpenUploadThumbnail}
          isOpen={isOpenUploadThumbnail}
          title="Select a Thumbnail"
          thumbnailValue={sessionTime?.selfPacedThumbnailUrl}
          fieldName="selfPacedThumbnailUrl"
          isPreviewMode
          updateSessionthumbnail={updateSessionthumbnail}
        />
      )}
    </StyledBody>
  );
};

export default SelfPacedVideo;
