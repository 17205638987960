import React, { useState, Fragment } from 'react';
import classes from './RankingType.module.scss';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Option from '../../../Option/Option';
import RankingOption from './RankingOption/RankingOption';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';

const RankingType = ({
  question,
  index,
  setFieldValue = () => {},
  onRemoveChoice,
  setChecked = () => {},
  clientView = false,
  checked = [],
}) => {
  const [renderOptions, setRenderOptions] = useState(true);

  const handleDragEnd = (results, options, setFieldValue) => {
    const { source, destination } = results;
    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const reOrderedOptions = [...options];
    const destinationOption = reOrderedOptions[destination.index];
    const [removedSection] = reOrderedOptions.splice(source.index, 1);
    reOrderedOptions.splice(destination.index, 0, removedSection);
    if (clientView === false) {
      setFieldValue(`questions[${index}].options`, reOrderedOptions);
    } else {
      setChecked(reOrderedOptions);
    }

    // reRenderOptions();
  };

  const onRemove = idx => {
    onRemoveChoice(idx);
    // reRenderOptions();
  };

  return (
    <div className={classes.container}>
      {clientView && <div className={classes.heading}>Drag and drop to rank options</div>}
      {clientView ? (
        <DragDropContext onDragEnd={results => handleDragEnd(results, checked, setChecked)}>
          <Droppable droppableId="options" type="option" className="test">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef} className={classes.options_list}>
                {renderOptions &&
                  checked?.length > 0 &&
                  checked?.map((option, idx) => {
                    const onRankingInputChange = e => {
                      const value = e.target.value;
                      const newOptions = [...question.options];
                      newOptions[idx] = { ...newOptions[idx], value };
                      setChecked(newOptions);
                    };
                    return (
                      <Fragment key={idx}>
                        <Draggable draggableId={`option-${idx}`} key={`option-${idx}`} index={idx}>
                          {provided => (
                            <>
                              <div {...provided.draggableProps} ref={provided.innerRef}>
                                <RankingOption
                                  key={index}
                                  index={idx}
                                  questionsIndex={index}
                                  parentProvided={provided}
                                  option={option}
                                  setFieldValue={setFieldValue}
                                  onRemoveChoice={onRemove}
                                  question={question}
                                  onRankingInputChange={onRankingInputChange}
                                  clientView={clientView}
                                />
                              </div>
                            </>
                          )}
                        </Draggable>
                      </Fragment>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <DragDropContext onDragEnd={results => handleDragEnd(results, question?.options, setFieldValue)}>
          <Droppable droppableId="options" type="option" className="test">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef} className={classes.options_list}>
                {renderOptions &&
                  question?.options?.map((option, idx) => {
                    const onRankingInputChange = e => {
                      const value = e.target.value;
                      const newOptions = [...question.options];
                      newOptions[idx] = { ...newOptions[idx], value };
                      setFieldValue(`questions[${index}].options`, newOptions);
                    };
                    return (
                      <Fragment key={idx}>
                        <Draggable draggableId={`option-${idx}`} key={`option-${idx}`} index={idx}>
                          {provided => (
                            <>
                              <div {...provided.draggableProps} ref={provided.innerRef}>
                                <RankingOption
                                  key={index}
                                  index={idx}
                                  questionsIndex={index}
                                  parentProvided={provided}
                                  option={option}
                                  setFieldValue={setFieldValue}
                                  onRemoveChoice={onRemove}
                                  question={question}
                                  onRankingInputChange={onRankingInputChange}
                                  clientView={clientView}
                                />
                              </div>
                              {option.value.length > 20 && (
                                <div style={{ marginLeft: '45px' }}>
                                  <CommonErrorMessage
                                    align="left"
                                    message="Option should not be more than 20 characters"
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </Draggable>
                      </Fragment>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default RankingType;
