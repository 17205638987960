import React, { useRef } from 'react';
import { useConversations, useUnifiedCommunity } from 'hooks';
import { useEffect } from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import Chip from '@mui/material/Chip';
import { Typography, Divider } from '@mui/material';
// import TextField from 'components/UI/TextField';
import TextField from '@mui/material/TextField';
import Select from 'components/FormUI/Select';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TuneIcon from '@mui/icons-material/Tune';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { colors } from 'utils/styles';
import List from '@mui/material/List';
import Modal from 'components/UI/Modal';
import { StyledCloseIcon } from 'components/App/GettingStarted/GettingStarted.styles';
import { determineColorToUse } from 'services/contributions.service';
import { useSelector } from 'react-redux';
import { Grid, MenuItem, OutlinedInput, CircularProgress } from '@material-ui/core';
import { determineDarkThemedColorToUse } from 'services/contributions.service';
import { Form, Formik } from 'formik';
import { sortBy } from 'lodash';
import Popper from '@mui/material/Popper';
import { lightOrDark } from 'utils/utils';

const styles = makeStyles(theme => ({
  select_root: {
    paddingLeft: '10px',
    marginTop: '5px',
    border: '1px solid #dbe7f2',
    borderRadius: '4px',
    paddingTop: '8px',
  },
  button: {
    color: 'gray',
    border: '1px solid gray',
    textTransform: 'None',
    fontSize: '14px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid gray',
        borderColor: 'gray',
      },
      '&:hover fieldset': {
        border: '1px solid gray',
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid gray',
        borderColor: 'gray',
      },
    },
  },
}));

const NotifictaionIcon = styled.div`
  position: absolute;
  background-color: ${colors.goldenColor};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  right: -10px;
  top: -10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  .MuiInputBase-root {
    margin-top: 0;
    .MuiSelect-root {
      color: ${({ color }) => color};
    }
    svg {
      color: ${({ color }) => color};
    }
  }
`;
const StyledSearchBarCard = styled.div`
  max-width: 700px !important;
  height: 90px;
  border-radius: 4px;
  padding: 20px;
  background-color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? '' : '#ffffff')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${({ isUnifiedCommunity }) => (isUnifiedCommunity ? '0px 0px 3.3631110191345215px 0px #9D9D9D4D;' : '')};
  // box-shadow: 0 0px 10px 0px rgba(64, 60, 67, 0.16);

  ${({ normalCommunity }) =>
    normalCommunity &&
    `
  border-radius: 23px;
  `}
`;

const StyledModalHeader = styled.div`
  padding: 8px 20px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
`;

const KeywordsPanel = styled.div`
  position: absolute;
  top: 80px;
  width: 100%;
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  border-radius: 4px;
  padding: 5px 0px;
`;

const StyledKeyword = styled.div`
  color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'white' : 'black')};
  cursor: pointer;
  padding: 0px 15px;
  border-radius: 20px;
  font-size: 20px;
  margin-bottom: 5px;
`;

const StyledModalTitle = styled.span`
  font-size: 16px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.19px;
  color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'white' : 'rgba(0, 0, 0, 0.87)')};
`;

const SearchBarContainer = styled.div`
  width: 83%;
  ${({ mobileView }) =>
    mobileView &&
    `
  width: 70%;
`}
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const StyledModalFooter = styled.div`
  // border-top: 1px solid #e7e7e7;
  padding: 10px 20px;

  & button + button {
    margin-left: ${props => (!props.help ? '10px' : '0px')};
  }
`;

const StyledModalBody = styled.div`
  padding: 10px 20px;
  flex: 1;
  max-height: 70vh;
  /* overflow-y: scroll; */
  overflow: auto;

  :: -webkit-scrollbar {
    // width: 5px;
  }
  /* Track */
  :: -webkit-scrollbar - track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  /* Handle */
  :: -webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #215c73;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  :: -webkit-scrollbar-thumb: window-inactive {
    background: #215c73;
  }
`;

const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const FilterContainer = styled.div`
  ${({ mobileView }) =>
    !mobileView &&
    `
  width: 15%;
`}
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
const FilterMenu = styled.div`
  width: 300px;
  background-color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? '#2D2F31' : '#ffffff')};
  box-shadow: 0 0px 10px 0px rgba(64, 60, 67, 0.16);
  position: absolute;
  bottom: -285px;
  right: -65px;
  z-index: 100;
`;

const CustomTagDisplayHashtag = ({ option, onDelete, isDarkThemeApplied, numOfTags }) => {
  const giveLength = numOfTags => {
    if (numOfTags === 1) {
      return 24;
    } else if (numOfTags === 2) {
      return 9;
    } else {
      return 8;
    }
  };
  const maxLength = giveLength(numOfTags); // Set the maximum length for the displayed text
  const displayText =
    option.length > maxLength
      ? option.substring(0, maxLength).toLowerCase() + '..' // Truncate the text
      : option;
  const chipStyle = {
    color: 'white',
    border: '1px solid white',
  };

  return <Chip label={displayText} onDelete={null} style={isDarkThemeApplied ? chipStyle : {}} />;
};

const CustomTagDisplay = ({ option, onDelete, isDarkThemeApplied, numOfTags }) => {
  const giveLength = numOfTags => {
    if (numOfTags === 1) {
      return 24;
    } else if (numOfTags === 2) {
      return 9;
    } else {
      return 8;
    }
  };
  const maxLength = giveLength(numOfTags); // Set the maximum length for the displayed text
  const displayText =
    option.title.length > maxLength
      ? option.title.substring(0, maxLength) + '..' // Truncate the text
      : option.title;
  const chipStyle = {
    color: 'white',
    border: '1px solid white',
  };

  return <Chip label={displayText} onDelete={null} style={isDarkThemeApplied ? chipStyle : {}} />;
};

function CustomOption({ label, selected }) {
  return <Typography style={{ fontWeight: selected ? '600' : '500' }}>{label}</Typography>;
}

const CustomListbox = props => {
  return <List style={{ maxHeight: '100px', overflow: 'auto' }} {...props} />;
};

const useStyles = makeStyles(theme => ({
  customAutocomplete: {
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      width: '0px',
      minWidth: '0px !important',
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '30px !important',
    },
  },
  popupIndicator: {
    color: 'white !important',
  },
}));

const FilterComponent = ({ setfilteredStates, filteredStates }) => {
  const { isUnifiedCommunity } = useUnifiedCommunity();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const isDarkThemeEnabled = false;
  const colorToUse = determineColorToUse(activeContribution);

  const classes = styles();
  const dropdownRef = useRef();

  const onFilterClick = () => {
    setIsFilterMenuOpen(!isFilterMenuOpen);
  };

  const onClearClick = clear => {
    if (clear) {
      //   onClearFilter();
    } else {
      setIsFilterMenuOpen(!isFilterMenuOpen);

      //   onClearFilter();
    }
  };

  return (
    <StyledSearchBarCard
      normalCommunity={false}
      isDarkThemeEnabled={isDarkThemeEnabled}
      isUnifiedCommunity={isUnifiedCommunity}
    >
      <FilterContainer mobileView={mobileView}>
        {isFilterMenuOpen && (
          <Formik
            initialValues={{
              type: filteredStates?.type,
              status: filteredStates?.status,
            }}
            onSubmit={values => {
              setIsFilterMenuOpen(!isFilterMenuOpen);
              setfilteredStates(values);
            }}
          >
            {({ values, setFieldValue, handleSubmit }) => {
              return (
                <Form className="dropdown" ref={dropdownRef}>
                  <FilterMenu className="dropdown" isDarkThemeEnabled={isDarkThemeEnabled}>
                    <StyledModalHeader className="dropdown">
                      <StyledModalTitle isDarkThemeEnabled={isDarkThemeEnabled}>Filter By:</StyledModalTitle>
                      <StyledCloseIcon onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)} />
                    </StyledModalHeader>{' '}
                    <StyledModalBody className="filter-menu-body dropdown">
                      {!isUnifiedCommunity && (
                        <Grid item sm={12} xs={12} className="dropdown">
                          <p style={{ margin: 2, color: isDarkThemeEnabled ? 'white' : colorToUse?.AccentColorCode }}>
                            Type
                          </p>
                          <StyledSelect
                            disableUnderline={true}
                            classes={{
                              root: classes.select_root,
                            }}
                            name="type"
                            defaultValue={values?.type}
                            onChange={e => {
                              setFieldValue('type', e.target.value);
                            }}
                            // defaultValue={dropDownData[0]?.value}
                            items={[
                              { title: 'All', value: 'All' },
                              { title: 'Live', value: 'Live' },
                              { title: 'Self-Paced', value: 'Selfpaced' },
                            ]}
                          />
                        </Grid>
                      )}

                      <Grid item sm={12} xs={12} className="dropdown">
                        <p style={{ margin: 2, color: isDarkThemeEnabled ? 'white' : colorToUse?.AccentColorCode }}>
                          Status
                        </p>
                        <StyledSelect
                          disableUnderline={true}
                          classes={{
                            root: classes.select_root,
                          }}
                          onChange={e => {
                            setFieldValue('status', e.target.value);
                          }}
                          name="status"
                          defaultValue={values?.status}
                          items={[
                            { title: 'All', value: 'All' },
                            { title: 'Upcoming', value: 'Upcoming' },
                            { title: 'Past', value: 'Past' },
                          ]}
                        />
                      </Grid>
                    </StyledModalBody>{' '}
                    <StyledModalFooter className="dropdown">
                      <StyledFooterContainer className="modal-footer-container dropdown">
                        <Button
                          style={
                            isDarkThemeEnabled
                              ? { color: 'white', border: '1.25px solid white' }
                              : { color: 'black', border: '1.25px solid black' }
                          }
                          className="filter-cancel-button"
                          onClick={() => {
                            setFieldValue('status', 'All');
                            setFieldValue('type', 'All');
                            setfilteredStates({ status: 'All', type: 'All' });
                            onClearClick(false);
                          }}
                          variant="outlined"
                        >
                          Clear
                        </Button>
                        <Button
                          style={{ background: colorToUse?.PrimaryColorCode, color: 'white' }}
                          className="filter-submit-button"
                          type="submit"
                        >
                          Apply
                        </Button>
                      </StyledFooterContainer>
                    </StyledModalFooter>
                  </FilterMenu>
                </Form>
              );
            }}
          </Formik>
        )}
        <Button
          variant="outlined"
          startIcon={<TuneIcon htmlColor={colors?.darkOceanBlue} fontSize={mobileView ? '10px' : '14px'} />}
          sx={{
            minWidth: '90px',
            color: `${colors?.darkOceanBlue} !important`,
            border: '1px solid #DBE7F2',
            textTransform: 'None !important',
            fontSize: mobileView ? '12px' : '14px',
            fontWeight: '900',
            // '&:hover': {
            //   borderColor: `${colors?.darkOceanBlue} !important`,
            // },
            fontFamily: 'Avenir',
            '& .css-1d6wzja-MuiButton-startIcon': {
              marginLeft: mobileView ? '0px' : '-4px',
              marginRight: mobileView ? '1px' : '8px',
            },
          }}
          onClick={onFilterClick}
        >
          Filter
        </Button>
      </FilterContainer>
    </StyledSearchBarCard>
  );
};

export default FilterComponent;
