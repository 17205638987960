import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';
import { identical } from 'ramda';

const BaseURL = '/Quiz';

const addQuiz = data => axiosInstance.post(`${BaseURL}/AddOrUpdateQuiz`, data).then(get('data'));
const submitQuestionResponse = data => axiosInstance.post(`${BaseURL}/SubmitQuestionResponse`, data).then(get('data'));
const getQuizzesByType = quizType =>
  axiosInstance.get(`${BaseURL}/GetQuizzesByType?quizType=${quizType}`).then(get('data'));
const getQuizzesFormsPaginated = (quizType, pageNumber, pageSize) => {
  return axiosInstance
    .get(`${BaseURL}/GetQuizzesOrFormsByType?quizType=${quizType}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
    .then(get('data'));
};
const exportResponsesQuizForm = quizId => {
  return axiosInstance.get(`${BaseURL}/ExportQuestionResponses/${quizId}`).then(get('data'));
};
const deleteQuizForm = quizId => {
  return axiosInstance.delete(`${BaseURL}/DeleteQuiz?quizId=${quizId}`).then(get('data'));
};
const duplicateQuizForm = quizId => {
  return axiosInstance.post(`${BaseURL}/DuplicateQuiz`, { quizId: quizId }).then(get('data'));
};

const getQuizFormById = (quizId, excludeDeletedQuestions = false) => {
  return axiosInstance
    .get(`${BaseURL}/GetQuizOrFormById?id=${quizId}&excludeDeletedQuestions=${excludeDeletedQuestions}`)
    .then(get('data'));
};

const getClientQuizFormById = quizId => {
  return axiosInstance.get(`${BaseURL}/GetClientQuizOrFormById?id=${quizId}`).then(get('data'));
};

const getResultsById = quizAttemptId => {
  return axiosInstance.get(`${BaseURL}/GetQuizAttemptByQuizIdAsync/${quizAttemptId}`).then(get('data'));
};

const createUpdateQuiz = body => {
  return axiosInstance.post(`${BaseURL}/AddOrUpdateQuiz`, body).then(get('data'));
};

const createUpdateQuestionInQuiz = body => {
  return axiosInstance.post(`${BaseURL}/AddOrUpdateQuestionInQuiz`, body).then(get('data'));
};

const createQuizLeadUser = body => {
  return axiosInstance.post(`${BaseURL}/CreateQuizLeadUser`, body).then(get('data'));
};

const submitQuestion = body => {
  return axiosInstance.post(`${BaseURL}/SubmitQuestionResponse`, body).then(get('data'));
};

const getQuizResult = (attemptId, leadId) => {
  return axiosInstance
    .get(`${BaseURL}/GetQuizAttemptsResultByAttemptId?quizAttemptId=${attemptId}&LeadId=${leadId}`)
    .then(get('data'));
};

const updateRemindAhain = (quizId, dontRemindAgain) => {
  return axiosInstance
    .put(`${BaseURL}/SetDontRemindAgain`, {
      quizId: quizId,
      dontRemindAgain: dontRemindAgain,
    })
    .then(get('data'));
};

const getCountUpdate = () => {
  return axiosInstance.get(`${BaseURL}/GetCountsForAllTabs`).then(get('data'));
};

const getCustomTemplateByQuiztId = id => {
  return axiosInstance.get(`${BaseURL}/GetCustomTemplateByQuiztId?quizId=${id}`).then(get('data'));
};

const updateEmailTemplate = data => {
  return axiosInstance.put(`${BaseURL}/UpdateEmailTemplate`, data).then(get('data'));
};

const resetEmailTemplate = data => {
  return axiosInstance.put(`${BaseURL}/ResetEmailTemplate`, data).then(get('data'));
};

const sendTestQuizCustomEmailNotification = data => {
  return axiosInstance.post(`${BaseURL}/SendTestQuizCustomEmailNotification`, data).then(get('data'));
};

export {
  addQuiz,
  submitQuestionResponse,
  getQuizzesByType,
  getQuizzesFormsPaginated,
  exportResponsesQuizForm,
  deleteQuizForm,
  duplicateQuizForm,
  getQuizFormById,
  getClientQuizFormById,
  getResultsById,
  createUpdateQuiz,
  createQuizLeadUser,
  submitQuestion,
  getQuizResult,
  createUpdateQuestionInQuiz,
  updateRemindAhain,
  getCountUpdate,
  getCustomTemplateByQuiztId,
  updateEmailTemplate,
  resetEmailTemplate,
  sendTestQuizCustomEmailNotification,
};
