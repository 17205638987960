import React, { memo, useEffect, useState } from 'react';
import classes from '../ContentPage.module.scss';
import { isEqual } from 'lodash';
import useRouter from 'hooks/useRouter';
import ContentPageBody from '../../ContentBody/ContentBody';
import ResultPageBody from '../../ResultBody/ResultBody';
import { useDispatch, useSelector } from 'react-redux';
import BackLeftIcon from '../Assests/Icons/BackLeftIcon';
import Header from './MobileHeader/MobileHeader';

function CenterColumn({
  focusedQuestion,
  questions,
  setFieldValue,
  passResultData,
  failResultData,
  noResultData,
  formProps,
  mobileView,
  onBlackClick,
}) {
  const { showCustomizeResults } = useSelector(state => state?.quizes);
  const {
    location: { pathname },
    query,
  } = useRouter();
  const isEditing = query?.id;
  const [focusedQuestionData, setFocusedQuestionData] = useState(null);
  useEffect(() => {
    if (focusedQuestion != null) {
      setFocusedQuestionData(questions[focusedQuestion]);
    } else {
      setFocusedQuestionData(null);
    }
  }, [focusedQuestion, questions]);

  const handleTypeSelect = type => {
    setFieldValue(`questions[${focusedQuestion}].type`, type);
  };

  const removeHtmlTags = text => {
    return text?.replace(/<\/?[^>]+(>|$)/g, '');
  };

  function truncateText(text) {
    if (text?.length > 30) {
      return text?.slice(0, 30) + '...';
    } else {
      return text;
    }
  }

  return (
    <div className={classes.container}>
      {!mobileView && <Header isEditing={isEditing} values={formProps?.values} setFieldValue={setFieldValue} />}
      {mobileView && (
        <div className={classes.mobile_header}>
          <div className={classes.back_btn_container}>
            <BackLeftIcon onClick={onBlackClick} />
          </div>
          <div className={classes.question}>
            {showCustomizeResults
              ? 'Customize Result Page'
              : truncateText(removeHtmlTags(focusedQuestionData?.name)) ||
                truncateText(removeHtmlTags(focusedQuestionData?.placeholder))}
          </div>
        </div>
      )}
      <div className={classes.content_body}>
        {!showCustomizeResults && (
          <ContentPageBody
            focusedQuestion={focusedQuestion}
            focusedQuestionData={focusedQuestionData}
            setFieldValue={setFieldValue}
            questions={questions}
            formProps={formProps}
            mobileView={mobileView}
          />
        )}

        {showCustomizeResults && (
          <ResultPageBody
            passResultData={passResultData}
            failResultData={failResultData}
            noResultData={noResultData}
            setFieldValue={setFieldValue}
            formProps={formProps}
            mobileView={mobileView}
          />
        )}
      </div>
    </div>
  );
}

export default memo(CenterColumn, isEqual);
