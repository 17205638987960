import React from 'react';

const ActionsIcon = () => {
  return (
    <svg width="5" height="19" viewBox="0 0 5 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="2.9375" cy="2.07692" rx="2.0625" ry="2.07692" fill="#CFCFCF" />
      <ellipse cx="2.9375" cy="9.23073" rx="2.0625" ry="2.07692" fill="#CFCFCF" />
      <ellipse cx="2.9375" cy="16.3845" rx="2.0625" ry="2.07692" fill="#CFCFCF" />
    </svg>
  );
};

export default ActionsIcon;
