import Modal from 'components/UI/Modal';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CircleSvg from './CircleIcon';
import CalendarSvg from 'pages/ContributionView/ContributionSessions/OneToOneSessions/EasyBooking/icons/CalendarIcon';
import { ContributionType } from 'helpers/constants';

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const EventsPopup = ({ events, date, onEventClick, onCancel, isDropdown }) => {
  return (
    <Modal
      isDropdown={isDropdown}
      className="day-events-popup"
      isOpen
      onCancel={onCancel}
      brandingColor={false}
      isCreatingContribution={true}
      title={
        <>
          <p className="day-events-day">{days[date?.getDay()]}</p>
          <p className="day-events-date">{date?.getDate()}</p>
        </>
      }
      disableFooter
    >
      {events?.map(event => (
        <div
          className={`day-events-event ${
            moment(event.start).isBefore(moment(), 'day') &&
            event.contributionType === ContributionType.contributionOneToOne
              ? 'is-past-event'
              : ''
          }`}
          onClick={e => onEventClick(event, e)}
          role="button"
          tabIndex={0}
          onKeyPress={() => onEventClick(event)}
          style={{ color: event?.color }}
        >
          <CircleSvg fill={event?.color} />
          <p>{event.title}</p>
          {event?.isExternalEvent && (
            <span style={{ marginLeft: '10px' }}>
              <CalendarSvg color={event?.color} />
            </span>
          )}
        </div>
      ))}
    </Modal>
  );
};

EventsPopup.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })).isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  onEventClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EventsPopup;
