import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHttp, useAccount } from 'hooks';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { editUserProfile } from 'services/user.service';
import Modal from 'components/UI/Modal';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import Loader from 'components/UI/Loader';
import Popup from 'components/Popup/Popup';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import Button from 'components/FormUI/Button';
import * as userActions from 'actions/user';
import CommunitySvg from '../../../../../../src/components/Icons/CommunityIcon';
import EnrolementSvg from '../../../../../../src/components/Icons/EnrollmentIcon';
import SessionSvg from '../../../../../../src/components/Icons/ReminderIcon';
import { Switch, StyledInput, StyledSlider } from '../../../../../../src/components/switch/style';
import '../../Notifications.scss';
import { Pagination } from '@mui/material';
import { updateEmailPermission, updateEmailPermissionContribution } from 'services/communicationTab.service ';
import { updateUser } from 'actions/update-user';
import { UserRoles } from 'helpers/constants';
import { getAllContributionForClient, getAllContributionForCoach } from 'services/user.service';

const SubcscribeCard = () => {
  const dispatch = useDispatch();
  const { user, currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const [customTemData, setcustomTemData] = useState(user.defaultEmailPermissions);
  const [oneHourSession, setOneHourSession] = useState(user?.subCategorySmsPermissions?.OneHourSession);
  const [twentFourHourSession, setTwentyFourHourSession] = useState(
    user?.subCategorySmsPermissions?.TwentyFourHourSession,
  );
  const [globalSessionReminderPermission, setglobalSessionReminderPermission] = useState(
    user.globalSmsPermissions.SessionReminder,
  );
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [accordionOpen1, setAccordionOpen1] = useState(false);
  const [accordionOpen2, setAccordionOpen2] = useState(false);
  const [accordionOpen4, setAccordionOpen4] = useState(false);
  const [accordionOpen5, setAccordionOpen5] = useState(false);
  const [accordionOpen6, setAccordionOpen6] = useState(false);
  const [pageNumber, setpageNumber] = useState(0);
  const [contributionForEmail, setContributionForEmail] = useState([]);
  const [loadingContributions, setLoadingContributions] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const togglePopup = () => {
    setShowModal(!showModal);
  };
  const EnrollmentSales = isCoach
    ? customTemData.filter(
        el =>
          el.category == '2' && (el.emailNotificationUserType === 'Common' || el.emailNotificationUserType === 'Coach'),
      )
    : customTemData.filter(
        el =>
          el.category == '2' &&
          (el.emailNotificationUserType === 'Common' || el.emailNotificationUserType === 'Client'),
      );
  const EmailNotification = customTemData.filter(el => el.category == '1');
  const SessionContent = isCoach
    ? customTemData.filter(
        el =>
          el.category == '4' && (el.emailNotificationUserType === 'Common' || el.emailNotificationUserType === 'Coach'),
      )
    : customTemData.filter(
        el =>
          el.category == '4' &&
          (el.emailNotificationUserType === 'Common' || el.emailNotificationUserType === 'Client'),
      );
  const Community = isCoach
    ? customTemData.filter(
        el =>
          el.category == '3' && (el.emailNotificationUserType === 'Common' || el.emailNotificationUserType === 'Coach'),
      )
    : customTemData.filter(
        el =>
          el.category == '3' &&
          (el.emailNotificationUserType === 'Common' || el.emailNotificationUserType === 'Client'),
      );
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    getContributions(pageNumber);
  }, []);

  const switchFun = (item, e) => {
    item.isEmailEnabled = e.target.checked;
    updateEmailPermission(item.emailType, e.target.checked)
      .then(res => {
        let u = {
          ...user,
          defaultEmailPermissions: res?.defaultEmailPermissions,
        };
        dispatch(updateUser(u));
      })
      .catch(err => console.log(err, 'Err Testing ===>'));
  };

  const switchFunContribution = (item, e) => {
    item.isEmailEnable = e.target.checked;
    updateEmailPermissionContribution(item.contributionId, e.target.checked)
      .then(res => {
        getContributions(pageNumber);
      })
      .catch(err => console.log(err, 'Err Testing ===>'));
  };

  const getContributions = number => {
    if (isCoach) {
      getAllContributionForCoach(number).then(res => {
        setContributionForEmail(res);
        setLoadingContributions(false);
      });
    } else {
      getAllContributionForClient(number).then(res => {
        setContributionForEmail(res);
        setLoadingContributions(false);
      });
    }
  };

  const handleChange = useCallback((event, value) => {
    if (value === 1) {
      setpageNumber(0);
      getContributions(0);
    } else if (value > 1) {
      setpageNumber(value - 1);
      getContributions(value - 1);
    } else {
      setpageNumber(value);
      getContributions(value);
    }
  }, []);
  const round = Math.ceil(contributionForEmail.TotalCount / 5);

  const updateUserCall = async (val, state) => {
    let updatedUser;
    if (val === 'global') {
      updatedUser = {
        ...user,
        id: user.id,
        globalSmsPermissions: { SessionReminder: state },
        subCategorySmsPermissions: {
          TwentyFourHourSession: state,
          OneHourSession: state,
        },
      };
      setTwentyFourHourSession(state);
      setOneHourSession(state);
      setglobalSessionReminderPermission(state);
    } else if (val === '1hour') {
      updatedUser = {
        ...user,
        id: user.id,
        subCategorySmsPermissions: {
          ...user?.subCategorySmsPermissions,
          OneHourSession: state,
        },
      };
      setOneHourSession(state);
    } else {
      updatedUser = {
        ...user,
        id: user.id,
        subCategorySmsPermissions: {
          ...user?.subCategorySmsPermissions,
          TwentyFourHourSession: state,
        },
      };
      setTwentyFourHourSession(state);
    }
    await editUserProfile(user.id, updatedUser).then(async res => {
      let u = {
        ...user,
        ...res?.user,
      };
      dispatch(updateUser(u));
    });
  };
  return (
    <Grid item xs={10} classes={{ root: 'card-container' }}>
      {loadingContributions && <Loader />}
      <Card maxHeight>
        <CardHeader>
          <PageTitleSecond>Email Notifications</PageTitleSecond>
        </CardHeader>
        <>
          <Grid container>
            <Grid style={{ width: '100%' }} item md={12}>
              {isCoach && (
                <Accordion expanded={accordionOpen}>
                  <AccordionSummary
                    style={{
                      backgroundColor: '#F1F8FF',
                      width: '100%',
                      padding: '0 10px',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen(!accordionOpen)}
                    // className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <div
                      style={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          marginTop: '5px',
                          width: '33%',
                          flexShrink: 0,
                          paddingLeft: '15px',
                          fontSize: '16px',
                          fontWeight: '900',
                          fontStyle: 'normal',
                          fontFamily: 'Avenir',
                        }}
                      >
                        My Email Notifications
                      </Typography>
                      {!accordionOpen && (
                        <Typography
                          sx={{
                            flexShrink: 0,
                            paddingLeft: '15px',
                            fontSize: '16px',
                            fontWeight: '350',
                            fontStyle: 'normal',
                            fontFamily: 'Avenir',
                            justifyContent: 'end',
                            display: 'flex',
                            marginRight: '5px',
                            marginTop: '5px',
                          }}
                        >
                          See Emails
                        </Typography>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      padding: '30px 16px 16px',
                      // backgroundColor: themedCardBackgroundColor,
                      // color: themedColor,
                    }}
                  >
                    {EmailNotification.map((item, index) => {
                      const { isEmailEnabled, name } = item;
                      return (
                        <Grid
                          style={{ justifyContent: 'space-between', display: 'flex', padding: '10px 0px' }}
                          container
                        >
                          <Grid md={6} style={{ width: mobileView ? '50%' : '100%' }}>
                            <p
                              style={{
                                fontSize: '16px',
                                fontWeight: '800',
                                fontStyle: 'normal',
                                fontFamily: 'Avenir',
                                marginLeft: '10px',
                              }}
                            >
                              {name}
                            </p>
                          </Grid>

                          <Grid style={{ display: 'flex', justifyContent: 'end' }} md={2}>
                            {!isEmailEnabled && (
                              <div
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '350',
                                  fontStyle: 'normal',
                                  fontFamily: 'Avenir',
                                  marginRight: '10px',
                                  marginTop: '3px',
                                }}
                              >
                                Unmute
                              </div>
                            )}
                            <Switch className="switch">
                              <StyledInput
                                value={isEmailEnabled}
                                type="checkbox"
                                onChange={e => {
                                  switchFun(item, e);
                                }}
                                checked={isEmailEnabled}
                              />
                              <StyledSlider className="slider round" />
                            </Switch>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              )}
              <Accordion expanded={accordionOpen1}>
                <AccordionSummary
                  style={{
                    backgroundColor: '#F1F8FF',
                    width: '100%',
                    padding: '0 10px',
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  onClick={() => setAccordionOpen1(!accordionOpen1)}
                >
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      width: mobileView ? '87%' : '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        marginTop: '5px',
                        width: '70%',
                        flexShrink: 0,
                        paddingLeft: '15px',
                        fontSize: '16px',
                        fontWeight: '900',
                        fontStyle: 'normal',
                        fontFamily: 'Avenir',
                      }}
                    >
                      Community, Posts and Comments
                    </Typography>
                    {!accordionOpen1 && (
                      <Typography
                        sx={{
                          flexShrink: 0,
                          paddingLeft: '15px',
                          fontSize: '16px',
                          fontWeight: '350',
                          fontStyle: 'normal',
                          fontFamily: 'Avenir',
                          justifyContent: 'end',
                          display: 'flex',
                          marginRight: '5px',
                        }}
                      >
                        See Emails
                      </Typography>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {Community.map((item, index) => {
                    const { isEmailEnabled, name } = item;
                    return (
                      <Grid style={{ justifyContent: 'space-between', display: 'flex', paddingTop: '15px' }} container>
                        <Grid md={6} style={{ width: mobileView ? '50%' : '100%' }}>
                          <p
                            style={{
                              fontSize: '16px',
                              fontWeight: '800',
                              fontStyle: 'normal',
                              fontFamily: 'Avenir',
                              marginLeft: '10px',
                            }}
                          >
                            {name}
                          </p>
                        </Grid>

                        <Grid style={{ display: 'flex', justifyContent: 'end' }} md={2}>
                          {!isEmailEnabled && (
                            <div
                              style={{
                                fontSize: '14px',
                                fontWeight: '350',
                                fontStyle: 'normal',
                                fontFamily: 'Avenir',
                                marginRight: '10px',
                              }}
                            >
                              Unmute
                            </div>
                          )}
                          <Switch className="switch">
                            <StyledInput
                              value={isEmailEnabled}
                              type="checkbox"
                              onChange={e => {
                                switchFun(item, e);
                              }}
                              checked={isEmailEnabled}
                            />
                            <StyledSlider className="slider round" />
                          </Switch>
                        </Grid>
                      </Grid>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={accordionOpen2}>
                <AccordionSummary
                  style={{
                    backgroundColor: '#F1F8FF',
                    width: '100%',
                    padding: '0 10px',
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  onClick={() => setAccordionOpen2(!accordionOpen2)}
                >
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      width: mobileView ? '87%' : '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        marginTop: '5px',
                        width: '70%',
                        flexShrink: 0,
                        paddingLeft: '15px',
                        fontSize: '16px',
                        fontWeight: '900',
                        fontStyle: 'normal',
                        fontFamily: 'Avenir',
                      }}
                    >
                      Sessions Reminder and Self-Paced Content
                    </Typography>
                    {!accordionOpen2 && (
                      <Typography
                        sx={{
                          flexShrink: 0,
                          paddingLeft: '15px',
                          fontSize: '16px',
                          fontWeight: '350',
                          fontStyle: 'normal',
                          fontFamily: 'Avenir',
                          justifyContent: 'end',
                          display: 'flex',
                          marginRight: '5px',
                          marginTop: '5px',
                        }}
                      >
                        See Emails
                      </Typography>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {SessionContent.map((item, index) => {
                    const { isEmailEnabled, name } = item;
                    return (
                      <Grid style={{ justifyContent: 'space-between', display: 'flex', padding: '10px 0px' }} container>
                        <Grid md={6} style={{ width: mobileView ? '50%' : '100%' }}>
                          <p
                            style={{
                              fontSize: '16px',
                              fontWeight: '800',
                              fontStyle: 'normal',
                              fontFamily: 'Avenir',
                              marginLeft: '10px',
                            }}
                          >
                            {name}
                          </p>
                        </Grid>

                        <Grid style={{ display: 'flex', justifyContent: 'end' }} md={2}>
                          {!isEmailEnabled && (
                            <div
                              style={{
                                fontSize: '14px',
                                fontWeight: '350',
                                fontStyle: 'normal',
                                fontFamily: 'Avenir',
                                marginRight: '10px',
                                marginTop: '3px',
                              }}
                            >
                              Unmute
                            </div>
                          )}
                          <Switch className="switch">
                            <StyledInput
                              value={isEmailEnabled}
                              type="checkbox"
                              onChange={e => {
                                switchFun(item, e);
                              }}
                              checked={isEmailEnabled}
                            />
                            <StyledSlider className="slider round" />
                          </Switch>
                        </Grid>
                      </Grid>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={accordionOpen4}>
                <AccordionSummary
                  style={{
                    backgroundColor: '#F1F8FF',
                    width: '100%',
                    padding: '0 10px',
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  onClick={() => setAccordionOpen4(!accordionOpen4)}
                >
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        marginTop: '5px',
                        width: '33%',
                        flexShrink: 0,
                        paddingLeft: '15px',
                        fontSize: '16px',
                        fontWeight: '900',
                        fontStyle: 'normal',
                        fontFamily: 'Avenir',
                      }}
                    >
                      Enrollment and Sales
                    </Typography>
                    {!accordionOpen4 && (
                      <Typography
                        sx={{
                          flexShrink: 0,
                          paddingLeft: '15px',
                          fontSize: '16px',
                          fontWeight: '350',
                          fontStyle: 'normal',
                          fontFamily: 'Avenir',
                          justifyContent: 'end',
                          display: 'flex',
                          marginRight: '5px',
                          marginTop: '5px',
                        }}
                      >
                        See Emails
                      </Typography>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    padding: '30px 16px 16px',
                  }}
                >
                  {EnrollmentSales.map((item, index) => {
                    const { isEmailEnabled, name } = item;
                    return (
                      <Grid style={{ justifyContent: 'space-between', display: 'flex' }} container>
                        <Grid md={6} style={{ width: mobileView ? '50%' : '100%' }}>
                          <p
                            style={{
                              fontSize: '16px',
                              fontWeight: '800',
                              fontStyle: 'normal',
                              fontFamily: 'Avenir',
                              marginLeft: '10px',
                            }}
                          >
                            {name}
                          </p>
                        </Grid>

                        <Grid style={{ display: 'flex', justifyContent: 'end' }} md={2}>
                          {!isEmailEnabled && (
                            <div
                              style={{
                                fontSize: '14px',
                                fontWeight: '350',
                                fontStyle: 'normal',
                                fontFamily: 'Avenir',
                                marginRight: '10px',
                                marginTop: '3px',
                              }}
                            >
                              Unmute
                            </div>
                          )}
                          <Switch className="switch">
                            <StyledInput
                              // defaultChecked={true}
                              value={isEmailEnabled}
                              type="checkbox"
                              onChange={e => {
                                switchFun(item, e);
                              }}
                              checked={isEmailEnabled}
                            />
                            <StyledSlider className="slider round" />
                          </Switch>
                        </Grid>
                      </Grid>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
              {contributionForEmail?.contributionEmailNotificationDTOList?.length > 0 && (
                <Accordion expanded={accordionOpen5}>
                  <AccordionSummary
                    style={{
                      backgroundColor: '#F1F8FF',
                      width: '100%',
                      padding: '0 10px',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    onClick={() => setAccordionOpen5(!accordionOpen5)}
                    // className={isDarkThemeEnabled ? 'dark-notification-accordion' : ''}
                  >
                    <div
                      style={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          marginTop: '5px',
                          width: '33%',
                          flexShrink: 0,
                          paddingLeft: '15px',
                          fontSize: '16px',
                          fontWeight: '900',
                          fontStyle: 'normal',
                          fontFamily: 'Avenir',
                        }}
                      >
                        Contributions Enrolled
                      </Typography>
                      {!accordionOpen5 && (
                        <Typography
                          sx={{
                            flexShrink: 0,
                            paddingLeft: '15px',
                            fontSize: '16px',
                            fontWeight: '350',
                            fontStyle: 'normal',
                            fontFamily: 'Avenir',
                            justifyContent: 'end',
                            display: 'flex',
                            marginRight: '5px',
                            marginTop: '5px',
                          }}
                        >
                          See All
                        </Typography>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      padding: '30px 25px 16px',
                      display: 'flex',
                      flexDirection: 'column',
                      // backgroundColor: themedCardBackgroundColor,
                      // color: themedColor,
                    }}
                  >
                    {contributionForEmail?.contributionEmailNotificationDTOList?.map((item, index) => {
                      const { isEmailEnable, name } = item;
                      return (
                        <Grid
                          style={{ justifyContent: 'space-between', display: 'flex', padding: '10px 0px' }}
                          container
                        >
                          <Grid md={6} style={mobileView ? { width: '50%' } : { width: '100%' }}>
                            <p
                              style={{
                                fontSize: '16px',
                                fontWeight: '300',
                                fontStyle: 'normal',
                                fontFamily: 'Avenir',
                              }}
                            >
                              {name}
                            </p>
                          </Grid>

                          <Grid style={{ display: 'flex', justifyContent: 'end' }} md={2}>
                            {!isEmailEnable && (
                              <div
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '350',
                                  fontStyle: 'normal',
                                  fontFamily: 'Avenir',
                                  marginRight: '10px',
                                  marginTop: '3px',
                                }}
                              >
                                Unmute
                              </div>
                            )}
                            <Switch className="switch">
                              <StyledInput
                                value={isEmailEnable}
                                type="checkbox"
                                onChange={e => {
                                  switchFunContribution(item, e);
                                }}
                                checked={isEmailEnable}
                              />
                              <StyledSlider className="slider round" />
                            </Switch>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Pagination
                      count={round}
                      page={pageNumber + 1}
                      onChange={handleChange}
                      className={`client-info-table-pagination`}
                      size="small"
                      shape="rounded"
                    />
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid>
          </Grid>
        </>
      </Card>
      <Card style={{ marginTop: '20px' }} maxHeight>
        <CardHeader>
          <PageTitleSecond>SMS Notifications</PageTitleSecond>
          <Grid style={{ display: 'flex', justifyContent: 'end' }} md={2}>
            <Switch className="switch">
              <StyledInput
                value={globalSessionReminderPermission}
                type="checkbox"
                onChange={e => {
                  if (globalSessionReminderPermission === true) {
                    updateUserCall('global', false);
                  } else {
                    if (isCoach) {
                      togglePopup();
                    }
                    updateUserCall('global', true);
                  }
                }}
                checked={globalSessionReminderPermission}
              />
              <StyledSlider className="slider round" />
            </Switch>
          </Grid>
        </CardHeader>
        <>
          <Grid container>
            <Grid style={{ width: '100%' }} item md={12}>
              <Accordion expanded={accordionOpen6}>
                <AccordionSummary
                  style={{
                    backgroundColor: '#F1F8FF',
                    width: '100%',
                    padding: '0 10px',
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  onClick={() => setAccordionOpen6(!accordionOpen6)}
                >
                  <Typography
                    sx={{
                      marginTop: '5px',
                      width: '70%',
                      flexShrink: 0,
                      paddingLeft: '15px',
                      fontSize: '16px',
                      fontWeight: '900',
                      fontStyle: 'normal',
                      fontFamily: 'Avenir',
                    }}
                  >
                    Session Reminders
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid style={{ justifyContent: 'space-between', display: 'flex', paddingTop: '15px' }} container>
                    <Grid md={6} style={{ width: mobileView ? '50%' : '100%' }}>
                      <p
                        style={{
                          fontSize: '16px',
                          fontWeight: '800',
                          fontStyle: 'normal',
                          fontFamily: 'Avenir',
                          marginLeft: '10px',
                        }}
                      >
                        1 Hour Session Reminder
                      </p>
                    </Grid>
                    <Grid style={{ display: 'flex', justifyContent: 'end' }} md={2}>
                      <Switch className="switch">
                        <StyledInput
                          value={oneHourSession}
                          type="checkbox"
                          onChange={e => {
                            if (oneHourSession === true) {
                              updateUserCall('1hour', false);
                            } else {
                              updateUserCall('1hour', true);
                            }
                          }}
                          checked={oneHourSession}
                        />
                        <StyledSlider className="slider round" />
                      </Switch>
                    </Grid>
                  </Grid>
                  <Grid style={{ justifyContent: 'space-between', display: 'flex', paddingTop: '15px' }} container>
                    <Grid md={6} style={{ width: mobileView ? '50%' : '100%' }}>
                      <p
                        style={{
                          fontSize: '16px',
                          fontWeight: '800',
                          fontStyle: 'normal',
                          fontFamily: 'Avenir',
                          marginLeft: '10px',
                        }}
                      >
                        24 Hour Session Reminder
                      </p>
                    </Grid>
                    <Grid style={{ display: 'flex', justifyContent: 'end' }} md={2}>
                      <Switch className="switch">
                        <StyledInput
                          value={twentFourHourSession}
                          type="checkbox"
                          onChange={e => {
                            if (twentFourHourSession === true) {
                              updateUserCall('24hour', false);
                            } else {
                              updateUserCall('24hour', true);
                            }
                          }}
                          checked={twentFourHourSession}
                        />
                        <StyledSlider className="slider round" />
                      </Switch>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </>
      </Card>
      {/* <Modal
        title="Sms Service"
        isOpen={showModal}
        onCloseText="OK"
        hiddenCancel
        onSubmit={togglePopup}
        onCancel={togglePopup}
      >
        You will be charged for the sms service in future, right now it's in beta.
      </Modal> */}
      <div style={{ height: '100px' }}></div>
    </Grid>
  );
};

export default SubcscribeCard;
