import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import MainContainer from './MainContainer';
import { StyledMainSection } from './CreateContributionContainer';
import ClientPage from 'pages/ClientPage/ClientPage';

const LeadDetailViewContainer = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <MainContainer>
        <StyledMainSection mobileView={mobileView}>
          <ClientPage isLeadPage={true} />
        </StyledMainSection>
      </MainContainer>
    </>
  );
};

export default LeadDetailViewContainer;
