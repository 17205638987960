import React from 'react';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import Logo from 'components/FormUI/Logo';
import CloseIcon from '@material-ui/icons/Close';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  width: 100%;
  margin-left: ${({ mobileView }) => (mobileView ? '' : '195px')};
  margin-top: ${({ mobileView }) => (mobileView ? '' : '-14px')};

  @media (max-width: 815px) and (min-width: 576px) {
    margin-left: 0;
    margin-top: 0;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 62px;
  height: 61.907px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'color: #fff' : 'color: #black')}
`;

const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ mobileView }) => (mobileView ? '20px 30px' : '20px 100px')};
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};

  @media (max-width: 815px) and (min-width: 576px) {
    padding: 20px 20px;
  }
`;

const ThankYouTitle = styled.div`
  margin-top: 80px;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 900;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
`;

const ThankYouSubtitle = styled.div`
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
  width: ${({ mobileView }) => (mobileView ? '350px' : '580px')};
`;

const ThankYouModal = ({ isOpen, handleClose, title, subtitle, isDarkModeEnabled, mobileView }) => {
  return (
    <Modal isOpen={isOpen} onCancel={handleClose} disableFooter CrossIconHide>
      <ThankYouContainer isDarkModeEnabled={isDarkModeEnabled} mobileView={mobileView}>
        <HeaderContainer mobileView={mobileView}>
          <StyledCloseIcon isDarkThemeEnabled={isDarkModeEnabled} onClick={handleClose} />
        </HeaderContainer>
        <LogoContainer>
          <Logo islarge startlogo />
        </LogoContainer>
        <ThankYouTitle isDarkModeEnabled={isDarkModeEnabled}>{title}</ThankYouTitle>
        <ThankYouSubtitle isDarkModeEnabled={isDarkModeEnabled} mobileView={mobileView}>
          {subtitle}
        </ThankYouSubtitle>
      </ThankYouContainer>
    </Modal>
  );
};

export default ThankYouModal;
