import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { toRem, colors, devices } from 'utils/styles';
import { NavLink } from 'react-router-dom';
import { TemplateType } from 'helpers/constants/templateType';
import { ContributionType, ContributionThemedColors } from 'helpers/constants';
import { lightOrDark } from 'utils/utils';
import { getThemedColors } from 'services/contributions.service';
import useClientPreview from 'hooks/useClientPreview';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useRouter } from 'hooks';
import { useDispatch } from 'react-redux';
import { setIsNewlyCreated } from 'actions/contributions';

const StyledContributionHaderWrapper = styled.div`
  background-color: ${props =>
    !props.isMobileSidebarNeeded ? (props.isCoach ? props.themedBackgroundColor : props.newThemedBackgroundColor) : ''};
  color: ${props => props.themedColor};
  border: ${({ isCoach, type, template, themedCardOutlineColor }) =>
    isCoach || template === TemplateType.TemplateThree ? `solid 1px ${themedCardOutlineColor}` : ''};
  padding: 0;

  ${({ isDarkEnabled, clientPreviewMode, themedCardBackgroundColor }) =>
    isDarkEnabled &&
    clientPreviewMode &&
    `
  background-color: ${themedCardBackgroundColor};
  `}
`;

const StyledContributionHaderContainer = styled.div`
  padding: 0 16px;
  margin: 0 auto;

  ${({ mobileView }) =>
    mobileView
      ? `
        padding: 0px;
      ;
  `
      : null}

  @media screen and (${devices.desktop}) {
    padding: ${({ isCoach, type, template, isSessionTab }) => {
      if (isSessionTab && isCoach) {
        return '0 39px';
      }
      return !isCoach && (template === TemplateType.TemplateOne || template === TemplateType.TemplateTwo)
        ? '0'
        : '0 40px';
    }};

  @media screen and (max-width: 1320px) {
    padding: ${({ isCoach, type, template, isSessionTab }) => {
      if (isSessionTab && isCoach) {
        return '0 14px';
      }
      return !isCoach && (template === TemplateType.TemplateOne || template === TemplateType.TemplateTwo)
        ? '0'
        : '0 14px';
    }};
  }

  @media screen and (max-width: 1320px) {
    padding: ${({ isCoach, type, template, isSessionTab }) => {
      if (isSessionTab && isCoach) {
        return '0 14px';
      }
      return !isCoach && (template === TemplateType.TemplateOne || template === TemplateType.TemplateTwo)
        ? '0'
        : '0 14px';
    }};
  }

  ${({ columnView }) =>
    columnView
      ? `display: flex;
  flex-direction: column;
  width: 45%;
  `
      : null}
`;

export const StyledText = styled.div`
  text-decoration: none;
  font-size: ${toRem(16)};
  line-height: 1;
  font-weight: 900;
  color: ${({ columnView, color, colorToUse }) =>
    columnView ? lightOrDark(colorToUse?.PrimaryColorCode) : color ?? '#4a4a4a'};
  opacity: ${({ columnView }) => (columnView ? 0.5 : ' ')};
  padding: 16px 32px;
  padding: ${({ loggedIn, activeTemplate }) =>
    loggedIn ? ' 16px 32px ' : activeTemplate === 'ThirdTemplate' ? '18px' : '18px'};
  display: inline-block;
  position: relative;
  cursor: pointer;

  ${({ columnView }) =>
    columnView
      ? `
  text-align: center;
  `
      : null}

  ${({ mobileView }) =>
    mobileView &&
    `
    padding: 16px;
    font-size: 16px;
  `}

  ${({ isCoach, type, activeTemplate }) =>
    !isCoach &&
    type != ContributionType.contributionOneToOne &&
    activeTemplate != TemplateType.TemplateThree &&
    css`
      font-family: 'Brandon Text';
      font-style: normal;
      font-weight: 420;
      font-size: 18px;
    `}
`;

export const ApplicationFormBanner = styled.div`
  background: ${props => props.colorToUse?.AccentColorCode};
  border-radius: 5px;
  padding: 15px 20px;
  color: ${props => props.color};
  margin: 2rem 10rem 0.5rem 10rem;

  ${({ isCommunicationTab }) =>
    isCommunicationTab &&
    `
   margin: 10px 0px !important;
   display: flex;
   align-self: center;
   width: 94%;
   height: 56px;
  
  `}
`;

export const ClientPreviewBanner = styled.div`
  background: ${props => props.colorToUse?.AccentColorCode};
  border-radius: 5px;
  padding: 15px 20px;
  color: ${props => props.color};
  margin: 2rem 10rem 0.5rem 10rem;
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  font-size: ${toRem(16)};
  line-height: 1;
  font-weight: 900;
  color: ${({ columnView, color, colorToUse }) =>
    columnView ? lightOrDark(colorToUse?.PrimaryColorCode) : color ?? '#4a4a4a'};

  opacity: ${({ columnView }) => (columnView ? 0.5 : ' ')};
  padding: ${({ maxwidth, isCoach, canAccess, activeTemplate }) =>
    maxwidth ? ' 16px 32px ' : activeTemplate === 'ThirdTemplate' ? '18px' : '18px'};
  display: inline-block;
  position: relative;
  ${({ columnView }) =>
    columnView
      ? `
  text-align: center;
  `
      : null}

  &.active {
    ${({ activeTemplate, isCoach, type, colorToUse, columnView }) =>
      css`
        color: ${columnView ? lightOrDark(colorToUse?.PrimaryColorCode) : colorToUse?.AccentColorCode};
        opacity: ${({ columnView }) => (columnView ? 1 : ' ')};
      `};
  }

  &.active:after {
    content: '';
    width: 100%;
    height: 2px;
    // height: 4px;
    // background-color: ${colors.darkOceanBlue};
    // background-color: ${colors.lightBlue};
    ${({ activeTemplate, isCoach, type, colorToUse, columnView }) =>
      css`
        background-color: ${colorToUse?.AccentColorCode};
        opacity: ${({ columnView }) => (columnView ? 1 : ' ')};
      `};

    position: absolute;
    bottom: 0;
    left: 0;
  }
  color: ${props => props.themedColor} !important;

  ${({ isCoach, type, activeTemplate }) =>
    !isCoach &&
    activeTemplate != TemplateType.TemplateThree &&
    css`
      font-family: 'Brandon Text';
      font-style: normal;
      font-weight: 420;
      font-size: 18px;
    `}
`;

const ContributionHeaderView = ({
  mobileView,
  removeBackgroundColor,
  columnView,
  isCoach,
  contribution,
  children,
  maxwidth,
}) => {
  const {
    themedColor,
    themedBackgroundColor,
    themedCardOutlineColor,
    newThemedBackgroundColor,
    newThemedTextColor,
    themedCardBackgroundColor,
  } = getThemedColors(contribution);
  const { clientPreviewMode } = useClientPreview();
  const isDarkEnabled = contribution?.isDarkModeEnabled;
  const theme = useTheme();
  const isMobileSidebarNeeded = useMediaQuery(theme.breakpoints.down('sm'));
  const { pathname } = useRouter();
  const pathName = pathname.split('/');
  const isSessionTab = pathName[3]?.includes('sessions');
  const dispatch = useDispatch();

  useEffect(() => {
    if (contribution) {
      dispatch(setIsNewlyCreated(contribution?.isNewlyCreated));
    }
  }, [contribution]);

  return (
    <StyledContributionHaderWrapper
      isCoach={isCoach}
      isDarkEnabled={isDarkEnabled}
      type={contribution?.type}
      template={contribution?.activeTemplate || TemplateType.TemplateThree}
      removeBackgroundColor={removeBackgroundColor}
      mobileView={mobileView}
      themedColor={isCoach ? themedColor : newThemedTextColor}
      themedBackgroundColor={themedBackgroundColor}
      newThemedBackgroundColor={newThemedBackgroundColor}
      themedCardOutlineColor={themedCardOutlineColor}
      themedCardBackgroundColor={themedCardBackgroundColor}
      clientPreviewMode={clientPreviewMode}
      isMobileSidebarNeeded={isMobileSidebarNeeded}
    >
      <StyledContributionHaderContainer
        mobileView={mobileView}
        columnView={columnView}
        maxwidth={maxwidth}
        isCoach={isCoach}
        type={contribution?.type}
        template={contribution?.activeTemplate || TemplateType.TemplateThree}
        isSessionTab={isSessionTab}
      >
        {children}
      </StyledContributionHaderContainer>
    </StyledContributionHaderWrapper>
  );
};

ContributionHeaderView.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContributionHeaderView;
