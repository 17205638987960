import Modal from 'components/UI/Modal';
import React, { useEffect, useState, useCallback } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';
import { dataURLtoFile } from 'utils/utils';
import { useHttp } from 'hooks';
import Cropper from 'react-easy-crop';
import getCroppedImg from './usecanvas';
import { useTheme, useMediaQuery } from '@material-ui/core';

const StyledCropperWrapper = styled.div`
  width: 800px;
  min-height: 400px;

  ${({ mobileView, tabletView }) =>
    (mobileView || tabletView) &&
    ` width: 400px;
  `}
`;

const StyledPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

function EasyCropper(props) {
  const CROP_AREA_ASPECT = 812 / 320;
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletView = useMediaQuery(theme.breakpoints.down('md'));
  const {
    imageToCrop,
    isOpen,
    onClose,
    field,
    setFieldValue,
    shape,
    title,
    submitTitle,
    cropRatio,
    successCallback = () => {},
  } = props;
  const { request } = useHttp();
  const [uploading, setUploading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const Submit = () => {
    setUploading(true);
    const formData = new FormData();
    getCroppedImg(imageToCrop, croppedAreaPixels, rotation, true).then(data => {
      if (field && data) {
        formData.append('file', dataURLtoFile(data));
        request('/content/addpublicimage', 'POST', formData, {
          'Content-Type': 'multipart/form-data',
        })
          .then(image => {
            setFieldValue(field, image);
            successCallback(image);
          })
          .catch(console.dir)
          .finally(() => {
            onClose();
            setUploading(false);
          });
      }
    });
  };
  useEffect(() => {
    getCroppedImg(imageToCrop, croppedAreaPixels, rotation).then(croppedImage => {
      setCroppedImage(croppedImage);
    });
  }, [croppedAreaPixels]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      hiddenCancel
      loading={uploading}
      onCancel={() => {
        onClose();
      }}
      dontCancelOnSideClick
      onSubmit={() => {
        Submit();
      }}
      submitTitle={submitTitle}
    >
      <StyledCropperWrapper tabletView={tabletView} mobileView={mobileView}>
        <div
          style={{
            position: 'relative',
            height: '208px',
            width: '750px',
          }}
        >
          {!!imageToCrop && (
            <Cropper
              style={{ width: '560px' }}
              image={imageToCrop}
              aspect={cropRatio || CROP_AREA_ASPECT}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          )}
        </div>
        <StyledPreview>
          <div style={{ fontWeight: 'bold' }}>Preview</div>
          <div
            style={{
              height: '220px',
              display: 'flex',
              // alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              marginTop: '30px',
            }}
          >
            {croppedImage && (
              <img
                style={{
                  marginLeft: mobileView ? '200px' : '' || tabletView ? '200px' : '',
                  height: '200px',
                  position: 'absolute',
                }}
                src={croppedImage}
                alt="cropped"
              />
            )}
          </div>
        </StyledPreview>
        <div style={{ height: '30px', width: '10px' }} />
      </StyledCropperWrapper>
    </Modal>
  );
}

export default EasyCropper;
