import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { colors } from 'utils/styles';
import { useTheme, useMediaQuery, Typography, Tooltip, TextField, Checkbox, Button } from '@material-ui/core';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import InfoIcon from '@material-ui/icons/Info';
import { TOOLTIP } from 'constants.js';
import { makeStyles } from '@material-ui/core';
import EditButton from 'components/UI/ListButtons/EditButton';
import Modal from 'components/UI/Modal';
import { Switch, StyledInput, StyledSlider } from '../../../../../components/switch/style';
import { ContributionType } from 'helpers/constants';
import { SmsBillingServices } from 'pages/Account/CohealerBilling/SmsBillingPages/Services/SmsBillingServices';

export const useStyles = makeStyles(theme => ({
  smsReminderMainContainer: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    // height: '90px',
  },
  smsReminderMainTitle: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: '800',
    marginLeft: '1px',
  },
  smsReminderTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  smsReminderValuesContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  smsReminderSubTitle: {
    fontFamily: 'Avenir',

    fontSize: '16px',
    fontWeight: '350',
  },
  smsReminderEditButton: {
    marginRight: '57px',
  },
  smsReminderCheckBox: {
    padding: '0px',
  },
  smsReminderRadionButton: {
    marginLeft: '57px',
    marginBottom: '0px',
  },
}));

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;
const LiveSessionReminders = ({ values, setFieldValue, contributionType }) => {
  const smsPermissionsPerContribution = values.find(setting => setting.smsPermissionsPerContribution)
    ?.smsPermissionsPerContribution.SessionReminder;

  const subCategorySmsPermissionsPerContribution = values.find(
    setting => setting.subCategorySmsPermissionsPerContribution,
  )?.subCategorySmsPermissionsPerContribution;

  const customText = values.find(setting => setting.customText)?.customText;

  const theme = useTheme();
  const classes = useStyles();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [oneHourSessionMessage, setOneHourSessionMessage] = useState(customText?.OneHourSession);
  const [smsCreditResponse, setsmsCreditResponse] = useState(null);
  const [twentyFourHourSessionMessage, setTwentyFourHourSessionMessage] = useState(customText?.TwentyFourHourSession);
  const [smsToBeUploaded, setsmsToBeUploaded] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [smsSessionReminderSettings, setSmsSessionReminderSettings] = useState([
    { mainSmsPermissionPerContribution: smsPermissionsPerContribution },
    { subSmsPermissionsPerContribution: subCategorySmsPermissionsPerContribution },
    { customText: customText },
  ]);
  const [enableCheckBoxSection, setEnableCheckBoxSection] = useState(smsPermissionsPerContribution);
  const fetchSmsCredits = async () => {
    try {
      const response = await SmsBillingServices.GetSmsCredits();
      setsmsCreditResponse(response.totalRemainingComplementaryCredits + response.totalRemainingPurchasedCredits);
    } catch (error) {
      setsmsCreditResponse(0);
      console.error('Error fetching SMS plans:', error);
    }
  };
  useEffect(() => {
    fetchSmsCredits();
  }, []);

  useEffect(() => {
    setOneHourSessionMessage(customText?.OneHourSession);
    setTwentyFourHourSessionMessage(customText?.TwentyFourHourSession);
    setEnableCheckBoxSection(smsPermissionsPerContribution);
  }, [customText]);

  const toggleEditPopupLiveSessionReminers = () => {
    setShowEditModal(!showEditModal);
  };

  const handleMainSmsPermission = event => {
    setEnableCheckBoxSection(!enableCheckBoxSection);
    const newValue = event.target.checked;
    setSmsSessionReminderSettings(prevSettings =>
      prevSettings.map(setting =>
        setting.hasOwnProperty('mainSmsPermissionPerContribution')
          ? {
              ...setting,
              mainSmsPermissionPerContribution: newValue,
            }
          : setting,
      ),
    );
    setFieldValue('smsPermissionsPerContribution.SessionReminder', newValue);
  };

  const updateCustomText = () => {
    var updatedCustomText = {
      OneHourSession: oneHourSessionMessage,
      TwentyFourHourSession: twentyFourHourSessionMessage,
    };
    setSmsSessionReminderSettings(prevSettings =>
      prevSettings.map(setting =>
        setting.hasOwnProperty('customText')
          ? {
              ...setting,
              customText: updatedCustomText,
            }
          : setting,
      ),
    );
    setFieldValue('customText', updatedCustomText);
    setShowEditModal(!showEditModal);
  };

  const handleSubSmsPermission = (subPermissionType, event) => {
    let oneHourPermission =
      subPermissionType === '1hr'
        ? event.target.checked
        : smsSessionReminderSettings[1].subSmsPermissionsPerContribution.OneHourSession;

    let twentyFourHourPermission =
      subPermissionType === '24hr'
        ? event.target.checked
        : smsSessionReminderSettings[1].subSmsPermissionsPerContribution.TwentyFourHourSession;

    var updateSubPermissions = {
      OneHourSession: oneHourPermission,
      TwentyFourHourSession: twentyFourHourPermission,
    };
    setSmsSessionReminderSettings(prevSettings =>
      prevSettings.map(setting =>
        setting.hasOwnProperty('subSmsPermissionsPerContribution')
          ? {
              ...setting,
              subSmsPermissionsPerContribution: updateSubPermissions,
            }
          : setting,
      ),
    );
    setFieldValue('subCategorySmsPermissionsPerContribution', updateSubPermissions);
  };

  return (
    <>
      <Grid item xs={12}>
        {smsCreditResponse === 0 || smsCreditResponse === null ? (
          <></>
        ) : (
          <Card mobileView={mobileView}>
            <CardHeader mobileView={mobileView} style={{ borderTop: '1px solid #e7e7e7' }}>
              <PageTitleSecond
                style={{ fontSize: contributionType === ContributionType.contributionOneToOne ? '18px' : '1.5rem' }}
                mobileView={mobileView}
              >
                Live Session Reminders
              </PageTitleSecond>
            </CardHeader>

            <CardBody mobileView={mobileView}>
              <div className={classes.smsReminderMainContainer}>
                <div className={classes.smsReminderTitleContainer}>
                  <Typography className={classes.smsReminderMainTitle}>Automated SMS Reminders</Typography>
                  <StyledTooltip
                    title="Get a credit refill whenever your existing SMS credits run out. Your credit card will automatically get charged to refill your credits on Cohere."
                    arrow
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <InfoIcon htmlColor={colors.lightBrown} />
                  </StyledTooltip>
                  <Switch className={classes.smsReminderRadionButton}>
                    <StyledInput
                      value={smsSessionReminderSettings[0]?.mainSmsPermissionPerContribution}
                      type="checkbox"
                      onChange={handleMainSmsPermission}
                      checked={smsSessionReminderSettings[0]?.mainSmsPermissionPerContribution}
                    />
                    <StyledSlider className="slider round" />
                  </Switch>
                </div>
                {enableCheckBoxSection && (
                  <div className={classes.smsReminderValuesContainer}>
                    <Checkbox
                      className={classes.smsReminderCheckBox}
                      color="primary"
                      checked={smsSessionReminderSettings[1]?.subSmsPermissionsPerContribution?.OneHourSession}
                      onChange={event => handleSubSmsPermission('1hr', event)}
                    />
                    <Typography
                      style={{
                        fontWeight: contributionType === ContributionType.contributionOneToOne ? '400' : '350',
                      }}
                      className={classes.smsReminderSubTitle}
                    >
                      1 Hour Reminder
                    </Typography>

                    <EditButton
                      className={classes.smsReminderEditButton}
                      handleOnClick={() => {
                        setsmsToBeUploaded('1hr');
                        toggleEditPopupLiveSessionReminers();
                      }}
                    />
                    <Checkbox
                      className={classes.smsReminderCheckBox}
                      color="primary"
                      checked={smsSessionReminderSettings[1].subSmsPermissionsPerContribution.TwentyFourHourSession}
                      onChange={event => handleSubSmsPermission('24hr', event)}
                    />
                    <Typography
                      style={{
                        fontWeight: contributionType === ContributionType.contributionOneToOne ? '400' : '350',
                      }}
                      className={classes.smsReminderSubTitle}
                    >
                      24 Hour Reminder
                    </Typography>
                    <EditButton
                      className={classes.smsReminderEditButton}
                      handleOnClick={() => {
                        setsmsToBeUploaded('24hr');
                        toggleEditPopupLiveSessionReminers();
                      }}
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  fontSize: '0.875rem',
                  fontFamily: 'Avenir',
                  fontWeight: 300,
                  marginTop: smsSessionReminderSettings[0].mainSmsPermissionPerContribution ? '10px' : '20px',
                }}
              >
                You can customize email notifications after creating or editing this service.
              </div>
            </CardBody>
          </Card>
        )}
      </Grid>
      <Modal
        isCreatingContribution={true}
        brandingColor={false}
        title={`Edit SMS for ${smsToBeUploaded === '1hr' ? '1 Hour Session Reminder' : '24 Hour Session Reminder'}`}
        isOpen={showEditModal}
        onCloseText="OK"
        onSubmit={updateCustomText}
        onCancel={toggleEditPopupLiveSessionReminers}
      >
        <TextField
          fullWidth
          name="moreInfo"
          variant="outlined"
          multiline
          minRows={7}
          onChange={e => {
            smsToBeUploaded === '1hr'
              ? setOneHourSessionMessage(e.target.value)
              : setTwentyFourHourSessionMessage(e.target.value);
          }}
          style={mobileView ? { height: '350px', marginBottom: '10px' } : { marginBottom: '10px' }}
          placeholder="Please input text"
          value={smsToBeUploaded === '1hr' ? oneHourSessionMessage : twentyFourHourSessionMessage}
        />
      </Modal>
    </>
  );
};

LiveSessionReminders.propTypes = {};

export default LiveSessionReminders;
