import React from 'react';

const ImageIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.354 18.0002C1.354 30.4993 5.54714 34.6668 18.1229 34.6668C30.6987 34.6668 34.8918 30.4993 34.8918 18.0002C34.8918 5.50106 30.6987 1.3335 18.1229 1.3335C5.54714 1.3335 1.354 5.50106 1.354 18.0002Z"
        stroke="#213649"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5797 12.2052C15.5797 13.9584 14.1512 15.3782 12.3873 15.3782C10.6252 15.3782 9.19482 13.9584 9.19482 12.2052C9.19482 10.452 10.6252 9.03223 12.3873 9.03223C14.1512 9.03223 15.5797 10.452 15.5797 12.2052Z"
        stroke="#213649"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.6566 22.8051C33.0595 21.1726 30.8007 17.8735 26.6511 17.8735C22.5014 17.8735 22.4108 25.1492 18.176 25.1492C13.9393 25.1492 12.2334 22.6789 9.47244 23.969C6.71327 25.2573 4.46533 30.3852 4.46533 30.3852"
        stroke="#213649"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ImageIcon;
