import React, { useCallback, useState, useEffect } from 'react';
import classes from './QuizPassed.module.scss';
import { SlimStyledSlider, StyledSlider, SlimSwitch, Switch, StyledInput } from 'components/switch/style';
import QuizImage from '../../QuizContent/Components/QuizImage/QuizImage';
import { useAccount, useHttp } from 'hooks/index';
import axiosInstance from 'utils/axiosInstance';
import { EditableTextField } from '../../QuizContent/Components/EditableTextField/EditableTextField';
import Button from 'components/FormUI/Button';
import TextField from 'components/UI/TextField';
import classNames from 'classnames';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import styled from 'styled-components';
import { colors } from 'utils/styles';

const ButtonTooltip = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTooltip = styled.div`
  display: flex;
  margin-left: -10px;
`;

const StyledEditableTextField = styled(EditableTextField)`
  color: #47627b !important;
`;

const QuizPassed = ({ passResultData, setFieldValue, mobileView }) => {
  const [imgUrl, setImgUrl] = useState(null);
  const [thankyouMsg, setThankyouMsg] = useState('Thanks for completing this Quiz!');
  const [scorePercentage, setScorePercentage] = useState('You passed it with a score of');
  const [percentageValue, setPercentageValue] = useState('[%]');
  const { request, loading } = useHttp();
  const { user } = useAccount();

  const handleUploadFile = useCallback(({ target: { files } }) => {
    const formData = new FormData();

    formData.append('file', files[0]);

    request('/Content/AddAvatar', 'POST', formData, {
      'Content-Type': 'multipart/form-data',
    })
      .then(res => {
        setImgUrl(res);
        setFieldValue('isSaved', false);
        setFieldValue('passResultData', {
          ...passResultData,
          isImageEnabled: true,
          image: res,
        });
      })
      .catch(console.dir);
    // .finally(updateUserImg);
  }, []);

  const fieldAvatarUrl = 'AvatarUrl';
  const fieldValue = null;
  const fieldinUserCollection = true;

  const onDeleteClick = useCallback(async () => {
    try {
      const res = await axiosInstance.post(
        `Content/RemoveImagesFromS3BucketAndAccountPage?imageUrl=${imgUrl}&field=${fieldAvatarUrl}&fieldValue=${fieldValue} &userId=${user?.id}&inUserCollection=${fieldinUserCollection}`,
      );
      setFieldValue('isSaved', false);
      setFieldValue('passResultData', {
        ...passResultData,
        image: null,
      });
    } catch (error) {
      console.error(error);
    }
  });

  const handleThankNoteChange = e => {
    e.stopPropagation();
    setThankyouMsg(e.target.value);
    setFieldValue('isSaved', false);
    setFieldValue('passResultData', {
      ...passResultData,
      headerText: e.target.value,
    });
  };

  const handlePercentageChange = e => {
    e.stopPropagation();
    setScorePercentage(e.target.value);
    setFieldValue('isSaved', false);
    setFieldValue('passResultData', {
      ...passResultData,
      percentageText: e.target.value,
    });
  };

  useEffect(() => {
    if (
      passResultData?.isCutomButtonEnabled &&
      (passResultData?.buttonLink?.length === 0 || passResultData?.buttonLink === null)
    ) {
      setFieldValue('isSaved', true);
    }
    if (passResultData?.isCutomButtonEnabled && passResultData?.buttonText?.length === 0) {
      setFieldValue('isSaved', true);
    }
    if (passResultData?.isImageEnabled && (passResultData?.image === null || passResultData?.image?.length === 0)) {
      setFieldValue('isSaved', true);
    }
    if (passResultData?.percentageText?.length === 0) {
      setFieldValue('isSaved', true);
    }
    if (passResultData?.headerText?.length === 0) {
      setFieldValue('isSaved', true);
    }
  }, [passResultData]);

  return (
    <div className={classes.container}>
      <div className={classes.image_toggle_container}>
        <div className={classes.image_toggle}>
          <div className={classes.heading}>
            Add Custom Image
            {passResultData.isImageEnabled ? (
              <Tooltip title={'Photo should be atleast 500x500px and upto 2 mb.'} arrow>
                <InfoIcon style={{ marginLeft: '10px' }} htmlColor={colors.lightBrown} />
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
          <Switch className={classes.toggle_button}>
            <StyledInput
              type="checkbox"
              onClick={() => {
                setFieldValue('isSaved', false);
                setFieldValue('passResultData', {
                  ...passResultData,
                  isImageEnabled: !passResultData.isImageEnabled,
                });
              }}
              checked={passResultData.isImageEnabled}
            />
            <StyledSlider className="slider round" />
          </Switch>
        </div>
      </div>
      {passResultData.isImageEnabled && (
        <>
          <div className={classes.image_container}>
            <QuizImage
              url={passResultData.image}
              onUpload={handleUploadFile}
              onDelete={onDeleteClick}
              isUploading={loading}
            />
          </div>
          {/* <div className={classes.photo_size}>Photo should be atleast 500x500px and upto 2 mb.</div> */}
        </>
      )}
      <StyledEditableTextField
        value={passResultData.headerText}
        onChange={handleThankNoteChange}
        mobileView={mobileView}
      />
      <EditableTextField
        className={classNames(classes.score_percentage, { [classes.score_percentage_responsive]: mobileView })}
        value={passResultData.percentageText}
        onChange={handlePercentageChange}
        mobileView={mobileView}
      />
      <div className={classNames(classes.correct_answers, { [classes.correct_answers_responsive]: mobileView })}>
        You answered [no. of correct answers] out of [total no. of questions] questions correctly
      </div>

      <div className={classNames(classes.action_btns, { [classes.action_btns_responsive]: mobileView })}>
        {passResultData.isCutomButtonEnabled ? (
          <Button className={classes.button_btn} autoWidth>
            {passResultData.buttonText}
          </Button>
        ) : (
          <ButtonTooltip>
            <Button className={classes.button_btn} autoWidth>
              Complete
            </Button>
            <StyledTooltip>
              <Tooltip title={'Your client will be redirected to their dashboard'} arrow>
                <InfoIcon style={{ marginLeft: '10px' }} htmlColor={colors.lightBrown} />
              </Tooltip>
            </StyledTooltip>
          </ButtonTooltip>
        )}
      </div>
      <div className={classes.image_toggle_container}>
        <div className={classes.image_toggle}>
          <div className={classes.heading}>Customize Button & URL</div>
          <Switch className={classes.toggle_button}>
            <StyledInput
              type="checkbox"
              onClick={() => {
                setFieldValue('isSaved', false);
                setFieldValue('passResultData', {
                  ...passResultData,
                  isCutomButtonEnabled: !passResultData.isCutomButtonEnabled,
                });
              }}
              checked={passResultData.isCutomButtonEnabled}
            />
            <StyledSlider className="slider round" />
          </Switch>
        </div>
      </div>
      {passResultData.isCutomButtonEnabled && (
        <div className={classes.btn_text_input}>
          <TextField
            fullWidth
            placeholder="Button Text"
            variant="outlined"
            inputClasses={{
              root: classes.input_root,
              notchedOutline: classes.input_notchedOutline,
              focused: classes.input_focused,
            }}
            value={passResultData.buttonText}
            onChange={e => {
              setFieldValue('isSaved', false);
              setFieldValue('passResultData', {
                ...passResultData,
                buttonText: e.target.value,
              });
            }}
          />
          {passResultData.buttonText.length === 0 && (
            <CommonErrorMessage message="Customize button text should not be empty" />
          )}
        </div>
      )}
      {passResultData.isCutomButtonEnabled && (
        <div className={classes.btn_link_input}>
          <TextField
            fullWidth
            placeholder="Enter Custom Redirect URL"
            variant="outlined"
            inputClasses={{
              root: classes.input_root,
              notchedOutline: classes.input_notchedOutline,
              focused: classes.input_focused,
            }}
            value={passResultData.buttonLink}
            onChange={e => {
              setFieldValue('isSaved', false);
              setFieldValue('passResultData', {
                ...passResultData,
                buttonLink: e.target.value,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default QuizPassed;
