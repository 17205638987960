import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment';
import { batch, useDispatch, useSelector } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { isNil } from 'ramda';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import styled, { css } from 'styled-components';
import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import { fetchTimeZones } from 'actions/timeZone';
import { SessionMoreInfo } from 'components/Session/SessionMoreInfo/SessionMoreInfo';
import { TIMEZONES } from 'constants.js';
import { UserRoles, ContributionThemedColors } from 'helpers/constants';
import { useAccount, useChangedProps, useClientPreview } from 'hooks';
import { memberPodsSelector } from 'selectors/MemberPods';
import { toRem } from 'utils/styles';
import SessionTime, { SessionTimeRedesign } from './SessionTime';
import { BottomPanel } from './BottomPanel/BottomPanel';
import { useRouter } from 'hooks';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import './Session.scss';
import { debounce, isEqual, isUndefined } from 'lodash';
import { TemplateType } from 'helpers/constants/templateType';
import { ContributionType } from 'helpers/constants';
import { ColorSchemeService } from 'services/color.service';
import { lightOrDark } from 'utils/utils';
import Modal from 'components/UI/Modal';
import { BodyText } from 'components/UI/Text/TextStyles';
import {
  setSelectedSessionAndSessionTimeId,
  setSelectedSessionId,
  setSelectedSessionTimeOrContentId,
  setSessionId,
  setShowMobileDetailedView,
} from 'actions/contributions';
import { pickContentItemCard, pickSessionCard } from '../../helpers';
import { DRAGGABLE_TYPES, SESSION_TYPES } from '../../constants';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
// import Loader from 'components/UI/SmallLoader';
import Loader from 'components/UI/Loader';
import { VerticalTimeline } from '../../component/VerticalTimeline';
import { useContribution } from 'pages/ContributionView/hooks';
import {
  activeContribSessTabViewSettingsSelector,
  getActiveContribSessionById,
  getActiveContribSessionTimesBySessionId,
} from 'selectors/contribution';

const useStyles = makeStyles(theme => ({
  sessiontime_summary: {
    marginLeft: '35px',
    [theme.breakpoints.down('769')]: {
      marginLeft: '0px',
    },
  },
}));

const checkIsFuture = date => {
  return moment(date).isAfter(moment(), 'm');
};
const StyledTimeZoneLabel = styled.a`
  display: inline-block;
  font-size: ${toRem(13)};
  background: #116582;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
  color: white;
  vertical-align: middle;
  margin-bottom: 3px;
  font-weight: bold;
`;

const SessionWrapper = styled.div`
  margin-top: 10px;
  border: 1px solid #dbe7f2;
  ${({ isDarkThemeEnabled }) =>
    !isDarkThemeEnabled &&
    `
    border-radius: 5px;
`}
`;
const getMainStyleOverrides = (isClient = false, isSelfPaced = false) => css`
  &.content-group-container.ant-timeline {
    > .ant-timeline-item {
      > .ant-timeline-item-tail {
        /* transform: translate(0); */
        transform: translateY(39%);
        height: calc(100% - 2px);
        top: 0;
        /* left: 1.5rem; */
        margin-left: 1rem;
        z-index: 1;
      }
      > .ant-timeline-item-head.ant-timeline-item-head-custom {
        /* left: 1.5rem; */
        transform: translate(-50%, 0%);
        background-color: transparent;
        margin-left: 1rem;
        svg.hollow circle {
          fill: transparent;
        }
      }
      > .ant-timeline-item-content {
        margin: 0;
        margin-left: 0.625rem;
        padding-left: 1rem;
        ${isClient &&
        isSelfPaced &&
        css`
          max-width: 100%;
        `}
      }
    }
    > .ant-timeline-item:not(:first-child):not(:last-child) {
    }
    > .ant-timeline-item:first-child {
    }
    > .ant-timeline-item:last-child {
      .ant-timeline-item-tail {
        color: transparent;
        border-left-color: transparent;
        border-left-width: none;
        border-left: none;
      }
    }
    /* :hover {
position: relative !important;
left: -2rem !important;
> * {
left: 2rem !important;
position: relative;
}
} */
  }
  /* &.content-group-container.ant-timeline:hover {
position: relative !important;
left: -2rem !important;
> * {
left: 2rem !important;
position: relative;
}
} */
`;
const SessionTimeContainer = props => {
  const {
    children,
    sessionType,
    currentExpanded,
    themedCardOutlineColor,
    cardProps,
    setCurrentExpanded,
    onExpansion,
    sessionId,
  } = props;
  // const {} = useSelector(s => getActiveContribSessionById(s, sessionId));
  const { isCoach, isClient } = useAccount();
  const contribution = useContribution();
  const isSelfPaced = sessionType === SESSION_TYPES.SELFPACED;
  const TypeCardComponent = pickSessionCard(sessionType);
  return (
    <>
      <TypeCardComponent {...cardProps}>
        {cardExpanded => {
          setCurrentExpanded(cardExpanded);
          // if (cardExpanded) {
          //   selectSessionTimeOrContentById(sessionTimes[0]?.id);
          // }
          onExpansion(cardExpanded);
          return (
            <>
              {isCoach ? (
                <Droppable
                  droppableId={`droppable-${sessionId}`}
                  type={`${DRAGGABLE_TYPES.SESSION_CONTENT}-${sessionId}`}
                  // isDropDisabled={!isCoach}
                >
                  {(dropProvided, dropSnapshot) => {
                    return (
                      <div
                        className="content-group-container"
                        ref={dropProvided.innerRef}
                        {...dropProvided.droppableProps}
                        style={
                          {
                            // background: dropSnapshot.isDraggingOver // && dropSnapshot.draggingOverWith
                            //   ? 'lightgreen'
                            //   : 'unset',
                            // padding: 8,
                            // width: 200,
                            // minHeight: 100,
                            // margin: 8,
                          }
                        }
                      >
                        {children}
                        {dropProvided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              ) : (
                <>
                  <VerticalTimeline
                    className="content-group-container"
                    {...{
                      $mainStyleOverrides: getMainStyleOverrides(isClient, isSelfPaced),
                    }}
                  >
                    {children}
                  </VerticalTimeline>
                </>
              )}
            </>
          );
        }}
      </TypeCardComponent>
      <StyledBottomSeparator
        {...{
          expanded: currentExpanded,
          borderColor: contribution?.isDarkModeEnabled ? themedCardOutlineColor : '#dfe3e4',
        }}
      />
    </>
  );
};
const SessionTimeItem = memo(props => {
  const {
    i,
    sessionId,
    sessionTimeId,
    number,
    sessionType,
    // selectSessionTimeOrContentById
  } = props;
  const { isCoach } = useAccount();
  const properties = {
    number: i + 1,
    sessionId,
    sessionTimeId,
    sessionTimeIndex: i,
    sessionNumber: number - 1,
    // key: st.id,
    sessionType,
    // selectSessionTimeOrContentById,
    // dragProps: { dragProvided, dragSnapshot },
  };
  const Return = isCoach ? (
    <Draggable
      key={sessionTimeId}
      index={i}
      draggableId={`draggable-${sessionTimeId}`}
      // isDragDisabled={!isCoach}
    >
      {(dragProvided, dragSnapshot) => {
        return <SessionTimeRedesign {...{ ...properties, dragProvided, dragSnapshot }} />;
      }}
    </Draggable>
  ) : (
    <SessionTimeRedesign {...{ ...properties, key: sessionTimeId }} />
  );
  return Return;
}, isEqual);
const StyledBottomSeparator = styled.div`
  border-bottom: 1px solid ${({ borderColor }) => borderColor || 'red' || 'var(--Cohere-Greys-Grey-Tint, #DFE3E4)'};
  width: 100%;
  ${({ expanded }) =>
    !expanded &&
    css`
      margin-top: -0.625rem;
    `}
`;
const Session = props => {
  const { user } = useAccount();
  const { focusedSession, number, sessionId, dragProps, isExpanded = false, onExpansion = () => {} } = props;
  const { name, isCompleted, sessionTimes: sessionTimesNotMemo, isPrerecorded } = useSelector(s =>
    getActiveContribSessionById(s, sessionId),
  );
  // const sessionTimesNotMemo = useSelector(s => getActiveContribSessionTimesBySessionId(s, sessionId));
  // const sessionTimesNotMemo = [];
  const ref = useRef(null);
  const contribution = useContribution();
  const dispatch = useDispatch();
  const { selectedSessionId, selectedSessionTimeOrContentId } = useSelector(activeContribSessTabViewSettingsSelector);
  const { isCoach } = useAccount();
  useEffect(() => {
    console.info('change:', isEqual(ref?.current, contribution));
    ref.current = contribution;
  }, [contribution]);
  const { id: contributionId, isPurchased } = contribution;

  const sessionToBeExpanded = useSelector(state => state.contributions.sessionId);

  const [isLoading, setLoading] = useState(false);

  const [currentExpanded, setCurrentExpanded] = useState(false);
  // const selectSessionTimeOrContentById = useCallback(
  //   timeOrContentId => {
  //     console.info('dispatched:1:', sessionId, timeOrContentId);
  //     batch(() => {
  //       console.info('dispatched:1.1:', sessionId, timeOrContentId);
  //       dispatch(setSelectedSessionId(sessionId));
  //       dispatch(setSelectedSessionTimeOrContentId(timeOrContentId));
  // dispatch(setShowMobileDetailedView(true));
  //       console.info('dispatched:1.2:', sessionId, timeOrContentId);
  //     });
  //   },
  //   [sessionId],
  // );

  const { contributionMemberPods } = useSelector(memberPodsSelector);
  const [filteredSessionTimes, setFilteredSessionTimes] = useState(isCoach || !isPurchased ? sessionTimesNotMemo : []);

  const sessionTimes = useMemo(() => {
    setLoading(true);
    let result = [...sessionTimesNotMemo];

    if (isCoach || !isPurchased) {
      setFilteredSessionTimes(result);
    } else {
      const contributionPods = contributionMemberPods?.[contributionId];

      result = sessionTimesNotMemo?.filter(item => {
        if (!item.podId) {
          return true;
        }

        const sessionTimePods = contributionPods?.filter(pod => pod.id === item.podId);

        return sessionTimePods?.some(sessionTimePod => sessionTimePod.clientIds?.includes(user.id));
      });

      setFilteredSessionTimes(result);
    }
    setLoading(false);
  }, [sessionTimesNotMemo]);

  const isTodaySession = sessionTimesNotMemo?.some(
    st => moment(st.startTime).startOf('day').diff(moment().startOf('day'), 'days') == 0,
  );

  const currentSelected = selectedSessionId === sessionId;

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(isTodaySession || isExpanded || currentSelected);
  }, [isTodaySession, isExpanded, currentSelected]);

  const isUpcomingSessionTime = find(sessionTimes, { isCompleted: false });

  const { themedCardOutlineColor } = getThemedColors(contribution);

  useEffect(() => {
    setExpanded(isNil(isUpcomingSessionTime) && isCompleted ? false : !isPrerecorded);
  }, [isCompleted]);

  useEffect(() => {
    if (focusedSession === sessionId) {
      setExpanded(true);
    }
    if (sessionToBeExpanded === sessionId) {
      setExpanded(true);
    }
    if (selectedSessionId === sessionId) {
      setExpanded(true);
    }
  }, [focusedSession, sessionToBeExpanded, selectedSessionId]);

  // useEffect(() => {
  //   setLoading(true);
  //   let result = [...sessionTimes];

  //   if (isCoach || !isPurchased) {
  //     setFilteredSessionTimes(result);
  //   } else {
  //     const contributionPods = contributionMemberPods?.[contributionId];

  //     result = sessionTimes?.filter(item => {
  //       if (!item.podId) {
  //         return true;
  //       }

  //       const sessionTimePods = contributionPods?.filter(pod => pod.id === item.podId);

  //       return sessionTimePods?.some(sessionTimePod => sessionTimePod.clientIds?.includes(user.id));
  //     });

  //     setFilteredSessionTimes(result);
  //   }
  //   setLoading(false);
  // }, [contributionId, contributionMemberPods, isCoach, isPurchased, sessionTimes, user.id]);

  const selectedSessionHandlerMemoized = useCallback(
    debounce(
      async () => {
        if (
          filteredSessionTimes?.length &&
          selectedSessionId !== sessionId &&
          selectedSessionTimeOrContentId !== filteredSessionTimes[0]?.id
        ) {
          // selectSessionTimeOrContentById(filteredSessionTimes[0]?.id);
          dispatch(setSelectedSessionAndSessionTimeId(sessionId, filteredSessionTimes[0]?.id));
          dispatch(setShowMobileDetailedView(true));
        }
      },
      50,
      { leading: true, trailing: false },
    ),
    [filteredSessionTimes, selectedSessionId, selectedSessionTimeOrContentId],
  );

  const sessionType = Number(isPrerecorded);

  const SessionTimesList = useMemo(() => {
    return !filteredSessionTimes ? (
      <></>
    ) : (
      filteredSessionTimes?.map((st, i) => {
        // return <div>/ - / - / - /</div>
        return (
          // <div>Session time {i}</div>
          <SessionTimeItem
            {...{
              i,
              sessionId,
              sessionTimeId: st?.id,
              number,
              sessionType,
              // selectSessionTimeOrContentById,
            }}
          />
        );
      })
    );
  }, [filteredSessionTimes, sessionType, number, sessionId]);

  // if (isLoading) {
  //   return <Loader />;
  // }
  if (!filteredSessionTimes?.length) {
    return null;
  }

  const sortedSessionTimes = orderBy(filteredSessionTimes, ['startTime'], 'asc');
  const closetSessionTime = sortedSessionTimes && sortedSessionTimes[0];
  const startTime = closetSessionTime?.startTime;
  const startTimeMoment = moment(startTime);

  let completed = false;
  if (SESSION_TYPES.SELFPACED) {
    completed = filteredSessionTimes?.every(sessionTime =>
      sessionTime?.completedSelfPacedParticipantIds?.includes(user?.id),
    );
  }
  const cardProps = {
    id: sessionId,
    title: name,
    serialNo: number,
    // isToday: isTodaySession || isTodayselfpacedSession,
    disableDrag: sessionType === SESSION_TYPES.LIVE || !isCoach,
    // disableDrag: false,
    expanded: expanded || isExpanded,
    dragProps,
    ...(sessionType === SESSION_TYPES.LIVE && {
      subTitle: `${
        checkIsFuture(startTimeMoment) && !closetSessionTime?.isCompleted ? `Upcoming on ` : ''
      } ${startTimeMoment.format('MMM Do YYYY')} at ${startTimeMoment.format('h:mma')}`,
      // expanded: true,
      isToday: isTodaySession,
    }),
    isClient: !isCoach,
    timeline: {
      disableTail: false,
      isCompleted: isCompleted || completed || false,
    },
    selectedSessionHandler: selectedSessionHandlerMemoized,
    // ...(!isUndefined(expanded) && { expanded }),
  };
  // const ItemCard = pickContentItemCard(sessionType, null);
  // const itemCardProps = getContentItemProps(sessionType, null);
  // const ItemCardList = pickContentItemCardList(sessionType, s);

  return (
    <>
      {isLoading && <Loader />}

      <SessionTimeContainer
        {...{
          sessionType,
          currentExpanded,
          themedCardOutlineColor,
          cardProps,
          setCurrentExpanded,
          onExpansion,
          sessionId,
        }}
      >
        {
          // orderBy(filteredSessionTimes, ['startTime'], ['asc'])
          SessionTimesList
        }
      </SessionTimeContainer>
    </>
  );
};

Session.propTypes = {
  number: PropTypes.number.isRequired,

  dragProps: PropTypes.shape({
    dragProvided: PropTypes.object,
    dragSnapshot: PropTypes.object,
  }),
};
Session.defaultProps = {
  dragProps: {},
};

export default memo(Session, isEqual);
