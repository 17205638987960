import { useContribution } from 'pages/ContributionView/hooks';
import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Avatar, Box } from '@material-ui/core';
import { determineColorToUse } from 'services/contributions.service';
import { getIcalFileLinkOneToOneSession } from 'services/purchase.service';
import pluralize from 'pluralize';
import { getThemedColors } from 'services/contributions.service';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import { BiCalendar } from 'react-icons/bi';
import { MenuItem, useTheme, useMediaQuery, ClickAwayListener } from '@material-ui/core';
import useAccount from 'hooks/useAccount';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ExistingUserGuestContributionAddModal from './ExistingUserGuestContributionAddModal';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const StyledPContainer = styled.div`
  ${({ mobileView }) =>
    mobileView &&
    css`
      flex: 1 !important;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      p {
        font-size: 0.9375rem;
      }
      p:nth-child(1) {
        flex-basis: 50%;
        margin-bottom: 0;
      }
      p:nth-child(2) {
        flex-basis: 50%;
        padding-top: 0;
      }
    `}
`;

const StyledP = styled.p`
  color: ${({ isDarkModeEnabled, newThemedTextColor }) => (isDarkModeEnabled ? newThemedTextColor : '')};
`;
const StyledContainerBox = styled.div`
  display: flex;
  min-width: ${({ mobileView }) => (mobileView ? '100%' : '49.3125rem')};
  min-height: 30.625rem;
  max-width: 54.8%;
  max-height: 54.4%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--Cohere-Greys-Background-Grey, #f5f5f5);
  background: #fff;

  /* Cohere/Shadow B */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
`;
const CalendarContainer = styled.div`
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  ${({ mobileView }) =>
    mobileView
      ? css`
          width: calc(100% + 20px);
        `
      : ''}
`;

const CalendarMenu = styled.div`
  width: 220px;
  background-color: white;
  color: black;
  box-shadow: 0 0px 10px 0px rgba(64, 60, 67, 0.16);
  position: absolute;
  ${({ mobileView }) =>
    mobileView
      ? css`
          margin-top: 115px;
          width: 100%;
        `
      : css`
          margin-top: 93px;
        `}
  z-index: 1000;
  margin-left: -10px;
`;
const StyledImageWrapper = styled.div`
  display: flex;
  padding: 0.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  width: 4.2625rem;
  height: 4.2625rem;
  max-width: 4.2625rem;
  max-height: 4.2625rem;

  border-radius: 4.0625rem;
  border: 2px solid ${({ borderColor }) => borderColor || 'var(--Cohere-Secondary-Brown, #c9b382)'};

  > .img-container {
    height: 100%;
    width: 100%;
  }
`;
const StyledMessageContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  & > .main-message {
    color: rgba(0, 0, 0, 0.87);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Avenir;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  & > .sub-message {
    color: rgba(0, 0, 0, 0.87);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Avenir;
    font-size: 1rem;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
  }
`;

const StyledTextAddCalendar = styled.span`
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.25rem; /* 142.857% */
`;

const StyledMeetingInfoBlock = styled.div`
  background: #f5f5f5;

  display: flex;
  padding: 1rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  border-radius: 0.25rem;
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  background: #fff;

  & > .meeting-duration {
    color: rgba(0, 0, 0, 0.87);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Avenir;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  & > .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    &:nth-child(2) {
      .icon {
        margin-top: -2px;
      }
    }
    & > .info-row {
      display: flex;
      align-items: center;
      gap: 0.625rem;
      > .icon {
        width: 1.5rem;
        height: 1.5rem;
      }
      > .text {
        color: rgba(0, 0, 0, 0.87);
        font-family: Avenir;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        text-transform: capitalize;
      }
      > .text.coach-name {
      }
      > .meeting-date-time {
      }
      > .meeting-location {
      }
    }
  }
  & > .actions {
  }
`;
const CoachIcon = ({ color = '#215C73' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M12.1471 5.88235C13.7038 5.88235 14.9706 7.14918 14.9706 8.70588C14.9706 10.2626 13.7038 11.5294 12.1471 11.5294C10.5904 11.5294 9.32353 10.2626 9.32353 8.70588C9.32353 7.14918 10.5904 5.88235 12.1471 5.88235ZM12.1471 4C9.54565 4 7.44118 6.10635 7.44118 8.70588C7.44118 11.3054 9.54565 13.4118 12.1471 13.4118C14.7485 13.4118 16.8529 11.3054 16.8529 8.70588C16.8529 6.10635 14.7485 4 12.1471 4ZM12.1471 16.2353C14.0492 16.2353 15.2539 16.9609 15.7075 17.5953C15.0647 17.84 13.8544 18.1176 12.1471 18.1176C10.2798 18.1176 9.12965 17.8306 8.55365 17.6075C8.98565 16.9346 10.2261 16.2353 12.1471 16.2353ZM12.1471 14.3529C8.61765 14.3529 6.5 16.2353 6.5 18.1176C6.5 19.0588 8.61765 20 12.1471 20C15.4581 20 17.7941 19.0588 17.7941 18.1176C17.7941 16.2353 15.5786 14.3529 12.1471 14.3529Z"
        fill={color}
      />
    </svg>
  );
};
const DateCalendarIcon = ({ color = '#215C73' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M12.7 14.8C12.14 14.8 11.6667 14.6067 11.28 14.22C10.8933 13.8333 10.7 13.36 10.7 12.8C10.7 12.24 10.8933 11.7667 11.28 11.38C11.6667 10.9933 12.14 10.8 12.7 10.8C13.26 10.8 13.7333 10.9933 14.12 11.38C14.5067 11.7667 14.7 12.24 14.7 12.8C14.7 13.36 14.5067 13.8333 14.12 14.22C13.7333 14.6067 13.26 14.8 12.7 14.8ZM5.1 18C4.66 18 4.28347 17.8435 3.9704 17.5304C3.65733 17.2173 3.50053 16.8405 3.5 16.4V5.2C3.5 4.76 3.6568 4.38347 3.9704 4.0704C4.284 3.75733 4.66053 3.60053 5.1 3.6H5.9V2H7.5V3.6H13.9V2H15.5V3.6H16.3C16.74 3.6 17.1168 3.7568 17.4304 4.0704C17.744 4.384 17.9005 4.76053 17.9 5.2V16.4C17.9 16.84 17.7435 17.2168 17.4304 17.5304C17.1173 17.844 16.7405 18.0005 16.3 18H5.1ZM5.1 16.4H16.3V8.4H5.1V16.4ZM5.1 6.8H16.3V5.2H5.1V6.8Z"
        fill={color}
      />
    </svg>
  );
};
const GlobeIcon = ({ color = '#215C73' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.5 12C4.5 7.5816 8.0816 4 12.5 4C16.9184 4 20.5 7.5816 20.5 12C20.5 16.4184 16.9184 20 12.5 20C8.0816 20 4.5 16.4184 4.5 12ZM6.1 12C6.1 13.6974 6.77428 15.3253 7.97452 16.5255C9.17475 17.7257 10.8026 18.4 12.5 18.4C14.1974 18.4 15.8253 17.7257 17.0255 16.5255C18.2257 15.3253 18.9 13.6974 18.9 12C18.9 10.3026 18.2257 8.67475 17.0255 7.47452C15.8253 6.27428 14.1974 5.6 12.5 5.6C10.8026 5.6 9.17475 6.27428 7.97452 7.47452C6.77428 8.67475 6.1 10.3026 6.1 12ZM9.5016 10.7584C9.696 10.352 10.016 9.2 10.2472 9.2C10.4776 9.2 10.3936 9.7784 10.6 9.8288C10.7736 9.8712 10.64 9.5704 11.1224 9.5168C11.6048 9.4632 12.1304 9.7584 12.1304 9.7584C12.1304 9.7584 12.7 9.9 13.2256 9.7584C13.2256 9.7584 12.948 9.3376 13.288 9.2C13.6272 9.0624 14.0896 9.5712 14.1064 9.9C14.1232 10.2288 13.3512 10.4496 13.3512 10.4496L14.1064 10.9032C14.1064 10.9032 14.328 10.2008 14.8 10.1936C15.22 10.1856 15.7464 10.9536 15.5 11.3C15.2536 11.6464 15.0952 11.408 15.0952 11.408C15.0952 11.408 14.3432 12.1696 14.1064 12.3064C13.8704 12.4424 13.54 12.3064 13.54 12.3064C13.54 12.3064 13.4 12.5656 13.54 12.6848C13.824 12.9776 14.5616 13.2688 14.5616 13.2688C14.5616 13.2688 16.8296 13.6376 16.9 14.1C16.9704 14.5624 15.0952 16.9 14.8 16.9H14.1C13.8144 16.396 14.5616 14.7848 14.5616 14.7848C14.5616 14.7848 14.1776 14.3592 14.1 14.1C14.0232 13.8408 14.2488 13.3848 14.2488 13.3848L13.2256 12.9136C13.2256 12.9136 12.6816 12.9136 12.412 12.7C12.1424 12.4864 12 11.3 12 11.3L11.024 10.5848C11.024 10.5848 10.176 11.6616 9.9 11.524C9.6232 11.3864 9.308 11.164 9.5016 10.7584ZM14.7576 9.08C14.0824 9.08 12.4576 8.2208 12.7304 7.76C12.7304 7.0992 14.4384 7.1984 14.8048 7.1984C15.1712 7.1984 16.0688 7.4816 16.5672 7.8784C17.0656 8.2744 16.3568 8.8408 16.1096 9.08C15.8616 9.32 15.4336 9.08 14.7576 9.08Z"
        fill={color}
      />
    </svg>
  );
};

const SessionBookingThankyou = props => {
  const easyBookingThankYou = useSelector(state => state.easyBookingThankYou);
  const contribution = useContribution();
  const theme = useTheme();
  const { AccentColorCode = '', PrimaryColorCode = '' } = determineColorToUse(contribution);
  const [isVisibleCalendarDropdown, setIsVisibleCalendarDropdown] = useState(false);
  const [thanksYouPageData, setThanksYouPageData] = useState({});
  const { serviceProviderName = '', durations, coachAvatarUrl = '', isDarkModeEnabled = false } = contribution;
  const duration = durations && durations?.length ? durations[0] : 0;
  const dropdownRef = useRef(null);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { newThemedBackgroundColor, newThemedCardColor, newThemedTextColor } = getThemedColors(contribution);
  const themedColor = newThemedTextColor;
  const [links, setLinks] = useState({});
  const { user } = useAccount();
  const { showModal } = useSelector(state => state.easyBookingThankYou);
  const [openModal, setOpenModal] = useState(user?.isSecondaryExisting && !user?.isSecondaryAccount && showModal);

  useEffect(() => {
    setOpenModal(user?.isSecondaryExisting && !user?.isSecondaryAccount && showModal);
  }, [user]);

  useEffect(() => {
    setThanksYouPageData({
      timezone: easyBookingThankYou?.timeZoneThankYou,
      timeSlot: `${moment(easyBookingThankYou?.selectedSlot?.start).format('h:mma')} - ${moment(
        easyBookingThankYou?.selectedSlot?.end,
      ).format('h:mma')}, ${moment(easyBookingThankYou?.selectedDate).format('dddd, MMMM DD, YYYY')}`,
      selectedSlot: easyBookingThankYou?.selectedSlot,
    });
  }, [user, easyBookingThankYou]);

  const getIcalLinks = async () => {
    const icalLinks = await getIcalFileLinkOneToOneSession({
      contributionId: contribution.id,
      availabilityTimeId: thanksYouPageData?.selectedSlot?.id,
    });
    setLinks(icalLinks);
  };

  const handleClickEvent = type => {
    const link = links[type];
    if (link) {
      window.open(link, '_blank');
    }
  };

  useEffect(() => {
    if (thanksYouPageData?.selectedSlot && thanksYouPageData?.selectedSlot.id) {
      getIcalLinks();
    }
  }, [thanksYouPageData?.selectedSlot]);

  return (
    <>
      <StyledContainer>
        <StyledContainerBox
          mobileView={mobileView}
          style={{ ...(isDarkModeEnabled && { backgroundColor: newThemedCardColor, border: '1px solid #f5f5f550' }) }}
        >
          <StyledImageWrapper {...{ borderColor: PrimaryColorCode }}>
            <Avatar className="img-container" src={coachAvatarUrl} alt="coach-profile-pic" />
          </StyledImageWrapper>
          <StyledMessageContainer>
            <div className="main-message" style={{ color: newThemedTextColor }}>
              This session is scheduled.
            </div>
            <div className="sub-message" style={{ color: newThemedTextColor }}>
              A calendar invitation has been sent to your email.
            </div>
          </StyledMessageContainer>
          <StyledMeetingInfoBlock
            style={{ ...(isDarkModeEnabled && { backgroundColor: newThemedCardColor, border: '1px solid #f5f5f550' }) }}
          >
            <div className="meeting-duration" style={{ color: newThemedTextColor }}>
              {duration} {pluralize('Minute', duration)} Meeting
            </div>
            <div className="info">
              <div className="info-row">
                <div className="icon" style={{ height: '25px' }}>
                  <CoachIcon color={AccentColorCode} />
                </div>
                <div className="text coach-name" style={{ color: newThemedTextColor }}>
                  {serviceProviderName}
                </div>
              </div>
              <div className="info-row">
                <div className="icon" style={{ height: '22px' }}>
                  <DateCalendarIcon color={AccentColorCode} />
                </div>
                <div className="text meeting-date-time" style={{ color: newThemedTextColor }}>
                  {thanksYouPageData?.timeSlot}
                </div>
              </div>
              <div className="info-row">
                <div className="icon" style={{ height: '25px' }}>
                  <GlobeIcon color={AccentColorCode} />
                </div>
                <div className="text meeting-location" style={{ color: newThemedTextColor }}>
                  {thanksYouPageData?.timezone}
                </div>
              </div>
            </div>
            <StyledPContainer
              style={{
                borderRadius: '0.25rem',
                boxShadow: 'none',
                padding: '10px',
                border: `1px solid ${PrimaryColorCode}`,
                ...(isDarkModeEnabled && {
                  backgroundColor: PrimaryColorCode,
                }),
              }}
              {...{ mobileView }}
            >
              <StyledP
                newThemedTextColor={newThemedTextColor}
                isDarkModeEnabled={isDarkModeEnabled}
                ref={dropdownRef}
                onClick={() => setIsVisibleCalendarDropdown(!isVisibleCalendarDropdown)}
                style={{
                  width: '100%',
                  marginBottom: '0px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  ...(mobileView && { flexBasis: 'auto' }),
                }}
                className="course-live-session-time__label"
              >
                <BiCalendar
                  style={{ fontSize: 20, color: isDarkModeEnabled ? newThemedTextColor : PrimaryColorCode }}
                />{' '}
                <StyledTextAddCalendar
                  style={{
                    color: PrimaryColorCode,
                    ...(isDarkModeEnabled && {
                      color: newThemedTextColor,
                    }),
                  }}
                >
                  Add to Calendar
                </StyledTextAddCalendar>{' '}
                {!isVisibleCalendarDropdown ? (
                  <RiArrowDropDownLine
                    style={{
                      float: 'right',
                      fontSize: 20,
                      color: isDarkModeEnabled ? newThemedTextColor : PrimaryColorCode,
                    }}
                  />
                ) : (
                  <RiArrowDropUpLine
                    style={{
                      float: 'right',
                      fontSize: 20,
                      color: isDarkModeEnabled ? newThemedTextColor : PrimaryColorCode,
                    }}
                  />
                )}
              </StyledP>
              {isVisibleCalendarDropdown && (
                <ClickAwayListener
                  onClickAway={() => {
                    setIsVisibleCalendarDropdown(false);
                  }}
                >
                  <Box>
                    <CalendarContainer className="clickedOutSide" mobileView={mobileView}>
                      <CalendarMenu
                        newThemedTextColor={isDarkModeEnabled ? newThemedTextColor : themedColor}
                        className="clickedOutSide"
                        mobileView={mobileView}
                      >
                        <MenuItem
                          onClick={() => handleClickEvent('iCalFileLink')}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            borderBottom: '0.5px solid gray',
                            ...(isDarkModeEnabled && {
                              backgroundColor: newThemedBackgroundColor,
                              color: newThemedTextColor,
                            }),
                          }}
                        >
                          Download iCal
                        </MenuItem>
                        <MenuItem
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            ...(isDarkModeEnabled && {
                              backgroundColor: newThemedBackgroundColor,
                              color: newThemedTextColor,
                            }),
                          }}
                          onClick={() => handleClickEvent('importLink')}
                        >
                          Add to Google Calendar
                        </MenuItem>
                      </CalendarMenu>
                    </CalendarContainer>
                  </Box>
                </ClickAwayListener>
              )}
            </StyledPContainer>
          </StyledMeetingInfoBlock>
        </StyledContainerBox>
      </StyledContainer>
      {openModal && (
        <ExistingUserGuestContributionAddModal
          user={user}
          isOpen={openModal}
          setIsOpen={setOpenModal}
          PrimaryColorCode={PrimaryColorCode}
          newThemedTextColor={newThemedTextColor}
          newThemedBackgroundColor={newThemedBackgroundColor}
        />
      )}
    </>
  );
};

export default SessionBookingThankyou;
