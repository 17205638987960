import React, { Fragment, useState } from 'react';
import classes from './Options.module.scss';
import { questionTypes } from 'pages/CreateQuizes/Constants/constants';
import Option from '../Option/Option';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Select from 'components/UI/Select';
import Choice from '../../Modals/AddChoices/Choice/Choice';
import { useMediaQuery, useTheme } from '@material-ui/core';
const OPTIONS = [
  { id: 1, value: 'Option' },
  { id: 2, value: 'Option' },
];

const Options = ({ index, question, questionType, setFieldValue, onRemoveChoice }) => {
  console.log('question?.options---->', question?.options);
  const theme = useTheme();
  const [renderOptions, setRenderOptions] = useState(true);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const reRenderOptions = () => {
    setRenderOptions(false);
    setTimeout(() => {
      setRenderOptions(true);
    }, 200);
  };

  const handleDragEnd = (results, options, setFieldValue) => {
    const { source, destination } = results;
    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const reOrderedOptions = [...options];
    const destinationOption = reOrderedOptions[destination.index];
    const [removedSection] = reOrderedOptions.splice(source.index, 1);
    reOrderedOptions.splice(destination.index, 0, removedSection);

    setFieldValue(`questions[${index}].options`, reOrderedOptions);
    reRenderOptions();
  };

  const onRemove = idx => {
    onRemoveChoice(idx);
    reRenderOptions();
  };

  if (question?.questionType === questionTypes.dropdown) {
    return (
      <DragDropContext onDragEnd={results => handleDragEnd(results, question?.options, setFieldValue)}>
        <Droppable droppableId="options" type="option" className="test">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef} className={classes.options_list}>
              {renderOptions &&
                question?.options?.map((option, idx) => {
                  return (
                    <Fragment key={idx}>
                      <Draggable draggableId={`option-${idx}`} key={`option-${idx}`} index={idx}>
                        {provided => (
                          <div {...provided.draggableProps} ref={provided.innerRef}>
                            <Choice
                              key={index}
                              choice={option}
                              index={idx}
                              parentProvided={provided}
                              setFieldValue={setFieldValue}
                              removeChoice={onRemove}
                              allChoices={question?.options}
                              mobileView={mobileView}
                              questionsIndex={index}
                              question={question}
                            />
                          </div>
                        )}
                      </Draggable>
                    </Fragment>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  return (
    <DragDropContext onDragEnd={results => handleDragEnd(results, question?.options, setFieldValue)}>
      <Droppable droppableId="options" type="option" className="test">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef} className={classes.options_list}>
            {renderOptions &&
              question?.options?.map((option, idx) => {
                return (
                  <Fragment key={idx}>
                    <Draggable draggableId={`option-${idx}`} key={`option-${idx}`} index={idx}>
                      {provided => (
                        <div {...provided.draggableProps} ref={provided.innerRef}>
                          <Option
                            index={idx}
                            questionsIndex={index}
                            parentProvided={provided}
                            option={option}
                            setFieldValue={setFieldValue}
                            onRemoveChoice={onRemove}
                            question={question}
                          />
                        </div>
                      )}
                    </Draggable>
                  </Fragment>
                );
              })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Options;
