import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';

export const addPublicFile = data =>
  axiosInstance
    .post('/Content/AddPublicFile', data, {
      'Content-Type': 'multipart/form-data',
    })
    .then(get('data'));

export const addRecordedSession = async (
  data,
  contributionId,
  partNumber,
  isLastPart,
  documentId,
  fileName,
  uploadId,
  prevETags,
  handleUpdateProgress,
) =>
  axiosInstance
    .post(
      `/Content/AddRecordedAttachmentOnContribution?contributionId=${contributionId}&partNumber=${partNumber}&isLastPart=${isLastPart}&documentId=${documentId}&fileName=${encodeURIComponent(
        fileName,
      )}&uploadId=${uploadId}&prevETags=${prevETags}`,
      data,
      {
        'Content-Type': 'multipart/form-data',
        onUploadProgress: progressEvent => {
          handleUpdateProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
        },
      },
    )
    .then(get('data'));

export const addLeadMagnetRecordedSession = async (
  data,
  leadMagnetId,
  partNumber,
  isLastPart,
  documentId,
  fileName,
  uploadId,
  prevETags,
  handleUpdateProgress,
  videoDuration,
) =>
  axiosInstance
    .post(
      `/Content/AddRecordedAttachmentOnLeadMagnet?leadMagnetId=${leadMagnetId}&partNumber=${partNumber}&isLastPart=${isLastPart}&documentId=${documentId}&fileName=${encodeURIComponent(
        fileName,
      )}&uploadId=${uploadId}&prevETags=${prevETags}`,
      data,
      {
        'Content-Type': 'multipart/form-data',
        onUploadProgress: progressEvent => {
          handleUpdateProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
        },
      },
    )
    .then(get('data'));

export const addFileToSession = async (
  data,
  contributionId,
  sessionId,
  partNumber,
  isLastPart,
  documentId,
  fileName,
  uploadId,
  prevETags,
  handleUpdateProgress,
) =>
  axiosInstance
    .post(
      `/Content/AddAttachmentToExisted?contributionId=${contributionId}&sessionId=${sessionId}&partNumber=${partNumber}&isLastPart=${isLastPart}&documentId=${documentId}&fileName=${encodeURIComponent(
        fileName,
      )}&uploadId=${uploadId}&prevETags=${prevETags}`,
      data,
      {
        'Content-Type': 'multipart/form-data',
        onUploadProgress: progressEvent => {
          handleUpdateProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
        },
      },
    )
    .then(get('data'));

export const addFileToSessionTime = async (
  data,
  contributionId,
  sessionTimeId,
  partNumber,
  isLastPart,
  documentId,
  fileName,
  uploadId,
  prevETags,
  handleUpdateProgress,
  roomId,
  duration,
) =>
  axiosInstance
    .post(`/Content/AddAttachmentToExistedSessionTime`, data, {
      'Content-Type': 'multipart/form-data',
      onUploadProgress: progressEvent => {
        handleUpdateProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
      },
      params: {
        contributionId,
        sessionTimeId,
        roomId,
        partNumber,
        isLastPart,
        documentId,
        fileName,
        uploadId,
        prevETags,
        duration,
        replyLink: window.location.href,
      },
    })
    .then(get('data'))
    .catch(error => {
      console.dir(error);
    });

export const DeleteSessionTimeRecording = async (contributionId, sessionTimeId, roomId, compositionFileName) =>
  axiosInstance
    .delete(`/Content/DeleteSessionTimeRecording`, {
      params: {
        contributionId,
        sessionTimeId,
        roomId,
        compositionFileName,
      },
    })
    .catch(error => {
      console.dir(error);
    });

export const addFileToPost = async (
  data,
  postId,
  partNumber,
  isLastPart,
  documentId,
  fileName,
  fileType,
  uploadId,
  prevETags,
  isCommentType,
  handleUpdateProgress,
) =>
  axiosInstance
    .post(
      `/Content/AddAttachmentToPost?postId=${postId}&partNumber=${partNumber}&isLastPart=${isLastPart}&documentId=${documentId}&fileName=${encodeURIComponent(
        fileName,
      )}&fileType=${fileType}&uploadId=${uploadId}&prevETags=${prevETags}&isCommentType=${isCommentType}`,
      data,
      {
        'Content-Type': 'multipart/form-data',
        onUploadProgress: progressEvent => {
          handleUpdateProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
        },
      },
    )
    .then(get('data'));

export const addFileToPostForUnifiedCommunityPost = async (
  data,
  partNumber,
  isLastPart,
  documentId,
  fileName,
  fileType,
  uploadId,
  prevETags,
  handleUpdateProgress,
) => {
  const response = await axiosInstance.post(
    `/Content/AddAttachmentForUnifiedCommunityPost?partNumber=${partNumber}&isLastPart=${isLastPart}&documentId=${documentId}&fileName=${encodeURIComponent(
      fileName,
    )}&fileType=${fileType}&uploadId=${uploadId}&prevETags=${prevETags}&isCommenttype=false`,
    data,
    {
      'Content-Type': 'multipart/form-data',
      onUploadProgress: progressEvent => {
        handleUpdateProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
      },
    },
  );
  return response.data;
};

export const addResourceToContribution = async (
  data,
  contributionId,
  partNumber,
  isLastPart,
  documentId,
  fileName,
  uploadId,
  prevETags,
  handleUpdateProgress,
  groupId,
  cancelTokenSource,
) => {
  const response = await axiosInstance.post(
    `/Content/AddAttachmentInResourceFolder/${contributionId}?partNumber=${partNumber}&isLastPart=${isLastPart}&documentId=${documentId}&fileName=${encodeURIComponent(
      fileName,
    )}&uploadId=${uploadId}&prevETags=${prevETags}${groupId ? '&groupId='.concat(groupId) : ''}`,
    data,
    {
      'Content-Type': 'multipart/form-data',
      onUploadProgress: progressEvent => {
        handleUpdateProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
      },
      cancelToken: cancelTokenSource?.token,
    },
  );
  return response.data;
};

export const addAttachmentOnThankyouPage = async (
  data,
  partNumber,
  isLastPart,
  fileName,
  uploadId,
  prevETags,
  handleUpdateProgress,
  cancelTokenSource,
) => {
  const response = await axiosInstance.post(
    `/Content/AddAttachmentOnThankyouPage/?partNumber=${partNumber}&isLastPart=${isLastPart}&fileName=${encodeURIComponent(
      fileName,
    )}&uploadId=${uploadId}&prevETags=${prevETags}`,
    data,
    {
      'Content-Type': 'multipart/form-data',
      onUploadProgress: progressEvent => {
        handleUpdateProgress(Math.round((100 * progressEvent.loaded) / progressEvent.total));
      },
      cancelToken: cancelTokenSource?.token,
    },
  );
  return response.data;
};

export const deletePublicImage = fileUrl => axiosInstance.post(`/Content/DeletePublicImage?imageLink=${fileUrl}`);
