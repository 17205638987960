import React from 'react';
import classes from './Multiple.module.scss';
import CheckIcon from '../../../Assests/Icons/CheckIcon';

const Multiple = () => {
  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <CheckIcon />
      </div>
      <div className={classes.type_name}>Multiple Choice</div>
    </div>
  );
};

export default Multiple;
