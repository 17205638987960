import React, { useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PropTypes from 'prop-types';
import { UserRoles } from 'helpers/constants';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { connect, useSelector } from 'react-redux';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ShareIcon from '@material-ui/icons/Share';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {
  deleteLinksInGroup,
  getCommunityGroupLinks,
  getCommunityGroupResources,
} from 'services/communitySections.service';
import { updateCurrentGroupData, updateSectionsMetaOnly } from 'actions/communitySections';
import { addhttp } from 'utils/utils';
import Axios from 'axios';
import Loader from 'components/UI/Loader';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import DeleteIcon from './DeleteIcon';
import { TOOLTIP } from '../../../../constants';
import LinkIcon from './LinkIcon';
import { determineColorToUse } from 'services/contributions.service';
function RightSectionMenu({
  currentRole,
  mobileView,
  updateCurrentGroup,
  setOpenLinks,
  setOpenResources,
  isDarkThemeEnabled,
  loading,
  setLoading,
  onDeleteResource,
  hideRightPanel,
  isFetching,
  successMessage,
}) {
  const isCoach = currentRole === UserRoles.cohealer;
  const { currentGroup } = useSelector(state => state.communitySections);
  const contribution = useContribution();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showLinks, setShowLinks] = useState(true);
  const [showResources, setShowResources] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  let colorToUse = determineColorToUse(contribution);
  const copyToClipboard = async text => {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };
  useEffect(() => {
    const currentGroupId = currentGroup?.id || '';
    if (!currentGroupId || (currentGroup?.links?.length > 0 && currentGroup?.resources?.length > 0) || isFetching)
      return;
    setLoadingData(true);
    Axios.all([
      getCommunityGroupResources({ contributionId: contribution?.id, groupId: currentGroupId }),
      getCommunityGroupLinks({ groupId: currentGroupId }),
    ])
      .then(res => {
        updateCurrentGroup({
          group: {
            ...currentGroup,
            resources: res[0],
            links: res[1] !== '' ? res[1] : [],
          },
        });
      })
      .finally(() => setLoadingData(false));
  }, [currentGroup?.id, isFetching]);

  const onDeleteLinkClick = linkId => {
    setLoading(true);
    deleteLinksInGroup({
      linkId,
      groupId: currentGroup?.id,
    })
      .then(res => {
        updateCurrentGroup({
          group: {
            ...currentGroup,
            links: res,
          },
        });
        successMessage('Link Delete');
      })
      .finally(() => setLoading(false));
  };

  const toggle = type => {
    if (!mobileView) {
      return null;
    }
    if (type === 'links') {
      return setShowLinks(!showLinks);
    }
    return setShowResources(!showResources);
  };

  return (
    <>
      {loading && <Loader />}
      <div
        className={`community-left-section${isDarkThemeEnabled ? ' dark-mode' : ''} ${
          hideRightPanel ? ' invisible' : ''
        }`}
      >
        <div className="heading">
          <div
            className={`title ${showLinks ? 'open' : ''}`}
            tabIndex="0"
            role="button"
            onKeyDown={() => toggle('links')}
            onClick={() => toggle('links')}
          >
            <div>
              {mobileView && <ArrowForwardIosIcon style={{ color: isDarkThemeEnabled ? 'white' : 'black' }} />} Links
            </div>
            {isCoach && (
              <IconButton onClick={() => setOpenLinks(true)}>
                <AddCircleOutlineIcon style={{ color: isDarkThemeEnabled ? 'white' : 'black' }} />
              </IconButton>
            )}
          </div>
          <div className="data-list">
            {!loadingData && !isCoach && currentGroup?.links && currentGroup?.links.length === 0 && (
              <p style={{ color: colorToUse?.AccentColorCode }}>No links added</p>
            )}
            {!loadingData &&
              showLinks &&
              currentGroup?.links?.map(link => {
                return (
                  <span className="community-group-link">
                    <span className="community-data-title">
                      <a className="name" href={addhttp(link?.url || '')} target="_blank" rel="noreferrer">
                        <Tooltip
                          placement="bottom-start"
                          title={link?.title}
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <span>{link?.title}</span>
                        </Tooltip>
                      </a>
                    </span>
                    <span className="community-group-link-actions">
                      {isCoach && (
                        <span
                          className="group-link-delete-icon"
                          onClick={() => onDeleteLinkClick(link?.id)}
                          onKeyUp={() => {}}
                          role="button"
                          tabIndex="0"
                        >
                          <DeleteIcon fillColor={isDarkThemeEnabled ? 'white' : colorToUse?.PrimaryColorCode} />
                        </span>
                      )}
                      <span
                        onKeyUp={() => {}}
                        role="button"
                        tabIndex="0"
                        onClick={() => window.open(addhttp(link?.url || ''), '_blank')}
                      >
                        <LinkIcon
                          className="link-icon"
                          color={isDarkThemeEnabled ? 'white' : colorToUse?.PrimaryColorCode}
                        />
                      </span>
                    </span>
                  </span>
                );
              })}
            {loadingData && (
              <>
                <Typography variant="h4">
                  <Skeleton animation="wave" />
                </Typography>
                <Typography variant="h4">
                  <Skeleton animation="wave" />
                </Typography>
              </>
            )}
          </div>
        </div>
        <div className="heading">
          <div
            className={`title ${showResources ? 'open' : ''}`}
            tabIndex="0"
            role="button"
            onKeyDown={() => toggle('resources')}
            onClick={() => toggle('resources')}
          >
            <div>
              {mobileView && <ArrowForwardIosIcon style={{ color: isDarkThemeEnabled ? 'white' : 'black' }} />}
              Resources
            </div>
            {isCoach && (
              <IconButton onClick={() => setOpenResources(true)}>
                <AddCircleOutlineIcon style={{ color: isDarkThemeEnabled ? 'white' : 'black' }} />
              </IconButton>
            )}
          </div>
          <div className="data-list">
            {!loadingData && !isCoach && currentGroup?.resources && currentGroup?.resources?.length === 0 && (
              <p style={{ color: colorToUse?.AccentColorCode }}>No resources added</p>
            )}
            {!loadingData &&
              showResources &&
              currentGroup?.resources?.map(resource => {
                return (
                  <span className="community-group-link">
                    <span className="community-data-title">
                      <DescriptionOutlinedIcon
                        style={{
                          color: isDarkThemeEnabled ? 'white' : colorToUse?.PrimaryColorCode,
                          height: '30px',
                          width: '30px',
                        }}
                      />
                      <a className="name" href={addhttp(resource?.url || '')} target="_blank" rel="noreferrer">
                        <Tooltip
                          placement="bottom-start"
                          title={resource?.name}
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <span style={{ fontWeight: '400', fontSize: '16px' }}>{resource?.name}</span>
                        </Tooltip>
                      </a>
                    </span>
                    <span className="community-group-link-actions">
                      {isCoach && (
                        <span
                          className="group-link-delete-icon"
                          onClick={() => {
                            setTooltipOpen(true);
                            copyToClipboard(resource?.url);
                            setTimeout(() => {
                              setTooltipOpen(false);
                              // setIsDropDownOpen(false);
                            }, 2000);
                          }}
                          onKeyUp={() => {}}
                          role="button"
                          tabIndex="0"
                        >
                          <Tooltip
                            className="cursor-pointer"
                            title="Link Copied"
                            open={tooltipOpen}
                            onClose={() => {}}
                            arrow
                          >
                            <ShareIcon htmlColor={isDarkThemeEnabled ? 'white' : colorToUse?.PrimaryColorCode} />
                          </Tooltip>
                        </span>
                      )}
                      {isCoach && (
                        <span
                          className="group-link-delete-icon"
                          onClick={() => onDeleteResource(resource?.documentId)}
                          onKeyUp={() => {}}
                          role="button"
                          tabIndex="0"
                        >
                          <DeleteIcon fillColor={isDarkThemeEnabled ? 'white' : colorToUse?.PrimaryColorCode} />
                        </span>
                      )}
                      <IconButton onClick={() => window.open(resource.url, '_blank')} className="p-0">
                        <SaveAltIcon style={{ color: isDarkThemeEnabled ? 'white' : colorToUse?.PrimaryColorCode }} />
                      </IconButton>
                    </span>
                  </span>
                );
              })}
            {loadingData && (
              <>
                <Typography variant="h3">
                  <Skeleton animation="wave" />
                </Typography>
                <Typography variant="h3">
                  <Skeleton animation="wave" />
                </Typography>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
const actions = {
  getSectionsMeta: updateSectionsMetaOnly,
  updateCurrentGroup: updateCurrentGroupData,
};

const mapStateToProps = ({ communitySections: { isFetching } }) => ({
  isFetching,
});

RightSectionMenu.propTypes = {
  currentRole: PropTypes.string.isRequired,
  mobileView: PropTypes.bool.isRequired,
  updateCurrentGroup: PropTypes.func.isRequired,
  setOpenLinks: PropTypes.func.isRequired,
  setOpenResources: PropTypes.func.isRequired,
  isDarkThemeEnabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func.isRequired,
  hideRightPanel: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  successMessage: PropTypes.func.isRequired,
};

export default connect(() => mapStateToProps, actions)(RightSectionMenu);
