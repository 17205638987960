import React from 'react';

const CheckIcon = () => {
  return (
    <svg width="12" height="7" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 3.5L4.33333 7L10.5 1"
        stroke="#213649"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
