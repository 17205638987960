import React, { useState, useEffect } from 'react';
import { Tooltip, Grid, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';
import classes from './EmailMarketing.module.scss';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-activity';
import { EmailMarketingIntegrationService } from '../service/marketingAccountIntegration.service';
import { fetchDomainsActions, setDomainInAuthProcess, setDomains, setLastSender } from 'actions/emailMarketing.actions';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import Modal from 'components/UI/Modal';
import TextField from '@mui/material/TextField';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import InfoIcon from '@material-ui/icons/Info';
import Input from './Input/Input';
import { colors } from 'utils/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setAccount } from 'actions/emailMarketing.actions';
import useAccount from 'hooks/useAccount';
import SetDefaultModal from './Modals/SetDefaultModal/SetDefaultModal';
import ErrorModal from './Modals/ErrorModal/ErrorModal';
import { setSenderIdentities } from 'actions/emailMarketing.actions';
import Row from './Row/Row';
import DefaultRow from './DefaultRow/DefaultRow';
import useRouter from 'hooks/useRouter';
import RegisterDomainModal from './Modals/RegisterDomainModal/RegisterDomainModal';
import { useHistory } from 'react-router-dom';
import MUISelect from 'components/UI/Select';
import Loader from 'components/UI/Loader';
import emailMarketingReducer from 'reducers/emailMarketing.reducer';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { editProfile, getProfile } from 'actions/user';
import useEmailMarketing from 'pages/CreateCampaign/Hooks/useEmailMarketing';

const StyledLink = styled(Link)`
  cursor: pointer;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 800;
  line-height: 32px;
  color: #215c73;
  &:hover {
    color: #215c73;
  }
`;
const NarrowTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15,
  },
})(TableCell);

const StyledButton = styled(Button)`
  margin-right: ${({ googleBtn }) => (googleBtn ? '5px' : '0px')};
  ${({ googleBtn }) =>
    googleBtn
      ? `
  background-color: white;
  color: gray;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  '&:hover': {
    background-color: white;
  } 
  `
      : ''};
`;

const CalendarRecordActionsColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  svg {
    cursor: pointer;
  }
`;

const BoldTableCell = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: ({ mobileView }) => (mobileView ? '16px' : '17.8px'),
    fontWeight: 500,
    letterSpacing: '0.15px',
    flexWrap: 'wrap',
  },
})(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    borderRadius: '4px',
    border: '1px solid #f5f0f0',
    outline: '1px solid #f5f0f0',
    border: '0 !important',
    alignSelf: 'center',
  },
  noBorder: {
    border: 'none',
  },
}));

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const CalendarInfoWrapper = styled.div`
  padding: '20px';
  display: flex;
  align-items: center;
  cursor: ${props => (!props.isDefault ? 'pointer' : 'default')};
`;

const WarningWrapper = styled.div`
  margin-top: 10px;
`;

const DetailsWrapper = styled.div`
  margin-top: 10px;
`;

const CardMainContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 4px;
  padding: 0px;
`;

const VerifiedEmail = styled.div`
  font-family: Avenir;
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  color: #ffffff;
  background-color: #215c73;
  border-radius: 4px;
  padding: 2px 6px;
`;

const NotVerifiedEmail = styled.div`
  font-family: Avenir;
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  color: #215c73;
  background-color: white;
  border-radius: 4px;
  border: 2px solid #215c73;
  padding: 2px 6px;
`;

const DefaultEmail = styled.div`
  font-family: Avenir;
  font-size: 11px;
  font-weight: 800;
  line-height: 16px;
  color: #ffffff;
  background-color: #c9b382;
  border-radius: 4px;
  padding: 2px 6px;
`;

const StyledEmail = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: background: rgba(0, 0, 0, 0.87);
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 15px 0px;
`;

const StyledRowWrapper = styled.div`
  width: 100%;
`;

const StyledAnother = styled.div`
  font-family: 'Avenir';
  font-size: 14px;
  font-weight: 900;
  line-height: 16px;
  color: #215c73;
  margin: 10px 0px;
  cursor: pointer;
`;

export const EmailMarketing = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useAccount();
  console.log('user----->', user);
  const { location } = useRouter();
  const history = useHistory();
  const businessName = user?.businessName;
  const isComingFromConnectDomain = location?.state?.from === 'connectdomain';
  const lastDomainAdded = location?.state?.data || {};
  const isComingFromDomainVerificationPage = location?.state?.from === 'domainverification';
  const isSendGridConnected = user?.isSendGridConnected;
  const domains = useSelector(state => state?.emailMarketing?.domains) || [];
  const verifiedDomains = domains?.filter(domain => domain.isVerified === true) || [];
  const verifiedDomainExists = domains?.some(domain => domain.isVerified === true);
  const lastSenderAdded = useSelector(state => state?.emailMarketing?.lastSenderAdded);
  const [isLoading, setIsLoading] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [accountCreationError, setAccountCreationError] = useState('');
  const [domainError, setDomainError] = useState('');
  const [addEmailPopUpOpen, setAddEmailPopUpOpen] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(
    verifiedDomains[0]?.domain.includes('www.')
      ? verifiedDomains[0]?.domain.replace('www.', '')
      : verifiedDomains[0]?.domain || '',
  );
  const openRegisterDomainModal = (!domains || !domains.length) && isComingFromConnectDomain;
  const [isRegisterDomainModalOpen, setIsRegisterDomainModalOpen] = useState(openRegisterDomainModal);
  const [isDomainVerifiedModalOpen, setisDomainVerifiedModalOpen] = useState(isComingFromDomainVerificationPage);
  const [selectedSenderAccount, setSelectedSenderAccount] = useState(null);
  const [selectedSenderAccountForDefault, setSelectedSenderAccountForDefault] = useState(null);
  const [makeDefaultModalOpen, setMakeDefaultModalOpen] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [isRemoveSenderModalOpen, setIsRemoveSenderModalOpen] = useState(false);
  const [verifyEmailPopUpOpen, setVerifyEmailPopUpOpen] = useState(false);
  const [openedRow, setOpenedRow] = useState(null);
  const [domain, setDomain] = useState('');
  const [email, setEmail] = useState('');
  const [physicalAddress, setPhysicalAddress] = useState('');
  const [isPhysicalAddressModalOpen, setIsPhysicalAddressModalOpen] = useState(false);
  const { verifiedSendingAddresses } = useEmailMarketing();
  const showPhysicalAddressBtn = isSendGridConnected && verifiedSendingAddresses?.length > 0 && !user?.address;
  const [emailDomain, setEmailDomain] = useState('');

  useEffect(() => {
    if (domains.length > 0) {
      setSelectedDomain(
        domains[0]?.domain.includes('www.') ? domains[0]?.domain.replace('www.', '') : domains[0]?.domain,
      );
    }
  }, [domains]);

  const handlehowToVideos = ({}) => {
    window.open('https://www.cohere.live/how-to-library/', '_blank');
  };

  const handleConnectClick = () => {
    setAddEmailPopUpOpen(true);
  };

  const resendClickHandle = item => {
    setSelectedSenderAccount(item?.email);
    setVerifyEmailPopUpOpen(true);
  };

  const createAccountAndAddDomainAuthentication = params => {
    const { domain } = params;
    setIsLoading(true);
    EmailMarketingIntegrationService.createEmailMarketingAccount(params)
      .then(res => {
        dispatch(setAccount(res));
        dispatch(setDomains(res?.domainData));
        dispatch(setDomainInAuthProcess(domain));
        dispatch(setLastSender(email));
        setSelectedSenderAccount(email);
        // setVerifyEmailPopUpOpen(true);
        setIsLoading(false);
        setIsRegisterDomainModalOpen(false);
        history.push(`${location?.pathname}/authenticate-domain`);
      })
      .catch(err => {
        const errorMessage = err?.response?.data?.message || err?.message;
        setAccountCreationError(errorMessage);
        console.log('errorMessage', errorMessage);
        setIsLoading(false);
        setIsRegisterDomainModalOpen(false);
      });
  };

  const addDomainAuthentication = params => {
    const { domain } = params;
    setIsLoading(true);
    EmailMarketingIntegrationService.addDomainAuthentication(params)
      .then(res => {
        dispatch(setAccount(res));
        dispatch(setDomains(res?.domainData));
        dispatch(setDomainInAuthProcess(domain));
        dispatch(setLastSender(email));
        setSelectedSenderAccount(email);
        // setVerifyEmailPopUpOpen(true);
        setIsLoading(false);
        setIsRegisterDomainModalOpen(false);
        history.push(`${location?.pathname}/authenticate-domain`);
      })
      .catch(err => {
        const errorMessage = err?.response?.data?.message || err?.message;
        setAccountCreationError(errorMessage);
        setIsLoading(false);
        setIsRegisterDomainModalOpen(false);
      });
  };

  const resendVerificationEmail = () => {
    setIsLoading(true);
    EmailMarketingIntegrationService.resendSenderVerificationEmail(lastSenderAdded)
      .then(res => {
        console.log('Verification email sent');
        setIsLoading(false);
        setVerifyEmailPopUpOpen(false);
      })
      .catch(err => {
        console.log('err', err);
        setIsLoading(false);
      });
  };

  const handleSettingClick = account => {
    setSelectedSenderAccountForDefault(account);
    setMakeDefaultModalOpen(true);
  };

  const changeDefault = selectedSender => {
    setIsLoading(true);
    const { email, isDefault } = selectedSender;
    if (isDefault) {
      EmailMarketingIntegrationService.setAsDefaultSender(email)
        .then(res => {
          const updatedDomains = domains?.map(domain => {
            if (email.includes(domain?.domain)) {
              return { ...domain, senderIdentities: res };
            } else {
              return domain;
            }
          });
          dispatch(setDomains(updatedDomains));
          setIsLoading(false);
          setMakeDefaultModalOpen(false);
        })
        .catch(err => {
          setIsLoading(false);
          console.log('err', err);
          const errorMessage = err?.response?.data?.message || err?.message;
          setAccountCreationError(errorMessage);
        });
    } else {
      setIsLoading(false);
      setMakeDefaultModalOpen(false);
    }
  };

  const handleCrossClick = account => {
    setSelectedSenderAccount(account);
    setIsRemoveSenderModalOpen(true);
  };

  const removeSenderAccount = account => {
    const isEmail = account?.includes('@');
    if (isEmail) {
      setIsLoading(true);
      EmailMarketingIntegrationService.removeSender(account)
        .then(res => {
          console.log('res', res);
          dispatch(setDomains(res?.domainData));

          setIsRemoveSenderModalOpen(false);
        })
        .catch(err => {
          console.log('err', err);
          const errorMessage = err?.response?.data?.message || err?.message;
          setAccountCreationError(errorMessage);
          setIsRemoveSenderModalOpen(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      //Do Nothing for now...
      setIsLoading(true);
      EmailMarketingIntegrationService.removeDomain(account)
        .then(res => {
          console.log('res', res);
          dispatch(setDomains(res?.domainData));

          setIsRemoveSenderModalOpen(false);
        })
        .catch(err => {
          console.log('err', err);
          const errorMessage = err?.response?.data?.message || err?.message;
          setAccountCreationError(errorMessage);
          setIsRemoveSenderModalOpen(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleEmailChange = e => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/@/g, ''); // Remove all "@" symbols

    // Update the input field with the sanitized value

    setEmail(sanitizedValue);
  };

  const handlePhysicalAddressChange = e => {
    const inputValue = e.target.value;
    if (inputValue?.length <= 1000) {
      setPhysicalAddress(inputValue);
    }
  };

  const handleDomainChange = e => {
    const inputDomain = e.target.value;
    const isValid = /^[a-zA-Z0-9.-]*$/.test(inputDomain);
    if (inputDomain.length > 35) {
      setDomainError('Domain should be 35 characters long');
      return;
    } else {
      setDomainError('');
    }
    if (isValid && inputDomain.length <= 35) {
      setDomain(inputDomain);

      let emailDomain = inputDomain;
      const parts = emailDomain.split('.');

      if (parts.length > 2) {
        emailDomain = parts.slice(-2).join('.');
      }
      setEmailDomain(emailDomain);
    }
  };

  const handleAuthenticateClick = () => {
    setIsRegisterDomainModalOpen(true);
  };

  const isAuthenticatedDomain = domain => {
    let isAuthenticatedDomain = false;
    const domainData = domains?.find(d => d.domain === domain);
    if (domainData?.isVerified) {
      isAuthenticatedDomain = true;
    }

    return isAuthenticatedDomain;
  };

  return (
    <CardMainContainer style={{ marginBottom: '50px' }} id="integration3" className={classes.container}>
      {isLoading && <Loader />}
      <TableContainer id="integration1" component={Paper} style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
        <Table className="" aria-label="contributions table">
          <TableHead>
            <TableRow>
              <BoldTableCell mobileView={mobileView}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className={classes.heading}>Email Marketing</div>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                  <StyledButton mobileView={mobileView} autoWidth onClick={handleAuthenticateClick}>
                    Authenticate My Domain
                  </StyledButton>
                  {domains?.length > 0 && (
                    <StyledButton
                      mobileView={mobileView}
                      autoWidth
                      onClick={handleConnectClick}
                      disabled={!verifiedDomainExists}
                    >
                      Setup Sending Address
                    </StyledButton>
                  )}
                  {showPhysicalAddressBtn && (
                    <StyledButton
                      mobileView={mobileView}
                      autoWidth
                      onClick={() => {
                        setIsPhysicalAddressModalOpen(true);
                      }}
                    >
                      Add Physical Address
                    </StyledButton>
                  )}
                </div>
              </BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {domains &&
              domains?.map((item, i) => {
                if (!item?.id) return;

                return (
                  <>
                    <Row
                      key={i}
                      item={item}
                      isLoading={isLoading}
                      handleCrossClick={handleCrossClick}
                      handleSettingClick={handleSettingClick}
                      resendClickHandle={resendClickHandle}
                      tableRow={true}
                      type="domain"
                    />
                    {item?.senderIdentities.length > 0 &&
                      item?.senderIdentities.map((item, i) => (
                        <Row
                          key={i}
                          item={item}
                          isLoading={isLoading}
                          handleCrossClick={handleCrossClick}
                          handleSettingClick={handleSettingClick}
                          resendClickHandle={resendClickHandle}
                          tableRow={true}
                          type="email"
                        />
                      ))}
                  </>
                );
              })}
            {(!domains || !domains.length) && (
              <div className={classes.body}>
                <DetailsWrapper>
                  To setup Email Marketing, you will need to have an Accelerate plan and you will need connect a domain
                  that you currently own. Click ‘Authenticate My Domain’ to get started.
                </DetailsWrapper>
              </div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Please enter a valid email address'),
        })}
        onSubmit={() => {}}
      >
        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, touched, errors, isValid }) => {
          return (
            <>
              {/* <Modal
                isOpen={addEmailPopUpOpen}
                submitTitle="Add Email"
                onSubmit={() => {
                  if (values?.email.length > 0 && isValid) {
                    if (isSendGridConnected) {
                      addSender(values?.email);
                    } else {
                      createAccountAndAddSender(values?.email);
                    }
                    setShowEmailInput(false);
                  }
                }}
                title="Where do you want your marketing emails to come from?"
                onCancel={() => {
                  setAddEmailPopUpOpen(false);
                  setFieldValue('email', '');
                  setShowEmailInput(false);
                }}
                onCloseClick={() => {
                  setAddEmailPopUpOpen(false);
                  setFieldValue('email', '');
                  setShowEmailInput(false);
                }}
                closeClick={true}
                cancelTitle="Cancel"
                shrinkCancelButton={true}
                loading={isLoading}
                widthRequired
              >
                <StyledWrapper>
                  {senderIdentities &&
                    senderIdentities?.map((item, i) => {
                      if (item.id === openedRow) {
                        return (
                          <DefaultRow
                            sender={item}
                            showLessOption={true}
                            showLessClick={() => {
                              setOpenedRow(null);
                            }}
                          />
                        );
                      } else {
                        return (
                          <Row
                            key={i}
                            item={item}
                            isLoading={isLoading}
                            handleCrossClick={handleCrossClick}
                            handleSettingClick={() => {
                              setOpenedRow(item?.id);
                            }}
                            resendClickHandle={resendClickHandle}
                          />
                        );
                      }
                    })}
                  {(senderIdentities.length === 0 || showEmailInput) && (
                    <Input
                      name="email"
                      type="text"
                      placeholder="Add Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={touched && touched.email && errors && errors.email}
                    />
                  )}
                  {senderIdentities.length > 0 && !showEmailInput && (
                    <StyledAnother
                      onClick={() => {
                        setShowEmailInput(true);
                      }}
                    >
                      Add Another
                    </StyledAnother>
                  )}
                </StyledWrapper>
              </Modal> */}
              <Modal
                isOpen={verifyEmailPopUpOpen}
                submitTitle="Open Email Client"
                onSubmit={() => {
                  window.location.href = `mailto:${values.email}`;
                  setVerifyEmailPopUpOpen(false);
                  setFieldValue('email', '');
                }}
                title="Please verify your email"
                onCancel={() => {
                  resendVerificationEmail();
                  setFieldValue('email', '');
                }}
                onCloseClick={() => {
                  setVerifyEmailPopUpOpen(false);
                  setFieldValue('email', '');
                }}
                closeClick={true}
                cancelTitle="Resend Email"
                shrinkCancelButton={true}
                widthRequired
                footer="Still can't find the email?"
                verifyModal
                cancelBtnStyle={{
                  color: colors.newGold,
                  border: `1px solid ${colors.newGold}`,
                }}
                loading={isLoading}
              >
                <div style={{ margin: '0px 0px 20px 0px' }}>
                  <div style={{ margin: '10px 0px' }}>
                    <div>You’re almost there! We sent an email to</div>
                    <div>
                      <strong>{selectedSenderAccount}</strong>
                    </div>
                  </div>

                  <div style={{ margin: '10px 0px' }}>
                    <div>Just click on the link in that email to complete your signup.</div>
                    <div>
                      If you don’t see it, you may need to <strong>check your spam</strong> folder.
                    </div>
                  </div>
                </div>
              </Modal>
              {makeDefaultModalOpen && (
                <SetDefaultModal
                  isOpen={makeDefaultModalOpen}
                  title="Settings"
                  submitTitle="Done"
                  cancelTitle="Cancel"
                  onCancel={() => {
                    setMakeDefaultModalOpen(false);
                  }}
                  disableSubmitClick={isLoading}
                  onSubmit={() => {
                    changeDefault(selectedSenderAccountForDefault);
                  }}
                  sender={selectedSenderAccountForDefault}
                  onCheckedChange={() => {
                    setSelectedSenderAccountForDefault({
                      ...selectedSenderAccountForDefault,
                      isDefault: !selectedSenderAccountForDefault?.isDefault,
                    });
                  }}
                  widthRequired
                  reduceCancelButton
                />
              )}

              {!!accountCreationError && (
                <ErrorModal
                  isOpen={!!accountCreationError}
                  title="Something went wrong!"
                  onSubmit={() => {
                    setAccountCreationError('');
                  }}
                  onCancel={() => {
                    setAccountCreationError('');
                  }}
                  submitTitle="Close"
                  error={accountCreationError}
                  hiddenCancel
                  widthRequired
                />
              )}

              <Modal
                isOpen={isRemoveSenderModalOpen}
                title={selectedSenderAccount?.includes('@') ? 'Remove Sender' : 'Remove Domain'}
                submitTitle="Yes"
                onSubmit={() => {
                  removeSenderAccount(selectedSenderAccount);
                }}
                onCancel={() => {
                  setIsRemoveSenderModalOpen(false);
                }}
                reduceCancelButton
                widthRequired
              >
                {selectedSenderAccount?.includes('@') ? (
                  <div>
                    Do you really want to remove <strong>{selectedSenderAccount}</strong>?
                  </div>
                ) : (
                  <>
                    {isAuthenticatedDomain(selectedSenderAccount) ? (
                      <div>
                        Are you sure you want to remove the domain? Please note that this action will halt any scheduled
                        email marketing campaigns linked to the corresponding email address and domain.
                      </div>
                    ) : (
                      <div>
                        Are you sure you want to delete the domain? This action would stop any scheduled email marketing
                        campaigns from the associated email address and domain.
                      </div>
                    )}
                  </>
                )}
              </Modal>
              <RegisterDomainModal
                isOpen={isRegisterDomainModalOpen}
                title="Get Your Domain Registered"
                submitTitle="Next"
                cancelTitle="Cancel"
                onSubmit={() => {
                  setIsLoading(true);
                  const emailData = `${email}@${domain}`;
                  const params = {
                    // email: emailData,
                    domain: domain,
                    subDomain: '',
                  };

                  const addDomainParams = {
                    domain: domain,
                    subDomain: '',
                  };

                  if (isSendGridConnected) {
                    addDomainAuthentication(addDomainParams);
                  } else {
                    createAccountAndAddDomainAuthentication(params);
                  }
                }}
                onCancel={() => {
                  setIsRegisterDomainModalOpen(false);
                }}
                widthRequired
                reduceCancelButton
                domain={domain}
                domainError={domainError}
                email={email}
                handleEmailChange={handleEmailChange}
                handleDomainChange={handleDomainChange}
                emailDomain={emailDomain}
              />

              <Modal
                isOpen={isDomainVerifiedModalOpen}
                title="Domain Authenticated Successfully!"
                submitTitle="Ok"
                cancelTitle="Setup Sending Address"
                onSubmit={() => {
                  setisDomainVerifiedModalOpen(false);
                  history.replace({ ...location, state: null });
                }}
                onCancel={() => {
                  history.replace({ ...location, state: null });
                  handleConnectClick();
                  setisDomainVerifiedModalOpen(false);
                }}
                widthRequired
                reduceCancelButton
                cancelBtnStyle={{
                  color: '#C9B382',
                  borderColor: '#C9B382',
                }}
                cancelAsBack={true}
                backButton={() => {
                  history.replace({ ...location, state: null });
                  setisDomainVerifiedModalOpen(false);
                }}
              >
                <div>
                  {/* Great news! Your domain for <strong>{lastDomainAdded?.domain}</strong> has been successfully verified.
                  Also, you can now send emails from <strong>{lastDomainAdded?.email}.</strong> Now you're all set to
                  create your very first email marketing campaign.  */}
                  Great news! Your domain for <strong>{lastDomainAdded?.domain}</strong> has been successfully verified.
                  Continue by adding your first sending address.
                </div>
              </Modal>

              <Modal
                isOpen={addEmailPopUpOpen}
                title="Where do you want your marketing emails to come from?*"
                submitTitle="Add Email"
                cancelTitle="Cancel"
                onSubmit={() => {
                  if (email?.length > 0) {
                    setIsLoading(true);
                    const data = `${email}@${selectedDomain}`;
                    EmailMarketingIntegrationService.addSenderEmail(data)
                      .then(res => {
                        console.log(res);
                        dispatch(setAccount(res));
                        dispatch(setDomains(res?.domainData));
                        setEmail('');
                        setAddEmailPopUpOpen(false);
                        setIsLoading(false);
                        if (!user?.address) {
                          setIsPhysicalAddressModalOpen(true);
                        }
                      })
                      .catch(err => {
                        const errorMessage = err?.response?.data?.message || err?.message;
                        setAccountCreationError(errorMessage);
                        setIsLoading(false);
                      });
                  }
                }}
                onCancel={() => {
                  setEmail('');
                  setAddEmailPopUpOpen(false);
                }}
                widthRequired
                reduceCancelButton
                cancelBtnStyle={{ color: 'black', border: '1px solid rgba(0, 0, 0, 0.87)' }}
              >
                <div className={classes.email_main_container}>
                  <TextField
                    className={classes.email_main}
                    placeholder="Email Address"
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    InputProps={{
                      classes: {
                        root: classes.email_root,
                        notchedOutline: classes.email_notchedOutline,
                        adornedEnd: classes.email_adornedEnd,
                      },
                      endAdornment: (
                        <div className={classes.endAdornment}>
                          <MUISelect
                            name="domain-select"
                            value={selectedDomain}
                            disableUnderline={true}
                            items={verifiedDomains?.map(d => {
                              return {
                                title: d.domain.includes('www.') ? `@${d.domain.replace('www.', '')}` : `@${d.domain}`,
                                value: d.domain.includes('www.') ? `${d.domain.replace('www.', '')}` : `${d.domain}`,
                              };
                            })}
                            onChange={e => {
                              let value = e.target.value.includes('www.')
                                ? e.target.value.replace('www.', '')
                                : e.target.value;
                              setSelectedDomain(value);
                            }}
                            classes={{
                              root: classes.select_root,
                            }}
                          />
                        </div>
                      ),
                    }}
                  />
                </div>
              </Modal>
              <Modal
                isOpen={isPhysicalAddressModalOpen}
                title={
                  <span>
                    Add your physical address{' '}
                    <Tooltip
                      title="Businesses must include a physical address in the footer of all email campaigns, as required by laws such as the CAN-SPAM Act in the United States. These laws aim to prevent the sending of unsolicited, fraudulent, or deceptive emails, promoting transparency and reducing spam."
                      arrow
                    >
                      <InfoIcon style={{ marginLeft: '8px', marginBottom: '3px' }} htmlColor="#C9B382" />
                    </Tooltip>
                  </span>
                }
                submitTitle="Done"
                disableSubmitClick={physicalAddress?.length === 0}
                onSubmit={() => {
                  if (physicalAddress?.length > 0) {
                    const data = {
                      ...user,
                      address: physicalAddress,
                    };
                    dispatch(editProfile(user?.id, data));
                    dispatch(getProfile(user?.id));
                    setIsPhysicalAddressModalOpen(false);
                  }
                }}
                onCancel={() => {
                  setPhysicalAddress('');
                  setIsPhysicalAddressModalOpen(false);
                }}
                hiddenCancel
                widthRequired
              >
                <div className={classes.email_main_container}>
                  <TextField
                    className={classes.email_main}
                    placeholder="Please enter your physical address"
                    variant="outlined"
                    value={physicalAddress}
                    onChange={handlePhysicalAddressChange}
                    // helperText={
                    //   physicalAddress?.length === 0 ? (
                    //     <div style={{ color: 'red', marginTop: '5px', marginBottom: '5px', marginLeft: '-14px' }}>
                    //       <CommonErrorMessage message="Required" align="left" />
                    //     </div>
                    //   ) : (
                    //     ''
                    //   )
                    // }
                    InputProps={{
                      classes: {
                        root: classes.email_root,
                        notchedOutline: classes.email_notchedOutline,
                        adornedEnd: classes.email_adornedEnd,
                      },
                    }}
                  />
                </div>
              </Modal>
            </>
          );
        }}
      </Formik>
    </CardMainContainer>
  );
};
