import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
import * as R from 'ramda';
import { useHttp, useShallowEqualSelector, useEndVideoChat, useAccount, useRouter } from 'hooks';
import Loader from 'components/UI/Loader';
import { setDashboardSessions, clearDashboardSessions } from 'actions/dashboardSessions';
import { setDashboardIncome, clearDashboardIncome } from 'actions/dashboardIncome';

import BannerContainer from './BannerContainer/BannerContainer';
import IntroScreen from './IntroScreen';
import * as paidTier from 'selectors/paidTier';
import Dashboard from './Dashboard/Dashboard';
import { ContributionType, SIGNUP_TYPES } from 'helpers/constants';
import { redirectTo } from 'services/links';
import ChatAndGetStartedButtons from './LiveChat/LiveChatContainer';
import { PAID_TIER_TITLES, ROUTES } from 'constants.js';
import * as paidTierActions from 'actions/paidTierOptions';
import * as paidTierService from 'services/paidTierOptions.service';
import { getBoughtByType } from 'services/contributions.service';
import CreateQuizesModal from 'pages/CreateQuizes/Components/Modals/CreateModal/CreateModal';
import { setShowCreateModal } from 'actions/quizes';

const StyledWrapper = styled.div`
  padding: 20px 20px;

  ${({ mobileview }) => mobileview && `padding: 16px;`}
`;

const CohealerDashboardContainer = () => {
  const dispatch = useDispatch();
  const { request, loading } = useHttp();
  const { user } = useAccount();
  const { push } = useRouter();
  const { signupType } = useSelector(state => state.account);
  const { showModal } = useSelector(state => state.quizes);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const getDashboardSessions = useCallback(() => {
    request(`/Contribution/GetForCohealerDashboard`, 'GET')
      .then(R.compose(dispatch, setDashboardSessions))
      .catch(console.dir);
  }, [dispatch, request]);

  const { Popup } = useEndVideoChat(getDashboardSessions);

  const closestClassForBanner = useShallowEqualSelector(state => state?.dashboardSessions?.closestClassForBanner);
  const coachDeclinedSubscriptions = useShallowEqualSelector(
    state => state?.dashboardSessions?.coachDeclinedSubscriptions,
  );
  const sessions = useShallowEqualSelector(state => state?.dashboardSessions?.contributionsForDashboard || []);
  const incomes = useShallowEqualSelector(state => state?.dashboardIncome?.incomes || []);
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const shouldBeDisabled = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch && user.isCohealer;
  const getActivePaidTier = useCallback(() => paidTierService.getCurrentPlan(), []);
  const getAllPaidTiers = useCallback(() => paidTierService.getPaidTierOptions(), []);
  const updateActiveContributionsState = useCallback(() => {
    return (
      getBoughtByType(ContributionType.contributionMembership)
        // .then(filterMembership)
        .then(matchedMemberships => {
          dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
        })
    );
  }, []);

  useEffect(() => {
    getActivePaidTier().then(data => {
      dispatch(paidTierActions.setActivePaidTierPlan(data));
      // setIsLoader(s => ({ ...s, activePaidTierPlan: false }));
    });

    getAllPaidTiers().then(data => {
      dispatch(paidTierActions.setPaidTierPlans(data));
      // setIsLoader(s => ({ ...s, paidTierPlans: false }));
    });
    updateActiveContributionsState();

    paidTierService.getAcademy().then(data => {
      dispatch(paidTierActions.setPaidTierContribution(data));
    });
  }, []);
  useEffect(() => {
    if (user.signupType === SIGNUP_TYPES.TYPE_A || signupType === SIGNUP_TYPES.TYPE_A) {
      push(ROUTES.ACCOUNT_COACH_BILLING);
    } else if (user.signupType === SIGNUP_TYPES.TYPE_B || signupType === SIGNUP_TYPES.TYPE_B) {
      push(ROUTES.ACCOUNT_BILLING_EXTENDED);
    }
  }, [user]);

  useEffect(() => {
    getDashboardSessions();

    return R.compose(dispatch, clearDashboardSessions);
  }, [dispatch, getDashboardSessions]);

  useEffect(() => {
    request(`/api/cohealer-income/dashboard`, 'GET').then(R.compose(dispatch, setDashboardIncome)).catch(console.dir);

    return R.compose(dispatch, clearDashboardIncome);
  }, [dispatch, request]);

  if (loading) {
    return <Loader />;
  }

  return (
    <StyledWrapper mobileview={mobileView}>
      <BannerContainer
        closestClassForBanner={closestClassForBanner}
        coachDeclinedSubscriptions={coachDeclinedSubscriptions}
        pendingApplicationsCount
      />
      {!R.isEmpty(sessions) || !R.isEmpty(incomes) ? (
        <Dashboard sessions={sessions} incomes={incomes} />
      ) : (
        <IntroScreen />
      )}
      <Popup />
      <ChatAndGetStartedButtons />
    </StyledWrapper>
  );
};
export default CohealerDashboardContainer;
