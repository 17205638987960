import React, { useState, useEffect } from 'react';
import classes from './MultipleChoiceOption.module.scss';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import CrossIcon from 'pages/Contacts/Components/Filter/SVG/CrossIcon';

const MultipleChoiceOption = ({ index, option, onRemoveChoice, setFieldValue, questionIndex }) => {
  const [text, setText] = useState(option?.value);

  useEffect(() => {
    setFieldValue(`questions[${questionIndex}].options[${index}].value`, text);
    setFieldValue(`isSaved`, false);
  }, [text]);
  return (
    <div className={classes.option_container}>
      <div className={classes.checkbox_container}>
        <Checkbox disabled={true} color="primary" className={classes.checkbox} />
      </div>
      <div className={classes.textfield_container}>
        <TextField
          placeholder="Type choice here..."
          variant="outlined"
          fullWidth
          value={option?.value}
          onChange={e => {
            setText(e?.target?.value);
          }}
          InputProps={{
            classes: {
              root: classes.input_root,
              notchedOutline: classes.input_notchedOutline,
              focused: classes.input_focused,
            },
          }}
        />
      </div>
      <div
        className={classes.cross_btn_container}
        onClick={() => {
          onRemoveChoice(index);
        }}
      >
        <CrossIcon />
      </div>
    </div>
  );
};

export default MultipleChoiceOption;
