import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Grid } from '@material-ui/core';
import getCourseType from 'utils/getCourseType';
import styled from 'styled-components';
import Button from '@mui/material/Button';

import ViewContributionButton from './components/ViewContributionButton';
import { redirectTo } from 'services/links';
import { ContributionType } from 'helpers/constants';
const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.25rem;
  border: 1px solid;
  width: fit-content;
`;

const IsCollectiveTag = styled.div`
  color: #215c73;
  padding: 2px 4px;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 800;
`;
const CollectiveContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CollectiveTitle = styled.span`
  padding-right: 10px;
`;
const ContributionTableAdmin = ({ rows }) => {
  const [isCollective, setIsCollective] = useState(true);
  const [collectiveContributons, setCollectiveContributons] = useState([]);
  const filters = ['Collective', 'Non-Collective'];
  useEffect(() => {
    if (rows.length > 0) {
      if (isCollective) {
        setCollectiveContributons(rows.filter(row => row?.isCollectiveContribution === true));
      } else {
        setCollectiveContributons(rows.filter(row => row.isCollectiveContribution === false));
      }
    }
  }, [isCollective, rows]);

  return (
    <>
      <Grid
        container
        style={{
          marginBottom: '15px',
          display: 'flex',
          justifyContent: 'flexStart',
          width: '100%',
        }}
      >
        {filters.map((item, index) => {
          return (
            <div>
              {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}> */}
              <Button
                variant="contained"
                value={item}
                disableElevation="true"
                style={{
                  backgroundColor:
                    item == 'Collective' && isCollective === true
                      ? '#C9B382'
                      : item == 'Non-Collective' && isCollective === false
                      ? '#C9B382'
                      : 'white',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  textTransform: 'none',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  color:
                    item == 'Collective' && isCollective === true
                      ? 'white'
                      : item == 'Non-Collective' && isCollective === false
                      ? 'white'
                      : '#C9B382',
                  border: `1px solid #C9B382`,
                  minWidth: 'auto',
                  borderRadius: '5px',
                  marginRight: '10px',
                }}
                onClick={e => {
                  setIsCollective(item == 'Collective' ? true : false);
                }}
              >
                {item}
              </Button>
              {/* </Box> */}
            </div>
          );
        })}
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="admin table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Coherer Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {collectiveContributons.map(
              ({ id, title, type, serviceProviderName, updateTime, isCollectiveContribution }) => (
                <TableRow key={id}>
                  <TableCell>
                    <CollectiveContainer>
                      <CollectiveTitle>{title}</CollectiveTitle>
                      {isCollectiveContribution && (
                        <TagsContainer>
                          <IsCollectiveTag>Collective</IsCollectiveTag>
                        </TagsContainer>
                      )}
                    </CollectiveContainer>
                  </TableCell>
                  <TableCell>{getCourseType(type)}</TableCell>
                  <TableCell>{serviceProviderName}</TableCell>
                  <TableCell>{moment(updateTime).format('YYYY/MM/DD hh:mm A')}</TableCell>
                  <TableCell align="right">
                    {/* <Link to={`/admin/contribution-view/${id}`}> */}
                    <ViewContributionButton
                      onClick={() => {
                        if (type === ContributionType.contributionCommunity) {
                          redirectTo(`/admin/contribution-view/${id}/community`);
                        } else {
                          redirectTo(`/admin/contribution-view/${id}`);
                        }
                      }}
                    />
                    {/* </Link> */}
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

ContributionTableAdmin.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
};

ContributionTableAdmin.defaultProps = {
  rows: [],
};

export default ContributionTableAdmin;
