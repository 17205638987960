import React from 'react';

const FilledStarIcon = () => {
  return (
    <svg width="47" height="44" viewBox="0 0 47 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.0516 1.33832C23.235 0.966694 23.765 0.966696 23.9484 1.33832L30.1663 13.9372C30.3848 14.3799 30.8071 14.6867 31.2957 14.7577L45.1994 16.7781C45.6095 16.8377 45.7732 17.3416 45.4765 17.6309L35.4157 27.4377C35.0621 27.7824 34.9008 28.2789 34.9843 28.7654L37.3593 42.6129C37.4294 43.0214 37.0006 43.3329 36.6338 43.14L24.198 36.6021C23.761 36.3724 23.239 36.3724 22.802 36.6021L10.3662 43.14C9.99936 43.3329 9.57064 43.0214 9.6407 42.6129L12.0157 28.7654C12.0992 28.2789 11.9379 27.7824 11.5843 27.4378L1.52354 17.6309C1.22678 17.3416 1.39054 16.8377 1.80065 16.7781L15.7043 14.7577C16.1929 14.6867 16.6152 14.3799 16.8337 13.9372L23.0516 1.33832Z"
        fill="#E1BB62"
        stroke="#E1BB62"
      />
    </svg>
  );
};

export default FilledStarIcon;
