import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import TextBlock from './TextBlock';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { getThemedColors } from 'services/contributions.service';
import { flatten, orderBy } from 'lodash';
import Modal from 'components/UI/Modal';
import { formatTime, sortByStartTime } from 'pages/ContributionView/components/EnrollmentBlock';
import {
  EnroleDateShowMore,
  StyledCalenderIcon,
  TIME_FORMAT,
  START_DATETIME_FORMAT,
} from 'pages/ContributionView/components/CustomEnrollmentBlock';

//const TIME_FORMAT = 'hh:mmA';

const getFormattedDateRange = (startDate, endDate, timezoneShort) => {
  return `${moment(startDate).format(TIME_FORMAT)} - ${moment(endDate).format(TIME_FORMAT)} ${timezoneShort}`;
};

const getFormattedDateRangeShowMore = (startDate, endDate, timezoneShort) => {
  return `${moment(startDate).format(START_DATETIME_FORMAT)} - ${moment(endDate).format(TIME_FORMAT)} ${timezoneShort}`;
};

const formatSessionTimes = startDate => {
  return `${formatTime(startDate)}`;
};

const WorkshopDatesBlock = ({ enrollment: { fromDate, toDate } }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const contribution = useContribution();
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);

  const sessions = contribution?.sessions;
  const { isWorkshop, timeZoneShortName, displaySessionTimeDates } = contribution;
  const [showMore, setShowMore] = useState(false);

  const liveSessions = sessions => {
    const live = sessions?.filter(s => !s.isPrerecorded && !s.isCompleted);
    return live;
  };

  const allLiveUncompletedSessions = liveSessions(sessions);
  const flatSessionTimes = flatten((allLiveUncompletedSessions || []).map(k => k.sessionTimes));

  const ordered =
    orderBy(
      flatSessionTimes?.filter(k => !k.isCompleted),
      sortByStartTime,
      'asc',
    ) || [];

  const openPopup = () => {
    setShowMore(true);
  };

  const MainDivDate = styled.div`
    display: flex;
  `;

  const DateStart = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  `;

  return (
    <>
      {isWorkshop && displaySessionTimeDates && ordered?.length > 0 && (
        <Card
          style={{ backgroundColor: themedCardBackgroundColor, color: themedColor, height: '' }}
          mobileView={mobileView}
          maxHeight
        >
          <CardHeader mobileView={mobileView}>
            <PageTitleSecond style={{ color: themedColor }} mobileView={mobileView}>
              Workshop Date{ordered.length > 1 ? 's' : ''} & Time
            </PageTitleSecond>
          </CardHeader>
          <CardBody mobileView={mobileView}>
            <Grid container spacing={2}>
              <Grid item md={6} sm={6} xs={12}>
                <TextBlock
                  style={{ color: themedColor }}
                  mobileView={mobileView}
                  image={null}
                  title="Dates"
                  text={
                    <div style={{ display: 'flex', rowGap: '0.5rem', flexDirection: 'column' }}>
                      {ordered.slice(0, 5).map((session, index) => (
                        <div key={index}>{formatSessionTimes(session.startTime)}</div>
                      ))}
                    </div>
                  }
                />
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <TextBlock
                  style={{ color: themedColor }}
                  mobileView={mobileView}
                  image={null}
                  title="Time"
                  text={
                    <div style={{ display: 'flex', rowGap: '0.5rem', flexDirection: 'column' }}>
                      {ordered.slice(0, 5).map((session, index) => (
                        <div key={index}>
                          {getFormattedDateRange(session.startTime, session.endTime, timeZoneShortName)}
                        </div>
                      ))}
                    </div>
                  }
                />
              </Grid>
              {ordered.length > 5 && (
                <div style={{ marginTop: '6px', marginLeft: '6px' }}>
                  <EnroleDateShowMore
                    onClick={openPopup}
                    style={{
                      color: themedColor,
                      fontSize: '1.125rem',
                      fontWeight: '900',
                      letterSpacing: '0.12px',
                      lineHeight: '26px',
                    }}
                  >
                    Show More
                  </EnroleDateShowMore>
                </div>
              )}
            </Grid>
          </CardBody>
        </Card>
      )}
      {isWorkshop && displaySessionTimeDates && showMore && (
        <Modal
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: '0.5rem',
                marginRight: '2rem',
              }}
            >
              <StyledCalenderIcon style={{ color: 'var(--Cohere-Primary-Blue, #215C73)' }} />
              <EnroleDateShowMore
                style={{
                  color: 'var(--Cohere-Primary-Blue, #215C73)',
                  textAlign: 'center',
                  fontFamily: 'Avenir',
                  fontSize: '20px',
                }}
              >
                Workshop Date{ordered.length > 1 ? 's' : ''} & Time
              </EnroleDateShowMore>
            </div>
          }
          isOpen={showMore}
          onCloseText="OK"
          hiddenCancel
          onSubmit={() => {
            setShowMore(false);
          }}
          onCancel={() => {
            setShowMore(false);
          }}
          disableFooter
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem 3rem 2rem 0rem',
              rowGap: '0.5rem',
            }}
          >
            {ordered.map((sessionTime, index) => (
              <MainDivDate key={sessionTime.id}>
                <DateStart style={{ color: themedColor }}>
                  {getFormattedDateRangeShowMore(sessionTime.startTime, sessionTime.endTime, timeZoneShortName)}
                </DateStart>
              </MainDivDate>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

WorkshopDatesBlock.propTypes = {
  enrollment: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }).isRequired,
};

export default WorkshopDatesBlock;
