import React from 'react';
import classes from './RatingIconLabel.module.scss';

const RatingIconLabel = ({ icon, label, onClick }) => {
  return (
    <div className={classes.container}>
      <div
        className={classes.icon}
        onClick={() => {
          onClick(label);
        }}
      >
        {icon}
      </div>
      <div className={classes.label}>{label}</div>
    </div>
  );
};

export default RatingIconLabel;
