import React from 'react';
import classes from './Dropdown.module.scss';
import DropdownIcon from '../../../Assests/Icons/DropdownIcon';

const Dropdown = () => {
  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <DropdownIcon />
      </div>
      <div className={classes.type_name}>Dropdown</div>
    </div>
  );
};

export default Dropdown;
