import React, { useRef, useState, Fragment, useEffect } from 'react';
import classes from '../ContentPage.module.scss';
import Grid from '@material-ui/core/Grid';
import Button from 'components/FormUI/Button';
import QuestionModal from 'pages/ContributionView/components/ContributionHeader/ApplicationForm/QuestionModal';
import { getStyledButton, getStyledSecondaryButton, pluckKeys } from 'utils/utils';
import styled, { css } from 'styled-components';
import Select from 'components/UI/Select';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IconButton } from '@material-ui/core';
import SvgHandler from 'pages/ContributionView/components/ContributionHeader/ApplicationForm/SvgHandler';
import { FieldArray } from 'formik';
import Question from './Question/Question';
import { questionTypes } from 'pages/CreateQuizes/Constants/constants';
import QuestionTypesPanel from './QuestionTypesPanel/QuestionTypesPanel';
import CheckIcon from '../Assests/Icons/CheckIcon';
import DropdownIcon from '../Assests/Icons/DropdownIcon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom';
import { setShowCustomizeResults } from 'actions/quizes';
import { useDispatch } from 'react-redux';
import ShortTextIcon from '../Assests/Icons/ShortTextIcon';
import RatingIcon from '../Assests/Icons/RatingIcon';
import RankingIcon from '../Assests/Icons/RankingIcon';
import classNames from 'classnames';
import { DOCUMENT_TYPES } from '../constants';

const questionTypesUIValues = {
  Radio: 'Single Choice',
  CheckBox: 'Multiple Choice',
  Text: 'Written Answer',
};

const TYPES = {
  Quiz: 'Quiz',
  Form: 'Form',
};

function LeftColumn({ focusedQuestionIndex, setFocusedQuestion, formProps, mobileView, setDisplayNumber }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { values } = formProps;
  const [isQuestionTypesPanelOpen, setIsQuestionTypesPanelOpen] = useState(false);
  const handleDragEnd = (results, questions, setFieldValue) => {
    const { source, destination } = results;
    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const reOrderedQuestions = [...questions];
    const destinationQuestion = reOrderedQuestions[destination.index];
    const [removedSection] = reOrderedQuestions.splice(source.index, 1);
    reOrderedQuestions.splice(destination.index, 0, removedSection);

    setFieldValue('questions', reOrderedQuestions);
    setFieldValue('isSaved', false);
  };

  const handleAddQuestionClick = () => {
    dispatch(setShowCustomizeResults(false));
    setIsQuestionTypesPanelOpen(!isQuestionTypesPanelOpen);
  };

  return (
    <FieldArray name="questions">
      {({ push, remove, form }) => {
        const {
          values: { questions, type },
          setFieldValue,
        } = form;
        const addQuestion = type => {
          const question = {
            type: type,
            description: '',
            options:
              type === questionTypes.dropdown
                ? [{ value: '', isCorrect: false }]
                : type === questionTypes.multiple_choice
                ? [{ value: '', isCorrect: false }]
                : type === questionTypes.rating
                ? [{ value: '5' }]
                : type === questionTypes.short_text
                ? [{ value: '0', isCorrect: false }]
                : [],
            isOptional: true,
            canSelectMultipleCorrectOptions: false,
            name: '',
            questionType: type,
            userResponse: [],
            isDraft: true,
            isRequired: true,
            isDeleted: false,
            placeholder: 'Please write your question here…',
          };
          push(question);
          setFieldValue('isSaved', false);
        };

        const ActionsList = {
          [DOCUMENT_TYPES.Quiz]: [
            {
              name: 'Dropdown',
              icon: <DropdownIcon />,
              onClick: e => {
                e.stopPropagation();
                addQuestion(questionTypes.dropdown);
                setIsQuestionTypesPanelOpen(false);
              },
            },
            {
              name: 'Multiple Choice',
              icon: <CheckIcon />,
              onClick: e => {
                e.stopPropagation();
                addQuestion(questionTypes.multiple_choice);
                setIsQuestionTypesPanelOpen(false);
              },
            },
          ],
          [DOCUMENT_TYPES.Form]: [
            {
              name: 'Dropdown',
              icon: <DropdownIcon />,
              onClick: e => {
                e.stopPropagation();
                addQuestion(questionTypes.dropdown);
                setIsQuestionTypesPanelOpen(false);
              },
            },
            {
              name: 'Multiple Choice',
              icon: <CheckIcon />,
              onClick: e => {
                e.stopPropagation();
                addQuestion(questionTypes.multiple_choice);
                setIsQuestionTypesPanelOpen(false);
              },
            },
            {
              name: 'Ranking',
              icon: <RankingIcon />,
              onClick: e => {
                e.stopPropagation();
                addQuestion(questionTypes.ranking);
                setIsQuestionTypesPanelOpen(false);
              },
            },
            {
              name: 'Rating',
              icon: <RatingIcon />,
              onClick: e => {
                e.stopPropagation();
                addQuestion(questionTypes.rating);
                setIsQuestionTypesPanelOpen(false);
              },
            },
            {
              name: 'Short Text',
              icon: <ShortTextIcon />,
              onClick: e => {
                e.stopPropagation();
                addQuestion(questionTypes.short_text);
                setIsQuestionTypesPanelOpen(false);
              },
            },
          ],
        };

        return (
          <div className={classes.container_left}>
            <div className={classes.buttonContainer}>
              <Button
                className={classNames(classes.add_question_btn, { [classes.add_question_btn_responsive]: mobileView })}
                variant="secondary"
                onClick={handleAddQuestionClick}
                autoWidth
              >
                Add Question
              </Button>
              {isQuestionTypesPanelOpen && (
                <QuestionTypesPanel
                  mobileView={mobileView}
                  actions={ActionsList[type]}
                  onClose={() => {
                    setIsQuestionTypesPanelOpen(false);
                  }}
                />
              )}
            </div>
            <div className={classes.questionContainer}>
              <Grid container>
                <Grid xl={12} lg={12} md={12} sm={12} xs={12} className="saved-question">
                  <DragDropContext onDragEnd={results => handleDragEnd(results, questions, setFieldValue)}>
                    <Droppable droppableId="questions" type="question" className="test">
                      {provided => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className={classes.questions}>
                          {questions.map((question, index) => {
                            let displayIndex = 0;
                            for (let i = 0; i <= index; i++) {
                              if (!questions[i].isDeleted) {
                                displayIndex++;
                              }
                            }
                            if (question.isDeleted) return null;

                            return (
                              <Fragment key={question.localId}>
                                <Draggable draggableId={`question-${index}`} key={`question-${index}`} index={index}>
                                  {provided => (
                                    <div {...provided.draggableProps} ref={provided.innerRef}>
                                      <Question
                                        questionsLength={questions.length}
                                        question={question}
                                        index={index} // Use the manually calculated display index
                                        parentProvided={provided}
                                        push={push}
                                        remove={remove}
                                        questions={questions}
                                        isFocused={focusedQuestionIndex === index}
                                        onFocus={() => {
                                          dispatch(setShowCustomizeResults(false));
                                          setFocusedQuestion(index);
                                          setDisplayNumber(displayIndex);
                                        }}
                                        mobileView={mobileView}
                                        setFieldValue={setFieldValue}
                                        setFocusedQuestion={setFocusedQuestion}
                                        displayIndex={displayIndex}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              </Fragment>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Grid>
              </Grid>
            </div>
            {type === DOCUMENT_TYPES.Quiz && (
              <div className={classes.buttonContainer}>
                <Button
                  className={classNames(classes.customize_result_btn, {
                    [classes.customize_result_btn_responsive]: mobileView,
                  })}
                  variant="secondary"
                  onClick={() => {
                    dispatch(setShowCustomizeResults(true));
                  }}
                  autoWidth
                >
                  Customize Result
                </Button>
              </div>
            )}
          </div>
        );
      }}
    </FieldArray>
  );
}

export default LeftColumn;
