const copyShareRecordingLinkToClipboard = ({ contributionId, code, sessionTimeId = '' }) => {
  const dummy = document.createElement('input');
  dummy.style.position = 'absolute';
  dummy.style.left = '-9999px';
  dummy.style.opacity = '0';
  const text = window.location.origin;
  document.body.appendChild(dummy);

  const contributionLabel = contributionId ? `/share-recording/${contributionId}/${sessionTimeId}` : '';
  const codeLabel = code ? `/${code}` : '';

  dummy.value = `${text}${contributionLabel}${codeLabel}`;
  dummy.select();
  dummy.setSelectionRange(0, 99999);

  // It is work for premium feature copying
  setTimeout(() => {
    document.execCommand('copy');
  }, 150);

  setTimeout(() => {
    document.body.removeChild(dummy);
  }, 300);
};

export default copyShareRecordingLinkToClipboard;
