import React from 'react';

const ResultIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9167 3H5.08333C3.93333 3 3 3.93333 3 5.08333V10.9167C3 12.0667 3.93333 13 5.08333 13H9.80833C10.3667 13 10.8875 12.7833 11.2833 12.3917L12.3917 11.2833C12.7833 10.8917 13 10.3667 13 9.80833V5.08333C13 3.93333 12.0667 3 10.9167 3ZM5.08333 12.1667C4.39583 12.1667 3.83333 11.6042 3.83333 10.9167V5.08333C3.83333 4.39583 4.39583 3.83333 5.08333 3.83333H10.9167C11.6042 3.83333 12.1667 4.39583 12.1667 5.08333V9.25H10.5C9.8125 9.25 9.25 9.8125 9.25 10.5V12.1667H5.08333ZM10.6917 11.8C10.525 11.9667 10.3125 12.0833 10.0833 12.1333V10.4958C10.0833 10.2667 10.2708 10.0792 10.5 10.0792H12.1375C12.0875 10.3083 11.9708 10.5208 11.8042 10.6875L10.6958 11.7958L10.6917 11.8Z"
        fill="#213649"
        stroke="#213649"
        stroke-width="0.25"
      />
    </svg>
  );
};

export default ResultIcon;
