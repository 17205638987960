import React, { useEffect, useState } from 'react';
import { Grid, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import ContributionCommunityRouter from 'routes/ContributionCommunityRouter';
import PropTypes, { bool, string } from 'prop-types';
import { useLocation } from 'react-router-dom';
import BurgerMenu from 'assets/BurgerMenu.svg';
import { Groups, ModeComment } from '@mui/icons-material';
import styled from 'styled-components';
import {
  fetchCommunitySections,
  createOrEditCommunitySection,
  deleteCommunitySection,
  createOrEditCommunityGroup,
  fetchCommunitySuggestedGroups,
  muteOrUnmuteGroupNotifications,
  setContributionIdActions,
  updateCurrentGroupData,
  moveGroupFromOneSectionToOtherAction,
} from 'actions/communitySections';
import Loader from 'components/UI/Loader';

import { LabelText } from 'components/UI/Text/TextStyles';
import useRouter from 'hooks/useRouter';
import { findMaxOfKey, findValueByKey, isValidUrl, pluckKeys } from 'utils/utils';
import { deleteResourcesInGroup, moveGroupFromOneSectionToOther } from 'services/communitySections.service';
import useNotifications from 'hooks/useNotifications';
import LinksModal from './components/LinksModal';
import ResourcesModal from './components/ResourcesModal';
import RightDrawer from './components/RightDrawer';
import LeftSectionMenu from './components/LeftSectionMenu';
import LeftDrawer from './components/LeftDrawer';
import HomeIcon from './components/HomeIcon';

import './ContributionCommunity.scss';
import SectionModal from './components/SectionModal';
import ConfirmationModal from './components/ConfirmationModal';
import GroupModal from './components/GroupModal';
import { SECTION_MENU_OPTIONS, GROUP_MENU_OPTIONS, TOOLTIP } from '../../../constants';
import RightSectionMenu from './components/RightSectionMenu';
import MoveToSectionModal from './components/MoveToSectionModal';

const MobileMenuWrapper = styled.div`
  margin-right: 12px;
  &:hover {
    cursor: pointer;
  }
`;

const defaults = {
  social: {
    icon: HomeIcon,
    title: 'Main Community',
  },
  chat: {
    icon: ModeComment,
    title: 'Chat',
  },
  clients: {
    icon: Groups,
    title: 'Members',
  },
};

const ContributionCommunity = ({
  contribution,
  currentRole,
  loading,
  subSections,
  archivedGroups,
  getSections,
  createOrEditSection,
  deleteSection,
  createOrEditGroup,
  getSuggestedGroups,
  suggestedGroups,
  currentGroup,
  muteOrUnmuteNotifications,
  updateCurrentGroup,
  mainGroup,
}) => {
  // const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  // const type = contribution?.type;
  // const { user } = useAccount();
  // const isCoach = currentRole === 'Cohealer';
  const { pathname } = useLocation();
  const {
    match: { url },
  } = useRouter();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const themedColors = getThemedColors(contribution);
  const colorToUse = determineColorToUse(contribution);
  const hideRightPanel = pathname.includes('conversations') || pathname.includes('clients');
  const { successMessage } = useNotifications();
  const [openRightSection, setOpenRightSection] = useState(false);
  const [openLeftSection, setOpenLeftSection] = useState(false);

  // section related modal inputs
  const [openSectionModal, setOpenSectionModal] = useState(false);
  const [sectionModalMode, setSectionModalMode] = useState('');
  const [sectionModalTitle, setSectionModalTitle] = useState('');
  const [sectionToEdit, setSectionToEdit] = useState(null);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmationModalMode, setConfirmationModalMode] = useState('');
  const [confirmationModalTitle, setConfirmationModalTitle] = useState('');

  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [groupModalMode, setGroupModalMode] = useState('');
  const [groupModalTitle, setGroupModalTitle] = useState('');
  const [groupModalSubmitTitle, setGroupModalSubmitTitle] = useState('');
  const [groupToEdit, setGroupToEdit] = useState(null);

  const [openLinks, setOpenLinks] = useState(false);
  const [openResources, setOpenResources] = useState(false);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const [modalLoading, setModalLoading] = useState(false);

  const [openMoveTosectionModal, setOpenMoveTosectionModal] = useState(false);
  const [hasUnreadCount, setHasUnreadCount] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    dispatch(setContributionIdActions.update({ contributionId: contribution.id }));
    getSections(contribution.id);
    getSuggestedGroups();
  }, []);

  useEffect(() => {
    if (hideRightPanel) {
      updateCurrentGroup({
        group: mainGroup,
      });
    }
  }, [hideRightPanel]);

  useEffect(() => {
    setOpenLeftSection(false);
  }, [
    mobileView,
    pathname,
    openSectionModal,
    openConfirmationModal,
    openGroupModal,
    currentGroup,
    openMoveTosectionModal,
  ]);

  useEffect(() => {
    setOpenRightSection(false);
  }, [openLinks, openResources]);

  useEffect(() => {
    if (!loading && message !== '') {
      successMessage(message);
      setMessage('');
    }
  }, [loading]);

  useEffect(() => {
    if (subSections) {
      const groups = pluckKeys(subSections, 'groups');
      const allGroups = [].concat(...(groups ?? []), ...(archivedGroups ?? []));
      const hasCount = allGroups?.filter(group => group.unreadCount && group.unreadCount > 0)?.length > 0;
      setHasUnreadCount(hasCount);
    }
  }, [subSections]);

  const getCurrent = () => {
    if (pathname.includes('conversations')) {
      return (
        <div className="menu-content">
          <defaults.chat.icon style={{ color: colorToUse?.PrimaryColorCode }} /> <span>{defaults.chat.title}</span>
        </div>
      );
    }
    if (pathname.includes('clients')) {
      return (
        <div className="menu-content">
          <defaults.clients.icon style={{ color: colorToUse?.PrimaryColorCode }} />{' '}
          <span>{defaults.clients.title}</span>
        </div>
      );
    }

    if (pathname === `${url}` || pathname === `${url}/social`) {
      return (
        <div className="menu-content">
          <defaults.social.icon style={{ margin: 'unset' }} fillColor={colorToUse?.PrimaryColorCode} />{' '}
          <span>{defaults.social.title}</span>
        </div>
      );
    }
    if (currentGroup) {
      return (
        <div className="menu-content">
          {isValidUrl(currentGroup.imageUrl) ? (
            <img src={currentGroup.imageUrl} alt="group icon" className="group-image" />
          ) : (
            <div className="group-image">{currentGroup.imageUrl ?? ''}</div>
          )}
          <Tooltip
            placement="bottom-start"
            title={currentGroup?.title}
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
          >
            <span className="title">{currentGroup?.title}</span>
          </Tooltip>
        </div>
      );
    }
    return null;
  };

  const handleCreateSectionModal = () => {
    setSectionModalMode('new');
    setSectionModalTitle('Create Section');
    setOpenSectionModal(true);
  };

  const handleEditSectionModal = section => {
    setSectionModalMode('edit');
    setSectionToEdit(section);
    setSectionModalTitle('Edit Section');
    setOpenSectionModal(true);
  };

  const handleCreateSection = values => {
    if (sectionModalMode === 'new') {
      const order = findMaxOfKey(subSections, 'sectionOrder', 0);
      createOrEditSection({ ...values, orderNo: order + 1 });
      setMessage('Create Section');
    }
    if (sectionModalMode === 'edit') {
      createOrEditSection({ ...sectionToEdit, ...values });
      setMessage('Edit Section');
    }
    setSectionToEdit(null);
    setOpenSectionModal(false);
  };

  const getConfirmationContent = () => {
    return (
      <LabelText style={{ color: themedColors.themedColor }}>
        Are you sure you wish to remove {confirmationModalMode === 'deleteSection' ? sectionToEdit?.sectionTitle : ''}?
      </LabelText>
    );
  };

  const handleDelete = () => {
    setOpenConfirmationModal(false);
    deleteSection({
      contributionId: contribution.id,
      id: sectionToEdit.sectionId,
      title: sectionToEdit.sectionTitle,
      orderNo: sectionToEdit.sectionOrder,
    });
    setSectionToEdit(null);
    setMessage('Delete Section');
  };

  const handleDeleteSection = section => {
    if (!section) {
      return;
    }
    setConfirmationModalMode('deleteSection');
    setSectionToEdit(section);
    setConfirmationModalTitle('Delete Section');
    setOpenConfirmationModal(true);
  };

  const handleCreateGroup = section => {
    setGroupModalMode(SECTION_MENU_OPTIONS.createGroup);
    setSectionToEdit(section);
    setGroupModalTitle('Create Groups');
    setGroupModalSubmitTitle('Create');
    setOpenGroupModal(true);
  };

  const handleEditGroup = group => {
    setSectionToEdit(subSections.find(section => section.sectionId === group.sectionId));
    setGroupToEdit(group);
    setGroupModalMode(GROUP_MENU_OPTIONS.editGroup);
    setGroupModalTitle('Edit Cohort');
    setGroupModalSubmitTitle('Done');
    setOpenGroupModal(true);
  };

  const createEditOrArchiveGroups = values => {
    setOpenGroupModal(false);
    setGroupToEdit(null);
    if (values?.groups && values?.groups?.length > 0) {
      const data = {
        ...values,
        contributionId: contribution?.id,
      };

      if (sectionToEdit) {
        let order = findMaxOfKey(sectionToEdit?.groups, 'orderNo', 0) + 1;
        const groups = values.groups.map(group => {
          if (groupModalMode === SECTION_MENU_OPTIONS.createGroup || group.isSuggested) {
            delete group.id;
          }
          return {
            ...group,
            sectionId: sectionToEdit?.sectionId,
            orderNo: groupModalMode === SECTION_MENU_OPTIONS.createGroup ? order++ : group.orderNo,
          };
        });

        data.groups = groups;
      }

      createOrEditGroup(data);
    }
    setSectionToEdit(null);
  };

  const moveGroupFromSectionToSection = (
    groupMovedFromSectionId,
    groupMovedToSectionId,
    sourceGroups,
    destinationGroups,
    movedGroupId,
  ) => {
    dispatch(
      moveGroupFromOneSectionToOtherAction.update({
        fromSection: groupMovedFromSectionId,
        toSection: groupMovedToSectionId,
        fromSectionGroups: sourceGroups,
        toSectionGroups: destinationGroups,
      }),
    );

    const groupMovedToSectionOrders = {};
    const groupMovedFromSectionOrders = {};
    sourceGroups.forEach(group => {
      groupMovedFromSectionOrders[group.id] = group.orderNo;
    });
    destinationGroups.forEach(group => {
      groupMovedToSectionOrders[group.id] = group.orderNo;
    });

    moveGroupFromOneSectionToOther({
      contributionId: contribution.id,
      groupMovedFromSectionId,
      groupMovedToSectionId,
      groupMovedFromSectionOrders,
      groupMovedToSectionOrders,
      movedGroupId,
    });
    successMessage('Move group to another section');
  };

  const handleGroupSubmit = values => {
    if (groupModalMode === SECTION_MENU_OPTIONS.createGroup || groupModalMode === GROUP_MENU_OPTIONS.editGroup) {
      createEditOrArchiveGroups(values);
      setMessage(
        groupModalMode === SECTION_MENU_OPTIONS.createGroup
          ? `Create Group${values?.length > 1 ? 's' : ''}`
          : 'Edit Group',
      );
    }
    if (groupModalMode === GROUP_MENU_OPTIONS.moveTosection) {
      if (values.sectionId !== '') {
        const destinationSectionId = values.sectionId;
        const sourceSectionId = groupToEdit.sectionId;
        const sourceSectionGroups = findValueByKey(subSections, 'sectionId', sourceSectionId)?.groups;
        const destinationSectionGroups = findValueByKey(subSections, 'sectionId', destinationSectionId)?.groups;
        moveGroupFromSectionToSection(
          sourceSectionId,
          destinationSectionId,
          sourceSectionGroups.filter(group => group.id !== groupToEdit.id),
          [...(destinationSectionGroups ?? []), { ...groupToEdit, sectionId: destinationSectionId }],
          groupToEdit.id,
        );
      }
      setGroupToEdit(null);
      setOpenMoveTosectionModal(false);
    }
  };

  const handleGroupActions = (action, group) => {
    if (action === GROUP_MENU_OPTIONS.editGroup) {
      handleEditGroup(group);
    }
    if (action === GROUP_MENU_OPTIONS.archiveGroup) {
      const sectionGroups = findValueByKey(subSections, 'sectionId', group.sectionId)?.groups;

      const updatedOrders = {};
      let order = 1;
      sectionGroups.forEach(sectionGroup => {
        if (sectionGroup.id !== group.id) {
          updatedOrders[sectionGroup.id] = order++;
        }
      });

      const data = {
        contributionId: contribution.id,
        sectionId: group.sectionId,
        groups: [{ ...group, isArchived: true }],
        updatedOrders,
      };

      createEditOrArchiveGroups(data);
      setMessage('Archive Group');
    }
    if (action === GROUP_MENU_OPTIONS.unArchive) {
      const section = findValueByKey(subSections, 'sectionId', group.sectionId);
      const orderNo = findMaxOfKey(section?.groups, 'orderNo', 0) + 1;
      const data = {
        contributionId: contribution.id,
        sectionId: group.sectionId,
        groups: [{ ...group, isArchived: false, orderNo }],
      };
      createEditOrArchiveGroups(data);
      setMessage('Unarchive Group');
    }
    if (action === GROUP_MENU_OPTIONS.muteNotifications || action === GROUP_MENU_OPTIONS.unmuteNotifications) {
      muteOrUnmuteNotifications(group.id, action === GROUP_MENU_OPTIONS.muteNotifications);
      successMessage(action === GROUP_MENU_OPTIONS.muteNotifications ? 'Mute Group' : 'Unmute Group');
    }
    if (action === GROUP_MENU_OPTIONS.moveTosection) {
      setGroupToEdit(group);
      setGroupModalMode(action);
      setOpenMoveTosectionModal(true);
    }
  };

  const onDeleteResourceClick = (documentId, deleteFromS3Only = false, fileExtension) => {
    setModalLoading(true);
    deleteResourcesInGroup({
      contributionId: contribution?.id,
      documentId,
      groupId: currentGroup?.id,
      deleteFromS3Only,
      fileExtension,
    })
      .then(res => {
        if (!openResources) {
          updateCurrentGroup({
            group: {
              ...currentGroup,
              resources: res,
            },
          });
          successMessage('Delete Resource');
        }
      })
      .finally(() => setModalLoading(false));
  };

  return (
    <>
      {loading && <Loader />}
      {mobileView ? (
        <>
          <LeftDrawer
            isOpen={openLeftSection}
            setIsOpen={setOpenLeftSection}
            contribution={contribution}
            themedColors={themedColors}
            mobileView={mobileView}
            currentRole={currentRole}
            pathname={pathname}
            createSection={handleCreateSectionModal}
            sections={subSections}
            editSection={handleEditSectionModal}
            deleteSection={handleDeleteSection}
            archivedGroups={archivedGroups}
            createGroup={handleCreateGroup}
            handleGroupActions={handleGroupActions}
            currentGroup={currentGroup}
            isDarkThemeEnabled={isDarkThemeEnabled}
          />
          <RightDrawer
            isOpen={openRightSection}
            setIsOpen={setOpenRightSection}
            currentRole={currentRole}
            mobileView={mobileView}
            setOpenLinks={setOpenLinks}
            setOpenResources={setOpenResources}
            loading={modalLoading}
            setLoading={setModalLoading}
            onDeleteResource={onDeleteResourceClick}
            isDarkThemeEnabled={isDarkThemeEnabled}
            themedColors={themedColors}
            hideRightPanel={hideRightPanel}
            successMessage={successMessage}
          />
          <div className="nav-menu">
            <div className="menu-item">
              <div className="position-relative burger-icon">
                <MobileMenuWrapper onClick={() => setOpenLeftSection(true)}>
                  <img alt="burger-menu" src={BurgerMenu} />
                </MobileMenuWrapper>
                {hasUnreadCount && <span className="unread-count" />}
              </div>
              {getCurrent()}
            </div>
            {!hideRightPanel && (
              <div
                className="resources"
                tabIndex="0"
                role="button"
                onKeyDown={() => {}}
                onClick={() => setOpenRightSection(true)}
              >
                Resources
              </div>
            )}
          </div>
          <div className="contribution-community-router mobile-content">
            <ContributionCommunityRouter />
          </div>
        </>
      ) : (
        <Grid container className="community-section-container" spacing={2}>
          <Grid item md={3}>
            <LeftSectionMenu
              contribution={contribution}
              themedColors={themedColors}
              mobileView={mobileView}
              currentRole={currentRole}
              pathname={pathname}
              createSection={handleCreateSectionModal}
              sections={subSections}
              editSection={handleEditSectionModal}
              deleteSection={handleDeleteSection}
              archivedGroups={archivedGroups}
              createGroup={handleCreateGroup}
              handleGroupActions={handleGroupActions}
              currentGroup={currentGroup}
              isDarkThemeEnabled={isDarkThemeEnabled}
            />
          </Grid>
          <Grid item md={6} className="contribution-community-router">
            <ContributionCommunityRouter />
          </Grid>
          <Grid item md={3}>
            <RightSectionMenu
              currentRole={currentRole}
              mobileView={mobileView}
              setOpenLinks={setOpenLinks}
              setOpenResources={setOpenResources}
              loading={modalLoading}
              setLoading={setModalLoading}
              onDeleteResource={onDeleteResourceClick}
              isDarkThemeEnabled={isDarkThemeEnabled}
              hideRightPanel={hideRightPanel}
              successMessage={successMessage}
            />
          </Grid>
        </Grid>
      )}

      <SectionModal
        title={sectionModalTitle}
        isOpen={openSectionModal}
        onCancel={() => {
          setOpenSectionModal(false);
          setSectionToEdit(null);
        }}
        contributionId={contribution.id}
        section={sectionToEdit}
        onSubmit={values => handleCreateSection(values)}
      />

      <ConfirmationModal
        title={confirmationModalTitle}
        isOpen={openConfirmationModal}
        onCancel={() => {
          setOpenConfirmationModal(false);
          setSectionToEdit(null);
        }}
        onSubmit={() => handleDelete()}
      >
        {getConfirmationContent()}
      </ConfirmationModal>

      <GroupModal
        title={groupModalTitle}
        submitTitle={groupModalSubmitTitle}
        mode={groupModalMode}
        isOpen={openGroupModal}
        section={sectionToEdit}
        contribution={contribution}
        themedColors={themedColors}
        suggestedGroups={suggestedGroups}
        groupToEdit={groupToEdit}
        isDarkThemeEnabled={isDarkThemeEnabled}
        onSubmit={handleGroupSubmit}
        onCancel={() => {
          setGroupToEdit(null);
          setOpenGroupModal(false);
          setSectionToEdit(null);
        }}
      />

      <MoveToSectionModal
        isOpen={openMoveTosectionModal}
        groupToEdit={groupToEdit}
        sections={subSections}
        onCancel={() => {
          setGroupToEdit(null);
          setOpenMoveTosectionModal(false);
        }}
        onSubmit={handleGroupSubmit}
        isDarkThemeEnabled={isDarkThemeEnabled}
      />

      {openLinks && (
        <LinksModal
          contributionId={contribution?.id}
          darkThemeEnabled={isDarkThemeEnabled}
          isOpen={openLinks}
          successMessage={successMessage}
          onCancel={() => setOpenLinks(false)}
        />
      )}
      {openResources && (
        <ResourcesModal
          contributionId={contribution?.id}
          darkThemeEnabled={isDarkThemeEnabled}
          isOpen={openResources}
          onCancel={() => setOpenResources(false)}
          onDeleteResourceClick={onDeleteResourceClick}
          isDarkThemeEnabled={isDarkThemeEnabled}
          themedColors={themedColors}
          colorToUse={colorToUse}
        />
      )}
    </>
  );
};

ContributionCommunity.propTypes = {
  contribution: PropTypes.shape({
    type: string,
    isMembersHiddenInCommunity: bool,
    id: string,
    isGroupChatHidden: bool,
    isPurchased: bool,
  }).isRequired,
  currentRole: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  subSections: PropTypes.shape([]).isRequired,
  mainGroup: PropTypes.shape({}).isRequired,
  archivedGroups: PropTypes.shape([]).isRequired,
  getSections: PropTypes.func.isRequired,
  createOrEditSection: PropTypes.func.isRequired,
  deleteSection: PropTypes.func.isRequired,
  createOrEditGroup: PropTypes.func.isRequired,
  getSuggestedGroups: PropTypes.func.isRequired,
  suggestedGroups: PropTypes.shape([]).isRequired,
  currentGroup: PropTypes.shape({ imageUrl: string, title: string }).isRequired,
  muteOrUnmuteNotifications: PropTypes.func.isRequired,
  updateCurrentGroup: PropTypes.func.isRequired,
};

const actions = {
  getSections: fetchCommunitySections,
  createOrEditSection: createOrEditCommunitySection,
  deleteSection: deleteCommunitySection,
  createOrEditGroup: createOrEditCommunityGroup,
  getSuggestedGroups: fetchCommunitySuggestedGroups,
  muteOrUnmuteNotifications: muteOrUnmuteGroupNotifications,
  updateCurrentGroup: updateCurrentGroupData,
};

const mapStateToProps = ({
  contributions,
  account,
  communitySections: { isLoading, error, subSections, archivedGroups, suggestedGroups, currentGroup, mainGroup },
}) => ({
  contribution: contributions?.activeContribution,
  currentRole: account?.currentRole,
  loading: isLoading,
  error,
  subSections,
  archivedGroups,
  suggestedGroups,
  currentGroup,
  mainGroup,
});

export default connect(mapStateToProps, actions)(ContributionCommunity);
