import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import Button from 'components/FormUI/Button';
import ConditionalRenderBillingPage from 'pages/Account/CohealerBilling/ConditionalRenderBillingPage';
import BasicSmsCreditsInfo from 'pages/Account/CohealerBilling/SmsBillingPages/BasicSmsCreditsInfo';
import ChatAndGetStartedButtons from 'pages/Dashboard/components/CohealerDashboard/LiveChat/LiveChatContainer';
import { useLocation, useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  BillingButtonWrapper: {
    display: 'flex',
    gap: '15px',
  },
}));
export const StyledBorderdButton = styled(Button)`
  min-width: 175px;
  min-height: 48px;
  background-color: white;
  color: #c9b382;
  border: 1px solid #c9b382;
  padding: 12px 15px 12px 15px;
  font-size: 15px;
  font-weight: 800;
  font-family: 'Avenir';
  &:hover {
    background-color: white; // Keep the same color on hover
    color: #c9b382; // Keep the same text color on hover
  }
`;
export const StyleddButton = styled(Button)`
  min-width: 175px;
  min-height: 48px;
  font-size: 15px;
  font-weight: 800;
  font-family: 'Avenir';
  border-radius: 5px;
  border: 1px solid;
  padding: 12px 15px 12px 15px;
`;
const BillingButtons = () => {
  const classes = useStyles();
  const [activeButton, setActiveButton] = useState('CohereBillingPage');
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const billingType = queryParams.get('billingType');

  const handleButtonClick = button => {
    let newUrl = window.location.pathname;

    if (button === 'SmsBillingPage') {
      newUrl += '?billingType=sms';
    }

    setActiveButton(button);
    history.push(newUrl);
  };
  useEffect(() => {
    if (billingType === 'sms') {
      setActiveButton('SmsBillingPage');
    } else {
      setActiveButton('CohereBillingPage');
    }
  }, [billingType]);
  const BillingButton = ({ isActive, onClick, children }) => {
    const classes = useStyles();
    return isActive ? (
      <StyleddButton onClick={onClick}>{children}</StyleddButton>
    ) : (
      <StyledBorderdButton onClick={onClick}>{children}</StyledBorderdButton>
    );
  };

  return (
    <>
      <div>
        <div className={classes.BillingButtonWrapper}>
          <BillingButton
            isActive={activeButton === 'CohereBillingPage'}
            onClick={() => handleButtonClick('CohereBillingPage')}
          >
            Cohere Subscription
          </BillingButton>
          <BillingButton
            isActive={activeButton === 'SmsBillingPage'}
            onClick={() => handleButtonClick('SmsBillingPage')}
          >
            Credits & Add-ons
          </BillingButton>
        </div>
        <>
          {activeButton === 'CohereBillingPage' ? (
            <ConditionalRenderBillingPage />
          ) : activeButton === 'SmsBillingPage' ? (
            <BasicSmsCreditsInfo />
          ) : (
            <ConditionalRenderBillingPage />
          )}
        </>
      </div>
      <ChatAndGetStartedButtons page={'ProfilePage'} />
    </>
  );
};

export default BillingButtons;
