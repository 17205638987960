import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import {
  useActiveContributionSessionById,
  useActiveContributionSessionTimeOrContentById,
} from 'pages/ContributionView/hooks';
import VideoButton from 'components/FormUI/Button';
import { lightOrDark } from 'utils/utils';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { useAccount, useClientPreview } from 'hooks';
import { UserRoles } from 'helpers/constants';
import { BodyText } from 'components/UI/Text/TextStyles';
import { useRouter } from 'hooks';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import Modal from 'components/UI/Modal';
import { TOOLTIP } from '../../../../../constants';
import Tooltip from '@material-ui/core/Tooltip';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { DarkEnabledPrimaryFilledButton } from '../styled';
import { useSelector } from 'react-redux';
import { activeContribSessTabViewSettingsSelector } from 'selectors/contribution';

const StyledHeader = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: ${({ mobileView, isCoach }) =>
    mobileView ? 'flex-start' : isCoach ? 'space-between' : 'flex-start'};
  align-items: center;
  flex-shrink: 0;
  color: ${({ textColor }) => textColor || '#282B2B'};
  flex-wrap: wrap;
  gap: 0.625rem;
`;
const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: fit-content;
  /* max-width: ${({ maxWidth }) => maxWidth || '85%'}; */
  ${({ mobileView, isCoach }) =>
    mobileView
      ? css`
          flex-direction: column;
          align-items: flex-start;
          gap: 0;
          max-width: calc(100% - 54px);
        `
      : css`
          flex-direction: row;
          gap: 0.62rem;
          max-width: calc(100% - ${isCoach ? 128 : 35}px - 0.625rem);
        `}
`;
const StyledSubtitle = styled.div`
  color: ${({ textColor }) => textColor || 'var(--cohere-greys-000000, #000)'};
  font-family: Avenir;
  font-size: ${({ mobileView }) => (mobileView ? '0.8125rem' : '0.875rem')};
  font-style: normal;
  font-weight: 400;
  line-height: 1.49625rem;
  width: fit-content;
  /* max-width: 255px; */
  max-width: fit-content;
  min-width: fit-content;
  flex: 0;
`;
const StyledTitle = styled.div`
  color: ${({ textColor }) => textColor || '#282b2b'};
  font-family: Avenir;
  font-size: ${({ mobileView }) => (mobileView ? '1rem' : '1.875rem')};
  font-style: normal;
  font-weight: 800;
  line-height: 1.6875rem; /* 90% */
  /* text-transform: capitalize; */
  line-height: 2.5rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // max-width: calc(75% - 240px); /* Adjust the value to fit your layout */
  flex: 1;
  ${({ mobileView }) =>
    mobileView &&
    css`
      max-width: calc(100vw - 86px);
    `};
`;
const StyledDarkEnabledPrimaryFilledButton = styled(DarkEnabledPrimaryFilledButton)`
  /* max-height: 2.375rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; */
  margin-top: -5px;
`;
const checkIsFuture = date => {
  return moment(date).isAfter(moment(), 'm');
};
const LiveSessionHeader = ({ ExpansionRestorationIcon = null, textColor, CenterColumnBackButton = null }) => {
  const { selectedSessionId, selectedSessionTimeOrContentId } = useSelector(activeContribSessTabViewSettingsSelector);
  const { selectedSession } = useActiveContributionSessionById();
  const { selectedSessionTimeOrContent } = useActiveContributionSessionTimeOrContentById();
  const { id: sessionId, name } = selectedSession || {};
  const { startTime, isCompleted, completedDateTime } = selectedSessionTimeOrContent || {};

  const [isRescheduleModalOpen, setRescheduleModalState] = useState(false);
  const { protocol, domain, baseUrl } = useRouter();
  const { currentRole, user, isClient } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const contribution = useContribution();
  const { isDarkModeEnabled } = contribution;
  let colorToUse = determineColorToUse(contribution);
  const { clientPreviewMode } = useClientPreview();
  const theme = useTheme();
  const smView = useMediaQuery(theme.breakpoints.down('md'));
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const textColor2 =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const {
    themedColor: themedTextColor,
    themedCardBackgroundColor: oldThemedCardBackgroundColor,
    themedBackgroundColor: oldThemedBackgroundColor,
    newThemedTextColor,
    newThemedCardColor,
    newThemedBackgroundColor,
  } = getThemedColors(contribution);
  const themedColor = isCoach && !clientPreviewMode ? themedTextColor : newThemedTextColor;
  const btnColor = colorToUse?.PrimaryColorCode;
  const startTimeMoment = startTime ? moment(startTime) : null;
  const completedTimeMoment = completedDateTime ? moment(completedDateTime) : null;
  const clientDisable = !moment(startTime).isAfter(moment().add(24, 'h')) || isCompleted;
  const isRescheduleDisabled = contribution?.serviceProviderName ? clientDisable : isCompleted;

  const handleReschedule = e => {
    e.preventDefault();
    e.stopPropagation();
    // console.info('Session,selectedSessionId', selectedSessionId);
    // const nextState = {
    //   sessionId: selectedSessionId,
    //   contributionId: contribution.id,
    // };
    const editUrl = `/edit-contribution/${contribution.id}/sessions/${selectedSessionId}/${selectedSessionTimeOrContentId}`;
    // window.history.pushState(nextState, '', editUrl);
    // const contributionUrl = new URL(editUrl, baseUrl).toString();
    // window.location.href = contributionUrl;
    redirectToAppIfIsCoachDomain(domain, editUrl);
  };
  const subtitle = isCompleted
    ? `Completed on ${completedTimeMoment?.format('MMM Do YYYY')} at ${completedTimeMoment?.format('h:mma')}`
    : (checkIsFuture(startTimeMoment) && !isCompleted ? `Upcoming on ` : '') +
      `${startTimeMoment?.format('MMM Do YYYY')} at ${startTimeMoment?.format('h:mma')}`;
  // const visibleSubtitle =;
  return (
    <>
      <StyledHeader {...{ textColor, isCoach, mobileView }}>
        {CenterColumnBackButton && <CenterColumnBackButton />}
        <StyledTitleContainer
          // maxWidth={smView ? '73%' : '85%'}
          {...{ mobileView, isCoach }}
        >
          <Tooltip
            title={name}
            arrow
            enterTouchDelay={TOOLTIP.ENTER_DELAY}
            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            style={mobileView ? {} : { marginLeft: '5px' }}
          >
            <StyledTitle {...{ textColor, mobileView }}>{name}</StyledTitle>
          </Tooltip>
          <StyledSubtitle {...{ textColor, mobileView }}>{subtitle}</StyledSubtitle>
        </StyledTitleContainer>
        {!mobileView && isCoach && (
          <StyledDarkEnabledPrimaryFilledButton
            // textColor={contribution?.isDarkModeEnabled ? textColor2 : textColor}
            textColor={textColor2}
            backgroundColor={colorToUse?.PrimaryColorCode}
            autoWidth
            // disabled={isRescheduleDisabled}
            onClick={handleReschedule}
            // style={{ alignSelf: 'flex-end' }}
            {...{ isDarkModeEnabled }}
          >
            Reschedule
          </StyledDarkEnabledPrimaryFilledButton>
        )}
        {!mobileView && isClient && ExpansionRestorationIcon && <ExpansionRestorationIcon />}
      </StyledHeader>
      {isRescheduleModalOpen && (
        <Modal
          isOpen={isRescheduleModalOpen}
          onCancel={() => {
            setRescheduleModalState(false);
          }}
          PrimaryColor={colorToUse.PrimaryColorCode}
          textColor={textColor}
          onSubmit={() => {
            // setRedirectToEditContributionPage(true);
            redirectToAppIfIsCoachDomain(domain, `/edit-contribution/${contribution?.id}/sessions/${sessionId}`);
            setRescheduleModalState(false);
          }}
          title="Reschedule your session"
          hiddenCancel
          submitTitle="Reschedule Session"
          disableSubmitClick={isCompleted}
        >
          <BodyText color={themedColor}>
            To reschedule, please select a new time from your calendar. Also, please confirm with your client that the
            new time works for them prior to making the change. It will automatically update the time for all parties
            once you confirm.
            <br />
            {/* <br/>
       If you need to cancel with a client, your Client will need to cancel on their end.
       At this time, Cohere does not automate refunds and thus requires approval from your Client.
       If you think your client needs a refund, please email us at <a href="mailto:support@cohere.live">support@cohere.live</a>. */}
          </BodyText>
        </Modal>
      )}
    </>
  );
};

export default LiveSessionHeader;
