import React from 'react';
import classes from './ProgressBbar.module.scss';

const ProgressBar = ({ array, activeIndex, AccentColorCode, isBrandingColorEnabled }) => {
  const totalItems = array.length;
  const progress = (activeIndex + 1) / totalItems;

  return (
    <div className={classes.progress_container}>
      <div
        className={classes.progress_bar}
        style={
          isBrandingColorEnabled
            ? { width: `${progress * 100}%`, backgroundColor: AccentColorCode }
            : { width: `${progress * 100}%`, backgroundColor: ' #215c73' }
        }
      ></div>
    </div>
  );
};

export default ProgressBar;
