import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
import { useHeader } from 'hooks';
import { EngagementData } from 'pages/EngagementDataPage/EngagementData';

import MainContainer from './MainContainer';

const StyledMainSection = styled.div`
  padding: 55px 55px 35px;
  height: 100%;

  ${({ mobileView }) => mobileView && `padding: 16px;`}
`;

const EngagementDataContainer = ({ match: { path } }) => {
  useHeader('Engagement Data');
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MainContainer>
      <StyledMainSection mobileView={mobileView}>
        <Switch>
          <Route component={EngagementData} />
        </Switch>
      </StyledMainSection>
    </MainContainer>
  );
};

EngagementDataContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default EngagementDataContainer;
