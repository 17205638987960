import React, { useState, useRef, useEffect, useMemo, memo } from 'react';

import Slider from 'react-slick';
import styled, { css } from 'styled-components';
import { Tooltip } from '@material-ui/core';
import UploadThumbnailIcon from 'pages/CreateContribution/components/UploadThumbnailIcon';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { TOOLTIP } from '../../constants';
import { PlayIcon } from 'pages/ContributionView/ContributionSessions/component/icons';
import { useAccount, useClientPreview, useScreenView, useVideoChat } from 'hooks';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { UserRoles } from 'helpers/constants';
import { determineColorToUse } from 'services/contributions.service';
import { isEqual } from 'lodash';

const StyledUploadThumbnailContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 15px;
  z-index: 100;
  cursor: pointer;
`;
const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #215c73;
  font-family: 'Avenir';
  margin-top: 0.625rem;
`;

const StyledFooterContent = styled.div`
  color: ${({ accentColor }) => accentColor && accentColor};
  font-family: Avenir;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1rem; /* 114.286% */
`;

const StyledBackArrowIcon = styled(ArrowBackIosNewIcon)`
  cursor: pointer;
  font-size: 1rem;
  color: ${color => color};
`;
const StyledForwardArrowIcon = styled(ArrowForwardIosIcon)`
  cursor: pointer;
  font-size: 1rem;
  color: ${color => color};
`;
const StyledPlayButtonWrapper = styled.div`
  height: 52.045px;
  width: 52.045px;
  color: gray;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  border-radius: 46.263px;
  background: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 0.25rem;
  }
`;
const StyledPlayIcon = styled(PlayIcon)`
  height: 24.152px;
  width: 24.152px;
  color: #fff;
  position: absolute;
  z-index: 15;
  cursor: pointer;
`;
const StyledImg = styled.img`
  max-height: 100%;
  width: max-content;
  max-width: 100%;
  height: auto;

  /* height: ${({ mobileView, hasThumbnail }) => (mobileView || hasThumbnail ? 'auto' : '435px')}; */
  /* max-width: 100%; */

  /* height: ${({ mobileView }) => (mobileView ? 'auto' : '435px')}; */
  /* max-height: 100%; */
  /* width: 100%; */
  /* min-width: fit-content; */
  /* width: fit-content; */

  /* height: auto; */
`;
const StyledVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
  object-fit: contain;
`;
const StyledContain = styled.div`
  position: relative;
  width: 100% !important;
  padding-bottom: 56.25%;
  background: rgb(250, 250, 250);
  overflow: hidden;
  max-height: ${({ heightRatio }) => `${heightRatio}px`};
`;
const StyledPlayableImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ heightRatio }) => `${heightRatio}px !important`};
`;
const StyledVideoWrapper = styled.div`
  /* height: ${({ mobileView, hasThumbnail }) => (mobileView || hasThumbnail ? 'auto' : '435px')};
  width: 100%; */

  /* height: ${({ mobileView, heightRatio }) => (mobileView ? 'auto' : `${heightRatio}px !important`)};  */

  height: ${({ heightRatio }) => `${heightRatio}px !important`};

  /* width: 100%;
  /* width: ${({ widthRatio }) => `${widthRatio}px !important`};
  height: ${({ heightRatio }) => `${heightRatio}px !important`}; */
  background-color: black;
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
`;
const StyledVideoPlusThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CustomSlide = props => {
  const {
    roomId,
    widthRatio,
    heightRatio,
    poster,
    index,
    currentSlide,
    setCurrentRoomId,
    link,
    setCurrentVideoPoster,
    uploadThumbnail,
  } = props;
  const { isClient } = useAccount();
  const { mobileView } = useScreenView();
  const [timeStamp, setTimeStamp] = useState(0);
  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef(null);
  const contribution = useContribution();
  let colorToUse = determineColorToUse(contribution);

  useEffect(() => {
    if (!showVideo) {
      setTimeStamp(0);
    }
  }, [showVideo]);

  useEffect(() => {
    setShowVideo(false);
    setTimeStamp(0);
  }, [currentSlide]);

  const onPlay = () => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = timeStamp;
      video.play();
    }
  };

  const onPause = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      setTimeStamp(video.duration && video.currentTime === video.duration ? 0 : video.currentTime);
      if (video.duration && video.currentTime === video.duration) {
        video.load();
      }
    }
  };

  return isClient ? (
    // <StyledVideoWrapper key={videoSource?.roomId} hasThumbnail={Boolean(videoSource?.poster || poster)}>
    <StyledContain
      className="lllllllll"
      key={roomId}
      {...{ widthRatio, heightRatio, mobileView }}
      // style={{
      //   position: 'relative',
      //   width: '100% !important',
      //   paddingBottom: '56.25%',
      //   background: 'black',
      //   overflow: 'hidden',
      // }}
    >
      {showVideo === false ? (
        <StyledPlayableImageContainer
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setShowVideo(true);
            // setTimeout(() => {
            //   onPlay();
            // }, [2000]);
          }}
          {...{ widthRatio, heightRatio, mobileView }}
          style={{ background: 'rgb(250, 250, 250)' }}
        >
          <StyledImg src={poster} mobileView={mobileView} hasThumbnail={Boolean(poster)} />

          <StyledPlayButtonWrapper backgroundColor={colorToUse?.AccentColorCode}>
            <StyledPlayIcon />
          </StyledPlayButtonWrapper>
        </StyledPlayableImageContainer>
      ) : (
        <StyledVideo
          ref={index === currentSlide - 1 ? videoRef : null}
          onPlay={onPlay}
          onPause={onPause}
          // preload="none"
          controls
          autoPlay
          // poster={videoSource?.poster || poster}
          mobileView={mobileView}
        >
          <source src={link} />
        </StyledVideo>
      )}
    </StyledContain>
  ) : (
    <>
      {' '}
      {/* <StyledVideoWrapper key={videoSource?.roomId} hasThumbnail={Boolean(videoSource?.poster || poster)}> */}
      <StyledVideoWrapper key={roomId} {...{ widthRatio, heightRatio, mobileView }}>
        <StyledVideoPlusThumbnailContainer
          className="position-relative w-100"
          style={{
            position: 'relative',
            width: '100% !important',
            paddingBottom: '56.25%',
            background: 'rgb(250,250,250)',
            overflow: 'hidden',
          }}
        >
          <Tooltip title="Thumbnail" arrow enterTouchDelay={TOOLTIP.ENTER_DELAY} leaveTouchDelay={TOOLTIP.LEAVE_DELAY}>
            <StyledUploadThumbnailContainer
              onClick={() => {
                setCurrentRoomId(roomId);
                setCurrentVideoPoster(poster);
                uploadThumbnail(true);
              }}
            >
              <UploadThumbnailIcon style={{ width: '36px', height: '37px', backgroundColor: 'transparent' }} />
            </StyledUploadThumbnailContainer>
          </Tooltip>
          {showVideo === false ? (
            <StyledPlayableImageContainer
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setShowVideo(true);
                // setTimeout(() => {
                //   onPlay();
                // }, [2000]);
              }}
              {...{ widthRatio, heightRatio, mobileView }}
            >
              <StyledImg src={poster} mobileView={mobileView} hasThumbnail={Boolean(poster)} />
              <StyledPlayButtonWrapper backgroundColor={colorToUse?.AccentColorCode}>
                <StyledPlayIcon />
              </StyledPlayButtonWrapper>
            </StyledPlayableImageContainer>
          ) : (
            <>
              {' '}
              <StyledVideo
                ref={index === currentSlide - 1 ? videoRef : null}
                onPlay={onPlay}
                onPause={onPause}
                controls
                autoPlay
                // poster={videoSource?.poster || poster}
                mobileView={mobileView}
              >
                <source src={link} />
              </StyledVideo>
            </>
          )}
          {/* <video
            preload="none"
            controls
            poster={videoSource.poster ? videoSource.poster : poster}
            style={{ height: '465px', width: '100%' }}
          >
            <source src={videoSource.link} />
          </video> */}
        </StyledVideoPlusThumbnailContainer>
      </StyledVideoWrapper>
    </>
  );
};

const VideoCarousel = ({
  videoSources = [],
  uploadThumbnail,
  poster,
  setCurrentRoomId,
  setCurrentVideoPoster,
  widthRatio,
  heightRatio,
}) => {
  const sliderRef = useRef(null);
  const videoRef = useRef(null);
  const [timeStamp, setTimeStamp] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [slideCount, setSlideCount] = useState(videoSources?.length);
  const [showVideo, setShowVideo] = useState(false);
  const contribution = useContribution();
  let colorToUse = determineColorToUse(contribution);

  const prevArrowHandler = () => {
    sliderRef.current.slickPrev();
    setShowVideo(false);
    if (currentSlide > 1) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const nextArrowHandler = () => {
    sliderRef.current.slickNext();
    setShowVideo(false);
    if (currentSlide < videoSources?.length) {
      setCurrentSlide(currentSlide + 1);
    }
  };
  const CustomFooter = ({ children }) => (
    <StyledFooter>
      <StyledBackArrowIcon onClick={prevArrowHandler} color={colorToUse?.AccentColorCode} />
      <StyledFooterContent accentColor={colorToUse?.AccentColorCode}>
        {currentSlide}/{slideCount}
      </StyledFooterContent>
      <StyledForwardArrowIcon onClick={nextArrowHandler} color={colorToUse?.AccentColorCode} />
    </StyledFooter>
  );
  const sliderConfig = useMemo(
    () => ({
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <></>,
      nextArrow: <></>,
      appendDots: () => <CustomFooter />,
      lazyLoad: false,
      // swipe: false,
      beforeChange: (current, next) => {
        // onPause(current);
        setTimeStamp(0);
        setShowVideo(false);
      },
      afterChange: current => {
        console.info('current-slide:', current);
        setCurrentSlide(current + 1);
      },
      onSwipe: swipeDirection => {
        setShowVideo(false);
      },
    }),
    [currentSlide, slideCount],
  );

  return (
    <Slider ref={sliderRef} {...sliderConfig}>
      {videoSources?.map((videoSource, index) => (
        <CustomSlide
          {...{
            roomId: videoSource?.roomId,
            widthRatio,
            heightRatio,
            poster: videoSource?.poster || poster,
            index,
            currentSlide,
            setCurrentRoomId,
            link: videoSource?.link,
            setCurrentVideoPoster,
            uploadThumbnail,
          }}
        />
      ))}
    </Slider>
  );
};

export default memo(VideoCarousel, isEqual);
