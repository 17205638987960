import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from 'components/FormUI/Button';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import DetailedSmsCreditsInfo from 'pages/Account/CohealerBilling/SmsBillingPages/DetailedSmsCreditsInfo';
import { SmsBillingServices } from './Services/SmsBillingServices';
import Loader from 'components/UI/Loader';
import { colors } from 'utils/styles';
const useStyles = makeStyles(theme => ({
  headerContainer: {
    border: '1px solid #E7E7E7',
    marginTop: '15px',
    padding: '10px 20px 10px 20px',
  },
  discriptionContainer: {
    borderLeft: '1px solid #E7E7E7',
    borderRight: '1px solid #E7E7E7',
    borderBottom: '1px solid #E7E7E7',
    padding: '10px 20px 10px 14px',
  },
  title: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textAlign: 'left',
  },
  description: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: '500',
  },
  reamingCredits: {
    display: 'flex',
    gap: '10px',
    padding: '0px 8px 8px 8px',
    alignItems: 'flex-end',
  },
  reamingCreditsStaticText: {
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textAlign: 'left',
  },
  creditsCostStaticText: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: '400',
    padding: '0px 8px 8px 8px',
  },
  credits: {
    color: '#215C73',
    fontWeight: 'bold',
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: '500',
  },
  creditsButtonWrapper: {
    display: 'flex',
    gap: '15px',
  },
}));
export const StyleddButton = styled(Button)`
  min-width: 136px;
  min-height: 48px;
  font-size: 14px;
  font-weight: 800;
  padding: 7px 20px 7px 20px;
`;
const BasicSmsCreditsInfo = () => {
  const classes = useStyles();
  const [showDetailedInfo, setShowDetailedInfo] = useState(false);
  const addCredits = () => {
    setShowDetailedInfo(true);
  };
  const [smsCredits, setSmsCredits] = useState({
    totalRemainingPurchasedCredits: 0,
    totalRemainingPurchasedCreditsAmount: 0,
    totalRemainingComplementaryCredits: 0,
    totalRemainingCredits: 0,
    isAcceleratePlanUser: false,
    threshHoldPlanId: '',
    rechargerPlanId: '',
    isAutoChargeEnabled: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const fetchSmsCredits = async () => {
    try {
      const response = await SmsBillingServices.GetSmsCredits();
      setSmsCredits(response);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching SMS plans:', error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchSmsCredits();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className={classes.container}>
        {showDetailedInfo ? (
          <DetailedSmsCreditsInfo smsCredits={smsCredits} />
        ) : (
          <>
            <div className={classes.headerContainer}>
              <Typography className={classes.title}>SMS Credits</Typography>
            </div>
            <div className={classes.discriptionContainer}>
              <div className={classes.description}>
                <div style={{ padding: '5px 5px 0px 0px' }}>
                  <div className={classes.reamingCredits}>
                    <Typography className={classes.reamingCreditsStaticText}>Text (SMS) Reminders</Typography>
                    <Typography className={classes.credits}>
                      {(() => {
                        const allConsumedCredits =
                          smsCredits.totalRemainingComplementaryCredits + smsCredits.totalRemainingPurchasedCredits;
                        return allConsumedCredits > 0
                          ? allConsumedCredits.toLocaleString() + ' Credits Remaining'
                          : 'No credits purchased';
                      })()}
                    </Typography>
                  </div>
                  <Typography className={classes.creditsCostStaticText}>
                    You can purchase credits for reminders on our Impact, Impact Plus, and Accelerate plans. Accelerate
                    plans include 1500 credits per month. To buy credits individually, reminders cost 1 credit ($0.05)
                    each.
                  </Typography>
                </div>
                <div className={classes.creditsButtonWrapper}>
                  <StyleddButton className={classes.creditsButton} onClick={() => addCredits(true)}>
                    Add Credits
                  </StyleddButton>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BasicSmsCreditsInfo;
