import React from 'react';
import styled from 'styled-components';

import logo from 'assets/logo.png';
import coherelogo from 'assets/coherelogo.png';
const CohereLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  width: 10rem;
  height: 90px;

  ${({ chatlogo }) =>
    chatlogo &&
    `
  width: 83px;
  height: 48px;
  margin-bottom: 10px;

  `}

  ${({ startlogo, islarge }) =>
    startlogo &&
    `
    width: ${islarge ? '65px' : '40px'};
    height: ${islarge ? '65px' : '40px'};
    margin-bottom: 0px;
  `}
`;

const Logo = ({ chatlogo, startlogo = false, islarge = false }) => {
  return <CohereLogo chatlogo={chatlogo} startlogo={startlogo} islarge={islarge} src={startlogo ? coherelogo : logo} />;
};

export default Logo;
