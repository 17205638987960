import React from 'react';

const CorrectIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 10.1111C0 7.45892 1.05357 4.91538 2.92893 3.04002C4.8043 1.16465 7.34784 0.111084 10 0.111084C12.6522 0.111084 15.1957 1.16465 17.0711 3.04002C18.9464 4.91538 20 7.45892 20 10.1111C20 12.7632 18.9464 15.3068 17.0711 17.1822C15.1957 19.0575 12.6522 20.1111 10 20.1111C7.34784 20.1111 4.8043 19.0575 2.92893 17.1822C1.05357 15.3068 0 12.7632 0 10.1111ZM9.42933 14.3911L15.1867 7.19375L14.1467 6.36175L9.23733 12.4964L5.76 9.59908L4.90667 10.6231L9.42933 14.3924"
        fill="#259945"
      />
    </svg>
  );
};

export default CorrectIcon;
