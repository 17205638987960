import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Loader from 'components/UI/Loader';
import '../../../pages/AllApplicationsPage/ApplicationPage.scss';
import { StyledShareButton } from 'pages/ContributionView/components/ContributionHeader/styled';
import { Icon } from '@mdi/react';
import { mdiShareVariant } from '@mdi/js';
import ExportIcon from 'pages/Contacts/Components/Icons/ExportIcon';
import ShareModal from 'pages/ContributionView/components/ShareModal/ShareModalContainer';
import EditButton from 'components/UI/ListButtons/EditButton';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteButton from 'components/UI/ListButtons/DeleteButton';
import { getCohealerContribution, shareViaEmail } from 'services/contributions.service';
import usePaidTier from 'hooks/usePaidTier';
import moment from 'moment';
import useAccount from 'hooks/useAccount';
import { fetchContributionActions } from 'actions/contributions';
import CreateApplicationFormModal from 'pages/ContributionView/components/ContributionHeader/ApplicationForm/CreateApplicationFormModal';
import { downloadFile, formatDate, formatTime } from 'utils/utils';
import { TRIAL_PERIOD_DAYS, TRIAL_PERIOD_START_DATE } from '../Contribution';
import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import * as paidTier from 'selectors/paidTier';
import { isPaidTierAnnualSelector } from 'selectors/user';
import useShallowEqualSelector from '../../../hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from '../../../constants';
import { ContributionType, UserRoles } from 'helpers/constants';
import { ExportEnrollmentFormResponses } from 'services/user.service';
const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    fontWeight: 350,
  },
})(TableCell);

export const EnrollmentFormRow = ({ index, row }) => {
  const {
    contributionId,
    contributionName,
    createTime,
    id: formId,
    contributionType,
    numberOfQuestions,
    numberOfResponses,
    enrolmentFormCreationDate,
  } = row;
  const url = `/contribution-view/${contributionId}/about`;
  const { user } = useAccount();
  const dispatch = useDispatch();

  return (
    <>
      <TableRow key={index}>
        <NarrowTableCell style={{ textDecoration: 'underline' }}>
          <Link to={url}>{contributionName}</Link>
        </NarrowTableCell>
        <NarrowTableCell component="th" scope="row">
          <div
            style={{
              padding: '7px 10px 7px 10px',
              fontWeight: '800',
              fontFamily: 'Avenir',
              fontSize: '14px',
              color: '#A58B62',
              borderRadius: '4px',
              border: '1px solid #C9B382',
              background: '#C9B3821A',
              width: 'fit-content',
            }}
          >
            {contributionType === ContributionType.contributionOneToOne
              ? '1:1 Sessions'
              : contributionType === ContributionType.contributionCourse
              ? 'Group Course'
              : contributionType === ContributionType.contributionMembership
              ? 'Memberships'
              : contributionType === ContributionType.contributionCommunity
              ? 'Community'
              : 'Workshop'}
          </div>
        </NarrowTableCell>
        <NarrowTableCell>
          <div style={{ fontFamily: 'Avenir', fontWeight: '350', fontSiz: '14px' }}>{numberOfQuestions}</div>
        </NarrowTableCell>
        <NarrowTableCell>
          <div style={{ fontFamily: 'Avenir', fontWeight: '350', fontSiz: '14px' }}>{numberOfResponses}</div>
        </NarrowTableCell>
        <NarrowTableCell>{formatDate(enrolmentFormCreationDate)}</NarrowTableCell>
        <NarrowTableCell align="center">
          <div
            onClick={() => {
              ExportEnrollmentFormResponses(formId).then(res => {
                downloadFile(res, 'EnrollmentFormResponses.csv');
              });
            }}
            style={{ cursor: 'pointer' }}
          >
            <ExportIcon width={25} height={25} />
          </div>
        </NarrowTableCell>
      </TableRow>
    </>
  );
};
EnrollmentFormRow.propTypes = {
  index: PropTypes.string.isRequired,
  row: PropTypes.shape({
    contributionName: PropTypes.string.isRequired,
    formLinkId: PropTypes.string.isRequired,
    contributionId: PropTypes.string.isRequired,
    questions: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};

EnrollmentFormRow.defaultProps = {
  row: {
    contributionName: '',
    formLinkId: '',
    contributionId: '',
    questions: '',
  },
};
