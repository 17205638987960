import React, { useRef } from 'react';
import classes from './QuizImage.module.scss';
import newPlaceholder from 'assets/placeholdenew.png';
import { UploadImageIcon } from 'pages/Profile/components/icons/UploadImage';
import styled from 'styled-components';
import ImageIcon from '../../Assests/Icons/ImageIcon';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'components/UI/Loader';
import CameraIcon from './Icons/CameraIcon';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledImage = styled.img`
  background-image: url(${({ url }) => url});
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const QuizImage = ({ url, onUpload, onDelete, isUploading }) => {
  const inputRef = useRef();

  return (
    <div className={classes.container}>
      {isUploading && <Loader />}
      {url ? (
        <StyledImage url={url} />
      ) : (
        <div className={classes.image_placeholder}>
          <ImageIcon />
        </div>
      )}
      {!url && (
        <div
          className={classes.upload_btn}
          role="button"
          tabIndex="0"
          onKeyUp={() => inputRef.current.click()}
          onClick={() => inputRef.current.click()}
        >
          <CameraIcon />
        </div>
      )}
      {url && (
        <div className={classes.delete_btn} role="button" tabIndex="0" onClick={onDelete}>
          {/* <CloseIcon style={{ color: 'white', width: '16px', height: '16px' }} /> */}
          <DeleteIcon style={{ color: 'white', width: '16px', height: '16px' }} />
        </div>
      )}
      <input type="file" accept="image/*" onChange={onUpload} ref={inputRef} hidden />
    </div>
  );
};

export default QuizImage;
