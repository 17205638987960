import React, { useState, useEffect } from 'react';
import classes from './RankingOption.module.scss';
import { Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CrossIcon from 'pages/Contacts/Components/Filter/SVG/CrossIcon';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import styled from 'styled-components';
import Loader from 'components/UI/Loader';
import { convertToPlainText } from 'utils/utils';
import RankingTag from '../RankingTag/RankingTag';

const StyledLabel = styled.div`
  font-family: 'Avenir';
  font-weight: 350;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
`;

const DragIcon = ({ parentProvided }) => {
  return (
    <div className={classes.dragicon_container} {...parentProvided.dragHandleProps}>
      <DragIndicatorIcon className={classes.drag_icon} />
    </div>
  );
};

const RankingOption = ({
  option,
  parentProvided,
  questionsIndex,
  index,
  setFieldValue,
  onRemoveChoice,
  question,
  onRankingInputChange = () => {},
  clientView = false,
}) => {
  const hasCorrectAnswer = question?.options?.some(item => item.isCorrect === true);

  return (
    <Droppable droppableId={`option-${index}`}>
      {(provided, snapshot) => (
        <div {...provided.droppableProps} innerRef={provided.innerRef}>
          <div className={classes.container}>
            <TextField
              className={classes.textfield_root}
              variant="outlined"
              InputProps={{
                classes: {
                  root: classes.input_root,
                  notchedOutline: classes.input_notchedOutline,
                  focused: classes.input_focused,
                  input: classes.input_input,
                },
                endAdornment: <DragIcon parentProvided={parentProvided} />,
                startAdornment: <RankingTag number={index + 1} />,
              }}
              value={option?.value}
              onChange={onRankingInputChange}
              disabled={clientView}
            />

            {clientView === false && (
              <div
                className={classes.cross_icon_container}
                onClick={() => {
                  onRemoveChoice(index);
                }}
              >
                <CrossIcon />
              </div>
            )}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default RankingOption;
