import React from 'react';

const DropdownIcon = () => {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L5 5L9 1" stroke="#213649" stroke-width="1.5" stroke-linecap="round" />
    </svg>
  );
};

export default DropdownIcon;
