import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CoachImage from 'assets/chatlogo.png';
import Button from 'components/FormUI/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import ReactCrop from 'react-image-crop';
import CrossIcon from '@material-ui/icons/Clear';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import 'react-image-crop/dist/ReactCrop.css';
import ImageCropper from '../CreateContribution/components/Cropper/ImageCropper';
import styled from 'styled-components';
import { mdiAccountOutline, mdiHeart, mdiRun, mdiTabletIpad, mdiLightbulb } from '@mdi/js';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@material-ui/core/Tooltip';
import ExamplePDF from 'assets/cohere_template_example.pdf';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import TextField from 'components/UI/TextField';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import MainContainer from 'components/Containers/MainContainer';
import * as contributionActions from 'actions/contributions';
import * as leadMagnetActions from 'actions/leadMagnets';
import { ContributionType, LeadMagnetStatus } from 'helpers/constants';
import Select from 'components/FormUI/Select';
import Input from 'components/FormUI/Input';
import Modal from 'components/UI/Modal';
import TextArea from 'components/FormUI/TextArea';
import { useSaveLeadMagnet } from 'hooks';
import { useRouter, useHttp } from 'hooks';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { PageTitleSecond, BodyText } from 'components/UI/Text/TextStyles';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import Buttons from './components/Buttons';
import SamplePfd from './sample.pdf';
import { colors } from '../../utils/styles/styles';
import { CONTRIBUTION_COLORS, TOOLTIP } from '../../constants';
import { Construction } from '@mui/icons-material';
import ButtonExample from 'pages/Profile/ButtonExample';
import * as userActions from 'actions/user';
import { addCalendarAccount } from 'services/calendars.service';
import { convertToPlainText, dataURLtoFile } from 'utils/utils';
import moment from 'moment';
import TemplateOne from 'assets/congratulations.png';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { MenuItem, OutlinedInput } from '@material-ui/core';
import { editUserColors } from 'services/user.service';
import { getLatestApproved, getUpcomingCreated } from 'services/contributions.service';
import { BsFillHeartFill, BsFillPersonFill, BsFillQuestionCircleFill, BsFillLightbulbFill } from 'react-icons/bs';

const ExampleLink = styled.a`
  margin-left: auto;
  padding-left: 10px;
  white-space: nowrap;

  ${({ mobileView }) => mobileView && `margin-top: 10px; white-space: normal;`}
`;

const Image = styled.img`
  background-color: #f5f5f5;
  width: 100%;
  max-height: 500px;
  object-fit: contain;
  border: solid 1px #dadada;
`;

const StyledColorCodeWrapper = styled.div`
  display: flex;
  width: 70%;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
`;
const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const StyledCloseIcon = styled(CrossIcon)`
  margin: 5px;
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const StyledTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.115;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  ${({ mobileView }) => mobileView && `font-size: 16px`}
`;

const InputHidden = styled.input.attrs({ type: 'file' })`
  display: none;
`;

const StyledColorSection = styled.div`
  margin-top: 15px;
`;

const Container = styled.div`
  cursor: pointer;
`;

const StyledColorPicker = styled.div`
  border: 1px solid rgb(178, 202, 224);
  border-radius: 5px;
  display: flex;
  padding: 3px;
  width: 145px;
  height: 32px;
`;

const StyledColorSelector = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const StyledIconSection = styled.div`
  margin-top: 15px;
`;

const EmptyIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  color: #bdbdbd;
  height: 75px;
  width: 75px;
  border-radius: 10px;
`;

const StyledHeading = styled(Typography)`
  color: '#213649';
`;

const IconContainer = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles({
  checkboxLabel: {
    fontSize: 13.7,
    fontFamily: 'Avenir',
  },

  detailsHeader: {
    marginRight: '20px',
    whiteSpace: 'nowrap',
  },

  colorSelect: {
    // borderRadius: '5px',
    // display: 'flex',
    // padding: '3px',
    width: '145px',
    // height: '32px',
    backgroundColor: 'transparent',
    // marginTop: '-30px',
  },

  customBranding: {
    marginRight: '0px',
  },
  hidechat: {
    marginRight: '20px',
    marginTop: '10px',
  },
  hideMemberCommunity: {
    alignSelf: 'end',
    margin: '8px 0px ',
    width: '160px',
  },

  hideMemberOnGroupChatLiveSession: {
    alignSelf: 'end',
    margin: '8px 0px ',
    width: '200px',
  },

  header: ({ mobileView }) =>
    mobileView
      ? {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingBottom: '10px',
        }
      : {},
});

const SocialGrid = styled(Grid)`
  display: flex;
  justify-content: flex-start;
`;

const DetailsGrid = styled(Grid)`
  position: relative;
`;

const TextAreaWrapper = styled(Grid)`
  ${({ isCollapsed }) =>
    !isCollapsed &&
    `
      position: absolute;
      z-index: 2;
      background: white;
      height: -webkit-fill-available;
      margin-bottom: 15px;
      box-shadow: 0 2px 8px 1px ${colors.shadow};
    `}
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: -8px;
  top: calc(50% - 5px);
  transform: translateY(-50%);
`;

const StyledTooltipModal = styled(Tooltip)`
  position: absolute;
  right: -23px;
  top: calc(50% - 0px);
  transform: translateY(-50%);
`;

const StyledTooltipWrap = styled.span`
  position: relative;
  padding-right: 7px;
  display: inline-block;
`;
const StyledCheckbox = styled(Checkbox)`
  padding-left: 0 !important;
`;

const StyledTooltipWrapModal = styled.span`
  position: relative;
  padding-right: 7px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function DetailsForm({ user, leadMagnet, saving, saveLeadMagnet, saveLeadMagnetToLS }) {
  const TEXT_AREA_SIZE = {
    SMALL: 6,
    HUGE: 12,
  };
  const { pathname } = useRouter();

  const theme = useTheme();
  const IconUpload = useRef();
  const [openBrandingModal, setOpenBrandingModal] = useState(false);
  const [openBrandingModalPopUp, setOpenBrandingModalPopUp] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ mobileView });
  const { query, history } = useRouter();
  const isCACStep =
    history.location.pathname.includes('edit-contribution') || history.location.pathname.includes('create-contribution')
      ? false
      : true;
  const contributionFormRef = useRef();
  const iconRef = useRef(null);
  const dispatch = useDispatch();
  const isEditing = Boolean(query.id);
  const { onSave: onSaveLeadMagnet } = useSaveLeadMagnet(isEditing);
  const [redirectTo, setRedirectTo] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [whoIAmCollapsed, setWhoIAmCollapsed] = useState(true);
  const handleResizeWhoIAm = useCallback(() => setWhoIAmCollapsed(prevState => !prevState), []);
  const [purposeCollapsed, setPurposeCollapsed] = useState(true);
  const handleResizePurpose = useCallback(() => setPurposeCollapsed(prevState => !prevState), []);
  const [whatWeDoCollapsed, setWhatWeDoCollapsed] = useState(true);
  const handleResizeWhatWeDo = useCallback(() => setWhatWeDoCollapsed(prevState => !prevState), []);
  const [preparationCollapsed, setPreparationCollapsed] = useState(true);
  const handlePreparationResize = useCallback(() => setPreparationCollapsed(prevState => !prevState), []);
  const [imageForCrop, setImageForCrop] = useState(null);
  const saveHandler = isEditing ? saveLeadMagnet : saveLeadMagnetToLS;
  const [clickedIconeType, setClickedIconeType] = useState(null);
  const [imageToCrop, setImageToCrop] = useState('');
  const [run, setRun] = useState(!user?.userProgressbarData?.FirstContributionDone);

  const [stepIndex, setStepIndex] = useState(4);
  const [showModal, setShowModal] = useState(false);
  const [contibId, setContibId] = useState(null);
  const errorOccured = useSelector(state => state?.leadMagnets.showError);

  useEffect(() => {
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, []);
  const togglePopup = () => {
    setShowModal(!showModal);
  };
  let joyRideHelpers;
  useEffect(() => {
    if (isEditing === true) {
      setRun(false);
    }
  }, []);

  const getOptions = () => {
    const { paymentInfo: { paymentOptions = [] } = {} } = leadMagnet;
    let options = [
      { title: 'Free', value: 'Free' },
      { title: 'Paid', value: 'Paid' },
      { title: 'Both', value: 'Both' },
    ];
    const isPaid =
      paymentOptions?.includes('EntireCourse') ||
      paymentOptions?.includes('PerSession') ||
      paymentOptions?.includes('SessionsPackage') ||
      paymentOptions?.includes('SplitPayments') ||
      paymentOptions?.includes('MonthlySessionSubscription');
    const isFree = paymentOptions.includes('Free') || paymentOptions.includes('FreeSessionsPackage');
    if (!(isPaid && isFree) && !pathname.includes('/create-contribution')) {
      if (isPaid) options = [{ title: 'Paid', value: 'Paid' }];
      if (isFree) options = [{ title: 'Free', value: 'Free' }];
    }
    return options;
  };

  const [steps, setSteps] = useState([
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Landing Page Details
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            These sections will be displayed on your landing page to give your clients details about your service! Feel
            free to customize the titles and details and/or hide sections. You can edit these sections at any time.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#contributionDetails',
    },
    // {
    //   hideFooter: true,
    //   disableBeacon: true,
    //   content: (
    //     <div>
    //       <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
    //       <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
    //       <div style={{ color: '#A58B62', fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
    //         You are fully set up & ready to transform lives!
    //       </div>
    //       <Button
    //         autoWidth
    //         backgroundColor={'#C9B382'}
    //         style={{
    //           marginTop: '40px',
    //           paddingTop: '10px',
    //           paddingBottom: '10px',
    //         }}
    //         onClick={() => {
    //           joyRideHelpers.skip();
    //         }}
    //       >
    //         Let’s do this!
    //       </Button>
    //     </div>
    //   ),
    //   placement: 'center',
    //   target: 'body',
    // },
  ]);

  const updateUserF = async () => {
    let obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        FirstContributionDone: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      if (res.userProgressbarData?.IsPlanPurchased === false) {
        history.push('/account/payment', { from: 'verify' });
      } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
        history.push('/account/payment', { from: 'bankAccount' });
      } else if (res?.userProgressbarData?.IntegrationsDone === false) {
        history.push('/account/integrations', { from: 'bankAccount' });
      } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
        history.push('/account/profile');
      } else if (res?.userProgressbarData?.FirstContributionDone === false) {
        dispatch(contributionActions.setPopUp(true));
        history.push('/dashboard');
      } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
        history.push('/account/profile', { from: 'Manage Your Website Information' });
      } else if (res?.userProgressbarData?.InviteLink === false) {
        contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
      }
    });
    joyRideHelpers.skip();
  };

  useEffect(() => {
    if (!!user?.userProgressbarData?.FirstContributionDone) {
      setSteps([]);
    }
  }, []);

  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }

    console.groupCollapsed(type);
    console.log(data);
    console.groupEnd();
  };
  const colorOptions = [
    { value: '#000000', title: 'Black' },
    { value: '#FFFFFF', title: 'White' },
    { value: 'Auto', title: 'Auto' },
  ];

  const onUploadFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageToCrop(reader.result?.toString() || ''));
      reader.readAsDataURL(e.target.files[0]);
      setIsOpen(true);
    }
  };

  const saveToStorageHandler = useCallback(
    (values, formikHelpers, to = '') => {
      let data;
      const {
        PrimaryColorCode,
        AccentColorCode,
        TertiaryColorCode,
        TextColorCode,
        customLogo,
        isDarkModeEnabled,
        ...rest
      } = values;
      let coachSelectedBrandingColors = {
        PrimaryColorCode: user.brandingColors.PrimaryColorCode,
        AccentColorCode: user.brandingColors.AccentColorCode,
        TertiaryColorCode: user.brandingColors.TertiaryColorCode,
        TextColorCode: user.brandingColors.TextColorCode,
      };
      let brandingColors = {
        PrimaryColorCode,
        AccentColorCode,
        TertiaryColorCode,
        TextColorCode,
      };
      data = {
        ...rest,
        customLogo: values.customLogo,
        isDarkModeEnabled: isDarkModeEnabled,
        coachSelectedBrandingColors: coachSelectedBrandingColors,
        brandingColors: brandingColors,
        isLegacyColorsActive: values.isLegacyColorsActive,
        isCustomBrandingColorsActive: values.isCustomBrandingColorsActive,
        languageCodes: values.languageCodes,
        minAge: String(values.minAge),

        inviteClientType: values.inviteClientType,
      };

      const newRecord = {
        ...leadMagnet,
        ...data,
      };
      console.info('step2-obj:', cloneDeep(newRecord));
      saveHandler(newRecord);
      onSaveLeadMagnet(newRecord);
      history.push(to || redirectTo);
    },
    [leadMagnet, history, saveHandler, onSaveLeadMagnet, redirectTo],
  );
  const isInvitationDisabled = !!query.id && leadMagnet?.status !== LeadMagnetStatus.unfinished;
  const { request, loading } = useHttp();

  const saveColor = obj => {
    const { colorName, colorCode, formValues } = obj;
    const { PrimaryColorCode, AccentColorCode, TertiaryColorCode, TextColorCode, ...rest } = formValues || {};
    const brandColors = {
      PrimaryColorCode,
      AccentColorCode,
      TertiaryColorCode,
      TextColorCode,
    };
    const userData = {
      ...rest,
      brandingColors: {
        ...brandColors,
        [colorName]: colorCode,
      },
      id: user.id,
      accountId: user.accountId,
      avatarUrl: user.avatarUrl,
      BirthDate: moment.utc(formValues?.BirthDate).format(),
      serviceAgreementType: user.serviceAgreementType,
      isBetaUser: user.isBetaUser,
    };

    // editProfile(user.id, userData);
  };

  const handleFormKeyDown = e => {
    if (e.target.tagName !== 'TEXTAREA' && (e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  const onClickHandler = () => {
    if (IconUpload) {
      IconUpload.current.click();
    }
  };

  const onModalClose = () => {
    setIsOpen(false);
  };

  const handleIconChange = useCallback((e, setFieldValue) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        const formData = new FormData();
        // setFieldValue('customLogo', reader.result);
        formData.append('file', dataURLtoFile(reader.result));
        request('/content/addpublicimage', 'POST', formData, {
          'Content-Type': 'multipart/form-data',
        })
          .then(image => {
            setFieldValue('customLogo', image);
          })
          .catch(console.dir)
          .finally(() => {
            // onClose();
            // setUploading(false);
          });
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const handleSubmitCropper = () => {
    setIsOpen(false);
  };
  const iconType = {
    whoIAm: 'whoIAm',
    whatWeDo: 'whatWeDo',
    purpose: 'purpose',
    preparation: 'preparation',
  };

  function handleIconClick(type) {
    setClickedIconeType(type);

    if (iconRef) {
      iconRef.current.click();
    }
  }

  useEffect(() => {
    if (!saving) {
      setOpenBrandingModal(false);
    }
  }, [saving]);

  if (!leadMagnet) {
    return null;
  }

  const customMax = (value, limit = 1000) => {
    return (convertToPlainText(value)?.length || 0) <= limit;
  };

  return (
    <Formik
      innerRef={contributionFormRef}
      enableReinitialize
      initialValues={{
        customTitleForMeetYourCoach: leadMagnet?.customTitleForMeetYourCoach || 'Coach',
        // contributionTagline: leadMagnet?.contributionTagline || '',
        whoIAmLabel: leadMagnet?.whoIAmLabel || 'Who this is for',
        whoIAmIcon: leadMagnet?.whoIAmIcon || mdiAccountOutline,
        viewWhoIAm: leadMagnet?.viewWhoIAm,
        whoIAm: leadMagnet?.whoIAm || '',
        whatWeDoLabel: leadMagnet?.whatWeDoLabel || 'What to expect',
        whatWeDoIcon: leadMagnet?.whatWeDoIcon || mdiLightbulb,
        viewWhatWeDo: leadMagnet?.viewWhatWeDo,
        whatWeDo: leadMagnet?.whatWeDo || '',
        purposeLabel: leadMagnet?.purposeLabel || 'Why this was created',
        purposeIcon: leadMagnet?.purposeIcon || mdiHeart,
        viewPurpose: leadMagnet?.viewPurpose,
        purpose: leadMagnet?.purpose || '',
        preparationLabel: leadMagnet?.preparationLabel || 'Additional details',
        preparationIcon: leadMagnet?.preparationIcon || mdiTabletIpad,
        viewPreparation: leadMagnet?.viewPreparation,
        preparation: leadMagnet?.preparation || '',
        languageCodes: leadMagnet?.languageCodes || ['En'],
        minAge: leadMagnet?.minAge?.toString() || '18+',
        gender: leadMagnet?.gender || 'NoRequirement',
        inviteClientType: leadMagnet?.inviteClientType || 'Both',
        isSpeakingLanguageRequired: isNil(leadMagnet?.isSpeakingLanguageRequired)
          ? false
          : leadMagnet?.isSpeakingLanguageRequired,
        invitationOnly: isNil(leadMagnet?.invitationOnly) ? true : leadMagnet?.invitationOnly,

        areClientPublicPostsAllowed: isNil(leadMagnet?.areClientPublicPostsAllowed)
          ? true
          : leadMagnet?.areClientPublicPostsAllowed,
        instagramUrl: leadMagnet?.instagramUrl || '',
        linkedInUrl: leadMagnet?.linkedInUrl || '',
        youtubeUrl: leadMagnet?.youtubeUrl || '',
        facebookUrl: leadMagnet?.facebookUrl || '',
        websiteUrl: leadMagnet?.websiteUrl || '',
        tiktokUrl: leadMagnet?.tiktokUrl || '',
        isCustomBrandingColorsActive: leadMagnet.isCustomBrandingColorsActive || false,
        isLegacyColorsActive: leadMagnet.isLegacyColorsActive || false,
        isCommunityFeatureHiddden:
          leadMagnet.isCommunityFeatureHiddden || leadMagnet.type === ContributionType.contributionOneToOne
            ? true
            : false,
        PrimaryColorCode:
          // contribution?.isLegacyColorsActive === false && contribution?.isCustomBrandingColorsActive === true
          //   ? contribution?.coachSelectedBrandingColors?.PrimaryColorCode
          //   : contribution?.isLegacyColorsActive === true && contribution?.isCustomBrandingColorsActive === false
          //   ?
          leadMagnet.brandingColors?.PrimaryColorCode,
        // : contribution?.isLegacyColorsActive === true && contribution?.isCustomBrandingColorsActive === true
        // ? contribution.coachSelectedBrandingColors?.PrimaryColorCode
        // : CONTRIBUTION_COLORS.PrimaryColorCode,
        AccentColorCode:
          // contribution?.isLegacyColorsActive === false && contribution?.isCustomBrandingColorsActive == true
          //   ? contribution?.coachSelectedBrandingColors?.AccentColorCode
          //   : contribution?.isLegacyColorsActive === true && contribution?.isCustomBrandingColorsActive == false
          //   ?
          leadMagnet.brandingColors?.AccentColorCode,
        // : contribution?.isLegacyColorsActive === true && contribution?.isCustomBrandingColorsActive === true
        // ? contribution.coachSelectedBrandingColors?.AccentColorCode
        // : CONTRIBUTION_COLORS.AccentColorCode,

        TextColorCode: leadMagnet.brandingColors?.TextColorCode || 'Auto',
        TertiaryColorCode:
          // contribution?.isLegacyColorsActive === false && contribution?.isCustomBrandingColorsActive == true
          //   ? contribution?.coachSelectedBrandingColors?.TertiaryColorCode
          //   : contribution?.isLegacyColorsActive === true && contribution?.isCustomBrandingColorsActive === false
          //   ?
          leadMagnet.brandingColors?.TertiaryColorCode,
        // : contribution?.isLegacyColorsActive === true && contribution?.isCustomBrandingColorsActive === true
        // ? contribution.coachSelectedBrandingColors?.TertiaryColorCode
        // : CONTRIBUTION_COLORS.TertiaryColorCode,
        customLogo: leadMagnet?.customLogo || null,
        isDarkModeEnabled: leadMagnet?.isDarkModeEnabled || false,
      }}
      validationSchema={Yup.object().shape({
        customTitleForMeetYourCoach: Yup.string()
          .max(20, 'Title can be a max of 20 characters')
          .required('This is a required field'),
        // contributionTagline: Yup.string().max(200, 'Title can be a max of 200 characters').optional(),
        viewWhoIAm: Yup.boolean().optional(),
        whoIAm: Yup.string().when('viewWhoIAm', {
          is: false,
          then: Yup.string()
            .strict(true)
            .min(0)
            .test('CustomMax', 'Cannot exceed 1000 characters', customMax)
            .required('This is a required field'),
          otherwise: Yup.string().strict(true).min(0).test('CustomMax', 'Cannot exceed 1000 characters', customMax),
        }),

        whoIAmLabel: Yup.string()
          .strict(true)
          .min(2, 'Must be at least 2 characters')
          .max(50, 'Cannot exceed 50 characters')
          .required('This is a required field'),

        viewWhatWeDo: Yup.boolean().optional(),
        whatWeDo: Yup.string().when('viewWhatWeDo', {
          is: false,
          then: Yup.string()
            .strict(true)
            .min(0)
            .test('CustomMax', 'Cannot exceed 1000 characters', customMax)
            .required('This is a required field'),
          otherwise: Yup.string().strict(true).min(0).test('CustomMax', 'Cannot exceed 1000 characters', customMax),
        }),

        whatWeDoLabel: Yup.string()
          .strict(true)
          .min(2, 'Must be at least 2 characters')
          .max(50, 'Cannot exceed 50 characters')
          .required('This is a required field'),

        viewPurpose: Yup.boolean().optional(),
        purpose: Yup.string().when('viewPurpose', {
          is: false,
          then: Yup.string()
            .strict(true)
            .min(0)
            .test('CustomMax', 'Cannot exceed 1000 characters', customMax)
            .required('This is a required field'),
          otherwise: Yup.string().strict(true).min(0).test('CustomMax', 'Cannot exceed 1000 characters', customMax),
        }),
        purposeLabel: Yup.string()
          .strict(true)
          .min(2, 'Must be at least 2 characters')
          .max(50, 'Cannot exceed 50 characters')
          .required('This is a required field'),

        viewPreparation: Yup.boolean().optional(),
        preparation: Yup.string().when('viewPreparation', {
          is: false,
          then: Yup.string()
            .strict(true)
            .min(0)
            .test('CustomMax', 'Cannot exceed 1000 characters', customMax)
            .required('This is a required field'),
          otherwise: Yup.string()
            .strict(true)
            .min(0)
            .test('CustomMax', 'Cannot exceed 1000 characters', customMax)
            .nullable(),
        }),
        preparationLabel: Yup.string()
          .strict(true)
          .min(2, 'Must be at least 2 characters')
          .max(50, 'Cannot exceed 500 characters')
          .required('This is a required field'),
        languageCodes: Yup.string().required('This is a required field'),
        minAge: Yup.string().required('This is a required field'),
        gender: Yup.mixed().oneOf(['NoRequirement', 'Male', 'Female', 'NonBinary']),
        inviteClientType: Yup.mixed().required().oneOf(['Free', 'Paid', 'Both']),
        instagramUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('instagram.com', 'This link is not related to Instagram'),
        linkedInUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('linkedin.com', 'This link is not related to LinkedIn'),
        youtubeUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('youtube.com|youtu.be', 'This link is not related to Youtube'),
        facebookUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('facebook.com', 'This link is not related to Facebook'),
        tiktokUrl: Yup.string()
          .max(100, 'Cannot exceed 100 characters')
          .matches('tiktok.com', 'This link is not related to TikTok'),
        websiteUrl: Yup.string().max(100, 'Cannot exceed 100 characters'),
        PrimaryColorCode: Yup.string().min(7).max(7).required(),
        AccentColorCode: Yup.string().min(7).max(7).required(),
        TertiaryColorCode: Yup.string().min(7).max(7).required(),
        // TextColorCode: Yup.string().min(7).max(7).required(),
      })}
      onSubmit={saveToStorageHandler}
    >
      {formProps => {
        const saveData = (event, to, withValidation = false) => {
          if (isEditing || withValidation) {
            formProps.handleSubmit(event);
          } else {
            saveToStorageHandler(formProps.values, {}, to);
          }
        };
        if (isEmpty(formProps.errors)) {
          dispatch(contributionActions.setErrorPopUp(false));
        }
        const setToInitial = () => {
          if (formProps.errors?.PrimaryColorCode) {
            formProps.setFieldValue('PrimaryColorCode', '#000');
          }
          if (formProps.errors?.AccentColorCode) {
            formProps.setFieldValue('AccentColorCode', '#000');
          }
          if (formProps.errors?.TertiaryColorCode) {
            formProps.setFieldValue('TertiaryColorCode', '#000');
          }
          if (formProps.errors?.TextColorCode) {
            formProps.setFieldValue('TextColorCode', '#000');
          }
        };

        return (
          <>
            <MainContainer
              sidebarProps={{
                saveHandler: (event, { to }) => {
                  setRedirectTo(to);
                  saveData(event, to);
                },
                errors: formProps?.errors,
              }}
            >
              <Joyride
                // tooltipComponent={Tooltip}
                callback={handleJoyrideCallback}
                continuous={true}
                getHelpers={getHelpers}
                run={run}
                stepIndex={stepIndex}
                showProgress={false}
                showSkipButton={false}
                steps={steps}
                styles={{
                  options: {
                    primaryColor: '#CDBA8F',
                    zIndex: 10000000,
                  },
                }}
              />
              <StyledMainSection mobileView={mobileView}>
                {errorOccured && (
                  <div
                    style={{
                      width: '45%',
                      backgroundColor: 'white',
                      position: 'absolute',
                      marginInline: '9%',
                      border: '1px solid #DBE7F2',
                      borderRadius: '4px',
                      zIndex: 1,
                    }}
                  >
                    <div style={{ padding: '15px 10px 10px 15px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontWeight: '700',
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#1E1E1E',
                              marginInline: '10px',
                            }}
                          >
                            {formProps.errors.whoIAm === 'This is a required field' ||
                            formProps.errors.preparation === 'This is a required field' ||
                            formProps.errors.purpose === 'This is a required field' ||
                            formProps.errors.whatWeDo === 'This is a required field'
                              ? 'Empty Fields'
                              : 'Error'}
                          </div>
                        </div>
                        <StyledCloseIcon
                          onClick={() => {
                            dispatch(contributionActions.setErrorPopUp(false));
                          }}
                          fontSize="10px"
                          style={{ color: 'black' }}
                        />
                      </div>
                      <div
                        style={{
                          fontFamily: 'Avenir',
                          fontWeight: '400',
                          fontSize: '16px',
                          lineHeight: '22px',
                          color: '#1E1E1E',
                          marginInline: '35px',
                        }}
                      >
                        {formProps.errors.whoIAm === 'This is a required field' ||
                        formProps.errors.preparation === 'This is a required field' ||
                        formProps.errors.purpose === 'This is a required field' ||
                        formProps.errors.whatWeDo === 'This is a required field'
                          ? 'Please fill out all fields in order to continue'
                          : 'Limit Exceeded'}
                      </div>
                      {/* <div style={{
                    fontFamily: 'Avenir',
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#1E1E1E',
                    marginTop: '10px',
                    marginInline: "35px"
                  }}>Please fill out missing field in order to continue</div> */}
                    </div>
                  </div>
                )}
                <Card id={'contributionDetails'} mobileView={mobileView}>
                  <CardHeader className={classes.header} mobileView={mobileView}>
                    <PageTitleSecond className={classes.detailsHeader} mobileView={mobileView}>
                      2. Lead Magnet Details
                    </PageTitleSecond>
                    {/* {!isEditing && (
                      <ExampleLink href={ExamplePDF} mobileView={mobileView} target="_blank">
                        PDF Template
                      </ExampleLink>
                    )} */}
                  </CardHeader>
                  <CardBody mobileView={mobileView}>
                    <Form id="create-lead-magnet-form" onKeyDown={handleFormKeyDown}>
                      <Grid container spacing={4}>
                        <Grid
                          style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline' }}
                          item
                          md={6}
                          xs={12}
                        >
                          <p style={{ marginRight: '10px', minWidth: '80px' }}>Meet Your</p>
                          <Input
                            type="text"
                            name="customTitleForMeetYourCoach"
                            value={formProps.values.customTitleForMeetYourCoach}
                          />
                          <StyledTooltipWrap>
                            <StyledTooltip
                              title="Your clients will see a section called “Meet Your Coach” on the landing page associated with this service. You can change this title to be anything you’d like! Examples; healer, teacher, mentor, consultant etc."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon htmlColor={colors.lightBrown} />
                            </StyledTooltip>
                          </StyledTooltipWrap>
                        </Grid>
                        {/* {contribution?.type != ContributionType.contributionOneToOne && ( */}
                        {/* <Grid
                          style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline' }}
                          item
                          md={6}
                          xs={12}
                        >
                          <p style={{ marginRight: '10px', width: '200px' }}>Tagline (optional)</p>
                          <Input
                            id="Tagline-input"
                            // label={`Taglin(optional)`}
                            name="contributionTagline"
                            value={formProps.values.contributionTagline}
                            counter={200}
                            fullWidth
                          />
                        </Grid> */}
                        {/* )} */}
                      </Grid>

                      <DetailsGrid container justify="center" spacing={4}>
                        <TextAreaWrapper
                          container
                          item
                          isCollapsed={purposeCollapsed}
                          md={purposeCollapsed ? TEXT_AREA_SIZE.SMALL : TEXT_AREA_SIZE.HUGE}
                          xs={12}
                        >
                          <TextArea
                            fprops={formProps}
                            image={formProps.values.purposeIcon}
                            label={formProps.values.purposeLabel}
                            isLabelEditable
                            isTextAreaModeEditable
                            initialMode={formProps.values.viewPurpose ? false : true}
                            changeMode={() => formProps.setFieldValue('viewPurpose', !formProps.values.viewPurpose)}
                            labelName="purposeLabel"
                            placeholder="Add Landing Page Copy here"
                            name="purpose"
                            rows="5"
                            fullWidth
                            counter={1000}
                            helperTextPosition="right"
                            onClickResize={handleResizePurpose}
                            // handleUploadIconFile={e => {
                            //   handleUploadIconFile(e, iconType.purpose, formProps.setFieldValue);
                            // }}
                            handleIconClick={() => {
                              handleIconClick(iconType.purpose);
                            }}
                            isCollapsed={purposeCollapsed}
                            isckEditorForEditContribution
                            id="lead-magnet-details-purpose-textarea"
                          />
                        </TextAreaWrapper>
                        <TextAreaWrapper
                          container
                          item
                          isCollapsed={whoIAmCollapsed}
                          md={whoIAmCollapsed ? TEXT_AREA_SIZE.SMALL : TEXT_AREA_SIZE.HUGE}
                          xs={12}
                        >
                          <TextArea
                            fprops={formProps}
                            image={formProps.values.whoIAmIcon}
                            label={formProps.values.whoIAmLabel}
                            isLabelEditable
                            isTextAreaModeEditable
                            initialMode={formProps.values.viewWhoIAm ? false : true}
                            changeMode={() => formProps.setFieldValue('viewWhoIAm', !formProps.values.viewWhoIAm)}
                            labelName="whoIAmLabel"
                            placeholder="Add Landing Page Copy here"
                            name="whoIAm"
                            rows="5"
                            fullWidth
                            counter={1000}
                            helperTextPosition="right"
                            onClickResize={handleResizeWhoIAm}
                            handleIconClick={() => {
                              handleIconClick(iconType.whoIAm);
                            }}
                            isCollapsed={whoIAmCollapsed}
                            isckEditorForEditContribution
                            id="lead-magnet-details-bio-textarea"
                          />
                        </TextAreaWrapper>
                        <TextAreaWrapper
                          container
                          item
                          isCollapsed={whatWeDoCollapsed}
                          md={whatWeDoCollapsed ? TEXT_AREA_SIZE.SMALL : TEXT_AREA_SIZE.HUGE}
                          xs={12}
                        >
                          <TextArea
                            fprops={formProps}
                            image={formProps.values.whatWeDoIcon}
                            label={formProps.values.whatWeDoLabel}
                            isLabelEditable
                            isTextAreaModeEditable
                            initialMode={formProps.values.viewWhatWeDo ? false : true}
                            changeMode={() => formProps.setFieldValue('viewWhatWeDo', !formProps.values.viewWhatWeDo)}
                            labelName="whatWeDoLabel"
                            placeholder="Add Landing Page Copy here"
                            name="whatWeDo"
                            rows="5"
                            fullWidth
                            counter={1000}
                            helperTextPosition="right"
                            onClickResize={handleResizeWhatWeDo}
                            handleIconClick={() => {
                              handleIconClick(iconType.whatWeDo);
                            }}
                            isCollapsed={whatWeDoCollapsed}
                            isckEditorForEditContribution
                            id="lead-magnet-details-expect-textarea"
                          />
                        </TextAreaWrapper>
                        <TextAreaWrapper
                          container
                          item
                          isCollapsed={preparationCollapsed}
                          md={preparationCollapsed ? TEXT_AREA_SIZE.SMALL : TEXT_AREA_SIZE.HUGE}
                          xs={12}
                        >
                          <TextArea
                            fprops={formProps}
                            image={formProps.values.preparationIcon}
                            label={formProps.values.preparationLabel}
                            isLabelEditable
                            isTextAreaModeEditable
                            initialMode={formProps.values.viewPreparation ? false : true}
                            changeMode={() =>
                              formProps.setFieldValue('viewPreparation', !formProps.values.viewPreparation)
                            }
                            labelName="preparationLabel"
                            placeholder="Add Landing Page Copy here"
                            name="preparation"
                            rows="5"
                            fullWidth
                            counter={1000}
                            helperTextPosition="right"
                            onClickResize={handlePreparationResize}
                            handleIconClick={() => {
                              handleIconClick(iconType.preparation);
                            }}
                            isCollapsed={preparationCollapsed}
                            isckEditorForEditContribution
                            id="lead-magnet-details-prepare-textarea"
                          />
                        </TextAreaWrapper>
                      </DetailsGrid>

                      {/* <Grid container spacing={4}></Grid> */}

                      <SocialGrid container spacing={4}>
                        <Grid item md={3} sm={6} xs={12}>
                          <Input label="Website (optional)" type="text" name="websiteUrl" fullWidth />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Input label="Instagram (optional)" type="text" name="instagramUrl" fullWidth />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Input label="LinkedIn (optional)" type="text" name="linkedInUrl" fullWidth />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Input label="Youtube (optional)" type="text" name="youtubeUrl" fullWidth />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Input label="TikTok (optional)" type="text" name="tiktokUrl" fullWidth />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                          <Input label="Facebook (optional)" type="text" name="facebookUrl" fullWidth />
                        </Grid>
                      </SocialGrid>
                      {/* <Grid container>
                      <Grid item md={3} sm={6} xs={12} style={{ marginTop: '22px', marginBottom: '22px' }}>
                        <Input label="TikTok (optional)" type="text" name="tiktokUrl" fullWidth />
                      </Grid>
                    </Grid> */}
                      <Grid container spacing={4}>
                        {/* <Grid container item md={3} sm={6} xs={12}>
                        <StyledTooltipWrap>
                          <FormControlLabel
                            checked={formProps.values.invitationOnly}
                            onChange={formProps.handleChange('invitationOnly')}
                            control={<Checkbox color="primary" />}
                            label="Invitation Only"
                            name="invitationOnly"
                            disabled={true}
                          />
                          <StyledTooltip
                            title="Currently Cohere allows coaches and service providers to invite clients to the platform and their services. In the future, we will have an option to open up your services to the public so that Cohere can connect you with more clients."
                            arrow
                            enterTouchDelay={TOOLTIP.ENTER_DELAY}
                            leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                          >
                            <InfoIcon htmlColor={colors.lightBrown} />
                          </StyledTooltip>
                        </StyledTooltipWrap>
                      </Grid> */}

                        <Grid container item md={4} sm={6} xs={12}>
                          <StyledTooltipWrap>
                            <FormControlLabel
                              className={classes.customBranding}
                              checked={formProps.values.isCustomBrandingColorsActive}
                              onChange={formProps.handleChange('isCustomBrandingColorsActive')}
                              control={<Checkbox color="primary" />}
                              // label="Use Custom Branding Colors"
                              name="isCustomBrandingColorsActive"
                            />
                            <Link
                              // style={formProps.values.isCustomBrandingColorsActive ? {} : { pointerEvents: 'none' }}
                              onClick={() => {
                                setOpenBrandingModal(true);
                              }}
                            >
                              Use Custom Branding Colors
                            </Link>
                          </StyledTooltipWrap>
                        </Grid>
                      </Grid>
                      {formProps.errors && formProps.errors.PrimaryColorCode && (
                        <p style={{ color: 'red' }}>{formProps.errors.PrimaryColorCode}</p>
                      )}
                      {formProps.errors && formProps.errors.AccentColorCode && (
                        <p style={{ color: 'red' }}>{formProps.errors.AccentColorCode}</p>
                      )}
                      {formProps.errors && formProps.errors.TertiaryColorCode && (
                        <p style={{ color: 'red' }}>{formProps.errors.TertiaryColorCode}</p>
                      )}
                      {formProps.errors && formProps.errors.TextColorCode && (
                        <p style={{ color: 'red' }}>{formProps.errors.TextColorCode}</p>
                      )}
                    </Form>
                  </CardBody>
                </Card>

                <Buttons
                  backButtonTitle="Back"
                  backClickHandler={event => {
                    const to = query.id ? `/edit-lead-magnet/${query.id}/basic` : '/create-lead-magnet/basic';
                    // const to = query.id ? `/edit-lead-magnet/${query.id}/details` : '/create-lead-magnet/details';
                    setRedirectTo(to);
                    saveData(event, to);
                  }}
                  onNextClickHandler={event => {
                    if (isEditing) {
                      if (isEmpty(formProps.errors) === false) {
                        dispatch(contributionActions.setErrorPopUp(true));
                      } else {
                        const to = query.id ? `/edit-lead-magnet/${query.id}/content` : '/create-lead-magnet/content';
                        // const to = query.id ? `/edit-lead-magnet/${query.id}/review` : '/create-lead-magnet/review';
                        setRedirectTo(to);
                        saveData(event, to, true);
                      }
                    } else {
                      const to = query.id ? `/edit-lead-magnet/${query.id}/content` : '/create-lead-magnet/content';
                      // const to = query.id ? `/edit-lead-magnet/${query.id}/review` : '/create-lead-magnet/review';
                      setRedirectTo(to);
                      saveData(event, to, true);
                    }
                  }}
                  nextButtonTitle="Save and Next"
                  formId="create-lead-magnet-form"
                />
              </StyledMainSection>
            </MainContainer>
            <input type="file" accept="image/*" onChange={onUploadFile} hidden="hidden" ref={iconRef} />
            <ImageCropper
              title={`Crop Your Icon`}
              submitTitle="Set Icon"
              imageToCrop={imageToCrop}
              isOpen={isOpen}
              onClose={() => {
                setIsOpen(false);
                setClickedIconeType(null);
              }}
              setFieldValue={formProps.setFieldValue}
              field={`${clickedIconeType}Icon`}
              shape="square"
              brandingColor={false}
            />
            <Modal
              title="Custom Branding Colors"
              isOpen={openBrandingModal}
              hiddenCancel
              loading={saving}
              brandingColor={false}
              isCreatingContribution={true}
              onCancel={() => {
                setOpenBrandingModal(false);
              }}
              onSubmit={() => {
                const to = query.id ? `/edit-lead-magnet/${query.id}/details` : '/create-lead-magnet/details';
                if (
                  !formProps.errors?.PrimaryColorCode &&
                  !formProps.errors?.AccentColorCode &&
                  !formProps.errors?.TertiaryColorCode &&
                  !formProps.errors?.TextColorCode
                ) {
                  const userData = {
                    ...user,

                    coachSelectedBrandingColors: {
                      PrimaryColorCode: formProps.values.PrimaryColorCode,
                      AccentColorCode: formProps.values.AccentColorCode,
                      TertiaryColorCode: formProps.values.TertiaryColorCode,
                      TextColorCode: formProps.values.TextColorCode,
                    },
                    customLogo: formProps.values.customLogo,
                    isDarkModeEnabled: formProps.values.isDarkModeEnabled,
                  };
                  // saveToStorageHandler(formProps.values, {}, to);
                  // saveData(formProps, to, true);
                  setOpenBrandingModalPopUp(true);
                  // editProfile(user?.id, userData);
                  // setOpenBrandingModal(false);
                }
              }}
              submitTitle="Save"
            >
              <Grid container item style={{ marginBottom: '50px', width: '500px' }}>
                <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7', height: '90px' }}>
                  <div className="heading">
                    <p>Brand Elements</p>
                  </div>
                  <div>
                    <p>Cohere allows you to adjust your custom branding colors</p>
                  </div>
                </Grid>
                <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7' }}>
                  <StyledIconSection>
                    <StyledRow>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Logo</StyledHeading>
                        <StyledTooltipModal
                          title="This is the logo that will appear on your branded landing pages on Cohere."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>
                      <Container onClick={onClickHandler}>
                        {formProps.values.customLogo ? (
                          <IconContainer>
                            <Image src={formProps.values.customLogo} />
                          </IconContainer>
                        ) : (
                          <EmptyIconContainer>+</EmptyIconContainer>
                        )}
                      </Container>
                    </StyledRow>
                  </StyledIconSection>
                  <InputHidden
                    ref={IconUpload}
                    onChange={e => {
                      handleIconChange(e, formProps.setFieldValue);
                      formProps.setFieldValue('isCustomBrandingColorsActive', true);
                    }}
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </Grid>
                <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7' }}>
                  <StyledIconSection>
                    <StyledRow>
                      <p style={{ margin: 'auto 0' }}>Choose Style Mode</p>
                      <ToggleButtonGroup
                        className="toggle-button"
                        color="primary"
                        value={formProps?.values?.isDarkModeEnabled ? 'dark' : 'light'}
                        exclusive
                        onChange={() => {
                          formProps.setFieldValue('isDarkModeEnabled', !formProps.values.isDarkModeEnabled);
                          formProps.setFieldValue('isCustomBrandingColorsActive', true);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="light">Light</ToggleButton>
                        <ToggleButton value="dark">Dark</ToggleButton>
                      </ToggleButtonGroup>
                    </StyledRow>
                  </StyledIconSection>
                </Grid>
                <Grid item xs={12} style={{ borderBottom: '1px solid #E7E7E7', marginTop: '20px' }}>
                  <StyledColorSection>
                    <StyledColorSelector>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Primary color</StyledHeading>
                        <StyledTooltipModal
                          title="This color will be associated with call-to-action (CTA) buttons on your branded pages"
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>

                      <StyledColorPicker>
                        <ButtonExample
                          colorCode={formProps.values.PrimaryColorCode}
                          onchangevalue={code => {
                            formProps.setFieldValue('PrimaryColorCode', code);
                            // formProps.setFieldValue('isLegacyColorsActive', true);
                            formProps.setFieldValue('isCustomBrandingColorsActive', true);
                            saveColor({
                              colorName: 'PrimaryColorCode',
                              colorCode: code,
                              // formValues: values,
                            });
                          }}
                          isLeadMagnet
                          {...{ mobileView }}
                        />
                        {/* <StyledColor colorCode={values.PrimaryColorCode} onClick={funTest} /> */}
                        <StyledColorCodeWrapper>
                          <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                          <div style={{ width: '85%' }}>
                            <TextField
                              name="PrimaryColorCode"
                              value={formProps?.values?.PrimaryColorCode?.substring(1)}
                              type="text"
                              onChange={e => {
                                const colorCode = '#' + e.target.value;
                                formProps.setFieldValue('PrimaryColorCode', colorCode);
                                // formProps.setFieldValue('isLegacyColorsActive', true);
                                formProps.setFieldValue('isCustomBrandingColorsActive', true);
                                if (colorCode.length == 7) {
                                  saveColor({
                                    colorName: 'PrimaryColorCode',
                                    colorCode: colorCode,
                                    // formValues: values,
                                  });
                                }
                              }}
                              onBlur={formProps.handleBlur}
                              fullWidth
                            />
                          </div>
                        </StyledColorCodeWrapper>
                      </StyledColorPicker>
                    </StyledColorSelector>
                    <StyledColorSelector>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Accent color</StyledHeading>
                        <StyledTooltipModal
                          title="The color will be associated with various tabs, labels, and misc. components on your branded pages."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>
                      <StyledColorPicker>
                        <ButtonExample
                          colorCode={formProps.values.AccentColorCode}
                          onchangevalue={code => {
                            formProps.setFieldValue('AccentColorCode', code);
                            // formProps.setFieldValue('isLegacyColorsActive', true);
                            formProps.setFieldValue('isCustomBrandingColorsActive', true);
                            saveColor({
                              colorName: 'AccentColorCode',
                              colorCode: code,
                              // formValues: values,
                            });
                          }}
                          isLeadMagnet
                          {...{ mobileView }}
                        />
                        <StyledColorCodeWrapper>
                          <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                          <div style={{ width: '85%' }}>
                            <TextField
                              name="AccentColorCode"
                              value={formProps?.values?.AccentColorCode?.substring(1)}
                              type="text"
                              onChange={e => {
                                // formProps.setFieldValue('isLegacyColorsActive', true);
                                formProps.setFieldValue('isCustomBrandingColorsActive', true);
                                const colorCode = '#' + e.target.value;
                                formProps.setFieldValue('AccentColorCode', colorCode);

                                if (colorCode.length == 7) {
                                  saveColor({
                                    colorName: 'AccentColorCode',
                                    colorCode: colorCode,
                                    // formValues: values,
                                  });
                                }
                              }}
                              onBlur={formProps.handleBlur}
                              fullWidth
                            />
                          </div>
                        </StyledColorCodeWrapper>
                      </StyledColorPicker>
                    </StyledColorSelector>
                    <StyledColorSelector>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Tertiary color</StyledHeading>
                        <StyledTooltipModal
                          title="This color is only used if you have more than two categories on your Lead Magnet."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>
                      <StyledColorPicker>
                        <ButtonExample
                          colorCode={formProps.values.TertiaryColorCode}
                          onchangevalue={code => {
                            // formProps.setFieldValue('isLegacyColorsActive', true);
                            formProps.setFieldValue('TertiaryColorCode', code);
                            formProps.setFieldValue('isCustomBrandingColorsActive', true);
                            saveColor({
                              colorName: 'TertiaryColorCode',
                              colorCode: code,
                              // formValues: values,
                            });
                          }}
                          isLeadMagnet
                          {...{ mobileView }}
                        />
                        {/* <StyledColor colorCode={values.TertiaryColorCode} /> */}
                        <StyledColorCodeWrapper>
                          <div style={{ width: '15%', color: '#AFAFAF' }}>#</div>
                          <div style={{ width: '85%' }}>
                            <TextField
                              value={formProps?.values?.TertiaryColorCode?.substring(1)}
                              name="TertiaryColorCode"
                              type="text"
                              onChange={e => {
                                // formProps.setFieldValue('isLegacyColorsActive', true);
                                const colorCode = '#' + e.target.value;
                                formProps.setFieldValue('TertiaryColorCode', colorCode);
                                formProps.setFieldValue('isCustomBrandingColorsActive', true);

                                if (colorCode.length == 7) {
                                  saveColor({
                                    colorName: 'TertiaryColorCode',
                                    colorCode: colorCode,
                                    // formValues: values,
                                  });
                                }
                              }}
                              onBlur={formProps.handleBlur}
                              fullWidth
                            />
                          </div>
                        </StyledColorCodeWrapper>
                      </StyledColorPicker>
                    </StyledColorSelector>
                    <StyledColorSelector>
                      <StyledTooltipWrapModal>
                        <StyledHeading>Text color</StyledHeading>
                        <StyledTooltipModal
                          title="This color is used show text color on your Lead Magnet."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor="#47627B" />
                        </StyledTooltipModal>
                      </StyledTooltipWrapModal>
                      <Select
                        className={classes.colorSelect}
                        label=""
                        name="TextColorCode"
                        fullWidth
                        items={[
                          { title: 'Black', value: '#000000' },
                          { title: 'White', value: '#FFFFFF' },
                          { title: 'Auto', value: 'Auto' },
                        ]}
                        value={formProps?.values?.TextColorCode}
                        onChange={e => {
                          formProps.setFieldValue('TextColorCode', e.target.value);
                          // formProps.setFieldValue('isLegacyColorsActive', true);
                          formProps.setFieldValue('isCustomBrandingColorsActive', true);
                        }}
                      />
                    </StyledColorSelector>
                  </StyledColorSection>
                </Grid>
              </Grid>
              {formProps.errors && formProps.errors.PrimaryColorCode && (
                <p style={{ color: 'red' }}>{formProps.errors.PrimaryColorCode}</p>
              )}
              {formProps.errors && formProps.errors.AccentColorCode && (
                <p style={{ color: 'red' }}>{formProps.errors.AccentColorCode}</p>
              )}
              {formProps.errors && formProps.errors.TertiaryColorCode && (
                <p style={{ color: 'red' }}>{formProps.errors.TertiaryColorCode}</p>
              )}
              {formProps.errors && formProps.errors.TextColorCode && (
                <p style={{ color: 'red' }}>{formProps.errors.TextColorCode}</p>
              )}
            </Modal>
            <Modal
              title="Custom Branding Colors Set As Default"
              isOpen={openBrandingModalPopUp}
              brandingColor={false}
              isCreatingContribution={true}
              // hiddenCancel
              loading={saving}
              onCancel={() => {
                setOpenBrandingModalPopUp(false);
                setOpenBrandingModal(false);
                formProps.setFieldValue('isLegacyColorsActive', true);
              }}
              onSubmit={() => {
                if (
                  !formProps.errors?.PrimaryColorCode &&
                  !formProps.errors?.AccentColorCode &&
                  !formProps.errors?.TertiaryColorCode &&
                  !formProps.errors?.TextColorCode
                ) {
                  const userData = {
                    id: user.id,
                    brandingColors: {
                      PrimaryColorCode: formProps.values.PrimaryColorCode,
                      AccentColorCode: formProps.values.AccentColorCode,
                      TertiaryColorCode: formProps.values.TertiaryColorCode,
                      TextColorCode: formProps.values.TextColorCode,
                    },
                    customLogo: formProps.values.customLogo,
                    isDarkModeEnabled: formProps.values.isDarkModeEnabled,
                  };
                  // saveData(formProps, to, true);
                  editUserColors(userData).then(res => {
                    dispatch(userActions.getUserSuccess(res));
                  });

                  // editProfile(user?.id, userData);
                  setOpenBrandingModal(false);
                  setOpenBrandingModalPopUp(false);
                  formProps.setFieldValue('isLegacyColorsActive', true);
                }
              }}
              cancelTitle="Just For This Lead Magnet"
              submitTitle="Save To Profile And For This Lead Magnet"
            >
              <Grid container item style={{ marginBottom: '50px', width: '500px' }}>
                Do you also want to make these colors your default profile colors?
              </Grid>
            </Modal>
            <Modal
              title=""
              isOpen={showModal}
              onCloseText="OK"
              hiddenCancel
              onSubmit={togglePopup}
              onCancel={togglePopup}
              disableFooter
            >
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
                <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
                <div
                  style={{
                    color: '#A58B62',
                    fontSize: '16px',
                    marginTop: '13px',
                    fontWeight: 450,
                    fontFamily: 'Avenir',
                  }}
                >
                  You are fully set up & ready to transform lives!
                </div>
                <Button
                  autoWidth
                  backgroundColor={'#C9B382'}
                  style={{
                    marginTop: '40px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    marginBottom: '10px',
                  }}
                  onClick={togglePopup}
                >
                  Let’s do this!
                </Button>
              </div>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
}

DetailsForm.propTypes = {
  leadMagnet: PropTypes.shape(),
  user: PropTypes.shape({
    bio: PropTypes.string,
  }).isRequired,
  saveLeadMagnet: PropTypes.func.isRequired,
  saveLeadMagnetToLS: PropTypes.func.isRequired,
};

DetailsForm.defaultProps = {
  leadMagnet: null,
};

const mapStateToProps = ({ account, leadMagnets }) => ({
  leadMagnet: leadMagnets.activeLeadMagnet,
  user: account?.user,
  saving: account?.isLoading,
});

const actions = {
  saveLeadMagnet: leadMagnetActions.saveLeadMagnet,
  saveLeadMagnetToLS: leadMagnetActions.saveDraftLeadMagnet,
};

export default connect(mapStateToProps, actions)(DetailsForm);
