import React, { useState, useEffect } from 'react';
import classes from '../../QuizPage.module.scss';
import styled from 'styled-components';
import CheckIcon from '../../Assests/Icons/checkIcon.png';
import Button from 'components/FormUI/Button';
import CorrectIcon from '../../Assests/Icons/CorrectIcon';
import IncorrectIcon from '../../Assests/Icons/IncorrectIcon';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { checkboxClasses } from '@mui/material';
import useRouter from 'hooks/useRouter';
import { convertToPlainText } from 'utils/utils';
import { useSelector } from 'react-redux';
import useQuiz from 'hooks/useQuiz';
import { CONTRIBUTION_COLORS } from '../../../../../../../src/constants';

const CustomCheckBox = styled(Checkbox)`
  &.MuiCheckbox-root {
    &.Mui-checked {
      color: ${({ color }) => color};
    }
  }
`;

export default function MultipleChoice({
  checked,
  data,
  setChecked,
  responseSubmitted,
  isMultipleAllowed,
  setErrorMessage,
  isForm,
}) {
  const { activeQuiz } = useQuiz();
  const { questions, isBrandingColorEnabled, brandingColors } = activeQuiz;
  const { PrimaryColorCode, AccentColorCode, TextColorCode } = brandingColors ?? CONTRIBUTION_COLORS;

  console.log('isBrandingColorEnabled--->PrimaryColorCode', isBrandingColorEnabled, PrimaryColorCode);

  function modifyArrayBasedOnPresence(arr, str) {
    const index = arr.indexOf(str);
    if (index === -1) {
      arr.push(str);
    } else {
      arr.splice(index, 1);
    }
    return arr;
  }
  console.log('question----->11', checked, isMultipleAllowed);
  return (
    <div className={classes.option_block}>
      <div className={classes.option_left_panel}>
        {!isForm && responseSubmitted && data.isCorrect === true && (
          <div style={{ padding: '9px 0px 0px 0px' }}>
            {' '}
            <CorrectIcon />
          </div>
        )}
        {!isForm && responseSubmitted && data.isCorrect === false && (
          <div style={{ padding: '9px 0px 0px 0px' }}>
            {' '}
            <IncorrectIcon />{' '}
          </div>
        )}
        {!responseSubmitted ? (
          <FormControlLabel
            className={classes.MuiFormControlLabel_root}
            checked={
              isMultipleAllowed
                ? Array.isArray(checked) && checked.some(item => item === data.value)
                : checked === data.value || (Array.isArray(checked) && checked.some(item => item === data.value))
            }
            onChange={() => {
              setErrorMessage(false);
              if (isMultipleAllowed) {
                const newChecked = modifyArrayBasedOnPresence([...checked], data.value);
                setChecked(newChecked);
              } else {
                setChecked(data.value);
              }
            }}
            control={
              <CustomCheckBox
                color={isBrandingColorEnabled ? `${PrimaryColorCode} !important` : '#213649 !important'}
              />
            }
            label={<></>}
            name="default"
          />
        ) : (
          isForm && (
            <FormControlLabel
              className={classes.MuiFormControlLabel_root}
              checked={
                isMultipleAllowed
                  ? Array.isArray(checked) && checked.some(item => item === data.value)
                  : checked === data.value || (Array.isArray(checked) && checked.some(item => item === data.value))
              }
              onChange={() => {
                setErrorMessage(false);
                if (isMultipleAllowed) {
                  const newChecked = modifyArrayBasedOnPresence([...checked], data.value);
                  setChecked(newChecked);
                } else {
                  setChecked(data.value);
                }
              }}
              control={
                <CustomCheckBox
                  color={isBrandingColorEnabled ? `${PrimaryColorCode} !important` : '#213649 !important'}
                />
              }
              label={<></>}
              name="default"
            />
          )
        )}
      </div>
      <div className={classes.option_right_panel} dangerouslySetInnerHTML={{ __html: data.value }}></div>
    </div>
  );
}
