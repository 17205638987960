import React, { useState } from 'react';
import classes from 'pages/EmailCampaigns/Components/IntroCrad/IntroCard.module.scss';
import EmailIcon from 'assets/emailSvg.svg';
import PartyIcon from 'assets/partySvg.svg';
import CoachImage from 'assets/chatlogo.png';
import { useMediaQuery, useTheme } from '@material-ui/core';
import useAccount from 'hooks/useAccount';
import Checkbox from '@material-ui/core/Checkbox';
import { colors } from 'utils/styles';
import Button from 'components/FormUI/Button';
import {
  AvatarComponent,
  StyledGreeting,
  StyledIcon,
  WelcomMessage,
  Description,
  FeatureHeading,
  StyledFeatureList,
  StyledCheckList,
  StyledFeature,
} from 'pages/EmailCampaigns/Components/IntroCrad/IntroCrad';
import { Link } from 'react-router-dom';

const IntroCard = ({}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { user } = useAccount();

  return (
    <>
      <div className={classes.container}>
        <AvatarComponent alt="coach image" src={CoachImage} mobileView={mobileView} />
        <StyledGreeting>
          Hi {user?.firstName?.charAt(0).toUpperCase() + user?.firstName?.slice(1)} &nbsp;{' '}
          <StyledIcon src={PartyIcon} /> &nbsp; <StyledIcon src={EmailIcon} />
        </StyledGreeting>
        <WelcomMessage>Welcome to Cohere's new Lead Magnet feature!</WelcomMessage>
        <Description>
          Lead magnets are an excellent marketing strategy to collect emails from leads. In a few easy steps you’ll be
          able to create a lead magnet including an opt-in page and a thank you page.
        </Description>
        <FeatureHeading>Your Checklist to Go Live:</FeatureHeading>
        <StyledFeatureList>
          <StyledCheckList>
            <div style={{ marginRight: '10px' }}>
              <Checkbox
                disableRipple={true}
                classes={{
                  root: classes.checkbox_root,
                }}
                value={true}
                onChange={() => {}}
                checked={true}
                style={{ color: colors.darkOceanBlue }}
              />
            </div>
            Your account must be upgraded to an Accelerate Plan.
          </StyledCheckList>
          <StyledCheckList>
            <div style={{ marginRight: '10px' }}>
              <Checkbox
                disableRipple={true}
                classes={{
                  root: classes.checkbox_root,
                }}
                onChange={() => {}}
                checked={true}
                style={{ color: colors.darkOceanBlue }}
              />
            </div>
            You'll need a few paragraphs of content for the opt in and thank you page.
          </StyledCheckList>
          <StyledCheckList>
            <div style={{ marginRight: '10px' }}>
              <Checkbox
                disableRipple={true}
                classes={{
                  root: classes.checkbox_root,
                }}
                onChange={() => {}}
                checked={true}
                style={{ color: colors.darkOceanBlue }}
              />
            </div>
            <div>You'll need to have a lead magnet created, such as a video, an audio recording or a Pdf document.</div>
          </StyledCheckList>
        </StyledFeatureList>
        <FeatureHeading>Additional Coming Soon Feature:</FeatureHeading>
        <StyledFeatureList>
          <StyledFeature>Quiz lead magnets</StyledFeature>
        </StyledFeatureList>
        <Link to={`/create-lead-magnet/basic`}>
          <Button autoWidth>Create Lead Magnet</Button>
        </Link>
      </div>
    </>
  );
};

export default IntroCard;
