import React from 'react';
import { useSelector } from 'react-redux';

const useQuiz = () => {
  const { isLoading, activeQuiz, showCustomizeResults, quizesCollection } = useSelector(state => state?.quizes);
  return {
    isLoading,
    activeQuiz,
    showCustomizeResults,
    quizesCollection,
  };
};

export default useQuiz;
