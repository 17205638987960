import React from 'react';
import classes from './ShortText.module.scss';
import { TextField } from '@material-ui/core';

const ShortText = ({
  index,
  question,
  setFieldValue,
  onRemoveChoice,
  clientView = false,
  checked,
  setChecked = () => {},
}) => {
  console.log('checked------>', checked);
  const extractValue = value => {
    if (Array.isArray(value)) {
      return value.length > 0 ? value[0] : '';
    } else if (typeof value === 'string') {
      return value;
    } else {
      return '';
    }
  };
  return clientView ? (
    <div className={classes.container}>
      {question.options[0].isCorrect ? (
        <TextField
          fullWidth
          placeholder="Type your answer here..."
          onChange={value => {
            setChecked(value.target.value);
          }}
          value={checked}
          helperText={`${extractValue(checked)?.length}/${question?.options[0]?.value}`}
          inputProps={{ maxLength: question.options[0].value }}
          type="text"
          multiline
        />
      ) : (
        <TextField
          fullWidth
          placeholder="Type your answer here..."
          onChange={value => {
            setChecked(value.target.value);
          }}
          value={checked}
          type="text"
        />
      )}
    </div>
  ) : (
    <>
      <div className={classes.container}>
        <TextField
          InputProps={{
            classes: {
              underline: classes.input_underline,
              disabled: classes.input_disabled,
            },
          }}
          fullWidth
          disabled={true}
          placeholder="Type your answer here..."
        />
      </div>
    </>
  );
};

export default ShortText;
