import React from 'react';

const CrossIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L13 13" stroke="#213649" stroke-width="1.5" stroke-linecap="round" />
      <path d="M1 13L13 1" stroke="#213649" stroke-width="1.5" stroke-linecap="round" />
    </svg>
  );
};

export default CrossIcon;
