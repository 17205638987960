import React from 'react';

const FormIcon = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 34 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.9952 19.7273C33.9952 24.3436 33.9952 28.96 33.9952 33.5763C33.9952 36.0209 32.3581 37.9635 29.9463 38.3782C29.6407 38.4328 29.3242 38.4328 29.0077 38.4328C20.9973 38.4328 12.9869 38.4328 4.98741 38.4328C2.05172 38.4328 0 36.3701 0 33.4454C0 24.2781 0 15.1218 0 5.95457C0 3.02979 2.05172 0.967163 4.98741 0.967163C12.9978 0.967163 21.0082 0.967163 29.0077 0.967163C31.9434 0.967163 33.9952 3.01888 33.9952 5.95457C34.0061 10.5491 33.9952 15.1436 33.9952 19.7273ZM2.61921 19.7054C2.61921 24.2672 2.61921 28.829 2.61921 33.3908C2.61921 34.9514 3.48136 35.8245 5.03106 35.8245C13.0087 35.8245 20.9864 35.8245 28.9641 35.8245C30.5138 35.8245 31.3759 34.9514 31.3759 33.3908C31.3759 24.2672 31.3759 15.1436 31.3759 6.03097C31.3759 4.47036 30.5138 3.59729 28.9641 3.59729C20.9864 3.59729 13.0087 3.59729 5.03106 3.59729C3.47045 3.59729 2.61921 4.47036 2.61921 6.03097C2.61921 10.5818 2.61921 15.1436 2.61921 19.7054Z"
        fill="#215C73"
      />
      <path
        d="M9.0362 29.7021C10.2148 28.5125 11.3498 27.3884 12.4848 26.2425C12.8122 25.9151 13.1833 25.7187 13.6526 25.7842C14.1655 25.8605 14.5365 26.1334 14.7112 26.6245C14.8967 27.1156 14.7985 27.563 14.4492 27.9559C14.351 28.065 14.2419 28.1742 14.1327 28.2724C12.8668 29.5384 11.5899 30.8152 10.324 32.0812C10.1603 32.2449 10.0402 32.4522 9.85471 32.5832C9.40726 32.8888 8.77428 32.8451 8.33774 32.4522C7.37737 31.5901 6.42791 30.7061 5.48936 29.8221C4.96552 29.3201 4.96552 28.4907 5.45662 27.9887C5.94772 27.4757 6.76622 27.443 7.29006 27.9559C7.8903 28.5016 8.43597 29.0909 9.0362 29.7021Z"
        fill="#215C73"
      />
      <path
        d="M9.02533 20.1201C10.1931 18.9524 11.2844 17.8392 12.4085 16.7479C12.6267 16.5296 12.9432 16.355 13.2379 16.2895C13.7836 16.1695 14.3183 16.4641 14.5693 16.9443C14.8312 17.4354 14.7658 18.0356 14.362 18.4613C14.0455 18.7996 13.7072 19.1161 13.3798 19.4435C12.2557 20.6003 11.1207 21.7571 9.99662 22.9139C9.64739 23.2741 8.8398 23.3177 8.41418 22.9248C7.41015 22.0299 6.41703 21.1132 5.44574 20.1856C4.94372 19.7054 4.99829 18.8651 5.48939 18.3849C5.99141 17.8938 6.75534 17.8829 7.30101 18.363C7.60659 18.6359 7.89033 18.9305 8.17408 19.2252C8.44692 19.498 8.71976 19.7927 9.02533 20.1201Z"
        fill="#215C73"
      />
      <path
        d="M9.09103 10.5163C10.2042 9.40313 11.2737 8.33362 12.3323 7.26411C12.9325 6.66388 13.7074 6.63114 14.2421 7.16589C14.7878 7.71156 14.7442 8.48641 14.1548 9.08665C12.8452 10.3963 11.5356 11.7059 10.226 13.0155C9.51665 13.7248 8.80728 13.7248 8.08699 13.0264C7.32306 12.2843 6.57004 11.5422 5.81701 10.7891C5.27135 10.2544 5.26043 9.50135 5.77336 8.9666C6.28629 8.44276 7.05023 8.44276 7.58498 8.97751C8.087 9.47953 8.56718 9.98154 9.09103 10.5163Z"
        fill="#215C73"
      />
      <path
        d="M23.1364 9.25046C24.5224 9.25046 25.9084 9.25046 27.2944 9.25046C28.1566 9.25046 28.7677 9.78521 28.7677 10.5491C28.7677 11.3131 28.1675 11.8588 27.3053 11.8588C24.4897 11.8588 21.674 11.8588 18.8584 11.8588C18.0289 11.8588 17.4396 11.3131 17.4287 10.5601C17.4287 9.80704 18.018 9.25046 18.8474 9.25046C20.2771 9.23954 21.7067 9.25046 23.1364 9.25046Z"
        fill="#215C73"
      />
      <path
        d="M23.0924 28.4253C24.5329 28.4253 25.9844 28.4143 27.425 28.4253C28.1452 28.4362 28.5709 28.8727 28.7346 29.6912C28.8219 30.1387 28.4836 30.6952 27.9706 30.9135C27.7851 30.9899 27.5777 31.0336 27.3813 31.0336C24.522 31.0445 21.6627 31.0445 18.8034 31.0336C18.1813 31.0336 17.6902 30.6625 17.5156 30.1387C17.3519 29.6366 17.472 29.0364 17.9194 28.7417C18.1923 28.5562 18.5633 28.4362 18.9016 28.4362C20.2876 28.4143 21.6845 28.4253 23.0924 28.4253Z"
        fill="#215C73"
      />
      <path
        d="M23.0816 21.4407C21.6192 21.4407 20.1677 21.4516 18.7053 21.4407C18.1051 21.4407 17.6249 21.0369 17.483 20.4912C17.3411 19.9346 17.5376 19.3562 18.0287 19.0725C18.2579 18.9415 18.5416 18.8542 18.7926 18.8433C21.641 18.8324 24.4894 18.8324 27.3269 18.8324C28.1126 18.8324 28.5928 19.3017 28.7347 20.142C28.8111 20.5895 28.4182 21.1788 27.8835 21.3534C27.687 21.4189 27.4578 21.4298 27.2396 21.4407C25.8645 21.4516 24.4785 21.4407 23.0816 21.4407Z"
        fill="#215C73"
      />
    </svg>
  );
};

export default FormIcon;
