import * as leadMagnetActions from 'actions/leadMagnets';
import { LeadMagnetType } from 'helpers/constants';
import * as Yup from 'yup';

import { useSaveLeadMagnet } from 'hooks';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { batch, connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as R from 'ramda';
import _, { isEmpty } from 'lodash';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';
import Button from 'components/FormUI/Button';
import { useMediaQuery, useTheme } from '@material-ui/core';
import * as calendarsActions from 'actions/calendars';
import CoachImage from 'assets/chatlogo.png';
import TemplateOne from 'assets/congratulations.png';
import Modal from 'components/UI/Modal';
import CrossIcon from '@material-ui/icons/Clear';
import InfoIcon from '@material-ui/icons/Info';
import { toRem, colors } from 'utils/styles';
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  EVENTS,
  StoreHelpers,
  BeaconRenderProps,
  TooltipRenderProps,
} from 'react-joyride';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import MainContainer from 'components/Containers/MainContainer';
import { useLoadMemberPodsByContribution } from 'components/MemberPods';
import { useSaveContribution } from 'hooks/useSaveContribution';
import { getTimePeriodsForSessionsCollection, UTCtoLocalDateTime } from 'utils/datesAndMoney';
import { useRouter } from 'hooks';
import useAccount from 'hooks/useAccount';
import { ContributionType } from 'helpers/constants';
import { getCurrentSessionTime } from 'utils/getSessionTime';
import CommunityForm from 'pages/CreateContribution/components/CommunityForm';
import Buttons from './components/Buttons';
import LiveForm from './components/LiveForm';
import MembershipForm from './components/MembershipForm/index';
import { OneToOneForm } from './components/OneToOneForm';
import { generateOneToOneSlots, prepareSessions } from './utils';
import { useOtherEvents } from './hooks/useOtherEvents';
import { checkSessionDuration } from './hooks/useCheckSessionDuration';
import {
  communitySessionsValidationSchema,
  enrollmentFormSchema,
  liveSessionsValidationSchema,
  membershipSessionsValidationSchema,
  oneToOneSessionsValidationSchema,
} from './contributionValidations';
import { LiveVideoProvider } from '../../constants';
import { date } from 'yup';
import { orderBy } from 'lodash';
import { UpdateUserProgressBarData } from 'services/user.service';
import { updateUser } from 'actions/update-user';
import { getLatestApproved, getUpcomingCreated } from 'services/contributions.service';
import CustomQuestions from './components/CustomQuestions';
import ContentAudioVideoForm from './components/ContentAudioVideoForm';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { BodyText, PageTitleSecond } from 'components/UI/Text/TextStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { openInNewWindow } from 'services/links';
import { deletePublicImage } from 'services/content.service';
import pdfFile from 'assets/Thankyou page.pdf';

const serializeTime = R.compose(R.head, R.split('+'));

const CustomInvitationBodyDefault = `When it's time for your session, please launch Cohere by going to the following URL to login: App.Cohere.Live.
If you need to contact the other session participant(s), this can also be done by going to App.Cohere.Live and then visiting \"Conversations\".
If you need to reschedule, this can done by going to App.Cohere.Live and then visiting the Sessions tab. 
If you are a Client, please go to \"My Journey\", click on the session, and then visit the \"Sessions\" tab to find the reschedule button. 
If you are a service provider, please open your Contribution and go to the \"Sessions\"
PLEASE NOTE, THIS IS A NO-REPLY EMAIL ACCOUNT.`;
const CustomInvitationBodyDefaultOneOnOne = `{CoachName} and {ClientName} have scheduled a video call for {contributionName}. To join the call, simply click on the link provided in the invitation. If you are not logged in to Cohere, you will be prompted to do so before joining the call.

If you need to reschedule your session, you can do so here: {contributionLink}. You may need to log into Cohere at app.cohere.live before accessing the link.
`;

const customInvitaionBodyText = {
  cohereVideo: `{CoachName} and {ClientName} have scheduled a video call for {contributionName}. To join the call, simply click on the link provided in the invitation. If you are not logged in to Cohere, you will be prompted to do so before joining the call.

If you need to reschedule your session, you can do so here: {contributionLink}. You may need to log into Cohere at app.cohere.live before accessing the link.
`,
  zoomVideo: `{CoachName} and {ClientName} have scheduled a video call for {contributionName}. To join the call, simply click on the link provided in the invitation. 

If you need to reschedule your session, you can do so here:  {contributionLink}. You may need to log into Cohere at app.cohere.live before accessing the link.
`,
  privateLink: `{CoachName} and {ClientName} have scheduled a video call for {contributionName}. To join the call, simply click on the link provided in the invitation. 

If you need to reschedule your session, you can do so here:  {contributionLink}. You may need to log into Cohere at app.cohere.live before accessing the link.
`,
  phoneCall: `{CoachName} and {ClientName} have a scheduled call for {contributionName}. Participants will call {phoneNo}.  

If you need to reschedule your session, you can do so here:  {contributionLink}. You may need to log into Cohere at app.cohere.live before accessing the link.
`,
  inPerson: `{CoachName} and {ClientName} have a scheduled meeting for {contributionName}. Participants will meet at the location specified in this invite.

If you need to reschedule your meeting, you can do so here:  {contributionLink}. You may need to log into Cohere at app.cohere.live before accessing the link.
`,
};

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const StyledCloseIcon = styled(CrossIcon)`
  margin: 5px;
  height: 15px;
  width: 15px;
  &:hover {
    cursor: pointer;
  }
`;
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ mobileView }) => mobileView && `flex-direction:column`}
`;
const StyledImgCongrats = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  display: inline-block;
  // border-radius: 40px;
  align-items: center;
  z-index: 1;
`;

const StyledHeaderButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  column-gap: 0.5em;
  ${({ mobileView }) =>
    mobileView &&
    css`
      flex-direction: row;
    `}
`;

const useStyles = makeStyles({
  detailsHeader: {
    marginRight: '20px',
    // whiteSpace: 'nowrap',
  },

  colorSelect: {
    // borderRadius: '5px',
    // display: 'flex',
    // padding: '3px',
    width: '145px',
    // height: '32px',
    backgroundColor: 'transparent',
    // marginTop: '-30px',
  },

  header: ({ mobileView }) =>
    mobileView
      ? {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingBottom: '10px',
        }
      : {},
});

const findFirstErrorMessage = errors => {
  // Check if the current object is an error message
  if (typeof errors === 'string') {
    return errors; // Return the error message
  }

  // Iterate through the object keys
  for (const key in errors) {
    // Recursively call the function if the value is an object
    if (typeof errors[key] === 'object') {
      const errorMessage = findFirstErrorMessage(errors[key]);
      if (errorMessage) {
        return errorMessage; // Return the first error message found
      }
    } else if (typeof errors[key] === 'string') {
      return errors[key]; // Return the error message
    }
  }

  return null; // Return null if no error message found
};

function Sessions({ leadMagnet, saveLeadMagnet, saveLeadMagnetToLS }) {
  const { user } = useAccount();
  const dispatch = useDispatch();

  const errorState = useSelector(state => state?.leadMagnets.error);
  const errorOccured = useSelector(state => state?.leadMagnets.showError);

  const serviceAgreementType = user?.serviceAgreementType;
  const isBetaUser = user?.isBetaUser;
  const showOnlyFreeOption = serviceAgreementType && isBetaUser ? true : false;

  const [errorPopUpState, seterrorPopUpState] = useState(false);
  const [run, setRun] = useState(!user?.userProgressbarData?.FirstContributionDone);
  const [stepIndex, setStepIndex] = useState(4);
  const [selectedEmailCalendar, setSelectedEmailCalendar] = useState();
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [offset, setOffset] = useState(0);
  const windowHeight = window.innerHeight;
  const scrollPosition = window.scrollY;

  // useEffect(() => {
  //   return () => {
  //     (filesToDelete || []).forEach(k => deletePublicImage(k));
  //   };
  // }, []);
  useEffect(() => {
    console.info('filesToDelete:', filesToDelete);
  }, [filesToDelete]);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setOffset(scrollPosition);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [offset]);
  let joyRideHelpers;
  useEffect(() => {
    if (isEditing === true) {
      setRun(false);
    }
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [contibId, setContibId] = useState(null);
  useEffect(() => {
    getLatestApproved().then(res => {
      setContibId(res);
    });
  }, []);
  const [steps, setSteps] = useState([
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                // setRun(false);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      content: (
        <div className="step3">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Add Photo
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            Lorem Ipsum passages, and more recently with desktop publishing software like
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '70%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#integrationTab',
    },
    {
      spotlightClicks: true,
      disableBeacon: true,
      hideFooter: true,
      floaterProps: { disableFlip: true },
      content: (
        <div className="step4">
          <div
            className="image"
            style={{
              height: '70px',
              width: '70px',
              backgroundColor: 'white',
              position: 'absolute',
              borderRadius: '100px',
              top: -45,
              left: 150,
              borderColor: '#215C73',
              borderWidth: '5px',
            }}
          >
            <StyledImg previewImg={CoachImage} />
          </div>
          <div
            style={{
              fontSize: '18px',
              color: '#215C73',
              display: 'flex',
              alignSelf: 'flex-start',
              fontWeight: 850,
              fontFamily: 'Avenir',
            }}
          >
            Pre-Schedule Group Sessions and/or Add Self-Paced Modules
          </div>
          <div
            style={{
              color: 'black',
              fontSize: '16px',
              marginTop: '13px',
              fontWeight: 350,
              fontFamily: 'Avenir',
              textAlign: 'left',
            }}
          >
            This is where you create your group course content! Your clients will get auto-booked for any upcoming LIVE
            group sessions that get scheduled here. They will be directed to a tab to see upcoming sessions and
            self-paced audio, video content, and PDFs.
          </div>
          <div className="buttons" style={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              className="btn-left"
              autoWidth
              backgroundColor={'white'}
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '100%',
                borderColor: '#A58B62',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#A58B62',
              }}
              onClick={() => {
                updateUserF();
                // setRun(false);
              }}
            >
              Mark as Complete
            </Button>

            {/* <Button
              className="btn-right"
              autoWidth
              invert
              style={{
                marginTop: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
                width: '30%',
                borderColor: '#215C73',
                borderWidth: '1px',
                borderRadius: '5px',
                color: '#215C73',
                marginLeft: '10px',
              }}
              onClick={() => {
                joyRideHelpers.skip();
                setRun(false);
              }}
            >
              Next
            </Button> */}
          </div>
        </div>
      ),
      placement: 'left',
      target: '#contributionSessions',
    },
    // {
    //   hideFooter: true,
    //   disableBeacon: true,
    //   content: (
    //     <div>
    //       <StyledImgCongrats previewImg={TemplateOne}></StyledImgCongrats>
    //       <div style={{ fontSize: '24px', color: '#215C73', fontWeight: 800 }}>CONGRATS!</div>
    //       <div style={{ color: '#A58B62', fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
    //         You are fully set up & ready to transform lives!
    //       </div>
    //       <Button
    //         autoWidth
    //         backgroundColor={'#C9B382'}
    //         style={{
    //           marginTop: '40px',
    //           paddingTop: '10px',
    //           paddingBottom: '10px',
    //         }}
    //         onClick={() => {
    //           joyRideHelpers.skip();
    //         }}
    //       >
    //         Let’s do this!
    //       </Button>
    //     </div>
    //   ),
    //   placement: 'center',
    //   target: 'body',
    // },
  ]);

  const updateUserF = async () => {
    let obj = {
      id: user.id,
      userProgressbarData: {
        ...user.userProgressbarData,
        FirstContributionDone: true,
      },
      progressBarPercentage: user.progressBarPercentage,
    };

    await UpdateUserProgressBarData(obj).then(res => {
      let u = {
        ...user,
        userProgressbarData: { ...res.userProgressbarData },
        progressBarPercentage: res.progressBarPercentage,
      };
      dispatch(updateUser(u));
      if (res.progressBarPercentage == 100) {
        setShowModal(true);
      }
      if (res.userProgressbarData?.IsPlanPurchased === false) {
        history.push('/account/payment', { from: 'verify' });
      } else if (res?.userProgressbarData?.IsBankAccountConnected === false) {
        history.push('/account/payment', { from: 'bankAccount' });
      } else if (res?.userProgressbarData?.IntegrationsDone === false) {
        history.push('/account/integrations', { from: 'bankAccount' });
      } else if (res?.userProgressbarData?.IsProfileUploaded === false) {
        history.push('/account/profile');
      } else if (res?.userProgressbarData?.FirstContributionDone === false) {
        dispatch(leadMagnetActions.setPopUp(true));
        history.push('/dashboard');
      } else if (res?.userProgressbarData?.IsWebsiteAdded === false) {
        history.push('/account/profile', { from: 'Manage Your Website Information' });
      } else if (res?.userProgressbarData?.InviteLink === false) {
        contibId === null ? console.log('Helloo') : history.push(`contribution-view/${contibId}/about`);
      }
    });
    joyRideHelpers.skip();
  };

  useEffect(() => {
    if (!!user?.userProgressbarData?.FirstContributionDone) {
      setSteps([]);
    }
  }, []);

  const getHelpers = helpers => {
    joyRideHelpers = helpers;
  };

  const handleJoyrideCallback = data => {
    const { status, type, index } = data;
    const nextStepIndex = index + 1;

    const finishedStatuses = ['finished', 'skipped'];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
    if ([EVENTS.STEP_AFTER].includes(type)) {
      setStepIndex(nextStepIndex);
    }
    if (data.action === 'close') {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };

  const { history, query } = useRouter();
  const contributionFormRef = useRef();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isEditing = Boolean(query.id);
  const { onSave: onSaveLeadMagnet } = useSaveLeadMagnet(isEditing);
  const [redirectTo, setRedirectTo] = useState('');
  const classes = useStyles({ mobileView });

  const saveHandler = isEditing ? saveLeadMagnet : saveLeadMagnetToLS;

  //// TODO -------------------
  const submitLeadMagnetHandler = useCallback(
    (values, formikHelpers, to = '') => {
      console.info('lead-magnet', values.additionalThankYou);
      let content = {
        contentHeader: values.contentHeader,
        prerecordedData: values.content,
      };
      let thankyouPage = {
        leadMagnetThankyouPage: values.thankYou,
        additionalLeadMagnetThankyouPage: values.additionalThankYou,
      };
      const newRecord = {
        ...leadMagnet,
        content,
        thankyouPage,
      };
      saveHandler(newRecord);
      onSaveLeadMagnet(newRecord);
      console.info('Lead Magnet new', newRecord);
      history.push(to || redirectTo);
    },
    [leadMagnet, history, saveHandler, redirectTo, onSaveLeadMagnet],
  );

  let Form = null;

  const MAX_HEADER_WORD_COUNT = 20;
  const MAX_DESCRIPTION_WORD_COUNT = 150;
  const MAX_HEADER_CHAR_COUNT = 80;
  const MAX_DESCRIPTION_CHAR_COUNT = 500;
  const MAX_BUTTON_CHAR_COUNT = 20;

  const renderButtons = (submitHandler = () => {}) => (
    <Buttons
      backButtonTitle="Back"
      backClickHandler={event => {
        const to = query.id ? `/edit-lead-magnet/${query.id}/details` : '/create-lead-magnet/details';
        setRedirectTo(to);
        submitHandler(event, to);
      }}
      onNextClickHandler={event => {
        const to = query.id ? `/edit-lead-magnet/${query.id}/review` : '/create-lead-magnet/review';
        setRedirectTo(to);
        submitHandler(event, to, true);
        (filesToDelete || []).forEach(k => deletePublicImage(k));
      }}
      nextButtonTitle="Save and Next"
      formId="create-lead-magnet-form"
      clientPreviewBtn
      onClientPreviewBtnHandler={event => {
        const to =
          query.id || leadMagnet?.id
            ? `/lead-magnet-view/${query.id || leadMagnet?.id}/clientpreview/thankyoupage`
            : '/create-lead-magnet/content';
        openInNewWindow(to);
        submitHandler(event, true);
      }}
    />
  );
  if ([LeadMagnetType.LeadMagnetAudioVideo, LeadMagnetType.LeadMagnetPDF].includes(leadMagnet?.type)) {
    Form = (
      <Formik
        innerRef={contributionFormRef}
        enableReinitialize
        initialValues={{
          contentHeader: leadMagnet?.content?.contentHeader || 'Download Now',
          content: leadMagnet?.content?.prerecordedData || [],
          thankYou: leadMagnet?.thankyouPage?.leadMagnetThankyouPage || {
            bannerImage: '',
            header: '',
            description: '',
            ctaButtonText: 'Access Content',
            isVideoBanner: false,
          },
          additionalThankYou: leadMagnet?.thankyouPage?.additionalLeadMagnetThankyouPage
            ? { ...(leadMagnet?.thankyouPage?.additionalLeadMagnetThankyouPage || {}), isCustomCTAEnabled: true }
            : {
                bannerImage: '',
                header: '',
                description: '',
                ctaButtonText: '',
                isCustomCTAEnabled: true,
                url: '',
                isVideoBanner: false,
                isSectionHidden: false,
              },
        }}
        validationSchema={Yup.object().shape({
          contentHeader: Yup.string()
            .strict(true)
            .required('Header is a required field')
            // .min(1, 'Header is a required field')
            .max(MAX_HEADER_CHAR_COUNT, 'Header can be max 80 characters'),
          // .test('word-count', 'Header must be at most 20 words.', value =>
          //   value ? value.trim().split(/\s+/).length <= MAX_HEADER_WORD_COUNT : true,
          // ),
          content: Yup.array()
            .of(
              Yup.object().shape({
                // id: Yup.string().required(),
                documentKeyWithExtension: Yup.string().nullable(),
                documentOriginalNameWithExtension: Yup.string().nullable(),
                // contentType: Yup.string().nullable(),
                duration: Yup.string().nullable(),
                extension: Yup.string().nullable(),
                attachementUrl: Yup.string().nullable(),
                thumbnailUrl: Yup.string().nullable(),
                embeddedVideoUrl: Yup.string().nullable(),
                // uploadDateTime: Yup.string().nullable(),
                // isRemovedFromResource: Yup.boolean().required(),
              }),
            )
            .min(1, 'At least one content item is required')
            .required(),
          thankYou: Yup.object().shape({
            bannerImage: Yup.string().default(''),
            isVideoBanner: Yup.boolean().default(false),
            header: Yup.string()
              .strict(true)
              .required('Header is a required field')
              .max(MAX_HEADER_CHAR_COUNT, 'Header can be max 80 characters'),
            // .test('word-count', 'Header must be at most 20 words.', value =>
            //   value ? value.trim().split(/\s+/).length <= MAX_HEADER_WORD_COUNT : true,
            // ),
            description: Yup.string()
              .strict(true)
              // .required('Description is a required field')
              .max(MAX_DESCRIPTION_CHAR_COUNT, 'Description can be max 500 characters'),
            // .test('word-count', 'Description must be at most 150 words.', value =>
            //   value ? value.trim().split(/\s+/).length <= MAX_DESCRIPTION_WORD_COUNT : true,
            // ),
            ctaButtonText: Yup.string()
              .strict(true)
              .required('Button text is a required field')
              .max(MAX_BUTTON_CHAR_COUNT, 'Button text can be max 20 characters'),
          }),
          additionalThankYou: Yup.object().shape({
            bannerImage: Yup.string().default('').nullable(),
            isVideoBanner: Yup.boolean().default(false),
            header: Yup.string()
              .strict(true)
              // .required('Header is a required field')
              .max(MAX_HEADER_CHAR_COUNT, 'Header can be max 80 characters'),
            // .test('word-count', 'Header must be at most 20 words.', value =>
            //   value ? value.trim().split(/\s+/).length <= MAX_HEADER_WORD_COUNT : true,
            // ),
            description: Yup.string()
              .strict(true)
              // .required('Description is a required field')
              .max(MAX_DESCRIPTION_CHAR_COUNT, 'Description can be max 500 characters'),
            // .test('word-count', 'Description must be at most 150 words.', value =>
            //   value ? value.trim().split(/\s+/).length <= MAX_DESCRIPTION_WORD_COUNT : true,
            // ),
            ctaButtonText: Yup.string()
              .strict(true)
              // .required('Button Text is a required field')
              .max(MAX_BUTTON_CHAR_COUNT, 'Button Text can be max 20 characters'),
            isCustomCTAEnabled: Yup.boolean().default(true),
            url: Yup.string().default(''),
            selectedContributionId: Yup.string().default('').nullable(),
            // if isCustomCTAEnabled is enabled, then one from 'url' or 'selected-contribution-id' would be required
            // url: Yup.string().when('isCustomCTAEnabled', {
            //   is: true,
            //   then: Yup.string().required('URL is a required field'),
            //   else: Yup
            // }),
            // selectedContributionId: Yup.string(),
            isSectionHidden: Yup.boolean().default(false),
          }),
        })}
        onSubmit={submitLeadMagnetHandler}
      >
        {formProps => {
          const saveData = (event, to, withValidation = false) => {
            if (/*isEditing || */ withValidation) {
              if (!isEmpty(formProps.errors)) {
                const firstErrorFieldMessage = findFirstErrorMessage(formProps.errors);
                batch(() => {
                  dispatch(leadMagnetActions.setErrorPopUp(true));
                  dispatch(leadMagnetActions.setErrorMessage({ message: firstErrorFieldMessage }));
                });
                seterrorPopUpState(true);
                return;
              }
              formProps.handleSubmit(event);
            } else {
              submitLeadMagnetHandler(formProps.values, formProps, to);
            }
          };

          return (
            <MainContainer
              sidebarProps={{
                saveHandler: (event, { to }) => {
                  setRedirectTo(to);
                  saveData(event, to);
                },
                errors: {},
              }}
            >
              <Joyride
                // tooltipComponent={Tooltip}
                callback={handleJoyrideCallback}
                continuous={true}
                getHelpers={getHelpers}
                run={run}
                stepIndex={stepIndex}
                showProgress={false}
                showSkipButton={false}
                steps={steps}
                styles={{
                  options: {
                    primaryColor: '#CDBA8F',
                    zIndex: 10000000,
                  },
                }}
              />
              <StyledMainSection id={'leadMagnetContent'} mobileView={mobileView}>
                {errorOccured && errorPopUpState && (
                  <div
                    className="sticky"
                    style={{
                      top: offset >= 110 ? 0 : 110,
                      width: '45%',
                      backgroundColor: 'white',
                      marginInline: '9%',
                      border: '1px solid #DBE7F2',
                      borderRadius: '4px',
                      zIndex: 1,
                      transitionDuration: '1s',
                    }}
                  >
                    <div style={{ padding: '15px 10px 10px 15px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <InfoIcon style={{ transform: 'rotateX(180deg)' }} htmlColor={colors.darkOceanBlue} />
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontWeight: '700',
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: '#1E1E1E',
                              marginInline: '10px',
                            }}
                          >
                            Before you proceed...
                          </div>
                        </div>
                        <StyledCloseIcon
                          onClick={() => {
                            seterrorPopUpState(false);
                            batch(() => {
                              dispatch(leadMagnetActions.setErrorPopUp(false));
                              dispatch(leadMagnetActions.setErrorMessage(''));
                            });
                          }}
                          fontSize="10px"
                          style={{ color: 'black' }}
                        />
                      </div>

                      {errorState?.message ===
                      `Your availability time frame windows are overlapping. Please fix this on step 3` ? (
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#1E1E1E',
                            marginTop: '10px',
                            marginInline: '35px',
                          }}
                        >
                          You already have a session scheduled on the added date & time. Please add another date & time
                          in order to proceed with “Save and Exit” Please fill out missing field in order to continue
                        </div>
                      ) : errorState?.Error?.Message ? (
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#1E1E1E',
                            marginTop: '10px',
                            marginInline: '35px',
                          }}
                        >
                          Your Zoom access token has expired.Please update your Zoom access token by reconnecting zoom
                          on the integrations tab.
                        </div>
                      ) : (
                        <div
                          style={{
                            fontFamily: 'Avenir',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#1E1E1E',
                            marginTop: '10px',
                            marginInline: '35px',
                          }}
                        >
                          {errorState?.message || errorState?.Error?.Message}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <Card id={'leadMagnetContents'} mobileView={mobileView}>
                  <CardHeader className={classes.header} mobileView={mobileView}>
                    <StyledHeader {...{ mobileView }}>
                      <PageTitleSecond
                        className={classes.detailsHeader}
                        mobileView={mobileView}
                        style={mobileView ? { marginRight: 0 } : {}}
                        styleOverrides={
                          mobileView
                            ? css`
                                color: rgba(0, 0, 0, 0.87);
                                font-family: Avenir;
                                font-size: 1.125rem;
                                font-style: normal;
                                font-weight: 350;
                                line-height: normal;
                                text-transform: capitalize;
                              `
                            : css`
                                color: rgba(0, 0, 0, 0.87);
                                font-family: Avenir;
                                font-size: 1.5rem;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: capitalize;
                              `
                        }
                      >
                        3. Add Lead Magnet Content & Create Thank You Page
                      </PageTitleSecond>
                      <StyledHeaderButtons {...{ mobileView }}>
                        <Button
                          autoWidth
                          clientPreviewBtn
                          onClick={() => {
                            window.open(pdfFile, '_blank');
                          }}
                          style={{
                            margin: '0.67em 0.5em',
                            margin: 0,
                            ...(mobileView ? { width: '50%' } : {}),
                          }}
                        >
                          Example
                        </Button>
                        <Button
                          autoWidth
                          clientPreviewBtn
                          onClick={event => {
                            const to =
                              query.id || leadMagnet?.id
                                ? `/lead-magnet-view/${query.id || leadMagnet?.id}/clientpreview/thankyoupage`
                                : '/create-lead-magnet/content';
                            openInNewWindow(to);
                            saveData(event, true);
                          }}
                          style={{
                            margin: '0.67em 0.5em',
                            margin: 0,
                            ...(mobileView ? { width: '50%' } : {}),
                          }}
                        >
                          Client Preview
                        </Button>
                      </StyledHeaderButtons>
                    </StyledHeader>

                    {/* {!isEditing && (
                      <ExampleLink href={ExamplePDF} mobileView={mobileView} target="_blank">
                        PDF Template
                      </ExampleLink>
                    )} */}
                  </CardHeader>
                  <CardBody
                    styleOverrides={css`
                      padding: ${mobileView ? '1.25rem 0.5rem' : '1.25rem'};
                    `}
                  >
                    <BodyText mobileView={mobileView}>
                      In this step, you’re creating a ‘Thank You Page’. This is where your leads will get redirected
                      after they opt-in, and where they’ll be able to access your content!
                    </BodyText>
                    <ContentAudioVideoForm
                      {...formProps}
                      {...{
                        addFilesToDelete: url => {
                          if (!filesToDelete.includes(url)) {
                            setFilesToDelete([...filesToDelete, url]);
                          }
                        },
                      }}
                    />
                  </CardBody>
                </Card>
                {renderButtons(saveData)}
              </StyledMainSection>
            </MainContainer>
          );
        }}
      </Formik>
    );
  }

  // return Form || renderButtons();
  return Form;
}

Sessions.propTypes = {
  leadMagnet: PropTypes.shape(),
};

Sessions.defaultProps = {
  contribution: null,
};

const mapStateToProps = ({ leadMagnets }) => ({
  leadMagnet: leadMagnets.activeLeadMagnet,
});

const actions = {
  saveLeadMagnet: leadMagnetActions.saveLeadMagnet,
  saveLeadMagnetToLS: leadMagnetActions.saveDraftLeadMagnet,
};

export default connect(mapStateToProps, actions)(Sessions);
