/* eslint-disable react/prop-types,no-unused-expressions */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TagsInput } from 'react-tag-input-component';
import Grid from '@material-ui/core/Grid';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import { determineColorToUse, determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import SendIcon from '@material-ui/icons/Send';
import { Formik, FieldArray } from 'formik';
import moment from 'moment';
import isNull from 'lodash/isNull';
import { connect, useDispatch, useSelector } from 'react-redux';
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import * as R from 'ramda';
import filter from 'lodash/filter';
import { ContributionType, UserRoles, ContributionThemedColors } from 'helpers/constants';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { editPost, getHashtags, notifyTaggedUsers, post, removeAttachment } from 'services/community.service';
import { StyledInput, StyledSlider, Switch } from 'components/switch/style';
import UserMentionInput from 'components/UI/mentions/UserMentionInput';
import _ from 'lodash';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { setPostsAsRead } from 'services/user.service';
import { useAccount, useHttp, usePartnerCoach, useUnifiedCommunity } from '../../../../../hooks';
import useContribution from '../../../hooks/useContribution';
import * as actions from '../../../../../actions/community';
import { Attachments } from '../Attachments/Attachments';
import { AvatarComponent, StyledButton, StyledPhotoCameraIcon, StyledTextError } from './CreatePost.styled';
import ButtonProgress from '../../../../../components/FormUI/ButtonProgress';
import { COUNT_POSTS_BY_PAGE, DAILY_BY_SEND_DATA, TOOLTIP } from '../../../../../constants';
import { colors } from '../../../../../utils/styles';
import * as contentService from 'services/content.service';
import './CreatePost.scss';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import styled from 'styled-components';
import EventIcon from '@material-ui/icons/Event';
import { ColorSchemeService } from 'services/color.service';
// import DateTimePicker from 'components/FormUI/DateTimePicker';
import { DateTimePicker } from '@material-ui/pickers';
import { TemplateType } from 'helpers/constants/templateType';
import { isValidUrl, lightOrDark } from 'utils/utils';
import Modal from 'components/UI/Modal';
import useRouter from 'hooks/useRouter';
import Button from 'components/FormUI/Button';
import TextField from 'components/UI/TextField';
import { RxCrossCircled } from 'react-icons/rx';

const HashTagButton = styled(Button)`
  padding: 5px 10px !important;
  margin-bottom: 1.35rem;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 600;
`;

const StyledCross = styled(RxCrossCircled)``;
const StyledRemove = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 8px;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
`;

const StyledHashtag = styled.div`
  height: 25px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  width: auto;
  word-wrap: break-word;
  position: relative;
  // border-radius: 5px;
  margin-right: 15px;
  min-width: 40px;
`;
const StyledProgressWrap = styled(Box)`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
`;

const CreatePost = ({
  item,
  isEdit,
  onSubmit,
  pods = [],
  currentGroup,
  contributionData,
  fetchPosts,
  isEditing = false,
}) => {
  const podIdPrefix = 'podId-';
  const [selected, setSelected] = useState(isEdit ? item?.hashTags : []);
  const participantsLoader = useSelector(state => state.contributions?.loadingParticipants);

  const { user } = useAccount();
  const attachmentRef = useRef();
  const { domain, subdomain, parentDomain } = useRouter();
  const { request, loading } = useHttp();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [typedHashTags, settypedHashTags] = useState([]);
  const mobileViewCommunity = useMediaQuery(theme.breakpoints.down('sm'));
  const [hashTags, sethashTags] = useState([]);
  const { isUnifiedCommunity } = useUnifiedCommunity();
  const contrib = useContribution();
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const contribution = isUnifiedCommunity ? contributionData : activeContribution;
  let colorToUse = determineColorToUse(contribution);
  // const { partnerCoaches } = usePartnerCoach(contribution.id);
  const contributionAuthor = useSelector(state => state.cohealerInfo.info);
  const communityPosts = useSelector(state => state.communityPosts?.userDraftPost);
  const [isPrivate, setIsPrivate] = useState(item?.isPrivate);
  const [textareaValue, setTextareaValue] = useState(item?.text ?? '');
  const [userMentions, setUserMentions] = useState([]);
  const [resetUserMentionInputValue, setResetUserMentionInputValue] = useState(false);
  const [progress, setProgress] = useState(null);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const datetimeRef = useRef(null);
  const currentDate = moment();
  const [hashtag, setHashtag] = useState('');
  const [LoaderLocal, setLoaderLocal] = useState(null);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const AccentColor = colorToUse?.AccentColorCode;
  const [dateandtimevalue, setDateandtimevalue] = useState(isEdit ? item?.scheduledTime : currentDate);
  const [isScheduled, setIsScheduled] = useState(isEdit ? item?.isScheduled : false);
  const [users, setUsers] = useState([]);
  const calculatedUsers = [
    ...[
      {
        imageUrl: null,
        display: `@channel`,
        id: 'channelId',
      },
    ],
    ...(contribution?.participants?.map(x => ({
      imageUrl: x.avatarUrl,
      display: `@${x.firstName} ${x.lastName}`,
      id: x.id,
    })) || []),
    ...(contribution?.contributionPartners?.map(x => ({
      imageUrl: x.avatarUrl,
      display: `@${x.firstName} ${x.lastName}`,
      id: x.userId,
    })) || []),
    ...pods?.map(x => ({ id: `${podIdPrefix}${x.id}`, display: x.name })),
  ];

  if (
    contributionAuthor &&
    !calculatedUsers?.some(e => e.id === contributionAuthor?.id && participantsLoader === false)
  ) {
    calculatedUsers.push({
      imageUrl: contributionAuthor.avatarUrl,
      display: `@${contributionAuthor.firstName} ${contributionAuthor?.lastName}`,
      id: contributionAuthor.id,
    });
    if (participantsLoader) {
      calculatedUsers.push({
        imageUrl: null,
        display: `Loading participants...`,
        id: '1122',
      });
    }
  }

  useEffect(() => {
    if (participantsLoader === false) {
      setUsers(calculatedUsers);
      setTimeout(() => {
        setLoaderLocal(false);
      }, 1000);
    }
    if (participantsLoader === true) {
      setLoaderLocal(null);
      setUsers([{ imageUrl: null, display: `Loading participants...`, id: '1122' }]);
    }
  }, [activeContribution, participantsLoader]);

  // TODO move to redux
  const [draftPost, setDraftPost] = useState(null);
  const [bubbleIsMarked, setBubbleIsMarked] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [editPostPending, setEditPostPending] = useState(false);
  const dispatch = useDispatch();
  const { currentRole } = useAccount();
  const timer = useRef(null);
  const formRef = useRef(null);
  const isClient = currentRole === UserRoles.client;

  const isCoach = currentRole == 'Cohealer';
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  const type = contribution?.type;
  const create_Post = true;
  // const shouldChangeColor = type != ContributionType.contributionOneToOne;
  const btnColor = colorToUse?.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const [image_video_Limit, setImageVideo_Limit] = useState(false);
  const [isHashtagModalOpen, setIsHashtagModalOpen] = useState(false);
  const [showApprovalpopup, setShowApprovalpopup] = useState(false);
  const closeHashtagModal = () => {
    setIsHashtagModalOpen(false);
  };

  const handleClick = () => {
    if (datetimeRef.current) {
      datetimeRef.current.click();
    }
  };

  const handledateandtimeChange = newValue => {
    setDateandtimevalue(newValue);
    setIsScheduled(true);
  };

  const createDraftPost = (value = '', isCreateDraft = false) => {
    if (isNull(draftPost) || isCreateDraft) {
      let mentionedUserIds;
      if (!_.isEmpty(userMentions)) {
        if (userMentions.some(e => e.id === 'channelId')) {
          mentionedUserIds = _.uniq([
            ...contribution?.participants.map(x => x.id),
            ...(contribution?.contributionPartners && contribution?.contributionPartners.map(x => x.userId)),
            ...pods.flatMap(podItem => podItem.clientIds),
            contribution?.userId,
          ]);
        } else {
          mentionedUserIds = _.uniq([
            ...userMentions.filter(x => !x.id.includes(podIdPrefix)).map(x => x.id),
            ...userMentions
              .filter(x => x.id.includes(podIdPrefix))
              .flatMap(x =>
                pods.filter(pod => `${podIdPrefix}${pod.id}` === x.id).flatMap(podItem => podItem.clientIds),
              ),
          ]).filter(x => !_.isEmpty(x));
        }
      }
      post({
        contributionId: contribution?.id,
        text: value,
        isScheduled,
        scheduledTime: dateandtimevalue,
        isDraft: true,
        isPrivate,
        attachments,
        hashTags: typedHashTags,
        taggedUserIds: mentionedUserIds,
        isApprovalRequired: isCoach ? false : contribution?.isPostApprovalRequired,
        groupId: !_.isEmpty(currentGroup) && currentGroup.orderNo !== -1 ? currentGroup.id : null,
      })
        .then(data => {
          setDraftPost(data);
          setIsPrivate(data.isDraft);
          setIsScheduled(false);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (item?.isDraft || isEdit) {
      setDraftPost(item);
      setBubbleIsMarked(item?.isBubbled);
      setAttachments(item?.attachments);
    } else {
      createDraftPost();
      setDraftPost(null);
      setBubbleIsMarked(false);
      setAttachments([]);
    }
    getHashtags(contribution?.id).then(res => {
      sethashTags(res);
    });
  }, [item]);
  const handlePostCreate = (e, { resetForm }) => {
    const { hashTags } = e;
    setEditPostPending(true);
    const currentItem = item || draftPost;
    clearTimeout(timer.current);
    let mentionedUserIds;
    if (!_.isEmpty(userMentions)) {
      if (userMentions.some(e => e.id === 'channelId')) {
        mentionedUserIds = _.uniq([
          ...contribution?.participants?.map(x => x.id),
          ...(contribution?.contributionPartners && contribution?.contributionPartners.map(x => x.userId)),
          ...pods.flatMap(podItem => podItem.clientIds),
          contribution?.userId,
        ]);
      } else {
        mentionedUserIds = _.uniq([
          ...userMentions.filter(x => !x.id.includes(podIdPrefix)).map(x => x.id),
          ...userMentions
            .filter(x => x.id.includes(podIdPrefix))
            .flatMap(x => pods.filter(pod => `${podIdPrefix}${pod.id}` === x.id).flatMap(podItem => podItem.clientIds)),
        ]).filter(x => !_.isEmpty(x));
      }
    }
    editPost({
      ...currentItem,
      text: textareaValue,
      isBubbled: bubbleIsMarked,
      isDraft: false,
      isScheduled,
      scheduledTime: dateandtimevalue,
      isPrivate,
      userId: user.id,
      hashTags: typedHashTags,
      isApprovalRequired: isCoach ? false : isEditing ? false : contribution?.isPostApprovalRequired,
      attachments,
      groupId: !_.isEmpty(currentGroup) && currentGroup.orderNo !== -1 ? currentGroup.id : null,
      taggedUserIds: mentionedUserIds,
    })
      .then(async res => {
        res = res;

        // dispatch(
        //   actions.getAllPostsForContribution(
        //     `${contribution?.id}/1/${COUNT_POSTS_BY_PAGE}${!_.isEmpty(currentGroup) && currentGroup?.orderNo !== -1 ? `?groupId=${currentGroup.id}` : ''
        //     }`,
        //   ),
        // );
        resetForm({});
        if ((contribution?.isPostApprovalRequired && isClient) === false || isEditing === true) {
          fetchPosts();

          setDraftPost(null);
          setEditPostPending(false);
          onSubmit(false);
          setIsPrivate(false);
          // createDraftPost('', true);
          setAttachments([]);
          setEditPostPending(false);
          setIsScheduled(false);
          setTextareaValue('');
          setResetUserMentionInputValue(true);
        }
        if (contribution?.isPostApprovalRequired && isClient && isEditing === false) {
          setShowApprovalpopup(true);
        }
      })
      .then(() =>
        setPostsAsRead({
          contributionId: !_.isEmpty(currentGroup) && currentGroup.orderNo !== -1 ? currentGroup.id : contribution?.id,
          userId: user.id,
        }),
      )
      .catch(err => {
        console.log(err);
        setEditPostPending(false);
      });
    if (!_.isEmpty(userMentions)) {
      let res;
      let mentionedUserIds;

      if (userMentions.some(e => e.id === 'channelId')) {
        mentionedUserIds = _.uniq([
          ...contribution?.participants.map(x => x.id),
          ...(contribution?.contributionPartners && contribution?.contributionPartners?.map(x => x.userId)),
          ...pods.flatMap(podItem => podItem.clientIds),
          contribution?.userId,
        ]);
      } else {
        mentionedUserIds = _.uniq([
          ...userMentions.filter(x => !x.id.includes(podIdPrefix)).map(x => x.id),
          ...userMentions
            .filter(x => x.id.includes(podIdPrefix))
            .flatMap(x => pods.filter(pod => `${podIdPrefix}${pod.id}` === x.id).flatMap(podItem => podItem.clientIds)),
        ]).filter(x => !_.isEmpty(x));
      }
      notifyTaggedUsers({
        mentionAuthorUserName: `${user?.firstName} ${user?.lastName}`,
        message: textareaValue,
        contributionName: contribution?.title,
        mentionedUserIds: mentionedUserIds,
        replyLink: `https://${domain}/contribution-view/${communityPosts?.contributionId}/community/social/${
          communityPosts?.id || currentItem?.id
        }`,
        AuthorUserId: user?.id,
        ContributionId: communityPosts?.contributionId || contribution?.id,
        PostId: communityPosts?.id || currentItem?.id,
      });
    }
  };
  const handleKeyUp = useCallback(
    (plainText, mentions) => {
      if (participantsLoader === true) {
        setTimeout(() => {
          let hash = plainText.match(/#[^\s]+/g);
          let filteredMentions = mentions.filter(obj => obj.id !== obj.display);
          settypedHashTags(hash);
          if (!isEdit && !loading) {
            clearTimeout(timer.current);
            timer.current = setTimeout(function () {
              editPost({
                ...draftPost,
                text: plainText,
                isBubbled: bubbleIsMarked,
                isDraft: true,
                hashTags: typedHashTags,
                isApprovalRequired: isCoach ? false : contribution?.isPostApprovalRequired,
                isPrivate,
                groupId: !_.isEmpty(currentGroup) && currentGroup.orderNo !== -1 ? currentGroup.id : null,
                attachments,
              }).then(() => {});
            }, DAILY_BY_SEND_DATA);
          }
          setTextareaValue(plainText);
          setUserMentions(filteredMentions);

          setResetUserMentionInputValue(false);
        }, 1000);
      } else {
        let hash = plainText.match(/#[^\s]+/g);
        let filteredMentions = mentions.filter(obj => obj.id !== obj.display);
        settypedHashTags(hash);
        if (!isEdit && !loading) {
          clearTimeout(timer.current);
          timer.current = setTimeout(function () {
            editPost({
              ...draftPost,
              text: plainText,
              isBubbled: bubbleIsMarked,
              isDraft: true,
              hashTags: typedHashTags,
              isApprovalRequired: isCoach ? false : contribution?.isPostApprovalRequired,
              isPrivate,
              groupId: !_.isEmpty(currentGroup) && currentGroup.orderNo !== -1 ? currentGroup.id : null,
              attachments,
            }).then(() => {});
          }, DAILY_BY_SEND_DATA);
        }
        setTextareaValue(plainText);
        setUserMentions(filteredMentions);

        setResetUserMentionInputValue(false);
      }
    },
    [draftPost, attachments, loading, participantsLoader],
  );

  const handleToggleBubble = () => {
    if (isEdit) {
      setDraftPost({
        ...draftPost,
        isBubbled: !bubbleIsMarked,
      });
      setBubbleIsMarked(!bubbleIsMarked);
    } else {
      setEditPostPending(true);
      editPost({
        ...draftPost,
        isBubbled: !bubbleIsMarked,
        isApprovalRequired: isCoach ? false : contribution?.isPostApprovalRequired,
        userId: user.id,
        groupId: !_.isEmpty(currentGroup) && currentGroup.orderNo !== -1 ? currentGroup.id : null,
        attachments,
      })
        .then(data => {
          setDraftPost(data);
          setBubbleIsMarked(data.isBubbled);
          setEditPostPending(false);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  /**
   * Max size limit on backend is 5GB
   */
  const MAX_FILES_SIZE_IN_BYTES = 5368706371;

  const isFilesSizeLimitOrLess = R.compose(sum => sum <= MAX_FILES_SIZE_IN_BYTES, R.sum, R.map(R.prop('size')));

  const handleIsFileLoad = () => {
    setIsFileUpload(false);
    setProgress(null);
  };

  const handleUpdateProgress = (partNumber, totalParts) => progressData => {
    const percentPerChunk = 100 / totalParts;
    setProgress((progressData / 100) * percentPerChunk + percentPerChunk * (partNumber - 1));
  };

  const guid = () => {
    var d = new Date().getTime();
    var guid = 'xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
    return guid;
  };

  const handleUploadFile = useCallback(
    async ({ target: { files } }) => {
      const chunkSize = 26214400;

      if (isFilesSizeLimitOrLess(files)) {
        const newUploadedFiles = [...attachments, ...files];

        const maxImages = 5; // Maximum number of images that can be selected
        const maxVideos = 1; // Maximum number of videos that can be selected
        let selectedImages = 0;
        let selectedVideos = 0;

        for (let j = 0; j < newUploadedFiles.length; j++) {
          const temp_file = newUploadedFiles[j];
          if (temp_file?.type?.startsWith('image/')) {
            selectedImages++;
          } else if (temp_file?.type?.startsWith('video/')) {
            selectedVideos++;
          }
        }

        if (selectedImages > maxImages || selectedVideos > maxVideos || (selectedImages >= 1 && selectedVideos >= 1)) {
          selectedImages = 0;
          selectedVideos = 0;
          setImageVideo_Limit(true);
          return;
        }

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const documentId = guid();
          const fileName = file.name;
          const fileType = file.type;
          const isCommentType = isEdit;
          let uploadId = '';
          let prevETags = '';
          let partNumber = 1;

          for (let start = 0; start < file.size; start += chunkSize) {
            const isLastPart = start + chunkSize >= file.size;
            const chunk = file.slice(start, start + chunkSize);
            const totalParts = Math.ceil(file.size / chunkSize);
            const result = await uploadPartFile(
              chunk,
              draftPost?.id,
              partNumber,
              totalParts,
              isLastPart,
              documentId,
              fileName,
              fileType,
              uploadId,
              prevETags,
              isCommentType,
            );
            if (result) {
              if (isLastPart && formRef.current) {
                if (isCommentType) {
                  const attachs = [...attachments, result[0]];
                  setAttachments(attachs);
                  formRef.current.setFieldValue('attachments', attachs);
                } else {
                  setAttachments(result.attachments);
                  formRef.current.setFieldValue('attachments', result.attachments);
                }
              }
              uploadId = result.uploadId;
              prevETags = result.prevETags;
            }
            partNumber++;
          }
        }
      }
      if (attachmentRef.current) {
        attachmentRef.current.value = null;
      }
    },
    [dispatch, request, draftPost, attachments],
  );
  //   ({ target: { files } }) => {
  //     if (files.length >= 1) {
  //       const formData = new FormData();
  //       formData.append('file', R.head(files));
  //       formData.append('postId', draftPost?.id);
  //       formData.append('fileName', files[0].name);
  //       request('/Post/Attachment', 'POST', formData, {
  //         'Content-Type': 'multipart/form-data',
  //       })
  //         .then(data => {
  //           setAttachments(data.attachments);
  //         })
  //         .catch(err => {
  //           console.log(err);
  //         });
  //     }
  //   },
  //   [dispatch, request, draftPost, attachments],
  // );

  const uploadPartFile = (
    chunk,
    postId,
    partNumber,
    totalParts,
    isLastPart,
    documentId,
    fileName,
    fileType,
    uploadId,
    prevETags,
    isCommentType,
  ) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', chunk);
      setIsFileUpload(true);
      contentService
        .addFileToPost(
          formData,
          postId,
          partNumber,
          isLastPart,
          documentId,
          fileName,
          fileType,
          uploadId,
          prevETags,
          isCommentType,
          handleUpdateProgress(partNumber, totalParts),
        )
        .then(data => {
          resolve(data);
        })
        .then(() => {
          if (isLastPart) {
            handleIsFileLoad();
          }
        })
        .catch(data => {
          handleIsFileLoad();
          console.dir(data);
          reject(data);
        });
    });

  const handleRemoveAttachment = index => {
    const linkId = attachments[index].id;
    const newAttachments = filter(attachments, attachment => attachment.id !== linkId);
    const isAttachmentPublished = item?.attachments?.some(attachment => attachment.id === linkId);
    // if (!isAttachmentPublished) {
    //   if (formRef.current) {
    //     setAttachments(newAttachments);
    //     formRef.current.setFieldValue('attachments', newAttachments);
    //   }
    // } else {
    removeAttachment(`${draftPost?.id}/${linkId}`)
      .then(() => {
        if (formRef.current) {
          setAttachments(newAttachments);
          formRef.current.setFieldValue('attachments', newAttachments);
        }
      })
      .catch(err => {
        console.log(err);
      });
    // }
  };

  const handleSubmitMyForm = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleTogglePrivate = () => {
    if (!isEdit) {
      setEditPostPending(true);
      editPost({
        ...draftPost,
        text: textareaValue,
        isBubbled: bubbleIsMarked,
        isApprovalRequired: isCoach ? false : contribution?.isPostApprovalRequired,
        isDraft: true,
        isPrivate: !isPrivate,
        userId: user.id,
        groupId: !_.isEmpty(currentGroup) && currentGroup.orderNo !== -1 ? currentGroup.id : null,
        attachments,
      }).then(() => {
        setEditPostPending(false);
      });
    }
    setIsPrivate(!isPrivate);
  };

  if (
    !isUnifiedCommunity &&
    !contribution?.arePublicPostsAllowed &&
    !currentGroup?.enableClientPosting &&
    currentGroup != null &&
    isClient
  ) {
    return false;
  }

  if (!isUnifiedCommunity && !currentGroup?.enableClientPosting && currentGroup != null && isClient) {
    return false;
  }

  if (!isUnifiedCommunity && currentGroup?.isArchived) {
    return (
      <div className="text-center w-100 p-4">
        <h6 className="mb-0">This group has been archived</h6>
      </div>
    );
  }

  return (
    <>
      <div className="create-post-root">
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            text: draftPost?.text || '',
            // hashTags: draftPost?.hashTags || [],
            hashTags: isEdit ? item?.hashTags : [],
          }}
          validateOnMount
          onSubmit={handlePostCreate}
          id="post-create-form"
        >
          {formProps => {
            return (
              <div
                style={isUnifiedCommunity ? {} : { backgroundColor: themedCardBackgroundColor, color: themedColor }}
                className="create-post-root"
              >
                <Grid container className="flex-flow-column">
                  <Grid wrap="nowrap" container className="flex-flow-row" style={{ alignItems: 'start' }}>
                    <Grid wrap="nowrap" container className="flex-flow-row">
                      <Box style={{ padding: '0 12px 0 16px', height: '86px' }} className="d-flex">
                        {currentGroup && currentGroup.orderNo !== -1 && !mobileViewCommunity ? (
                          <div className="community-group-info justify-content-center">
                            {isValidUrl(currentGroup.imageUrl) ? (
                              <img src={currentGroup.imageUrl} alt="group icon" className="group-image" />
                            ) : (
                              <div className="group-image">{currentGroup.imageUrl ?? ''}</div>
                            )}
                            <Tooltip
                              title={currentGroup?.title}
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <span className="group-title">{currentGroup?.title}</span>
                            </Tooltip>
                          </div>
                        ) : (
                          <AvatarComponent
                            alt={`${user?.firstName} ${user?.lastName}`}
                            customMargin="0"
                            src={user?.avatarUrl}
                            mobileView={mobileView}
                            style={{ alignSelf: 'center' }}
                          >
                            {`${user?.firstName && user?.firstName[0]}${user?.lastName && user.lastName[0]}`}
                          </AvatarComponent>
                        )}
                      </Box>
                      {participantsLoader && LoaderLocal === null ? (
                        <Grid
                          className={` ${
                            isDarkThemeEnabled && !isUnifiedCommunity
                              ? `create-post-container-dark-themed `
                              : `create-post-container`
                          } flex-flow-row`}
                          style={{ justifyContent: 'space-between' }}
                          container
                          wrap="wrap"
                        >
                          <div
                            style={{ maxWidth: '75%', position: 'relative' }}
                            className={
                              isDarkThemeEnabled && !isUnifiedCommunity
                                ? 'create-post-mention-input-container-dark-themed'
                                : 'create-post-mention-input-container'
                            }
                          >
                            <UserMentionInput
                              style={{ color: themedColor }}
                              placeholder="What would you like to share?"
                              defaultValue={textareaValue?.trimStart()}
                              users={
                                isUnifiedCommunity
                                  ? []
                                  : [{ imageUrl: null, display: `Loading participants...`, id: '1122' }]
                              }
                              hashTags={hashTags}
                              onInputChange={handleKeyUp}
                              resetInputValue={resetUserMentionInputValue}
                              onResetInputValueCallback={() => setResetUserMentionInputValue(false)}
                            />
                          </div>
                          {/* TODO replace to react-textarea-autosize */}
                          <Box className="create-post-icons-container">
                            {/* <Box className="icon-container">
                            <EmojiObjectsIcon
                              mobileView={mobileView}
                              onClick={handleToggleBubble}
                              style={{ color: `${bubbleIsMarked ? colors.gold : colors.smokedGray}` }}
                              className="smoked-gary-color hoverable"
                            />
                          </Box> */}
                            <Box className="icon-container" disabled={loading}>
                              <label htmlFor={`attach-media${draftPost?.id || item?.id}`}>
                                <input
                                  multiple
                                  disabled={isFileUpload}
                                  accept="image/jpeg,image/png,image/gif,video/mpeg,video/mp4,video/avi,video/webm,video/mkv,video/mov,video/quicktime"
                                  className="d-none"
                                  id={`attach-media${draftPost?.id || item?.id}`}
                                  type="file"
                                  onChange={handleUploadFile}
                                />
                                <StyledPhotoCameraIcon
                                  mobileView={mobileView}
                                  className="smoked-gary-color hoverable"
                                />
                              </label>
                            </Box>
                            <Box className="icon-container" disabled={loading}>
                              <label htmlFor={`attach-file${draftPost?.id || item?.id}`}>
                                <input
                                  ref={attachmentRef}
                                  disabled={isFileUpload}
                                  accept="*"
                                  className="d-none"
                                  id={`attach-file${draftPost?.id || item?.id}`}
                                  type="file"
                                  onChange={handleUploadFile}
                                />
                                {isFileUpload ? (
                                  <StyledProgressWrap position="relative">
                                    <CircularProgressWithLabel value={progress} />
                                  </StyledProgressWrap>
                                ) : (
                                  <AttachFileIcon mobileView={mobileView} className="smoked-gary-color hoverable" />
                                )}
                              </label>
                            </Box>
                          </Box>
                          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                            {formProps?.values?.hashTags?.map((item, i) => (
                              <StyledHashtag
                                backgroundColor={isUnifiedCommunity ? '#116582' : AccentColor}
                                color={isUnifiedCommunity ? 'white' : textColor}
                              >
                                {item}
                              </StyledHashtag>
                            ))}
                          </div>
                        </Grid>
                      ) : (
                        LoaderLocal != null &&
                        participantsLoader === false && (
                          <Grid
                            className={` ${
                              isDarkThemeEnabled && !isUnifiedCommunity
                                ? `create-post-container-dark-themed `
                                : `create-post-container`
                            } flex-flow-row`}
                            style={{ justifyContent: 'space-between' }}
                            container
                            wrap="wrap"
                          >
                            <div
                              style={{ maxWidth: '75%', position: 'relative' }}
                              className={
                                isDarkThemeEnabled && !isUnifiedCommunity
                                  ? 'create-post-mention-input-container-dark-themed'
                                  : 'create-post-mention-input-container'
                              }
                            >
                              {
                                <UserMentionInput
                                  style={{ color: themedColor }}
                                  placeholder="What would you like to share?"
                                  defaultValue={textareaValue?.trimStart()}
                                  users={isUnifiedCommunity ? [] : calculatedUsers}
                                  hashTags={hashTags}
                                  onInputChange={handleKeyUp}
                                  resetInputValue={resetUserMentionInputValue}
                                  onResetInputValueCallback={() => setResetUserMentionInputValue(false)}
                                />
                              }
                            </div>
                            {/* TODO replace to react-textarea-autosize */}
                            <Box className="create-post-icons-container">
                              {/* <Box className="icon-container">
                            <EmojiObjectsIcon
                              mobileView={mobileView}
                              onClick={handleToggleBubble}
                              style={{ color: `${bubbleIsMarked ? colors.gold : colors.smokedGray}` }}
                              className="smoked-gary-color hoverable"
                            />
                          </Box> */}
                              <Box className="icon-container" disabled={loading}>
                                <label htmlFor={`attach-media${draftPost?.id || item?.id}`}>
                                  <input
                                    multiple
                                    disabled={isFileUpload}
                                    accept="image/jpeg,image/png,image/gif,video/mpeg,video/mp4,video/avi,video/webm,video/mkv,video/mov,video/quicktime"
                                    className="d-none"
                                    id={`attach-media${draftPost?.id || item?.id}`}
                                    type="file"
                                    onChange={handleUploadFile}
                                  />
                                  <StyledPhotoCameraIcon
                                    mobileView={mobileView}
                                    className="smoked-gary-color hoverable"
                                  />
                                </label>
                              </Box>
                              <Box className="icon-container" disabled={loading}>
                                <label htmlFor={`attach-file${draftPost?.id || item?.id}`}>
                                  <input
                                    ref={attachmentRef}
                                    disabled={isFileUpload}
                                    accept="*"
                                    className="d-none"
                                    id={`attach-file${draftPost?.id || item?.id}`}
                                    type="file"
                                    onChange={handleUploadFile}
                                  />
                                  {isFileUpload ? (
                                    <StyledProgressWrap position="relative">
                                      <CircularProgressWithLabel value={progress} />
                                    </StyledProgressWrap>
                                  ) : (
                                    <AttachFileIcon mobileView={mobileView} className="smoked-gary-color hoverable" />
                                  )}
                                </label>
                              </Box>
                            </Box>
                            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                              {formProps?.values?.hashTags?.map((item, i) => (
                                <StyledHashtag
                                  backgroundColor={isUnifiedCommunity ? '#116582' : AccentColor}
                                  color={isUnifiedCommunity ? 'white' : textColor}
                                >
                                  {item}
                                </StyledHashtag>
                              ))}
                            </div>
                          </Grid>
                        )
                      )}
                    </Grid>

                    {!isClient ? (
                      isEdit && item.isScheduled ? (
                        <Box
                          className="icon-container"
                          disabled={loading}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '8px',
                            height: '86px',
                          }}
                        >
                          <Box>
                            <DateTimePicker
                              fullWidth
                              name="dateandtime"
                              type="datetime"
                              hidden="true"
                              value={dateandtimevalue}
                              onChange={handledateandtimeChange}
                              inputRef={datetimeRef}
                              minDate={moment().add(0, 'd')}
                            />
                            <EventIcon
                              mobileView={mobileView}
                              className="smoked-gary-color hoverable"
                              onClick={handleClick}
                              style={{ color: `${isScheduled ? colors.gold : colors.smokedGray}` }}
                            />
                          </Box>
                        </Box>
                      ) : !isEdit && !isClient ? (
                        <Box
                          className="icon-container"
                          disabled={loading}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '8px',
                            height: '86px',
                          }}
                        >
                          <Box>
                            <DateTimePicker
                              fullWidth
                              name="dateandtime"
                              type="datetime"
                              hidden="true"
                              value={dateandtimevalue}
                              onChange={handledateandtimeChange}
                              inputRef={datetimeRef}
                              minDate={moment().add(0, 'd')}
                            />
                            <EventIcon
                              mobileView={mobileView}
                              className="smoked-gary-color hoverable"
                              onClick={handleClick}
                              style={{ color: `${isScheduled ? colors.gold : colors.smokedGray}` }}
                            />
                          </Box>
                        </Box>
                      ) : null
                    ) : null}

                    {!isEdit && (
                      <StyledButton
                        disabled={editPostPending || textareaValue?.trimStart().length === 0}
                        autoWidth
                        type="submit"
                        id="post-create-form"
                        onClick={formProps.handleSubmit}
                        style={{
                          backgroundColor: textareaValue?.trimStart().length === 0 ? 'gray' : btnColor,
                          color: textColor,
                        }}
                      >
                        {editPostPending ? (
                          <CircularProgress size={36} />
                        ) : (
                          <SendIcon
                            style={{ color: textColor }}
                            fontSize="small"
                            className="smoked-gary-color hoverable"
                          />
                        )}
                      </StyledButton>
                    )}
                    {formProps.errors.text && formProps.values.text.length > 0 && formProps.dirty && (
                      <StyledTextError mobileView={mobileView}>{formProps.errors.text}</StyledTextError>
                    )}
                  </Grid>
                  {/* <div style={{ width: isEdit ? '95%' : '50%', marginLeft: '20px', marginBottom: "15px" }}>
                    <TagsInput
                      value={selected}
                      onChange={setSelected}
                      name="fruits"
                      placeHolder="Enter HashTags"
                    />
                  </div> */}
                  {textareaValue ? (
                    <Grid container className="mt-1" justify="flex-end" alignItems="baseline">
                      <div>
                        {/* <HashTagButton
                          autoWidth
                          onClick={() => {
                            setIsHashtagModalOpen(true);
                          }}
                          backgroundColor={btnColor}
                          color={textColor}
                        >
                          {isEdit || formProps?.values?.hashTags?.length > 0 ? `Edit Hashtags` : `Add Hashtags`}
                        </HashTagButton> */}
                      </div>
                      {!isClient && (
                        <>
                          <div className="mr-2">
                            <Tooltip
                              title="When you turn off “Members Only” this community post will be public to anyone who has your contribution link. Your clients community posts will only be seen by members."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <span
                                style={{
                                  height: '1.5rem',
                                  width: '1.5rem',
                                  borderRadius: 100,
                                  backgroundColor: isUnifiedCommunity ? '#CDBA8F' : btnColor,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                              >
                                <FontAwesomeSvgIcon
                                  icon={faInfo}
                                  size="lg"
                                  style={
                                    isUnifiedCommunity
                                      ? { height: '0.7rem', color: 'white' }
                                      : { color: textColor, height: '0.7rem' }
                                  }
                                />
                              </span>
                              {/* <InfoIcon htmlColor={btnColor} /> */}
                            </Tooltip>
                          </div>
                          <Switch className="switch">
                            <StyledInput type="checkbox" onClick={handleTogglePrivate} checked={isPrivate} />
                            <StyledSlider
                              style={isUnifiedCommunity ? {} : { backgroundColor: isPrivate ? btnColor : 'gray' }}
                              className="slider round"
                            />
                          </Switch>
                          <div className="ml-2 mr-3">Members Only</div>
                        </>
                      )}
                    </Grid>
                  ) : null}

                  <Attachments
                    pending={loading}
                    attachments={attachments}
                    removeAttachment={handleRemoveAttachment}
                    createPost={create_Post}
                    colorToUse={colorToUse}
                  />
                </Grid>
                <FieldArray name="hashTags">
                  {({ push, remove, form }) => {
                    const pushNewHashtag = newHashtag => {
                      push(newHashtag);
                      setHashtag('');
                    };

                    const removeHashtag = item => {
                      const index = form?.values?.hashTags?.indexOf(item);
                      remove(index);
                    };
                    return (
                      <>
                        {isHashtagModalOpen && (
                          <>
                            <Modal
                              isOpen={isHashtagModalOpen}
                              onCancel={() => {
                                setIsHashtagModalOpen(false);
                              }}
                              submitTitle="Add"
                              onSubmit={() => {
                                if (hashtag?.length > 0) {
                                  pushNewHashtag(hashtag);
                                } else {
                                  closeHashtagModal();
                                }
                              }}
                              cancelTitle="Cancel"
                              title="Add Hashtags"
                              widthRequiredIs
                            >
                              <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <div>
                                  <TextField
                                    className=""
                                    variant="outlined"
                                    placeholder="Write here..."
                                    value={hashtag}
                                    // name="hashTagInput"
                                    onChange={e => {
                                      setHashtag(e.target.value.trim());
                                    }}
                                    onFocus={e => e.stopPropagation()}
                                    InputProps={{
                                      style: { color: themedColor },
                                    }}
                                    fullWidth
                                  />
                                </div>
                                <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>
                                  {form?.values?.hashTags?.map((item, i) => (
                                    <StyledHashtag backgroundColor={AccentColor} color={textColor}>
                                      <StyledRemove onClick={() => removeHashtag(item)}>
                                        <StyledCross />
                                      </StyledRemove>
                                      {item}
                                    </StyledHashtag>
                                  ))}
                                </div>
                              </div>
                            </Modal>
                          </>
                        )}
                      </>
                    );
                  }}
                </FieldArray>
              </div>
            );
          }}
        </Formik>
      </div>
      {isEdit && (
        <div className="create-post-edit-button-container" style={isEdit ? { marginBottom: '10px' } : {}}>
          <ButtonProgress
            backgroundColor={isUnifiedCommunity === false && colorToUse.PrimaryColorCode}
            textColor={isUnifiedCommunity === false ? textColor : 'white'}
            disabled={editPostPending || textareaValue?.trimStart().length <= 0}
            pending={editPostPending}
            autoWidth
            type="submit"
            id="post-create-form"
            onClick={handleSubmitMyForm}
          >
            {isEdit && item.isScheduled ? 'Update' : 'Publish'}
          </ButtonProgress>
        </div>
      )}

      {image_video_Limit && (
        <>
          <Modal
            isOpen={image_video_Limit}
            onCancel={() => {
              setImageVideo_Limit(false);
            }}
            widthRequiredIs
            handleOnCancel
            disableConfirm
            cancelTitle="OK"
            title="File Uploading Restrictions"
          >
            <p> You are allowed to upload only 5 images OR 1 video per post. </p>
          </Modal>
        </>
      )}
      {showApprovalpopup && (
        <>
          <Modal
            isOpen={showApprovalpopup}
            onSubmit={() => {
              fetchPosts();
              setDraftPost(null);
              setEditPostPending(false);
              onSubmit(false);
              setIsPrivate(false);
              // createDraftPost('', true);
              setAttachments([]);
              setEditPostPending(false);
              setIsScheduled(false);
              setTextareaValue('');
              setResetUserMentionInputValue(true);
              setShowApprovalpopup(false);
            }}
            handleOnCancel
            cancelTitle="OK"
            hiddenCancel={true}
            title="Your post is awaiting approval"
            widthRequired={'90%'}
          >
            <p>
              {`Thank you for your post! To ensure the quality of our community, all posts must undergo a brief review process. Once approved by ${contribution.serviceProviderName}, you'll receive an email notifying you of its status. We appreciate your patience!`}{' '}
            </p>
          </Modal>
        </>
      )}
    </>
  );
};
CreatePost.defaultProps = {
  onSubmit: () => {},
  isEdit: false,
};

const mapStateToProps = ({ contributions, communitySections: { currentGroup } }) => ({
  brandingColors: contributions?.brandingColors,
  currentGroup,
});
export default connect(mapStateToProps)(CreatePost);
