import React, { useState } from 'react';
import classes from './PublishModal.module.scss';
import Modal from 'components/UI/Modal';
import useQuiz from 'hooks/useQuiz';
import styled from 'styled-components';
import copyToClipboard from 'utils/copyToClipboard';
import { Tooltip } from '@material-ui/core';
import Button from 'components/FormUI/Button';
import useScreenView from 'hooks/useScreenView';

const StyledHostName = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  color: #4a4a4a;
  width: ${({ mobileView }) => (mobileView ? '100%' : '600px')};
  overflow: hidden;
  border: 1px solid #dfe3e4;
  display: flex;
  align-items: center;
  margin-right: 20px;
  border-radius: 4px;
  padding: 0px 10px;
`;

const PublishModal = ({ isOpen, onSubmit, onCancel }) => {
  const { activeQuiz } = useQuiz();
  const { mobileView } = useScreenView();
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const originText = window.location.origin;

  return (
    <Modal
      isOpen={isOpen}
      onSubmit={onSubmit}
      title={`Your ${activeQuiz?.type === 'Quiz' ? 'quiz' : 'type'} has been published!`}
      disableConfirm={true}
      cancelTitle={'Close'}
      onCancel={onCancel}
      modalType={'Quizes'}
      disableFooter={true}
      maxWidth={'900px'}
    >
      {/* <div
        style={{
          fontWeight: '800',
          fontSize: '18px',
          fontFamily: 'Avenir',
          color: '#000000',
          marginBottom: '10px',
        }}
      >
        Here’s what you can do next:
      </div> */}
      <div
        style={{ fontWeight: '800', fontSize: '16px', fontFamily: 'Avenir', color: '#1E1E1E', marginBottom: '10px' }}
      >
        Copy Shareable Link
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <StyledHostName mobileView={mobileView}>{`${originText}/quiz-view/${activeQuiz.id}`}</StyledHostName>
        <Tooltip
          className="cursor-pointer"
          title="Link Copied!"
          open={isToolTipOpen}
          onClose={() => {
            setIsToolTipOpen(false);
          }}
          arrow
        >
          <Button
            onClick={() => {
              setIsToolTipOpen(true);
              copyToClipboard(`${originText}/quiz-view/${activeQuiz.id}`);
            }}
            textColor="#fff"
            autoWidth
          >
            Copy
          </Button>
        </Tooltip>
      </div>
    </Modal>
  );
};

export default PublishModal;
