import React from 'react';
import classes from './RankingTag.module.scss';
import DropdownIcon from 'pages/CreateQuizes/Pages/QuizContent/Assests/Icons/DropdownIcon';

const RankingTag = ({ number }) => {
  return (
    <div className={classes.ranking_tag_container}>
      <div className={classes.number}>{number}</div>
      <DropdownIcon />
    </div>
  );
};

export default RankingTag;
