import React, { useEffect } from 'react';
import {
  useActiveContributionSessionById,
  useActiveContributionSessionTimeOrContentById,
} from 'pages/ContributionView/hooks';
import SelfPacedSessionBody from './SelfPacedComponents/SelfPacedSessionBody';
import LiveSessionBody from './LiveSessionComponents/LiveSessionBody';
import { useSelector } from 'react-redux';
import { activeContribSessTabViewSettingsSelector } from 'selectors/contribution';

const SessionBody = ({ convertedToSelfPaced, rightColCollapsed = false, selectNextSession }) => {
  const { selectedSession, selectedSessionIndex } = useActiveContributionSessionById();
  const {
    selectedSessionTimeOrContent,
    selectedSessionTimeOrContentIndex,
  } = useActiveContributionSessionTimeOrContentById();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedSession, selectedSessionIndex, selectedSessionTimeOrContent]);

  return (
    <>
      {selectedSession.isPrerecorded ? (
        <SelfPacedSessionBody
          session={selectedSession}
          sessionTime={selectedSessionTimeOrContent}
          sessionIndex={selectedSessionIndex}
          sessionTimeIndex={selectedSessionTimeOrContentIndex}
          {...{ rightColCollapsed, selectNextSession }}
        />
      ) : (
        <LiveSessionBody convertedToSelfPaced={convertedToSelfPaced} index={selectedSessionTimeOrContentIndex} />
      )}
    </>
  );
};

export default SessionBody;
