import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTheme, useMediaQuery, OutlinedInput, Select, MenuItem } from '@material-ui/core';
import { ProfileAvatar } from 'components/ProfileAvatar/ProfileAvatar';
import * as contributionActions from 'actions/contributions';
import { TextField, Tooltip } from '@material-ui/core';
import { dataURLtoFile } from 'utils/utils';
import bannerplaceholder from 'assets/bannerplaceholder.svg';
import bannerplaceholdernew from 'assets/bannerplacholdenew.png';
import '../Profile/profilepage.scss';
import { UploadImageIcon } from '../Profile/components/icons/UploadImage';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import UploadIcon from '@mui/icons-material/Upload';
import Button from 'components/FormUI/Button';
import { PageTitleSecond, BodyOrLeftText } from 'components/UI/Text/TextStyles';
import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { useRouter, useHttp, useAccount } from 'hooks';
import { StyledButtonContainer, HiddenFileInput } from './style';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import EasyCropper from 'components/ImageCropper/Cropper/EasyCropper';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ReactPlayer from 'react-player';
import { openInNewWindow } from 'services/links';

const MIN_STRING_LENGTH = 3;
const MAX_STRING_LENGTH = 100;
const BIO_MAX_LENGTH = 1000;

function CreateThankYouPage({ user, changeView, setThankYouPageModel, thankYouPageModel, backClicked }) {
  const { request } = useHttp();
  const theme = useTheme();
  const contribution = useContribution();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [tagline, setTagline] = useState(contribution?.thankyouPage?.tagline || 'WELCOME IN!');
  const [redirectToOption, setredirectToOption] = useState(
    contribution?.thankyouPage?.thankyouPageRedirectTo || 'Sessions',
  );
  const [description, setDescription] = useState(
    contribution?.thankyouPage?.description ||
      'We’re excited to have you confirmed! Please check your email inbox for calendar invites for any upcoming LIVE sessions and click the button below to see what’s coming up!',
  );
  const [buttonTitle, setButtonTitle] = useState(
    contribution?.thankyouPage?.contributionRedirectText || `Access ${contribution?.title}`,
  );
  const [profilePicture, setProfilePicture] = useState(contribution?.thankyouPage?.profileImage || user?.avatarUrl);
  const [bannerImage, setBannerImage] = useState(
    contribution?.thankyouPage?.bannerImage || user?.profilePageViewModel?.primaryBannerUrl,
  );
  const [isVideoBanner, setIsVideoBanner] = useState(
    contribution?.thankyouPage?.isVideoBanner || user?.profilePageViewModel?.isPrimaryBannerVideo,
  );

  useEffect(() => {
    if (contribution?.thankyouPage === null) {
      setThankYouPageModel({
        ...thankYouPageModel,
        bannerImage: bannerImage,
        isVideoBanner: user?.profilePageViewModel?.isPrimaryBannerVideo,
        description: description,
        tagline: tagline,
        contributionRedirectText: buttonTitle,
      });
      setIsVideoBanner(user?.profilePageViewModel?.isPrimaryBannerVideo);
    } else {
      setIsVideoBanner(contribution?.thankyouPage?.isVideoBanner);
    }
  }, []);

  const [fileUploadError, setfileUploadError] = useState(false);
  const [imageToCrop, setImageToCrop] = useState('');
  const [isPrimaryOpen, setIsPrimaryOpen] = useState(false);
  const [isBioOpen, setIsBioOpen] = useState(false);
  const bioFileInput = React.createRef();
  const { query, history } = useRouter();
  const isEditing = Boolean(query.id);
  const uploadFileHandler = (imageName, droppedFiles = []) => e => {
    setfileUploadError(false);
    let files;
    if (droppedFiles.length > 0) {
      files = droppedFiles;
    } else if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (!files || !files[0]) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      if (files[0].type.includes('video/')) {
        const formData = new FormData();
        const imageSrc = reader.result;

        formData.append('file', dataURLtoFile(imageSrc));
        request('/content/AddPublicFile', 'POST', formData, {
          'Content-Type': 'multipart/form-data',
        })
          .then(video => {
            setBannerImage(video);
            setIsVideoBanner(true);
            setThankYouPageModel({
              ...thankYouPageModel,
              bannerImage: video,
              isVideoBanner: true,
              description: description,
              tagline: tagline,
              contributionRedirectText: buttonTitle,
            });
            // setFieldValues('profilePageViewModel', {
            //   ...profilePageViewModel,
            //   primaryBannerUrl: video,
            //   isPrimaryBannerVideo: true,
            // });
          })
          .catch(err => {
            setfileUploadError(true);
          });
        return;
      }
      setImageToCrop(reader.result?.toString() || '');
      if (imageName === 'bio') setIsBioOpen(true);
      if (imageName === 'primary') setIsPrimaryOpen(true);
    };
    reader.readAsDataURL(files[0]);
    setfileUploadError(false);
  };
  const onBioBannerUploadClick = useCallback(() => {
    if (bioFileInput) {
      bioFileInput.current.click();
    }
  }, [bioFileInput]);

  const profilePageViewModel = user.profilePageViewModel || {};
  const bioBannerUrl = bannerImage || '';
  return (
    <StyledMainSection mobileView={mobileView}>
      <Formik
        // innerRef={formRef}
        initialValues={{
          tagline: tagline,
          description: description,
          buttonTitle: buttonTitle,
          thankyouPageRedirectTo: redirectToOption,
        }}
        validationSchema={Yup.object().shape({
          tagline: Yup.string()
            .strict(true)
            .min(MIN_STRING_LENGTH)
            .max(MAX_STRING_LENGTH)
            .required('Tagline is a required field'),
          description: Yup.string()
            .strict(true)
            .min(MIN_STRING_LENGTH)
            .max(BIO_MAX_LENGTH)
            .required('Description is a required field'),
          buttonTitle: Yup.string().strict(true).max(50).required('Button Title is a required field'),
        })}
        onSubmit={changeView}
      >
        {({ setFieldValue, handleChange, values, errors, touched }) => {
          return (
            <>
              <Form>
                <Card mobileView={mobileView}>
                  <CardHeader mobileView={mobileView}>
                    <PageTitleSecond mobileView={mobileView}>
                      {contribution.thankyouPage === null || contribution.isThankyouPageAdded === false
                        ? `Create Thank You Page`
                        : `Edit Thank You Page`}
                    </PageTitleSecond>
                  </CardHeader>
                  <CardBody>
                    <Grid container className="profile-section">
                      <Grid item xs={12} sm={4} md={12} lg={4} xl={2}>
                        {/* <p
                          style={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: '500',
                            lineHeight: '16px',
                            color: '#47627B',
                            marginTop: '10px',
                          }}
                          className="field-title"
                        >
                          Profile Picture
                        </p> 
                          <Grid
                          style={{ backgroundColor: '#F7F9FA', padding: '10px' }}
                          item
                          xs={12}
                          sm={4}
                          md={12}
                          lg={8}
                          xl={10}
                          className="profile-picture"
                        >
                        <ProfileAvatar
                            updateUserImg={res => {
                              setProfilePicture(res);
                              setThankYouPageModel({
                                ...thankYouPageModel,
                                profileImage: res,
                              });
                            }}
                            avatarUrl={profilePicture}
                            isCoach
                            contribution
                            thankyoupage
                          />
                        </Grid> */}
                        <p style={{ color: '#47627B' }} className="field-title">
                          Banner
                        </p>
                        <Grid
                          style={{ backgroundColor: '#F7F9FA', padding: '10px' }}
                          item
                          xs={12}
                          sm={4}
                          md={12}
                          lg={10}
                          xl={10}
                          className="profile-picture"
                        >
                          <div
                            className="banner-avatar-container "
                            style={{
                              position: 'relative',
                            }}
                          >
                            {isVideoBanner ? (
                              <ReactPlayer
                                muted
                                url={bioBannerUrl || bannerplaceholdernew}
                                playing
                                width="100%"
                                height="100%"
                                loop
                              />
                            ) : (
                              <img
                                alt="banner"
                                style={{ width: '100%', height: '100%' }}
                                src={bioBannerUrl || bannerplaceholdernew}
                              />
                            )}

                            <span
                              style={{
                                position: 'absolute',
                                float: 'right',
                                right: '12px',
                                bottom: '55px',
                                borderRadius: '48px',
                                padding: '8px',
                                display: 'flex',
                                background: '#cdba8f',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              onClick={onBioBannerUploadClick}
                              onKeyUp={onBioBannerUploadClick}
                              role="button"
                              tabIndex="0"
                            >
                              <UploadIcon style={{ color: 'white' }} />
                              {/* <UploadImageIcon /> */}
                            </span>
                            <HiddenFileInput
                              onChange={uploadFileHandler('bio')}
                              ref={bioFileInput}
                              accept="image/jpeg,image/png,image/gif,video/*,video/mpeg,video/mp4,video/avi,video/webm,video/mkv,video/mov,video/quicktime"
                            />
                            <p
                              style={{
                                marginTop: '10px',
                                marginBottom: '0',
                                fontFamily: 'Inter',
                                // fontStyle: 'normal',
                                // fontWeight: '400',
                                fontSize: '12px',
                                lineHeight: '15px',
                                textAlign: 'center',
                                display: 'block',
                                color: '#797a7b',
                              }}
                            >
                              Photo/Video should be approx. 812x320px and up to 5 mb.
                            </p>
                          </div>{' '}
                        </Grid>
                        {fileUploadError && (
                          <p
                            style={{
                              marginTop: '10px',
                              marginBottom: '0',
                              fontSize: '11px',
                              lineHeight: '15px',
                              display: 'block',
                              color: '#797a7b',
                              width: '90%',
                              color: 'red',
                            }}
                          >
                            Selected file size is greater than 5 mb.
                          </p>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={8} md={12} lg={8} xl={10}>
                        <Grid container>
                          <Grid item sm={12} md={12} xs={12}>
                            <p style={{ color: '#47627B' }} className="field-title">
                              Thank You Page Title
                            </p>
                            <TextField
                              name="tagline"
                              className="input-field"
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              value={tagline}
                              error={Boolean(errors.tagline)}
                              helperText={errors.tagline}
                              FormHelperTextProps={{
                                style: {
                                  textAlign: 'right',
                                  marginRight: 0,
                                },
                              }}
                              onChange={e => {
                                setFieldValue('tagline', e.target.value);
                                setTagline(e.target.value);
                                setThankYouPageModel({
                                  ...thankYouPageModel,
                                  isVideoBanner: thankYouPageModel?.isVideoBanner,
                                  tagline: e.target.value,
                                });
                              }}
                              inputProps={{ maxLength: 100 }}
                              helperText={
                                values?.tagline?.length === undefined ? `0/100` : `${values?.tagline?.length}/100`
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item sm={12} md={12} xs={12}>
                            <p style={{ color: '#47627B' }} className="field-title">
                              Description
                            </p>
                            <TextField
                              multiline
                              rows={10}
                              className="input-field"
                              id="outlined-basic"
                              variant="outlined"
                              name="description"
                              fullWidth
                              value={description}
                              onChange={e => {
                                setFieldValue('description', e.target.value);
                                setDescription(e.target.value);
                                setThankYouPageModel({
                                  ...thankYouPageModel,
                                  description: e.target.value,
                                });
                              }}
                              error={Boolean(errors.description)}
                              inputProps={{ maxLength: 1000 }}
                              helperText={
                                values?.description?.length === undefined
                                  ? `0/1000`
                                  : `${values?.description?.length}/1000`
                              }
                              FormHelperTextProps={{
                                style: {
                                  textAlign: 'right',
                                  marginRight: 0,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid style={{ display: 'flex' }} item spacing={12}>
                          <Grid item sm={12} md={6} xs={12}>
                            <p style={{ color: '#47627B' }} className="field-title">
                              Contribution Redirect Button Text
                            </p>
                            <TextField
                              name="buttonTitle"
                              className="input-field"
                              id="outlined-basic"
                              variant="outlined"
                              fullWidth
                              value={buttonTitle}
                              onChange={e => {
                                setFieldValue('buttonTitle', e.target.value);
                                setButtonTitle(e.target.value);
                                setThankYouPageModel({
                                  ...thankYouPageModel,
                                  isVideoBanner: thankYouPageModel?.isVideoBanner,
                                  contributionRedirectText: e.target.value,
                                });
                              }}
                              inputProps={{ maxLength: 50 }}
                              helperText={
                                values?.buttonTitle?.length === undefined ? `0/50` : `${values?.buttonTitle?.length}/50`
                              }
                              error={Boolean(errors.buttonTitle)}
                              FormHelperTextProps={{
                                style: {
                                  textAlign: 'right',
                                  marginRight: 0,
                                },
                              }}
                            />
                          </Grid>
                          <Grid style={{ marginLeft: '15px' }} item sm={12} md={6} xs={12}>
                            <p style={{ color: '#47627B' }} className="field-title">
                              Redirect Client To
                            </p>
                            <Select
                              variant="outlined"
                              input={<OutlinedInput margin="dense" />}
                              className="chats-list-header-toolbar__date-filter"
                              style={{ minWidth: '100%', height: '3.5em' }}
                              value={redirectToOption}
                              onChange={e => {
                                setFieldValue('thankyouPageRedirectTo', e.target.value);
                                setredirectToOption(e.target.value);
                                setThankYouPageModel({
                                  ...thankYouPageModel,
                                  thankyouPageRedirectTo: e.target.value,
                                });
                              }}
                            >
                              <MenuItem value="Sessions">Sessions</MenuItem>
                              {contribution?.isCommunityFeatureHiddden === false && (
                                <MenuItem value="Community">Community</MenuItem>
                              )}
                              <MenuItem value="About">About</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <EasyCropper
                      title={`Crop Your ${isBioOpen ? 'Bio Banner' : 'Primary Banner'} Image`}
                      submitTitle="Set Image"
                      imageToCrop={imageToCrop}
                      isOpen={isBioOpen || isPrimaryOpen}
                      onClose={() => {
                        setIsBioOpen(false);
                        setIsPrimaryOpen(false);
                        setImageToCrop('');
                      }}
                      setFieldValue={(a, b) => {
                        setBannerImage(b);
                        setIsVideoBanner(false);
                        setThankYouPageModel({
                          ...thankYouPageModel,
                          bannerImage: b,
                          isVideoBanner: false,
                          description: description,
                          tagline: tagline,
                          contributionRedirectText: buttonTitle,
                        });
                      }}
                      field="file"
                      shape="rectangle"
                    />
                  </CardBody>
                </Card>

                <StyledButtonContainer>
                  <Button onClick={backClicked} invert autoWidth type="button">
                    Back
                  </Button>
                  <div>
                    <Button
                      disabled={
                        contribution.thankyouPage === null || contribution.isThankyouPageAdded === false ? true : false
                      }
                      onClick={() => {
                        const to = query.id
                          ? `/edit-contribution/${query.id}/thankyoupreviewpage`
                          : `/create-contribution/${contribution.id}/thankyoupreviewpage`;
                        openInNewWindow(to);
                      }}
                      style={{ marginRight: '20px' }}
                      invert
                      autoWidth
                      type="button"
                    >
                      Client Preview
                    </Button>
                    <Button type="submit" autoWidth>
                      Save
                    </Button>
                  </div>
                </StyledButtonContainer>
              </Form>
            </>
          );
        }}
      </Formik>
    </StyledMainSection>
  );
}

CreateThankYouPage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  saveContributionToLS: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

CreateThankYouPage.defaultProps = {
  error: null,
};

const mapStateToProps = ({ account, contributions }) => ({
  user: account?.user,
  error: contributions?.error,
  loading: contributions?.loading,
  contribution: contributions.activeContribution,
});

const actions = {
  saveContributionToLS: contributionActions.saveDraftContribution,
};

export default connect(mapStateToProps, actions)(CreateThankYouPage);
