import React, { useCallback, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AddBankAccountPopup from '../../../pages/Account/Payment/components/BankAccountCard/AddBankAccountPopup';
import { useHttp, useAccount } from 'hooks';
import { useTheme, useMediaQuery, Tooltip, TextField } from '@material-ui/core';
import * as userActions from 'actions/user';
import InfoIcon from '@material-ui/icons/Info';
import Button from 'components/FormUI/Button';
import styled from 'styled-components';
import '../Banner.scss';
import { getProfile, editProfile } from 'actions/user';
import Modal from 'components/UI/Modal';
import { makeStyles } from '@material-ui/core';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { openInNewWindow } from 'services/links';
const StyledButton = styled(Button)`
  background-color: #0e536b;
  padding: 6px 14px;
  min-width: 95px;
  width: 95px;
  font-size: 12px;
  font-weight: 700;
`;
const useStyles = makeStyles(() => ({
  email_main_container: {
    width: '100%',
  },
  email_main: {
    width: '100%',
  },
  email_root: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  email_notchedOutline: {
    border: 'none',
  },
}));
const PaymentFailedBanner = () => {
  const { request } = useHttp();
  const { user } = useAccount();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [physicalAddress, setPhysicalAddress] = useState('');
  const BannerButton = mobileView ? StyledButton : Button;
  const classes = useStyles();
  const checkOnClick = () => {
    window.location.href = 'mailto:support@cohere.live';
  };
  return (
    <>
      <div className={`banner-container ${mobileView && 'banner-container__mobile'}`}>
        <p className="banner-container__text">
          Your payment failed. Please contact support to update your payment information and regain access to your Coach
          Dashboard.
        </p>
        <div>
          <BannerButton variant="primary" className="banner-container__button" autoWidth onClick={checkOnClick}>
            Contact Support
          </BannerButton>
        </div>
      </div>
    </>
  );
};
PaymentFailedBanner.propTypes = {
  userId: PropTypes.string,
  getUserProfile: PropTypes.func.isRequired,
};
PaymentFailedBanner.defaultProps = {
  userId: null,
};
const mapStateToProps = ({ account }) => ({
  userId: account?.user?.id,
});
const actions = {
  getUserProfile: userActions.getProfile,
};
export default connect(mapStateToProps, actions)(PaymentFailedBanner);
