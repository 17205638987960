import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ResubCard from 'components/UnsubCards/ResubCard/ResubCard';
import ResubSuccessCard from 'components/UnsubCards/ResubSuccessCard/ResubSuccessCard';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { resubscribeEmailCampaign } from 'pages/CreateCampaign/Services/CreateCampaign.service';
import Loader from 'components/UI/Loader';
import ErrorCard from 'components/UnsubCards/ErrorCard/ErrorCard';
import { EMAIL_SUBSCRIPTION_STATUS } from 'pages/Unsubscribe/Unsubscribe';
const CenterContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  box-sizing: border-box;
  flex-direction: column;
  background-color: #fafafa;
  ${({ viewportHeight }) => viewportHeight && 'min-height: 100vh'}
`;
const ResubscribePage = () => {
  const { coachId, clientEmail } = useParams();
  const [status, setStatus] = useState(EMAIL_SUBSCRIPTION_STATUS.unsubscribed);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    unsubscribeUser();
  }, []);

  const unsubscribeUser = () => {
    setLoading(true);
    const data = {
      coachId,
      clientEmail,
    };
    resubscribeEmailCampaign(data)
      .then(res => {
        setStatus(EMAIL_SUBSCRIPTION_STATUS.resubscribed);
      })
      .catch(err => {
        console.log('err', err);
        setStatus(EMAIL_SUBSCRIPTION_STATUS.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <CenterContainer viewportHeight>
      {loading && <Loader />}
      {status === EMAIL_SUBSCRIPTION_STATUS.resubscribed && <ResubSuccessCard clientEmail={clientEmail} />}
      {status === EMAIL_SUBSCRIPTION_STATUS.error && <ErrorCard clientEmail={clientEmail} onRetry={unsubscribeUser} />}
    </CenterContainer>
  );
};
export default ResubscribePage;
