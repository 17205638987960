import React from 'react';
import classes from './Ranking.module.scss';
import CheckIcon from '../../../Assests/Icons/CheckIcon';
import RankingIcon from '../../../Assests/Icons/RankingIcon';

const Ranking = () => {
  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <RankingIcon />
      </div>
      <div className={classes.type_name}>Ranking</div>
    </div>
  );
};

export default Ranking;
