import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Modal from 'components/UI/Modal';
import { Rating, Box } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import Button from 'components/FormUI/Button';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import { getOTP, login } from 'actions/user';
import { useDispatch } from 'react-redux';
import { darken } from 'polished';
import { TemplateType } from 'helpers/constants/templateType';
import { useMediaQuery } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const breakpoints = {
  small: '375px', // iPhone SE
  medium: '390px', // iPhone 12 Pro
  large: '360px', // Galaxy S8+
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px 20px;
  background: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#2d2f31' : '#fff')};
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
  width: ${({ mobileView }) => (mobileView ? '350px' : '520px')};
  @media (max-width: ${breakpoints.large}) {
    width: 350px;
  }
  @media (max-width: ${breakpoints.medium}) {
    width: 330px;
  }
  @media (max-width: ${breakpoints.small}) {
    width: 310px;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
const StyledTitle = styled.div`
  margin-top: ${({ mobileView }) => (mobileView ? '20px' : '0px')};
  margin-bottom: ${({ mobileView }) => (mobileView ? '10px' : '0px')};
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  font-family: Avenir;
  font-style: normal;
  line-height: normal;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#215C73')};
  @media (max-width: ${breakpoints.large}) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  @media (max-width: ${breakpoints.medium}) {
    margin-top: 15px;
    margin-bottom: 8px;
  }
  @media (max-width: ${breakpoints.small}) {
    margin-top: 10px;
    margin-bottom: 5px;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  width: ${({ mobileView }) => (mobileView ? '350px' : '500px')};
  margin-right: ${({ mobileView }) => (mobileView ? '25px' : '0px')};
  @media (max-width: ${breakpoints.large}) {
    width: 340px;
  }
  @media (max-width: ${breakpoints.medium}) {
    width: 345px;
  }
  @media (max-width: ${breakpoints.small}) {
    width: 325px;
  }
`;
const StyledParagraph = styled.div`
  font-size: ${({ mobileView }) => (mobileView ? '14px' : '16px')};
  font-weight: 350;
  font-family: Avenir;
  font-style: normal;
  line-height: 24px;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
  text-align: center;
  display: inline-block;
`;
const StyledSubmitParagraph = styled.div`
  font-size: ${({ mobileView }) => (mobileView ? '10px' : '12px')};
  font-weight: 350;
  font-family: Avenir;
  font-style: normal;
  line-height: 24px;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
  text-align: center;
  display: inline-block;
  @media (max-width: ${breakpoints.large}) {
    font-size: 10px;
  }
  @media (max-width: ${breakpoints.medium}) {
    font-size: 10px;
  }
  @media (max-width: ${breakpoints.small}) {
    font-size: 9px;
  }
`;
const StyledSpan = styled.span`
  font-size: ${({ mobileView }) => (mobileView ? '14px' : '16px')};
  font-weight: 800;
  text-align: center;
  font-family: Avenir;
  font-style: normal;
  line-height: normal;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
  display: inline-block;
`;
const StyledTermAndCondationsSpan = styled.span`
  font-size: ${({ mobileView }) => (mobileView ? '10px' : '12px')};
  font-weight: 350;
  text-align: center;
  font-family: Avenir;
  font-style: normal;
  line-height: normal;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#C9B382')};
  display: inline-block;
  cursor: pointer;
`;
const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-right: -15px;
  ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'color: #fff' : 'color: black')}
`;
const CodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
  gap: 10px;
`;
const StyledTitledButton = styled.p`
  color: #c9b382;
  cursor: pointer;
  font-size: ${({ mobileView }) => (mobileView ? '14px' : '16px')};
  font-weight: 800;
  text-decoration: underline;
`;
export const StyledButton = styled(Button)`
  min-width: ${({ mobileView }) => (mobileView ? '100%' : '386px')};
  min-height: 48px;
  font-size: 15px;
  font-weight: 800;
  border-radius: 5px;
  border: 1px solid;
  padding: 12px 15px 12px 15px;
  
  ${({ variant, backgroundColor, activeTemplate, textColor }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
        color: ${textColor};
        border-color: ${backgroundColor};
      `;
    }
    return activeTemplate === TemplateType.TemplateOne
      ? css`
          background-color: #6999ca;
        `
      : css`
          background-color: #b78aea;
        `;
  }}

  ${({ invert }) =>
    invert &&
    css`
      color: rgba(0, 0, 0, 0.87);
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.87);
    `}

  &:hover {
    ${({ variant, backgroundColor, activeTemplate }) => {
      if (backgroundColor) {
        return css`
          background-color: ${darken(0.05, backgroundColor)};
        `;
      }
      return activeTemplate === TemplateType.TemplateOne
        ? css`
            background-color: ${darken(0.05, '#6999CA')};
          `
        : css`
            background-color: ${darken(0.05, '#B78AEA')};
          `;
    }}

    ${({ invert }) =>
      invert &&
      css`
        background-color: ${darken(0.05, 'white')};
      `}
`;
const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ mobileView }) => (mobileView ? '10px' : '16px')};
  align-items: center;
`;

export const StyledInput = styled.input`
  width: ${({ mobileView }) => (mobileView ? '48px' : '56px')};
  height: ${({ mobileView }) => (mobileView ? '48px' : '56px')};
  border: 1px solid #c4c4c4;
  padding: 12px 15px 12px 15px;
  border-radius: 4px;
  text-align: center;
  outline: none;
  &:focus {
    border-color: #c4c4c4;
    box-shadow: #c4c4c4;
  }
  color: #2b2b2b;
`;
export const StyledInputContainer = styled.div`
  display: flex;
  gap: ${({ mobileView }) => (mobileView ? '5px' : '10px')};
`;
const StyledError = styled.div`
  color: red;
  font-size: ${({ mobileView }) => (mobileView ? '12px' : '14px')};
  margin-top: 2px;
  padding-left: 10px;
  font-weight: 500;
`;
const SignInWithOtpModal = ({
  isOpen,
  email,
  isDarkModeEnabled,
  formRef,
  onClose,
  accentColorCode,
  primaryColor,
  textColor,
  activeTemplate,
  errorMsg,
}) => {
  const mobileView = useMediaQuery('(max-width:768px)');
  const dispatch = useDispatch();
  const [showTerms, setShowTerms] = useState(false);
  const [removeOtp, setRemoveOtp] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(''));

  useEffect(() => {
    if (errorMsg === 'You have entered the wrong login code, try again') {
      setOtp(new Array(6).fill(''));
    }
  }, [errorMsg]);

  const handleCancel = () => {
    onClose();
  };
  const sendOTP = () => {
    const returedOTPResult = getOTP(email)(dispatch);
    setTooltipOpen(true);
  };

  const logInAndJoinContribution = () => {
    const otpValue = otp.join('');
    formRef.current.setFieldValue('otp', otpValue);
    formRef.current.handleSubmit();
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.value !== '' && index < 5) {
      document.getElementById(`otpInput-${index + 1}`).focus();
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        document.getElementById(`otpInput-${index - 1}`).focus();
      }
    }
  };

  const handlePaste = e => {
    const pasteData = e.clipboardData.getData('text');
    const pasteOtp = pasteData.split('').slice(0, 6);
    setOtp(pasteOtp);
  };

  return (
    isOpen && (
      <>
        <Modal style={{ background: '#2d2f31' ? '#2d2f31' : '#fff' }} isOpen={isOpen} disableFooter CrossIconHide>
          <MainContainer mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
            <TitleContainer mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
              <HeaderContainer mobileView={mobileView}>
                <StyledCloseIcon isDarkThemeEnabled={isDarkModeEnabled} onClick={handleCancel} />
              </HeaderContainer>
              <StyledTitle
                mobileView={mobileView}
                isDarkModeEnabled={isDarkModeEnabled}
                style={{ color: accentColorCode }}
              >
                Complete Your Enrollment
              </StyledTitle>

              <StyledParagraph mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
                We’ve sent a 6-digit code to {mobileView && <br />}
                <StyledSpan mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
                  {' '}
                  {email}.{' '}
                </StyledSpan>
                {mobileView && <br />} The code expires soon, so please enter it promptly.
              </StyledParagraph>
              <CodeContainer mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
                <StyledInputContainer mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
                  {otp.map((data, index) => (
                    <StyledInput
                      mobileView={mobileView}
                      key={index}
                      type="text"
                      maxLength="1"
                      id={`otpInput-${index}`}
                      value={otp[index]}
                      onChange={e => handleChange(e.target, index)}
                      onKeyDown={e => handleKeyDown(e, index)}
                      onPaste={index === 0 ? handlePaste : null}
                      style={{ color: '#2b2b2b' }}
                    />
                  ))}
                </StyledInputContainer>
                <StyledParagraph mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
                  Can’t find your code? Check your spam folder.
                </StyledParagraph>
                <Tooltip
                  className="cursor-pointer"
                  title="Code sent successfully!"
                  open={tooltipOpen}
                  arrow
                  onClose={() => {
                    setTooltipOpen(false);
                  }}
                >
                  <StyledTitledButton mobileView={mobileView} style={{ color: primaryColor }} onClick={sendOTP}>
                    Resend
                  </StyledTitledButton>
                </Tooltip>
              </CodeContainer>
              <SubmitContainer mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
                <StyledButton
                  textColor={textColor}
                  backgroundColor={primaryColor}
                  activeTemplate={activeTemplate}
                  style={{ minWidth: mobileView ? '100%' : '386px' }}
                  onClick={logInAndJoinContribution}
                >
                  Join Now
                </StyledButton>
                <StyledSubmitParagraph mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
                  By proceeding, I agree to the{' '}
                  <StyledTermAndCondationsSpan
                    onClick={e => {
                      e.preventDefault();
                      setShowTerms(true);
                    }}
                    mobileView={mobileView}
                    isDarkModeEnabled={isDarkModeEnabled}
                    style={{ color: primaryColor }}
                  >
                    {' '}
                    Terms and Conditions
                  </StyledTermAndCondationsSpan>
                  , and I'm at least 18 years old.
                </StyledSubmitParagraph>
              </SubmitContainer>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {errorMsg && <StyledError>{errorMsg}</StyledError>}
              </div>
            </TitleContainer>
          </MainContainer>
        </Modal>
        <ModalTermsAndConditions applyTheming showTerms={showTerms} onCancel={() => setShowTerms(false)} />
      </>
    )
  );
};

export default SignInWithOtpModal;
