import React, { useState } from 'react';
import classes from './ResultBody.module.scss';
import Button from 'components/FormUI/Button';
import useRouter from 'hooks/useRouter';
import QuizPassed from './QuizPassed/QuizPassed';
import QuizFailed from './QuizFailed/QuizFailed';
import { useSelector } from 'react-redux';
import ResultWithOutGrade from './ResultWithOutGrade/ResultWithOutGrade';
import classNames from 'classnames';

const HEADER_TAB = ['Quiz Passed', 'Quiz Failed'];
const ResultBody = ({ passResultData, failResultData, setFieldValue, formProps, noResultData, mobileView }) => {
  const { isLoading, activeQuiz, showCustomizeResults } = useSelector(state => state?.quizes);
  const { history } = useRouter();
  const [activeTab, setActiveTab] = useState(HEADER_TAB[0]);
  const BodyComponent = activeTab === HEADER_TAB[0] ? QuizPassed : QuizFailed;

  return formProps?.values?.isPassingGradeRequired ? (
    <div className={classes.container}>
      <div className={classes.header}>
        {HEADER_TAB.map((item, index) => {
          const isActive = item === activeTab;
          return (
            <div
              className={classNames(classes.tab, {
                [classes.tab_responsive]: mobileView,
                [classes.active_tab]: isActive,
              })}
            >
              <Button
                variant="contained"
                value={item}
                className={isActive ? classes.active_button : classes.button}
                onClick={e => {
                  setActiveTab(item);
                }}
              >
                {item}
              </Button>
            </div>
          );
        })}
      </div>
      <div className={classes.body} style={{ padding: mobileView ? '24px 16px' : '' }}>
        <BodyComponent
          passResultData={passResultData}
          failResultData={failResultData}
          setFieldValue={setFieldValue}
          mobileView={mobileView}
        />
      </div>
    </div>
  ) : (
    <div className={classes.container}>
      <div className={classes.body} style={{ padding: mobileView ? '24px 16px' : '' }}>
        <ResultWithOutGrade noResultData={noResultData} setFieldValue={setFieldValue} mobileView={mobileView} />
      </div>
    </div>
  );
};

export default ResultBody;
