import React from 'react';
import classes from './Choice.module.scss';
import { Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import styled from 'styled-components';
import CrossIcon from '../../../Assests/Icons/CrossIcon';

const StyledLabel = styled.div`
  font-family: Avenir;
  font-weight: 350;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
`;

const Choice = ({
  choice,
  parentProvided,
  index,
  setFieldValue,
  removeChoice,
  errors,
  handleBlur,
  allChoices,
  mobileView,
  questionsIndex,
  question,
}) => {
  console.log('errors->', errors);

  const hasCorrectAnswer = allChoices.some(item => item.isCorrect === true);
  return (
    <Droppable droppableId={`choice-${index}`}>
      {(provided, snapshot) => (
        <>
          {' '}
          <div {...provided.droppableProps} innerRef={provided.innerRef}>
            <div className={classes.container}>
              <div className={classes.dragicon_container} {...parentProvided.dragHandleProps}>
                <DragIndicatorIcon className={classes.drag_icon} />
              </div>
              <div className={classes.input_container} style={{ width: mobileView ? '90%' : '' }}>
                <TextField
                  placeholder="Enter a new option"
                  variant="outlined"
                  fullWidth
                  value={choice?.value}
                  onChange={event => {
                    setFieldValue(`questions[${questionsIndex}].options[${index}].value`, event?.target.value);
                    setFieldValue(`isSaved`, false);
                  }}
                  onBlur={handleBlur}
                />
                <div
                  className={classes.cross_btn}
                  onClick={() => {
                    removeChoice(index);
                  }}
                >
                  <CrossIcon />
                </div>
              </div>
              {!mobileView && (
                <div className={classes.correct_answer}>
                  <FormControlLabel
                    className={classes.MuiFormControlLabel_root}
                    checked={choice?.isCorrect}
                    onChange={() => {
                      setFieldValue(`isSaved`, false);
                      let contentLength = choice.value;

                      if (contentLength.length > 0) {
                        if (question.canSelectMultipleCorrectOptions) {
                          setFieldValue(`questions[${questionsIndex}].options[${index}].isCorrect`, !choice?.isCorrect);
                        } else {
                          if (!hasCorrectAnswer) {
                            let updatedArray = question.options.map(item => ({
                              ...item,
                              isCorrect: false,
                            }));
                            setFieldValue(`questions[${questionsIndex}].options`, updatedArray);
                            setFieldValue(
                              `questions[${questionsIndex}].options[${index}].isCorrect`,
                              !choice?.isCorrect,
                            );
                          } else if (choice?.isCorrect) {
                            setFieldValue(
                              `questions[${questionsIndex}].options[${index}].isCorrect`,
                              !choice?.isCorrect,
                            );
                          } else {
                            let updatedArray = question.options.map(item => ({
                              ...item,
                              isCorrect: false,
                            }));
                            setFieldValue(`questions[${questionsIndex}].options`, updatedArray);
                            setFieldValue(
                              `questions[${questionsIndex}].options[${index}].isCorrect`,
                              !choice?.isCorrect,
                            );
                          }
                        }
                      }
                    }}
                    control={
                      <Checkbox
                        classes={{
                          root: classes.checkbox_root,
                        }}
                        color="primary"
                      />
                    }
                    label={<StyledLabel>Correct Answer</StyledLabel>}
                    name="default"
                  />
                </div>
              )}
            </div>
            {provided.placeholder}
          </div>
          {mobileView && (
            <div className={classes.correct_answer}>
              <FormControlLabel
                className={classes.MuiFormControlLabel_root}
                checked={choice?.isCorrect}
                onChange={() => {
                  setFieldValue(`isSaved`, false);
                  let contentLength = choice.value;

                  if (contentLength.length > 0) {
                    if (question.canSelectMultipleCorrectOptions) {
                      setFieldValue(`questions[${questionsIndex}].options[${index}].isCorrect`, !choice?.isCorrect);
                    } else {
                      if (!hasCorrectAnswer) {
                        let updatedArray = question.options.map(item => ({
                          ...item,
                          isCorrect: false,
                        }));
                        setFieldValue(`questions[${questionsIndex}].options`, updatedArray);
                        setFieldValue(`questions[${questionsIndex}].options[${index}].isCorrect`, !choice?.isCorrect);
                      } else if (choice?.isCorrect) {
                        setFieldValue(`questions[${questionsIndex}].options[${index}].isCorrect`, !choice?.isCorrect);
                      } else {
                        let updatedArray = question.options.map(item => ({
                          ...item,
                          isCorrect: false,
                        }));
                        setFieldValue(`questions[${questionsIndex}].options`, updatedArray);
                        setFieldValue(`questions[${questionsIndex}].options[${index}].isCorrect`, !choice?.isCorrect);
                      }
                    }
                  }
                }}
                control={
                  <Checkbox
                    classes={{
                      root: classes.checkbox_root,
                    }}
                    color="primary"
                  />
                }
                label={<StyledLabel>Correct Answer</StyledLabel>}
                name="default"
              />
            </div>
          )}
        </>
      )}
    </Droppable>
  );
};

export default Choice;
