import React, { useEffect } from 'react';
import classes from './ActionsPanel.module.scss';

const ActionsPanel = ({ actions, onClose }) => {
  useEffect(() => {
    document.addEventListener('click', onClose);
    return () => {
      document.removeEventListener('click', onClose);
    };
  }, []);

  return (
    <div className={classes.container}>
      {actions.map((action, index) => {
        const isLast = index === actions.length - 1;
        return (
          <div className={`${classes.action} ${isLast ? classes.last_item : ''}`} onClick={action.onClick}>
            {action.name}
          </div>
        );
      })}
    </div>
  );
};

export default ActionsPanel;
