import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import ChatButton from 'components/UI/ListButtons/ChatButton';
import Loader from 'components/UI/Loader';
import { getStyledButton, getPressEffectButton, formatTime, addhttp } from 'utils/utils';
import useRouter from 'hooks/useRouter';
import formPreviewIcon from '../../../assets/form-preview-icon.svg';
import '../../../pages/AllApplicationsPage/ApplicationPage.scss';
import { approveOrRejectForm, ApplicationFormModal, applicationStatus } from '../useSharedApplicationForm';
import Modal from 'components/UI/Modal';

const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
  },
})(TableCell);

export const Application = ({ index, row, totalRows, openedIndex, setOpenedIndex }) => {
  const { contributionId, status, clientName, id, contributionName, createTime, clientId, isDeleted } = row;
  const url = `/contribution-view/${contributionId}/about`;
  const [confirmationModalData, setConfirmationModalData] = useState(null);
  const [loader, setLoader] = useState(false);
  const { domain } = useRouter();
  const dispatch = useDispatch();
  const StyledButton = getStyledButton();
  const PressedEffectButton = getPressEffectButton();

  return (
    <>
      <TableRow key={index}>
        <NarrowTableCell component="th" scope="row">
          {clientName}
        </NarrowTableCell>
        <NarrowTableCell style={{ textDecoration: 'underline' }}>
          <Link to={url}>{contributionName}</Link>
        </NarrowTableCell>
        <NarrowTableCell>{formatTime(createTime)}</NarrowTableCell>
        {!isDeleted && status === applicationStatus.pending ? (
          <NarrowTableCell style={{ justifyItems: 'spa' }}>
            <StyledButton
              type="button"
              className="btn reject-button"
              value="Reject"
              onClick={() => {
                setConfirmationModalData({
                  onSubmit: () => {
                    approveOrRejectForm(id, false, setLoader, dispatch);
                    setConfirmationModalData(null);
                  },
                  onCancel: () => setConfirmationModalData(null),
                  text: 'Are you sure you want to reject this application? ',
                });
              }}
            >
              Reject
            </StyledButton>
            <StyledButton
              type="button"
              className="btn approve-button"
              value="Approve"
              onClick={() => {
                setConfirmationModalData({
                  onSubmit: () => {
                    approveOrRejectForm(id, true, setLoader, dispatch);
                    setConfirmationModalData(null);
                  },
                  onCancel: () => setConfirmationModalData(null),
                  text: 'Are you sure you want to approve this application?',
                });
              }}
            >
              Approve
            </StyledButton>
          </NarrowTableCell>
        ) : (
          <NarrowTableCell>
            <span>
              {isDeleted ? (
                <StyledButton type="button" style={{ width: 'initial' }} className="btn rejected-button" value={status}>
                  Not Responded
                </StyledButton>
              ) : (
                <StyledButton type="button" className={`btn ${status?.toLowerCase()}-button`} value={status}>
                  {status}
                </StyledButton>
              )}
            </span>
          </NarrowTableCell>
        )}
        <NarrowTableCell align="center">
          <PressedEffectButton type="button">
            <img src={formPreviewIcon} alt="preview" onClick={() => setOpenedIndex(index)} />
          </PressedEffectButton>
        </NarrowTableCell>
        <NarrowTableCell align="center">
          <ChatButton
            handleClick={() => {
              window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${clientId}`));
            }}
          />
        </NarrowTableCell>
      </TableRow>
      <ApplicationFormModal
        totalRows={totalRows}
        openedIndex={openedIndex}
        setOpenedIndex={setOpenedIndex}
        key={index}
        showApplicationFormDetailPopUp={index === openedIndex}
        setShowApplicationFormDetailPopUp={() => setOpenedIndex(null)}
        row={row}
      />
      <Modal
        title="Confirm"
        isOpen={Boolean(confirmationModalData)}
        onCancel={confirmationModalData?.onCancel}
        onSubmit={confirmationModalData?.onSubmit}
        cancelTitle="Cancel"
        submitTitle="Yes"
      >
        <p>{confirmationModalData?.text}</p>
      </Modal>
      {loader && <Loader />}
    </>
  );
};
Application.propTypes = {
  index: PropTypes.string.isRequired,
  totalRows: PropTypes.number.isRequired,
  openedIndex: PropTypes.string.isRequired,
  setOpenedIndex: PropTypes.func.isRequired,
  row: PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
    contributionName: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    contributionId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isDeleted: PropTypes.bool,
  }),
};

Application.defaultProps = {
  row: {
    clientId: '',
    clientName: '',
    contributionName: '',
    createTime: '',
    status: '',
    contributionId: '',
    isDeleted: false,
  },
};
