import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import AnalyticsIcon from '@mui/icons-material/Analytics';

const CustomAnalyticsButton = ({ handleClick, disabled, className }) => (
  <IconButton
    className={className}
    color="primary"
    aria-label="chat picture"
    component="span"
    onClick={handleClick}
    disabled={disabled}
  >
    <AnalyticsIcon />
  </IconButton>
);

CustomAnalyticsButton.propTypes = {
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

CustomAnalyticsButton.defaultProps = {
  handleClick: undefined,
  disabled: false,
  className: '',
};

export default CustomAnalyticsButton;
