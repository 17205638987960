import React from 'react';
import classes from './RatingType.module.scss';
import RatingIconLabel from './RatingIconLabel/RatingIconLabel';
import FilledStarIcon from 'pages/CreateQuizes/Pages/QuizContent/Assests/Icons/FilledStarIcon';
import OutlinedStarIcon from 'pages/CreateQuizes/Pages/QuizContent/Assests/Icons/OutlinedStarIcon';

const RatingType = ({
  index,
  question,
  setFieldValue = () => {},
  onRemoveChoice,
  clientView = false,
  setChecked = () => {},
  checked,
}) => {
  function generateArray(num) {
    return Array.from({ length: num }, (_, index) => index + 1);
  }
  return clientView ? (
    <div className={classes.container}>
      <div className={classes.rating_stars_container}>
        {generateArray(question?.options[0]?.value ?? 5).map(ratingNumber => {
          const icon = ratingNumber <= checked ? <FilledStarIcon /> : <OutlinedStarIcon />;
          const handleClick = number => {
            setChecked(number.toString());
          };
          return <RatingIconLabel icon={icon} label={ratingNumber} onClick={handleClick} />;
        })}
      </div>
    </div>
  ) : (
    <>
      <div className={classes.container}>
        <div className={classes.rating_stars_container}>
          {generateArray(question?.options[0]?.value ?? 5).map(ratingNumber => {
            const icon = ratingNumber <= question?.options[0]?.value ? <FilledStarIcon /> : <OutlinedStarIcon />;
            const handleClick = number => {
              setFieldValue(`questions[${index}].options[${0}].value`, number.toString());
            };
            return (
              <RatingIconLabel icon={icon} label={ratingNumber} onClick={ratingNumber > 2 ? handleClick : () => {}} />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RatingType;
