import React, { useState } from 'react';
import useRouter from 'hooks/useRouter';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
import BurgerMenu from 'assets/BurgerMenu.svg';
import { StyledNav, StyledButton, StyledLinkDiv } from './StyledElements';
import Sidebar from './Sidebar';
import { MessageIcon } from '../icons/Message';
import { addhttp, lightOrDark } from 'utils/utils';
import { BurgerMenuIcon } from '../icons/BurgerMenu';

function Nav({
  theme,
  links,
  apiData,
  currentPage,
  setCurrentPage,
  themedHoverColor,
  brandingColors,
  customLogo = null,
  messageRedirection,
}) {
  const { domain } = useRouter();
  const muiTheme = useTheme();
  const defaultColor = theme === 'dark' ? 'white' : 'black';
  const defaultBackGroundColor = theme === 'dark' ? '#2A2C2E' : 'white';
  const primaryColor = brandingColors.PrimaryColorCode || defaultBackGroundColor;
  const textColor = brandingColors.TextColorCode || '#FFFFFF';
  const color = brandingColors.AccentColorCode || defaultColor;
  const customLinks = apiData?.customLinks || [];
  const isDarkModeEnabled = apiData?.isDarkModeEnabled || false;
  const isMessagingEnabled = apiData?.isMessagingEnabled || false;
  const [openSidebar, setOpenSidebar] = useState(false);
  const isMobileSidebarNeeded = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const desktop = useMediaQuery('(max-width: 1200px)');
  const mobileView = useMediaQuery('(max-width: 576px)');

  const toggleSideBar = () => {
    setOpenSidebar(!openSidebar);
  };

  const MobileMenuWrapper = styled.div`
    margin-right: 12px;
    display: inline-block;
    &:hover {
      cursor: pointer;
    }
  `;

  const BurgerMenuWrapper = styled.img`
    height: 20px;
    width: 25px;
    position: relative;
    margin-right: 10px;
  `;

  const mappedLink = [];
  links.forEach(link => {
    switch (link.name) {
      case 'ContributionCommunity':
        mappedLink.push({ name: 'Community Services', path: link.path });
        return;
      case 'ContributionCourse':
        mappedLink.push({ name: 'Group Services', path: link.path });
        return;
      case 'ContributionMembership':
        mappedLink.push({ name: 'Membership Services', path: link.path });
        return;
      case 'Workshops':
        mappedLink.push({ name: 'Workshops', path: link.path });
        return;
      case 'ContributionArchived':
        mappedLink.push({ name: 'Archived', path: link.path });
        return;
      case 'ContributionOneToOne':
        mappedLink.push({ name: '1:1 Services', path: link.path });
        return;
      default:
        mappedLink.push(link);
    }
  });

  return (
    <StyledNav
      className="coach-website-navbar"
      mobileView={mobileView}
      desktop={desktop}
      color={color}
      bgColor={theme === 'dark' ? '#2A2C2E' : 'white'}
    >
      <Drawer open={openSidebar} onClose={toggleSideBar}>
        <Sidebar
          mappedLink={mappedLink}
          customLinks={customLinks}
          customLogo={customLogo}
          isDarkModeEnabled={isDarkModeEnabled}
          linkColor={color}
        />
      </Drawer>
      {isMobileSidebarNeeded ? (
        <StyledLinkDiv fullWidth className="website-mobile-nav">
          <div className="flex-item flex-grow-1">
            <MobileMenuWrapper onClick={toggleSideBar}>
              <BurgerMenuIcon color={primaryColor} />
            </MobileMenuWrapper>
          </div>
          <Link id="nav-logo-img" style={{ padding: '0px' }} to="/dashobard" className="flex-item d-flex flex-grow-1">
            {customLogo && (
              <img
                src={customLogo}
                style={{
                  objectFit: 'cover',
                  height: '50px',
                  width: '50px',
                  display: 'block',
                  padding: '0px',
                  verticalAlign: 'middle',
                  borderRadius: '100%',
                }}
                alt=""
              />
            )}
          </Link>
          <span
            role="button"
            tabIndex="0"
            onKeyDown={() => {
              window.location.herf = addhttp(domain.concat(`/conversations/all?chatId=${apiData?.userId}`));
            }}
            onClick={() => {
              window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${apiData?.userId}`));
            }}
          >
            <MessageIcon color={primaryColor} />
          </span>
        </StyledLinkDiv>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className="links-contianer">
          <div style={{ display: 'flex' }}>
            <Link id="nav-logo-img" className="nav-logo-img" to="/dashobard">
              {customLogo && (
                <img
                  src={customLogo || ''}
                  style={{
                    objectFit: 'cover',
                    height: '60px',
                    width: '60px',
                    display: 'block',
                    padding: '0px',
                    verticalAlign: 'middle',
                  }}
                  alt=""
                />
              )}
            </Link>
            {mappedLink &&
              mappedLink
                .filter(link => {
                  if (link.path === 'links' && !customLinks.length > 0) {
                    return false; // skip
                  }
                  return true;
                })
                .map(link => {
                  return currentPage === link?.path ? (
                    <Link
                      isActive
                      themedHoverColor="red"
                      onClick={() => setCurrentPage(link?.path)}
                      className="active-link nav-bar-link"
                      style={{
                        borderBottom: currentPage === link?.path && `1px solid ${color}`,
                        fontWeight: currentPage === link?.path && 700,
                      }}
                      to={link?.path}
                    >
                      {link?.name}
                    </Link>
                  ) : (
                    <Link
                      className="nav-bar-link"
                      style={{ borderBottom: currentPage === link?.path && `1px solid ${themedHoverColor}` }}
                      onClick={() => setCurrentPage(link?.path)}
                      to={link?.path}
                    >
                      {link?.name}
                    </Link>
                  );
                })}
          </div>
          {isMessagingEnabled && (
            <StyledButton
              bgColor={primaryColor}
              margin="9px 9%"
              color={textColor === 'Auto' ? lightOrDark(primaryColor) : textColor}
              className="float-right"
              onClick={() => {
                if (messageRedirection?.redirectionType === 'email') {
                  if (messageRedirection?.email != null) {
                    window.location.href = `mailto:${messageRedirection?.email}`;
                  }
                } else if (messageRedirection?.redirectionType === 'coherechat') {
                  window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${apiData?.userId}`));
                } else if (messageRedirection?.redirectionType === 'otherlink') {
                  window.location.href = addhttp(messageRedirection?.externalLink);
                } else {
                  window.location.href = addhttp(domain.concat(`/conversations/all?chatId=${apiData?.userId}`));
                }
              }}
            >
              {messageRedirection?.buttonText ? messageRedirection?.buttonText : 'Message'}
            </StyledButton>
          )}
        </div>
      )}
    </StyledNav>
  );
}
export default Nav;
