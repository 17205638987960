import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { Card, CardBody, CardHeader } from 'components/UI/Card';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import TextBlock from './TextBlock';
import { TemplateType } from 'helpers/constants/templateType';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { flatten, orderBy } from 'lodash';
import { format } from 'date-fns';
import Modal from 'components/UI/Modal';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export const DATE_VIEW = 'MMM DD, YYYY';
export const TIME_FORMAT = 'hh:mmA';
export const START_DATETIME_FORMAT = `${DATE_VIEW} ${TIME_FORMAT}`;

const DATE_WITHOUT_YEAR = 'MMM Do';
const TIME_FORMAT_SHORT = 'h:mm a';
const START_DATETIME_FORMAT_SHORT = `${DATE_WITHOUT_YEAR}, ${TIME_FORMAT_SHORT}`;

const formatTime = date => {
  return moment(date).format(DATE_VIEW);
};

const sortByStartTime = session => {
  const startTime = moment(session.startTime);
  return startTime.unix();
};

const getFormattedDateRange = (startDate, endDate, timezoneShort) => {
  return `${moment(startDate).format(START_DATETIME_FORMAT_SHORT)} - ${moment(endDate).format(
    START_DATETIME_FORMAT_SHORT,
  )} ${timezoneShort}`;
  // style with flex justified width
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>{moment(startDate).format(START_DATETIME_FORMAT)}</div>
      <div>-</div>
      <div>{moment(endDate).format(TIME_FORMAT)}</div>
      <div>{timezoneShort}</div>
    </div>
  );
};

export const EnroleDateShowMore = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  cursor: pointer;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
`;

export const StyledCalenderIcon = styled(CalendarMonthIcon)`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;

const WorkshopDatesBlockTemplate = ({ userNotLoggedIn, themedColor, colorToUse }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const EnroleDateP = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
  `;
  const MainDivDate = styled.div`
    display: flex;
    justify-content: flex-start;
  `;
  const DateStart = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  `;

  const DateEnd = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #282b2b;
  `;

  const contribution = useContribution();
  const sessions = contribution?.sessions;
  const { isWorkshop, timeZoneShortName, displaySessionTimeDates } = contribution;
  const [showMore, setShowMore] = useState(false);

  const liveSessions = sessions => {
    const live = sessions?.filter(s => !s.isPrerecorded && !s.isCompleted);
    return live;
  };

  const allLiveUncompletedSessions = liveSessions(sessions);
  const flatSessionTimes = flatten((allLiveUncompletedSessions || []).map(k => k.sessionTimes));
  console.info('ffff-1', flatSessionTimes);
  const ordered =
    orderBy(
      flatSessionTimes?.filter(k => !k.isCompleted),
      sortByStartTime,
      'asc',
    ) || [];
  console.info('ffff-2', ordered);

  const openPopup = () => {
    setShowMore(true);
  };

  return (
    <>
      <div
        style={{
          ...{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
          ...(userNotLoggedIn ? {} : { padding: '4px 20px' }),
        }}
      >
        {isWorkshop && displaySessionTimeDates && ordered?.length > 0 ? (
          <>
            <EnroleDateP style={{ color: colorToUse.AccentColorCode, marginTop: '1rem' }}>
              Workshop Date{ordered.length > 1 ? 's' : ''} & Time
            </EnroleDateP>
            {ordered.slice(0, 5).map((sessionTime, index) => (
              <MainDivDate key={sessionTime.id} style={{ marginBottom: '0.5rem' }}>
                <DateStart style={{ color: themedColor }}>
                  {/* <StyledSessionDateTimeRange color={themedColor}> */}
                  {getFormattedDateRange(sessionTime.startTime, sessionTime.endTime, timeZoneShortName)}
                  {/* </StyledSessionDateTimeRange> */}
                </DateStart>
              </MainDivDate>
            ))}
            {ordered.length > 5 && (
              <div style={{ marginTop: '10px' }}>
                <EnroleDateShowMore onClick={openPopup} style={{ color: themedColor }}>
                  Show More
                </EnroleDateShowMore>
              </div>
            )}
          </>
        ) : null}
      </div>

      {isWorkshop && displaySessionTimeDates && showMore && (
        <Modal
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: '0.5rem',
                marginRight: '2rem',
              }}
            >
              <StyledCalenderIcon style={{ color: colorToUse.AccentColorCode }} />
              <EnroleDateShowMore
                style={{
                  color: colorToUse.AccentColorCode,
                  textAlign: 'center',
                  fontFamily: 'Avenir',
                  fontSize: '20px',
                }}
              >
                Workshop Date{ordered.length > 1 ? 's' : ''} & Time
              </EnroleDateShowMore>
            </div>
          }
          isOpen={showMore}
          onCloseText="OK"
          hiddenCancel
          onSubmit={() => {
            setShowMore(false);
          }}
          onCancel={() => {
            setShowMore(false);
          }}
          disableFooter
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem 3rem 2rem 0rem',
              rowGap: '0.5rem',
            }}
          >
            {ordered.map((sessionTime, index) => (
              <MainDivDate key={sessionTime.id}>
                <DateStart style={{ color: themedColor }}>
                  {/* <StyledSessionDateTimeRange color={themedColor}> */}
                  {getFormattedDateRange(sessionTime.startTime, sessionTime.endTime, timeZoneShortName)}
                  {/* </StyledSessionDateTimeRange> */}
                </DateStart>
              </MainDivDate>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

WorkshopDatesBlockTemplate.propTypes = {
  enrollment: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
  }).isRequired,
  themedColor: PropTypes.string.isRequired,
  colorToUse: PropTypes.shape({
    AccentColorCode: PropTypes.string,
  }).isRequired,
};

export default WorkshopDatesBlockTemplate;
