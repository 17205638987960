import styled from 'styled-components';
import Select from 'components/FormUI/Select';
import Button from 'components/FormUI/Button';
import { makeStyles } from '@material-ui/core';

export const ClientEmailTabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ mobileView }) =>
    mobileView &&
    `
     padding: 12px 12px;
     margin: 0px;
`}
`;
// export const ClientNotificationDiv = styled.div``;
export const ClientNotificationP = styled.p`
  font-size: 14px;
  text-decoration: underline;
  font-weight: 600;
  color: #cdba8f;
  ${({ mobileView }) =>
    mobileView &&
    `
  margin: 0px;
  `}
`;
export const EmailP = styled.p`
  font-size: 14px;
  text-decoration: underline;
  font-weight: 600;
  color: #000;
`;
export const EmailDiv = styled.div``;
export const NotificationBannerMainDiv = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #116582;
  padding: 15px 20px;
  border-radius: 5px;
  align-items: center;
  ${({ mobileView }) =>
    mobileView &&
    `
    display: block;
    padding: 15px 32px;
  `}
`;
export const NotificationBannerOk = styled.p`
  color: #fff;
  margin: 0px;
  cursor: pointer;
  ${({ mobileView }) =>
    mobileView &&
    `
  display: flex;
  justify-content: end;
  font-size: 20px;
  font-weight: 900;
`}
`;
export const NotificationBannerP = styled.p`
  color: #fff;
  margin: 0px;
  display: flex;
`;
export const DropDownMainContainer = styled.div`
  display: flex;
  align-items: flex-end;
  ${({ mobileView }) =>
    mobileView &&
    `
  display: block;
  `};
`;

export const EmailHeading = styled.p`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 850;
  font-size: 14px;
  ${({ mobileView }) =>
    mobileView &&
    `
  margin: 0px;
  `}
`;

export const HeadingMainContainer = styled.div`
  background-color: #fff;
  padding-top: 20px;
  color: rgba(0, 0, 0, 0.87);
  // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const InfoIconStyled = styled.span`
  ${({ mobileView }) =>
    mobileView &&
    `
  margin-left: -24px;
`}
`;

export const EditNotificationP = styled.p`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #c9b382;
  margin: 0px;
`;

export const ClientContriP = styled.p`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 850;
  font-size: 22px;
  color: #4a4a4a;
`;
export const DraftBtn = styled.div`
  background: #dbe7f2;
  border-radius: 5px;
  padding: 6px 14px;
  height: 35px;
  color: #4a4a4a;
`;
export const ClientMainDvi = styled.div`
  display: flex;
  justify-content: space-between;
  // width: 364px;
  ${({ mobileView }) =>
    mobileView &&
    `
       padding: 30px 0px 14px 0px;`}
`;

export const BackarrowMain = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const BtnDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  min-width: 150px;
  width: 80px;
  background: #ffffff;
  border: 1px solid #c9b382;
  border-radius: 2px;
  color: #c9b382;
  padding: 7px 24px;
  &:hover {
    cursor: pointer;
    background-color: #fff;
  }
  ${({ mobileView }) =>
    mobileView &&
    `
    min-width:  170px;
       
       `}
`;

export const StyledButtonSaved = styled(Button)`
  min-width: 150px;
  width: 80px;
  background: #c9b382;
  border: 1px solid #c9b382;
  border-radius: 2px;
  color: #fff;
  padding: 7px 24px;
  ${({ mobileView }) =>
    mobileView &&
    `
    min-width:  170px;
       
       `}
`;

export const StyledButtonReset = styled(Button)`
  min-width: 150px;
  width: 80px;
  background: #c9b382;
  border: 1px solid #c9b382;
  border-radius: 2px;
  color: #fff;
  padding: 7px 24px;
  ${({ mobileView }) =>
    mobileView &&
    `
    min-width:  170px;
       
       `}
`;

export const TriggerHeading = styled.p`
  background: #dbe7f2;
  max-width: 59rem;
  border-radius: 5px;
  padding: 12px 14px;
  font-family: 'Avenir';
  font-style: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
`;
export const Trigger = styled.span`
  font-weight: 800;
  margin-right: 10px;
`;
export const SubjectHeading = styled.p`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #4a4a4a;
  // padding-left: 16px;
`;

export const GropContributionHeading = styled.p`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #4a4a4a;
  // border: 1px solid #dfe3e4;
  border-radius: 2px;
  // padding: 6px 20px;
  // margin: 0px 16px;
  ${({ mobileView }) =>
    mobileView &&
    `
   font-size: 14px;
   margin: 0px 14px;

`}
`;

export const CheckboxHeading = styled.p`
  padding: 0px;
  margin: 0px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #4a4a4a;
`;

export const NotificationMainDiv = styled.div`
  display: flex;
  ${({ mobileView }) =>
    mobileView &&
    `
   padding: 20px 0px;
 `}
`;

export const StyledSelect = styled(Select)`
  width: calc(100% - 33px);
`;

export const PurchaseGroupContainer = styled.div`
  display: flex;
  ${({ mobileView }) =>
    mobileView &&
    `
   display: block;
 `}
`;
export const useStyles = makeStyles(theme => ({
  notchedOutline: {
    border: '1px solid white',
    borderColor: 'white',
  },
  PannelTopBar: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color,
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms ',
    borderRadius: '4px 4px 0px 0px',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    padding: '20px 16px 4px',
    // paddingBottom: '4px',
  },
  PannelTopBarTopDark: {
    backgroundColor: 'rgb(61, 66, 70)',
    color: 'white',
    padding: '10px 16px 10px',
    fontSize: '16px',
    fontWeight: '800',
    fontFamily: 'Avenir',
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingBottom: '4px',
  },

  PannelTopBarTop: {
    backgroundColor: '#F5F5F5',
    // color: ({ color }) => color,
    // transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms ',
    // borderRadius: '4px 4px 0px 0px',
    // boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    padding: '10px 16px 10px',
    fontSize: '16px',
    fontWeight: '800',
    fontFamily: 'Avenir',
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingBottom: '4px',
  },
  EmailText: {
    // paddingLeft: '20px',
  },
  ActionsText: {
    textAlign: 'center',
    // paddingLeft: '13rem',
  },
  Input_root_dark: {
    color: 'white',
  },
  Input_root_light: {
    color: 'black',
  },
  Enable: {
    marginTop: '0px',
    marginBottom: '5px',
    marginRight: '5px',
  },
  ButtonViewHide: {
    display: 'flex',
    // justifyContent: 'end',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70px',
    // marginTop: '0px',
    // marginRight: '5px',
    // paddingLeft: '5px',
  },
  ButtonEnb: {
    paddingLeft: '5px',
  },
  AccordianDetails: {
    padding: '30px 16px 16px',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color,
  },
  MobileViewMain: {
    margin: '0px 12px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  MobileViewMainImg: {
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
  },
  MobileViewMainChat: { borderBottom: '1px solid #EDEDED', padding: '20px 0px' },
  MobileViewCommunity: {
    borderBottom: '1px solid #EDEDED',
    padding: '20px 0px',
  },
  SessionContentMain: {
    borderBottom: '1px solid #EDEDED',
    padding: '20px 0px',
  },
  SessionContentBtn: {
    display: 'flex',
    alignItems: 'center',
  },
  MobileViewMainSalesCon: {
    borderBottom: '1px solid #EDEDED',
    padding: '20px 0px',
  },
  EditNotificationMain: {
    width: '100%',
    margin: 'auto',
  },
  CkEditorMainDiv: {
    width: '100%',
    margin: 'auto',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    boxShadow: '0px 1px 7px rgba(0, 0, 0, 0.13)',
    borderRadius: '4px',
  },
  CkEditorEmailH: {
    padding: '14px 0px',
  },
  CkEditorContainer: {
    width: '100%',
    margin: '0px 50px',
    border: '1px solid #DFE3E4',
    [theme.breakpoints.down(600)]: {
      margin: '0px',
      width: '100%',
    },
  },
  EnableLogoMain: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(600)]: {
      justifyContent: 'left',
    },
  },
  MainContainer: {
    width: '94%',
    margin: 'auto',
  },
}));
