import useRouter from 'hooks/useRouter';
import CreateQuizMain from 'pages/CreateQuizes/Components/Containers/MainContainer/MainContainer';
import React, { useEffect, useRef, useState } from 'react';
import CenterColumn from './Components/centerColumn';
import { LeftSidebarColumn, MainContentColumn, RightSidebarColumn } from './Components/columns';
import LeftColumn from './Components/leftColumn';
import QuizesContentPageLayout from './Components/quizesContentPageLayout';
import classes from './ContentPage.module.scss';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { questionTypes } from 'pages/CreateQuizes/Constants/constants';
import { Checkbox, FormControlLabel, TextField, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import RightColumn from './Components/rightColumn';
import ContentPageExitModal from 'pages/CreateQuizes/Components/Modals/ContentPageModal/ContentPageExitModal';
import { createUpdateQuiz, updateRemindAhain } from 'pages/CreateQuizes/Services/CreateQuizes.service';
import { setActiveQuiz, setShowCustomizeResults } from 'actions/quizes';
import useScreenView from 'hooks/useScreenView';
import Button from 'components/FormUI/Button';
import { openInNewWindow } from 'services/links';
import useQuiz from 'hooks/useQuiz';
import { convertToPlainText } from 'utils/utils';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';
import copyToClipboard from 'utils/copyToClipboard';
import PublishModal from './Modals/PublishModal/PublishModal';
import MobileHeader from './Components/MobileHeader/MobileHeader';
import TabsHeader from './Components/TabsHeader/TabsHeader';
import { useHeader } from 'hooks/usePageActions';

const dummyQuestionQuiz = {
  type: questionTypes.multiple_choice,
  description: '',
  options: [{ value: '', isCorrect: false }],
  isOptional: true,
  canSelectMultipleCorrectOptions: false,
  name: '',
  questionType: questionTypes.multiple_choice,
  userResponse: [],
  isDraft: true,
  isRequired: true,
  isDeleted: false,
  placeholder: 'Please write your question here…',
};
const dummyQuestionForm = {
  type: questionTypes.short_text,
  description: '',
  options: [{ value: '0', isCorrect: false }],
  isOptional: true,
  canSelectMultipleCorrectOptions: false,
  name: '',
  questionType: questionTypes.short_text,
  userResponse: [],
  isDraft: true,
  isRequired: true,
  isDeleted: false,
  placeholder: 'Please write your question here…',
};

const HeaderTitle = ({ title = '', type, isEditing }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  let titleText = '';
  if (title?.length > 0) {
    if (mobileView) {
      if (title?.length > 10) {
        titleText = title.substring(0, 10) + '...';
      } else {
        titleText = title;
      }
    } else {
      if (title?.length > 50) {
        titleText = title.substring(0, 40) + '...';
      } else {
        titleText = title;
      }
    }
  } else {
    if (type === 'Form' && isEditing) {
      titleText = 'Edit a Form';
    } else if (type === 'Form' && !isEditing) {
      titleText = 'Create a Form';
    } else if (type != 'Form' && isEditing) {
      titleText = 'Edit a Quiz';
    } else {
      titleText = 'Create a Quiz';
    }
  }
  return (
    <Tooltip title={title} arrow>
      <div style={{ fontSize: mobileView ? '20px' : '' }}>{titleText}</div>
    </Tooltip>
  );
};

const FocusedQuestionUpdater = ({
  setFocusedQuestion,
  setDisplayNumber,
  displayNumber,
  questions = [],
  mobileView,
}) => {
  useEffect(() => {
    if (!mobileView) {
      const questionsCount = questions?.length;
      const focused = questionsCount > 0 ? questions[questionsCount - 1] : null;
      const focusedIndex = questionsCount > 0 ? questionsCount - 1 : null;
      setFocusedQuestion(focusedIndex);
      setDisplayNumber(displayNumber);
    } else {
      setFocusedQuestion(null);
      setDisplayNumber(displayNumber);
    }
  }, [questions?.length, mobileView]);

  return null;
};

export default function ContentPage() {
  const { mobileView } = useScreenView();
  const formRef = useRef();
  const { isLoading, activeQuiz } = useSelector(state => state?.quizes);

  const [showUpdateShareModal, setShowUpdateShareModal] = useState(false);
  const [dontRemindAgain, setDontRemindAgain] = useState(activeQuiz.dontRemindAgain);
  const { history, location, query } = useRouter();
  const isEditing = query?.id;
  const isCreateOrEditQuizContentPage =
    location?.pathname?.includes('create-quiz') ||
    (location?.pathname?.includes('edit-quiz') && location?.pathname?.includes('content'));

  const isCreateOrEditFormContentPage =
    location?.pathname?.includes('create-form') ||
    (location?.pathname?.includes('edit-form') && location?.pathname?.includes('content'));

  const [showShareModal, setShowShareModal] = useState(false);

  const MobileTabs = [
    {
      name: 'Questions',
      onClick: val => {
        setSelectedTab(val);
      },
    },
    {
      name: 'Settings',
      onClick: val => {
        setSelectedTab(val);
      },
    },
  ];

  const [selectedTab, setSelectedTab] = useState(MobileTabs[0].name);

  const [focusedQuestion, setFocusedQuestion] = useState(mobileView ? null : 0);
  const [displayNumber, setDisplayNumber] = useState(mobileView ? null : 1);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowCustomizeResults(false));
  }, []);

  const togglePopup = () => {
    setShowModal(!showModal);
  };

  const handleBackClick = () => {
    if (validateArray() === true) {
      togglePopup();
    } else {
      history.goBack();
    }
  };

  function validateArray() {
    return (
      formRef.current?.values?.questions?.every(
        item =>
          convertToPlainText(item?.name)?.length > 0 &&
          item?.options?.length > 0 &&
          item.options.some(option => option.isCorrect),
      ) &&
      formRef.current.values.title.length > 0 &&
      formRef.current.values.isSaved === false &&
      ((formRef.current.values.passingPercentage > 0 && formRef.current.values.isPassingGradeRequired === true) ||
        formRef.current.values.isPassingGradeRequired === false)
    );
  }

  const saveQuiz = () => {
    if (activeQuiz.isPublished === true) {
      if (activeQuiz.dontRemindAgain === false) {
        setShowUpdateShareModal(true);
      } else {
        let updatedQuestions = formRef.current.values?.questions?.map(question => ({
          ...question,
          isDraft: question?.isDraft,
        }));
        let Form = {
          ...formRef.current.values,
          questions: updatedQuestions,
        };
        createUpdateQuiz(Form).then(res => {
          formRef.current.setFieldValue('isSaved', true);
          formRef.current.setFieldValue('questions', res.questions);
          dispatch(
            setActiveQuiz({
              ...res,
              isSaved: true,
            }),
          );
          togglePopup();
          history.goBack();
        });
      }
    } else {
      let updatedQuestions = formRef.current.values.questions.map(question => ({
        ...question,
        isDraft: question.isDraft,
      }));
      let Form = {
        ...formRef.current.values,
        questions: updatedQuestions,
      };
      createUpdateQuiz(Form).then(res => {
        formRef.current.setFieldValue('questions', res.questions);
        dispatch(
          setActiveQuiz({
            ...res,
            isSaved: true,
          }),
        );
        togglePopup();
        history.goBack();
      });
    }
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          title: activeQuiz.title,
          userId: activeQuiz.userId,
          useCase: activeQuiz.useCase,
          type: activeQuiz.type,
          quizType: activeQuiz.quizType,
          isPassingGradeRequired: activeQuiz.isPassingGradeRequired,
          passingPercentage: activeQuiz.passingPercentage,
          sendResultToClients: activeQuiz.sendResultToClients,
          isBrandingColorEnabled: activeQuiz.isBrandingColorEnabled,
          isPublished: activeQuiz.isPublished,
          questions:
            activeQuiz?.questions?.length === 0 && activeQuiz.type === 'Form'
              ? [dummyQuestionForm]
              : activeQuiz?.questions?.length === 0 && activeQuiz.type === 'Quiz'
              ? [dummyQuestionQuiz]
              : activeQuiz?.questions,
          passResultData: activeQuiz?.passResultData ?? {},
          noResultData: activeQuiz?.noResultData ?? {},
          failResultData: activeQuiz?.failResultData ?? {},
          isSaved: activeQuiz?.isSaved ?? false,
          id: activeQuiz?.id ?? null,
          dontRemindAgain: activeQuiz.dontRemindAgain ?? false,
          isPublished: activeQuiz.isPublished ?? false,
          isConfirmationEmailEnabled: activeQuiz.isConfirmationEmailEnabled ?? false,
        }}
        validationSchema={Yup.object().shape({
          questions: Yup.array().of(
            Yup.object().shape({
              type: Yup.string(),
              description: Yup.string(),
              isOptional: Yup.bool(),
              options: Yup.array().of(Yup.string()),
            }),
          ),
        })}
        onSubmit={() => {}}
      >
        {formProps => {
          const {
            values: { questions, passResultData, failResultData, noResultData, title, type },
            setFieldValue,
          } = formProps;

          return (
            <CreateQuizMain
              headerProps={{
                handleBackClick,
                isCreateOrEditQuizContentPage,
                isCreateOrEditFormContentPage,
                headerTitle: <HeaderTitle {...{ title, type, isEditing }} />,
              }}
            >
              <>
                <FocusedQuestionUpdater
                  {...{ setFocusedQuestion, questions, setDisplayNumber, displayNumber, mobileView, setFieldValue }}
                />
                <QuizesContentPageLayout
                  header={
                    <MobileHeader isEditing={isEditing} setFieldValue={setFieldValue} values={formProps?.values} />
                  }
                  subHeader={<TabsHeader tabs={MobileTabs} activeTab={selectedTab} />}
                  selectedTab={selectedTab}
                  focusedQuestion={focusedQuestion}
                  leftCol={
                    <LeftSidebarColumn
                      children={
                        <LeftColumn
                          focusedQuestionIndex={focusedQuestion}
                          setFocusedQuestion={setFocusedQuestion}
                          setDisplayNumber={setDisplayNumber}
                          displayNumber={displayNumber}
                          formProps={formProps}
                          mobileView={mobileView}
                        />
                      }
                      mobileView={mobileView}
                    />
                  }
                  centerCol={
                    <MainContentColumn>
                      <CenterColumn
                        {...{
                          focusedQuestion,
                          questions,
                          setFieldValue,
                          passResultData,
                          failResultData,
                          noResultData,
                          setDisplayNumber,
                          displayNumber,
                          formProps,
                          mobileView,
                          onBlackClick: () => {
                            setFocusedQuestion(null);
                            dispatch(setShowCustomizeResults(false));
                          },
                        }}
                      />
                    </MainContentColumn>
                  }
                  rightCol={<RightSidebarColumn children={<RightColumn {...{ formProps }} />} />}
                />
              </>
            </CreateQuizMain>
          );
        }}
      </Formik>

      <ContentPageExitModal
        type={activeQuiz.type}
        showModal={showModal}
        togglePopup={togglePopup}
        savenexit={saveQuiz}
        wosavenexit={() => {
          togglePopup();
          history.goBack();
        }}
      />
      <Modal
        isOpen={showUpdateShareModal}
        mobileView={mobileView}
        onSubmit={() => {
          let updatedQuestions = formRef.current.values?.questions?.map(question => ({
            ...question,
            isDraft: question?.isDraft,
          }));
          let Form = {
            ...formRef.current.values,
            questions: updatedQuestions,
            dontRemindAgain: dontRemindAgain,
          };
          createUpdateQuiz(Form).then(res => {
            formRef.current.setFieldValue('isSaved', true);
            formRef.current.setFieldValue('questions', res.questions);
            dispatch(
              setActiveQuiz({
                ...res,
                isSaved: true,
              }),
            );
          });
          setShowUpdateShareModal(false);
        }}
        title={`Save ${activeQuiz.type}`}
        cancelTitle={'Cancel'}
        onCancel={() => {
          setShowUpdateShareModal(false);
        }}
        modalType={'Quizes'}
        maxWidth={'900px'}
        submitTitle={'Save'}
      >
        <div className={classes.remind_again}>
          Any further changes made to the {activeQuiz.type === 'Quiz' ? 'quiz' : 'form'} will only affect future users
          taking the {activeQuiz.type === 'Quiz' ? 'quiz' : 'form'}, not those who have already completed the previous
          version.
          <FormControlLabel
            className={classes.MuiFormControlLabel_root}
            checked={dontRemindAgain}
            onChange={() => {
              setDontRemindAgain(!dontRemindAgain);
              updateRemindAhain(activeQuiz.id, !dontRemindAgain).then(res => {
                // formRef.current.setFieldValue('questions', res.questions);
                formRef.current.setFieldValue('dontRemindAgain', res.dontRemindAgain);
                dispatch(
                  setActiveQuiz({
                    ...formRef.current.values,
                    dontRemindAgain: res.dontRemindAgain,
                  }),
                );
              });
            }}
            control={
              <Checkbox
                classes={{
                  root: classes.checkbox_root,
                }}
                color="primary"
              />
            }
            label={'Don’t remind me again'}
            name="default"
          />
        </div>
      </Modal>
      <PublishModal
        isOpen={showShareModal}
        onSubmit={() => {
          setShowShareModal(false);
        }}
        onCancel={() => {
          setShowShareModal(false);
        }}
      />
    </>
  );
}
