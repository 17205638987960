import withStyles from '@material-ui/core/styles/withStyles';
import styled, { css } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import Select from 'components/FormUI/Select';
import { colors, toRem } from 'utils/styles/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Input from 'components/FormUI/Input';
import CustomExpansionPanel from 'pages/CreateContribution/components/ExpansionPanel';
import FormControl from '@material-ui/core/FormControl';

export const StyledHeader = styled.div`
  /* font-size: 1rem; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
`;

export const StyledText = styled.span`
  /* font-size: ${toRem(16)}; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
`;

export const SelectStyled = styled(Select)`
  .MuiSelect-select {
    padding: 7px 12px;
  }
`;

export const SelectWrapStyled = styled.div`
  margin: 8px 0 4px 0;
`;

export const StyledSummaryBodyContainer = styled.div`
  padding: 1.25rem 1.5rem;

  & > *:not(first) {
    margin-top: 12px;
  }
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: ${({ fontWeight }) => fontWeight || '500'};
    line-height: 30px;
    letter-spacing: 0.1px;

    ${({ fontSize }) =>
      fontSize &&
      css`
        font-size: ${fontSize || '1rem'} !important;
      `}
  }
`;

export const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: -4px;
  top: calc(50%);
  transform: translateY(-50%);
`;

export const StyledTooltipWrap = styled.span`
  position: relative;
  padding-right: 7px;
  display: inline-block;
`;

export const BlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: colors.darkOceanBlue,
    },
  },
  checked: {},
})(Checkbox);

export const BlueCheckboxForAccordianSummaryStyled = styled(BlueCheckbox)`
  /* padding-left: 0; */
  margin-left: -5px;
`;

export const InputStyled = styled(Input)`
  & * {
    font-size: 1rem;
    font-weight: 400;
  }
  .MuiInputBase-root,
  .MuiInputBase-input {
    font-size: 1rem;
  }
  .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
  }
  .MuiFormHelperText-root.Mui-error {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
`;

export const SummaryPricingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    display: inline;
  }
  & > .value-title {
    /* margin-left: 0.3rem; */
    font-size: 1rem;
    font-weight: 700;
  }
  & > .value-body {
    /* margin-left: 0.3rem; */
    font-size: 1rem;
    font-weight: 400;
  }
  & > .value-title-group {
    /* margin-top: inherit; */
    font-size: 1rem;
  }
  & > .value-body-item {
    /* display: inherit; */
    display: block;
    font-size: 1rem;
    /* font-weight: 400; */
  }
`;

export const FormControlFullWidthStyled = styled(FormControl)`
  > :last-child > div {
    margin-bottom: 0px !important;
  }
`;
