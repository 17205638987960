import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AddOrUpdateApplicationForm } from 'services/applications.service';
import Modal from 'components/UI/Modal';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { useAccount, useShallowEqualSelector } from 'hooks';
import Loader from 'components/UI/Loader';
import ApplicationForm, { DEFAULT_SELECETED_CONTRIBUTION } from './ApplicationForm';
import { determineDarkThemedColorToUse } from 'services/contributions.service';
import styled, { css } from 'styled-components';
import useScreenView from 'hooks/useScreenView';

const ModalStyled = styled(Modal)`
  .modal-footer-container {
    ${({ isScrollVisible }) =>
      isScrollVisible
        ? css`
            margin-right: 10px;
          `
        : css`
            margin-right: -5px;
          `}
  }
`;

function CreateApplicationFormModal({
  showApplicationFormPopup,
  setShowApplicationFormPopup,
  onBack,
  template,
  forms,
  isEditMode,
  contributionViewMode = false,
  setTemplate,
  onSuccess,
  onCancel,
  onSubmit,
  modalType,
  setContributionId,
}) {
  const [questions, setQuestions] = useState([]);
  const [savedQuestion, setSavedQuestions] = useState([]);
  const [selectedContribution, setSelectedContribution] = useState(DEFAULT_SELECETED_CONTRIBUTION);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { id, applicationFormViewModel } = useContribution();
  const [createOrUpdate, setCreateOrUpdate] = useState('Create');
  const [submitButtonTitle, setSubmitButtonTitle] = useState('Save and Exit');
  const [fetchForm, setFechForm] = useState(isEditMode);
  const [questionsScrollIsVisible, setQuestionsScrollIsVisible] = useState(false);
  const [draftQuestion, setDraftQuestion] = useState(false);
  const [title, setTitle] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [saveChangesModal, setSaveChangesModal] = useState(false);
  const { mobileView } = useScreenView();
  const {
    user: { id: userId },
  } = useAccount();
  const contribution = useContribution();
  const isDarkModeEnabled = determineDarkThemedColorToUse(contribution);

  useEffect(() => {
    setErrorMessage('');
  }, [questions]);

  useEffect(() => {
    if (selectedContribution === 'no-contriution-selected') {
      setCreateOrUpdate('Next');
      setSubmitButtonTitle('Next');
    }
    if (fetchForm && !savedQuestion.length) {
      setCreateOrUpdate('Create');
      setSubmitButtonTitle('Save and Exit');
    }
    if (applicationFormViewModel || (selectedContribution && savedQuestion?.length)) {
      setCreateOrUpdate('Update');
      setSubmitButtonTitle('Save and Exit');
    }
  }, [selectedContribution, fetchForm, applicationFormViewModel, savedQuestion]);

  const handleSubmitQuestion = () => {
    setIsLoading(true);
    const ApplicationFormdata = {
      userId,
      questions,
      profileId: null,
      contributionId: id || (selectedContribution === DEFAULT_SELECETED_CONTRIBUTION ? '' : selectedContribution),
    };
    AddOrUpdateApplicationForm(ApplicationFormdata)
      .then(() => {
        setShowApplicationFormPopup(false);
        setIsLoading(false);
        setSelectedContribution(null);
        setQuestions([]);
        if (setContributionId) setContributionId(selectedContribution);
        if (createOrUpdate !== 'Update') onSuccess();
      })
      .catch(err => {
        setErrorMessage(err?.response?.data ? err?.response?.data[0]?.errorMessage : '');
        setIsLoading(false);
      })
      .finally(() => {
        onSubmit();
      });
  };

  const onFormCancel = () => {
    setSelectedContribution(DEFAULT_SELECETED_CONTRIBUTION);
    setShowApplicationFormPopup(false);
    onCancel();
  };

  const onFormSubmit = () => {
    if (!fetchForm && selectedContribution !== DEFAULT_SELECETED_CONTRIBUTION) {
      setFechForm(true);
      return;
    }
    setErrorMessage('');
    if (!questions.length) {
      setErrorMessage('Please select a contribution to continue');
      return;
    }
    if (!fetchForm) {
      setFechForm(true);
    }
    handleSubmitQuestion();
  };

  function deepEqualIgnoringLocalId(obj1, obj2) {
    if (obj1 === obj2) return true;
    if (obj1 == null || typeof obj1 !== 'object' || obj2 == null || typeof obj2 !== 'object') {
      return false;
    }
    const keys1 = Object.keys(obj1).filter(key => key !== 'id' && key !== 'localId' && key !== 'userResponse');
    const keys2 = Object.keys(obj2).filter(key => key !== 'id' && key !== 'localId' && key !== 'userResponse');
    if (keys1.length !== keys2.length) return false;
    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqualIgnoringLocalId(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }

  function compareQuestionArrays(arr1, arr2) {
    if (arr1.length === 0 && arr2 == null) {
      return true;
    }
    if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
      return false;
    }
    return arr1.every((obj1, index) => deepEqualIgnoringLocalId(obj1, arr2[index]));
  }

  return (
    <>
      <ModalStyled
        isScrollVisible={questionsScrollIsVisible}
        widthRequired="1000px"
        applyTheming
        submitTitle={submitButtonTitle}
        title={
          modalType === 'new'
            ? `${createOrUpdate.replace('Next', 'Create')} ${title ? 'Application Form ' : 'a client application for:'}`
            : 'Which Contribution do you want to apply this template to?'
        }
        isOpen={showApplicationFormPopup}
        cancelTitle={isEditMode || isCancel ? 'Cancel' : 'Back'}
        className={`create-application-form-modal ${
          contributionViewMode && isDarkModeEnabled ? 'contribution-dark-mode' : ''
        }`}
        isBackButton
        onSubmit={() => {
          onFormSubmit();
        }}
        onBack={() => {
          setTitle(false);
          setQuestions([]);
          setSavedQuestions([]);
          if (!isEditMode && !isCancel) {
            if (fetchForm && selectedContribution !== DEFAULT_SELECETED_CONTRIBUTION) {
              setFechForm(false);
              setSelectedContribution(DEFAULT_SELECETED_CONTRIBUTION);
              return;
            }
            onBack();
            return;
          }
          if (!compareQuestionArrays(questions, contribution?.applicationFormViewModel?.questions)) {
            setSaveChangesModal(true);
            return;
          }
          setSelectedContribution(DEFAULT_SELECETED_CONTRIBUTION);
          setIsCancel(false);
          setShowApplicationFormPopup(false);
          onCancel();
        }}
        onCancel={() => {
          if (!compareQuestionArrays(questions, contribution?.applicationFormViewModel?.questions)) {
            setSaveChangesModal(true);
            return;
          }
          onFormCancel();
        }}
        disableOverlayClick
      >
        <>
          <ApplicationForm
            questions={questions}
            setQuestions={setQuestions}
            setSavedQuestions={setSavedQuestions}
            selectedContribution={selectedContribution}
            setSelectedContribution={setSelectedContribution}
            template={template}
            setTemplate={setTemplate}
            fetchForm={fetchForm}
            setFechForm={setFechForm}
            forms={forms}
            contributionViewMode={contributionViewMode}
            questionsScrollIsVisible={questionsScrollIsVisible}
            setQuestionsScrollIsVisible={setQuestionsScrollIsVisible}
            draftQuestion={draftQuestion}
            setDraftQuestion={setDraftQuestion}
            setTitle={setTitle}
            setIsCancel={setIsCancel}
          />
          {errorMessage && <p style={{ color: 'red', margin: '10px' }}>{errorMessage}</p>}
        </>
        {isLoading && <Loader />}
      </ModalStyled>
      {saveChangesModal && (
        <Modal
          isOpen={saveChangesModal}
          applyTheming
          mobileView={mobileView}
          title="Unsaved Changes"
          submitTitle="Save Changes"
          cancelTitle="Discard Changes"
          onSubmit={() => {
            onFormSubmit();
          }}
          onCancel={() => {
            setSaveChangesModal(false);
            onFormCancel();
          }}
          onCloseClick={() => {
            setSaveChangesModal(false);
          }}
          closeClick={true}
          shrinkCancelButton={true}
          className="confirmation-modal"
        >
          <p style={{ color: isDarkModeEnabled ? '#fff' : '#000' }}>
            You have unsaved changes that will be lost if you close this popup. Do you want to save your changes before
            closing?
          </p>
        </Modal>
      )}
    </>
  );
}
CreateApplicationFormModal.propTypes = {
  setShowApplicationFormPopup: PropTypes.func,
  onSuccess: PropTypes.func,
  showApplicationFormPopup: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func.isRequired,
  template: PropTypes.shape({}).isRequired,
  forms: PropTypes.shape([]).isRequired,
  setTemplate: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  contributionViewMode: PropTypes.bool,
};
CreateApplicationFormModal.defaultProps = {
  setShowApplicationFormPopup: () => {},
  onSuccess: () => {},
  onCancel: () => {},
  onSubmit: () => {},
  showApplicationFormPopup: false,
  isEditMode: false,
  contributionViewMode: false,
};
export default CreateApplicationFormModal;
