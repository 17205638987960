import React, { useState } from 'react';
import CreateQuizMain from 'pages/CreateQuizes/Components/Containers/MainContainer/MainContainer';
import useRouter from 'hooks/useRouter';
import classes from './PreviewPage.module.scss';
import styled from 'styled-components';
import CheckIcon from '../QuizContent/Assests/Icons/checkIcon.png';
import Button from 'components/FormUI/Button';
import CorrectIcon from './Assests/Icons/CorrectIcon';
import IncorrectIcon from './Assests/Icons/IncorrectIcon';
import { MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { convertToPlainText } from 'utils/utils';

const StyledImg = styled.div`
  background-image: url(${({ previewImg }) => previewImg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
  height: 50%;
  display: inline-block;
  border-radius: 40px;
  align-items: center;
  z-index: 1;
`;
const value = `<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.<br />\n<br />\n<img alt=\"\" src=\"https://www.google.com/url?sa=i&amp;url=https%3A%2F%2Fwww.freepik.com%2Ffree-photos-vectors%2Fbackground&amp;psig=AOvVaw0F9hJ6EaHOVB0Bdky-HyQQ&amp;ust=1720678298412000&amp;source=images&amp;cd=vfe&amp;opi=89978449&amp;ved=0CBQQjRxqFwoTCNi-2abom4cDFQAAAAAdAAAAABAE\" /><img alt=\"\" src=\"https://www.google.com/url?sa=i&amp;url=https%3A%2F%2Fwww.freepik.com%2Ffree-photos-vectors%2Fbackground&amp;psig=AOvVaw0F9hJ6EaHOVB0Bdky-HyQQ&amp;ust=1720678298412000&amp;source=images&amp;cd=vfe&amp;opi=89978449&amp;ved=0CBQQjRxqFwoTCNi-2abom4cDFQAAAAAdAAAAABAE\" /><img alt=\"\" height=\"200\" src=\"https://img.freepik.com/free-vector/realistic-blurred-spring-background_52683-55622.jpg?t=st=1720592315~exp=1720595915~hmac=ced29dd8c569af6238aa21d6f85f59a31e1a874ca66abc637e7fbdcef9e319e7&amp;w=1480\" width=\"300\" /></p>\n`;
export default function PreviewPage() {
  const [checked, setChecked] = useState(false);
  const [val, setVal] = useState('Text');

  const getFirstSentence = str => {
    let text = convertToPlainText(str);
    const indexOfDot = text.indexOf('.');
    return indexOfDot !== -1 ? text.substring(0, indexOfDot) : text;
  };
  return (
    <CreateQuizMain
      headerProps={{
        headerTitle: 'Quiz Title',
      }}
    >
      <div className={classes.container}>
        <div className={classes.preview_container}>
          <div className={classes.header}>1. {getFirstSentence(value)}</div>
          <div className={classes.question}>
            <div dangerouslySetInnerHTML={{ __html: value }}></div>
          </div>
          <div className={classes.options_container}>
            <div className={classes.sub_header}>Choose only one best answer</div>
            {[1, 2, 3, 4].map((item, index) => {
              return (
                <div className={classes.option_block}>
                  <div className={classes.option_left_panel}>
                    <IncorrectIcon />
                    <CorrectIcon />
                    <div
                      onClick={() => {
                        setChecked(!checked);
                      }}
                      className={checked ? classes.checkBoxChecked : classes.checkBoxUnchecked}
                    >
                      {checked === true && <StyledImg previewImg={CheckIcon} />}
                    </div>
                  </div>
                  <div className={classes.option_right_panel} dangerouslySetInnerHTML={{ __html: value }}></div>
                </div>
              );
            })}
            <Select
              style={{ overflow: 'hidden' }}
              className="select-field"
              variant="outlined"
              fullWidth
              defaultValue={0}
              input={<OutlinedInput margin="dense" />}
              onChange={e => {
                setVal(e.target.value);
              }}
              value={val}
            >
              {[
                { title: 'Test', value: 'Text' },
                { title: 'Test1', value: 'Text1' },
                { title: 'Test2', value: 'Text2' },
              ].map(data => {
                return (
                  <MenuItem
                    disabled={false}
                    style={{
                      fontFamily: 'Avenir',
                      fontWeight: val === data.value ? '500' : '300',
                      fontSize: '16px',
                      color: '#213649',
                    }}
                    value={data.value}
                  >
                    {data.title}
                  </MenuItem>
                );
              })}
            </Select>
            <div className={classes.footer}>
              <div className={classes.banner}>This answer is incorrect.</div>
              <div className={classes.bannerCorrect}>This answer is correct.</div>
              <Button variant="secondary" autoWidth onClick={() => {}}>
                Confirm Answer
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CreateQuizMain>
  );
}
