import React from 'react';

const RatingIcon = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 6.10352e-05L6.12257 3.45498H9.75528L6.81636 5.59023L7.93893 9.04515L5 6.90989L2.06107 9.04515L3.18364 5.59023L0.244718 3.45498H3.87743L5 6.10352e-05Z"
        fill="#213649"
      />
    </svg>
  );
};

export default RatingIcon;
