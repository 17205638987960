import Button from 'components/FormUI/Button';
import Modal from 'components/UI/Modal';
import React from 'react';

export default function ContentPageExitModal({ showModal, togglePopup, savenexit, wosavenexit, type }) {
  return (
    <Modal
      title="Save before you exit?"
      isOpen={showModal}
      onSubmit={savenexit}
      onCancel={wosavenexit}
      closePopUpFn={togglePopup}
      closePopUp={true}
      submitTitle={'Save & Exit'}
      cancelTitle={'Exit Without Saving'}
      modalType={'Quizes'}
      maxWidth={'900px'}
      extraFooterElements={
        <>
          <Button
            className="btn-left"
            autoWidth
            backgroundColor={'white'}
            invert
            style={{
              paddingTop: '10px',
              paddingBottom: '10px',
              borderColor: '#000000DE',
              borderWidth: '1px',
              borderRadius: '5px',
              color: '#000000DE',
              marginRight: '15px',
            }}
            onClick={togglePopup}
          >
            Cancel
          </Button>
        </>
      }
    >
      <div style={{ width: '100%' }}>
        Save your {type === 'Quiz' ? 'quiz' : 'form'} before you exit or you will loose your progress.
      </div>
    </Modal>
  );
}
