import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { mdiShareVariant } from '@mdi/js';
import { fetchUpcomingCreated, fetchUpcomingCreatedWithType } from 'actions/contributions';
import VideoButton from 'components/UI/ListButtons/VideoButton';
import EditButton from 'components/UI/ListButtons/EditButton';
import ChatButton from 'components/UI/ListButtons/ChatButton';
import ViewButton from 'components/UI/ListButtons/ViewButton';
import Modal from 'components/UI/Modal';
import { formatMoney } from 'utils/datesAndMoney';
import { getContributionStatusView } from 'utils/getContributionStatusView';
import { ContributionStatus } from 'helpers/constants';
import { useAccount, useRouter, useHttp } from 'hooks';
import { useRecordModal } from 'hooks/useSessionRecordModal';
import ShareModal from 'pages/ContributionView/components/ShareModal/ShareModalContainer';
import ShareIcon from 'assets/share-icon.svg';
import { shareViaEmail } from 'services/contributions.service';
import moment from 'moment';
import usePaidTier from 'hooks/usePaidTier';
import { Icon as Icon1 } from '@mdi/react';
import { TooltipWrapper } from '../UI/TooltipWrapper';
import ClickDropDown, { StyledDropDownItem } from '../../components/UI/ClickDropDown';
import { StyledLink } from '../../pages/CreateContribution/style';
import { BodyText } from 'components/UI/Text/TextStyles';
import Loader from 'components/UI/Loader';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { fetchCohealerContribution } from 'actions/contributions';
import * as contributionActions from 'actions/contributions';
import getBackPages from 'utils/getBackPages';
import { makeStyles } from '@material-ui/core';
import AnalyticsButton from 'components/UI/ListButtons/AnalyticsButton';
import { ConversionMetricsModal } from 'components/Modals/ConversionMetricsModal';
import { getContributionTypeView } from 'utils/getContributionTypeView';
import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import { getBoughtByType, getClientContribution, getCohealerContribution } from 'services/contributions.service';
import * as paidTier from 'selectors/paidTier';
import { isPaidTierAnnualSelector } from 'selectors/user';
import useShallowEqualSelector from '../../hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from '../../constants';
import { UserRoles } from 'helpers/constants';
const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
  },
})(TableCell);
const NarrowTableCellMore = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 30,
  },
})(TableCell);
const StyledShareButton = styled(Button)`
  float: right;
  margin-top: 5px;
  text-transform: none;
`;

const Icon = styled.img`
  vertical-align: middle;
  object-fit: contain;
`;

const LabelBadgeStyles = styled.div`
  display: flex;
  white-space: nowrap;
  padding: 0.4375rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  border-radius: 0.25rem;
  border: 1px solid var(--Cohere-Secondary-Brown, #c9b382);
  background: rgba(201, 179, 130, 0.1);

  height: fit-content;
  width: fit-content;
`;

const StyledLabelTypeBadge = styled(LabelBadgeStyles)`
  border-color: var(--Cohere-Secondary-Brown, #c9b382);
  background-color: rgba(201, 179, 130, 0.1);

  div.text {
    color: var(--Cohere-Primary-Brown, #a58b62);
  }
`;

const TooltipText = 'There are no clients for this contribution yet';
export const TRIAL_PERIOD_START_DATE = '2021-11-01';
export const TRIAL_PERIOD_DAYS = 13;

const useStyles = makeStyles(theme => ({
  button: {
    // paddingLeft: '0px',
  },
}));

export const Contribution = ({
  id,
  type,
  isWorkshop,
  closestSession,
  studentsNumber,
  title,
  isArchived,
  earnedRevenue,
  status,
  setPopupText,
  reasonDescription,
  currency,
  symbol,
  onLaunchSession,
  paymentInfo,
  archivingAllowed,
  deletingAllowed,
  isInvoiced,
  userId: contributionUserId,
  index,
  rows,
  deleteIndex,
  archiveIndex,
  isAll,
}) => {
  const classes = useStyles();
  const { history, pathname } = useRouter();
  const dispatch = useDispatch();

  const [isLoader, setIsLoader] = useState({
    paidTierPlans: false,
    activePaidTierPlan: false,
    academyMemberships: false,
    activeAcademyMemberships: false,
  });

  const { currentRole } = useAccount();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const activePaidTierCurrentPaymentPeriod = useShallowEqualSelector(
    paidTier.activePaidTierCurrentPaymentPeriodSelector,
  );

  const academyContributions = useSelector(paidTier.cohereAcademyContributionsSelector);
  const activeAcademyContributions = useSelector(paidTier.activeCohereAcademyContributionsSelector);

  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%
  const filterMembershipById = useCallback(
    res => {
      const activeMembershipsArray = activeAcademyContributions;

      const matchedMembershipFromAll = academyContributions.find(membership => membership.id === res.id);

      if (!matchedMembershipFromAll) {
        return activeMembershipsArray;
      }

      const matchedMembershipFromActive = activeMembershipsArray.find(membership => membership.id === res.id);

      if (matchedMembershipFromActive) {
        return activeMembershipsArray;
      }

      activeMembershipsArray.push(matchedMembershipFromAll);

      return activeMembershipsArray;
    },
    [activeAcademyContributions],
  );

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  // const { isLaunchPlan } = usePaidTier();
  const { user } = useAccount();
  const { id: userId } = user;
  const [asCompletedModal, setAsCompletedModal] = useState(false);
  const onSubmit = () => {
    onLaunchSession(
      id,
      type,
      closestSession?.classGroupId,
      closestSession?.classId,
      closestSession?.chatSid,
      closestSession?.title,
      { RecordParticipantsOnConnect: true },
    );
  };
  const onCancel = () => {
    onLaunchSession(
      id,
      type,
      closestSession?.classGroupId,
      closestSession?.classId,
      closestSession?.chatSid,
      closestSession?.title,
      { RecordParticipantsOnConnect: false },
    );
  };
  const modalOptions = {
    onSubmit,
    onCancel,
    classId: closestSession?.classId,
    contributionType: type,
    contributionId: id,
    liveVideoServiceProvider: closestSession?.liveVideoServiceProvider,
    zoomStartUrl: closestSession?.zoomStartUrl,
  };
  const { RecordModal, launchRecodModal } = useRecordModal(modalOptions);
  const url =
    status === ContributionStatus.unfinished ? `/edit-contribution/${id}/basic` : `/contribution-view/${id}/about`;
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });

  const { request } = useHttp();
  const [modalOpened, setModalOpened] = useState(false);
  const [analyticsModalOpened, setAnalyticsModalOpened] = useState(false);
  const [asDeleteModal, setAsDeleteModal] = useState(false);
  const trialPeriodStart = moment(user.createTime);
  const trialPeriodEnd = moment(user.createTime).add(TRIAL_PERIOD_DAYS, 'days');
  const todayDate = moment(moment());
  const [isRescheduleModalOpen, setRescheduleModal] = useState(false);
  const [isDeleteModalOpen, setDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [duplicteContribution, setDuplicteContribution] = useState(false);
  const [iscontributionDuplicated, setContributionDuplicated] = useState(false);
  const handleSubmitDelete = () => {
    setDeleteModal(false);
  };
  const handleSubmit = () => {
    setRescheduleModal(false);
  };

  const handleAnalyticsModalOpen = () => {
    setAnalyticsModalOpened(true);
  };

  const handleAnalyticsModalClose = () => {
    setAnalyticsModalOpened(false);
  };

  const handleModalOpen = useCallback(() => {
    if (
      isLaunchPlan &&
      trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
      todayDate.isAfter(trialPeriodEnd)
    )
      return;
    setModalOpened(true);
  }, [setModalOpened, status, isLaunchPlan, user]);
  const handleModalClose = useCallback(() => {
    setModalOpened(false);
  }, [setModalOpened]);

  const onSubmitHandler = id => {
    setLoader(true);
    request('/Contribution/SetAsCompleted', 'POST', {
      contributionId: id,
    })
      .then(() => {
        archiveIndex(index);
      })
      .catch(console.dir);
  };

  const onSubmitDeleteHandler = id => {
    setLoader(true);
    request(`/Contribution/DeleteContribById/${id}`, 'DELETE')
      .then(() => {
        deleteIndex(index);
        let url = getBackPages(type, false);
        if (
          url.link == '/contributions/group-courses' ||
          url.link == '/contributions/memberships' ||
          url.link == '/contributions/workshop'
        ) {
          dispatch(fetchUpcomingCreatedWithType(userId, type));
        }
      })
      .catch(console.dir);
  };
  const onSubmitDuplicateContributionHandler = id => {
    setDuplicteContribution(false);
    dispatch(fetchCohealerContribution(id))
      .then(data => {
        const {
          userId = '',
          title = '',
          categories = [],
          type = '',
          previewContentUrls = [],
          isWorkshop = false,
          purpose = '',
          whoIAm = '',
          whatWeDo = '',
          preparation = '',
          whoIAmLabel = '',
          whatWeDoLabel = '',
          purposeLabel = '',
          preparationLabel = '',
          languageCodes = [],
          isSpeakingLanguageRequired = false,
          minAge = '',
          gender = [],
          activeTemplate = '',
          isCustomBrandingColorsActive = false,
          customLogo = '',
          isLegacyColorsActive = false,
          isMembersHiddenInCommunity = false,
          brandingColors = [],
          coachSelectedBrandingColors = [],
          paymentInfo = [],
          isCommunityFeatureHiddden = false,
          isLiveSessionMembersHidden = false,
          arePublicPostsAllowed = false,
          areClientPublicPostsAllowed = false,
          isGroupChatHidden = false,
          instagramUrl = '',
          linkedInUrl = '',
          youtubeUrl = '',
          facebookUrl = '',
          websiteUrl = '',
          tiktokUrl = '',
          customTitleForMeetYourCoach = '',
          contributionTagline = '',
          viewPreparation = false,
          viewPurpose = false,
          viewWhatWeDo = false,
          viewWhoIAm = false,
          allowClientsToInvite = false,
          inviteClientType = '',
          isDownloadEnabled = false,
          invitationOnly = false,
          sessions = [],
          isThankyouPageAdded,
          thankyouPage,
          clientRedirectLink,
        } = data.payload;
        const obj = {
          userId,
          title,
          categories,
          type,
          previewContentUrls,
          isWorkshop,
          purpose,
          whoIAm,
          whatWeDo,
          preparation,
          whoIAmLabel,
          whatWeDoLabel,
          purposeLabel,
          preparationLabel,
          languageCodes,
          isSpeakingLanguageRequired,
          minAge,
          gender,
          activeTemplate,
          isCustomBrandingColorsActive,
          customLogo,
          isLegacyColorsActive,
          isMembersHiddenInCommunity,
          brandingColors,
          coachSelectedBrandingColors,
          paymentInfo,
          isCommunityFeatureHiddden,
          isLiveSessionMembersHidden,
          arePublicPostsAllowed,
          areClientPublicPostsAllowed,
          isGroupChatHidden,
          instagramUrl,
          linkedInUrl,
          youtubeUrl,
          facebookUrl,
          websiteUrl,
          tiktokUrl,
          customTitleForMeetYourCoach,
          contributionTagline,
          viewPreparation,
          viewPurpose,
          viewWhatWeDo,
          viewWhoIAm,
          allowClientsToInvite,
          inviteClientType,
          isDownloadEnabled,
          invitationOnly,
          parentContributionId: id,
          sessions,
          isDuplicate: true,
          isThankyouPageAdded,
          thankyouPage,
          clientRedirectLink,
        };
        setLoader(true);
        dispatch(contributionActions.saveDraftContribution(obj))
          .then(res => {
            let url = getBackPages(type, false);
            let allContributionUrl = window.location.pathname;
            if (
              (url.link == '/contributions/group-courses' ||
                url.link == '/contributions/memberships' ||
                url.link == '/contributions/workshop') &&
              allContributionUrl !== '/contributions/all'
            ) {
              dispatch(fetchUpcomingCreatedWithType(userId, type));
            } else {
              dispatch(fetchUpcomingCreated(userId));
            }
            setLoader(false);
            setContributionDuplicated(true);
          })
          .catch(console.dir);
      })
      .catch(console.dir);
  };

  const fetchandRemove = type => {
    if (type === 'delete') {
      dispatch(fetchUpcomingCreated(userId));
      // deleteIndex(index);
      setAsDeleteModal(false);
      setLoader(false);
    } else if (type === 'archive') {
      dispatch(fetchUpcomingCreated(userId));
      // archiveIndex(index);
      setLoader(false);
    }
  };
  const handleModalSubmit = useCallback(
    emails => {
      shareViaEmail({ contributionId: id, emailAddresses: emails })
        .then(() => {
          handleModalClose();
        })
        .catch(err => {
          setEmailsError({ isError: true, errorMessage: err?.response?.data?.message });
        });
    },
    [handleModalClose],
  );

  const lastIndex = index === rows?.length - 1;
  const secondLast = index === rows?.length - 2;
  return (
    <>
      <TableRow>
        <NarrowTableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
          <Link to={url}>{title}</Link>
        </NarrowTableCell>
        <NarrowTableCell>
          {getContributionStatusView(status)}
          {status === ContributionStatus.rejected && <ViewButton handleClick={() => setPopupText(reasonDescription)} />}
        </NarrowTableCell>
        {isAll && (
          <NarrowTableCell component="th" scope="row">
            <StyledLabelTypeBadge>
              <div className="text">
                {type === 'ContributionCourse' && isWorkshop ? 'Workshop' : getContributionTypeView(type)}
              </div>
            </StyledLabelTypeBadge>
          </NarrowTableCell>
        )}
        {(!isArchived || closestSession?.isPrerecorded) && (
          <NarrowTableCell>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TooltipWrapper tooltipText={TooltipText} isShow={!closestSession}>
                <VideoButton
                  className={classes.button}
                  handleClick={launchRecodModal}
                  disabled={closestSession?.isPrerecorded || !closestSession}
                />
              </TooltipWrapper>
              <div
                style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer', minWidth: '65px' }}
                onClick={launchRecodModal}
              >
                {closestSession?.title}
              </div>
            </div>
          </NarrowTableCell>
        )}
        <NarrowTableCell align="center">
          {/* <TooltipWrapper> */}
          <StyledShareButton
            color="red"
            disabled={status != 'Approved' ? true : false}
            startIcon={
              status != 'Approved' ? (
                <Icon1 path={mdiShareVariant} color="rgba(0, 0, 0, 0.26)" size={1} />
              ) : (
                <Icon1 path={mdiShareVariant} color="#116582" size={1} />
              )
            }
            onClick={handleModalOpen}
          />
          {/* </TooltipWrapper> */}
        </NarrowTableCell>
        <NarrowTableCell align="center">{studentsNumber}</NarrowTableCell>
        <NarrowTableCell align="center">{symbol + formatMoney(earnedRevenue) + ' ' + currency}</NarrowTableCell>
        <NarrowTableCell>
          <TooltipWrapper tooltipText={TooltipText} isShow={!closestSession}>
            <ChatButton
              className={classes.button}
              disabled={!closestSession}
              handleClick={() =>
                closestSession?.chatSid &&
                history.push(`/conversations/all`, {
                  preferredConversationId: closestSession.chatSid,
                })
              }
            />
          </TooltipWrapper>
        </NarrowTableCell>
        {!isArchived &&
          (status == 'Completed' ? (
            <NarrowTableCell>
              <EditButton disabled={true} style={{ color: 'gray' }} className={classes.button} />
            </NarrowTableCell>
          ) : (
            <NarrowTableCell>
              <Link to={`/edit-contribution/${id}`}>
                <EditButton className={classes.button} />
              </Link>
            </NarrowTableCell>
          ))}
        <NarrowTableCell component="th" scope="row">
          <AnalyticsButton
            className={classes.button}
            disabled={status !== ContributionStatus.approved}
            handleClick={handleAnalyticsModalOpen}
          />
        </NarrowTableCell>
        {pathname.includes('contributions/archived') === false && (
          <NarrowTableCellMore>
            <ClickDropDown
              lastIndex={lastIndex}
              secondLast={secondLast}
              materialIcon={true}
              id="header-user-dropdown"
              title={''}
            >
              {contributionUserId === userId &&
                (status === ContributionStatus.unfinished || status === ContributionStatus.approved) &&
                type !== 'ContributionOneToOne' && (
                  <div>
                    <StyledDropDownItem
                      onClick={() => {
                        setDuplicteContribution(true);
                      }}
                    >
                      Duplicate
                    </StyledDropDownItem>
                  </div>
                )}
              <div onClick={() => (!archivingAllowed ? setRescheduleModal(true) : setAsCompletedModal(true))}>
                <StyledDropDownItem
                  className={!archivingAllowed && 'disableMe'}
                  onClick={() => {
                    setAsCompletedModal(true);
                  }}
                >
                  Archive
                </StyledDropDownItem>
              </div>
              {contributionUserId === userId && (
                <div onClick={() => (!deletingAllowed ? setDeleteModal(true) : setAsDeleteModal(true))}>
                  <StyledDropDownItem
                    className={!deletingAllowed && 'disableMe'}
                    onClick={() => {
                      setAsDeleteModal(true);
                    }}
                  >
                    Delete
                  </StyledDropDownItem>
                </div>
              )}
            </ClickDropDown>
          </NarrowTableCellMore>
        )}
      </TableRow>
      <Modal
        isOpen={isRescheduleModalOpen}
        onCancel={() => {
          setRescheduleModal(false);
        }}
        onSubmit={handleSubmit}
        title="Archive Contribution"
        hiddenCancel
        disableConfirm
      >
        <BodyText>
          You are allowed to archive if you have no outstanding sessions with clients or have no unused package sessions
          with clients. Once archived, this will appear in the "Archived" section under "My Contributions" on your
          dashboard. If you want to re-launch this service, you will need to create a new Contribution.
        </BodyText>
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onCancel={() => {
          setDeleteModal(false);
        }}
        onSubmit={handleSubmitDelete}
        title="Delete Contribution"
        hiddenCancel
        disableConfirm
        brandingColor={false}
      >
        <BodyText>
          Since you have enrolled clients, you cannot delete this contribution as they will lose access. You can archive
          this contribution. Or, if you still wish to delete this contribution, please contact{' '}
          <StyledLink href="support@cohere.live" rel="noopener noreferrer" target="_blank">
            (support@cohere.live).
          </StyledLink>{' '}
          <br />
        </BodyText>
      </Modal>
      <Modal
        isOpen={asDeleteModal}
        title="Delete Contribution"
        submitTitle="OK"
        onSubmit={() => {
          onSubmitDeleteHandler(id);
        }}
        onCancel={() => {
          setAsDeleteModal(false);
        }}
        brandingColors={false}
      >
        <p>
          Everything associated with this contribution will be deleted from your account. Are you sure you wish to
          continue?
        </p>
      </Modal>
      <Modal
        isOpen={duplicteContribution}
        title="Duplicate Contribution"
        submitTitle="OK"
        onSubmit={() => {
          onSubmitDuplicateContributionHandler(id);
        }}
        onCancel={() => {
          setDuplicteContribution(false);
        }}
      >
        <p>Are you sure you want to duplicate the contribution?</p>
      </Modal>
      <Modal
        isOpen={iscontributionDuplicated}
        hiddenCancel
        title="Duplicated Successfully"
        submitTitle="OK"
        onSubmit={() => {
          setContributionDuplicated(false);
        }}
        onCancel={() => {
          setContributionDuplicated(false);
        }}
      >
        <p>Your contribution has been duplicated. Please note that Step 3 & 4 may requires additional information.</p>
      </Modal>
      {modalOpened && (
        <ShareModal
          isOpen={modalOpened}
          onSubmit={handleModalSubmit}
          onModalClose={handleModalClose}
          emailsError={emailsError}
          setEmailsError={setEmailsError}
          contributionId={id}
          paymentInfo={paymentInfo}
          type={type}
          isInvoiced={isInvoiced}
          symbol={symbol}
        />
      )}

      {analyticsModalOpened && (
        <ConversionMetricsModal
          isOpen={analyticsModalOpened}
          contributionId={id}
          handleClose={handleAnalyticsModalClose}
        />
      )}

      <Modal
        isOpen={asCompletedModal}
        title="Archive this Contribution"
        submitTitle="Yes I’m Sure. Archive my Contribution."
        onSubmit={() => {
          onSubmitHandler(id);
        }}
        onCancel={() => {
          setAsCompletedModal(false);
        }}
      >
        <p>
          Alert: Once you archive a Contribution, this cannot be undone. You are allowed to archive if you have no
          outstanding sessions with clients or have no unused package sessions with clients . Once archived, this will
          appear in the “Archived” section under “My Contributions” on your dashboard. If you want to re-launch this
          service, you will need to create a new Contribution.
        </p>
      </Modal>

      <RecordModal />
      {loader && <Loader />}
    </>
  );
};

Contribution.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  closestSession: PropTypes.shape({
    chatSid: PropTypes.string,
  }),
  studentsNumber: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isArchived: PropTypes.bool,
  isAll: PropTypes.bool,
  earnedRevenue: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  setPopupText: PropTypes.func.isRequired,
  reasonDescription: PropTypes.string,
  onLaunchSession: PropTypes.func.isRequired,
  isInvoiced: PropTypes.string,
};

Contribution.defaultProps = {
  closestSession: undefined,
  isArchived: undefined,
  isAll: false,
  reasonDescription: undefined,
};
