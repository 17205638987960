import { ContributionType } from 'helpers/constants';

export const PURCHASE_MODAL_TITLES = {
  [ContributionType.contributionCourse]: {
    button: 'Purchase',
    modal: 'Purchase Group Course',
    submit: 'Reserve',
    join: (contributionName, isLogin) => (isLogin ? 'You’re in!' : `Join ${contributionName}`),
  },
  [ContributionType.contributionMembership]: {
    button: 'Purchase',
    modal: 'Purchase Membership',
    submit: 'Reserve',
    join: (contributionName, isLogin) => (isLogin ? 'You’re in!' : `Join ${contributionName}`),
  },
  [ContributionType.contributionOneToOne]: {
    button: 'Reserve 1:1 Session',
    modal: 'Reserve 1:1 Session',
    submit: 'Reserve',
  },
  [ContributionType.contributionLocalEvent]: {
    button: '',
    modal: '',
    submit: '',
  },
};

export const PURCHASE_MODAL_STEPS = {
  loggedIn: 'loggedIn',
  memberLoggedIn: 'memberLoggedIn',
  init: 'init',
  memberInit: 'memberInit',
  login: 'login',
  memberLogin: 'memberLogin',
  create: 'create',
  memberCreate: 'memberCreate',
  join: 'join',
  joinLogin: 'joinLogin',
  joinCreate: 'joinCreate',
  enrollmentForm: 'enrollmentForm',
  joinEnrollmentForm: 'joinEnrollmentForm',
};

export const ACCOUNT_FORM_HEADERS = {
  loggedIn: '',
  init: 'Complete Your Enrollment',
  join: 'Login or Create Your Account',
  joinEasyBooking: 'Complete Your Booking',
  login: 'Login to Your Account',
  create: 'Create Your Account',
};

export const ACCOUNT_FORM_FIELDS = {
  confirmEmail: 'ConfirmEmail',
  email: 'Email',
  password: 'Password',
  firstName: 'FirstName',
  lastName: 'LastName',
  timeZoneId: 'TimeZoneId',
  signature: 'Signature',
  phone: 'phoneNo',
  state: 'stateCode',
  country: 'countryId',
  sessionReminder: 'sessionReminders',
  details: 'details',
};

export const ACCOUNT_FORM_FIELDS_LABELS = {
  email: 'Email',
  confirmEmail: 'Confirm email',
  password: 'Password',
  firstName: 'First name',
  lastName: 'Last name',
  timezone: 'Timezone',
  phone: 'Phone',
  state: 'State',
  country: 'Country',
};

export const DISCOUNT_CODE_FIELDS = {
  coupon: 'Coupon',
};

export const RESTORE_PASS = 'restore-pass';
