import React, { useState, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { SearchBarComponent } from '.';
import CoachImage from 'assets/chatlogo.png';
import Avatar from '@material-ui/core/Avatar';
import useAccount from 'hooks/useAccount';
import UserMentionInput from 'components/UI/mentions/UserMentionInput';
import { convertToPlainText } from 'utils/utils';
import moment from 'moment';
import * as R from 'ramda';
import * as contentService from 'services/content.service';
import { createMutiplePosts } from 'services/community.service';
import filter from 'lodash/filter';
import { Attachments } from '../../pages/ContributionView/ContributionSocial/components/Attachments/Attachments';
import { useMediaQuery, useTheme, Grid, Button, Checkbox, Box } from '@material-ui/core';
import { BsCameraVideo } from 'react-icons/bs';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import { colors } from 'utils/styles';
import Modal from 'components/UI/Modal';
import { useSelector } from 'react-redux';
import { CKEditor } from 'ckeditor4-react';
import { RxCrossCircled } from 'react-icons/rx';
import { useHttp } from 'hooks/';
import TextField from 'components/UI/TextField';
import {
  StyledSelect,
  useStyles,
} from 'pages/ContributionView/ContributionCommunication/components/ContributionCommunicationStyled';
import { determineColorToUse } from 'services/contributions.service';
import { Formik, FieldArray } from 'formik';
import TextArea from 'components/FormUI/TextArea';
import FacebookIcon from 'assets/FacebookIcon.svg';
import InstagramIcon from 'assets/InstagramIcon.svg';
import PhotoVideo from 'assets/photovideo.svg';
import LiveVideo from 'assets/livevideo.svg';
import GalleryIcon from 'assets/GalleryIcon.svg';
import {
  editPost,
  notifyTaggedUsers,
  post,
  removeAttachment,
  removeAttachmentForUnifiedCommunity,
} from 'services/community.service';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { UserRoles } from 'helpers/constants';

const StyledCrossedIcon = styled(RxCrossCircled)`
  width: 25px;
  height: 25px;
  color: ${({ color }) => color};
`;

const StyledInput = styled.div``;

const StyledProgressWrap = styled(Box)`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
`;

const VideoIcon = styled(BsCameraVideo)`
  width: 30px;
  color: ${({ color }) => color};
  height: 30px;
  margin-right: 5px;
`;

const Heading = styled.div`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 300;
  line-height: 13px;
  color: black;
  margin-bottom: 10px;
`;

const PhotoIcon = styled(MdOutlinePhotoCamera)`
  color: ${({ color }) => color};
  width: 30px;
  height: 30px;
  margin-right: 5px;
`;
const StyledCross = styled(RxCrossCircled)``;
const StyledRemove = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 8px;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
`;

const StyledHashtag = styled.div`
  height: 25px;
  background-color: ${({ color }) => color};
  color: white;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  width: auto;
  word-wrap: break-word;
  position: relative;
  // border-radius: 5px;
  margin-right: 15px;
  min-width: 40px;
`;

const StyledContainer = styled.div`
  width: 100%;
  // height: 150px;
  max-width: 700px !important;
  border-radius: 4px;
  padding: 20px;
  margin-top: 30px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 3.3631110191345215px 0px #9d9d9d4d;
`;

const AvatarComponent = styled(Avatar)`
  width: 50px;
  height: 50px;
  margin-right: 10px;

  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 40px;
        height: 40px;
        font-size: 0.7rem;
        margin-right: 5px;
      `
    );
  }}
`;

const getInitialSymbol = R.compose(R.toUpper, R.head);

const MAX_FILES_SIZE_IN_BYTES = 5368706371;

const isFilesSizeLimitOrLess = R.compose(sum => sum <= MAX_FILES_SIZE_IN_BYTES, R.sum, R.map(R.prop('size')));

const CreatePostSection = ({ communities, reloadPostsOnUpload, hashtags }) => {
  const formRef = useRef(null);
  const [draftPost, setDraftPost] = useState(null);
  const [resetUserMentionInputValue, setResetUserMentionInputValue] = useState(false);
  const [allContributionIds, setAllContributionIds] = useState([]);
  const fileRef = useRef(null);
  const [progress, setProgress] = useState(null);
  const { user, currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const [isFileUpload, setIsFileUpload] = useState(false);
  const theme = useTheme();
  const { request, loading } = useHttp();
  const users = ['Test1', 'Test2', 'Test3'];
  const [image_video_Limit, setImageVideo_Limit] = useState(false);
  const [liveVideoSession, setliveVideoSession] = useState(false);
  const [message, setMessage] = useState('');
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [postAttachment, setPostAttachment] = useState(null);
  const [isPostModalOpened, setIsPostModalOpened] = useState(false);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const [isHashtagModalOpen, setIsHashtagModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [hashtag, setHashtag] = useState('');

  const closeHashtagModal = () => {
    setIsHashtagModalOpen(false);
  };

  const [wysiwygData, setwysiwygData] = useState('');
  const classes = useStyles();
  const uploadImage = useRef(null);
  const colorToUse = determineColorToUse(activeContribution);
  const currentDate = moment();
  const onClickClosePostModal = () => {
    if (formRef.current) {
      formRef.current.setFieldValue('contributions', []);
      formRef.current.setFieldValue('textmessage', '');
      formRef.current.setFieldValue('attachments', []);
      formRef.current.setFieldValue('shareOnFacebook', false);
      formRef.current.setFieldValue('shareOnInstagram', false);
      formRef.current.setFieldValue('hashTags', []);
    }
    setErrorMessage(false);
    setIsPostModalOpened(!isPostModalOpened);
  };

  useEffect(() => {
    let Ids = [];
    for (let c in communities) {
      Ids.push(communities[c].id);
    }
    setAllContributionIds(Ids);
  }, [communities]);

  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };

      reader.onerror = () => {
        reject(new Error('Error occurred while reading the file.'));
      };

      reader.readAsDataURL(file);
    });
  }

  const handleIsFileLoad = () => {
    setIsFileUpload(false);
    setProgress(null);
  };

  const handleUpdateProgress = (partNumber, totalParts) => progressData => {
    const percentPerChunk = 100 / totalParts;
    setProgress((progressData / 100) * percentPerChunk + percentPerChunk * (partNumber - 1));
  };

  const guid = () => {
    var d = new Date().getTime();
    var guid = 'xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
    return guid;
  };

  const uploadPartFile = (
    chunk,
    partNumber,
    totalParts,
    isLastPart,
    documentId,
    fileName,
    fileType,
    uploadId,
    prevETags,
  ) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', chunk);
      setIsFileUpload(true);
      contentService
        .addFileToPostForUnifiedCommunityPost(
          formData,
          partNumber,
          isLastPart,
          documentId,
          fileName.includes('#') ? fileName.replace(/#/g, '') : fileName,
          fileType,
          uploadId,
          prevETags,
          handleUpdateProgress(partNumber, totalParts),
        )
        .then(data => {
          resolve(data);
        })
        .then(() => {
          if (isLastPart) {
            handleIsFileLoad();
          }
        })
        .catch(data => {
          handleIsFileLoad();
          console.dir(data);
          reject(data);
        });
    });

  const handleUploadFile = async ({ target: { files } }) => {
    const chunkSize = 26214400;

    if (isFilesSizeLimitOrLess(files) && formRef.current) {
      const newUploadedFiles = [...formRef.current.values.attachments, ...files];
      const maxImages = 5; // Maximum number of images that can be selected
      const maxVideos = 1; // Maximum number of videos that can be selected
      let selectedImages = 0;
      let selectedVideos = 0;
      for (let j = 0; j < newUploadedFiles.length; j++) {
        const temp_file = newUploadedFiles[j];
        if (temp_file?.type?.startsWith('image/')) {
          selectedImages++;
        } else if (temp_file?.type?.startsWith('video/')) {
          selectedVideos++;
        }
      }

      if (selectedImages > maxImages || selectedVideos > maxVideos || (selectedImages >= 1 && selectedVideos >= 1)) {
        selectedImages = 0;
        selectedVideos = 0;
        setImageVideo_Limit(true);
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const documentId = guid();
        const fileName = file.name;
        const fileType = file.type;
        let uploadId = '';
        let prevETags = '';
        let partNumber = 1;
        for (let start = 0; start < file.size; start += chunkSize) {
          const isLastPart = start + chunkSize >= file.size;
          const chunk = file.slice(start, start + chunkSize);
          const totalParts = Math.ceil(file.size / chunkSize);
          const result = await uploadPartFile(
            chunk,
            partNumber,
            totalParts,
            isLastPart,
            documentId,
            fileName,
            fileType,
            uploadId,
            prevETags,
          );
          if (result) {
            if (isLastPart) {
              if (formRef.current) {
                const allAttachments = [...formRef.current.values.attachments, ...result];
                formRef.current.setFieldValue('attachments', allAttachments);
              }
            }
            uploadId = result.uploadId;
            prevETags = result.prevETags;
          }
          partNumber++;
        }
      }
    }
  };

  const handleRemoveAttachment = index => {
    if (formRef.current) {
      const linkId = formRef.current.values.attachments[index].id;
      const newAttachments = filter(formRef.current.values.attachments, attachment => attachment.id !== linkId);
      removeAttachmentForUnifiedCommunity(linkId)
        .then(() => {
          formRef.current.setFieldValue('attachments', newAttachments);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const submitPost = values => {
    let data = [];
    let contributionIds = values.contributions;
    if (values?.contributions?.length === 0) {
      setErrorMessage(true);
    } else if (values.contributions.length === 1 && values.contributions.includes('All')) {
      contributionIds = allContributionIds;
    }
    data = contributionIds.map(id => ({
      contributionId: id,
      text: values.textmessage,
      isScheduled: false,
      scheduledTime: currentDate,
      isDraft: false,
      isPrivate: false,
      attachments: values.attachments,
      hashTags: values.hashTags,
    }));
    createMutiplePosts(data)
      .then(res => {
        if (res?.length) {
          if (formRef.current) {
            formRef.current.setFieldValue('contributions', []);
            formRef.current.setFieldValue('textmessage', '');
            formRef.current.setFieldValue('attachments', []);
            formRef.current.setFieldValue('shareOnFacebook', false);
            formRef.current.setFieldValue('shareOnInstagram', false);
            formRef.current.setFieldValue('hashTags', []);
            setErrorMessage(false);
          }
          setIsPostModalOpened(!isPostModalOpened);
          reloadPostsOnUpload();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <StyledContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <AvatarComponent alt={`${user?.firstName} ${user?.lastName}`} src={user?.avatarUrl} mobileView={mobileView}>
          {`${user?.firstName && getInitialSymbol(user?.firstName)}${
            user?.lastName && getInitialSymbol(user?.lastName)
          }`}
        </AvatarComponent>
        <div
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={e => {
            e.stopPropagation();
            setIsPostModalOpened(!isPostModalOpened);
          }}
        >
          <SearchBarComponent
            darkPlaceHolder={true}
            placeholder="What would you like to share?"
            searchFilter={message}
            onSearchFilterChange={setMessage}
            NotForSearch
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: mobileView ? 'space-around' : 'flex-end',
          marginTop: '24px',
        }}
      >
        <div
          style={{
            width: mobileView ? '40%' : '50%',
            borderRight: '1px solid #DBE7F2',
            color: '#47627B',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            fontWeight: '600',
            cursor: 'pointer',
            fontFamily: 'Poppins',
            fontSize: mobileView ? '10px' : '14px',
          }}
          onClick={() => setIsPostModalOpened(!isPostModalOpened)}
        >
          <img style={{ marginRight: '5px' }} src={PhotoVideo} alt={'Photo-Icon'} />
          Photos
        </div>
        <div
          style={{
            width: mobileView ? '40%' : '50%',
            color: '#47627B',
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: '600',
            fontFamily: 'Poppins',
            fontSize: mobileView ? '10px' : '14px',
          }}
          onClick={() => setIsPostModalOpened(!isPostModalOpened)}
        >
          <img style={{ marginRight: '5px' }} src={LiveVideo} alt={'Video-Icon'} />
          Video/Attachments
        </div>
      </div>
      <Formik
        innerRef={formRef}
        initialValues={{
          contributions: [],
          textmessage: message,
          attachments: [],
          shareOnFacebook: false,
          shareOnInstagram: false,
          hashTags: [],
        }}
        onSubmit={submitPost}
      >
        {({ values, setFieldValue, handleSubmit }) => {
          const handleEditorChange = event => {
            const editorContent = convertToPlainText(event.trim());
            // if (editorContent.length <= characterLimit) {
            let hash = editorContent.match(/#[^\s]+/g);
            setFieldValue('textmessage', editorContent);
            setFieldValue('hashTags', hash);
            // }
            // setCharacterCount(editorContent.length);
          };
          return (
            <Grid item sm={12} xs={12} style={{ width: 400 }}>
              <Modal
                title="Create a post"
                isOpen={isPostModalOpened}
                hiddenCancel
                onCancel={() => {
                  onClickClosePostModal();
                }}
                onSubmit={handleSubmit}
                disableSubmitClick={values?.textmessage?.length === 0 && values?.attachments?.length === 0}
                submitTitle="Post"
                widthRequiredIs
                disableOverlayClick
                createPost={true}
              >
                <Grid style={{ marginBottom: '20px' }} item sm={12} xs={12}>
                  {isCoach ? (
                    <StyledSelect
                      mobileView={mobileView}
                      style={mobileView ? { width: '320px' } : {}}
                      name="contributions"
                      label="Which communities would you like to post in?"
                      value={values.contributions}
                      disabled={false}
                      variant="outlined"
                      multiple
                      fullWidth
                      items={communities
                        ?.map(item => ({
                          title: item.title,
                          value: item.id,
                        }))
                        .concat([{ id: 'All', title: 'All My Communities' }])}
                      onChange={e => {
                        let values = e.target.value;
                        if (values.length > 1 && values.includes('All')) {
                          const firstElement = values[0];
                          const lastElement = values[values.length - 1];
                          if (firstElement === 'All') {
                            values = values.filter(val => val != 'All');
                          } else if (lastElement === 'All') {
                            values = values.filter(val => val === 'All');
                          } else {
                            values = values;
                          }
                        }
                        setFieldValue('contributions', values);
                      }}
                    />
                  ) : (
                    <StyledSelect
                      style={mobileView ? { width: '320px' } : {}}
                      mobileView={mobileView}
                      name="contributions"
                      label="Which communities would you like to post in?"
                      value={values.contributions}
                      disabled={false}
                      variant="outlined"
                      multiple
                      fullWidth
                      items={communities?.map(item => ({
                        title: item.title,
                        value: item.id,
                      }))}
                      onChange={e => {
                        let values = e.target.value;
                        if (values.length > 1 && values.includes('All')) {
                          const firstElement = values[0];
                          const lastElement = values[values.length - 1];
                          if (firstElement === 'All') {
                            values = values.filter(val => val != 'All');
                          } else if (lastElement === 'All') {
                            values = values.filter(val => val === 'All');
                          } else {
                            values = values;
                          }
                        }
                        setFieldValue('contributions', values);
                      }}
                    />
                  )}
                  {values.contributions.length === 0 && errorMessage && (
                    <CommonErrorMessage align="left" message="Please select contribution" />
                  )}
                </Grid>
                <Grid style={{ marginBottom: '24px' }} item sm={12} xs={12}>
                  {/* <Heading>What would you like to share?</Heading> */}
                  {/* <CKEditor
                    config={{
                      allowedContent: true,
                      height: '200px',
                      removePlugins: 'elementspath',
                      toolbar: [
                        {
                          name: 'styles',
                          items: ['Bold', 'Italic', 'Underline', 'TextColor'],
                        },
                        { name: 'links', items: ['Link'] },
                      ],
                    }}
                    initData={values.textmessage}
                    onChange={handleEditorChange}
                  /> */}
                  <div
                    style={{
                      width: '100%',
                      minHeight: '150px',
                      border: '1px solid gray',
                      padding: '10px',
                      borderRadius: '2px',
                    }}
                  >
                    <UserMentionInput
                      // style={{ color: themedColor}}
                      placeholder="What would you like to share?"
                      defaultValue={values?.textmessage?.trimStart()}
                      users={users}
                      onInputChange={handleEditorChange}
                      resetInputValue={resetUserMentionInputValue}
                      onResetInputValueCallback={() => setResetUserMentionInputValue(false)}
                      hashTags={hashtags}
                    />
                  </div>
                </Grid>
                {/* {values?.hashTags?.length > 0 && (
                  <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>
                    {values?.hashTags?.map((item, i) => (
                      <StyledHashtag color={colors.darkOceanBlue}>{item}</StyledHashtag>
                    ))}
                  </div>
                )} */}
                {values?.attachments && (
                  <Grid style={{ marginBottom: '2rpx' }} item sm={12} xs={12}>
                    <Attachments
                      pending={loading}
                      attachments={values?.attachments}
                      removeAttachment={handleRemoveAttachment}
                      createPost={true}
                      colorToUse={colorToUse}
                      unifiedCommunity
                    />
                    {/* <div style={{ width: '100%', position: 'relative' }}>
                      <img src={values?.mediaFile} width={'100%'} />
                      <div style={{ position: 'absolute', right: '5px', top: '5px', cursor: 'pointer' }} onClick={() => {
                        setFieldValue('mediaFile', null);
                        fileRef.current.value = null;
                      }}>
                        <StyledCrossedIcon color='white' />
                      </div>
                    </div> */}
                  </Grid>
                )}
                <Grid
                  style={{ marginBottom: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  item
                  sm={12}
                  xs={12}
                >
                  {isFileUpload ? (
                    <StyledProgressWrap position="relative">
                      <CircularProgressWithLabel color={colorToUse?.PrimaryColorCode} value={progress} />
                    </StyledProgressWrap>
                  ) : (
                    <>
                      <input
                        style={{
                          width: '100%',
                          color: '#47627B',
                          textTransform: 'none',
                          borderRadius: '30px',
                          fontWeight: '600',
                          fontFamily: 'Poppins',
                          border: `1px solid #CDBA8F`,
                        }}
                        multiple
                        accept="*"
                        className="d-none"
                        id={`attach-media`}
                        type="file"
                        ref={fileRef}
                        onChange={event => {
                          event.preventDefault();
                          handleUploadFile(event);
                        }}
                      />

                      <label
                        htmlFor={`attach-media`}
                        style={{
                          marginBottom: 0,
                          cursor: 'pointer',
                          fontWeight: '600',
                          fontFamily: 'Poppins',
                          fontSize: mobileView ? '10px' : '14px',
                          width: '100%',
                          color: '#47627B',
                          textTransform: 'none',
                          borderRadius: '30px',
                          fontWeight: '600',
                          fontFamily: 'Poppins',
                          border: `1px solid #CDBA8F`,
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '10px',
                          alignItems: 'center',
                        }}
                      >
                        <img style={{ marginRight: '5px' }} src={PhotoVideo} alt={'Facebook-Icon'} />{' '}
                        {/* <PhotoIcon color={colorToUse?.PrimaryColorCode} className="smoked-gary-color hoverable" />{' '} */}
                        Photo/Video/Attachments
                      </label>
                    </>
                  )}
                </Grid>
                {/* <Grid
                  style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  item
                  sm={12}
                  xs={12}
                >
                   <Button
                    style={{
                      background: 'white',
                      border: `1px solid ${colorToUse?.PrimaryColorCode}`,
                      marginTop: 10,
                      width: '100%',
                      borderRadius: '30px',
                      color: colorToUse?.PrimaryColorCode,
                      textTransform: 'none',
                    }}
                    onClick={() => {
                      setIsHashtagModalOpen(true);
                    }}
                  >
                    Add Hashtags
                  </Button> 
                </Grid> */}

                {/* <Grid item sm={12} xs={12}>
                  <div
                    style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
                  >
                    <div style={{ flex: 1, padding: '5px', display: 'flex', justifyContent: 'flex-start' }}>
                      <img src={FacebookIcon} alt={'Facebook-Icon'} />{' '}
                      <div
                        style={{
                          width: '250px',
                          alignItems: 'center',
                          display: 'flex',
                          paddingLeft: '5px',
                          fontWeight: '500',
                          fontFamily: 'Poppins',
                          color: '#47627B',
                        }}
                      >
                        Share on Facebook
                      </div>
                    </div>
                    <div style={{ flex: 1, padding: '5px', display: 'flex', justifyContent: 'flex-end' }}>
                      <Checkbox
                        id="create-contribution-agreement"
                        color="primary"
                        checked={values.shareOnFacebook}
                        name="shareOnFacebook"
                        onChange={e => setFieldValue('shareOnFacebook', e.target.checked)}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid style={{ marginBottom: '19px' }} item sm={12} xs={12}>
                  <div
                    style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
                  >
                    <div style={{ flex: 1, padding: '5px', display: 'flex', justifyContent: 'flex-start' }}>
                      <img src={InstagramIcon} alt={'Instagram-Icon'} />{' '}
                      <div
                        style={{
                          width: '250px',
                          alignItems: 'center',
                          display: 'flex',
                          paddingLeft: '5px',
                          fontWeight: '500',
                          fontFamily: 'Poppins',
                          color: '#47627B',
                        }}
                      >
                        Share on Instagram
                      </div>
                    </div>
                    <div style={{ flex: 1, padding: '5px', display: 'flex', justifyContent: 'flex-end' }}>
                      <Checkbox
                        id="create-contribution-agreement"
                        color="primary"
                        name="shareOnInstagram"
                        checked={values.shareOnInstagram}
                        onChange={e => setFieldValue('shareOnInstagram', e.target.checked)}
                      />
                    </div>
                  </div>
                </Grid> */}
              </Modal>
              <FieldArray name="hashTags">
                {({ push, remove, form }) => {
                  const pushNewHashtag = newHashtag => {
                    push(newHashtag);
                    setHashtag('');
                  };
                  const removeHashtag = item => {
                    const index = form?.values?.hashTags?.indexOf(item);
                    remove(index);
                  };
                  return (
                    <>
                      <Modal
                        isOpen={isHashtagModalOpen}
                        onCancel={() => {
                          setIsHashtagModalOpen(false);
                        }}
                        submitTitle="Add"
                        onSubmit={() => {
                          if (hashtag?.length > 0) {
                            pushNewHashtag(hashtag);
                          } else {
                            closeHashtagModal();
                          }
                        }}
                        cancelTitle="Cancel"
                        title="Add Hashtags"
                        widthRequiredIs
                      >
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                          <div>
                            <TextField
                              className=""
                              variant="outlined"
                              placeholder="Write here..."
                              value={hashtag}
                              // name="hashTagInput"
                              onChange={e => {
                                setHashtag(e.target.value.trim());
                              }}
                              onFocus={e => e.stopPropagation()}
                              InputProps={
                                {
                                  // style: { color: themedColor },
                                }
                              }
                              fullWidth
                            />
                          </div>
                          <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>
                            {form?.values?.hashTags?.map((item, i) => (
                              <StyledHashtag color={colors.darkOceanBlue}>
                                <StyledRemove onClick={() => removeHashtag(item)}>
                                  <StyledCross />
                                </StyledRemove>
                                {item}
                              </StyledHashtag>
                            ))}
                          </div>
                        </div>
                      </Modal>
                    </>
                  );
                }}
              </FieldArray>
            </Grid>
          );
        }}
      </Formik>

      {image_video_Limit && (
        <>
          <Modal
            isOpen={image_video_Limit}
            onCancel={() => {
              setImageVideo_Limit(false);
            }}
            widthRequiredIs
            handleOnCancel
            disableConfirm
            cancelTitle="OK"
            title="File Uploading Restrictions"
          >
            <p> You are allowed to upload only 5 images OR 1 video per post. </p>
          </Modal>
        </>
      )}

      <Modal
        isOpen={liveVideoSession}
        onCancel={() => {
          setliveVideoSession(false);
        }}
        widthRequiredIs
        handleOnCancel
        disableConfirm
        cancelTitle="OK"
        title="Live Video"
      >
        <p style={{ justifyContent: 'center', display: 'flex' }}> Coming Soon... </p>
      </Modal>
    </StyledContainer>
  );
};

export default CreatePostSection;
