import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useHeader, useEndVideoChat } from 'hooks';
import { fetchLeadMagnets, fetchLeadMagnetsActions } from 'actions/leadMagnets';
import Loader from 'components/UI/Loader';
import Banner from 'components/Banner/Banner';
import LeadMagnetsTable from 'components/Tables/LeadMagnets';
import ExportIcon from 'pages/Contacts/Components/Icons/ExportIcon';
import classes from 'pages/Contacts/Components/ContactsButtons/ContactsButtons.module.scss';
import { exportLeadMagnetUsersByLeadMagnetId } from 'services/leadMagnets.service';
import { downloadFile } from 'utils/utils';
import useNotifications from 'hooks/useNotifications';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import IntroCard from 'pages/LeadMagnetView/IntroCardLeadMagnet';
import Slider from 'pages/EmailCampaigns/Components/ImageSilder/Silder';
import TemplateOne from 'assets/lead-magnet-landing-page-sample-template-1.jpg';
import TemplateTwo from 'assets/lead-magnet-thankyou-page-sample-template-1.jpg';
import classesCampaigns from '../EmailCampaigns/AllCampaigns/AllCampaigns.module.scss';
import { Link } from 'react-router-dom';
import Button from 'components/FormUI/Button';
import { ROUTES } from '../../constants';

const StyledExportButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const CROUSAL_ITEMS = [
  {
    src: TemplateOne,
  },
  {
    src: TemplateTwo,
  },
];

const StyledSlider = styled(Slider)`
  padding: 20px;

  .CarouselItem {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2%;
    overflow: hidden;
  }
  img {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2) !important;
    border-radius: 2%;
  }
`;

const AllLeadMagnetsPage = ({
  //
  // contributionscount,
  leadMagnets,
  // closestClassForBanner,
  getLeadMagnets,
  loading,
  userId,
}) => {
  const [prevLists, setPrevLists] = useState(leadMagnets);
  const exportBtnClass = classes.exportBtn;
  const { successMessage, infoMessage } = useNotifications();
  const indicatorIconButtonProps = { style: { color: '#d9d9d9' } };
  const activeIndicatorIconButtonProps = { style: { color: '#215c73' } };
  const indicatorContainerProps = { style: { marginTop: '0px' } };

  useEffect(() => {
    if (!loading) {
      setPrevLists(leadMagnets);
    }
  }, [leadMagnets, loading]);
  const [pagination, setpagination] = useState(0);
  useHeader('My Lead Magnets');

  const handlePageNumber = useCallback(page => {
    setpagination(page);
  }, []);

  const renderList = useCallback(async () => {
    getLeadMagnets();
  }, [getLeadMagnets, userId]);

  // const { Popup } = useEndVideoChat(renderList);

  useEffect(() => {
    renderList();
  }, [renderList]);

  const handleExportLeads = () => {
    exportLeadMagnetUsersByLeadMagnetId()
      .then(res => {
        if (!res) {
          return infoMessage('Info', 'No leads available to export.');
        }
        return downloadFile(res, 'Lead Magnet - All Leads.csv').then(() =>
          successMessage('Success', 'File exported successfully.'),
        );
      })
      .catch(console.dir);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {loading && <Loader />}
      {/* <Banner type="closestSession" closestClassForBanner={closestClassForBanner} /> */}
      <StyledExportButtonRow className={classes.container}>
        <Link to={ROUTES.CREATE_LEAD_MAGNET_BASIC}>
          <Button autoWidth>Create Lead Magnet</Button>
        </Link>
        {prevLists?.length > 0 ? (
          <button
            className={exportBtnClass}
            type="button"
            onClick={loading ? null : handleExportLeads}
            disabled={loading}
            style={{ padding: '1rem 1.25rem', height: 'auto' }}
          >
            <ExportIcon width="16" height="16" />
            Export
          </button>
        ) : null}
      </StyledExportButtonRow>
      {prevLists?.length > 0 && (
        <>
          <LeadMagnetsTable
            // totalCount={contributionscount}
            rows={loading ? prevLists : leadMagnets}
            childpageNumber={handlePageNumber}
          />
        </>
      )}
      {(!prevLists || !prevLists?.length) && !loading && (
        <div className={classesCampaigns.container}>
          <div className={classesCampaigns.bodyContainer}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <IntroCard />
              </Grid>
              <Grid container item md={6} xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
                <Grid item md={11} xs={12} className={classesCampaigns.slider} style={{}}>
                  <StyledSlider
                    items={CROUSAL_ITEMS}
                    navButtonsAlwaysInvisible={true}
                    indicatorIconButtonProps={indicatorIconButtonProps}
                    activeIndicatorIconButtonProps={activeIndicatorIconButtonProps}
                    indicatorContainerProps={indicatorContainerProps}
                    interval={7000}
                    stopAutoPlayOnHover={true}
                    style={{ padding: '20px' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      {/* <Popup /> */}
    </>
  );
};

const mapStateToProps = ({ leadMagnets: { loading, error, leadMagnetsCollection }, account }) => ({
  userId: account?.user?.id,
  loading,
  error,
  // contributionscount: upcomingCreated?.totalCount || null,
  leadMagnets: leadMagnetsCollection || [],
  // closestClassForBanner: upcomingCreated?.closestClassForBanner || {},
});

const actions = {
  getLeadMagnets: fetchLeadMagnets,
};

AllLeadMagnetsPage.propTypes = {
  userId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  leadMagnets: PropTypes.arrayOf(PropTypes.object).isRequired,
  getLeadMagnets: PropTypes.func.isRequired,
  // closestClassForBanner: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, actions)(AllLeadMagnetsPage);
