import useRouter from 'hooks/useRouter';
import classes from './ResultPage.module.scss';
import QuizViewMain from 'pages/QuizViewPage/Components/Containers/MainContainer/MainContainer';
import React, { useEffect, useState } from 'react';
import PassedResult from './Components/PassedResult';
import FailedResult from './Components/FailedResult';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { useTheme, useMediaQuery } from '@material-ui/core';
import {
  getClientQuizFormById,
  getQuizFormById,
  getQuizResult,
} from 'pages/CreateQuizes/Services/CreateQuizes.service';
import { setActiveQuiz } from 'actions/quizes';
import Loader from 'components/UI/Loader';
const HeaderTitle = ({ title = '', type, isEditing }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  let titleText = '';
  if (title?.length > 0) {
    if (mobileView) {
      if (title?.length > 30) {
        titleText = title.substring(0, 25) + '...';
      } else {
        titleText = title;
      }
    } else {
      if (title?.length > 50) {
        titleText = title.substring(0, 40) + '...';
      } else {
        titleText = title;
      }
    }
  } else {
    if (type === 'Form' && isEditing) {
      titleText = 'Edit a Form';
    } else if (type === 'Form' && !isEditing) {
      titleText = 'Create a Form';
    } else if (type != 'Form' && isEditing) {
      titleText = 'Edit a Quiz';
    } else {
      titleText = 'Create a Quiz';
    }
  }
  return (
    <Tooltip title={title} arrow>
      <div style={{ fontSize: mobileView ? '20px' : '' }}>{titleText}</div>
    </Tooltip>
  );
};
export default function ResultPage() {
  const { leadId, attemptId } = useParams();
  const { activeQuiz } = useSelector(state => state?.quizes);
  const { title, type } = activeQuiz;
  const [loading, setLoading] = useState(false);
  const param = useParams();
  const [resultObtained, setResultObtained] = useState({});
  const dispatch = useDispatch();
  const { isPassed, isNoGradingResult } = resultObtained;
  useEffect(() => {
    setLoading(true);
    if (leadId && attemptId) {
      getQuizResult(attemptId, leadId).then(res => {
        setResultObtained(res);
        getClientQuizFormById(res.quizId).then(res => {
          dispatch(setActiveQuiz(res));
          setLoading(false);
        });
      });
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <QuizViewMain
      headerProps={{
        headerTitle: <HeaderTitle {...{ title, type }} />,
      }}
    >
      {isPassed && <PassedResult resultObtained={resultObtained} isNoGradingResult={isNoGradingResult} />}
      {!isPassed && <FailedResult resultObtained={resultObtained} isNoGradingResult={isNoGradingResult} />}
    </QuizViewMain>
  );
}
