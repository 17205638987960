import React, { useEffect, useState, useCallback } from 'react';
import { Grid, CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import Button from 'components/FormUI/Button';

import { useHttp } from 'hooks/index';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { useDispatch, useSelector } from 'react-redux';

import { mdiFolderPlusOutline, mdiFileUploadOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import Loader from 'components/UI/Loader';

import * as contentService from 'services/content.service';
import * as R from 'ramda';

import ResourcesContainer from 'components/ResourcesCard/ResourcesContainer';
import useAccount from 'hooks/useAccount';
import { UserRoles } from 'helpers/constants';
import { determineColorToUse, getAllResources } from 'services/contributions.service';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import { lightOrDark } from 'utils/utils';

function sortByUploadDateTime(attachments) {
  return attachments.sort((a, b) => new Date(b.uploadDateTime) - new Date(a.uploadDateTime));
}

const StyledResourceMainSection = styled.div`
  padding: 25px 25px 25px;
  height: 100%;
`;

const NewStyledResourceMainSection = styled.div`
  object-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButtonSection = styled.div`
  @media screen and (max-width: 576px) {
    padding: 20px 0px 0px 0px;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid #d1b989;
`;

function UserResources(props) {
  const { request } = useHttp();
  const [loadingData, setLoadingData] = useState(false);
  const contribution = useContribution();
  const contributionId = contribution.id;
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [progress, setProgress] = useState(null);

  const MAX_FILES_SIZE_IN_BYTES = 5368706371;
  const isFilesSizeLimitOrLess = R.compose(sum => sum <= MAX_FILES_SIZE_IN_BYTES, R.sum, R.map(R.prop('size')));
  const [resourcesAttachments, setAttachments] = useState([]);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const { currentRole } = useAccount();
  const isCohealer = currentRole === UserRoles?.cohealer;
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const activeContributionID = activeContribution?.id;
  const isContributionPurchased = activeContribution?.isPurchased;
  const canAccess =
    currentRole === UserRoles?.cohealer || (currentRole === UserRoles?.client && isContributionPurchased);
  const [uploading, setUploading] = useState(false);
  const uploadFileRef = React.createRef();
  const colorToUse = determineColorToUse(activeContribution);
  const btnColor = colorToUse.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const handleIsFileLoad = () => {
    setIsFileUpload(false);
  };
  const handleUpdateProgress = (partNumber, totalParts) => progressData => {
    const percentPerChunk = 100 / totalParts;
    setProgress((progressData / 100) * percentPerChunk + percentPerChunk * (partNumber - 1));
  };
  const guid = () => {
    let d = new Date().getTime();
    const guid = 'xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
    return guid;
  };

  const handleUploadFile = useCallback(
    async ({ target: { files } }) => {
      const chunkSize = 26214400;
      if (isFilesSizeLimitOrLess(files)) {
        setUploading(true);
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const documentId = guid();
          const fileName = file.name;
          let uploadId = '';
          let prevETags = '';
          let partNumber = 1;
          for (let start = 0; start < file.size; start += chunkSize) {
            const isLastPart = start + chunkSize >= file.size;
            const chunk = file.slice(start, start + chunkSize);
            const totalParts = Math.ceil(file.size / chunkSize);
            const result = await uploadPartFile(
              chunk,
              partNumber,
              totalParts,
              isLastPart,
              documentId,
              fileName,
              uploadId,
              prevETags,
            );

            if (result) {
              if (isLastPart) {
                setAttachments(result.attachments);
              }
              uploadId = result.uploadId;
              prevETags = result.prevETags;
            }
            partNumber++;
          }
        }
        setUploading(false);
      }
      //window.location.reload();
    },
    [dispatch, request, resourcesAttachments],
  );

  const uploadPartFile = (chunk, partNumber, totalParts, isLastPart, documentId, fileName, uploadId, prevETags) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', chunk);
      setIsFileUpload(true);
      contentService
        .addResourceToContribution(
          formData,
          contributionId,
          partNumber,
          isLastPart,
          documentId,
          fileName,
          uploadId,
          prevETags,
          handleUpdateProgress(partNumber, totalParts),
        )
        .then(result => {
          resolve(result);
        })
        .then(() => {
          if (isLastPart) {
            handleIsFileLoad();
          }
        })
        .catch(result => {
          handleIsFileLoad();
          reject(result);
        });
    });

  const handleRefreshList = useCallback(async () => {
    setLoadingData(true);
    getAllResources(activeContributionID)
      .then(res => {
        setAttachments(res.attachments);
        setLoadingData(false);
      })
      .catch(error => {
        setError(error);
        setLoadingData(false);
      });
  }, [activeContributionID, getAllResources]);

  useEffect(() => {
    handleRefreshList();
  }, []);

  useEffect(() => {
    if (resourcesAttachments?.length > 0) {
      setData(sortByUploadDateTime(resourcesAttachments));
    } else if (resourcesAttachments === undefined) {
      setData(null);
    } else {
      setData([]);
    }
  }, [resourcesAttachments, currentRole]);

  if (error || !canAccess) {
    if (currentRole === UserRoles.cohealer) {
      return <div style={{ marginLeft: '45px', marginTop: '15px' }}>Error loading resources.</div>;
    } else {
      return (
        <div style={{ marginLeft: '15px', marginTop: '15px' }}>
          You currently don’t have access to view resources on your client account.
        </div>
      );
    }
  }

  if (loadingData) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div>
        {isCohealer ? (
          <Grid container justify="space-between">
            <Grid item>
              {!resourcesAttachments ||
                (!resourcesAttachments.length && (
                  <div style={{ marginLeft: '45px', marginTop: '25px', color: colorToUse?.AccentColorCode }}>
                    Currently there are no resources added
                  </div>
                ))}
            </Grid>
            <Grid item style={{ display: 'flex' }}>
              {uploading && (
                <div className="resources-progress">
                  <CircularProgressWithLabel
                    className="upload-progress"
                    value={progress}
                    progressColor={colorToUse?.AccentColorCode}
                  />
                </div>
              )}
              <StyledButtonSection onClick={() => uploadFileRef.current.click()}>
                <StyledButton
                  style={{
                    backgroundColor: btnColor,
                    border: `1px solid ${btnColor}`,
                    marginTop: '10px',
                    color: textColor,
                  }}
                  autoWidth
                  disabled={uploading}
                >
                  <label style={{ color: textColor, marginBottom: '0px', cursor: 'pointer' }}>
                    <Icon valign="right" size={1} color={textColor} path={mdiFileUploadOutline} />
                    &nbsp;{uploading ? 'Uploading...' : 'Upload File'}
                  </label>
                  <input
                    id="file-upload"
                    ref={uploadFileRef}
                    type="file"
                    style={{ display: 'none', cursor: 'pointer' }}
                    onChange={handleUploadFile}
                    disabled={isFileUpload}
                    multiple
                  />
                </StyledButton>
              </StyledButtonSection>
            </Grid>
          </Grid>
        ) : (
          <Grid container justify="space-between">
            <Grid item>
              {!resourcesAttachments ||
                (!resourcesAttachments.length && (
                  <div style={{ marginLeft: '45px', marginTop: '25px', color: colorToUse?.AccentColorCode }}>
                    Currently there are no resources added
                  </div>
                ))}
            </Grid>
          </Grid>
        )}
        {!resourcesAttachments || !resourcesAttachments.length ? (
          <></>
        ) : (
          // <div style={{ marginLeft: '45px', marginTop: '15px' }}>Currently there are no resources to view</div>
          <StyledResourceMainSection mobileView={mobileView}>
            <NewStyledResourceMainSection>
              {data == null ? (
                <Grid style={{ justifyContent: 'center' }} container spacing={2}>
                  <CircularProgress />
                </Grid>
              ) : (
                <Grid container spacing={2} style={{ justifyContent: mobileView ? 'center' : 'flex-start' }}>
                  {data.map(item => (
                    <Grid item key={item.id}>
                      <ResourcesContainer contributionResources={item} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </NewStyledResourceMainSection>
          </StyledResourceMainSection>
        )}
      </div>
    </>
  );
}

export default UserResources;
