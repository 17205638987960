import Modal from 'components/UI/Modal';
import React, { useEffect, useState } from 'react';
import {
  exportResponsesQuizForm,
  getQuizFormById,
  getResultsById,
} from 'pages/CreateQuizes/Services/CreateQuizes.service';
import Question from 'pages/CreateQuizes/Pages/QuizContent/Components/Question/Question';
import QuestionPreview from 'pages/CreateQuizes/Pages/QuizContent/Components/Question/QuestionPreview';
import { questionTypes } from 'pages/CreateQuizes/Constants/constants';
import { Grid, Tooltip } from '@material-ui/core';
import { downloadFile } from 'utils/utils';
import { useMediaQuery, useTheme } from '@material-ui/core';

export default function QuizandFormResponseModal({ showModal, setShowModal, row }) {
  const [resultsArray, setResultsArray] = useState(null);
  const [questionsArray, setQuestionsArray] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(993));
  useEffect(() => {
    if (showModal) {
      getQuizFormDetail();
    }
  }, [showModal]);

  const getQuizFormDetail = () => {
    getQuizFormById(row.id)
      .then(res => {
        let filteredQuestion = res.questions.filter(question => question.isDraft === false);
        setQuestionsArray(filteredQuestion);
      })
      .catch(err => {
        setQuestionsArray([]);
      });
    // getResultsById(row.id)
    //   .then(res => {
    //     setResultsArray(res);
    //     console.log('res------------->', res);
    //   })
    //   .catch(err => {
    //     setResultsArray([]);
    //   });
  };
  const handleExportResponse = () => {
    exportResponsesQuizForm(row.id)
      .then(res => {
        downloadFile(res, `${row.title.length > 20 ? row.title.substring(0, 10) : row.title}_Responses.csv`);
        setShowModal(false);
      })
      .catch(() => {
        setShowModal(false);
      });
  };

  const HeaderTitle = ({ title = '', mobileView = false }) => {
    let titleText = '';
    if (title?.length > 0) {
      if (title?.length > 28) {
        titleText = title.substring(0, 28) + '...';
      } else {
        titleText = title;
      }
    }
    return (
      <Tooltip title={title} arrow>
        <span style={{ fontSize: mobileView ? '20px' : '' }}>{titleText}</span>
      </Tooltip>
    );
  };
  return (
    <Modal
      title={<HeaderTitle title={row.title} />}
      isOpen={showModal}
      cancleTitle={'Cancel'}
      subText={`- ${row.numberOfResponses} Response(s)`}
      submitTitle={isMobile ? 'Export' : 'Export Responses'}
      disableSubmitClick={row.numberOfResponses === 0}
      modalType={'Quizes'}
      onSubmit={() => {
        handleExportResponse();
      }}
      onCancel={() => {
        setShowModal(false);
      }}
      widthRequired={true}
      bodyBackgroundColor={'#FAFAFA'}
    >
      <div
        style={{
          diplay: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          display: 'flex',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <Grid lg={12} xs={12}>
          {questionsArray != null && (
            <div style={{ fontFamily: 'Avenir', fontWeight: '500', fontSize: '14', color: '#213649' }}>
              Questions({questionsArray.length})
            </div>
          )}
          {questionsArray != null &&
            questionsArray.map((str, index) => <QuestionPreview question={str} index={index} />)}
        </Grid>
        {resultsArray?.length > 0 && (
          <Grid lg={6} xs={6} style={{ marginLeft: '10px' }}>
            {resultsArray != null && (
              <div style={{ fontFamily: 'Avenir', fontWeight: '500', fontSize: '14', color: '#213649' }}>
                Results({resultsArray.length})
              </div>
            )}
            {resultsArray != null &&
              resultsArray.map(str => (
                <QuestionPreview
                  response={true}
                  question={{
                    type: questionTypes.dropdown,
                    description: 'How many legs does a spider have?',
                    options: [],
                    isOptional: false,
                  }}
                />
              ))}
          </Grid>
        )}
      </div>
    </Modal>
  );
}
