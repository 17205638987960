import React, { useEffect, useMemo, useState } from 'react';
import classes from './AllContacts.module.scss';
import { StyledHeading } from '../Components/StyledComponents/StyledComponents';
import Buttons from '../Components/ContactsButtons/ContactsButtons';
import { PAGE_TYPES } from '../Components/ContactsButtons/ContactsButtons';
import { DummyContacts } from './DummyContacts';
import { FormControlLabel, Checkbox, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import SearchBox from 'pages/EmailCampaigns/Components/SearchBox/SearchBox';
import { ClickAwayListener } from '@material-ui/core';
import FilterIcon from 'pages/EmailCampaigns/Styles/SVG/FilterSvg';
import ContactsTable from './Contacts/Contacts';
import PaginationBar from 'pages/EmailCampaigns/Components/PaginationBar/PaginationBar';
import FilterPopup from '../Components/Filter/Filter';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/UI/Loader';
import { PER_PAGE_ALLCONTACTS_COUNT } from 'pages/EmailCampaigns/Styles/Constants/constants';
import { isEmpty } from 'lodash';
import moment from 'moment';
import AllContactsFilter from '../Components/Filter/Filter';
import { FILTERTYPES } from 'pages/ContributionView/ContributionSessions/constants';
import { FilterTypes } from '../Components/Filter/FilterConstants';
import { ContactsServices } from '../Services/Contacts.service';
import { downloadFile } from 'utils/utils';
import Modal from 'components/UI/Modal';
import AddContactTagModal from '../Leads/LeadModals/AddContactTagModal';
import Select from 'components/UI/Select';
import ImportFileContent from '../Components/ImportFileContent/ImportFileContent';
import { useHeader } from 'hooks/usePageActions';
import { getSearchedAndFilteredData } from '../Utils/functions';
import MainContainer from 'components/Containers/MainContainer';
import { StyledMainSection } from 'components/Containers/CreateContributionContainer';
import { fetchAllContactsAsync } from 'actions/contacts';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { setSendEmailTo } from 'actions/campaign';
import useCampaign from 'pages/CreateCampaign/Hooks/useCampaign';
import Notification from 'pages/CreateCampaign/Components/Notification/Notification';

const StyledLabel = styled.div`
  font-family: 'Avenir';
  font-weight: 350;
  font-size: 14px;
  line-height: 16px;
  color: #4a4a4a;
`;

const FilterTag = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: ${({ color }) => color ?? 'black'};
  width: 18px;
  height: 18px;
  border-radius: 9px;
`;

const AllContacts = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteContactsModalOpen, setIsDeleteContactsModalOpen] = useState(false);
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const [isImportFileModalOpen, setIsImportFileModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);
  const [searchedKeyword, setSearchedKeyword] = useState('');
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [isUnderstandChecked, setIsUnderstandChecked] = useState(false);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const { isLoading: fetchingContacts, contacts } = useSelector(state => state?.contacts);
  console.log('selectedContacts==>>contacts', selectedContacts, contacts);
  const { campaignNotification } = useCampaign();
  useHeader('Contacts');

  useEffect(() => {
    dispatch(fetchAllContactsAsync({}));
  }, []);
  useEffect(() => {
    if (selectedContacts?.length > 0 && selectedContacts?.length === contacts?.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedContacts]);

  const handleDeleteAll = () => {
    setIsDeleteContactsModalOpen(true);
  };
  const handleAddTag = () => {
    setIsAddTagModalOpen(true);
  };

  const handleSendEmail = () => {
    // setIsEmailModalOpen(true);
    const data = contacts.filter(item => item.isDeleted === false).filter(c => selectedContacts.includes(c.id));
    dispatch(setSendEmailTo(data));
    history.push('/create-campaign/title');
  };
  const handleSearch = e => {
    setSearchedKeyword(e.target.value.trim());
  };

  const handleFilterClick = () => {
    setFilterPanelOpen(!filterPanelOpen);
  };

  const isChecked = id => {
    return selectedContacts.includes(id);
  };

  const onCheckClicked = id => {
    if (isChecked(id)) {
      setSelectedContacts(selectedContacts.filter(contactId => contactId !== id));
    } else {
      setSelectedContacts([...selectedContacts, ...[id]]);
    }
  };

  const pageCount = useMemo(() => {
    const count = Math.ceil(
      getSearchedAndFilteredData(
        contacts.filter(item => item.isDeleted === false),
        searchedKeyword?.toLocaleLowerCase(),
        appliedFilters,
      )?.length / PER_PAGE_ALLCONTACTS_COUNT,
    );
    return count;
  }, [contacts, searchedKeyword, appliedFilters]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageCount]);

  const handleSubmitFilter = values => {
    setAppliedFilters(values);
    setIsFilterApplied(true);
    setFilterPanelOpen(false);
  };

  const handleExportClick = () => {
    setIsExporting(true);
    // const data = {
    //   text: searchedKeyword || undefined,
    //   fromDate: appliedFilters?.fromDate
    //     ? `${moment(appliedFilters?.fromDate).format('YYYY-MM-DD')}T${moment(appliedFilters?.fromDate).format(
    //         'hh:mm:ss',
    //       )}Z`
    //     : undefined,
    //   toDate: appliedFilters?.toDate
    //     ? `${moment(appliedFilters?.toDate).format('YYYY-MM-DD')}T${moment(appliedFilters?.toDate).format('hh:mm:ss')}Z`
    //     : undefined,
    //   tagFilter: appliedFilters?.tags,
    //   contributionFilter: appliedFilters?.contributions,
    // };
    const type = 'all';
    ContactsServices.exportContacts(type)
      .then(res => {
        downloadFile(res, 'Contacts List.csv');
      })
      .catch(err => {
        console.log('err', err);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  const handleImportClick = () => {
    setIsImportFileModalOpen(true);
  };

  return (
    <MainContainer>
      <StyledMainSection mobileView={mobileView}>
        <div className={classes.container}>
          {!isEmpty(campaignNotification) && <Notification notification={campaignNotification} />}
          <div className={classes.topBar}>
            <StyledHeading>All</StyledHeading>
            <Buttons
              page={PAGE_TYPES.AllContacts}
              exportClickHandler={handleExportClick}
              importClickHandler={handleImportClick}
              exportBtnClassName={isExporting && classes.export_btn_disabled}
              importBtnClassName={isImporting && classes.import_btn_disabled}
            />
          </div>
          <div className={classes.searchBar}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
              <FormControlLabel
                style={{ fontFamily: 'Avenir', marginRight: '5px' }}
                checked={allSelected}
                onChange={() => {
                  setAllSelected(!allSelected);
                  if (allSelected === false) {
                    setSelectedContacts([...contacts?.map(c => c.id)]);
                  } else {
                    setSelectedContacts([]);
                  }
                }}
                control={<Checkbox color="primary" />}
                label={<StyledLabel>Select all</StyledLabel>}
                name="allSelect"
                disabled={false}
              />
              {selectedContacts?.length > 0 && (
                <button className={classes.deleteBtn} type="button" onClick={handleDeleteAll}>
                  Delete
                </button>
              )}
              {selectedContacts?.length > 0 && (
                <button className={classes.addTagBtn} type="button" onClick={handleAddTag}>
                  Add Tag
                </button>
              )}
              {selectedContacts?.length > 0 && (
                <button className={classes.sendEmailBtn} type="button" onClick={handleSendEmail}>
                  Send an Email
                </button>
              )}
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
              <div>
                <SearchBox
                  placeholder="Search"
                  searchIconColor="rgba(0, 0, 0, 0.87)"
                  background="white"
                  onChange={handleSearch}
                />
              </div>
              <ClickAwayListener
                onClickAway={() => {
                  setFilterPanelOpen(false);
                }}
              >
                <div style={{ position: 'relative' }}>
                  <button className={classes.filterBtn} type="button" onClick={handleFilterClick}>
                    <FilterIcon /> Filter
                  </button>
                  {!isEmpty(appliedFilters) && <FilterTag color="#215C73" />}
                  {filterPanelOpen && (
                    <AllContactsFilter
                      type={FilterTypes.AllContacts}
                      value={appliedFilters}
                      onCancelClick={() => {
                        setFilterPanelOpen(false);
                      }}
                      onSubmitClick={handleSubmitFilter}
                      onClear={() => {
                        setAppliedFilters({});
                        setIsFilterApplied(false);
                        setFilterPanelOpen(false);
                      }}
                      contacts={contacts.filter(item => item.isDeleted === false)}
                    />
                  )}
                </div>
              </ClickAwayListener>
            </div>
          </div>
          <div className={classes.contactsTable}>
            {fetchingContacts ? (
              <Loader />
            ) : (
              <>
                {getSearchedAndFilteredData(contacts, searchedKeyword?.toLocaleLowerCase(), appliedFilters)?.length ===
                  0 &&
                (!isEmpty(appliedFilters) || searchedKeyword?.length > 0) &&
                searchedKeyword.length > 0 ? (
                  <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '600' }}>No search results</div>
                ) : getSearchedAndFilteredData(contacts, searchedKeyword?.toLocaleLowerCase(), appliedFilters)
                    ?.length === 0 &&
                  (!isEmpty(appliedFilters) || searchedKeyword?.length > 0) &&
                  searchedKeyword.length === 0 ? (
                  <div style={{ display: 'flex', justifyContent: 'center', fontWeight: '600' }}>No contacts yet.</div>
                ) : (
                  <ContactsTable
                    contacts={getSearchedAndFilteredData(
                      contacts.filter(item => item?.isDeleted === false),
                      searchedKeyword?.toLocaleLowerCase(),
                      appliedFilters,
                    )?.slice((currentPage - 1) * PER_PAGE_ALLCONTACTS_COUNT, currentPage * PER_PAGE_ALLCONTACTS_COUNT)}
                    isChecked={isChecked}
                    onCheckClicked={onCheckClicked}
                  />
                )}
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                  <div style={{ fontSize: '13px', fontWeight: '400', position: 'relative', bottom: '-28px' }}>
                    {currentPage * PER_PAGE_ALLCONTACTS_COUNT - PER_PAGE_ALLCONTACTS_COUNT === 0
                      ? 1
                      : currentPage * PER_PAGE_ALLCONTACTS_COUNT - PER_PAGE_ALLCONTACTS_COUNT + 1}{' '}
                    -{' '}
                    {pageCount != currentPage
                      ? currentPage * PER_PAGE_ALLCONTACTS_COUNT
                      : contacts.filter(id => id.isDeleted === false).length}{' '}
                    of {contacts.filter(id => id.isDeleted === false).length}
                  </div>
                  <PaginationBar
                    page={currentPage}
                    handleChange={(e, page) => {
                      setCurrentPage(page);
                    }}
                    count={pageCount}
                  />
                </div>
              </>
            )}
          </div>

          <Modal
            isOpen={isDeleteContactsModalOpen}
            title="Delete Contact(s)"
            submitTitle="Delete"
            onSubmit={() => {
              setIsDeleting(true);
              ContactsServices.deleteContact([...selectedContacts])
                .then(res => {
                  console.log('res', res);
                  setSelectedContacts([]);
                  setIsUnderstandChecked(false);
                  setIsDeleteContactsModalOpen(false);
                  dispatch(fetchAllContactsAsync({}));
                })
                .catch(err => {
                  console.log('err', err);
                })
                .finally(() => {
                  setIsDeleting(false);
                });
            }}
            onCancel={() => {
              setIsDeleteContactsModalOpen(false);
            }}
            reduceCancelButton
            disableSubmitClick={!isUnderstandChecked || isDeleting}
          >
            <div className={classes.delete_modal_main}>
              <div className={classes.top_heading}>
                Are you sure you want to delete this/these contact(s)? Your action cannot be undone.
              </div>
              <div className={classes.form_control_label_container}>
                <FormControlLabel
                  checked={isUnderstandChecked}
                  onChange={() => {
                    setIsUnderstandChecked(!isUnderstandChecked);
                  }}
                  control={<Checkbox className={classes.checkbox_root} disableRipple={true} color="primary" />}
                  className={classes.form_control}
                  label={
                    <div className={classes.form_label}>
                      Please note: This action will remove the contact from all lists and services. They will need to
                      re-enroll to be added back to your contacts.
                    </div>
                  }
                  name="understand"
                  disabled={false}
                />
              </div>
              <div>
                <div className={classes.contact_list_heading}>Following contact(s) will be deleted:</div>
                <div className={classes.contact_list}>
                  {contacts
                    .filter(id => id.isDeleted === false)
                    ?.filter(c => selectedContacts.includes(c.id))
                    ?.map((item, index) => {
                      const isLastIndex = index === selectedContacts?.length - 1;
                      return isLastIndex ? (
                        <div>{`${item?.firstName} ${item?.lastName}`}</div>
                      ) : (
                        <div>{`${item?.firstName} ${item?.lastName}, `}</div>
                      );
                    })}
                </div>
              </div>
            </div>
          </Modal>

          {isAddTagModalOpen && (
            <AddContactTagModal
              contacts={contacts
                .filter(item => item.isDeleted === false)
                ?.filter(contact => selectedContacts.includes(contact.id))}
              contactsList={contacts
                .filter(item => item.isDeleted === false)
                .map(c => ({ title: `${c.firstName} ${c.lastName}`, value: c.id }))}
              isOpen={isAddTagModalOpen}
              onClose={() => {
                setSelectedContacts([]);
                setIsAddTagModalOpen(false);
              }}
            />
          )}

          <Modal
            title="Send a One-Time Email"
            isOpen={isEmailModalOpen}
            onCancel={() => {
              setIsEmailModalOpen(false);
            }}
            onSubmit={() => {
              setIsEmailModalOpen(false);
            }}
            submitTitle="Send"
            reduceCancelButton
            widthRequired
          >
            <div className={classes.email_modal_main}>
              <div>
                <div className={classes.label}>Send a One-Time Email</div>
                <div className={classes.select_container}>
                  <Select
                    className={classes.select}
                    value={['All']}
                    onChange={() => {}}
                    multiple
                    items={[{ title: 'All', value: 'All' }]}
                  />
                </div>
              </div>
            </div>
          </Modal>

          {isImportFileModalOpen && (
            <ImportFileContent
              title="Import CSV file"
              isOpen={isImportFileModalOpen}
              onCancel={() => {
                setIsImportFileModalOpen(false);
              }}
            />
          )}
        </div>
      </StyledMainSection>
    </MainContainer>
  );
};

export default AllContacts;
