import React from 'react';
import { Formik, Form } from 'formik';
import { TextField } from '@material-ui/core';
import Button from 'components/FormUI/Button';
import { getQuizLoginSchema } from 'utils/validation';
import classes from './LoginPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createQuizLeadUser } from 'pages/CreateQuizes/Services/CreateQuizes.service';
import { setLeadUser } from 'actions/quizes';
import useRouter from 'hooks/useRouter';
import useScreenView from 'hooks/useScreenView';
import { lightOrDark } from 'utils/utils';
import { CONTRIBUTION_COLORS } from '../../../../constants';
import QuizViewMain from 'pages/QuizViewPage/Components/Containers/MainContainer/MainContainer';
import { useHeader } from 'hooks/usePageActions';
import { Tooltip } from '@material-ui/core';
import { useTheme, useMediaQuery } from '@material-ui/core';
const HeaderTitle = ({ title = '', type, isEditing }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  let titleText = '';
  if (title?.length > 0) {
    if (mobileView) {
      if (title?.length > 30) {
        titleText = title.substring(0, 25) + '...';
      } else {
        titleText = title;
      }
    } else {
      if (title?.length > 50) {
        titleText = title.substring(0, 40) + '...';
      } else {
        titleText = title;
      }
    }
  } else {
    if (type === 'Form' && isEditing) {
      titleText = 'Edit a Form';
    } else if (type === 'Form' && !isEditing) {
      titleText = 'Create a Form';
    } else if (type != 'Form' && isEditing) {
      titleText = 'Edit a Quiz';
    } else {
      titleText = 'Create a Quiz';
    }
  }
  return (
    <Tooltip title={title} arrow>
      <div style={{ fontSize: mobileView ? '20px' : '' }}>{titleText}</div>
    </Tooltip>
  );
};
export default function LoginQuizPage() {
  const { history } = useRouter();
  const { activeQuiz } = useSelector(state => state?.quizes);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  useHeader(activeQuiz.title);
  const { title, type } = activeQuiz;
  const isBrandingColorEnabled = activeQuiz.isBrandingColorEnabled;
  const brandinColors = activeQuiz.brandingColors;
  const { PrimaryColorCode, AccentColorCode, TextColorCode } = brandinColors ?? CONTRIBUTION_COLORS;
  const textColor =
    TextColorCode === 'Auto' ? lightOrDark(PrimaryColorCode) : TextColorCode === '#000000' ? '#000000' : '#FFFFFF';

  const submitHandler = (values, { setSubmitting }) => {
    let params = {
      ...values,
      leadMagnetId: activeQuiz.id,
      leadType: activeQuiz.type,
    };
    createQuizLeadUser(params)
      .then(res => {
        dispatch(setLeadUser(res));
        if (activeQuiz.type === 'Quiz') {
          history.replace(`/quiz-view/${activeQuiz.id}/quiz`);
        } else {
          history.replace(`/form-view/${activeQuiz.id}/quiz`);
        }
        setSubmitting(false);
      })
      .catch(error => {
        setSubmitting(false);
      });
  };

  return (
    <QuizViewMain
      headerProps={{
        headerTitle: <HeaderTitle {...{ title, type }} />,
      }}
    >
      <div className={classes.container}>
        <div className={classes.header_text}>
          To get started, please provide your first name, last name and email address below.
        </div>
        <div className={classes.form}>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
            }}
            validationSchema={getQuizLoginSchema}
            onSubmit={submitHandler}
          >
            {({ setFieldValue, values, errors, touched, handleSubmit, isSubmitting }) => (
              <Form id="signUpForm" onSubmit={handleSubmit}>
                <TextField
                  className="input-field application-form-input"
                  id="firstName"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  type="text"
                  placeholder="First Name"
                  value={values.firstName}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  onChange={e => setFieldValue('firstName', e.target.value)}
                />
                <TextField
                  className="input-field application-form-input"
                  id="lastName"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  type="text"
                  placeholder="Last Name"
                  value={values.lastName}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  onChange={e => setFieldValue('lastName', e.target.value)}
                />
                <TextField
                  className="input-field application-form-input"
                  id="email"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  type="text"
                  placeholder="Email*"
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  onChange={e => setFieldValue('email', e.target.value)}
                />
                <div className={classes.submit_wrapper}>
                  <Button
                    className={classes.submit_btn}
                    backgroundColor={isBrandingColorEnabled ? PrimaryColorCode : null}
                    textColor={isBrandingColorEnabled ? textColor : null}
                    variant="secondary"
                    autoWidth
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {activeQuiz.type === 'Quiz' ? 'Take Quiz' : 'Next'}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </QuizViewMain>
  );
}
