import React from 'react';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';
import ConnectStripeStepOne from 'assets/connect-stripe-step-one.png';
import ConnectStripeStepTwo from 'assets/connect-stripe-step-two.png';

const ConnectStripeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 15px 0px;
  background: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#2d2f31' : '#fff')};
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
  width: ${({ mobileView }) => (mobileView ? '350px' : '600px')};
  gap: 15px;

  img {
    width: ${({ mobileView }) => (mobileView ? '100%' : '50%')};
    margin-bottom: 5px;
  }
`;

const ConnectStripeSubtitle = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  line-height: 24px;
  text-align: left;
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#000')};
`;

const Bullet = styled.div`
  .number {
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 18px;
    border: 1px solid rgba(201, 179, 130, 1);

    label {
      font-size: 16px;
      font-weight: 800;
    }
  }
`;

const NumberBullet = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  .description {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 350;
    line-height: 24px;
    text-align: left;
  }

  .imp-description {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    text-align: left;
    color: rgba(239, 30, 30, 0.87);
  }
`;

const ConnectStripeModal = ({ isOpen, onCancel, onSubmit, isDarkModeEnabled, mobileView }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onCancel={onCancel}
        onSubmit={onSubmit}
        hiddenCancel
        title="Affiliate Verification Needed For Payouts"
        submitTitle="Verify"
      >
        <ConnectStripeContainer mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
          <ConnectStripeSubtitle>
            To invite and receive affiliate revenue, please verify your information on Stripe.
          </ConnectStripeSubtitle>
          <NumberBullet mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
            <Bullet>
              <div className="number">
                <label>1</label>
              </div>
            </Bullet>
            <div className="description">Click on ‘Verify’ button below.</div>
          </NumberBullet>
          <NumberBullet mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
            <Bullet>
              <div className="number">
                <label>2</label>
              </div>
            </Bullet>
            <div className="description">Add a few details about yourself and your business on Stripe.</div>
          </NumberBullet>
          <img src={ConnectStripeStepOne} alt="Connect Stripe Step One" />
          <NumberBullet mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
            <Bullet>
              <div className="number">
                <label>3</label>
              </div>
            </Bullet>
            <div className="imp-description">
              IMPORTANT: Click Edit to modify your personal and business details, otherwise the verification will fail.
            </div>
          </NumberBullet>
          <img src={ConnectStripeStepTwo} alt="Connect Stripe Step Two" />
          <NumberBullet mobileView={mobileView} isDarkModeEnabled={isDarkModeEnabled}>
            <Bullet>
              <div className="number">
                <label>4</label>
              </div>
            </Bullet>
            <div className="description">Click on ‘Confirm’ button to save changes.</div>
          </NumberBullet>
        </ConnectStripeContainer>
      </Modal>
    </>
  );
};

export default ConnectStripeModal;
