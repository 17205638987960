import styled, { css } from 'styled-components';
import { colors } from 'utils/styles';
import { darken } from 'polished';

const getColor = props => {
  if (props.isDragAccept) {
    return colors.darkOceanBlue;
  }
  if (props.isDragReject) {
    return '#eeeeee';
  }
  if (props.isDragActive) {
    return colors.darkOceanBlue;
  }
  return '#bdbdbd';
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ mobileView }) => (mobileView ? `column` : 'row')};
  /* align-items: center; */
  justify-content: space-between;
  gap: 20px;

  ${({ userNotAllowed }) =>
    userNotAllowed &&
    css`
      pointer-events: none;
    `}
  ${({ $styleOverrides }) => $styleOverrides && $styleOverrides}
`;

const FlexColumn = styled.div`
  flex-basis: 50%;
`;

const Container = styled.div`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ showVideo }) => !showVideo && '20px'};
  background-color: #f5f5f5;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 208px;
  margin: 8px 0 20px;
  border-radius: 0.25rem;
`;

const StyledIconWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 60px;
  transition: color 0.24s ease-in-out;
  margin-bottom: 5px;
  color: ${props => getColor(props)};
`;

const MainDiv = styled.div`
  width: 'auto';
  position: absolute;
  padding: ${({ isSessionPage }) => (isSessionPage ? '40px 20px' : '20px')};
  /* top: 0px;
  bottom: 5px; */
  left: 5px;
  right: 5px;
`;

const Image = styled.img`
  width: 100%;
  height: 250px;
  object-fit: contain;
  margin: 8px 0 20px;
`;

const StyledVideo = styled.video`
  border-radius: 4px;
  display: flex;
  height: 250px;
  justify-content: center;
  margin: 10px 0 10px;
  // max-width: 400px;
  width: 100%;
`;

const StyledPlayIconWrapper = styled.div`
  svg {
    margin-left: 0.25rem;
  }
`;

const StyledDeleteContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 15px;
  z-index: 10;
  cursor: pointer;
`;

const StyledBodyContainer = styled.div`
  margin: 3rem 5rem;
  ${({ mobileView }) =>
    mobileView &&
    `
    margin:  2.5rem 0rem;
`}
`;

const StyledBanner = styled.div`
  padding: 2rem;
  ${({ mobileView }) =>
    mobileView &&
    css`
      padding: 0rem;
    `};
  margin-bottom: 2.69rem;
`;
const StyledError = styled.div`
  color: ${({ PrimaryColor }) => PrimaryColor};

  font-size: 14px;
  margin-top: 2px;
  padding-left: 10px;
`;

const StyledButton = styled.button`
  position: relative;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 5px;
  border: none;
  color: #fff;
  fill: #fff; /* for svg */
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  min-width: 18rem;
  width: 100%;
  transition: background 0.2s;
  font-family: 'Poppins';

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 10px;
    `}

  ${({ variant, backgroundColor, activeTemplate, textColor }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
        color: ${textColor};
      `;
    }
    // return activeTemplate === TemplateType.TemplateOne
    //   ? css`
    //       background-color: #6999ca;
    //     `
    //   : css`
    //       background-color: #b78aea;
    //     `;
  }}

  ${({ invert }) =>
    invert &&
    css`
      color: rgba(0, 0, 0, 0.87);
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.87);
    `}

  &:hover {
    ${({ variant, backgroundColor, activeTemplate }) => {
      if (backgroundColor) {
        return css`
          background-color: ${darken(0.05, backgroundColor)};
        `;
      }
      // return activeTemplate === TemplateType.TemplateOne
      //   ? css`
      //       background-color: ${darken(0.05, '#6999CA')};
      //     `
      //   : css`
      //       background-color: ${darken(0.05, '#B78AEA')};
      //     `;
    }}

    ${({ invert }) =>
      invert &&
      css`
        background-color: ${darken(0.05, 'white')};
      `}
  }

  :disabled {
    background-color: #9b9b9b;
    color: #fff;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;

export const StyledUploadThumbnailContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 15px;
  z-index: 10;
  cursor: pointer;
`;

export const StyledPdfContainer = styled.div`
  display: flex;
  height: ${({ mobileView }) => (mobileView ? 'auto' : '13.125rem')};
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.375rem;
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  background: #fff;
`;

export const StyledPdfIcon = styled.div`
  display: flex;
  height: 9.6875rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  align-self: stretch;
  background: #fafafa;
`;

export const StyledPdfTextContainer = styled.div`
  color: var(--Cohere-Greys-Text, #4a4a4a);
  text-align: center;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
  display: flex;
  /* width: 12.0625rem; */
  height: 3.4375rem;
  padding: 0.625rem 0.875rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  flex-shrink: 0;
`;

const StyledBannerTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTextContainer = styled.div`
  color: var(--Cohere-Greys-Text, #4a4a4a);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
  height: ${({ mobileView }) => (mobileView ? 'auto' : 'auto')};
  padding: ${({ mobileView }) => (mobileView ? '' : '0.625rem 0.875rem')};
  background-color: ${props => (props.bgColor ? props.bgColor : 'white')};
  width: 100%;
`;
export {
  FlexContainer,
  FlexColumn,
  Container,
  StyledIconWrap,
  MainDiv,
  Image,
  StyledVideo,
  StyledPlayIconWrapper,
  StyledDeleteContainer,
  StyledBodyContainer,
  StyledBanner,
  StyledError,
  StyledButton,
  StyledBannerTitleRow,
};
