import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';
import { lightOrDark } from 'utils/utils';
import { StyledButton } from './AboutMainInfoBlock/AboutMainInfoTemOne';
import styled, { css } from 'styled-components';
import { mdiApps, mdiMessageProcessing, mdiTimerOutline, mdiGenderMale, mdiAccountOutline } from '@mdi/js';
import { memberPodsService } from 'services/memberPods/memberPods.service';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { BiSolidTimeFive } from 'react-icons/bi';
import { BsFillCameraVideoFill, BsChatLeftDotsFill, BsFillPersonFill } from 'react-icons/bs';
import { getLanguages } from 'utils/transformerLanguages';
import { Card, CardBody } from 'components/UI/Card';
import { LabelText } from 'components/UI/Text/TextStyles';
import Icon from 'components/UI/Icon';
import breathIcon from '../../../assets/1.Bs.png';
import sessionIcon from '../../../assets/2.Bs.png';
import lanIcon from '../../../assets/3.Bs.png';
import ageIcon from '../../../assets/4.Bs.png';
import requirementIcon from '../../../assets/5.Bs.png';
import PurchaseBlock from './PurchaseBlock/PurchaseBlock';
import { ContributionType, ContributionThemedColors } from 'helpers/constants';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import useAccount from 'hooks/useAccount';

const useStyles = makeStyles(theme => ({
  gridItem: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const genderDisplay = {
  NoRequirement: 'No Requirement',
  Male: 'For Men',
  Female: 'For Women',
  NonBinary: 'Everyone Welcome',
};

const getSessionsCount = ({ availabilityTimes = [], clientPackages }) => {
  let newArray = [];

  availabilityTimes.forEach(time => {
    time.bookedTimes.forEach(booked => {
      if (booked.participantId && booked.isPurchaseConfirmed) {
        newArray = [...newArray, booked];
      }
    });
  });

  return newArray.length + (clientPackages && clientPackages.length ? sumBy(clientPackages, 'freeSessionNumbers') : 0);
};

const AboutGeneralInfoBlock = ({
  contribution = {},
  categories = [],
  gender,
  minAge,
  languageCodes = [],
  brandingColors,
}) => {
  const classes = useStyles();
  var colorsStyle2 = [
    brandingColors?.PrimaryColorCode,
    brandingColors?.AccentColorCode,
    brandingColors?.TertiaryColorCode,
  ];
  const { user, currentRole } = useAccount();
  const theme = useTheme();
  const [totalSessions, setTotalSessions] = useState({
    liveSession: null,
    selfPaced: null,
  });
  const [podsArray, setPodsArray] = useState([]);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const showPaymentCard = useMediaQuery(theme.breakpoints.down('sm'));
  const oneToOneType = contribution?.type === ContributionType.contributionOneToOne;
  const { themedColor } = getThemedColors(contribution);
  useEffect(() => {
    memberPodsService.getContributionPods(contribution.id).then(data => {
      setPodsArray(data);
    });
  }, []);
  const sessionsCount = !isEmpty(contribution?.sessions)
    ? contribution.sessions.length
    : getSessionsCount(contribution);

  const StyledName = styled.div`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: ${({ showPaymentCard }) => (showPaymentCard ? '10px' : '12px')};
    line-height: 13px;
  `;
  const SideIconMain = styled.div`
    padding: 0.5rem 0px 0px 0px;
    margin-top: 15px;
  `;
  const SideImg1 = styled.div`
    display: flex;
    // width: 100%;
    align-items: center;
    margin-right: 15px;
    ${({ mobileView }) =>
      mobileView &&
      css`
        padding: 5px 0px;
      `}
  `;
  const SideIcon = styled.img``;
  const StyledText = styled.p`
    font-family: 'Poppins';
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 0.5rem;
    margin: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: ${({ showPaymentCard }) => (showPaymentCard ? '13px' : '18px')};
    line-height: 27px;
    color: ${props => props.themedColor};
  `;
  const BorderDiv = styled.div`
    border: ${({ color }) => `1px solid ${color}`};
    opacity: ${({ opacity }) => opacity};
    margin: ${props => props.margin};
  `;

  const StyledBiSolidTimeFive = styled(BiSolidTimeFive)`
    color: ${({ color }) => color};
    height: ${({ showPaymentCard }) => (showPaymentCard ? '15px' : '24px')};
    width: ${({ showPaymentCard }) => (showPaymentCard ? '15px' : '24px')};
    margin: ${({ showPaymentCard }) => (showPaymentCard ? '0px 3px;' : '0px 5px;')};
  `;

  const StyledBsFillCameraVideoFill = styled(BsFillCameraVideoFill)`
    color: ${({ color }) => color};
    height: ${({ showPaymentCard }) => (showPaymentCard ? '15px' : '24px')};
    width: ${({ showPaymentCard }) => (showPaymentCard ? '15px' : '24px')};
    margin: ${({ showPaymentCard }) => (showPaymentCard ? '0px 3px;' : '0px 5px;')};
  `;

  const StyledBsChatLeftDotsFill = styled(BsChatLeftDotsFill)`
    color: ${({ color }) => color};
    height: ${({ showPaymentCard }) => (showPaymentCard ? '15px' : '24px')};
    width: ${({ showPaymentCard }) => (showPaymentCard ? '15px' : '24px')};
    margin: ${({ showPaymentCard }) => (showPaymentCard ? '0px 3px;' : '0px 5px;')};
  `;

  const StyledBsFillPersonFill = styled(BsFillPersonFill)`
    color: ${({ color }) => color};
    height: ${({ showPaymentCard }) => (showPaymentCard ? '15px' : '24px')};
    width: ${({ showPaymentCard }) => (showPaymentCard ? '15px' : '24px')};
    margin: ${({ showPaymentCard }) => (showPaymentCard ? '0px 3px;' : '0px 5px;')};
  `;

  const StyledCategories = styled.div`
    margin-top: 20px;
  `;

  let colorToUse = determineColorToUse(contribution);
  const textColorToUse =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const filterSessions = () => {
    // const podIds = sessions.flatMap(session => session.sessionTimes.filter(sessionTime => sessionTime.podId !== "").map(sessionTime => sessionTime.podId));
    let count = 0;
    if (contribution?.sessions) {
      for (const session of contribution?.sessions) {
        let shouldCountSession = false;
        for (const sessionTime of session.sessionTimes) {
          // Check if SessionTime does not have a PodId
          if (sessionTime.podId === '' || sessionTime.podId === null) {
            shouldCountSession = true;
            break; // One SessionTime without a PodId is enough to count the session
          } else {
            // If SessionTime has a PodId, check if the user is not in the Pod's ClientIds
            const pod = podsArray.find(p => p.id === sessionTime.podId);
            if (isEmpty(pod) || pod.clientIds.includes(user.id)) {
              shouldCountSession = true;
              break; // User not being in a Pod's ClientIds is enough to count the session
            }
          }
        }

        // If the criteria are met, count the session
        if (shouldCountSession) {
          count = count + 1;
        }
      }
    }
    return count;
  };
  useEffect(() => {
    if (contribution?.sessions) {
      let selfPaced = totalSessions.selfPaced;
      let liveSession = totalSessions.liveSession;
      const mappedSessions = contribution?.sessions.map(session => {
        if (session.isPrerecorded) {
          selfPaced = selfPaced + 1;
        } else {
          liveSession = liveSession + 1;
        }
        return session;
      });
      setTotalSessions({
        liveSession,
        selfPaced,
      });
    }
  }, []);
  useEffect(() => {
    if (!isEmpty(user)) filterSessions();
  }, [podsArray]);

  return (
    <>
      <BorderDiv
        color={contribution?.isDarkModeEnabled ? '#696969' : '#000000'}
        opacity={contribution?.isDarkModeEnabled ? 1 : 0.05}
      />
      <StyledCategories style={{ padding: showPaymentCard ? '0px 10px' : '0px' }}>
        {categories.map((item, index) => {
          var color2 = colorToUse?.AccentColorCode;
          return (
            <StyledButton
              disabled={true}
              mobileView={mobileView}
              background={color2}
              color={textColorToUse}
              margin="5px 3px"
              style={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: showPaymentCard ? '10px' : '12px',
                padding: '0px 7px 0px 7px',
              }}
            >
              <StyledName showPaymentCard={showPaymentCard}>{item}</StyledName>
            </StyledButton>
          );
        })}
      </StyledCategories>

      {contribution?.id != '668302b62459e1456f1b814d' && contribution?.id != '6682fb852459e1456f1b7f48' && (
        <SideIconMain className="session-items-one-line" style={{ padding: showPaymentCard ? '0px 10px 10px' : '0px' }}>
          {/* <SideImg1 mobileView={mobileView}>
          <SideIcon src={breathIcon}></SideIcon>
          <BreathTxt>{categories.join(', ')}</BreathTxt>
        </SideImg1> */}
          {(oneToOneType || totalSessions.liveSession !== null) && (
            <SideImg1 mobileView={mobileView}>
              {/* <SessionSvg color={themedColor} /> */}
              <StyledBiSolidTimeFive color={brandingColors?.PrimaryColorCode} showPaymentCard={showPaymentCard} />
              {isEmpty(user) ? (
                <StyledText showPaymentCard={showPaymentCard} themedColor={themedColor}>{`${
                  oneToOneType ? '1:1' : totalSessions.liveSession
                } ${totalSessions.liveSession == 1 ? `Session` : `Sessions`}`}</StyledText>
              ) : (
                <StyledText showPaymentCard={showPaymentCard} themedColor={themedColor}>{`${
                  oneToOneType ? '1:1' : filterSessions() - totalSessions.selfPaced
                } ${filterSessions() - totalSessions.selfPaced == 1 ? `Session` : `Sessions`}`}</StyledText>
              )}
            </SideImg1>
          )}
          {totalSessions.selfPaced && (
            <SideImg1 mobileView={mobileView}>
              <StyledBsFillCameraVideoFill color={brandingColors?.PrimaryColorCode} showPaymentCard={showPaymentCard} />
              <StyledText showPaymentCard={showPaymentCard} themedColor={themedColor}>{`${
                oneToOneType ? '1:1' : totalSessions.selfPaced
              } Self - Paced Module${totalSessions.selfPaced > 1 ? 's' : ''}`}</StyledText>
            </SideImg1>
          )}
          <SideImg1 mobileView={mobileView}>
            {/* <LanguageSvg color={themedColor} /> */}
            <StyledBsChatLeftDotsFill color={brandingColors?.PrimaryColorCode} showPaymentCard={showPaymentCard} />
            <StyledText showPaymentCard={showPaymentCard} themedColor={themedColor}>
              {getLanguages(languageCodes).join(', ')}
            </StyledText>
          </SideImg1>
          <SideImg1 mobileView={mobileView}>
            {/* <MinAgeSvg color={themedColor} /> */}
            <StyledBsFillPersonFill color={brandingColors?.PrimaryColorCode} showPaymentCard={showPaymentCard} />
            <StyledText showPaymentCard={showPaymentCard} themedColor={themedColor}>
              {minAge}
            </StyledText>
          </SideImg1>
          {/* {genderDisplay[gender] != 'No Requirement' && (
          <SideImg1 mobileView={mobileView}>
            <SideIcon src={requirementIcon}></SideIcon>
            <BreathTxt>{genderDisplay[gender]}</BreathTxt>
          </SideImg1>
        )} */}
        </SideIconMain>
      )}
      {/* {mobileView && <PurchaseBlock IsLoginAccount={false} />} */}

      {/* <Card>
        <CardBody mobileView={mobileView}>
          <Grid container spacing={2} justify="space-between">
            <Grid item className={classes.gridItem}>
              <Icon path={mdiApps} right="4" />
              <LabelText mobileView={mobileView}>
                <span>{categories.join(', ')}</span>
              </LabelText>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Icon path={mdiTimerOutline} right="4" />
              <LabelText mobileView={mobileView}>{`${
    !isEmpty(contribution?.sessions) ? contribution.sessions.length : getSessionsCount(contribution)
  } Sessions`}</LabelText>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Icon path={mdiMessageProcessing} right="4" />
              <LabelText mobileView={mobileView}>{getLanguages(languageCodes).join(', ')}</LabelText>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Icon path={mdiAccountOutline} right="4" />
              <LabelText mobileView={mobileView}>{minAge}</LabelText>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Icon path={mdiGenderMale} right="4" />
              <LabelText mobileView={mobileView}>{genderDisplay[gender]}</LabelText>
            </Grid>
          </Grid>
        </CardBody>
      </Card> */}
    </>
  );
};

AboutGeneralInfoBlock.propTypes = {
  contribution: PropTypes.shape({}),
  categories: PropTypes.arrayOf(PropTypes.string),
  gender: PropTypes.string.isRequired,
  minAge: PropTypes.number.isRequired,
  languageCodes: PropTypes.arrayOf(PropTypes.string),
};

AboutGeneralInfoBlock.defaultProps = {
  contribution: {},
  categories: [],
  languageCodes: [],
};

export default AboutGeneralInfoBlock;
