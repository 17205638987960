import React, { useEffect, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { ClickAwayListener, makeStyles } from '@material-ui/core';
import ClickDropDown from 'components/UI/ClickDropDown';
import CohereIcon from 'assets/coherelogo.png';
import DummyImage from 'assets/CrousalItem1.png';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import classes from './Tag.module.scss';
import DropDownList from 'components/UI/DropDownList/DropDownList';
import { Link } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { colors } from 'utils/styles';
import EditTagModalContent from 'pages/Contacts/Components/AddTagModalContent/AddTagModalContent';
import Modal from 'components/UI/Modal';
import Portal from 'components/UI/Portal';
import moment from 'moment';
import { ContactsServices } from 'pages/Contacts/Services/Contacts.service';
import { useDispatch } from 'react-redux';
import { fetchAllTagsAsync } from 'actions/contacts';

const StyledDropDownWrapper = styled.div`
  position: relative;
`;

const StyledType = styled.div`
  height: 24px;
  width: 50px;
  border-radius: 5px;
  padding: 2px 8px;
  color: #215c73;
  border: 1.5px solid #215c73;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.23999999463558197px;
  text-align: left;
`;

const TagsContainer = styled.div`
  //   width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  //   flex-wrap: wrap;
  gap: 5px;
`;

const StyledTag = styled.div`
  height: 24px;
  border-radius: 5px;
  padding: 2px 8px;
  color: ${colors.newGold};
  border: 1.5px solid ${colors.newGold};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.23999999463558197px;
  text-align: left;
`;

const StyledDropDownItem = styled(Link)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #4a4a4a;
  text-decoration: none;
  white-space: nowrap;
  background-color: white;
  padding: 8px 20px;
  border-bottom: 1px solid #f0f0f0;
  min-width: 100px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  text-align: left;

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 1;
    color: gray;
    pointer-events: none;
    cursor: not-allowed;
  
  `}

  &:hover {
    text-decoration: underline;
  }
`;

const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    borderBottom: 0,
  },
})(TableCell);

const StyledImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #dfe3e4;
`;
const StyledContacts = styled.div`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  text-decoration: ${({ clickable }) => (clickable ? 'underline' : 'none')};
`;

const StyledDropDown = styled.div`
  position: absolute;
  width: auto;
  top: ${({ top }) => (top ? top : '0px')};
  left: ${({ left }) => (left ? left : '0px')};
  z-index: 100;
  box-shadow: 0px 6px 30px 0px rgba(33, 92, 115, 0.32);
`;
const TooltipText = 'There are no clients for this contribution yet';
export const TRIAL_PERIOD_START_DATE = '2021-11-01';
export const TRIAL_PERIOD_DAYS = 13;

const Tag = ({
  index,
  row,
  isChecked,
  onCheckClicked,
  tagWithOpenDropDown,
  setId,
  setShowContactsList,
  setSelectedTag,
  setIsAddTagModalOpen,
  setIsReadOnlyView,
  setIsAddContact,
  setshowModalToAddContacts,
}) => {
  const dispatch = useDispatch();
  const [isEditTagModalOpen, setIsEditTagModalOpen] = useState(false);
  const [isDeleteTagModalOpen, setIsDeleteTagModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUnderstandChecked, setIsUnderstandChecked] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const clickAwayHandler = () => {
    setId(null);
  };

  useEffect(() => {
    if (tagWithOpenDropDown === null) {
      window.removeEventListener('click', clickAwayHandler);
    }
  }, [tagWithOpenDropDown]);

  const handleEditClick = () => {
    setSelectedTag(row);
    setIsReadOnlyView(false);
    setIsAddTagModalOpen(true);
  };

  const handleDelete = () => {
    setIsDeleteTagModalOpen(true);
  };

  const handleCountsClick = () => {
    if (row?.contactsCount > 0) {
      setSelectedTag(row);
      setIsReadOnlyView(true);
      setShowContactsList(true);
    }
  };

  const handleAddContactClick = () => {
    setIsAddContact(true);
    setSelectedTag(row);
    setShowContactsList(true);
    setshowModalToAddContacts(true);
  };

  return (
    <>
      <TableRow className={classes.container}>
        <NarrowTableCell component="th" scope="row" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <FormControlLabel
            style={{ fontFamily: 'Avenir', marginRight: '5px', marginBottom: '0px' }}
            checked={isChecked(row?.id)}
            onChange={() => {
              onCheckClicked(row.id);
            }}
            control={<Checkbox color="primary" />}
            // label={row?.title}
            name="selected"
            disabled={false}
          />
          <div
            style={{ width: '200px', cursor: 'pointer' }}
            onClick={() => {
              // onCheckClicked(row.id);
              handleEditClick();
            }}
          >
            {row?.name}
          </div>
        </NarrowTableCell>
        <NarrowTableCell align="center">
          <StyledContacts clickable={row?.contactsCount > 0 ? true : false} onClick={handleCountsClick}>
            {row?.contactsCount}
          </StyledContacts>
        </NarrowTableCell>
        <NarrowTableCell align="center">{row?.emailSent}</NarrowTableCell>
        <NarrowTableCell>{moment(row?.createTime).format('MMMM D, YYYY hh:mm')}</NarrowTableCell>
        <NarrowTableCell align="center">
          <StyledDropDownWrapper>
            <MoreHorizIcon
              style={{ cursor: 'pointer' }}
              onClick={e => {
                e.stopPropagation();
                const rect = e.currentTarget.getBoundingClientRect();
                const scrollTop = window.scrollY || document.documentElement.scrollTop;
                const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
                setDropdownPosition({ top: rect.bottom + scrollTop, left: rect.left + scrollLeft - 135 });
                if (tagWithOpenDropDown === row?.id) {
                  setId(null);
                } else {
                  setId(row?.id);
                }
                window.removeEventListener('click', clickAwayHandler);
                window.addEventListener('click', clickAwayHandler);
              }}
            />
            {tagWithOpenDropDown === row?.id && (
              <Portal>
                <StyledDropDown top={`${dropdownPosition.top}px`} left={`${dropdownPosition.left}px`}>
                  <div>
                    <StyledDropDownItem onClick={handleAddContactClick}>Add Contact</StyledDropDownItem>
                  </div>
                  <div>
                    <StyledDropDownItem onClick={handleEditClick}>Edit</StyledDropDownItem>
                  </div>
                  <div>
                    <StyledDropDownItem onClick={handleDelete}>Delete</StyledDropDownItem>
                  </div>
                </StyledDropDown>
              </Portal>
            )}
          </StyledDropDownWrapper>
        </NarrowTableCell>
      </TableRow>

      {/* {isEditTagModalOpen && (
        <EditTagModalContent
          isOpen={isEditTagModalOpen}
          onClose={() => {
            setIsEditTagModalOpen(false);
          }}
          tag={row}
          isEditing={true}
        />
      )} */}
      <Modal
        title="Delete Tag"
        isOpen={isDeleteTagModalOpen}
        onCancel={() => {
          setIsDeleteTagModalOpen(false);
        }}
        submitTitle="Delete"
        onSubmit={() => {
          setIsDeleting(true);
          ContactsServices.deleteTag([row?.id])
            .then(res => {
              dispatch(fetchAllTagsAsync());
            })
            .catch(err => {
              console.log('err', err);
            })
            .finally(() => {
              setIsDeleting(false);
              setIsDeleteTagModalOpen(false);
            });
        }}
        reduceCancelButton
        widthRequired
        disableSubmitClick={!isUnderstandChecked || isDeleting}
      >
        <div className={classes.delete_modal_main}>
          <div className={classes.top_heading}>
            Are you sure you want to delete this/these tag(s)? Your action cannot be undone.
          </div>
          <div className={classes.form_control_label_container}>
            <FormControlLabel
              checked={isUnderstandChecked}
              onChange={() => {
                setIsUnderstandChecked(!isUnderstandChecked);
              }}
              control={<Checkbox className={classes.checkbox_root} disableRipple={true} color="primary" />}
              className={classes.form_control}
              label={
                <div className={classes.form_label}>
                  I understand that this action will remove the below tags and cannot be undone.
                </div>
              }
              name="understand"
              disabled={false}
            />
          </div>
          <div>
            <div className={classes.contact_list_heading}>Following tag(s) will be deleted:</div>
            <div className={classes.contact_list}>{row?.name}</div>
          </div>
        </div>
      </Modal>
    </>
  );
};

Tag.propTypes = {};

Tag.defaultProps = {};

export default Tag;
