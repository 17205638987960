import React from 'react';

function ImportIcon({ width = '19', height = '19' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4126 9.90039L8.4126 6.90039V9.15039H2.4126V10.6504H8.4126V12.9004M15.9126 14.4004V5.40039C15.9126 5.00257 15.7546 4.62103 15.4733 4.33973C15.192 4.05843 14.8104 3.90039 14.4126 3.90039H5.4126C5.01477 3.90039 4.63324 4.05843 4.35194 4.33973C4.07063 4.62103 3.9126 5.00257 3.9126 5.40039V7.65039H5.4126V5.40039H14.4126V14.4004H5.4126V12.1504H3.9126V14.4004C3.9126 14.7982 4.07063 15.1797 4.35194 15.4611C4.63324 15.7424 5.01477 15.9004 5.4126 15.9004H14.4126C14.8104 15.9004 15.192 15.7424 15.4733 15.4611C15.7546 15.1797 15.9126 14.7982 15.9126 14.4004Z"
        fill="#215C73"
      />
    </svg>
  );
}

export default ImportIcon;
