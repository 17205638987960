import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import { StyledShareButton } from 'pages/ContributionView/components/ContributionHeader/styled';
import { TOOLTIP } from '../../../../src/constants';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Portal from 'components/UI/Portal';
import { Icon } from '@mdi/react';
import { mdiShareVariant } from '@mdi/js';
import formPreviewIcon from '../../../assets/form-preview-icon.svg';
import { getStyledButton, getPressEffectButton, formatTime, addhttp, downloadFile } from 'utils/utils';
import Modal from 'components/UI/Modal';
import copyToClipboard from 'utils/copyToClipboard';
import Button from 'components/FormUI/Button';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import {
  duplicateQuizForm,
  exportResponsesQuizForm,
  deleteQuizForm,
} from 'pages/CreateQuizes/Services/CreateQuizes.service';
import QuizandFormResponseModal from 'components/Modals/QuizandFormResponseModal';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setCampaignNotification } from 'actions/campaign';
const NarrowTableCell = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    fontWeight: 350,
  },
})(TableCell);
const NarrowTableCellSmall = withStyles({
  root: {
    paddingTop: 8,
    paddingBottom: 8,
    fontWeight: 350,
    // paddingLeft: 60,
    // paddingRight: 50,
  },
})(TableCell);
const StyledDropDownWrapper = styled.div`
  position: relative;
`;
const StyledDropDown = styled.div`
  position: absolute;
  width: auto;
  top: ${({ top }) => (top ? top : '0px')};
  left: ${({ left }) => (left ? left : '0px')};
  z-index: 100;
  box-shadow: 0px 6px 30px 0px rgba(33, 92, 115, 0.32);
`;
const StyledDropDownItem = styled(Link)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #4a4a4a;
  text-decoration: none;
  white-space: nowrap;
  background-color: white;
  padding: 8px 20px;
  border-bottom: 1px solid #f0f0f0;
  min-width: 100px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  text-align: left;

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 1;
    color: gray;
    pointer-events: none;
    cursor: not-allowed;
  `}
  &:hover {
    text-decoration: underline;
  }
`;
const StyledHostName = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 350;
  line-height: 16px;
  color: #4a4a4a;
  width: 600px;
  overflow: hidden;
  border: 1px solid #dfe3e4;
  display: flex;
  align-items: center;
  margin-right: 20px;
  border-radius: 4px;
  padding: 0px 10px;
`;
const SharebleText = styled.div`
  padding: 5px 0px 10px 0px;
`;
const useStyles = makeStyles(({ theme }) => ({
  share_btn: { marginTop: '0px !important' },
}));

export const FormRow = ({ index, row, contactWithOpenDropDown, setId, getQuizesFormsList }) => {
  const classes = useStyles();
  const { creationDate, id: formId, numberOfQuestions, numberOfResponses, quizType, title } = row;
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const PressedEffectButton = getPressEffectButton();
  const history = useHistory();
  const dispatch = useDispatch();
  const url = `/edit-form/${formId}/content`;
  const originText = window.location.origin;
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const clickAwayHandler = () => {
    setId(null);
  };
  const getTitle = () => {
    if (title?.length <= 28) {
      return title;
    } else {
      return title.substring(0, 28) + '...';
    }
  };

  const handleDuplicate = () => {
    duplicateQuizForm(formId).then(res => {
      history.push(`/edit-form/${res.id}/content`);
      dispatch(
        setCampaignNotification({
          message: 'Your form has been duplicated.',
          heading: 'Duplicated Successfully',
        }),
      );
    });
  };

  const handleExportResponse = () => {
    exportResponsesQuizForm(formId).then(res => {
      downloadFile(res, `${title.length > 20 ? title.substring(0, 10) : title}_Responses.csv`);
    });
  };

  const handleDelete = () => {
    deleteQuizForm(formId).then(() => {
      getQuizesFormsList('Form', 1, 10);
      setDeleteModal(false);
    });
  };

  const handleEdit = () => {
    window.location.href = url;
    // history.push(url);
  };
  return (
    <>
      <TableRow key={index}>
        <NarrowTableCell style={{ textDecoration: 'underline' }}>
          <Tooltip title={title} arrow enterTouchDelay={TOOLTIP.ENTER_DELAY} leaveTouchDelay={TOOLTIP.LEAVE_DELAY}>
            <Link to={url}>{getTitle()}</Link>
          </Tooltip>
        </NarrowTableCell>
        <NarrowTableCellSmall>{numberOfQuestions}</NarrowTableCellSmall>
        <NarrowTableCellSmall>{numberOfResponses}</NarrowTableCellSmall>
        <NarrowTableCell component="th" scope="row">
          {formatTime(creationDate)}
        </NarrowTableCell>
        <NarrowTableCell align="center">
          <PressedEffectButton
            onClick={() => {
              setShowModal(true);
            }}
            type="button"
          >
            <RemoveRedEyeOutlinedIcon fontSize="small" htmlColor="#215C73" />
          </PressedEffectButton>
        </NarrowTableCell>
        <NarrowTableCell>
          <StyledShareButton
            className={classes.share_btn}
            style={{ float: 'left', width: 'auto', cursor: numberOfQuestions > 0 ? 'pointer' : 'not-allowed' }}
            startIcon={<Icon path={mdiShareVariant} color={numberOfQuestions > 0 ? '#116582' : '#e7e7e7'} size={1} />}
            onClick={() => {
              if (numberOfQuestions > 0) {
                setShowShareModal(true);
              }
            }}
          />
        </NarrowTableCell>
        <NarrowTableCell align="center">
          <StyledDropDownWrapper>
            <MoreHorizIcon
              style={{ cursor: 'pointer' }}
              onClick={e => {
                e.stopPropagation();
                const rect = e.currentTarget.getBoundingClientRect();
                const scrollTop = window.scrollY || document.documentElement.scrollTop;
                const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
                setDropdownPosition({ top: rect.bottom + scrollTop, left: rect.left + scrollLeft - 160 });
                if (contactWithOpenDropDown === row?.id) {
                  setId(null);
                } else {
                  setId(row?.id);
                }
                window.removeEventListener('click', clickAwayHandler);
                window.addEventListener('click', clickAwayHandler);
              }}
            />
            {contactWithOpenDropDown === row?.id && (
              <Portal>
                <StyledDropDown top={`${dropdownPosition.top}px`} left={`${dropdownPosition.left}px`}>
                  <div>
                    <StyledDropDownItem onClick={handleEdit}>Edit</StyledDropDownItem>
                  </div>
                  <div>
                    <StyledDropDownItem onClick={handleDuplicate}>Duplicate</StyledDropDownItem>
                  </div>

                  <div>
                    <StyledDropDownItem isDisabled={numberOfResponses === 0} onClick={handleExportResponse}>
                      Export Responses
                    </StyledDropDownItem>
                  </div>
                  <div>
                    <StyledDropDownItem
                      onClick={() => {
                        setDeleteModal(true);
                      }}
                    >
                      Delete
                    </StyledDropDownItem>
                  </div>
                </StyledDropDown>
              </Portal>
            )}
          </StyledDropDownWrapper>
        </NarrowTableCell>
      </TableRow>
      <Modal
        isOpen={showShareModal}
        onSubmit={() => {
          setShowShareModal(false);
        }}
        title={'Share Form'}
        disableConfirm={true}
        cancelTitle={'Close'}
        onCancel={() => {
          setShowShareModal(false);
        }}
        modalType={'Quizes'}
        maxWidth={'100%'}
      >
        <SharebleText>Copy Sharable Link</SharebleText>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingBottom: '20px' }}>
          <StyledHostName>{`${originText}/form-view/${formId}`}</StyledHostName>
          <Tooltip
            className="cursor-pointer"
            title="Link Copied!"
            open={isToolTipOpen}
            onClose={() => {
              setIsToolTipOpen(false);
            }}
            arrow
          >
            <div
              onClick={() => {
                setIsToolTipOpen(true);
                setTimeout(() => {
                  copyToClipboard(`${originText}/form-view/${formId}`);
                }, 200);
              }}
            >
              <Button textColor="#fff" autoWidth>
                Copy
              </Button>
            </div>
          </Tooltip>
        </div>
      </Modal>
      <Modal
        isOpen={deleteModal}
        onSubmit={handleDelete}
        title={'Are you sure you want to delete?'}
        cancelTitle={'Cancel'}
        submitTitle={'Yes'}
        onCancel={() => {
          setDeleteModal(false);
        }}
        modalType={'Quizes'}
        brandingColor={false}
      >
        <p style={{ fontWeight: '400', fontFamily: 'Avenir', fontSize: '18px' }}>
          Deleting the form removes access for users. Are you sure you wish to continue?
        </p>
      </Modal>
      <QuizandFormResponseModal setShowModal={setShowModal} showModal={showModal} row={row} />
    </>
  );
};
FormRow.propTypes = {
  index: PropTypes.string.isRequired,
  row: PropTypes.shape({
    contributionName: PropTypes.string.isRequired,
    formLinkId: PropTypes.string.isRequired,
    contributionId: PropTypes.string.isRequired,
    questions: PropTypes.string.isRequired,
    createTime: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};

FormRow.defaultProps = {
  row: {
    contributionName: '',
    formLinkId: '',
    contributionId: '',
    questions: '',
  },
};
