import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHeader, useScreenView } from 'hooks';
import Loader from 'components/UI/Loader';
import { makeStyles } from '@material-ui/core';
import { fetchApplicationForms } from 'actions/applications';
import Button from 'components/FormUI/Button';
import CreateApplicationFormModal from 'pages/ContributionView/components/ContributionHeader/ApplicationForm/CreateApplicationFormModal';
import { APPLICATION_LIMIT } from 'services/applications.service';
import ApplicationFormsTable from 'components/Tables/ApplicationForms/ApplicationForms';
import { fetchContributionActions } from 'actions/contributions';
import AddIcon from '@mui/icons-material/Add';
import ApplicationSelectionModal from './components/ApplicationSelectionModal';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import ApplicationTemplateModal from './components/ApplicationTemplateModal';
import Modal from 'components/UI/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import moment from 'moment';
import * as paidTier from 'selectors/paidTier';
import useShallowEqualSelector from '../../hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES, TOOLTIP } from '../../constants';
import useAccount from 'hooks/useAccount';
import { TRIAL_PERIOD_DAYS, TRIAL_PERIOD_START_DATE } from 'components/Tables/Contribution';
import { getCohealerContribution, shareViaEmail } from 'services/contributions.service';
import ShareModal from 'pages/ContributionView/components/ShareModal/ShareModalContainer';
import EnrollmenFormTable from 'components/Tables/EnrollmentForms/EnrollmentFormTable';
import { colors } from 'utils/styles';
import FormsTable from 'components/Tables/Forms/Forms';
import { getCountUpdate, getQuizzesFormsPaginated } from 'pages/CreateQuizes/Services/CreateQuizes.service';
import PaginationBar from 'pages/EmailCampaigns/Components/PaginationBar/PaginationBar';
import QuizzesTable from 'components/Tables/Quizes/QuizesList';
import {
  setAllFormsCountDetails,
  setDefaultPage,
  setShowCreateModal,
  setShowCreateModalForm,
  setShowCreateModalQuiz,
} from 'actions/quizes';
import CreateQuizesModal from 'pages/CreateQuizes/Components/Modals/CreateModal/CreateModal';
const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.colors.darkOceanBlue2,
    borderRadius: 10,
    color: theme.palette.common.white,
    fontSize: 11,
    fontWeight: theme.typography.fontWeightBold,
    height: 20,
    padding: '3px 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
const AllFormsPage = ({ forms, getForms, loading }) => {
  useHeader('All Forms');
  const classNames = useStyles();
  const [showApplicationFormPopup, setShowApplicationFormPopup] = useState(false);
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [modalType, setModalType] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [template, setTemplate] = useState(null);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [fetchChanges, setFetchChanges] = useState(true);
  const [allQuizes, setAllQuizesList] = useState([]);
  const { showModal, showFormModal, showQuizModal, countData } = useSelector(state => state.quizes);
  const [showApplicationCreatedPopup, setShowApplicationCreatedPopup] = useState(false);
  const [formQuizesData, setformQuizesData] = useState(null);
  const [quizFilterType, setQuizFilterType] = useState('All');
  const { user } = useAccount();
  const { mobileView } = useScreenView();
  const trialPeriodStart = moment(user.createTime);
  const trialPeriodEnd = moment(user.createTime).add(TRIAL_PERIOD_DAYS, 'days');
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const { formPage } = useSelector(state => state.quizes);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;
  const todayDate = moment(moment());
  const [openingModal, setOpeningModal] = useState(false);
  const [contributionId, setContributionId] = useState(null);
  const [contribution, setContribution] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });
  const [value, setValue] = React.useState(formPage);
  const [isLoading, setLoading] = useState(false);
  const getCountDetailsApi = () => {
    getCountUpdate().then(res => {
      dispatch(setAllFormsCountDetails(res));
    });
  };
  useEffect(() => {
    if (value != formPage) {
      setDefaultPage(formPage);
      setValue(formPage);
    }
    getCountDetailsApi();
  }, []);

  const handleModalOpen = () => {
    setLoading(true);
    if (
      isLaunchPlan &&
      trialPeriodStart.isSameOrAfter(TRIAL_PERIOD_START_DATE, 'day') &&
      todayDate.isAfter(trialPeriodEnd)
    ) {
      return;
    }
    setOpeningModal(true);
    getCohealerContribution(contributionId)
      .then(contrib => {
        setContribution(contrib);
        setShowApplicationCreatedPopup(false);
        setModalOpened(true);
      })
      .finally(() => {
        setOpeningModal(false);
        setLoading(false);
      });
  };

  const handleModalClose = useCallback(() => {
    setModalOpened(false);
  }, [setModalOpened]);

  const handleModalSubmit = useCallback(
    emails => {
      shareViaEmail({ contributionId, emailAddresses: emails })
        .then(() => {
          handleModalClose();
        })
        .catch(err => {
          setEmailsError({ isError: true, errorMessage: err?.response?.data?.message });
        });
    },
    [handleModalClose],
  );

  useEffect(() => {
    dispatch(fetchContributionActions.success(null));
  }, []);

  useEffect(() => {
    if (!loading) {
      setFetchChanges(false);
    }
  }, [loading]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.scrollY;
      if (scrollPosition + windowHeight === documentHeight) {
        setOffset(offset + APPLICATION_LIMIT);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [offset]);

  const renderList = useCallback(async () => {
    getForms();
  }, [getForms]);

  useEffect(() => {
    renderList();
  }, [renderList]);
  useEffect(() => {
    if (value === 'Forms') {
      getQuizesFormsList('Form', 1, 10);
    }
    if (value === 'Quizzes') {
      getQuizesFormsList('Quiz', 1, 10);
    }
  }, [value]);
  const handleChange = useCallback((type, value) => {
    if (value === 1) {
      getQuizesFormsList(type, 1, 10);
    } else if (value > 1) {
      getQuizesFormsList(type, value, 10);
    } else {
      getQuizesFormsList(type, value, 10);
    }
  }, []);
  const getQuizesFormsList = async (type, pageNumber, pageSize) => {
    getQuizzesFormsPaginated(type, pageNumber, pageSize).then(res => {
      setformQuizesData(res.items);
      setCurrentPage(pageNumber);
      setTotalRecords(res?.totalRecords);
      setPageCount(Math.ceil(res?.totalRecords / 10));
      if (type === 'Quiz') {
        setAllQuizesList(res.items);
      }
    });
  };
  if (loading && !fetchChanges) {
    return <Loader />;
  }

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          width: '100%',
          overflowX: mobileView ? 'scroll' : 'unset',
        }}
      >
        {['Applications', 'Enrollments', 'Forms', 'Quizzes'].map((item, index) => {
          return (
            <div
              style={{
                borderBottom: item === value ? `3px solid #215C73` : 'none',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: '20px',
              }}
            >
              {mobileView ? (
                <Button
                  variant="contained"
                  value={item}
                  style={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '18px',
                    fontWeight: '400',
                    color: value != item ? '#000000' : '#215C73',
                    minWidth: 'auto',
                    fontFamily: 'Avenir',
                    alignItems: 'center',
                    gap: '5px',
                    padding: '1rem 0px',
                  }}
                  onClick={e => {
                    if (value != item) {
                      setformQuizesData(null);
                      setValue(item);
                      dispatch(setDefaultPage(item));
                      getCountDetailsApi();
                    }
                  }}
                >
                  {item}
                  <span>
                    {item === 'Applications'
                      ? `${countData.applicationFormCount}`
                      : item === 'Enrollments'
                      ? `${countData.enrollmentFormCount}`
                      : item === 'Forms'
                      ? `${countData.formCount}`
                      : `${countData.quizCount}`}
                  </span>

                  <Tooltip
                    title={
                      item === 'Forms'
                        ? 'Used for gathering information from clients already in a contribution.'
                        : item === 'Quizzes'
                        ? 'Whether standalone or integrated with services, can be scored or personality assessments to evaluate client knowledge or understand them better.'
                        : item === 'Enrollments'
                        ? 'If you had enrollment forms connected to your contribution, these enrolment forms gathered your client information when they joined your contributions.'
                        : 'Used to collect client information for approving or rejecting them to join a contribution.'
                    }
                    arrow
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer', marginBottom: '1px' }} />
                  </Tooltip>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  value={item}
                  style={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '18px',
                    fontWeight: '400',
                    color: value != item ? '#000000' : '#215C73',
                    minWidth: 'auto',
                    fontFamily: 'Avenir',
                    alignItems: 'center',
                    gap: '5px',
                    padding: '1rem 0px',
                  }}
                  onClick={e => {
                    if (value != item) {
                      setformQuizesData(null);
                      setValue(item);
                      dispatch(setDefaultPage(item));
                      getCountDetailsApi();
                    }
                  }}
                >
                  {item}{' '}
                  {item === 'Applications'
                    ? `${countData.applicationFormCount}`
                    : item === 'Enrollments'
                    ? `${countData.enrollmentFormCount}`
                    : item === 'Forms'
                    ? `${countData.formCount}`
                    : `${countData.quizCount}`}
                  {/* <Typography className={classNames.wrapper}>
                  {item === 'Applications'
                    ? countData.applicationFormCount
                    : item === 'Enrollments'
                    ? countData.enrollmentFormCount
                    : item === 'Forms'
                    ? countData.formCount
                    : countData.quizCount}
                </Typography> */}
                  <Tooltip
                    title={
                      item === 'Forms'
                        ? 'Used for gathering information from clients already in a contribution.'
                        : item === 'Quizzes'
                        ? 'Whether standalone or integrated with services, can be scored or personality assessments to evaluate client knowledge or understand them better.'
                        : item === 'Enrollments'
                        ? 'If you had enrollment forms connected to your contribution, these enrolment forms gathered your client information when they joined your contributions.'
                        : 'Used to collect client information for approving or rejecting them to join a contribution.'
                    }
                    arrow
                    enterTouchDelay={TOOLTIP.ENTER_DELAY}
                    leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                  >
                    <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer', marginBottom: '1px' }} />
                  </Tooltip>
                </Button>
              )}
            </div>
          );
        })}
      </Box>
      {(isLoading || loading) && <Loader />}
      {value === 'Applications' ? (
        <>
          <Button
            style={{
              marginBottom: '10px',
              minWidth: 'unset',
              float: 'right',
              borderRadius: '5px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10px',
            }}
            onClick={() => setShowSelectionModal(true)}
          >
            {/* <span
              style={{
                display: 'flex',
                border: '2px solid white',
                borderRadius: 10000,
                marginRight: '5px',
                justifyContent: 'center',
                alignItems: 'center',
                height: 20,
                width: 20,
              }}
            >
              <AddIcon style={{ height: 15, width: 15 }} />
            </span> */}
            Create Application
          </Button>
          {showSelectionModal && (
            <ApplicationSelectionModal
              isOpen
              onClose={() => {
                setShowSelectionModal(false);
                setTemplate(null);
                setModalType(null);
              }}
              onSubmit={type => {
                if (type === 'new') {
                  setShowApplicationFormPopup(true);
                } else {
                  setShowTemplateModal(true);
                }
                setModalType(type);
                setShowSelectionModal(false);
              }}
            />
          )}
          {showTemplateModal && (
            <ApplicationTemplateModal
              isOpen={showTemplateModal}
              forms={forms}
              onClose={() => {
                setTemplate(null);
                setShowTemplateModal(false);
              }}
              onBack={() => {
                setTemplate(null);
                setShowTemplateModal(false);
                setShowSelectionModal(true);
              }}
              onSubmit={formTemplate => {
                setShowTemplateModal(false);
                setShowApplicationFormPopup(true);
                setTemplate(formTemplate);
              }}
            />
          )}
          {showApplicationFormPopup && (
            <CreateApplicationFormModal
              showApplicationFormPopup={showApplicationFormPopup}
              setShowApplicationFormPopup={setShowApplicationFormPopup}
              template={template}
              setTemplate={setTemplate}
              forms={forms}
              onCancel={() => {
                setTemplate(null);
              }}
              onSuccess={() => {
                setFetchChanges(true);
                getForms();
                setShowApplicationCreatedPopup(true);
              }}
              onBack={() => {
                if (modalType === 'new') {
                  setShowSelectionModal(true);
                }
                if (modalType === 'template') {
                  setShowTemplateModal(true);
                }
                setTemplate(null);
                setShowApplicationFormPopup(false);
              }}
              modalType={modalType}
              setContributionId={setContributionId}
            />
          )}
          {showApplicationCreatedPopup && (
            <Modal
              isOpen={showApplicationCreatedPopup}
              applyTheming
              title="Confirmation"
              className="app-form-created-success"
              onCancel={() => {
                setLoading(true);
                setShowApplicationCreatedPopup(false);
                setLoading(false);
              }}
              onSubmit={handleModalOpen}
              submitTitle="Invite"
            >
              <div>Your application is successfully created</div>
            </Modal>
          )}
          {modalOpened && (
            <ShareModal
              isOpen={modalOpened}
              onSubmit={handleModalSubmit}
              onModalClose={() => setModalOpened(false)}
              emailsError={emailsError}
              setEmailsError={setEmailsError}
              contributionId={contributionId}
              paymentInfo={contribution?.paymentInfo || {}}
              type={contribution?.type || ''}
              isInvoiced={contribution?.isInvoiced}
              symbol={contribution?.defaultSymbol || '$'}
            />
          )}
          {forms.length ? (
            <ApplicationFormsTable rows={forms} />
          ) : (
            <div style={{ marginTop: '10px' }}>There are no application forms yet.</div>
          )}
        </>
      ) : value === 'Enrollments' ? (
        <>
          {forms.length ? (
            <div style={{ marginTop: '10px' }}>
              {' '}
              <EnrollmenFormTable rows={forms} />{' '}
            </div>
          ) : (
            <div style={{ marginTop: '10px' }}>There are no application forms yet.</div>
          )}
        </>
      ) : value === 'Quizzes' ? (
        <>
          <div className="quiz_btns_container">
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
              {['All', 'Scored Quizzes', 'Personality Quizzes'].map((item, index) => {
                if (item === 'Personality Quizzes') return;
                return (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px' }}>
                    <Button
                      variant="contained"
                      value={item}
                      style={
                        quizFilterType === item
                          ? {
                              backgroundColor: '#CDBA8F',
                              display: 'flex',
                              justifyContent: 'center',
                              fontSize: '15px',
                              fontWeight: '800',
                              color: 'white',
                              minWidth: 'auto',
                              fontFamily: 'Avenir',
                              alignItems: 'center',
                              gap: '5px',
                              letterSpacing: '0px',
                            }
                          : {
                              backgroundColor: 'transparent',
                              display: 'flex',
                              justifyContent: 'center',
                              fontSize: '15px',
                              fontWeight: '800',
                              color: '#CDBA8F',
                              minWidth: 'auto',
                              fontFamily: 'Avenir',
                              alignItems: 'center',
                              gap: '5px',
                              border: '1px solid  #CDBA8F',
                              letterSpacing: '0px',
                            }
                      }
                      onClick={e => {
                        setQuizFilterType(item);
                        if (value === 'Quizzes' && item === 'Scored Quizzes') {
                          setformQuizesData(allQuizes.filter(item => item.quizType === 'Scored'));
                        } else if (value === 'Quizzes' && item === 'Personality Quizzes') {
                          setformQuizesData(allQuizes.filter(item => item.quizType != 'Scored'));
                        } else {
                          setformQuizesData(allQuizes);
                        }
                      }}
                    >
                      {item}
                    </Button>
                  </div>
                );
              })}
            </div>
            <Button
              style={{
                marginBottom: '10px',
                minWidth: 'unset',
                float: 'right',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
              }}
              onClick={() => {
                dispatch(setShowCreateModalQuiz(true));
              }}
            >
              Create a Quiz
            </Button>
          </div>
          {formQuizesData === null ? (
            <div>
              <Loader />
            </div>
          ) : formQuizesData != null && formQuizesData.length > 0 ? (
            <QuizzesTable getQuizesFormsList={getQuizesFormsList} rows={formQuizesData} />
          ) : formQuizesData.length === 0 && formQuizesData != null ? (
            <div style={{ marginTop: '10px' }}>There are no quizzes yet.</div>
          ) : (
            <div>
              <Loader />
            </div>
          )}
          {formQuizesData != null && formQuizesData.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'end', flexDirection: 'row', paddingBottom: '10px' }}>
              <div style={{ fontSize: '13px', fontWeight: '400', position: 'relative', bottom: '-28px' }}>
                {currentPage === 0 ? 1 : currentPage * 10 - 10 + 1} -{' '}
                {pageCount != currentPage ? currentPage * 10 : totalRecords} of {totalRecords}
              </div>
              <PaginationBar
                page={currentPage}
                handleChange={(e, page) => {
                  handleChange('Quiz', page);
                }}
                count={pageCount}
              />
            </div>
          )}
          <CreateQuizesModal
            isOpen={showQuizModal}
            handleClose={() => {
              dispatch(setShowCreateModalQuiz(false));
            }}
            handleSubmit={() => {
              dispatch(setShowCreateModal(false));
            }}
            type={'Quiz'}
          />
        </>
      ) : (
        <>
          <Button
            style={{
              marginBottom: '10px',
              minWidth: 'unset',
              float: 'right',
              borderRadius: '5px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10px',
            }}
            onClick={() => dispatch(setShowCreateModalForm(true))}
          >
            Create a Form
          </Button>
          {formQuizesData === null ? (
            <div>
              <Loader />
            </div>
          ) : formQuizesData != null && formQuizesData.length > 0 ? (
            <FormsTable getQuizesFormsList={getQuizesFormsList} rows={formQuizesData} />
          ) : formQuizesData.length === 0 && formQuizesData != null ? (
            <div style={{ marginTop: '10px' }}>There are no forms yet.</div>
          ) : (
            <div>
              <Loader />
            </div>
          )}
          {formQuizesData != null && formQuizesData.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'end', flexDirection: 'row', paddingBottom: '10px' }}>
              <div style={{ fontSize: '13px', fontWeight: '400', position: 'relative', bottom: '-28px' }}>
                {currentPage === 0 ? 1 : currentPage * 10 - 10 + 1} -{' '}
                {pageCount != currentPage ? currentPage * 10 : totalRecords} of {totalRecords}
              </div>
              <PaginationBar
                page={currentPage}
                handleChange={(e, page) => {
                  handleChange('Form', page);
                }}
                count={pageCount}
              />
            </div>
          )}
          <CreateQuizesModal
            isOpen={showFormModal}
            handleClose={() => {
              dispatch(setShowCreateModalForm(false));
            }}
            handleSubmit={() => {
              dispatch(setShowCreateModalForm(false));
            }}
            type={'form'}
          />
        </>
      )}
    </>
  );
};
const mapStateToProps = ({ applications: { forms, isLoading }, upcomingCreated: { error }, account }) => ({
  userId: account?.user?.id,
  loading: isLoading,
  error,
  forms: forms || [],
});
const actions = {
  getForms: fetchApplicationForms,
};
AllFormsPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  forms: PropTypes.arrayOf(PropTypes.object).isRequired,
  getForms: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, actions)(AllFormsPage);
