import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { useAccount } from 'hooks';
import ShareIcon from 'assets/ic-share.svg';
import { inviteByEmail } from 'services/affiliate.service';
import * as bankAccountsService from 'services/bankAccounts.service';
import { redirectTo } from 'services/links';
import InviteCoachModalContainer from './InviteModal/InviteCoachModalContainer';
import ConnectStripeModal from 'components/Modals/ConnectStripeModal';
import useScreenView from 'hooks/useScreenView';
import copyToClipboard from 'utils/copyToClipboard';
const StyledShareButton = styled.div`
  float: right;
  margin-top: 5px;
  text-transform: none !important;
  display: flex;
`;

const Icon = styled.img`
  vertical-align: middle;
  object-fit: contain;
  margin-right: 7px;
`;

const InvitationCoaches = ({ disableInvite }) => {
  const {
    user: { transfersEnabled, affiliateAccountTransfersEnabled },
  } = useAccount();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [connectStripeModal, setConnectStripeModal] = useState(false);
  const { mobileView } = useScreenView();
  const [emailsError, setEmailsError] = useState({
    isError: false,
    errorMessage: null,
  });
  const handleModalSubmit = useCallback(
    emails => {
      inviteByEmail({ emailAddresses: emails })
        .then(() => {
          setModalOpened(false);
          setSuccessPopupOpen(true);
        })
        .catch(err => {
          setEmailsError({ isError: true, errorMessage: err?.response?.data?.message });
        });
    },
    [setModalOpened, setSuccessPopupOpen],
  );
  const handleSuccessPopupSubmit = useCallback(() => setSuccessPopupOpen(false), [setSuccessPopupOpen]);
  const handleConnectStripeModalClose = useCallback(() => setConnectStripeModal(false), [setConnectStripeModal]);
  const handleInviteModalOpen = useCallback(
    () =>
      affiliateAccountTransfersEnabled
        ? () => {
            setTooltipOpen(true);
            handleCopyClick();
          }
        : setConnectStripeModal(true),
    [setConnectStripeModal, setModalOpened],
  );
  const handleConnectStripeModalSubmit = useCallback(
    () =>
      bankAccountsService
        .verifyStripeAccountForAffiliateAccount()
        .then(({ link }) => redirectTo(link))
        .catch(err => {
          console.dir(err);
          setConnectStripeModal(false);
        }),
    [setConnectStripeModal],
  );
  const { user } = useAccount();
  const handleCopyClick = () => {
    copyToClipboard(
      origin.includes('local') || origin.includes('test') || origin.includes('dev')
        ? `https://staging5.rolustech.com:44338/affiliate?inviteCode=${user.accountId}`
        : `https://www.cohere.live/affiliate?inviteCode=${user.accountId}`,
    );
  };
  console.log('disableInvite------>', disableInvite);
  return (
    <Grid item xs={6} alignItems="center">
      <Tooltip
        className="cursor-pointer"
        title="Affiliate Link Copied Successfully"
        open={tooltipOpen}
        arrow
        onClose={() => {
          setTooltipOpen(false);
        }}
      >
        <StyledShareButton
          onClick={() => {
            if (affiliateAccountTransfersEnabled) {
              if (disableInvite === true) {
                setTooltipOpen(true);
                handleCopyClick();
              }
            } else {
              setConnectStripeModal(true);
            }
          }}
        >
          <Icon src={ShareIcon} />
          <span>Invite Coaches</span>
        </StyledShareButton>
      </Tooltip>
      <InviteCoachModalContainer
        isOpen={modalOpened}
        onModalClose={() => setModalOpened(false)}
        emailsError={emailsError}
        onSubmit={handleModalSubmit}
        setEmailsError={setEmailsError}
      />

      {successPopupOpen && (
        <Modal
          isOpen={successPopupOpen}
          onCancel={handleSuccessPopupSubmit}
          onSubmit={handleSuccessPopupSubmit}
          title="Invite Sent"
          hiddenCancel
          submitTitle="Ok"
        >
          Your referral link has been successfully sent.
        </Modal>
      )}
      {connectStripeModal && (
        <ConnectStripeModal
          isOpen={connectStripeModal}
          onCancel={handleConnectStripeModalClose}
          onSubmit={handleConnectStripeModalSubmit}
          mobileView={mobileView}
        />
      )}
    </Grid>
  );
};

export default InvitationCoaches;
