import React from 'react';

const PersonalityQuizIcon = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          d="M14.0625 12.5C14.9254 12.5 15.625 11.8004 15.625 10.9375C15.625 10.0746 14.9254 9.375 14.0625 9.375C13.1996 9.375 12.5 10.0746 12.5 10.9375C12.5 11.8004 13.1996 12.5 14.0625 12.5Z"
          fill="#215C73"
        />
        <path
          d="M42.1875 34.375V28.125C42.1875 27.2962 41.8583 26.5013 41.2722 25.9153C40.6862 25.3292 39.8913 25 39.0625 25H26.5625V18.75H40.625C41.4538 18.75 42.2487 18.4208 42.8347 17.8347C43.4208 17.2487 43.75 16.4538 43.75 15.625V6.25C43.75 5.4212 43.4208 4.62634 42.8347 4.04029C42.2487 3.45424 41.4538 3.125 40.625 3.125H9.375C8.5462 3.125 7.75134 3.45424 7.16529 4.04029C6.57924 4.62634 6.25 5.4212 6.25 6.25V15.625C6.25 16.4538 6.57924 17.2487 7.16529 17.8347C7.75134 18.4208 8.5462 18.75 9.375 18.75H23.4375V25H10.9375C10.1087 25 9.31384 25.3292 8.72779 25.9153C8.14174 26.5013 7.8125 27.2962 7.8125 28.125V34.375H6.25C5.4212 34.375 4.62634 34.7042 4.04029 35.2903C3.45424 35.8763 3.125 36.6712 3.125 37.5V43.75C3.125 44.5788 3.45424 45.3737 4.04029 45.9597C4.62634 46.5458 5.4212 46.875 6.25 46.875H12.5C13.3288 46.875 14.1237 46.5458 14.7097 45.9597C15.2958 45.3737 15.625 44.5788 15.625 43.75V37.5C15.625 36.6712 15.2958 35.8763 14.7097 35.2903C14.1237 34.7042 13.3288 34.375 12.5 34.375H10.9375V28.125H23.4375V34.375H21.875C21.0462 34.375 20.2513 34.7042 19.6653 35.2903C19.0792 35.8763 18.75 36.6712 18.75 37.5V43.75C18.75 44.5788 19.0792 45.3737 19.6653 45.9597C20.2513 46.5458 21.0462 46.875 21.875 46.875H28.125C28.9538 46.875 29.7487 46.5458 30.3347 45.9597C30.9208 45.3737 31.25 44.5788 31.25 43.75V37.5C31.25 36.6712 30.9208 35.8763 30.3347 35.2903C29.7487 34.7042 28.9538 34.375 28.125 34.375H26.5625V28.125H39.0625V34.375H37.5C36.6712 34.375 35.8763 34.7042 35.2903 35.2903C34.7042 35.8763 34.375 36.6712 34.375 37.5V43.75C34.375 44.5788 34.7042 45.3737 35.2903 45.9597C35.8763 46.5458 36.6712 46.875 37.5 46.875H43.75C44.5788 46.875 45.3737 46.5458 45.9597 45.9597C46.5458 45.3737 46.875 44.5788 46.875 43.75V37.5C46.875 36.6712 46.5458 35.8763 45.9597 35.2903C45.3737 34.7042 44.5788 34.375 43.75 34.375H42.1875ZM12.5 43.75H6.25V37.5H12.5V43.75ZM28.125 37.5V43.75H21.875V37.5H28.125ZM9.375 15.625V6.25H40.625V15.625H9.375ZM43.75 43.75H37.5V37.5H43.75V43.75Z"
          fill="#215C73"
        />
      </g>
    </svg>
  );
};

export default PersonalityQuizIcon;
