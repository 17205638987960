import React from 'react';
import classes from './MainContainer.module.scss';
import Header from 'components/UI/Header';
import styled from 'styled-components';
import useRouter from 'hooks/useRouter';
import useScreenView from 'hooks/useScreenView';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const StyledMainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  height: 100vh;
`;

const StyledMainSection = styled.div`
  padding: ${({ mobileView, isLoginPage }) => (mobileView ? (isLoginPage ? '30px 16px' : '0px 0px') : '0px 0px')};
  background-color: #fafafa;
`;

const QuizViewMain = ({ headerProps, children }) => {
  const { pathname } = useRouter();
  const { mobileView } = useScreenView();
  const { activeQuiz, userDetails } = useSelector(state => state?.quizes);
  const isQuizPreview = pathname?.includes('/quiz-view');
  const isFormPreview = pathname?.includes('/form-view');
  const isLoginPage = isQuizPreview && pathname?.includes('/login');
  return (
    <StyledMainContainer>
      <Helmet>
        <title>{activeQuiz.title || 'Cohere'}</title> {/* Ensures it's a string */}
      </Helmet>
      <Header
        isQuizPreview={isQuizPreview}
        isFormPreview={isFormPreview}
        backButtonText="Home"
        isCustomPrevButton
        {...headerProps}
      />
      <StyledMainSection mobileView={mobileView} isLoginPage={isLoginPage}>
        {children}
      </StyledMainSection>
    </StyledMainContainer>
  );
};

export default QuizViewMain;
